import { MenuItem, Typography, makeStyles } from '@material-ui/core'
import { Close, Fireplace } from '@material-ui/icons'
import React, { useState, useReducer, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useListContext,
  sanitizeFetchType,
  GET_MANY,
  GetManyResult,
  useTranslate,
} from 'react-admin'
import { ToggleType } from '../../../../core/devices/passages/passage-set-boolean-status-button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { PassageSelectStateDialog } from '../PassageDialogs'
import { PassageDto } from '../../../../core/dto/device/passages/passage.dto'
import {
  passageSetPPOZButtonInitialState,
  passageSetPPOZButtonReducer,
} from '../../../../core/devices/passages/passage-set-ppoz-button.reducer'
import { ResourceName } from '../../../../core/ResourceName'
import { ApiErrorDto } from '../../../../core/dto/error-handling/api-error.dto'
import { BulkActionProps } from '../../../common/BulkActionsProps'
import {
  ProgressStatus,
  StateBulkProgressDialog,
} from '../../../common/StateBulkProgressDialog'

const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 34,
  },
})

export const PassageSetPPOZBulkButton = ({
  selectedIds,
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  innerRef,
  asRecurringJob,
  ...props
}: BulkActionProps) => {
  const { loading, refetch } = useListContext<PassageDto>()
  const notify = useNotify()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const classes = useStyles()

  const [{ mutateQuery }, dispatch] = useReducer(
    passageSetPPOZButtonReducer,
    passageSetPPOZButtonInitialState,
  )
  const [selectStateDialogOpen, setSelectStateDialogOpen] = useState(false)
  const [mutate, { loading: mutationLoading }] = useMutation()
  const [selectedState, setSelectedState] = useState(false)
  const [bulkStateDialogOpen, setBulkStateDialogOpen] = useState(false)
  const [bulkProgressStatus, setBulkProgressStatus] = useState<
    ProgressStatus[]
  >([])
  const updateBulkProgressStatus = (newStatus: ProgressStatus) => {
    const oldStatusIndex = bulkProgressStatus.findIndex(
      (s) => s.id === newStatus.id,
    )
    bulkProgressStatus.splice(oldStatusIndex, 1, newStatus)
  }
  const [selectedPassages, setSelectedPassages] = useState<PassageDto[]>([])

  const handleMutation = async (
    passage: PassageDto,
    recurringJobName?: string,
    cronExpression?: string,
    auditNote?: string,
  ) => {
    let error
    let query
    if (!asRecurringJob) {
      query = mutateQuery?.(passage, auditNote)
    } else {
      query = mutateQuery?.(
        passage,
        auditNote,
        recurringJobName,
        cronExpression,
      )
    }
    let result = null
    try {
      result = await mutate(query, {
        returnPromise: true,
      })
    } catch (err) {
      error = err
    }
    if (error) {
      updateBulkProgressStatus({
        id: passage.id,
        status: 'error',
        error: error as ApiErrorDto,
      } as ProgressStatus)
      return error
    }
    updateBulkProgressStatus({
      id: passage.id,
      status: 'success',
    } as ProgressStatus)
    return result
  }

  const handleSetPPOZ = async (
    recurringJobName?: string,
    cronExpression?: string,
    auditNote?: string,
  ) => {
    setBulkStateDialogOpen(true)
    const pendingRequests: Promise<any>[] = []
    const selectedPassagesResponse = (await mutate(
      {
        type: sanitizeFetchType(GET_MANY),
        resource: ResourceName.PASSAGES,
        payload: {
          ids: selectedIds,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => {
          notify(err?.message, 'error')
          setBulkStateDialogOpen(false)
        },
      },
    )) as GetManyResult<PassageDto>
    if (!selectedPassagesResponse.data.length) {
      setBulkStateDialogOpen(false)
      return
    }
    selectedPassagesResponse.data.forEach((passage: PassageDto) => {
      pendingRequests.push(
        handleMutation(passage, recurringJobName, cronExpression, auditNote),
      )
      selectedPassages.push(passage)
      bulkProgressStatus.push({
        id: passage.id,
        status: 'pending',
      } as ProgressStatus)
    })
    await Promise.all(pendingRequests)
    refetch()
  }

  useEffect(() => {
    if (selectedState) {
      dispatch({ type: ToggleType.OFF })
    } else {
      dispatch({ type: ToggleType.ON })
    }
  }, [selectedState])

  useEffect(() => {
    if (!bulkStateDialogOpen) {
      setBulkProgressStatus([])
      setSelectedPassages([])
    }
  }, [bulkStateDialogOpen])

  return (
    <MenuItem
      {...props}
      disabled={
        !selectedIds.length ||
        !hasAuthority(Authority.PASSAGES_SET_PPOZ_BUTTON) ||
        loading ||
        mutationLoading
      }
      onClick={() => setSelectStateDialogOpen(true)}
      button
    >
      <Typography>
        {translate(
          !asRecurringJob
            ? 'resources.passages.actions.setPPOZState'
            : 'resources.passages.actions.setPPOZState-recurring',
        )}
      </Typography>
      <PassageSelectStateDialog
        open={selectStateDialogOpen}
        confirm={handleSetPPOZ}
        method="ppozState"
        close={() => setSelectStateDialogOpen(false)}
        state={selectedState}
        setState={setSelectedState}
        asRecurringJob={asRecurringJob}
        OnIcon={<Fireplace className={classes.icon} />}
        OffIcon={
          <>
            <Fireplace className={classes.icon} />
            <Close fontSize="large" className={classes.crossIcon} />
          </>
        }
        withAuditNote
      />
      <StateBulkProgressDialog
        open={bulkStateDialogOpen}
        close={() => setBulkStateDialogOpen(false)}
        elements={selectedPassages}
        method="ppozState"
        statuses={bulkProgressStatus}
        resource={ResourceName.PASSAGES}
      />
    </MenuItem>
  )
}
