import { Autorenew } from '@material-ui/icons'
import React, { useState, useReducer, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { ToggleType } from '../../../../core/devices/passages/passage-set-boolean-status-button'
import { PassageDto } from '../../../../core/dto/device/passages/passage.dto'
import { PassageSelectStateDialog } from '../PassageDialogs'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import {
  passageSetLockButtonInitialState,
  passageSetLockButtonReducer,
} from '../../../../core/devices/passages/passage-set-lock-button.reducer'

export const PassageSetLockButton = ({ ...props }) => {
  const { asRecurringJob, disabled } = props
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const [{ mutateQuery }, dispatch] = useReducer(
    passageSetLockButtonReducer,
    passageSetLockButtonInitialState,
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const [state, setState] = useState(false)
  const passage = useRecordContext<PassageDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  useEffect(() => {
    if (state) {
      dispatch({ type: ToggleType.OFF })
    } else {
      dispatch({ type: ToggleType.ON })
    }
  }, [state])

  const handleLock = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    let query
    if (!asRecurringJob) {
      query = mutateQuery?.(passage)
    } else {
      query = mutateQuery?.(
        passage,
        recurringJobName as string,
        cronExpression as string,
      )
    }
    await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    refresh()
  }

  return (
    <>
      <PassageSelectStateDialog
        open={dialogOpen}
        confirm={handleLock}
        method="lockState"
        close={() => setDialogOpen(false)}
        state={state}
        setState={setState}
        asRecurringJob={asRecurringJob}
      />
      <Button
        disabled={
          !hasAuthority(Authority.PASSAGES_LOCK_BUTTON) ||
          loading ||
          mutationLoading ||
          disabled === true
        }
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        onClick={() => setDialogOpen(true)}
        label={
          !asRecurringJob
            ? 'resources.passages.actions.setLockState'
            : 'common.recurring-job.buttons.create-recurring-job'
        }
        useSmallVersionBreakpoint={false}
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
      />
    </>
  )
}
