import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { Children, cloneElement, ReactElement } from 'react'
import {
  useCreateController,
  CreateProps,
  CreateContextProvider,
  useTranslate,
} from 'react-admin'

const useStyles = makeStyles({
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    marginLeft: '10px',
    marginRight: 'auto',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 0,
  },
  form: {
    width: '100%',
  },
})

/* eslint-disable @typescript-eslint/no-unused-vars */
const sanitizeRestProps = ({
  basePath,
  children,
  handleClose,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  history,
  location,
  match,
  onFailure,
  onSuccess,
  open,
  options,
  permissions,
  title,
  transform,
  ...rest
}: CreateDialogProps): any => rest
/* eslint-enable @typescript-eslint/no-unused-vars */

export interface CreateDialogProps
  extends Omit<CreateProps, 'classes'>,
    Omit<DialogProps, 'id' | 'title'> {
  children?: ReactElement
  handleClose?: () => void
}

export const CreateDialog = (props: CreateDialogProps): ReactElement => {
  const { children, open, handleClose, title, ...rest } = props
  const controllerProps = useCreateController(rest as CreateProps)
  const {
    basePath,
    record,
    redirect: redirectTo,
    resource,
    save,
    saving,
    version,
  } = controllerProps

  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      aria-labelledby="create-dialog-title"
      {...sanitizeRestProps(props)}
    >
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
      >
        <Typography className={classes.bulkProgressTitle}>
          {translate(title as string)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <CreateContextProvider value={controllerProps}>
          {cloneElement(Children.only(children as any), {
            basePath,
            record,
            redirect:
              typeof children?.props.redirect === 'undefined'
                ? redirectTo
                : children.props.redirect,
            resource,
            save,
            saving,
            version,
            className: classes.form,
          })}
        </CreateContextProvider>
      </DialogContent>
    </Dialog>
  )
}
