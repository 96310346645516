export enum DeviceTypes {
  ENGINE = 'ENGINE',
  PALM = 'PALM',
  DETECTOR = 'DETECTOR',
  PASSAGE = 'PASSAGE',
  OFFLINE_SERVER = 'OFFLINE_SERVER',
  DEPOSITOR = 'DEPOSITOR',
  DOORS = 'DOORS',
  TERMINALS = 'TERMINALS',
}
