import * as React from 'react'
import { Children, cloneElement } from 'react'
import {
  getListControllerProps,
  ListView,
  ListViewProps,
  useListContext,
} from 'react-admin'

export const ExtetdedListView = (props: ListViewProps) => {
  const { pagination, children, bulkActionButtons } = props
  const controllerProps = getListControllerProps(props)
  const listContext = useListContext(props)

  return pagination !== undefined && pagination !== false ? (
    <ListView {...props}>
      <>
        {pagination && cloneElement(pagination, listContext)}
        {children &&
          cloneElement(Children.only(children), {
            ...controllerProps, // deprecated, use ListContext instead, to be removed in v4
            hasBulkActions: bulkActionButtons !== false,
          })}
      </>
    </ListView>
  ) : (
    <ListView {...props} />
  )
}
