import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
  DeleteParams,
} from 'react-admin'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../../common/get-by-conditions.utils'
import { DynamicCounterParameterDto } from '../../../dto/dynamic-counter-parameter.dto'
import { TicketAPIGetListParams } from '../../../common/ticket-api-get-list.params'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../../../common/fetch.utils'
import { DYNAMIC_COUNTERS_PARAMETERS_URL } from '../../../api-urls'
import { DynamicCounterParameterTypes } from '../../../enum/DynamicCounterParameterTypes'
import { mapSortDynamicCounterParameterParams } from './dynamic-counter-parameter-sort.mapper'
import filterMapper from './dynamic-counter-parameter-filter.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, pagination, sort }: TicketAPIGetListParams,
  ): Promise<GetListResult<DynamicCounterParameterDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortDynamicCounterParameterParams,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DynamicCounterParameterDto[]>(
      `${DYNAMIC_COUNTERS_PARAMETERS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DynamicCounterParameterDto>> => {
    const data = await get<DynamicCounterParameterDto>(
      `${DYNAMIC_COUNTERS_PARAMETERS_URL}`,
      `/${id}`,
    )

    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<DynamicCounterParameterDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      UpdateDynamicCounterParameterRequest,
      DynamicCounterParameterDto
    >(`${DYNAMIC_COUNTERS_PARAMETERS_URL}`, {
      id: id as number,
      key: data.key !== previousData.key ? data.key : undefined,
      value: data.value !== previousData.value ? data.value : undefined,
      type: data.type !== previousData.type ? data.type : undefined,
      dynamicCounterId:
        data.dynamicCounterId !== previousData.dynamicCounterId
          ? data.dynamicCounterId
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<DynamicCounterParameterDto>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateDynamicCounterParameterRequest[],
      DynamicCounterParameterDto[]
    >(`${DYNAMIC_COUNTERS_PARAMETERS_URL}`, [
      {
        key: data?.key,
        value: data?.value,
        type: data?.type,
        dynamicCounterId: data?.dynamicCounterId,
      },
    ])
    return Promise.resolve({ data: created[0] })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<CreateResult> => {
    const deleted = await remove<DynamicCounterParameterDto>(
      `${DYNAMIC_COUNTERS_PARAMETERS_URL}`,
      `/${id}`,
    )

    return Promise.resolve({ data: deleted })
  },
} as DataProvider

interface CreateDynamicCounterParameterRequest {
  key: string
  value: string
  type: DynamicCounterParameterTypes
  dynamicCounterId: number
}

interface UpdateDynamicCounterParameterRequest {
  id: number
  key?: string
  value?: string
  type?: DynamicCounterParameterTypes
  dynamicCounterId?: number
}

export default provider
