import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import { SCHEMES_URL } from '../api-urls'
import { get, getWithPagination } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { LogSchemeDataDto } from '../dto/log-scheme-data.dto'
import { SchemeExtendedDto } from '../dto/scheme/scheme-extended.dto'
import { SchemeDto } from '../dto/scheme/scheme.dto'
import logSchemeDataProvider from '../log-scheme-data/log-scheme-data.provider'
import { ResourceName } from '../ResourceName'
import filterMapper from './scheme-filter.mapper'
import { mapSortSchemeParam } from './scheme-sort.mapper'
import { getParentSchemesRelatedSchemeData } from './schemes.utils'
import i18nProvider from '../i18n.provider'
import { PalmSchemeDataDto } from '../dto/palm-scheme-data.dto'
import palmSchemeDataProvider from '../palm-scheme-data/palm-scheme-data.provider'

const extendSchemeDto = (
  schemes: SchemeDto[],
  schemeDataWithParents: SchemeDto[],
  logSchemeData?: LogSchemeDataDto[],
  palmSchemeData?: PalmSchemeDataDto[],
  locale?: string,
): SchemeExtendedDto[] =>
  schemes.map((scheme) => ({
    ...scheme,
    hierarchyString: scheme.hierarchy
      ?.slice()
      .map((h) => h.name)
      .join(`/\n`),
    translatedLogSchemeDataHierarchyString:
      logSchemeData && locale
        ? scheme.hierarchy
            ?.slice()
            .map((h) => {
              const translatedText = logSchemeData.filter(
                (lsd) =>
                  lsd.language === locale &&
                  schemeDataWithParents
                    .filter((s) => s.id === h.id)[0]
                    ?.logSchemeDataIds.some((lsdId) => lsd.id === lsdId),
              )[0]?.text
              if (translatedText) return translatedText
              return h.name
            })
            .join(`/\n`)
        : scheme.hierarchy
            ?.slice()
            .map((h) => h.name)
            .join(`/\n`),
    translatedPalmSchemeDataHierarchyString:
      palmSchemeData && locale
        ? scheme.hierarchy
            ?.slice()
            .map((h) => {
              const translatedText = palmSchemeData.filter(
                (psd) =>
                  psd.language === locale &&
                  schemeDataWithParents
                    .filter((s) => s.id === h.id)[0]
                    ?.palmSchemeDataIds.some((psdId) => psd.id === psdId),
              )[0]?.text
              if (translatedText) return translatedText
              return h.name
            })
            .join(`/\n`)
        : scheme.hierarchy
            ?.slice()
            .map((h) => h.name)
            .join(`/\n`),
  })) as SchemeExtendedDto[]

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<SchemeExtendedDto>> => {
    const locale = i18nProvider.getLocale()
    const filterParams = `o=>${filterParamsComposer(
      'o',
      { ...filter, locale },
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortSchemeParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<SchemeDto[]>(
      `${SCHEMES_URL}/GetByConditions`,
      path,
    )

    const schemeIds = data.flatMap((s) => s.id)
    const schemesData = await getParentSchemesRelatedSchemeData(schemeIds)

    const schemeDataWithParents = [...schemesData.parentSchemes, ...data]

    const logSchemeData = await logSchemeDataProvider.getMany<LogSchemeDataDto>(
      ResourceName.LOG_SCHEME_DATA,
      { ids: schemesData.logSchemeDataIds } as GetManyParams,
    )

    const palmSchemeData =
      await palmSchemeDataProvider.getMany<PalmSchemeDataDto>(
        ResourceName.PALM_SCHEME_DATA,
        { ids: schemesData.palmSchemeDataIds } as GetManyParams,
      )

    return Promise.resolve({
      data: extendSchemeDto(
        data,
        schemeDataWithParents,
        logSchemeData.data,
        palmSchemeData.data,
        locale,
      ),
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<SchemeExtendedDto>> => {
    const locale = i18nProvider.getLocale()
    const data = await get<SchemeDto>(SCHEMES_URL, `/${id}`)
    const schemeData = await getParentSchemesRelatedSchemeData([data.id])

    const schemeDataWithParents = [...schemeData.parentSchemes, data]

    const logSchemeData = await logSchemeDataProvider.getMany<LogSchemeDataDto>(
      ResourceName.LOG_SCHEME_DATA,
      { ids: schemeData.logSchemeDataIds } as GetManyParams,
    )

    const palmSchemeData =
      await palmSchemeDataProvider.getMany<PalmSchemeDataDto>(
        ResourceName.PALM_SCHEME_DATA,
        { ids: schemeData.palmSchemeDataIds } as GetManyParams,
      )

    return Promise.resolve({
      data: extendSchemeDto(
        [data],
        schemeDataWithParents,
        logSchemeData.data,
        palmSchemeData.data,
        locale,
      )?.[0],
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<SchemeExtendedDto>> => {
    const locale = i18nProvider.getLocale()

    const data = await get<SchemeDto[]>(
      SCHEMES_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const schemesData = await getParentSchemesRelatedSchemeData(
      ids.map((id) => Number(id)),
    )

    const schemeDataWithParents = [...schemesData.parentSchemes, ...data]

    const logSchemeData = await logSchemeDataProvider.getMany<LogSchemeDataDto>(
      ResourceName.LOG_SCHEME_DATA,
      { ids: schemesData.logSchemeDataIds } as GetManyParams,
    )

    const palmSchemeData =
      await palmSchemeDataProvider.getMany<PalmSchemeDataDto>(
        ResourceName.PALM_SCHEME_DATA,
        { ids: schemesData.palmSchemeDataIds } as GetManyParams,
      )

    return Promise.resolve({
      data: extendSchemeDto(
        data,
        schemeDataWithParents,
        logSchemeData.data,
        palmSchemeData.data,
        locale,
      ),
    })
  },
} as DataProvider

export default provider
