import { Close } from '@material-ui/icons'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { PriorityHigh } from '@mui/icons-material'
import { get } from 'lodash'
import React, { ReactNode } from 'react'
import {
  BooleanField,
  DateField,
  FunctionField,
  Labeled,
  Record,
  RecordContextProvider,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import camelcaseKeys from 'camelcase-keys'
import { DateTime } from '../../../../core/common/date-time'
import { IOStatusDto } from '../../../../core/dto/device/common/modules/io-status.dto'
import { LiteDto } from '../../../../core/dto/device/common/modules/lite.dto'
import { ModuleBaseDto } from '../../../../core/dto/device/common/modules/module-base.dto'
import { OfflineServerModuleDto } from '../../../../core/dto/device/common/modules/offline-server-module.dto'
import { PresenterDto } from '../../../../core/dto/device/common/modules/presenter.dto'
import { ReaderDto } from '../../../../core/dto/device/common/modules/reader.dto'
import { SensorDto } from '../../../../core/dto/device/common/modules/sensor.dto'
import {
  TurnstileDto,
  TurnstileStatusData,
} from '../../../../core/dto/device/common/modules/turnstile.dto'
import { PinStates } from '../../../../core/enum/device-modues/PinStates'
import { LCDPlusDto } from '../../../../core/dto/device/common/modules/lcd-plus.dto'
import { getModulesFromJsonStatus } from '../../../../core/devices/common/modules/module.mapper'
import { getContrastColor } from '../../../../core/common/visual.utils'
import { DeviceModuleTypes } from '../../../../core/enum/device-modues/DeviceModuleTypes'
import { PrinterDto } from '../../../../core/dto/device/common/modules/printer.dto'
import { DeviceTypes } from '../../../../core/enum/DeviceTypes'
import PaperRoll from '../../../../assets/icons/PaperRoll'

const moduleHeaderBase = {
  borderTop: 'solid',
  borderRight: 'solid',
  borderLeft: 'solid',
  borderTopRightRadius: 5,
  borderTopLeftRadius: 5,
}

const moduleDetailsBase = {
  borderBottom: 'solid',
  borderRight: 'solid',
  borderLeft: 'solid',
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  '& > div': {
    width: '100%',
  },
}

const useStyles = makeStyles((theme: Theme) => ({
  simpleShowLayoutWithNoVerticalPadding: {
    paddingBottom: 0,
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
      [theme.breakpoints.only('xs')]: {
        paddingBottom: 0,
      },
    },
  },
  defaultModuleHeader: {
    ...moduleHeaderBase,
    backgroundColor: theme.palette.success.main,
    borderTopColor: theme.palette.success.main,
    borderRightColor: theme.palette.success.main,
    borderLeftColor: theme.palette.success.main,
  },
  defaultModuleDetails: {
    ...moduleDetailsBase,
    borderBottomColor: theme.palette.success.main,
    borderRightColor: theme.palette.success.main,
    borderLeftColor: theme.palette.success.main,
  },
  terminatedModuleHeader: {
    ...moduleHeaderBase,
    backgroundColor: theme.palette.error.main,
    borderTopColor: theme.palette.error.main,
    borderRightColor: theme.palette.error.main,
    borderLeftColor: theme.palette.error.main,
  },
  terminatedModuleDetails: {
    ...moduleDetailsBase,
    borderBottomColor: theme.palette.error.main,
    borderRightColor: theme.palette.error.main,
    borderLeftColor: theme.palette.error.main,
  },
  previewedDeviceOk: {
    borderRadius: 12,
    backgroundColor: theme.palette.success.main,
  },
  previewedDeviceError: {
    borderRadius: 12,
    backgroundColor: theme.palette.error.main,
  },
  iconContainer: {
    position: 'relative',
    width: 34,
  },
  statusIcon: {
    position: 'absolute',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
  exclamationIcon: {
    position: 'absolute',
    left: 2.5,
    top: 0,
    fontSize: 34,
  },
}))

const CheckLastRefreshError = (lastRefresh: DateTime) => {
  let moduleStatusTimeout = '00:00:00'
  try {
    moduleStatusTimeout = JSON.parse(
      localStorage?.preferences,
    )?.moduleStatusTimeout
    if (moduleStatusTimeout === null || moduleStatusTimeout === undefined)
      moduleStatusTimeout = '00:01:30'
  } catch {
    moduleStatusTimeout = '00:01:30'
  }
  const timeoutArray = moduleStatusTimeout.split(':')
  const timeoutDate = new Date(0)
  timeoutDate.setHours(Number(timeoutArray[0]) + 1)
  timeoutDate.setMinutes(Number(timeoutArray[1]))
  timeoutDate.setSeconds(Number(timeoutArray[2]))
  return new Date(lastRefresh).valueOf() < Date.now() - timeoutDate.valueOf()
}

const CheckOfflineServerError = (module: OfflineServerModuleDto) => {
  if (module?.module?.typeId === DeviceModuleTypes.OFFLINE_SERVER) {
    if (
      module.replicationApplierStatus === undefined ||
      module.replicationApplierStatus === undefined ||
      module.replicationApplierStatus === null ||
      module.replicationApplierStatus === null
    )
      return true
    if (
      module?.replicationApplierStatus === false ||
      module.replicationConnectionStatus === false
    )
      return true
  }
  return false
}

const CheckPrinterError = (module: PrinterDto) => {
  if (!module) return false
  if (
    module?.printingHeadUpError === true ||
    module?.coverOpened === true ||
    module?.headTemperatureError === true ||
    module?.powerSupplyVoltageError === true ||
    module?.ramError === true ||
    module?.autocutterError === true ||
    module?.emiterError === true ||
    module?.paperNotPresent === true
  )
    return true
  return false
}

const CheckModulesError = (modules: ModuleBaseDto[]) => {
  if (
    modules.some(
      (module) =>
        CheckLastRefreshError(module.lastRefresh) ||
        CheckOfflineServerError(module),
    )
  )
    return true
  return false
}

const ModuleStatusBase = (props: ModuleBaseStatusProps) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { moduleInfo, disableAliasHeader, children } = props
  const convertedSerialNumber = Number(
    moduleInfo.identity?.serialNumber,
  ).toString(16)
  const originalProductionVersion = Number(
    moduleInfo.identity?.version.productionVersion,
  )
  /* eslint-disable no-bitwise */
  const convertedProductionVersion = `${
    (originalProductionVersion >> 8) & 0xff
  }.${originalProductionVersion & 0xff}`
  const convertedModuleInfo = {
    ...moduleInfo,
    identity: moduleInfo.identity && {
      ...moduleInfo.identity,
      serialNumber: moduleInfo.identity?.serialNumber
        ? convertedSerialNumber
        : undefined,
      version: {
        ...moduleInfo.identity.version,
        productionVersion: originalProductionVersion
          ? convertedProductionVersion
          : undefined,
      },
    },
  }
  const Body = ({ ...childrenProps }) => {
    const moduleRecord = useRecordContext<ModuleBaseDto>()

    return (
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
      >
        {children !== undefined &&
          children.map((child, index) => {
            if (child !== false) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={index} md={2} sm={4} xs={12}>
                  <RecordContextProvider value={convertedModuleInfo}>
                    {child}
                  </RecordContextProvider>
                </Grid>
              )
            }
            return undefined
          })}
        <Grid item key="base-module-divider" xs={12}>
          <Divider />
        </Grid>
        <Grid item key="base-module-typeId" md={2} sm={4} xs={12}>
          <SimpleShowLayout
            {...childrenProps}
            className={classes.simpleShowLayoutWithNoVerticalPadding}
          >
            <FunctionField<ModuleBaseDto>
              source="module.typeId"
              render={(record?: ModuleBaseDto) => (
                <div>
                  {translate(
                    `resources.enums.module.deviceModuleTypes.${record?.module?.typeId}`,
                  )}
                </div>
              )}
            />
          </SimpleShowLayout>
        </Grid>
        {moduleRecord.module.address && (
          <Grid item key="base-module-address" md={2} sm={4} xs={12}>
            <SimpleShowLayout
              {...childrenProps}
              className={classes.simpleShowLayoutWithNoVerticalPadding}
            >
              <TextField source="module.address" />
            </SimpleShowLayout>
          </Grid>
        )}
        <Grid item key="base-module-lastRefresh" md={2} sm={4} xs={12}>
          <SimpleShowLayout
            {...childrenProps}
            className={classes.simpleShowLayoutWithNoVerticalPadding}
          >
            <DateField
              source="lastRefresh"
              showTime
              options={{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }}
            />
          </SimpleShowLayout>
        </Grid>
        {moduleRecord.identity?.serialNumber && (
          <Grid item key="base-module-serialNumber" md={2} sm={4} xs={12}>
            <SimpleShowLayout
              {...childrenProps}
              className={classes.simpleShowLayoutWithNoVerticalPadding}
            >
              <TextField source="identity.serialNumber" />
            </SimpleShowLayout>
          </Grid>
        )}
        {moduleRecord.identity?.version?.productionVersion && (
          <Grid item key="base-module-productionVersion" md={2} sm={4} xs={12}>
            <SimpleShowLayout
              {...childrenProps}
              className={classes.simpleShowLayoutWithNoVerticalPadding}
            >
              <TextField source="identity.version.productionVersion" />
            </SimpleShowLayout>
          </Grid>
        )}
        {moduleRecord.identity?.version?.develVersion !== undefined && (
          <Grid item key="base-module-develVersion" md={2} sm={4} xs={12}>
            <SimpleShowLayout
              {...childrenProps}
              className={classes.simpleShowLayoutWithNoVerticalPadding}
            >
              <Labeled source="identity.version.develVersion">
                <FunctionField<ModuleBaseDto>
                  source="identity.version.develVersion"
                  render={(record?: ModuleBaseDto, source?: string) =>
                    get(record, source as string) !== 0
                      ? get(record, source as string)
                      : '-'
                  }
                />
              </Labeled>
            </SimpleShowLayout>
          </Grid>
        )}
        {moduleRecord.identity?.version?.version && (
          <Grid item key="base-module-version" md={2} sm={4} xs={12}>
            <SimpleShowLayout
              {...childrenProps}
              className={classes.simpleShowLayoutWithNoVerticalPadding}
            >
              <TextField source="identity.version.version" />
            </SimpleShowLayout>
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <RecordContextProvider value={convertedModuleInfo}>
      <SimpleShowLayout resource="modules">
        {!disableAliasHeader && (
          <>
            <Box marginBottom={1}>
              <TextField variant="h6" source="module.alias" />
            </Box>
          </>
        )}
        <Body />
      </SimpleShowLayout>
    </RecordContextProvider>
  )
}

const ModuleStatus = (props: ModuleStatusProps) => {
  const { moduleInfo, statusIndex, reversedDirection } = props
  const translate = useTranslate()
  const classes = useStyles()
  const turnstileStatusDataLength = (moduleInfo as TurnstileDto)
    ?.turnstileStatusData?.length
  let convertedStatusIndex = 0
  if (
    turnstileStatusDataLength &&
    turnstileStatusDataLength > 0 &&
    statusIndex !== undefined &&
    statusIndex < turnstileStatusDataLength
  ) {
    convertedStatusIndex = statusIndex
  }
  const turnstileData =
    turnstileStatusDataLength === undefined
      ? (moduleInfo as TurnstileStatusData)
      : (moduleInfo as TurnstileDto)?.turnstileStatusData?.[
          convertedStatusIndex
        ]

  return (
    <ModuleStatusBase {...props}>
      {/* IOStatusDto */}
      {(moduleInfo as IOStatusDto)?.fireAlarms !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <Labeled source="fireAlarms.title">
            <>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translate('resources.modules.fields.fireAlarms.zone')}
                      </TableCell>
                      <TableCell align="center">
                        {translate('resources.modules.fields.fireAlarms.state')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {((moduleInfo as IOStatusDto)?.fireAlarms as boolean[]).map(
                      (value, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                          <TableCell align="center">
                            {(index + 1).toString()}
                          </TableCell>
                          <TableCell align="center">
                            <Checkbox style={{ padding: 0 }} checked={value} />
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          </Labeled>
        </SimpleShowLayout>
      )}
      {(moduleInfo as IOStatusDto)?.allPortsStates !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <Labeled source="allPortsStates.title">
            <>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translate(
                          'resources.modules.fields.allPortsStates.pin',
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {translate(
                          'resources.modules.fields.allPortsStates.state',
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(
                      (moduleInfo as IOStatusDto)?.allPortsStates as PinStates[]
                    ).map((value, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <TableRow key={index}>
                        <TableCell align="center">
                          {(index + 1).toString()}
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            {translate(
                              `resources.enums.module.pinStates.${value}`,
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          </Labeled>
        </SimpleShowLayout>
      )}

      {/* TurnstileDto */}
      {turnstileData?.forwardStatus !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <FunctionField<TurnstileStatusData>
            source="forwardStatus"
            render={(record?: TurnstileStatusData) => (
              <div>
                {translate(
                  `resources.enums.module.turnstileStatuses.${record?.forwardStatus}`,
                )}
              </div>
            )}
          />
        </SimpleShowLayout>
      )}
      {turnstileData?.backwardStatus !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <FunctionField<TurnstileStatusData>
            source="backwardStatus"
            render={(record?: TurnstileStatusData) => (
              <div>
                {translate(
                  `resources.enums.module.turnstileStatuses.${record?.backwardStatus}`,
                )}
              </div>
            )}
          />
        </SimpleShowLayout>
      )}
      {turnstileData?.fireAlarm !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <BooleanField source="fireAlarm" />
        </SimpleShowLayout>
      )}
      {turnstileData?.fireAlarmType !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <FunctionField<TurnstileStatusData>
            source="fireAlarmType"
            render={(record?: TurnstileStatusData) => (
              <div>
                {translate(
                  `resources.enums.module.turnstileInputTypes.${record?.fireAlarmType}`,
                )}
              </div>
            )}
          />
        </SimpleShowLayout>
      )}
      {turnstileData?.powerTurnedOn !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <BooleanField source="powerTurnedOn" />
        </SimpleShowLayout>
      )}
      {turnstileData?.freePassForward !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <BooleanField source="freePassForward" />
        </SimpleShowLayout>
      )}
      {turnstileData?.freePassBackward !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <BooleanField source="freePassBackward" />
        </SimpleShowLayout>
      )}
      {turnstileData?.keyLock !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <FunctionField<TurnstileStatusData>
            source="keyLock"
            render={(record?: TurnstileStatusData) => (
              <div>
                {translate(
                  `resources.enums.module.keyLockStatuses.${record?.keyLock}`,
                )}
              </div>
            )}
          />
        </SimpleShowLayout>
      )}
      {turnstileData?.tamperLock !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <BooleanField source="tamperLock" />
        </SimpleShowLayout>
      )}
      {reversedDirection !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={{ ...turnstileData, reversedDirection } as Record}
        >
          <BooleanField
            source="reversedDirection"
            label="resources.modules.fields.directionReversed"
          />
        </SimpleShowLayout>
      )}
      {turnstileData?.turnstileControllerError !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <FunctionField<TurnstileStatusData>
            source="turnstileControllerError"
            render={(record?: TurnstileStatusData) => (
              <BooleanField
                source="turnstileControllerError"
                record={{
                  ...(record as TurnstileStatusData),
                  turnstileControllerError: !record?.turnstileControllerError,
                }}
              />
            )}
          />
        </SimpleShowLayout>
      )}

      {/* LiteDto */}
      {(moduleInfo as LiteDto)?.ledsColor !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
          record={turnstileData}
        >
          <Labeled source="ledsColor">
            <>
              <Box
                p={1}
                borderRadius={10}
                color={
                  (moduleInfo as LiteDto)?.ledsColor &&
                  Number((moduleInfo as LiteDto).ledsColor) > 0
                    ? getContrastColor(
                        `#${(moduleInfo as LiteDto)?.ledsColor?.toString(16)}`,
                      )
                    : '#ffffff'
                }
                bgcolor={
                  (moduleInfo as LiteDto)?.ledsColor &&
                  Number((moduleInfo as LiteDto).ledsColor) > 0
                    ? `#${(moduleInfo as LiteDto)?.ledsColor?.toString(16)}`
                    : '#000000'
                }
              >
                <Typography>
                  #{(moduleInfo as LiteDto).ledsColor?.toString(16)}
                </Typography>
              </Box>
            </>
          </Labeled>
        </SimpleShowLayout>
      )}

      {/* ReaderDto */}
      {(moduleInfo as ReaderDto)?.isPresenterActive !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="isPresenterActive" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as ReaderDto)?.standbyMode !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<ReaderDto>
            source="standbyMode"
            render={(record?: ReaderDto) => (
              <div>
                {translate(
                  `resources.enums.module.stanbyModeNumeric.${record?.standbyMode}`,
                )}
              </div>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as ReaderDto)?.sensorId !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<ReaderDto>
            source="sensorId"
            render={(record?: ReaderDto) => (
              <Typography>{record?.sensorId?.toString(16)}</Typography>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as ReaderDto)?.errors?.scannerError !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="errors.scannerError" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as ReaderDto)?.errors?.mifareError !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="errors.mifareError" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as ReaderDto)?.errors?.flashError !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="errors.flashError" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as ReaderDto)?.errors?.sdCardError !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="errors.sdCardError" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as ReaderDto)?.errors?.licenceError !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="errors.licenceError" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as ReaderDto)?.errors?.scannerFirmwareError !==
        undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="errors.scannerFirmwareError" />
        </SimpleShowLayout>
      )}

      {/* PresenterDto */}
      {(moduleInfo as PresenterDto)?.contentType !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<PresenterDto>
            source="contentType"
            render={(record?: PresenterDto) => (
              <div>
                {translate(
                  `resources.enums.module.contentTypes.${record?.contentType}`,
                )}
              </div>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as PresenterDto)?.contentNumber !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <TextField source="contentNumber" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as PresenterDto)?.contrast !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<PresenterDto>
            source="contrast"
            render={() => (
              <Grid container alignItems="baseline">
                <TextField source="contrast" />
                <Typography>%</Typography>
              </Grid>
            )}
          />
        </SimpleShowLayout>
      )}

      {/* SensorDto */}
      {(moduleInfo as SensorDto)?.temperature !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<SensorDto>
            source="temperature"
            render={() => (
              <Grid container alignItems="baseline">
                <TextField source="temperature" />
                <Typography>&deg;C</Typography>
              </Grid>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as SensorDto)?.humidity !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<SensorDto>
            source="humidity"
            render={() => (
              <Grid container alignItems="baseline">
                <TextField source="humidity" />
                <Typography>%</Typography>
              </Grid>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as SensorDto)?.move !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<SensorDto>
            source="move"
            render={(record?: SensorDto) => (
              <div>
                {translate(
                  `resources.enums.module.moveSensorStatuses.${record?.move}`,
                )}
              </div>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as SensorDto)?.light !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<SensorDto>
            source="light"
            render={(record?: SensorDto) => (
              <Typography>
                {translate(
                  `resources.enums.module.lightLevels.${record?.light}`,
                )}
              </Typography>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as SensorDto)?.fan !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<SensorDto>
            source="fan"
            render={(record?: SensorDto) => (
              <Typography>
                {translate(
                  `resources.enums.module.fanHeaterStatuses.${record?.fan}`,
                )}
              </Typography>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as SensorDto)?.heater !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<SensorDto>
            source="heater"
            render={(record?: SensorDto) => (
              <Typography>
                {translate(
                  `resources.enums.module.fanHeaterStatuses.${record?.heater}`,
                )}
              </Typography>
            )}
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as SensorDto)?.moveSensorTimeout !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<SensorDto>
            source="moveSensorTimeout"
            render={() => (
              <Grid container alignItems="baseline">
                <TextField source="moveSensorTimeout" />
                <Typography>s</Typography>
              </Grid>
            )}
          />
        </SimpleShowLayout>
      )}

      {/* OfflineServerModuleDto */}
      {(moduleInfo as OfflineServerModuleDto)?.replicationApplierStatus !==
        undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="replicationApplierStatus" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as OfflineServerModuleDto)?.replicationConnectionStatus !==
        undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <BooleanField source="replicationConnectionStatus" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as OfflineServerModuleDto)?.queueLength !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <TextField source="queueLength" />
        </SimpleShowLayout>
      )}

      {/* LCDPlusDto */}
      {(moduleInfo as LCDPlusDto)?.fanRpm !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <TextField source="fanRpm" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as LCDPlusDto)?.voltage !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <FunctionField<LCDPlusDto>
            source="voltage"
            render={(record?: LCDPlusDto, source?: string) =>
              `${record?.[source as string]} V`
            }
          />
        </SimpleShowLayout>
      )}
      {(moduleInfo as LCDPlusDto)?.turnstileErrorNumber !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <TextField source="turnstileErrorNumber" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as LCDPlusDto)?.turnstileErrorSource !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <TextField source="turnstileErrorSource" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as LCDPlusDto)?.contentLedNumber !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <TextField source="contentLedNumber" />
        </SimpleShowLayout>
      )}
      {(moduleInfo as LCDPlusDto)?.contentBuzzerNumber !== undefined && (
        <SimpleShowLayout
          resource="modules"
          className={classes.simpleShowLayoutWithNoVerticalPadding}
        >
          <TextField source="contentBuzzerNumber" />
        </SimpleShowLayout>
      )}
    </ModuleStatusBase>
  )
}

const MultiModuleStatus = (props: MultiModuleStatusProps) => {
  const classes = useStyles()
  const { moduleInfo, statusIndex, reversedDirection } = props

  return (
    <>
      {moduleInfo.map((record) => (
        <Accordion key={record.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={
              CheckModulesError([record])
                ? classes.terminatedModuleHeader
                : classes.defaultModuleHeader
            }
          >
            <RecordContextProvider value={record}>
              <TextField variant="h6" source="module.alias" />
            </RecordContextProvider>
          </AccordionSummary>
          <AccordionDetails
            className={
              CheckModulesError([record])
                ? classes.terminatedModuleDetails
                : classes.defaultModuleDetails
            }
          >
            <ModuleStatus
              moduleInfo={record}
              statusIndex={statusIndex}
              reversedDirection={reversedDirection}
              disableAliasHeader
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

const TerminalStatus = ({ ...props }) => {
  const { deviceStatus } = props
  const { printerStatus } = deviceStatus
  const classes = useStyles()
  const translate = useTranslate()

  const paperTooltip = () => {
    if (
      !(printerStatus as PrinterDto)?.paperNotPresent &&
      !(printerStatus as PrinterDto)?.lowPaper
    )
      return translate('resources.enums.printerPaperStatuses.ok')
    if ((printerStatus as PrinterDto)?.paperNotPresent)
      return translate('resources.enums.printerPaperStatuses.paperNotPresent')
    return translate('resources.enums.printerPaperStatuses.lowPaper')
  }

  return (
    <Accordion key="printer">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={
          CheckPrinterError(printerStatus)
            ? classes.terminatedModuleHeader
            : classes.defaultModuleHeader
        }
      >
        <Typography variant="h6">Printer</Typography>
      </AccordionSummary>
      <AccordionDetails
        className={
          CheckPrinterError(printerStatus)
            ? classes.terminatedModuleDetails
            : classes.defaultModuleDetails
        }
      >
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="flex-start"
        >
          {(printerStatus as PrinterDto)?.paperNotPresent !== undefined &&
            (printerStatus as PrinterDto)?.lowPaper !== undefined && (
              <SimpleShowLayout
                resource="modules"
                className={classes.simpleShowLayoutWithNoVerticalPadding}
              >
                <FunctionField
                  source="paperStatus"
                  render={() => (
                    <Tooltip title={paperTooltip()}>
                      <div className={classes.iconContainer}>
                        <>
                          <PaperRoll
                            style={{
                              fill: 'transparent',
                              alignSelf: 'center',
                              opacity:
                                printerStatus?.paperNotPresent ||
                                printerStatus?.lowPaper
                                  ? 0.5
                                  : 1,
                            }}
                            className={classes.statusIcon}
                          />
                          {printerStatus?.paperNotPresent && (
                            <Close
                              fontSize="large"
                              className={classes.crossIcon}
                            />
                          )}
                          {!printerStatus?.paperNotPresent &&
                            printerStatus?.lowPaper && (
                              <PriorityHigh
                                fontSize="large"
                                className={classes.exclamationIcon}
                              />
                            )}
                        </>
                      </div>
                    </Tooltip>
                  )}
                />
              </SimpleShowLayout>
            )}
          {(printerStatus as PrinterDto)?.autocutterCoverOpened !==
            undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="autocutterCoverOpened" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.autocutterError !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="autocutterError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.blackMarkNotOverSensor !==
            undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="blackMarkNotOverSensor" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.blackMarkSearchError !==
            undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="blackMarkSearchError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.coverOpened !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="coverOpened" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.dragPaperMotorOn !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="dragPaperMotorOn" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.eepromError !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="eepromError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.emiterError !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="emiterError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.emitterMotorOff !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="emitterMotorOff" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.ffkeyPressed !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="ffkeyPressed" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.headTemperatureError !==
            undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="headTemperatureError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.lfKeyPressed !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="lfKeyPressed" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.noAckCommandError !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="noAckCommandError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.paperJam !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="paperJam" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.powerSupplyVoltageError !==
            undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="powerSupplyVoltageError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.printingHeadUpError !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="printingHeadUpError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.ramError !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="ramError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.rs232ComError !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="rs232ComError" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.spooling !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="spooling" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.ticketPresent !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="ticketPresent" />
            </SimpleShowLayout>
          )}
          {(printerStatus as PrinterDto)?.virtuallPaperEnd !== undefined && (
            <SimpleShowLayout
              resource="modules"
              className={classes.simpleShowLayoutWithNoVerticalPadding}
              record={printerStatus}
            >
              <BooleanField source="virtuallPaperEnd" />
            </SimpleShowLayout>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

const getOtherCustomNonModuleProperties = (rawJsonStatus: string) => {
  let otherProperties
  try {
    const jsonStatus = camelcaseKeys(JSON.parse(rawJsonStatus), { deep: true })
    if (jsonStatus !== null || jsonStatus !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { modules, ...others } = jsonStatus
      otherProperties = others
    }
  } catch {
    otherProperties = undefined
  }
  return otherProperties
}

const DeviceStatusesTab = (props: StatusTabProps) => {
  const {
    jsonStatus: rawJsonStatus,
    statusIndex,
    reversedDirection,
    deviceType,
  } = props
  const mappedModules = getModulesFromJsonStatus(rawJsonStatus)
  const otherProperties = getOtherCustomNonModuleProperties(rawJsonStatus)

  return (
    <div>
      {deviceType === DeviceTypes.TERMINALS &&
        otherProperties?.printerStatus && (
          <TerminalStatus deviceStatus={otherProperties} />
        )}
      <MultiModuleStatus
        moduleInfo={mappedModules
          .filter((m) => m != null)
          .map((m) => m as ModuleBaseDto)}
        statusIndex={statusIndex}
        reversedDirection={reversedDirection}
      />
    </div>
  )
}

const DeviceStatusesPreview = (props: StatusTabProps) => {
  const classes = useStyles()
  const { jsonStatus: rawJsonStatus, deviceType } = props
  const mappedModules = getModulesFromJsonStatus(rawJsonStatus)
    .filter((m) => m != null)
    .map((m) => m as ModuleBaseDto)
  const otherProperties = getOtherCustomNonModuleProperties(rawJsonStatus)

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      {deviceType === DeviceTypes.TERMINALS && otherProperties?.printerStatus && (
        <Grid item key="Printer" style={{ margin: 3 }}>
          <Box
            p={1}
            className={
              CheckPrinterError(otherProperties?.printerStatus)
                ? classes.previewedDeviceError
                : classes.previewedDeviceOk
            }
          >
            Printer
          </Box>
        </Grid>
      )}
      {mappedModules.map((module, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item key={index} style={{ margin: 3 }}>
          <Box
            p={1}
            className={
              CheckModulesError([module])
                ? classes.previewedDeviceError
                : classes.previewedDeviceOk
            }
          >
            <TextField source="module.alias" record={module} />
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

const CheckIfAnyModuleHasError = (
  rawJsonStatus: string,
  deviceType: DeviceTypes,
) => {
  const mappedModules = getModulesFromJsonStatus(rawJsonStatus)
    .filter((m) => m != null)
    .map((m) => m as ModuleBaseDto)
  const otherProperties = getOtherCustomNonModuleProperties(rawJsonStatus)

  let error = CheckModulesError(mappedModules)
  if (!error) {
    // Check for error in custom device properties
    switch (deviceType) {
      case DeviceTypes.TERMINALS:
        error = CheckPrinterError(otherProperties?.printerStatus)
        break
      default:
        break
    }
  }
  return error
}

interface ModuleBaseStatusProps {
  readonly moduleInfo: ModuleBaseDto
  readonly disableAliasHeader?: boolean
  readonly children?: ReactNode[]
  // readonly statusIndex?: number
}

interface ModuleStatusProps {
  readonly moduleInfo: ModuleBaseDto
  readonly disableAliasHeader?: boolean
  readonly statusIndex?: number
  readonly reversedDirection?: boolean
}

interface MultiModuleStatusProps {
  readonly moduleInfo: ModuleBaseDto[]
  readonly statusIndex?: number
  readonly reversedDirection?: boolean
}

interface StatusTabProps {
  readonly jsonStatus: string
  // eslint-disable-next-line react/no-unused-prop-types
  readonly statusIndex?: number
  // eslint-disable-next-line react/no-unused-prop-types
  readonly reversedDirection?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  readonly deviceType: DeviceTypes
}

export {
  CheckLastRefreshError,
  ModuleStatus,
  MultiModuleStatus,
  DeviceStatusesTab,
  DeviceStatusesPreview,
  CheckIfAnyModuleHasError,
}
