import React from 'react'
import {
  ListProps,
  Pagination,
  TextField,
  TextInput,
  Filter,
  FunctionField,
  useTranslate,
  Datagrid,
  SelectInput,
} from 'react-admin'
import FilterProps from '../../common/FilterProps'
import List from '../../common/customized-ra-components/List'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import { DynamicPropertyDataTypes } from '../../../core/enum/DynamicPropertyDataTypes'
import { DefinitionOwnerTypes } from '../../../core/enum/DefinitionOwnerTypes'

const DynamicPropertyDefinitionsFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="key" alwaysOn />
    <SelectInput
      source="type"
      choices={Object.entries(DynamicPropertyDataTypes).map(([value]) => ({
        id: value,
        name: `resources.enums.DynamicPropertyDataTypes.${value}`,
      }))}
    />
    <SelectInput
      source="definitionOwnerType"
      choices={Object.entries(DefinitionOwnerTypes).map(([value]) => ({
        id: value,
        name: `resources.enums.DefinitionOwnerTypes.${value}`,
      }))}
    />
  </Filter>
)

export const DynamicPropertyDefinitionsList = (props: ListProps) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<DynamicPropertyDefinitionsFilters />}
      bulkActionButtons={false}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <Datagrid
        size="small"
        rowClick={
          hasAuthority(Authority.EDIT_DYNAMIC_PROPERTY_DEFINITIONS)
            ? 'edit'
            : undefined
        }
      >
        <TextField source="id" label="ID" />
        <FunctionField
          source="definitionOwnerType"
          render={(record) =>
            translate(
              `resources.enums.DefinitionOwnerTypes.${record?.definitionOwnerType}`,
            )
          }
          sortable={false}
        />
        <TextField source="key" />
        <FunctionField
          source="type"
          render={(record) =>
            translate(
              `resources.enums.DynamicPropertyDataTypes.${record?.type}`,
            )
          }
        />
      </Datagrid>
    </List>
  )
}
