import { createStyles, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useNotify,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { TerminalDto } from '../../../../core/dto/device/terminals/terminal.dto'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: '2px',
    },
  }),
)

export const TerminalForceReportStateButton = () => {
  const { record: terminal, loading } = useShowContext<TerminalDto>()
  const classes = useStyles()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const notify = useNotify()

  const handleMutate = async () => {
    setConfirmDialogOpen(false)
    await mutate(
      {
        type: sanitizeFetchType('forceReportState'),
        resource: ResourceName.TERMINALS,
        payload: {
          id: terminal?.id,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
  }

  const handleConfirm = () => {
    setConfirmDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        confirm={handleMutate}
        source="forceReportState"
        cancel={() => setConfirmDialogOpen(false)}
        action="set"
        resource={ResourceName.TERMINALS}
      />
      <Button
        variant="contained"
        onClick={handleConfirm}
        color="primary"
        className={classes.button}
        label="resources.terminals.actions.forceReportState"
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.TERMINALS_FORCE_REPORT_STATE) ||
          loading ||
          mutationLoading
        }
      />
    </>
  )
}
