import React, { ReactElement } from 'react'
import {
  useTranslate,
  MenuItemLink,
  ReduxState,
  ResourceDefinition,
  getResources,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { ResourceName } from '../../core/ResourceName'

export interface MenuItemProps {
  resource: ResourceName
  icon: ReactElement
  to?: string
  label?: string
}

const MenuItem = (props: MenuItemProps) => {
  const { resource, icon, to, label } = props
  const translate = useTranslate()

  const availableResources = new Set(
    useSelector<ReduxState, ResourceDefinition[]>(getResources).map(
      (r) => r.name as ResourceName,
    ),
  )

  return (
    <>
      {availableResources.has(resource) && (
        <MenuItemLink
          to={to || `/${resource}`}
          primaryText={translate(label || `resources.${resource}.name`)}
          leftIcon={icon}
        />
      )}
    </>
  )
}

export default MenuItem
