import React from 'react'
import apiErrorHandlerSaga from './core/common/api-error-handler.saga'
import apiErrorModalReducer from './core/common/api-error-modal.reducer'
import { dashboardReducer } from './core/common/dashboard.reducer'
import themeReducer from './core/common/theme.reducer'
import { providersFactory } from './core/providers.factory'
import { Admin } from './lib/@react-admin/ra-enterprise'
import routes from './main/common/routes'
import { Dashboard } from './main/Dashboard'
import { AppLayout } from './main/layout/AppLayout'
import { generateResourcesBasedOnPermissions } from './main/Resources'

const App = () => {
  const { authProvider, dataProvider, i18nProvider } = providersFactory(
    process.env.NODE_ENV,
  )
  return (
    <Admin
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      customReducers={{
        theme: themeReducer,
        dashboard: dashboardReducer,
        apiErrorModal: apiErrorModalReducer,
      }}
      customSagas={[apiErrorHandlerSaga]}
      customRoutes={routes}
      layout={AppLayout}
    >
      {generateResourcesBasedOnPermissions}
    </Admin>
  )
}

export default App
