const mapping: Record<string, string> = {
  id: 'a=>a.Id',
  name: 'a=>a.Name',
  bonus: 'a=>a.Bonus',
  entranceId: 'a=>a.EntranceId',
  modifiedAt: 'a=>a.ModifiedAt',
  createdAt: 'a=>a.CreatedAt',
}

export const mapSortAccessParam = (key: string) => mapping[key]
