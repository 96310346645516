import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import FilterProps from '../../common/FilterProps'
import { Authority } from '../../../core/auth/Authority'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import {
  CheckIfAnyModuleHasError,
  CheckLastRefreshError,
  DeviceStatusesPreview,
} from '../common/modules/ModuleStatus'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import List from '../../common/customized-ra-components/List'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'

const OfflineServerFilters = (props: FilterProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          label="resources.offline-servers.fields.categoryId"
          source="categoryIdWithDescendants"
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

const OfflineServerGridExpand = ({ ...props }) => {
  const { record } = props
  return (
    <DeviceStatusesPreview
      jsonStatus={record.jsonStatus}
      deviceType={DeviceTypes.OFFLINE_SERVER}
    />
  )
}

export const OfflineServerList = ({ ...props }: ListProps) => {
  const theme = useTheme()
  const postRowStyle = (record: any) => {
    const detectorError = CheckLastRefreshError(record.lastRefresh)
    let detectorWarning = false
    if (!detectorError) {
      // Check for modules error
      detectorWarning = CheckIfAnyModuleHasError(
        record.jsonStatus,
        DeviceTypes.OFFLINE_SERVER,
      )
    }
    if (detectorError) {
      return {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      }
    }
    if (detectorWarning) {
      return {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
      }
    }
    return {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    }
  }

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
      filters={<OfflineServerFilters />}
      bulkActionButtons={false}
    >
      <Datagrid
        size="small"
        rowClick={(id) => `${ResourceName.OFFLINE_SERVERS}/${id}/show`}
        hasBulkActions={false}
        rowStyle={postRowStyle}
        isRowExpandable={(record) =>
          record?.jsonStatus !== undefined && record?.jsonStatus !== null
        }
        expand={<OfflineServerGridExpand />}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <TextField source="address" />
      </Datagrid>
    </List>
  )
}
