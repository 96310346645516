import { DataProvider } from 'react-admin'
import { ObjectDto } from '../../dto/object.dto'

const fakeObjects: ObjectDto[] = [
  {
    id: 0,
    name: 'Stadion 0',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 1,
    name: 'Stadion 1',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 2,
    name: 'Stadion 2',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 3,
    name: 'Stadion 3',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 4,
    name: 'Stadion 4',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 5,
    name: 'Stadion 5',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 6,
    name: 'Stadion 6',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 7,
    name: 'Stadion 7',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 8,
    name: 'Stadion 8',
    tribunesIds: [],
    eventsIds: [],
  },
  {
    id: 9,
    name: 'Stadion 9',
    tribunesIds: [],
    eventsIds: [],
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeObjects, total: fakeObjects.length }),
} as DataProvider

export default provider
