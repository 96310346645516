import { mapping as deviceMapping } from '../common/device-base/device-base-sort.mapper'

export const mapping: Record<string, string> = {
  ...deviceMapping,
  address: 'p=>p.Address',
}

const mapSortOfflineServerParam = (key: string) => mapping[key]

export default mapSortOfflineServerParam
