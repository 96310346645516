import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import {
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { Form } from 'react-final-form'
import { EventDto } from '../../../core/dto/event/event.dto'
import { ResourceName } from '../../../core/ResourceName'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import Button from '../../common/customized-mui-components/Button'
import DraggableComponent from '../../common/DraggableComponent'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

const useStyles = makeStyles(() =>
  createStyles({
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    bulkProgressTitle: {
      paddingLeft: '10px',
      width: '100%',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
    formToolbar: {
      backgroundColor: 'inherit',
      padding: 0,
      margin: 0,
    },
    dialogContent: {
      padding: '8px',
    },
  }),
)

export const PalmDeviceIdDialog = ({
  open,
  close,
  deviceId,
  setDeviceId,
  handleLock,
}: {
  open: boolean
  close: () => void
  deviceId: string
  setDeviceId: (value: string) => void
  handleLock: () => void
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()

  const handleClose = () => {
    setDeviceId('')
    setError(false)
    setErrorText('')
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleLock}
        source="deviceId"
        action="set"
        resource={ResourceName.PALMS}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.palms.dialogs.deviceId.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <TextField
          variant="outlined"
          value={deviceId}
          onChange={(e) => setDeviceId(e.target.value)}
          fullWidth
          error={error}
          helperText={errorText}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export const PalmLockDialog = ({
  open,
  close,
  disabledId,
  setDisabledId,
  handleLock,
  asRecurringJob,
}: {
  open: boolean
  close: () => void
  disabledId: number
  setDisabledId: (value: number) => void
  handleLock: (recurringJobName?: string, cronExpression?: string) => void
  asRecurringJob?: boolean
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    setDisabledId(0)
    setError(false)
    setErrorText('')
    setConfirmOpen(false)
    close()
  }

  const handleConfirm = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (asRecurringJob) handleLock(recurringJobName, cronExpression)
    else handleLock()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleConfirm}
        source="lock"
        action="set"
        resource={ResourceName.PALMS}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.palms.dialogs.lock.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Form onSubmit={() => undefined}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <FilteredReferenceInput
                source="disabled"
                defaultValue={null}
                reference={ResourceName.VERIFIER_DEVICE_DISABLED}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={smallScreen ? 5 : 15}
                allowEmpty
                filterSource="name"
                selectWithPaginationInputProps={{
                  optionText: 'name',
                  showFilter: true,
                  error,
                  helperText: errorText,
                }}
                fullWidth
                onChange={(e) => setDisabledId(e.target.value)}
                value={disabledId}
                resource={ResourceName.PALMS}
              />
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

const validateAttachEvents = (values: EventDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.id) {
    errors.id = 'ra.validation.required'
  }
  return errors
}

export const PalmEventsRecurringDialog = ({
  open,
  close,
  setSelectedEventId,
  handleAttachment,
  method,
}: {
  open: boolean
  close: () => void
  setSelectedEventId: (value: number | undefined) => void
  handleAttachment: (recurringJobName?: string, cronExpression?: string) => void
  method: 'attach' | 'detach'
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const Actions = (props: ToolbarProps) => (
    <Toolbar {...props} className={classes.formToolbar}>
      <SaveButton
        variant="contained"
        size="small"
        className={classes.actionsButton}
        onSave={(values) => {
          setSelectedEventId((values as EventDto)?.id)
          setConfirmOpen(true)
        }}
        onSubmit={() => setConfirmOpen(true)}
      />
      <Button
        variant="contained"
        onClick={handleClose}
        label={translate('const.cancel')}
        useSmallVersionBreakpoint={false}
        className={classes.actionsButton}
      />
    </Toolbar>
  )

  useEffect(() => {
    if (!open) {
      setSelectedEventId(undefined)
      setConfirmOpen(false)
    }
  }, [open, setSelectedEventId, setConfirmOpen])

  const handleConfirm = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    handleAttachment(recurringJobName, cronExpression)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => {
          setSelectedEventId(undefined)
          setConfirmOpen(false)
        }}
        confirm={handleConfirm}
        source={`${method}Events`}
        action="set"
        resource={ResourceName.PALMS}
        asRecurringJob
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(`resources.palms.dialogs.${method}Events.title`)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <SimpleForm toolbar={<Actions />} validate={validateAttachEvents}>
          <FilteredReferenceInput
            label={`resources.palms.dialogs.${method}Events.fields.eventId`}
            source="id"
            reference={ResourceName.EVENTS}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="search"
            required
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}
