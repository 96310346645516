import { createStyles, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import { DepositorDto } from '../../../../core/dto/device/depositor/depositor.dto'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: '2px',
    },
  }),
)

export const DepositorRemoveCredentialsButton = () => {
  const { record: depositor, loading } = useShowContext<DepositorDto>()
  const classes = useStyles()
  const [
    removeTSCanCredentialsDialogOpen,
    setRemoveTSCanCredentialsDialogOpen,
  ] = useState(false)
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()
  const notify = useNotify()

  const handleRemoveTSCanCredentials = async () => {
    setRemoveTSCanCredentialsDialogOpen(false)
    await mutate(
      {
        type: sanitizeFetchType('removeCredentials'),
        resource: ResourceName.DEPOSITORS,
        payload: {
          depositorId: depositor?.id,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
    refresh()
  }

  const handleRemoveTSCanCredentialsDialog = () => {
    setRemoveTSCanCredentialsDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={removeTSCanCredentialsDialogOpen}
        confirm={handleRemoveTSCanCredentials}
        source="setCredentials"
        cancel={() => setRemoveTSCanCredentialsDialogOpen(false)}
        action="remove"
        resource={ResourceName.DEPOSITORS}
      />
      <Button
        variant="contained"
        onClick={handleRemoveTSCanCredentialsDialog}
        color="primary"
        className={classes.button}
        label="resources.depositors.actions.removeCredentials"
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.DEPOSITORS_MANAGE_CREDENTIALS_BUTTON) ||
          loading ||
          mutationLoading
        }
      />
    </>
  )
}
