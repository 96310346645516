import { DataProvider } from 'react-admin'
import { VerifierDeviceBaseDto } from '../../dto/device/common/verifier-device-base/verifier-device-base.dto'

const fakeVerifierDevices: VerifierDeviceBaseDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeVerifierDevices,
      total: fakeVerifierDevices.length,
    }),
} as DataProvider

export default provider
