import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { DEPOSITORS_URL } from '../../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { DepositorEntrancesDto } from '../../dto/device/depositor/depositor-entrances.dto'
import { DepositorEventsDto } from '../../dto/device/depositor/depositor-events.dto'
import { SetDepositorCredentialsDto } from '../../dto/device/depositor/depositor-set-credentials.dto'
import { RemoveDepositorCredentialsDto } from '../../dto/device/depositor/depositor-remove-credentials.dto'
import { DepositorDto } from '../../dto/device/depositor/depositor.dto'
import { DeviceDirection } from '../../enum/DeviceDirection'
import { Multiplicities } from '../../enum/Multiplicities'
import { getDeviceCategoryChildren } from '../devices.utils'
import filterMapper from './depositor-filter.mapper'
import mapSortDepositorParam from './depositor-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<DepositorDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortDepositorParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DepositorDto[]>(
      `${DEPOSITORS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DepositorDto>> => {
    const data = await get<DepositorDto>(DEPOSITORS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<DepositorDto>> => {
    const data = await get<DepositorDto[]>(
      DEPOSITORS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateDepositorRequest>,
  ): Promise<CreateResult<DepositorDto>> => {
    const engine = await post<CreateDepositorRequest[], DepositorDto[]>(
      DEPOSITORS_URL,
      [data],
    )
    return {
      data: engine[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<DepositorDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      DepositorDto
    >(DEPOSITORS_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
      isWatched:
        data.isWatched !== previousData.isWatched ? data.isWatched : undefined,
      address: data.address !== previousData.address ? data.address : undefined,
      direction:
        data.direction !== previousData.direction ? data.direction : undefined,
      categoryId:
        data.categoryId !== previousData.categoryId
          ? data.categoryId
          : undefined,
      cameraId:
        data.cameraId !== previousData.cameraId ? data.cameraId : undefined,
      onlineInfixCeck:
        data.onlineInfixCeck !== previousData.onlineInfixCeck
          ? data.onlineInfixCeck
          : undefined,
      multiplicity:
        data.multiplicity !== previousData.multiplicity
          ? data.multiplicity
          : undefined,
      supervisorId:
        data.supervisorId !== previousData.supervisorId
          ? data.supervisorId
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  attachEvents: async (
    resource: string,
    params: DepositorEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<DepositorEventsDto>> => {
    const engineEvents = await put<DepositorEventsDto, DepositorEventsDto>(
      `${DEPOSITORS_URL}/AttachEvents`,
      params,
    )
    return {
      data: engineEvents,
    }
  },
  detachEvents: async (
    resource: string,
    params: DepositorEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<DepositorEventsDto>> => {
    const engineEvents = await put<DepositorEventsDto, DepositorEventsDto>(
      `${DEPOSITORS_URL}/DetachEvents`,
      params,
    )
    return {
      data: engineEvents,
    }
  },
  attachEntrances: async (
    resource: string,
    params: DepositorEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<DepositorEntrancesDto>> => {
    const engineEntrances = await put<
      DepositorEntrancesDto,
      DepositorEntrancesDto
    >(`${DEPOSITORS_URL}/AttachEntrances`, params)
    return {
      data: engineEntrances,
    }
  },
  detachEntrances: async (
    resource: string,
    params: DepositorEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<DepositorEntrancesDto>> => {
    const engineEntrances = await put<
      DepositorEntrancesDto,
      DepositorEntrancesDto
    >(`${DEPOSITORS_URL}/DetachEntrances`, params)
    return {
      data: engineEntrances,
    }
  },
  setCredentials: async (
    resource: string,
    params: SetDepositorCredentialsDto,
  ): Promise<DataProviderExtensionResult<SetDepositorCredentialsDto>> => {
    const setTSCanCredentials = await put<
      SetDepositorCredentialsDto,
      SetDepositorCredentialsDto
    >(`${DEPOSITORS_URL}/SetCredentials`, params)
    return {
      data: setTSCanCredentials,
    }
  },
  removeCredentials: async (
    resource: string,
    params: RemoveDepositorCredentialsDto,
  ): Promise<DataProviderExtensionResult<RemoveDepositorCredentialsDto>> => {
    const removeTSCanCredentials = await put<
      RemoveDepositorCredentialsDto,
      RemoveDepositorCredentialsDto
    >(`${DEPOSITORS_URL}/RemoveCredentials`, params)
    return {
      data: removeTSCanCredentials,
    }
  },
} as DetectorDataProvider

interface DetectorDataProvider extends DataProvider {
  attachEvents: (
    resource: string,
    params: DepositorEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<DepositorEventsDto>>

  detachEvents: (
    resource: string,
    params: DepositorEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<DepositorEventsDto>>

  attachEntrances: (
    resource: string,
    params: DepositorEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<DepositorEntrancesDto>>

  detachEntrances: (
    resource: string,
    params: DepositorEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<DepositorEntrancesDto>>

  setCredentials: (
    resource: string,
    params: SetDepositorCredentialsDto,
  ) => Promise<DataProviderExtensionResult<SetDepositorCredentialsDto>>

  removeCredentials: (
    resource: string,
    params: RemoveDepositorCredentialsDto,
  ) => Promise<DataProviderExtensionResult<RemoveDepositorCredentialsDto>>
}

export interface CreateDepositorRequest {
  readonly name: string
  readonly isWatched: boolean
  readonly address: string
  readonly direction: DeviceDirection
  readonly categoryId: number
  readonly disabled: string
  readonly jsonStatus: string
  readonly onlineInfixCeck: boolean
  readonly multiplicity: Multiplicities
  readonly supervisorId: number
}

export default provider
