import {
  Box,
  Chip,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { ArrowBack, Visibility } from '@material-ui/icons'
import React, { useEffect } from 'react'
import {
  BooleanField,
  BooleanInput,
  DateField,
  EditButton,
  FilterButton,
  FunctionField,
  GET_ONE,
  List,
  Pagination,
  Record,
  ReferenceField,
  ResourceContextProvider,
  sanitizeFetchType,
  SelectInput,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useQuery,
  useQueryWithStore,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { useHistory, useLocation } from 'react-router'
import { Authority } from '../../core/auth/Authority'
import { BookingDto } from '../../core/dto/booking.dto'
import { CustomerDto } from '../../core/dto/customer.dto'
import { TicketDto } from '../../core/dto/ticket.dto'
import { BookingType } from '../../core/enum/BookingType'
import { TicketType } from '../../core/enum/TicketType'
import { ResourceName } from '../../core/ResourceName'
import { RowForm } from '../../lib/@react-admin/ra-editable-datagrid'
import Button from '../common/customized-mui-components/Button'
import EditableDatagrid from '../common/customized-ra-components/EditableDatagrid'
import { MappedChipField } from '../common/MappedChipField'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { LogList } from '../logs/LogList'
import { AccessList } from './booking-show/AccessList'
import { AccessShow } from './booking-show/AccessShow'
import { ToggleBookingAccessButton } from './booking-show/ToggleBookingAccessButton'
import { ToggleBookingBlockadeButton } from './booking-show/ToggleBookingBlockadeButton'
import { EventDto } from '../../core/dto/event/event.dto'
import { BlockDto } from '../../core/dto/block.dto'
import { TribuneDto } from '../../core/dto/tribune.dto'
import { ObjectDto } from '../../core/dto/object.dto'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      letterSpacing: theme.spacing(0.2),
    },
    inactiveTicketCode: {
      color: theme.palette.error.main,
      textDecoration: 'line-through',
      textDecorationColor: theme.palette.error.main,
    },
    editButton: {
      margin: '2px',
    },
  }),
)

const BookingTicketCreate = ({ ...props }) => {
  const { total, ...rest } = props
  const { record: booking } = useShowContext()
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const validateTicketCreate = (values: TicketDto) => {
    const errors: { [n: string]: string } = {}
    if (!values.ticketCode) {
      errors.ticketCode = 'ra.validation.required'
    }
    return errors
  }

  return (
    <RowForm
      {...rest}
      initialValues={{
        bookingId: booking?.id,
        ticketType: TicketType.K,
      }}
      validate={validateTicketCreate}
    >
      {total && <TextField source="id" />}
      <TextInput
        source="ticketCode"
        variant={!smallScreen ? 'standard' : undefined}
      />
      <SelectInput
        variant={!smallScreen ? 'standard' : undefined}
        source="ticketType"
        choices={Object.keys(TicketType).map((key: string) => ({
          id: key,
          name: translate(`resources.enums.ticketType.${key}`),
        }))}
      />
      <BooleanInput source="active" label=" " />
      {!smallScreen && <DateField source="createdAt" />}
    </RowForm>
  )
}

const BookingTicketEdit = ({ ...props }) => {
  const { total, ...rest } = props
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { data: settings } = useQuery({
    type: 'getApplicationSettings',
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  const validateTicketEdit = (values: Record) => {
    const errors: { [n: string]: string } = {}
    if (!values.ticketCode) {
      errors.ticketCode = 'ra.validation.required'
    }
    if (settings?.patchTicketAuditComment && !values.auditComment) {
      errors.auditComment = 'ra.validation.required'
    }
    return errors
  }

  return (
    <RowForm {...rest} validate={validateTicketEdit}>
      {total && <TextField source="id" />}
      <TextInput
        source="ticketCode"
        label=" "
        variant={!smallScreen ? 'standard' : undefined}
      />
      <SelectInput
        label=" "
        variant={!smallScreen ? 'standard' : undefined}
        source="ticketType"
        choices={Object.keys(TicketType).map((key: string) => ({
          id: key,
          name: translate(`resources.enums.ticketType.${key}`),
        }))}
      />
      <BooleanInput source="active" label=" " />
      {!smallScreen && (
        <DateField
          source="createdAt"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
      )}
      {settings?.patchTicketAuditComment && (
        <TextInput
          source="auditComment"
          label="resources.audit-comment.fields.audit-comment"
          variant={!smallScreen ? 'standard' : undefined}
        />
      )}
    </RowForm>
  )
}

const BookingTicketsGrid = ({ ...props }) => {
  const { total } = props
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <EditableDatagrid
      {...props}
      padding={smallScreen ? 'checkbox' : 'default'}
      actions={hasAuthority(Authority.EDIT_TICKETS) ? undefined : false}
      editForm={<BookingTicketEdit total={total} />}
      createForm={
        hasAuthority(Authority.EDIT_TICKETS) &&
        hasAuthority(Authority.CREATE_TICKETS) ? (
          <BookingTicketCreate total={total} />
        ) : undefined
      }
      hasBulkActions={false}
      defaultTitle={null}
      noDelete
    >
      <TextField label="ID" source="id" />
      <FunctionField<TicketDto>
        source="ticketCode"
        render={(record?: TicketDto) =>
          record?.active === true ? (
            <TextField source="ticketCode" />
          ) : (
            <TextField
              source="ticketCode"
              className={classes.inactiveTicketCode}
            />
          )
        }
      />
      <FunctionField<TicketDto>
        source="ticketType"
        render={(record?: TicketDto) =>
          smallScreen ? (
            <div>
              {translate(`resources.enums.ticketType.${record?.ticketType}`)}
            </div>
          ) : (
            <Chip
              label={translate(
                `resources.enums.ticketType.${record?.ticketType}`,
              )}
            />
          )
        }
      />
      <BooleanField source="active" />
      {!smallScreen && (
        <DateField
          source="createdAt"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
      )}
    </EditableDatagrid>
  )
}

const BookingTickets = () => {
  const { basePath, record: booking } = useShowContext()
  const translate = useTranslate()

  const Filters = () => [
    <TextInput source="ticketCode" alwaysOn />,
    <SelectInput
      source="ticketType"
      choices={Object.keys(TicketType).map((key: string) => ({
        id: key,
        name: translate(`resources.enums.ticketType.${key}`),
      }))}
    />,
  ]

  const Actions = () => (
    <TopToolbar>
      <FilterButton />
    </TopToolbar>
  )

  return (
    <ResourceContextProvider value={ResourceName.TICKETS}>
      <List
        exporter={false}
        perPage={20}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} />}
        bulkActionButtons={false}
        basePath={basePath}
        filter={{
          bookingId: booking?.id,
        }}
        filters={Filters()}
        actions={<Actions />}
        title=" "
        empty={false}
      >
        <BookingTicketsGrid />
      </List>
    </ResourceContextProvider>
  )
}

const BookingLogsView = () => {
  const { record, basePath } = useShowContext()
  return (
    <ResourceContextProvider value={ResourceName.LOGS}>
      <LogList
        basePath={basePath}
        filter={{ bookingId: record?.id }}
        filters={undefined}
        actions={false}
        title=" "
        noBooking
      />
    </ResourceContextProvider>
  )
}

const ProfileDetailsView = () => {
  const { record: booking } = useShowContext<BookingDto>()
  const translate = useTranslate()

  const { data: customer } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CUSTOMERS,
    payload: {
      id: booking?.customerId,
    },
  })

  return (
    <>
      <ReferenceField
        reference={ResourceName.CUSTOMERS}
        record={booking}
        source="customerId"
        link="show"
      >
        <>
          <Button label="ra.action.show" startIcon={<Visibility />} />
        </>
      </ReferenceField>
      <SimpleShowLayout record={customer} resource={ResourceName.CUSTOMERS}>
        <TextField source="firstname" />
        <TextField source="lastname" />
        {customer?.company && <TextField source="company" />}
        {customer?.birthdate && <DateField source="birthdate" />}
        {customer?.documentType && <TextField source="documentType" />}
        {customer?.pesel ? (
          <TextField source="pesel" />
        ) : (
          <TextField source="documentNumber" />
        )}
        {customer?.nationality && <TextField source="nationality" />}
        {customer?.gender && (
          <FunctionField<CustomerDto>
            source="gender"
            render={(r?: CustomerDto) => (
              <div>{translate(`resources.enums.gender.${r?.gender}`)}</div>
            )}
          />
        )}
      </SimpleShowLayout>
    </>
  )
}

const AccessDetailsView = ({ ...props }) => {
  const { record: booking } = useShowContext<BookingDto>()
  const translate = useTranslate()
  const { search } = useLocation()
  const selectedAccess = new URLSearchParams(search).get('id')

  const history = useHistory()
  const location = useLocation()

  const { data: variant } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.VARIANTS,
    payload: {
      id: booking?.variantId,
    },
  })

  useEffect(() => {
    if (variant?.accessesIds.length === 1)
      history.push({
        search: new URLSearchParams({ id: variant?.accessesIds[0] }).toString(),
      })
  }, [variant, history])

  return (
    <SimpleShowLayout
      {...props}
      record={variant}
      resource={ResourceName.VARIANTS}
    >
      <TextField source="name" label="resources.bookings.fields.variantName" />
      {variant?.accessesIds.length !== 1 && !selectedAccess && (
        <>
          <Typography variant="h5">
            {translate('resources.bookings.accesses.name')}
          </Typography>
          <ResourceContextProvider value={ResourceName.ACCESSES}>
            <AccessList
              basePath={`/${ResourceName.ACCESSES}`}
              actions={false}
              filters={undefined}
              filter={{
                filterVariantId: variant?.id !== undefined,
                variantId: variant?.id,
              }}
              title=" "
            />
          </ResourceContextProvider>
        </>
      )}
      {selectedAccess && (
        <>
          {variant?.accessesIds.length > 1 && (
            <Grid container item direction="column" alignItems="stretch">
              <Button
                label="resources.bookings.accesses.actions.selectAccess"
                useSmallVersionBreakpoint={false}
                variant="outlined"
                startIcon={<ArrowBack />}
                onClick={() => {
                  const newQueryParams = new URLSearchParams(location.search)
                  newQueryParams.delete('id')
                  history.replace({
                    search: newQueryParams.toString(),
                  })
                }}
              />
            </Grid>
          )}
          <ResourceContextProvider value={ResourceName.ACCESSES}>
            <AccessShow
              id={selectedAccess}
              basePath={`/${ResourceName.ACCESSES}`}
              actions={false}
              resource={ResourceName.ACCESSES}
              title=" "
              accessUsingsTicketsIdsFilter={booking?.ticketsIds}
              booking={booking}
            />
          </ResourceContextProvider>
        </>
      )}
    </SimpleShowLayout>
  )
}

const StatusButtons = ({ ...props }) => {
  const { record } = props
  const hasAuthority = useHasAuthority()
  const classes = useStyles()

  return (
    <>
      <Divider />
      <Box p={1} display="flex" justifyContent="flex-end">
        <ToggleBookingAccessButton />
        <ToggleBookingBlockadeButton />
        {record?.type === BookingType.SERVICE && (
          <EditButton
            variant="contained"
            disabled={!hasAuthority(Authority.EDIT_SERVICE_BOOKINGS)}
            className={classes.editButton}
            record={record}
            resource={ResourceName.SERVICE_BOOKINGS}
            basePath="/service-bookings"
          />
        )}
        {[BookingType.NORMAL, BookingType.INFIX, BookingType.ABO].includes(
          record?.type,
        ) &&
          hasAuthority(Authority.EDIT_BOOKINGS) && (
            <EditButton
              variant="contained"
              className={classes.editButton}
              record={record}
            />
          )}
      </Box>
    </>
  )
}

export const BookingHeader = () => {
  const classes = useStyles()
  const hasAuthority = useHasAuthority()
  const { record: ticketDetails } = useShowContext<BookingDto>()
  const { data: customer } = useQuery(
    {
      type: sanitizeFetchType(GET_ONE),
      resource: ResourceName.CUSTOMERS,
      payload: {
        id: ticketDetails?.customerId,
      },
    },
    { enabled: hasAuthority(Authority.VIEW_CUSTOMERS) },
  )

  return (
    <Box m={2}>
      <Typography className={classes.header} variant="h5" paragraph>
        {customer?.firstname} {customer?.lastname}
      </Typography>
    </Box>
  )
}

const BookingView = ({ ...props }) => {
  const { record: ticketDetails } = useShowContext<BookingDto>()
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()

  return (
    <Tab {...props} label="resources.bookings.tabs.general">
      <MappedChipField
        source="type"
        mapper={(value: BookingType) => `resources.enums.bookingType.${value}`}
      />
      <FunctionField<BookingDto>
        source="disabled"
        render={(record?: BookingDto) => (
          <div>
            {record?.disabled !== null
              ? translate(`resources.enums.bookingDisabled.${record?.disabled}`)
              : translate(`resources.enums.bookingDisabled.NONE`)}
          </div>
        )}
      />
      {ticketDetails?.eventId && hasAuthority(Authority.VIEW_EVENTS) && (
        <ReferenceField
          label="resources.bookings.fields.eventName"
          source="eventId"
          reference={ResourceName.EVENTS}
          link="show"
        >
          <FunctionField<EventDto>
            source="name"
            render={(event?: EventDto) => `${event?.id}. ${event?.name}`}
          />
        </ReferenceField>
      )}
      {ticketDetails?.seatId && hasAuthority(Authority.VIEW_SEATS) && (
        <ReferenceField
          source="seatId"
          reference={ResourceName.SEATS}
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
      )}
      {ticketDetails?.seatId && hasAuthority(Authority.VIEW_SEATS) && (
        <ReferenceField
          label="resources.bookings.fields.seatNumber"
          source="seatId"
          reference={ResourceName.SEATS}
          link={false}
        >
          <TextField source="numberAlias" />
        </ReferenceField>
      )}
      {ticketDetails?.seatId && hasAuthority(Authority.VIEW_SEATS) && (
        <ReferenceField
          label="resources.bookings.fields.rowNumber"
          source="seatId"
          reference={ResourceName.SEATS}
          link={false}
        >
          <TextField source="rowAlias" />
        </ReferenceField>
      )}
      {ticketDetails?.seatId &&
        hasAuthority(Authority.VIEW_SEATS) &&
        hasAuthority(Authority.VIEW_BLOCKS) && (
          <ReferenceField
            label="resources.bookings.fields.blockName"
            source="seatId"
            reference={ResourceName.SEATS}
            link={false}
          >
            <>
              <ReferenceField
                label=" "
                source="blockId"
                reference={ResourceName.BLOCKS}
                link="show"
              >
                <FunctionField<BlockDto>
                  source="name"
                  render={(record?: BlockDto) =>
                    `${record?.id}. ${record?.name}`
                  }
                />
              </ReferenceField>
            </>
          </ReferenceField>
        )}
      {ticketDetails?.blockId && hasAuthority(Authority.VIEW_BLOCKS) && (
        <ReferenceField
          label="resources.bookings.fields.blockName"
          source="blockId"
          reference={ResourceName.BLOCKS}
          link="show"
        >
          <FunctionField<BlockDto>
            source="name"
            render={(record?: BlockDto) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )}
      {ticketDetails?.seatId &&
        hasAuthority(Authority.VIEW_SEATS) &&
        hasAuthority(Authority.VIEW_BLOCKS) &&
        hasAuthority(Authority.VIEW_TRIBUNES) && (
          <ReferenceField
            label="resources.bookings.fields.tribuneName"
            source="seatId"
            reference={ResourceName.SEATS}
            link={false}
          >
            <ReferenceField
              source="blockId"
              reference={ResourceName.BLOCKS}
              link={false}
            >
              <>
                <ReferenceField
                  source="tribuneId"
                  reference={ResourceName.TRIBUNES}
                  link="show"
                >
                  <FunctionField<TribuneDto>
                    source="name"
                    render={(record?: TribuneDto) =>
                      `${record?.id}. ${record?.name}`
                    }
                  />
                </ReferenceField>
              </>
            </ReferenceField>
          </ReferenceField>
        )}
      {ticketDetails?.blockId &&
        hasAuthority(Authority.VIEW_BLOCKS) &&
        hasAuthority(Authority.VIEW_TRIBUNES) && (
          <ReferenceField
            label="resources.bookings.fields.tribuneName"
            source="blockId"
            reference={ResourceName.BLOCKS}
            link={false}
          >
            <>
              <ReferenceField
                source="tribuneId"
                reference={ResourceName.TRIBUNES}
                link="show"
              >
                <FunctionField<TribuneDto>
                  source="name"
                  render={(record?: TribuneDto) =>
                    `${record?.id}. ${record?.name}`
                  }
                />
              </ReferenceField>
            </>
          </ReferenceField>
        )}
      {ticketDetails?.seatId &&
        hasAuthority(Authority.VIEW_SEATS) &&
        hasAuthority(Authority.VIEW_BLOCKS) &&
        hasAuthority(Authority.VIEW_TRIBUNES) &&
        hasAuthority(Authority.VIEW_OBJECTS) && (
          <ReferenceField
            label="resources.bookings.fields.objectName"
            source="seatId"
            reference={ResourceName.SEATS}
            link={false}
          >
            <ReferenceField
              source="blockId"
              reference={ResourceName.BLOCKS}
              link={false}
            >
              <ReferenceField
                source="tribuneId"
                reference={ResourceName.TRIBUNES}
                link={false}
              >
                <>
                  <ReferenceField
                    source="objectId"
                    reference={ResourceName.OBJECTS}
                    link="show"
                  >
                    <FunctionField<ObjectDto>
                      source="name"
                      render={(record?: ObjectDto) =>
                        `${record?.id}. ${record?.name}`
                      }
                    />
                  </ReferenceField>
                </>
              </ReferenceField>
            </ReferenceField>
          </ReferenceField>
        )}
      {ticketDetails?.blockId &&
        hasAuthority(Authority.VIEW_BLOCKS) &&
        hasAuthority(Authority.VIEW_TRIBUNES) &&
        hasAuthority(Authority.VIEW_OBJECTS) && (
          <ReferenceField
            label="resources.bookings.fields.objectName"
            source="blockId"
            reference={ResourceName.BLOCKS}
            link={false}
          >
            <ReferenceField
              source="tribuneId"
              reference={ResourceName.TRIBUNES}
              link={false}
            >
              <>
                <ReferenceField
                  source="objectId"
                  reference={ResourceName.OBJECTS}
                  link="show"
                >
                  <FunctionField<ObjectDto>
                    source="name"
                    render={(record?: ObjectDto) =>
                      `${record?.id}. ${record?.name}`
                    }
                  />
                </ReferenceField>
              </>
            </ReferenceField>
          </ReferenceField>
        )}
      {ticketDetails?.activeFrom && <DateField source="activeFrom" showTime />}
      {ticketDetails?.activeTo && <DateField source="activeTo" showTime />}
      <DateField source="createdAt" showTime />
      <DateField source="modifiedAt" showTime />
      <StatusButtons />
    </Tab>
  )
}

const BookingTabs = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  return (
    <>
      <BookingHeader />
      <Divider />
      <TabbedShowLayout {...props}>
        <BookingView />
        {hasAuthority(Authority.VIEW_TICKETS) && (
          <Tab path="tickets" label="resources.bookings.tabs.tickets">
            <BookingTickets />
          </Tab>
        )}
        {hasAuthority(Authority.VIEW_LOGS) && (
          <Tab path="logs" label="resources.bookings.tabs.logs">
            <BookingLogsView />
          </Tab>
        )}
        {hasAuthority(Authority.VIEW_CUSTOMERS) && (
          <Tab path="profile" label="resources.bookings.tabs.profile">
            <ProfileDetailsView />
          </Tab>
        )}
        {hasAuthority(Authority.VIEW_ACCESSES) &&
          hasAuthority(Authority.VIEW_VARIANTS) && (
            <Tab path="accesses" label="resources.bookings.tabs.accesses">
              <AccessDetailsView />
            </Tab>
          )}
      </TabbedShowLayout>
    </>
  )
}

const ShowTitle = ({ ...props }) => {
  const { record: booking } = props
  const translate = useTranslate()
  return booking?.type !== BookingType.SERVICE ? (
    <span>
      {translate('resources.bookings.name')}: {booking?.id}
    </span>
  ) : (
    <span>
      {translate('resources.service-bookings.titles.show')}: {booking?.id}
    </span>
  )
}

export const BookingShow = (props: ShowProps) => (
  <Show {...props} title={<ShowTitle />} actions={false}>
    <BookingTabs />
  </Show>
)
