import { DataProvider } from 'react-admin'
import { EntrancesSetDto } from '../../dto/entrances-set.dto'

const fakeEntrances: EntrancesSetDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeEntrances, total: fakeEntrances.length }),
} as DataProvider

export default provider
