import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { ENGINES_URL } from '../../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  recurringJobParamsComposer,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { EngineEntrancesDto } from '../../dto/device/engine/engine-entrances.dto'
import { EngineEventsDto } from '../../dto/device/engine/engine-events.dto'
import { EngineDto } from '../../dto/device/engine/engine.dto'
import { RemoveTSCanCredentialsDto } from '../../dto/device/engine/remove-tscan-credentials.dto'
import { SetStandbyModeDto } from '../../dto/device/engine/set-standby-mode.dto'
import { SetTSCanCredentialsDto } from '../../dto/device/engine/set-tscan-credentials.dto'
import { RecurringJobCreationParamsDto } from '../../dto/recurring-job-creation-params.dto'
import { RecurringJobDetailsDto } from '../../dto/recurring-job-details.dto'
import { DeviceDirection } from '../../enum/DeviceDirection'
import { HardwareVersions } from '../../enum/HardwareVersions'
import { Multiplicities } from '../../enum/Multiplicities'
import { StanbyMode } from '../../enum/StanbyMode'
import { getDeviceCategoryChildren } from '../devices.utils'
import filterMapper from './engine-filter.mapper'
import mapSortEngineParam from './engine-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<EngineDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortEngineParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<EngineDto[]>(
      `${ENGINES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<EngineDto>> => {
    const data = await get<EngineDto>(ENGINES_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<EngineDto>> => {
    const data = await get<EngineDto[]>(
      ENGINES_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateEngineRequest>,
  ): Promise<CreateResult<EngineDto>> => {
    const engine = await post<CreateEngineRequest[], EngineDto[]>(ENGINES_URL, [
      data,
    ])
    return {
      data: engine[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<EngineDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, EngineDto>(
      ENGINES_URL,
      {
        id,
        name: data.name !== previousData.name ? data.name : undefined,
        isWatched:
          data.isWatched !== previousData.isWatched
            ? data.isWatched
            : undefined,
        direction:
          data.direction !== previousData.direction
            ? data.direction
            : undefined,
        categoryId:
          data.categoryId !== previousData.categoryId
            ? data.categoryId
            : undefined,
        cameraId:
          data.cameraId !== previousData.cameraId ? data.cameraId : undefined,
        onlineInfixCeck:
          data.onlineInfixCeck !== previousData.onlineInfixCeck
            ? data.onlineInfixCeck
            : undefined,
        multiplicity:
          data.multiplicity !== previousData.multiplicity
            ? data.multiplicity
            : undefined,
        hardwareVersion:
          data.hardwareVersion !== previousData.hardwareVersion
            ? data.hardwareVersion
            : undefined,
        tsCanId:
          data.tsCanId !== previousData.tsCanId ? data.tsCanId : undefined,
        tsCommAddress:
          data.tsCommAddress !== previousData.tsCommAddress
            ? data.tsCommAddress
            : undefined,
        supervisorId:
          data.supervisorId !== previousData.supervisorId
            ? data.supervisorId
            : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  attachEvents: async (
    resource: string,
    params: EngineEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<EngineEventsDto>> => {
    const engineEvents = await put<EngineEventsDto, EngineEventsDto>(
      `${ENGINES_URL}/AttachEvents`,
      params,
    )
    return {
      data: engineEvents,
    }
  },
  detachEvents: async (
    resource: string,
    params: EngineEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<EngineEventsDto>> => {
    const engineEvents = await put<EngineEventsDto, EngineEventsDto>(
      `${ENGINES_URL}/DetachEvents`,
      params,
    )
    return {
      data: engineEvents,
    }
  },
  attachEntrances: async (
    resource: string,
    params: EngineEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<EngineEntrancesDto>> => {
    const engineEntrances = await put<EngineEntrancesDto, EngineEntrancesDto>(
      `${ENGINES_URL}/AttachEntrances`,
      params,
    )
    return {
      data: engineEntrances,
    }
  },
  detachEntrances: async (
    resource: string,
    params: EngineEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<EngineEntrancesDto>> => {
    const engineEntrances = await put<EngineEntrancesDto, EngineEntrancesDto>(
      `${ENGINES_URL}/DetachEntrances`,
      params,
    )
    return {
      data: engineEntrances,
    }
  },
  setTSCanCredentials: async (
    resource: string,
    params: SetTSCanCredentialsDto,
  ): Promise<DataProviderExtensionResult<SetTSCanCredentialsDto>> => {
    const setTSCanCredentials = await put<
      SetTSCanCredentialsDto,
      SetTSCanCredentialsDto
    >(`${ENGINES_URL}/SetTSCanCredentials`, params)
    return {
      data: setTSCanCredentials,
    }
  },
  removeTSCanCredentials: async (
    resource: string,
    params: RemoveTSCanCredentialsDto,
  ): Promise<DataProviderExtensionResult<RemoveTSCanCredentialsDto>> => {
    const removeTSCanCredentials = await put<
      RemoveTSCanCredentialsDto,
      RemoveTSCanCredentialsDto
    >(`${ENGINES_URL}/RemoveTSCanCredentials`, params)
    return {
      data: removeTSCanCredentials,
    }
  },
  lock: async (
    resource: string,
    { engineId, disabledId, ...rest }: LockParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const lockEngine = await put<
      LockParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${ENGINES_URL}/Lock/${query ? `?${query}` : ''}`, {
      engineId,
      disabledId,
    })
    if ((lockEngine as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: lockEngine as RecurringJobDetailsDto,
      }
    return {
      data: lockEngine,
    }
  },
  unlock: async (
    resource: string,
    { engineId, ...rest }: UnlockParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const unlockEngine = await put<
      UnlockParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${ENGINES_URL}/Unlock/${query ? `?${query}` : ''}`, {
      engineId,
    })
    if ((unlockEngine as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: unlockEngine as RecurringJobDetailsDto,
      }
    return {
      data: unlockEngine,
    }
  },
  setStandbyMode: async (
    resource: string,
    { engineId, stanbyMode, ...rest }: SetStandbyModeDto,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const setStandby = await put<
      SetStandbyModeDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${ENGINES_URL}/SetStandbyMode/${query ? `?${query}` : ''}`, {
      engineId,
      stanbyMode,
    })
    if ((setStandby as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setStandby as RecurringJobDetailsDto,
      }
    return {
      data: setStandby,
    }
  },
  forceReportState: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const forceReportState = await get<undefined>(
      ENGINES_URL,
      `/ForceReportState/${params?.id}`,
    )
    return {
      data: forceReportState,
    }
  },
  restartTSCan: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const restartTSCan = await get<undefined>(
      ENGINES_URL,
      `/RestartTSCan/${params?.id}`,
    )
    return {
      data: restartTSCan,
    }
  },
  resetModules: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const forceReportState = await get<undefined>(
      ENGINES_URL,
      `/ResetModules/${params?.id}`,
    )
    return {
      data: forceReportState,
    }
  },
} as EngineDataProvider

interface EngineDataProvider extends DataProvider {
  attachEvents: (
    resource: string,
    params: EngineEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<EngineEventsDto>>

  detachEvents: (
    resource: string,
    params: EngineEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<EngineEventsDto>>

  attachEntrances: (
    resource: string,
    params: EngineEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<EngineEntrancesDto>>

  detachEntrances: (
    resource: string,
    params: EngineEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<EngineEntrancesDto>>

  setTSCanCredentials: (
    resource: string,
    params: SetTSCanCredentialsDto,
  ) => Promise<DataProviderExtensionResult<SetTSCanCredentialsDto>>

  removeTSCanCredentials: (
    resource: string,
    params: RemoveTSCanCredentialsDto,
  ) => Promise<DataProviderExtensionResult<RemoveTSCanCredentialsDto>>

  lock: (
    resource: string,
    params: LockParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  unlock: (
    resource: string,
    params: UnlockParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setStandbyMode: (
    resource: string,
    params: SetStandbyModeDto,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  forceReportState: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  restartTSCan: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  resetModules: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>
}

export interface CreateEngineRequest {
  readonly name: string
  readonly isWatched: boolean
  readonly direction: DeviceDirection
  readonly categoryId: number
  readonly disabled: string
  readonly stanbyMode: StanbyMode
  readonly jsonStatus: string
  readonly onlineInfixCeck: boolean
  readonly multiplicity: Multiplicities
  readonly hardwareVersion: HardwareVersions
  readonly tsCanId: number
  readonly supervisorId: number
}

export interface LockParams extends RecurringJobCreationParamsDto {
  engineId: number
  disabledId: number
}

export interface UnlockParams extends RecurringJobCreationParamsDto {
  engineId: number
}

interface ChangeStateResponse {
  jobId: Identifier
}

export default provider
