import * as React from 'react';
import { Typography } from '@material-ui/core';
import { EditorTitle } from './EditorTitle';
import { useTranslate } from 'react-admin';
import { EditResourcesModelsButton } from '../ResourceModel';
export var PreferencesEditorRoot = function () {
    var translate = useTranslate();
    return (React.createElement(React.Fragment, null,
        React.createElement(EditorTitle, { title: "ra-preferences.no-code.title" }),
        React.createElement(Typography, null, translate('ra-preferences.no-code.instructions')),
        React.createElement(EditResourcesModelsButton, null)));
};
