import * as React from 'react';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { useTranslate, Confirm } from 'react-admin';
import { usePreferences } from '../usePreferences';
/**
 * Resets the settings for a given preferences key
 *
 * @example
 *
 * <ResetSettingsButton preferencesKey="myPreferencesKey" />
 */
export var ResetSettingsButton = function (_a) {
    var preferencesKey = _a.preferencesKey;
    var classes = useStyles();
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var translate = useTranslate();
    var _c = usePreferences(preferencesKey), _ = _c[0], setPrefs = _c[1];
    var handleClick = function () {
        setOpen(true);
    };
    var handleDialogClose = function () {
        setOpen(false);
    };
    var handleConfirm = function () {
        setPrefs({});
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { fullWidth: true, size: "small", onClick: handleClick, className: classes.root }, translate('ra-preferences.no-code.reset', {
            _: 'Reset Settings',
        })),
        React.createElement(Confirm, { isOpen: open, loading: false, title: "ra.message.are_you_sure", content: "", onConfirm: handleConfirm, onClose: handleDialogClose })));
};
var useStyles = makeStyles(function (theme) { return ({
    root: {
        marginTop: theme.spacing(1),
        color: theme.palette.error.main,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
        },
    },
}); });
