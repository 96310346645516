import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { PASSAGES_URL } from '../../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import { DateTime } from '../../common/date-time'
import {
  auditCommentParamComposer,
  get,
  getWithPagination,
  patch,
  post,
  put,
  recurringJobParamsComposer,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { PassageDto } from '../../dto/device/passages/passage.dto'
import { RemoveTSCanCredentialsDto } from '../../dto/device/passages/remove-tscan-credentials.dto'
import { ResetCountersDto } from '../../dto/device/passages/reset-counters.dto'
import { SetTSCanCredentialsDto } from '../../dto/device/passages/set-tscan-credentials.dto'
import { RecurringJobCreationParamsDto } from '../../dto/recurring-job-creation-params.dto'
import { RecurringJobDetailsDto } from '../../dto/recurring-job-details.dto'
import { DeviceDirection } from '../../enum/DeviceDirection'
import { HardwareVersions } from '../../enum/HardwareVersions'
import { getDeviceCategoryChildren } from '../devices.utils'
import filterMapper from './passage-filter.mapper'
import mapSortPassageParam from './passage-sort.mapper'
import { PassageDirections } from '../../enum/PassageDirections'
import { AuditCommentRequestDto } from '../../dto/audit-comment-request.dto'
import { AuditCommentParamsDto } from '../../dto/audit-comment-params.dto'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<PassageDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortPassageParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<PassageDto[]>(
      `${PASSAGES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<PassageDto>> => {
    const data = await get<PassageDto>(PASSAGES_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<PassageDto>> => {
    const { data } = await getWithPagination<PassageDto[]>(
      `${PASSAGES_URL}/GetByConditions/`,
      `e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreatePassageRequest>,
  ): Promise<CreateResult<PassageDto>> => {
    const passage = await post<CreatePassageRequest[], PassageDto[]>(
      PASSAGES_URL,
      [data],
    )
    return {
      data: passage[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<PassageDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      PassageDto
    >(PASSAGES_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
      isWatched:
        data.isWatched !== previousData.isWatched ? data.isWatched : undefined,
      categoryId:
        data.categoryId !== previousData.categoryId
          ? data.categoryId
          : undefined,
      cameraId:
        data.cameraId !== previousData.cameraId ? data.cameraId : undefined,
      freeEntrancesCount:
        data.freeEntrancesCount !== previousData.freeEntrancesCount
          ? data.freeEntrancesCount
          : undefined,
      freeExitsCount:
        data.freeExitsCount !== previousData.freeExitsCount
          ? data.freeExitsCount
          : undefined,
      ticketEntrancesCount:
        data.ticketEntrancesCount !== previousData.ticketEntrancesCount
          ? data.ticketEntrancesCount
          : undefined,
      ticketExitsCount:
        data.ticketExitsCount !== previousData.ticketExitsCount
          ? data.ticketExitsCount
          : undefined,
      unauthorizedEntrancesCount:
        data.unauthorizedEntrancesCount !==
        previousData.unauthorizedEntrancesCount
          ? data.unauthorizedEntrancesCount
          : undefined,
      unauthorizedExitsCount:
        data.unauthorizedExitsCount !== previousData.unauthorizedExitsCount
          ? data.unauthorizedExitsCount
          : undefined,
      hardwareVersion:
        data.hardwareVersion !== previousData.hardwareVersion
          ? data.hardwareVersion
          : undefined,
      tsCanId: data.tsCanId !== previousData.tsCanId ? data.tsCanId : undefined,
      tsCommAddress:
        data.tsCommAddress !== previousData.tsCommAddress
          ? data.tsCommAddress
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  resetCounters: async (
    resource: string,
    { passageId, ...rest }: ResetCountersDto,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const recurringJobQuery =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const auditCommentQuery =
      rest && auditCommentParamComposer(rest as AuditCommentParamsDto)
    const queryParams: string[] = []
    if (recurringJobQuery) {
      queryParams.push(recurringJobQuery)
    }
    if (auditCommentQuery) {
      queryParams.push(auditCommentQuery)
    }
    const query = queryParams?.length
      ? queryParams.reduce((p, c) => `${p}&${c}`)
      : undefined

    const resetCounters = await put<
      ResetCountersDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${PASSAGES_URL}/ResetCounters/${query ? `?${query}` : ''}`, {
      passageId,
    })

    if ((resetCounters as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: resetCounters as RecurringJobDetailsDto,
      }
    return {
      data: resetCounters,
    }
  },
  setTSCanCredentials: async (
    resource: string,
    params: SetTSCanCredentialsDto,
  ): Promise<CacheableDataProviderExtensionResult<SetTSCanCredentialsDto>> => {
    const response = await put<SetTSCanCredentialsDto, SetTSCanCredentialsDto>(
      `${PASSAGES_URL}/SetTSCanCredentials`,
      params,
    )
    return {
      data: response,
    }
  },
  removeTSCanCredentials: async (
    resource: string,
    params: RemoveTSCanCredentialsDto,
  ): Promise<
    CacheableDataProviderExtensionResult<RemoveTSCanCredentialsDto>
  > => {
    const response = await put<
      RemoveTSCanCredentialsDto,
      RemoveTSCanCredentialsDto
    >(`${PASSAGES_URL}/RemoveTSCanCredentials`, params)
    return {
      data: response,
    }
  },
  setLockState: async (
    resource: string,
    { passageId, disabledId, ...rest }: SetLockStateRequest,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const setLockState = await put<
      SetLockStateRequest,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${PASSAGES_URL}/SetLockState/${query ? `?${query}` : ''}`, {
      passageId,
      disabledId,
    })
    if ((setLockState as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setLockState as RecurringJobDetailsDto,
      }
    return {
      data: setLockState,
    }
  },
  setPowerState: async (
    resource: string,
    { passageId, powerState, ...rest }: SetPowerStateRequest,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const recurringJobQuery =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const auditCommentQuery =
      rest && auditCommentParamComposer(rest as AuditCommentParamsDto)
    const queryParams: string[] = []
    if (recurringJobQuery) {
      queryParams.push(recurringJobQuery)
    }
    if (auditCommentQuery) {
      queryParams.push(auditCommentQuery)
    }
    const query = queryParams?.length
      ? queryParams.reduce((p, c) => `${p}&${c}`)
      : undefined

    const setPowerState = await put<
      SetPowerStateRequest,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${PASSAGES_URL}/SetPowerState/${query ? `?${query}` : ''}`, {
      passageId,
      powerState,
    })

    if ((setPowerState as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setPowerState as RecurringJobDetailsDto,
      }
    return {
      data: setPowerState,
    }
  },
  setPPOZState: async (
    resource: string,
    { passageId, ppozState, ...rest }: SetPPOZStateRequest,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const recurringJobQuery =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const auditCommentQuery =
      rest && auditCommentParamComposer(rest as AuditCommentParamsDto)
    const queryParams: string[] = []
    if (recurringJobQuery) {
      queryParams.push(recurringJobQuery)
    }
    if (auditCommentQuery) {
      queryParams.push(auditCommentQuery)
    }
    const query = queryParams?.length
      ? queryParams.reduce((p, c) => `${p}&${c}`)
      : undefined

    const setPPOZState = await put<
      SetPPOZStateRequest,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${PASSAGES_URL}/SetPPOZState/${query ? `?${query}` : ''}`, {
      passageId,
      ppozState,
    })

    if ((setPPOZState as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setPPOZState as RecurringJobDetailsDto,
      }
    return {
      data: setPPOZState,
    }
  },
  setFreePassState: async (
    resource: string,
    { passageId, freePassState, direction, ...rest }: SetFreePassStateRequest,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const recurringJobQuery =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const auditCommentQuery =
      rest && auditCommentParamComposer(rest as AuditCommentParamsDto)
    const queryParams: string[] = []
    if (recurringJobQuery) {
      queryParams.push(recurringJobQuery)
    }
    if (auditCommentQuery) {
      queryParams.push(auditCommentQuery)
    }
    const query = queryParams?.length
      ? queryParams.reduce((p, c) => `${p}&${c}`)
      : undefined

    const setFreePassState = await put<
      SetFreePassStateRequest,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${PASSAGES_URL}/SetFreePassState/${query ? `?${query}` : ''}`, {
      passageId,
      direction,
      freePassState,
    })

    if ((setFreePassState as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setFreePassState as RecurringJobDetailsDto,
      }
    return {
      data: setFreePassState,
    }
  },
  forceReportState: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const forceReportState = await get<undefined>(
      PASSAGES_URL,
      `/ForceReportState/${params?.id}`,
    )
    return {
      data: forceReportState,
    }
  },
  restartTSCan: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const restartTSCan = await get<undefined>(
      PASSAGES_URL,
      `/RestartTSCan/${params?.id}`,
    )
    return {
      data: restartTSCan,
    }
  },
  resetModules: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const forceReportState = await get<undefined>(
      PASSAGES_URL,
      `/ResetModules/${params?.id}`,
    )
    return {
      data: forceReportState,
    }
  },
  setReversedRotationDirectionState: async (
    resource: string,
    {
      passageId,
      reversedRotationDirectionState,
      ...rest
    }: SetReversedRotationDirectionStateRequest,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const setReverseRotationState = await put<
      SetReversedRotationDirectionStateRequest,
      ChangeStateResponse | RecurringJobDetailsDto
    >(
      `${PASSAGES_URL}/SetReversedRotationDirectionState/${
        query ? `?${query}` : ''
      }`,
      {
        passageId,
        reversedRotationDirectionState,
      },
    )
    if ((setReverseRotationState as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setReverseRotationState as RecurringJobDetailsDto,
      }
    return {
      data: setReverseRotationState,
    }
  },
  setOpen: async (
    resource: string,
    { passageId, timeInSeconds, direction, ...rest }: SetOpenRequest,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const recurringJobQuery =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const auditCommentQuery =
      rest && auditCommentParamComposer(rest as AuditCommentParamsDto)
    const queryParams: string[] = []
    if (recurringJobQuery) {
      queryParams.push(recurringJobQuery)
    }
    if (auditCommentQuery) {
      queryParams.push(auditCommentQuery)
    }
    const query = queryParams?.length
      ? queryParams.reduce((p, c) => `${p}&${c}`)
      : undefined

    const setOpen = await put<
      SetOpenRequest,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${PASSAGES_URL}/SetOpen/${query ? `?${query}` : ''}`, {
      passageId,
      timeInSeconds,
      direction,
    })

    if ((setOpen as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setOpen as RecurringJobDetailsDto,
      }
    return {
      data: setOpen,
    }
  },
} as DetectorDataProvider

interface DetectorDataProvider extends DataProvider {
  resetCounters: (
    resource: string,
    params: ResetCountersDto,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setTSCanCredentials: (
    resource: string,
    params: SetTSCanCredentialsDto,
  ) => Promise<DataProviderExtensionResult<SetTSCanCredentialsDto>>

  removeTSCanCredentials: (
    resource: string,
    params: RemoveTSCanCredentialsDto,
  ) => Promise<DataProviderExtensionResult<RemoveTSCanCredentialsDto>>

  setLockState: (
    resource: string,
    params: SetLockStateRequest,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setPowerState: (
    resource: string,
    params: SetPowerStateRequest,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setPPOZState: (
    resource: string,
    params: SetPPOZStateRequest,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setFreePassState: (
    resource: string,
    params: SetFreePassStateRequest,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  forceReportState: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  restartTSCan: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  resetModules: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  setReversedRotationDirectionState: (
    resource: string,
    params: SetReversedRotationDirectionStateRequest,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setOpen: (
    resource: string,
    params: SetOpenRequest,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >
}

export interface CreatePassageRequest {
  readonly name: string
  readonly isWatched?: boolean
  readonly lastRefresh?: DateTime
  readonly direction?: DeviceDirection
  readonly categoryId?: number
  readonly jsonStatus?: string
  readonly freeEntrancesCount?: number
  readonly freeExitsCount?: number
  readonly ticketEntrancesCount?: number
  readonly ticketExitsCount?: number
  readonly unauthorizedEntrancesCount?: number
  readonly unauthorizedExitsCount?: number
  readonly hardwareVersion?: HardwareVersions
  readonly tsCanId: number
}

export interface SetFreePassStateRequest
  extends RecurringJobCreationParamsDto,
    AuditCommentRequestDto {
  readonly passageId: number
  readonly freePassState: boolean
  readonly direction: DeviceDirection
}

export interface SetLockStateRequest extends RecurringJobCreationParamsDto {
  readonly passageId: number
  readonly disabledId: boolean
}

export interface SetPowerStateRequest
  extends RecurringJobCreationParamsDto,
    AuditCommentRequestDto {
  readonly passageId: number
  readonly powerState: boolean
}

export interface SetPPOZStateRequest
  extends RecurringJobCreationParamsDto,
    AuditCommentRequestDto {
  readonly passageId: number
  readonly ppozState: boolean
}

export interface SetReversedRotationDirectionStateRequest
  extends RecurringJobCreationParamsDto {
  readonly passageId: number
  readonly reversedRotationDirectionState: boolean
}

export interface SetOpenRequest
  extends RecurringJobCreationParamsDto,
    AuditCommentRequestDto {
  readonly passageId: number
  readonly timeInSeconds: number
  readonly direction: PassageDirections
}

interface ChangeStateResponse {
  jobId: Identifier
}

export default provider
