import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  Identifier,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyResult,
} from 'react-admin'
import { CAMERA_SERVERS_URL } from '../../../api-urls'
import { DataProviderExtensionResult } from '../../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
} from '../../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../../common/ticket-api-get-list.params'
import { CameraServerDto } from '../../../dto/device/camera/camera-servers/camera-server.dto'
import { RemoveCameraServerCredentialsDto } from '../../../dto/device/camera/camera-servers/remove-camera-server-credentials.dto'
import { SetCameraServerCredentialsDto } from '../../../dto/device/camera/camera-servers/set-camera-server-credentials.dto'
import { getDeviceCategoryChildren } from '../../devices.utils'
import filterMapper from './camera-server-filter.mapper'
import { mapSortCameraServerParam } from './camera-server-sort.mapper'
import { CameraTypes } from '../../../enum/CameraTypes'
import { WebProtocols } from '../../../enum/WebProtocols'
import { CameraTimeZones } from '../../../enum/CameraTimeZones'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<CameraServerDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `c=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortCameraServerParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<CameraServerDto[]>(
      `${CAMERA_SERVERS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<CameraServerDto>> => {
    const data = await get<CameraServerDto>(CAMERA_SERVERS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<CameraServerDto>> => {
    const data = await get<CameraServerDto[]>(
      CAMERA_SERVERS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<CameraServerDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      CameraServerDto
    >(CAMERA_SERVERS_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
      webProtocol:
        data.webProtocol !== previousData.webProtocol
          ? data.webProtocol
          : undefined,
      address: data.address !== previousData.address ? data.address : undefined,
      type: data.type !== previousData.type ? data.type : undefined,
      timeZone:
        data.timeZone !== previousData.timeZone ? data.timeZone : undefined,
      serverId:
        data.serverId !== previousData.serverId ? data.serverId : undefined,
      delay: data.delay !== previousData.delay ? data.delay : undefined,
      downloadClipDuration:
        data.downloadClipDuration !== previousData.downloadClipDuration
          ? data.downloadClipDuration
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateCameraServerRequest>,
  ): Promise<CreateResult<CameraServerDto>> => {
    const camera = await post<CreateCameraServerRequest[], CameraServerDto[]>(
      CAMERA_SERVERS_URL,
      [data],
    )
    return {
      data: camera[0],
    }
  },
  setCredentials: async (
    resource: string,
    params: SetCameraServerCredentialsDto,
  ): Promise<DataProviderExtensionResult<SetCameraServerCredentialsDto>> => {
    const setCredentials = await put<
      SetCameraServerCredentialsDto,
      SetCameraServerCredentialsDto
    >(`${CAMERA_SERVERS_URL}/SetCredentials`, params)
    return {
      data: setCredentials,
    }
  },
  removeCredentials: async (
    resource: string,
    params: RemoveCameraServerCredentialsDto,
  ): Promise<DataProviderExtensionResult<RemoveCameraServerCredentialsDto>> => {
    const removeCredentials = await put<
      RemoveCameraServerCredentialsDto,
      RemoveCameraServerCredentialsDto
    >(`${CAMERA_SERVERS_URL}/RemoveCredentials`, params)
    return {
      data: removeCredentials,
    }
  },
} as CameraServerDataProvider

interface CameraServerDataProvider extends DataProvider {
  setCredentials: (
    resource: string,
    params: SetCameraServerCredentialsDto,
  ) => Promise<DataProviderExtensionResult<SetCameraServerCredentialsDto>>

  removeCredentials: (
    resource: string,
    params: RemoveCameraServerCredentialsDto,
  ) => Promise<DataProviderExtensionResult<RemoveCameraServerCredentialsDto>>
}

export interface CreateCameraServerRequest {
  readonly name: string
  readonly webProtocol?: WebProtocols
  readonly address: string
  readonly type?: CameraTypes
  readonly timeZone?: CameraTimeZones
  readonly serverId: string
  readonly delay: string
  readonly downloadClipDuration?: number
}

export default provider
