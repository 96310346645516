import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  ListButton,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { DynamicPropertyDefinitionBaseDTO } from '../../../core/dto/dynamic-property-definition.dto'
import { DynamicPropertyDataTypes } from '../../../core/enum/DynamicPropertyDataTypes'
import { DefinitionOwnerTypes } from '../../../core/enum/DefinitionOwnerTypes'

const validateDynamicPropertyDefinitionCreate = (
  values: DynamicPropertyDefinitionBaseDTO,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.definitionOwnerType) {
    errors.definitionOwnerType = 'ra.validation.required'
  }
  if (!values.key) {
    errors.key = 'ra.validation.required'
  }
  if (!values.type) {
    errors.type = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.dynamic-property-definitions.titles.create')}
    </span>
  )
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton redirect={() => '/dynamic-property-definitions'} />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const DynamicPropertyDefinitionCreate = (props: CreateProps) => {
  const translate = useTranslate()

  return (
    <Create {...props} title={<CreateTitle />} actions={false}>
      <SimpleForm
        redirect="show"
        validate={validateDynamicPropertyDefinitionCreate}
        toolbar={<CreateToolbar />}
      >
        <SelectInput
          source="definitionOwnerType"
          choices={Object.keys(DefinitionOwnerTypes).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.DefinitionOwnerTypes.${key}`),
          }))}
          isRequired
        />
        <TextInput source="key" isRequired />
        <SelectInput
          source="type"
          choices={Object.keys(DynamicPropertyDataTypes).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.DynamicPropertyDataTypes.${key}`),
          }))}
          isRequired
        />
      </SimpleForm>
    </Create>
  )
}
