var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import classnames from 'classnames';
/**
 * A Material-UI Avatar with fallback to the Person icon if no url was provided for an image.
 */
export var EventAvatar = function (props) {
    var className = props.className, src = props.src, fullName = props.fullName, _a = props.size, size = _a === void 0 ? 'medium' : _a, rest = __rest(props, ["className", "src", "fullName", "size"]);
    var classes = useStyles(props);
    return (React.createElement(Avatar, __assign({ src: src, className: classnames(className, classes[size]) }, rest), !!src ? null : fullName ? getInitials(fullName) : React.createElement(PersonIcon, null)));
};
var useStyles = makeStyles({
    small: {
        width: 20,
        height: 20,
    },
    medium: {
        width: 40,
        height: 40,
    },
});
export var getInitials = function (name) {
    return name
        .match(/(^\w\w?|\b\w)?/g)
        .join('')
        .match(/(^\w|\w$)?/g)
        .join('')
        .toUpperCase();
};
