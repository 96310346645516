import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  FormDataConsumer,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin'
import {
  CreateDialog,
  CreateDialogProps,
} from '../common/customized-ra-components/CreateDialog'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { ResourceName } from '../../core/ResourceName'
import { TicketImportMode } from '../../core/enum/TicketImportMode'
import { CreateTicketImportParams } from '../../core/ticket-imports/ticket-imports.provider'
import { FileInput } from './ticket-import-button/FileInput'

const useStyles = makeStyles({
  dialog: {
    '& > div > div': {
      width: '350px !important',
    },
  },
})

export interface TicketImportDialogProps extends CreateDialogProps {
  readonly templateId?: number
}

const validateTicketImport = (values: CreateTicketImportParams) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.templateId) {
    errors.templateId = 'ra.validation.required'
  }
  if (!values.importMode) {
    errors.importMode = 'ra.validation.required'
  }
  if (values.importMode === TicketImportMode.IMPORT && !values.csvFile) {
    errors.csvFile = 'ra.validation.required'
  }
  if (values.importMode === TicketImportMode.GENERATE && !values.ticketsCount) {
    errors.ticketsCount = 'ra.validation.required'
  }
  return errors
}

const CreateToolbar = ({ ...props }) => {
  const { handleClose, ...rest } = props
  const { invalid } = rest

  return (
    <Toolbar {...rest}>
      <SaveButton disabled={invalid} redirect="show" onClick={handleClose} />
    </Toolbar>
  )
}

export const TicketImportDialog = (props: TicketImportDialogProps) => {
  const { templateId, ...rest } = props
  const { handleClose } = rest
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <CreateDialog
      {...rest}
      className={classes.dialog}
      title={translate('common.import.dialog-title')}
    >
      <SimpleForm
        initialValues={{
          templateId,
          importMode: TicketImportMode.IMPORT,
        }}
        toolbar={<CreateToolbar handleClose={handleClose} />}
        validate={validateTicketImport}
      >
        <TextInput source="name" fullWidth />
        <FilteredReferenceInput
          source="templateId"
          defaultValue={null}
          reference={ResourceName.TICKET_IMPORT_TEMPLATES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          fullWidth
        />
        <SelectInput
          source="importMode"
          choices={Object.entries(TicketImportMode).map(([value]) => ({
            id: value,
            name: `resources.enums.TicketImportMode.${value}`,
          }))}
          fullWidth
        />
        <FormDataConsumer fullWidth>
          {({ formData, ...formRest }) =>
            formData?.importMode === TicketImportMode.IMPORT ? (
              <FileInput
                {...formRest}
                formData={formData}
                fullWidth
                source="csvFile"
              />
            ) : (
              <TextInput {...formRest} source="ticketsCount" fullWidth />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </CreateDialog>
  )
}
