const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  customerId: (lambdaParameter, value) =>
    `(${lambdaParameter}.Customer.Id.Equals(${value as number}))`,
  variantId: (lambdaParameter, value) =>
    `(${lambdaParameter}.Variant.Id.Equals(${value as number}))`,
  type: (lambdaParameter, value) =>
    `(${lambdaParameter}.Type.Equals(TicketAPI.Models.Enums.TicketManagement.BookingTypes.${value}))`,
  customer: (lambdaParameter, value) =>
    `(${value
      .toString()
      .split(' ')
      .map(
        (q: string) =>
          `(${lambdaParameter}.Customer.Firstname+${lambdaParameter}.Customer.Lastname+${lambdaParameter}.Customer.Company+${lambdaParameter}.Customer.Pesel+${lambdaParameter}.Customer.DocumentNumber).Contains("${q}")`,
      )
      .join('&&')})`,
  ticketCode: (lambdaParameter, value) =>
    `(${lambdaParameter}.Tickets.Any(t => t.TicketCode.Contains("${value}")))`,
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
