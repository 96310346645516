import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect } from 'react'
import {
  FunctionField,
  GET_ONE,
  SimpleShowLayout,
  TextField,
  useQuery,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { JobStatuses } from '../../../core/enum/JobStatuses'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      padding: 0,
      background: 'rgba(0,0,0,0.2)',
      color: theme.palette.error.contrastText,
    },
    dialogTitle: {
      marginLeft: '10px',
      marginRight: 'auto',
    },
    button: {
      borderRadius: 0,
      background: theme.palette.primary.main,
    },
    title: {
      padding: '5px 24px',
    },
  }),
)

interface StatusTransitionDialogProps {
  handleCloseDialog: () => void
  handleSkipDialog: () => void
  jobId?: number
  open: boolean
  skipDialog: boolean
}

export const StatusTransitionDialog = ({
  handleCloseDialog,
  handleSkipDialog,
  jobId,
  open,
  skipDialog,
}: StatusTransitionDialogProps) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { refetch: refetchEvent } = useShowContext()

  const { data: job, refetch } = useQuery(
    {
      resource: ResourceName.JOBS,
      type: GET_ONE,
      payload: {
        id: jobId,
      },
    },
    {
      enabled: !!jobId,
    },
  )

  useEffect(() => {
    if (jobId) {
      const interval = setInterval(() => {
        refetch?.()
      }, 5000)

      if (job && job?.jobResult) {
        clearInterval(interval)
      }

      return () => clearInterval(interval)
    }
    return () => undefined
  }, [handleCloseDialog, job, jobId, open, refetch])

  useEffect(() => {
    if (job?.jobResult) refetchEvent?.()
  }, [job, refetchEvent])

  if (!job || !job?.jobResult)
    return (
      <Dialog open={open && !skipDialog}>
        <DialogActions className={classes.actions}>
          <IconButton
            size="small"
            className={classes.button}
            onClick={handleSkipDialog}
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogActions>
        <DialogTitle className={classes.title}>
          {translate('resources.events.dialogs.transition.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('resources.events.dialogs.transition.content')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )

  return (
    <Dialog open={open}>
      <DialogActions className={classes.actions}>
        {(job?.jobStatus !== JobStatuses.SUCCEEDED ||
          job?.jobResult?.statusCode !== 200) && (
          <Typography className={classes.dialogTitle} variant="h6">
            HTTP {job?.jobResult?.value?.code}
          </Typography>
        )}
        <IconButton
          size="small"
          className={classes.button}
          onClick={handleCloseDialog}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogTitle className={classes.title}>
        {job?.jobStatus === JobStatuses.SUCCEEDED &&
        job?.jobResult?.statusCode === 200
          ? translate('resources.events.dialogs.transition.result.succedeed')
          : translate('resources.events.dialogs.transition.result.failed')}
      </DialogTitle>
      {(job?.jobStatus !== JobStatuses.SUCCEEDED ||
        job?.jobResult?.statusCode !== 200) && (
        <DialogContent>
          <SimpleShowLayout
            record={job?.jobResult}
            resource="api-error-handling"
          >
            <FunctionField
              source="message"
              render={(record) => record?.value?.message}
            />
            <TextField source="value.identifier" label="Id" />
          </SimpleShowLayout>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          label="ra.action.confirm"
          onClick={handleCloseDialog}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  )
}
