export const transformRowFormEditData = (data: any, keys: string[]) => {
  let transformData = data
  const dataKeys = Object.keys(data)
  keys.map((key: string) => {
    if (!dataKeys.includes(key))
      transformData = { ...transformData, [key]: null }
    return key
  })
  return transformData
}
