const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  note: (lambdaParameter, value) =>
    `${lambdaParameter}.Note.Contains("${value}")`,
  filterVerifierDeviceId: () => null,
  verifierDeviceId: (lambdaParameter, value, otherValues) =>
    otherValues.filterVerifierDeviceId === false
      ? null
      : `${lambdaParameter}.VerifierDevices.Any(vd=>vd.Id==${value})`,
  idWithNote: (lambdaParameter, value) => {
    const values = (value as string)
      .split(' ')
      .map((val) => val.trim())
      .filter((val) => val)
    return values
      .map(
        (val) =>
          `(${lambdaParameter}.Id.ToString().Contains("${val}") || "${val}".Contains(${lambdaParameter}.Id.ToString()) || ${lambdaParameter}.Note.Contains("${val}") || "${val}".Contains(${lambdaParameter}.Note))`,
      )
      .join(' && ')
  },
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
