import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import { CSI_URL } from '../api-urls'
import {
  get,
  getWithPagination,
  post,
  recurringJobParamsComposer,
} from '../common/fetch.utils'
import { filterMapper } from './sync-log-filter.mapper'
import { mapSortLogParam } from './sync-log-sort.mapper'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { SyncLogDto } from '../dto/sync-log.dto'
import { DataProviderExtensionResult } from '../common/data-provider'
import { RecurringJobCreationParamsDto } from '../dto/recurring-job-creation-params.dto'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<SyncLogDto>> => {
    const filterParams = `a=>${filterParamsComposer('a', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortLogParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<SyncLogDto[]>(
      `${CSI_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<SyncLogDto>> => {
    const data = await get<SyncLogDto>(CSI_URL, `/${id}`)

    return {
      data,
    }
  },
  syncStadiumBans: async (
    resource: string,
    params: RecurringJobCreationParamsDto,
  ): Promise<
    DataProviderExtensionResult<undefined | RecurringJobDetailsDto>
  > => {
    const query =
      params &&
      recurringJobParamsComposer(params as RecurringJobCreationParamsDto)

    const data = await post<undefined, undefined | RecurringJobDetailsDto>(
      `${CSI_URL}/SyncStadiumBans${query ? `?${query}` : ''}`,
      undefined,
    )

    return {
      data,
    }
  },
} as SyncLogsProvider

interface SyncLogsProvider extends DataProvider {
  syncStadiumBans: (
    resource: string,
    params: RecurringJobCreationParamsDto,
  ) => Promise<DataProviderExtensionResult<undefined | RecurringJobDetailsDto>>
}

export default provider
