import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  Record,
  ReferenceField,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { getContrastColor } from '../../../../core/common/visual.utils'
import { LogSchemeDataDto } from '../../../../core/dto/log-scheme-data.dto'
import { ResourceName } from '../../../../core/ResourceName'
import List from '../../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../../common/FilteredReferenceInput'
import FilterProps from '../../../common/FilterProps'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { SchemeDto } from '../../../../core/dto/scheme/scheme.dto'

const LogSchemeDataFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Filter {...props}>
      {hasAuthority(Authority.VIEW_SCHEMES) && (
        <FilteredReferenceInput
          label="resources.logs.filters.schemeId"
          source="schemeIdWithDescendants"
          reference={ResourceName.SCHEMES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="translatedLogSchemeDataTextSearch"
          selectWithPaginationInputProps={{
            optionText: 'translatedLogSchemeDataHierarchyString',
            showFilter: true,
          }}
        />
      )}
      <TextInput source="language" />
    </Filter>
  )
}

export const LogSchemeDataList = ({ ...props }: ListProps) => {
  const translate = useTranslate()
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const hasAuthority = useHasAuthority()

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
      filters={<LogSchemeDataFilters />}
      bulkActionButtons={false}
      title="resources.log-scheme-data.titles.list"
    >
      <Datagrid
        size="small"
        rowClick={(id) => `/${ResourceName.LOG_SCHEME_DATA}/${id}/show`}
        rowStyle={(record: Record) => ({
          backgroundColor: record?.color,
          color: record?.color ? getContrastColor(record?.color) : undefined,
        })}
      >
        {!xSmallScreen && <TextField source="id" label="ID" />}
        <FunctionField<LogSchemeDataDto>
          source="language"
          render={(record?: LogSchemeDataDto) =>
            translate(`settings.language.${record?.language}`)
          }
        />
        <TextField source="text" sortable={false} />
        <TextField source="color" sortable={false} />
        {hasAuthority(Authority.VIEW_SCHEMES) && (
          <ReferenceField
            source="schemeId"
            reference={ResourceName.SCHEMES}
            label="resources.log-scheme-data.fields.scheme"
            link={false}
          >
            <FunctionField<SchemeDto>
              source="name"
              render={(scheme?: SchemeDto) => `${scheme?.id}. ${scheme?.name}`}
            />
          </ReferenceField>
        )}
      </Datagrid>
    </List>
  )
}
