import { DataProvider, UpdateResult } from 'react-admin'
import { BookingDto } from '../../dto/booking.dto'
import { BookingDisabledOption } from '../../enum/BookingDisabledOption'
import { BookingType } from '../../enum/BookingType'

const fakeBookingsList: BookingDto[] = [
  {
    id: 1,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 2,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 3,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 4,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 5,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 6,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 7,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 8,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 9,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 10,
    type: BookingType.NORMAL,
    disabled: BookingDisabledOption.STORNO,
    activeFrom: '2021-07-15T08:50:27.2054',
    activeTo: '2021-07-15T08:50:27.2054',
    eventId: 0,
    seatId: 0,
    blockId: null,
    customerId: 0,
    variantId: 0,
    ticketsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeBookingsList, total: fakeBookingsList.length }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update: async (resource, params): Promise<UpdateResult<unknown>> =>
    Promise.resolve({
      data: { id: 1 },
    }),
  getOne: (resource, { id }: { id: string }) => {
    const found = fakeBookingsList.find((v) => `${v.id}` === id)
    if (found) {
      return Promise.resolve({
        data: found,
      })
    }
    return Promise.reject(new Error())
  },
} as DataProvider

export default provider
