export enum TicketImportStates {
  CREATED = 'CREATED',
  IMPORTING = 'IMPORTING',
  IMPORTED = 'IMPORTED',
  IMPORTED_WITHOUT_STATE = 'IMPORTED_WITHOUT_STATE',
  IMPORT_ERROR = 'IMPORT_ERROR',
  REVERTING = 'REVERTING',
  REVERTED = 'REVERTED',
  REVERT_ERROR = 'REVERT_ERROR',
  ACCEPTING = 'ACCEPTING',
  ACCEPTED = 'ACCEPTED',
  ACCEPTING_ERROR = 'ACCEPTING_ERROR',
}
