import { HttpError } from 'react-admin'
import {
  FETCH_ERROR,
  HIDE_API_ERROR_MODAL,
  SHOW_API_ERROR_MODAL,
} from '../constants/reducer.actions'
import { ApiErrorBodyDto } from '../dto/error-handling/api-error-body.dto'

export type ApiErrorAction = {
  type: typeof FETCH_ERROR
  error?: HttpError
}

export interface ApiErrorModalPayload extends ApiErrorBodyDto {
  httpCode: number
}

export type ApiErrorModalAction = {
  type: typeof SHOW_API_ERROR_MODAL | typeof HIDE_API_ERROR_MODAL
  payload?: HttpError
}

const initialState = () => ({
  show: false,
})

function apiErrorModalReducer(
  state = initialState(),
  action: ApiErrorModalAction,
) {
  const { type, payload } = action

  switch (type) {
    case SHOW_API_ERROR_MODAL:
      return {
        show: true,
        httpCode: payload?.status,
        ...payload?.body,
      }
    case HIDE_API_ERROR_MODAL:
      return {
        show: false,
      }
    default:
      return state
  }
}

export default apiErrorModalReducer
