import { DataProvider } from 'react-admin'
import { EventDto } from '../../dto/event/event.dto'
import { EventStatus } from '../../enum/EventStatus'

const fakeEvents: EventDto[] = [
  {
    id: 1,
    name: 'Event1',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 2,
    name: 'Event2',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 3,
    name: 'Event3',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 4,
    name: 'Event4',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 5,
    name: 'Event5',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 6,
    name: 'Event6',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 7,
    name: 'Event7',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 8,
    name: 'Event8',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 9,
    name: 'Event9',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
  {
    id: 10,
    name: 'Event10',
    dateStart: '2021-07-15T08:50:27.2054',
    dateEnd: '2021-07-15T08:50:27.2054',
    status: EventStatus.INACTIVE,
    externalId: '1',
    infix: 'abc123',
    objectsIds: [1, 2, 3],
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeEvents, total: fakeEvents.length }),
} as DataProvider

export default provider
