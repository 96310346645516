import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import List from '../common/customized-ra-components/List'
import { TicketImportDto } from '../../core/dto/ticket-import.dto'
import { ResourceName } from '../../core/ResourceName'

const TicketImportsFilters = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

export const TicketImportsList = (props: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const translate = useTranslate()

  return (
    <List
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<TicketImportsFilters />}
      {...props}
      bulkActionButtons={false}
      exporter={false}
      empty={false}
    >
      <Datagrid padding={xSmallScreen ? 'checkbox' : 'default'} rowClick="show">
        <TextField source="id" label="Id" />
        <TextField source="name" />
        <TextField source="originalFileName" />
        {!smallScreen && (
          <ReferenceField
            source="lastJobId"
            reference={ResourceName.JOBS}
            link={false}
          >
            <TextField source="jobId" />
          </ReferenceField>
        )}
        <FunctionField<TicketImportDto>
          source="state"
          render={(r?: TicketImportDto) => (
            <div>
              {translate(`resources.enums.TicketImportStates.${r?.state}`)}
            </div>
          )}
        />
      </Datagrid>
    </List>
  )
}
