import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  Identifier,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyResult,
} from 'react-admin'
import { get, getWithPagination, patch, post } from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { VerifierDeviceDisabledDto } from '../../dto/device/verifier-device-disabled/verifier-device-disabled.dto'
import { VERIFIER_DEVICE_DISABLED_URL } from '../../api-urls'
import filterMapper from './verifier-device-disabled-filter.mapper'
import { mapSortVerifierDeviceDisabledParam } from './verifier-device-disabled-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<VerifierDeviceDisabledDto>> => {
    const filterParams = `e=>${filterParamsComposer('e', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortVerifierDeviceDisabledParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<VerifierDeviceDisabledDto[]>(
      `${VERIFIER_DEVICE_DISABLED_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<VerifierDeviceDisabledDto>> => {
    const data = await get<VerifierDeviceDisabledDto>(
      VERIFIER_DEVICE_DISABLED_URL,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<VerifierDeviceDisabledDto>> => {
    const data = await get<VerifierDeviceDisabledDto[]>(
      VERIFIER_DEVICE_DISABLED_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<VerifierDeviceDisabledDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      VerifierDeviceDisabledDto
    >(VERIFIER_DEVICE_DISABLED_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
      description:
        data.description !== previousData.description
          ? data.description
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateVerifierDeviceDisabledRequest>,
  ): Promise<CreateResult<VerifierDeviceDisabledDto>> => {
    const camera = await post<
      CreateVerifierDeviceDisabledRequest[],
      VerifierDeviceDisabledDto[]
    >(VERIFIER_DEVICE_DISABLED_URL, [data])
    return {
      data: camera[0],
    }
  },
} as DataProvider

export interface CreateVerifierDeviceDisabledRequest {
  readonly name: string
  readonly description?: string
}

export default provider
