import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { CustomerDto } from '../dto/customer.dto'
import { CUSTOMERS_URL } from '../api-urls'
import { get, getWithPagination, patch, post } from '../common/fetch.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import filterMapper from './customer-filter.mapper'
import { mapSortCustomerParams } from './customer-sort.mapper'
import { DateTime, ignoreTimezoneOffset } from '../common/date-time'
import { CustomerDocumentType } from '../enum/CustomerDocumentType'
import { Gender } from '../enum/Gender'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<CustomerDto>> => {
    const filterParams = `c=>${filterParamsComposer('c', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortCustomerParams,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<CustomerDto[]>(
      `${CUSTOMERS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id: customerId }: GetOneParams,
  ): Promise<GetOneResult<CustomerDto>> => {
    const customer = await get<CustomerDto>(CUSTOMERS_URL, `/${customerId}`)
    return {
      data: customer as CustomerDto,
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<CustomerDto>> => {
    const data = await get<CustomerDto[]>(
      `${CUSTOMERS_URL}/GetByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { data, previousData }: UpdateParams<CustomerDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<UpdateCustomerRequest, CustomerDto>(
      CUSTOMERS_URL,
      {
        id: data.id,
        firstname:
          data.firstname !== previousData.firstname
            ? data.firstname
            : undefined,
        lastname:
          data.lastname !== previousData.lastname ? data.lastname : undefined,
        company:
          data.company !== previousData.company ? data.company : undefined,
        birthdate:
          data.birthdate !== previousData.birthdate
            ? (ignoreTimezoneOffset(
                new Date(data.birthdate),
              ).toISOString() as DateTime)
            : undefined,
        documentType:
          data.documentType !== previousData.documentType
            ? data.documentType
            : undefined,
        pesel: data.pesel !== previousData.pesel ? data.pesel : undefined,
        documentNumber:
          data.documentNumber !== previousData.documentNumber
            ? data.documentNumber
            : undefined,
        nationality:
          data.nationality !== previousData.nationality
            ? data.nationality
            : undefined,
        gender: data.gender !== previousData.gender ? data.gender : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CustomerDto>,
  ): Promise<CreateResult> => {
    const created = await post<CreateCustomerRequest[], CustomerDto[]>(
      CUSTOMERS_URL,
      [
        {
          ...data,
          birthdate:
            data.birthdate &&
            (ignoreTimezoneOffset(
              new Date(data.birthdate),
            ).toISOString() as DateTime),
        },
      ],
    )
    return Promise.resolve({ data: created[0] })
  },
} as DataProvider

interface CreateCustomerRequest {
  readonly firstname: string
  readonly lastname: string
  readonly company?: string
  readonly birthdate?: DateTime
  readonly documentType?: CustomerDocumentType
  readonly pesel?: string
  readonly documentNumber?: string
  readonly nationality?: string
  readonly gender?: Gender
}

interface UpdateCustomerRequest {
  readonly id: number
  readonly firstname?: string
  readonly lastname?: string
  readonly company?: string
  readonly birthdate?: DateTime
  readonly documentType?: CustomerDocumentType
  readonly pesel?: string
  readonly documentNumber?: string
  readonly nationality?: string
  readonly gender?: Gender
}

export default provider
