import { MenuItem, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useListContext,
  sanitizeFetchType,
  GET_MANY,
  GetManyResult,
  useTranslate,
} from 'react-admin'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { ApiErrorDto } from '../../../../core/dto/error-handling/api-error.dto'
import {
  ProgressStatus,
  StateBulkProgressDialog,
} from '../../../common/StateBulkProgressDialog'
import { EngineDto } from '../../../../core/dto/device/engine/engine.dto'
import { BulkActionProps } from '../../../common/BulkActionsProps'
import { StanbyMode } from '../../../../core/enum/StanbyMode'
import { SetStandbyModeDialog } from '../EngineDialogs'

export const EngineStandbyModeBulkButton = ({
  selectedIds,
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  innerRef,
  asRecurringJob,
  ...props
}: BulkActionProps) => {
  const { loading, refetch } = useListContext<EngineDto>()
  const notify = useNotify()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const [standbyModeDialogOpen, setStandbyModeDialogOpen] = useState(false)
  const [mutate, { loading: mutationLoading }] = useMutation()
  const [standbyMode, setStandbyMode] = useState<StanbyMode | undefined>(
    undefined,
  )
  const [bulkStateDialogOpen, setBulkStateDialogOpen] = useState(false)
  const [bulkProgressStatus, setBulkProgressStatus] = useState<
    ProgressStatus[]
  >([])
  const updateBulkProgressStatus = (newStatus: ProgressStatus) => {
    const oldStatusIndex = bulkProgressStatus.findIndex(
      (s) => s.id === newStatus.id,
    )
    bulkProgressStatus.splice(oldStatusIndex, 1, newStatus)
  }
  const [selectedEngines, setSelectedEngines] = useState<EngineDto[]>([])

  const handleMutation = async (
    engine: EngineDto,
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    let error
    let query
    if (!asRecurringJob) {
      query = {
        type: sanitizeFetchType('setStandbyMode'),
        resource: ResourceName.ENGINES,
        payload: {
          engineId: engine?.id,
          stanbyMode: standbyMode,
        },
      }
    } else {
      query = {
        type: sanitizeFetchType('setStandbyMode'),
        resource: ResourceName.ENGINES,
        payload: {
          engineId: engine?.id,
          stanbyMode: standbyMode,
          recurringJobName,
          cronExpression,
        },
      }
    }
    let result = null
    try {
      result = await mutate(query, {
        returnPromise: true,
      })
    } catch (err) {
      error = err
    }
    if (error) {
      updateBulkProgressStatus({
        id: engine.id,
        status: 'error',
        error: error as ApiErrorDto,
      } as ProgressStatus)
      return error
    }
    updateBulkProgressStatus({
      id: engine.id,
      status: 'success',
    } as ProgressStatus)
    return result
  }

  const handleStandbyMode = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    setBulkStateDialogOpen(true)
    setStandbyModeDialogOpen(false)
    const pendingRequests: Promise<any>[] = []
    let selectedEnginesResponse
    try {
      selectedEnginesResponse = (await mutate(
        {
          type: sanitizeFetchType(GET_MANY),
          resource: ResourceName.ENGINES,
          payload: {
            ids: selectedIds,
          },
        },
        { returnPromise: true, onFailure: (msg) => notify(msg, 'error') },
      )) as GetManyResult<EngineDto>
    } catch (err) {
      setBulkStateDialogOpen(false)
      notify((err as Error)?.message, 'error')
      return
    }
    if (!selectedEnginesResponse.data.length) {
      setBulkStateDialogOpen(false)
      return
    }
    selectedEnginesResponse.data.forEach((engine: EngineDto) => {
      pendingRequests.push(
        handleMutation(engine, recurringJobName, cronExpression),
      )
      selectedEngines.push(engine)
      bulkProgressStatus.push({
        id: engine.id,
        status: 'pending',
      } as ProgressStatus)
    })
    await Promise.all(pendingRequests)
    refetch()
  }

  useEffect(() => {
    if (!bulkStateDialogOpen) {
      setBulkProgressStatus([])
      setSelectedEngines([])
    }
  }, [bulkStateDialogOpen])

  return (
    <MenuItem
      {...props}
      disabled={
        !selectedIds.length ||
        !hasAuthority(Authority.ENGINES_SET_STANDBY_MODE_BUTTON) ||
        loading ||
        mutationLoading
      }
      onClick={() => setStandbyModeDialogOpen(true)}
      button
    >
      <Typography>
        {translate(
          !asRecurringJob
            ? 'resources.engines.actions.setStandbyMode'
            : 'resources.engines.actions.setStandbyMode-recurring',
        )}
      </Typography>
      <SetStandbyModeDialog
        open={standbyModeDialogOpen}
        close={() => setStandbyModeDialogOpen(false)}
        standbyMode={standbyMode}
        setStandbyMode={setStandbyMode}
        handleStandbyMode={handleStandbyMode}
        asRecurringJob={asRecurringJob}
      />
      <StateBulkProgressDialog
        open={bulkStateDialogOpen}
        close={() => setBulkStateDialogOpen(false)}
        elements={selectedEngines}
        method="setStandbyMode"
        statuses={bulkProgressStatus}
        resource={ResourceName.ENGINES}
      />
    </MenuItem>
  )
}
