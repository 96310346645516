import { Box, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  DeleteWithConfirmButton,
  EditButton,
  FormDataConsumer,
  FunctionField,
  List,
  Pagination,
  ResourceContextProvider,
  SelectInput,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutProps,
  TextField,
  TextInput,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { TicketImportDto } from '../../core/dto/ticket-import.dto'
import { ResourceName } from '../../core/ResourceName'
import { Authority } from '../../core/auth/Authority'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { TicketImportTemplateDto } from '../../core/dto/ticket-import-template.dto'
import EditableDatagrid from '../common/customized-ra-components/EditableDatagrid'
import { RowForm } from '../../lib/@react-admin/ra-editable-datagrid/esm/src'
import { ImportProperties } from '../../core/enum/ImportProperties'
import { TicketImportMappingDto } from '../../core/dto/ticket-import-mapping.dto'
import { validateTicketImportMapping } from '../ticket-import-mappings/TicketImportMappingCreate'
import { TicketImportButton } from '../ticket-imports/TicketImportButton'
import { MappingStaticValueInput } from './ticket-import-templates-show/MappingStaticValueInput'
import { transformRowFormEditData } from '../common/transformRowFormEditData'
import { MappingStaticValueField } from './ticket-import-templates-show/MappingStaticValueField'

const ShowTitle = (props: { record?: TicketImportDto }) => {
  const { record: ticketImport } = props
  const translate = useTranslate()

  return (
    <span>
      {translate('resources.ticket-import-templates.titles.show')}:{' '}
      {ticketImport?.id}. {ticketImport?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: template } = useShowContext<TicketImportTemplateDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item style={{ margin: '2px' }}>
            <TicketImportButton dialogProps={{ templateId: template?.id }} />
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DeleteWithConfirmButton
              basePath={`/${ResourceName.TICKET_IMPORT_TEMPLATES}`}
              record={template}
              variant="contained"
              disabled={!hasAuthority(Authority.DELETE_TICKET_IMPORT_TEMPLATES)}
            />
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <EditButton
              basePath={`/${ResourceName.TICKET_IMPORT_TEMPLATES}`}
              record={template}
              variant="contained"
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_TICKET_IMPORT_TEMPLATES)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const ImportMappingPropertyInput = ({ ...props }) => {
  const { change } = useForm()

  const handleChange = () => {
    change('csvPropertyName', undefined)
    change('staticValue', undefined)
  }

  return (
    <SelectInput
      {...props}
      source="property"
      choices={Object.entries(ImportProperties).map(([value]) => ({
        id: value,
        name: `resources.enums.ImportProperties.${value}`,
      }))}
      onChange={handleChange}
    />
  )
}

const ImportMappingCreate = ({ ...props }) => {
  const { total, ...rest } = props
  const { record: template } = useShowContext()

  return (
    <RowForm
      {...rest}
      initialValues={{
        importTemplateId: template?.id,
      }}
      validate={validateTicketImportMapping}
    >
      {total && <TextField source="id" label="Id" />}
      <ImportMappingPropertyInput />
      <TextInput source="csvPropertyName" defaultValue={null} fullWidth />
      <FormDataConsumer>
        {({ formData, ...inputProps }) => (
          <MappingStaticValueInput
            {...inputProps}
            property={formData?.property}
          />
        )}
      </FormDataConsumer>
    </RowForm>
  )
}

const ImportMappingEdit = ({ ...props }) => {
  const { total, ...rest } = props

  const handleTransform = (data: any) =>
    transformRowFormEditData(data, ['csvPropertyName', 'staticValue'])

  return (
    <RowForm
      {...rest}
      transform={handleTransform}
      validate={validateTicketImportMapping}
    >
      {total && <TextField source="id" label="Id" />}
      <ImportMappingPropertyInput />
      <TextInput source="csvPropertyName" fullWidth />
      <FormDataConsumer>
        {({ formData, ...inputProps }) => (
          <MappingStaticValueInput
            {...inputProps}
            property={formData?.property}
          />
        )}
      </FormDataConsumer>
    </RowForm>
  )
}

const ImportMappingsGrid = ({ ...props }) => {
  const { total } = props
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <EditableDatagrid
      {...props}
      padding={smallScreen ? 'checkbox' : 'default'}
      actions={
        hasAuthority(Authority.EDIT_TICKET_IMPORT_MAPPINGS) ? undefined : false
      }
      editForm={<ImportMappingEdit total={total} />}
      createForm={
        hasAuthority(Authority.EDIT_TICKETS) &&
        hasAuthority(Authority.CREATE_TICKETS) ? (
          <ImportMappingCreate total={total} />
        ) : undefined
      }
      hasBulkActions={false}
      defaultTitle={null}
    >
      <TextField source="id" label="ID" />
      <FunctionField<TicketImportMappingDto>
        source="property"
        render={(r?: TicketImportMappingDto) => (
          <div>
            {translate(`resources.enums.ImportProperties.${r?.property}`)}
          </div>
        )}
      />
      <TextField source="csvPropertyName" />
      <MappingStaticValueField source="staticValue" />
    </EditableDatagrid>
  )
}

const ImportMappingsTab = () => {
  const { record: template, basePath } = useShowContext()

  return (
    <ResourceContextProvider value={ResourceName.TICKET_IMPORT_MAPPINGS}>
      <List
        exporter={false}
        perPage={20}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} />}
        bulkActionButtons={false}
        basePath={basePath}
        filter={{
          templateId: template?.id,
        }}
        actions={false}
        title=" "
        empty={false}
      >
        <ImportMappingsGrid />
      </List>
    </ResourceContextProvider>
  )
}

const TicketImportTemplateTabs = ({
  ...props
}: Omit<TabbedShowLayoutProps, 'children'>) => {
  const hasAuthority = useHasAuthority()
  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.ticket-import-templates.tabs.general">
        <TextField source="id" label="Id" />
        <TextField source="name" />
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_TICKET_IMPORT_MAPPINGS) && (
        <Tab
          label="resources.ticket-import-templates.tabs.mappings"
          path="mappings"
        >
          <ImportMappingsTab />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const TicketImportTemplateShow = ({ ...props }: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <TicketImportTemplateTabs />
  </Show>
)
