import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
  BooleanInput,
} from 'react-admin'
import { VariantDto } from '../../../core/dto/variant.dto'
import { CreateRecurringJobUsingSaveContextButton } from '../../common/CreateRecurringJobButton'
import DropdownButton from '../../common/DropdownButton'

const validateVariantEdit = (values: VariantDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

interface VariantTitleProps extends TitleProps {
  record?: VariantDto
}

const EditTitle = (data: VariantTitleProps) => {
  const { record } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.variants.titles.edit')}: {record?.id}.{' '}
      {record?.name}{' '}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <DropdownButton
      size="small"
      mainAction={
        <SaveButton variant="contained" size="small" color="primary" />
      }
    >
      <CreateRecurringJobUsingSaveContextButton
        size="small"
        variant="text"
        color="default"
      />
    </DropdownButton>
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const VariantEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <SimpleForm
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateVariantEdit}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" isRequired />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
)
