const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  firstPointReference: (lambdaParameter, value) =>
    `firstPointReference=${value}`,
  minutesInterval: (lambdaParameter, value) => `minutesInterval=${value}`,
  deviceId: (lambdaParameter, value) => `deviceId=${value}`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
