import {
  CreateProps,
  GetListResult,
  useMutation,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin'
import React, {
  Dispatch,
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
} from 'react'
import { Box, Typography } from '@material-ui/core'
import { ImportCsvPreview } from './ImportCsvPreview'
import { ImportView } from './ImportView'
import {
  applyMappingsToCsvData,
  csvDataInitialState,
  csvDataReducer,
  FormMappings,
  IMPORT_PROVIDER_METHOD_TYPE,
  processCsvData,
} from '../../../core/csv'
import { ImportCsvButton } from './ImportCsvButton'
import { TicketDto } from '../../../core/dto/ticket.dto'

interface ImportBaseProps extends CreateProps {
  readonly children: ReactElement
  readonly title: string
  readonly columnNamesDispatcher: Dispatch<string[]>
  readonly formValuesMapper: (values: FormMappings) => FormMappings
  readonly onSuccess?: (response: { data: any }) => void
}

export const ImportBase = (props: ImportBaseProps) => {
  const {
    columnNamesDispatcher,
    formValuesMapper,
    resource,
    onSuccess,
    ...rest
  } = props
  const t = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const [mutate, { loading }] = useMutation()
  const [{ columnNames, previewRows, csvData }, csvDataDispatcher] = useReducer(
    csvDataReducer,
    csvDataInitialState,
  )

  useEffect(() => {
    columnNamesDispatcher(columnNames)
  }, [columnNamesDispatcher, columnNames])

  const handleOnSuccess = useCallback(() => {
    notify('common.import.import-success-message', 'success')
    redirect('list', `/${resource}`)
  }, [notify, redirect, resource])

  const onFailure = useCallback(
    (error) => {
      // eslint-disable-next-line no-console
      console.error('Error during importing file', error)
      notify('common.import.import-error-message', 'warning')
    },
    [notify],
  )

  const createMany = useCallback(
    (data: any) =>
      mutate(
        {
          type: IMPORT_PROVIDER_METHOD_TYPE,
          resource,
          payload: {
            data,
          },
        },
        {
          returnPromise: true,
          onSuccess: onSuccess || handleOnSuccess,
          onFailure,
        },
      ) as Promise<GetListResult<TicketDto>>,
    [resource, mutate, onSuccess, onFailure, handleOnSuccess],
  )

  const onSave = useCallback(
    async (formMappings: FormMappings) => {
      const processedMappings = formValuesMapper(formMappings)
      const processedData = processCsvData(columnNames, csvData)
      const mappedData = applyMappingsToCsvData(
        processedMappings,
        processedData,
      )
      await createMany(mappedData)
    },
    [createMany, columnNames, csvData, formValuesMapper],
  )

  return (
    <Box>
      <ImportCsvButton csvDataDispatcher={csvDataDispatcher} />
      {!!columnNames?.length && (
        <Box>
          <Typography variant="caption">
            {t('common.import.preview-caption')}
          </Typography>
          <ImportCsvPreview columnNames={columnNames} rows={previewRows} />
          <Box mt={2} ml={2}>
            <Typography variant="h5">
              {t('common.import.mapping-subtitle')}
            </Typography>
          </Box>
          <ImportView
            onSave={onSave}
            saving={loading}
            resource={resource}
            {...rest}
          />
        </Box>
      )}
    </Box>
  )
}
