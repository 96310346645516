export type ContentRangeHeaderValue = `bytes ${number}-${number}/${number}`

export const contentRangeParser = (headerValue: ContentRangeHeaderValue) => {
  if (!headerValue) {
    // 'content-range' header should exist otherwise pagination doesn't work
    return {
      total: 0,
    }
  }
  const summary = headerValue.split(' ')[1]
  const split = summary.split('-')
  const rangeFrom = split[0]
  const summarySecondPartSplit = split[1].split('/')
  const rangeTo = summarySecondPartSplit[0]
  const total = summarySecondPartSplit[1]
  return {
    rangeFrom: parseInt(rangeFrom, 10),
    rangeTo: parseInt(rangeTo, 10),
    total: parseInt(total, 10),
  }
}
