import * as React from 'react'
import {
  CreateButton,
  CreateButtonProps,
  useResourceContext,
} from 'react-admin'
import { useHistory } from 'react-router-dom'

interface ParameterizedCreateButtonProps extends CreateButtonProps {
  searchParams?: any
}

const ParameterizedCreateButton = ({
  ...props
}: ParameterizedCreateButtonProps) => {
  const { disabled } = props
  const history = useHistory()
  const resource = useResourceContext()

  const onClick = (e: any) => {
    if (props.searchParams) {
      e.preventDefault()
      history.push({
        pathname: props.basePath
          ? `${props.basePath}/create`
          : `/${resource}/create`,
        state: { _scrollToTop: props.scrollToTop },
        search: new URLSearchParams(props.searchParams).toString(),
      })
    }
  }

  return <CreateButton onClick={onClick} disabled={disabled} />
}

export default ParameterizedCreateButton
