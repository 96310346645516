import { sanitizeFetchType } from 'react-admin'
import { PalmDto } from '../../dto/device/palm/palm.dto'
import { ResourceName } from '../../ResourceName'
import {
  ToggleButtonAction,
  ToggleButtonState,
  ToggleType,
} from './palm-device-id-button'

type Mutation = {
  type: string
  resource: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
}

const setIdMutation = ({ id }: PalmDto, mobileDeviceId?: string): Mutation => ({
  type: sanitizeFetchType('setMobileDeviceId'),
  resource: ResourceName.PALMS,
  payload: {
    palmId: id,
    mobileDeviceId,
  },
})

const resetIdMutation = ({ id }: PalmDto): Mutation => ({
  type: sanitizeFetchType('resetMobileDeviceId'),
  resource: ResourceName.PALMS,
  payload: {
    palmId: id,
  },
})

type State = {
  mutateQuery?: (palm: PalmDto, mobileDeviceId?: string) => Mutation
} & ToggleButtonState

export const palmDeviceIdButtonInitialState = {
  type: ToggleType.RESETID,
  text: 'resources.palms.actions.resetMobileDeviceId',
}

export const palmDeviceIdButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case ToggleType.SETID: {
      return {
        type,
        text: 'resources.palms.actions.setMobileDeviceId',
        mutateQuery: setIdMutation,
      }
    }
    case ToggleType.RESETID: {
      return {
        type,
        text: 'resources.palms.actions.resetMobileDeviceId',
        mutateQuery: resetIdMutation,
      }
    }
    default:
      throw new Error()
  }
}
