import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  useTranslate,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  ToolbarProps,
  TextField,
  ShowButton,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { CameraDto } from '../../../../core/dto/device/camera/cameras/camera.dto'
import { ResourceName } from '../../../../core/ResourceName'
import FilteredReferenceInput from '../../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../../hooks/useHasAuthority'

export const validateCameraEdit = (values: CameraDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.description) {
    errors.desciption = 'ra.validation.required'
  }
  if (!values.serverSideName) {
    errors.serverSideName = 'ra.validation.required'
  }
  if (!values.serverId) {
    errors.serverId = 'ra.validation.required'
  }
  return errors
}

const EditTitle = ({ ...props }) => {
  const { record: camera } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.cameras.titles.edit')}: {camera?.id}.{' '}
      {camera?.description}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const Camera = ({ ...props }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <SimpleForm
      {...props}
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateCameraEdit}
    >
      <TextField source="id" label="ID" />
      <TextInput source="description" isRequired />
      <TextInput source="serverSideName" isRequired />
      {hasAuthority(Authority.VIEW_CAMERA_SERVERS) && (
        <FilteredReferenceInput
          source="serverId"
          defaultValue={null}
          reference={ResourceName.CAMERA_SERVERS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          isRequired
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </SimpleForm>
  )
}

export const CameraEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <Camera />
  </Edit>
)
