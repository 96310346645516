import { Box, Divider, makeStyles } from '@material-ui/core'
import React from 'react'
import {
  CreateButton,
  EditButton,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TitleProps,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { DeviceCategoryDto } from '../../core/dto/device-category/device-category.dto'
import { ShowNode } from '../../lib/@react-admin/ra-tree/esm/src'
import { useHasAuthority } from '../hooks/useHasAuthority'

const useStyles = makeStyles({
  button: {
    margin: '2px',
  },
})

interface DeviceCategoryTitleProps extends TitleProps {
  record?: DeviceCategoryDto
}

const ShowTitle = (data: DeviceCategoryTitleProps) => {
  const { record: deviceCategory } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.device-categories.titles.show')}:{' '}
      {deviceCategory?.id}. {deviceCategory?.name}
    </span>
  )
}

const GeneralActions = ({ ...props }) => {
  const { basePath, record: category } = props
  const classes = useStyles()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        {hasAuthority(Authority.CREATE_DEVICE_CATEGORIES) && (
          <CreateButton
            {...props}
            basePath={basePath}
            className={classes.button}
            label="ra-tree.action.add_child"
            to={{
              pathname: `${basePath}/create`,
              state: { parentId: category?.id },
            }}
            variant="contained"
          />
        )}
        {hasAuthority(Authority.EDIT_DEVICE_CATEGORIES) && (
          <EditButton
            {...props}
            basePath={basePath}
            className={classes.button}
            variant="contained"
          />
        )}
      </Box>
    </>
  )
}

const DeviceCategroy = ({ ...props }) => (
  <SimpleShowLayout {...props}>
    <TextField label="ID" source="id" />
    <TextField source="name" />
    <GeneralActions />
  </SimpleShowLayout>
)

interface ShowNodeProps extends ShowProps {
  syncWithLocation?: boolean
}

export const DeviceCategoryShow = ({
  //TreeWithDetails' props include syncWithLocation variable, which generates error when we pass it over to EditNode
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  syncWithLocation,
  ...props
}: ShowNodeProps) => (
  <ShowNode {...props} actions={false} title={<ShowTitle />}>
    <DeviceCategroy />
  </ShowNode>
)
