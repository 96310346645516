import { uniq } from 'lodash'
import { SCHEMES_URL } from '../api-urls'
import { get, getByConditions } from '../common/fetch.utils'
import { filterParamsComposer } from '../common/get-by-conditions.utils'
import { SchemeDto } from '../dto/scheme/scheme.dto'
import schemesFilterMapper from './scheme-filter.mapper'

// Tree type filter for schemes
const getSchemeChildren = async (schemeId: number) => {
  let schemesIds: number[] = []
  // eslint-disable-next-line no-await-in-loop
  const selectedScheme = await get<SchemeDto>(SCHEMES_URL, `/${schemeId}`)
  schemesIds.push(selectedScheme?.id)
  let resolveParents = true
  while (resolveParents) {
    const parentSchemesfilters = { parentIds: schemesIds }
    // eslint-disable-next-line no-await-in-loop
    const childrenCategories = await getByConditions<SchemeDto>(
      `${SCHEMES_URL}`,
      `o=>${filterParamsComposer(
        'o',
        parentSchemesfilters,
        schemesFilterMapper,
      )}`,
    )
    const newChildren = childrenCategories
      .map((cs) => cs.id)
      // eslint-disable-next-line no-loop-func
      .filter((cs) => !schemesIds.includes(cs))
    if (newChildren.length === 0) {
      resolveParents = false
    } else {
      schemesIds = schemesIds.concat(newChildren)
    }
  }
  return schemesIds
}

// Tree type method for fetch logSchemeData reladed to specified scheme (get parents from child)
const getParentSchemesRelatedSchemeData = async (schemeIds: number[]) => {
  let parentSchemesIds: number[] = []
  let parentSchemes: SchemeDto[] = []
  let parentSchemesIdsToResolve: number[] = []
  let logSchemeDataIds: number[] = []
  let palmSchemeDataIds: number[] = []
  // eslint-disable-next-line no-await-in-loop
  const selectedSchemes = await get<SchemeDto[]>(
    SCHEMES_URL,
    `/GetByConditions/e=>new int[] {${schemeIds.toString()}}.Contains(e.Id)`,
  )
  const selectedSchemesWithParents = selectedSchemes.filter((ss) => ss.parentId)

  parentSchemesIds = parentSchemesIds.concat(
    selectedSchemesWithParents?.map((ss) => Number(ss.parentId)),
  )
  parentSchemes = parentSchemes.concat(selectedSchemesWithParents)
  parentSchemesIdsToResolve = parentSchemesIdsToResolve.concat(parentSchemesIds)

  logSchemeDataIds = logSchemeDataIds.concat(
    selectedSchemes?.flatMap((ss) => ss.logSchemeDataIds),
  )

  palmSchemeDataIds = palmSchemeDataIds.concat(
    selectedSchemes?.flatMap((ss) => ss.palmSchemeDataIds),
  )

  while (parentSchemesIdsToResolve.length > 0) {
    const parentSchemesfilters = { ids: parentSchemesIdsToResolve }
    // eslint-disable-next-line no-await-in-loop
    const newParentSchemes = await getByConditions<SchemeDto>(
      `${SCHEMES_URL}`,
      `o=>${filterParamsComposer(
        'o',
        parentSchemesfilters,
        schemesFilterMapper,
      )}`,
    )
    parentSchemesIdsToResolve = []
    parentSchemesIdsToResolve = parentSchemesIdsToResolve.concat(
      newParentSchemes
        .filter((np) => np.parentId)
        .map((np) => Number(np.parentId)),
    )
    if (newParentSchemes.length > 0) {
      parentSchemesIds = parentSchemesIds.concat(parentSchemesIdsToResolve)
      parentSchemes = parentSchemes.concat(newParentSchemes)
      logSchemeDataIds = logSchemeDataIds.concat(
        newParentSchemes.flatMap((np) => np.logSchemeDataIds),
      )
      palmSchemeDataIds = palmSchemeDataIds.concat(
        newParentSchemes.flatMap((np) => np.palmSchemeDataIds),
      )
    }
  }
  return {
    logSchemeDataIds: uniq(logSchemeDataIds),
    palmSchemeDataIds: uniq(palmSchemeDataIds),
    parentSchemesIds: uniq(parentSchemesIds),
    parentSchemes: uniq(parentSchemes),
  }
}

export { getSchemeChildren, getParentSchemesRelatedSchemeData }
