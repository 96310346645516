import * as React from 'react'
import { useListContext } from 'react-admin'
import {
  Button,
  Toolbar,
  Grid,
  Paper,
  ButtonGroup,
  Select,
  MenuItem,
} from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

export const MobilePostPagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext()
  const nbPages = Math.ceil(total / perPage) || 1
  return nbPages > 1 ? (
    <Toolbar>
      <Grid container>
        <Grid item xs={3}>
          <Paper
            elevation={0}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Select
              value={perPage}
              style={{ width: '100%', height: '70%', textAlign: 'center' }}
              variant="outlined"
              onChange={(e) =>
                setPerPage(parseInt(e.target.value as string, 10))
              }
            >
              <MenuItem value={20}> 20 </MenuItem>
              <MenuItem value={50}> 50 </MenuItem>
              <MenuItem value={100}> 100 </MenuItem>
            </Select>
          </Paper>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={5}>
          <Paper
            elevation={0}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ButtonGroup style={{ height: '70%' }}>
              {page > 1 ? (
                <Button
                  variant="outlined"
                  color="primary"
                  key="prev"
                  onClick={() => setPage(page - 1)}
                >
                  <ChevronLeft />
                </Button>
              ) : (
                <Button variant="outlined" color="primary" disabled>
                  <ChevronLeft />
                </Button>
              )}
              <Button disabled variant="contained">
                {' '}
                {page}{' '}
              </Button>
              {page !== nbPages ? (
                <Button
                  variant="outlined"
                  color="primary"
                  key="next"
                  onClick={() => setPage(page + 1)}
                >
                  <ChevronRight />
                </Button>
              ) : (
                <Button variant="outlined" color="primary" disabled>
                  <ChevronRight />
                </Button>
              )}
            </ButtonGroup>
          </Paper>
        </Grid>
      </Grid>
    </Toolbar>
  ) : (
    <div />
  )
}
