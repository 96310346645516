import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { ACCESSES_URL } from '../api-urls'
import { CacheableDataProviderExtensionResult } from '../common/data-provider'
import { DateTime } from '../common/date-time'
import { get, getWithPagination, patch, post, put } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { AccessVariantsDto } from '../dto/access-variants.dto'
import { AccessDto } from '../dto/access.dto'
import { AccessActivePeriodStartingOptions } from '../enum/AccessActivePeriodStartingOptions'
import { DayOfWeek } from '../enum/DayOfWeek'
import filterMapper from './access-filter.mapper'
import { mapSortAccessParam } from './access-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<AccessDto>> => {
    const filterParams = `a=>${filterParamsComposer('a', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortAccessParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<AccessDto[]>(
      `${ACCESSES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<AccessDto>> => {
    const data = await get<AccessDto>(ACCESSES_URL, `/${id}`)
    return {
      data,
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<AccessDto>> => {
    const data = await get<AccessDto[]>(
      `${ACCESSES_URL}/GetByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { data, previousData }: UpdateParams<AccessDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<UpdateAccessRequest, AccessDto>(ACCESSES_URL, {
      id: data.id,
      name: data.name !== previousData.name ? data.name : undefined,
      bonusId: data.bonusId !== previousData.bonus ? data.bonusId : undefined,
      entranceId:
        data.entranceId !== previousData.entranceId
          ? data.entranceId
          : undefined,
      allowReenter:
        data.allowReenter !== previousData.allowReenter
          ? data.allowReenter
          : undefined,
      multipleEntry:
        data.multipleEntry !== previousData.multipleEntry
          ? data.multipleEntry
          : undefined,
      multiple:
        data.multiple !== previousData.multiple ? data.multiple : undefined,
      usePriority:
        data.usePriority !== previousData.usePriority
          ? data.usePriority
          : undefined,
      isTicketUnlockedWithNextDay:
        data.isTicketUnlockedWithNextDay !==
        previousData.isTicketUnlockedWithNextDay
          ? data.isTicketUnlockedWithNextDay
          : undefined,
      releaseTime:
        data.releaseTime !== previousData.releaseTime
          ? data.releaseTime
          : undefined,
      releaseDayOfWeek:
        data.releaseDayOfWeek !== previousData.releaseDayOfWeek
          ? data.releaseDayOfWeek
          : undefined,
      releaseDayOfMonth:
        data.releaseDayOfMonth !== previousData.releaseDayOfMonth
          ? data.releaseDayOfMonth
          : undefined,
      delay: data.delay !== previousData.delay ? data.delay : undefined,
      activePeriod:
        data.activePeriod !== previousData.activePeriod
          ? data.activePeriod
          : undefined,
      activePeriodStartingFrom:
        data.activePeriodStartingFrom !== previousData.activePeriodStartingFrom
          ? data.activePeriodStartingFrom
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<AccessDto>,
  ): Promise<CreateResult> => {
    const created = await post<CreateAccessRequest[], AccessDto[]>(
      ACCESSES_URL,
      [
        {
          name: data.name,
          bonusId: data.bonusId ? data.bonusId : undefined,
          entranceId: data.entranceId || undefined,
          allowReenter: data.allowReenter || undefined,
          multipleEntry: data.multipleEntry || undefined,
          multiple: data.multiple || undefined,
          usePriority: data.usePriority || undefined,
          isTicketUnlockedWithNextDay:
            data.isTicketUnlockedWithNextDay || undefined,
          releaseTime: data.releaseTime || undefined,
          releaseDayOfWeek: data.releaseDayOfWeek || undefined,
          releaseDayOfMonth: data.releaseDayOfMonth || undefined,
          delay: data.delay || undefined,
          activePeriod: data.activePeriod || undefined,
          activePeriodStartingFrom: data.activePeriodStartingFrom || undefined,
        },
      ],
    )
    return Promise.resolve({ data: created[0] })
  },
  attachVariants: async (
    resource: string,
    params: AccessVariantsDto,
  ): Promise<CacheableDataProviderExtensionResult<AccessVariantsDto>> => {
    const accessVariants = await put<AccessVariantsDto, AccessVariantsDto>(
      `${ACCESSES_URL}/AttachVariants`,
      params,
    )
    return {
      data: accessVariants,
    }
  },
  detachVariants: async (
    resource: string,
    params: AccessVariantsDto,
  ): Promise<CacheableDataProviderExtensionResult<AccessVariantsDto>> => {
    const accessVariants = await put<AccessVariantsDto, AccessVariantsDto>(
      `${ACCESSES_URL}/DetachVariants`,
      params,
    )
    return {
      data: accessVariants,
    }
  },
} as AccessDataProvider

interface AccessDataProvider extends DataProvider {
  attachVariants: (
    resource: string,
    params: AccessVariantsDto,
  ) => Promise<CacheableDataProviderExtensionResult<AccessVariantsDto>>

  detachVariants: (
    resource: string,
    params: AccessVariantsDto,
  ) => Promise<CacheableDataProviderExtensionResult<AccessVariantsDto>>
}

interface CreateAccessRequest {
  readonly name: string
  readonly bonusId?: number
  readonly entranceId?: number
  readonly allowReenter?: boolean
  readonly multipleEntry?: boolean
  readonly multiple?: number
  readonly usePriority?: number
  readonly isTicketUnlockedWithNextDay?: boolean
  readonly releaseTime?: DateTime
  readonly releaseDayOfWeek?: DayOfWeek
  readonly releaseDayOfMonth?: number
  readonly delay?: string
  readonly activePeriod?: string
  readonly activePeriodStartingFrom?: AccessActivePeriodStartingOptions
}

interface UpdateAccessRequest {
  readonly id: Identifier
  readonly name?: string
  readonly bonusId?: number
  readonly entranceId?: number
  readonly allowReenter?: boolean
  readonly multipleEntry?: boolean
  readonly multiple?: number
  readonly usePriority?: number
  readonly isTicketUnlockedWithNextDay?: boolean
  readonly releaseTime?: DateTime
  readonly releaseDayOfWeek?: DayOfWeek
  readonly releaseDayOfMonth?: number
  readonly delay?: string
  readonly activePeriod?: string
  readonly activePeriodStartingFrom?: AccessActivePeriodStartingOptions
}

export default provider
