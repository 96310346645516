import { DataProvider } from 'react-admin'
import { PassagesCountersResponse } from '../../stats/passage-counters-view/passage-counters-view'

const fakeStatsCounterView: PassagesCountersResponse = {
  uncategorizedCounters: [],
  categorizedCounters: [],
}

const provider = {
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  getOne: (resource, params) =>
    Promise.resolve({
      data: fakeStatsCounterView,
    }),
} as DataProvider

export default provider
