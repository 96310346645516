import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  useTranslate,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  ToolbarProps,
  TextField,
  ShowButton,
  BooleanInput,
  FunctionField,
} from 'react-admin'
import { PalmSchemeDataDto } from '../../../../core/dto/palm-scheme-data.dto'
import { RAColorPicker } from '../../../common/RAColorPicker'

export const validatePalmSchemeDataEdit = (values: PalmSchemeDataDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.text) {
    errors.text = 'ra.validation.required'
  }
  if (!values.backgroundColor) {
    errors.backgroundColor = 'ra.validation.required'
  }
  return errors
}

const EditTitle = ({ ...props }) => {
  const { record: scheme } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.palm-scheme-data.titles.edit')}: {scheme?.id}.{' '}
      {scheme?.text}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const PalmSchemeDataEdit = (props: EditProps) => {
  const translate = useTranslate()

  return (
    <Edit {...props} hasShow={false} title={<EditTitle />}>
      <SimpleForm
        redirect="show"
        toolbar={<EditToolbar />}
        validate={validatePalmSchemeDataEdit}
      >
        <TextField source="id" label="ID" />
        <FunctionField<PalmSchemeDataDto>
          source="language"
          render={(record?: PalmSchemeDataDto) =>
            translate(`settings.language.${record?.language}`)
          }
        />
        <TextInput source="text" fullWidth multiline />
        <RAColorPicker source="backgroundColor" />
        <TextInput source="backgroundImage" />
        <TextInput source="sound" />
        <BooleanInput source="positiveValidation" />
        <TextField source="schemeId" />
      </SimpleForm>
    </Edit>
  )
}
