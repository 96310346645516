import { DataProvider } from 'react-admin'
import { TicketImportMappingDto } from '../../dto/ticket-import-mapping.dto'

const fakeTicketImportMappings: TicketImportMappingDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeTicketImportMappings,
      total: fakeTicketImportMappings.length,
    }),
} as DataProvider

export default provider
