import React, { useState, useReducer, useEffect, useMemo } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import {
  passageResetCountersButtonInitialState,
  passageResetCountersButtonReducer,
} from '../../../../core/devices/passages/passage-reset-counters-button.reducer'
import { ToggleType } from '../../../../core/devices/passages/passage-reset-counters-button'
import { PassageDto } from '../../../../core/dto/device/passages/passage.dto'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import passageProvider from '../../../../core/devices/passages/passages.provider'
import { CreateRecurringJobButton } from '../../../common/CreateRecurringJobButton'
import { AuditCommentActionDialog } from '../../../common/AuditCommentDialog'

const PassageResetCountersButtonRecurring = ({ ...props }) => {
  const { record: passage, loading } = useShowContext<PassageDto>()
  const refresh = useRefresh()
  const hasAuthority = useHasAuthority()

  const saveRecurringJob = async (recurringJobName, cronExpression) => {
    await passageProvider.resetCounters(ResourceName.PASSAGES, {
      passageId: Number(passage?.id),
      recurringJobName,
      cronExpression,
    })
    refresh()
  }

  return (
    <CreateRecurringJobButton
      {...props}
      size="small"
      saveRecurringJob={saveRecurringJob}
      color="default"
      variant="text"
      disabled={!hasAuthority(Authority.PASSAGES_COUNTERS_EDIT) || loading}
    />
  )
}

const PassageResetCountersButtonBase = () => {
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const [{ type, text, mutateQuery }, dispatch] = useReducer(
    passageResetCountersButtonReducer,
    passageResetCountersButtonInitialState,
  )
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const passage = useRecordContext<PassageDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  const countersEmpty = useMemo(
    () =>
      (!passage?.freeEntrancesCount || passage.freeEntrancesCount === 0) &&
      (!passage?.freeExitsCount || passage.freeExitsCount === 0) &&
      (!passage?.ticketEntrancesCount || passage.ticketEntrancesCount === 0) &&
      (!passage?.ticketExitsCount || passage.ticketExitsCount === 0) &&
      (!passage?.unauthorizedEntrancesCount ||
        passage.unauthorizedEntrancesCount === 0) &&
      (!passage?.unauthorizedExitsCount ||
        passage.unauthorizedExitsCount === 0),
    [passage],
  )

  useEffect(() => {
    if (countersEmpty) {
      dispatch({ type: ToggleType.DISABLED })
    } else {
      dispatch({ type: ToggleType.RESET })
    }
  }, [countersEmpty])

  const onClick = async () => {
    if (type === ToggleType.RESET) setResetDialogOpen(true)
  }

  const handleReset = async (
    recurringJobName?: string,
    cronExpression?: string,
    auditNote?: string,
  ) => {
    try {
      const query = mutateQuery?.(passage, auditNote)
      await mutate(query, {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      })
      refresh()
    } catch (err) {
      notify((err as Error)?.message, 'error')
    }
  }

  return (
    <>
      <AuditCommentActionDialog
        open={resetDialogOpen}
        handleClose={() => setResetDialogOpen(false)}
        mutate={handleReset}
      />
      <Button
        disabled={
          !hasAuthority(Authority.PASSAGES_COUNTERS_EDIT) ||
          type === ToggleType.DISABLED ||
          loading ||
          mutationLoading
        }
        variant="contained"
        onClick={onClick}
        label={text}
        useSmallVersionBreakpoint={false}
      />
    </>
  )
}

export const PassageResetCountersButton = ({ ...props }) => {
  const { asRecurringJob, ...rest } = props
  return !asRecurringJob ? (
    <PassageResetCountersButtonBase {...rest} />
  ) : (
    <PassageResetCountersButtonRecurring {...rest} />
  )
}
