export enum DeviceModuleTypes {
  OFFLINE_SERVER = 0, // OfflineServerModuleDto
  IO_GP = 4, // IOStatusDto
  IO_KA = 8, // TurnstileDto
  IO_GT = 12, // TurnstileDto
  IO_GA = 16, // TurnstileDto
  LITE = 20, // LiteDto
  SCANNER_DS = 24, // ReaderDto
  MIFARE = 28, // ReaderDto
  LACAN_IO = 36, // LacanIODto
  LED_19X7 = 44, // PresenterDto
  LCD_7 = 48, // PresenterDto
  IO_16 = 52, // IOStatusDto
  LED_8X8 = 60, // PresenterDto
  BUZZER = 64, // PresenterDto
  LCD_TXT = 76, // PresenterDto
  LITE_MOBILE = 84, // LiteDto
  LCD_PLUS = 88, // LCDPlusDto
  SENSOR = 96, // SensorDto
}
