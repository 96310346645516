import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Create,
  CreateProps,
  DateTimeInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
  Toolbar,
  ToolbarProps,
  SaveButton,
  ListButton,
} from 'react-admin'
import { formatTime } from '../../../core/common/date-time'
import { AccessDto } from '../../../core/dto/access.dto'
import { AccessActivePeriodStartingOptions } from '../../../core/enum/AccessActivePeriodStartingOptions'
import { DayOfWeek } from '../../../core/enum/DayOfWeek'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import { AccessBonusDto } from '../../../core/dto/access-bonus.dto'

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const validateAccessCreate = (values: AccessDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.bonusId) {
    errors.bonusId = 'ra.validation.required'
  }
  if (!values.entranceId) {
    errors.entranceId = 'ra.validation.required'
  }
  if (Number.isNaN(Number(values.multiple)))
    errors.multiple = 'ra.validation.not_numeric'
  if (values.usePriority && Number.isNaN(Number(values.usePriority)))
    errors.usePriority = 'ra.validation.not_numeric'
  if (
    values.releaseDayOfMonth &&
    Number.isNaN(Number(values.releaseDayOfMonth))
  )
    errors.releaseDayOfMonth = 'ra.validation.not_numeric'
  if (
    values.releaseDayOfMonth &&
    (Number(values.releaseDayOfMonth) > 31 ||
      Number(values.releaseDayOfMonth) < 1)
  )
    errors.releaseDayOfMonth = 'ra.validation.not_day_of_the_month'
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.accesses.titles.create')}</span>
}

export const AccessCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Create {...props} title={<CreateTitle />}>
      <SimpleForm
        redirect="show"
        validate={validateAccessCreate}
        toolbar={<CreateToolbar />}
      >
        <TextInput source="name" isRequired />
        {hasAuthority(Authority.VIEW_ACCESS_BONUSES) && (
          <FilteredReferenceInput
            source="bonusId"
            defaultValue={null}
            reference={ResourceName.ACCESS_BONUS}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              showFilter: true,
              optionText: (choice: AccessBonusDto) =>
                `${choice.name} (${choice.description})`,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_ENTRANCES) && (
          <FilteredReferenceInput
            source="entranceId"
            defaultValue={null}
            reference={ResourceName.ENTRANCES}
            sort={{ field: 'note', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="idWithNote"
            selectWithPaginationInputProps={{
              optionText: 'note',
              showFilter: true,
            }}
          />
        )}
        <BooleanInput source="allowReenter" defaultChecked />
        <BooleanInput source="multipleEntry" />
        <TextInput source="multiple" defaultValue={1} />
        <TextInput source="usePriority" />
        <BooleanInput source="isTicketUnlockedWithNextDay" />
        <DateTimeInput
          source="releaseTime"
          type="time"
          format={formatTime}
          parse={(value: string) => (value === '' ? null : value)}
        />
        <SelectInput
          source="releaseDayOfWeek"
          choices={[
            { id: null, name: 'N/A' },
            ...Object.entries(DayOfWeek).map(([value]) => ({
              id: value,
              name: `resources.enums.dayOfWeek.${value}`,
            })),
          ]}
        />
        <TextInput source="releaseDayOfMonth" />
        <TextInput source="delay" />
        <TextInput source="activePeriod" />
        <SelectInput
          source="activePeriodStartingFrom"
          choices={Object.entries(AccessActivePeriodStartingOptions).map(
            ([value]) => ({
              id: value,
              name: `resources.enums.activePeriodStartingFrom.${value}`,
            }),
          )}
        />
      </SimpleForm>
    </Create>
  )
}
