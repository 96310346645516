import {
  AuthProvider,
  DataProvider,
  I18nProvider,
  LegacyDataProvider,
} from 'react-admin'
import fakeDataProvider from './fake/fake-data.provider'
import fakeAuthProvider from './fake/providers/fake-auth.provider'
import authProvider from './auth/auth.provider'
import dataProvider from './data.provider'
import i18nProvider from './i18n.provider'

interface Providers {
  readonly authProvider: AuthProvider
  readonly dataProvider: DataProvider | LegacyDataProvider
  readonly i18nProvider: I18nProvider
}

export const providersFactory = (
  env: 'development' | 'test' | 'production',
): Providers => {
  switch (env) {
    case 'production':
      return {
        authProvider,
        dataProvider,
        i18nProvider,
      }
    case 'development':
      return {
        authProvider,
        dataProvider,
        i18nProvider,
      }
    default:
      return {
        authProvider: fakeAuthProvider,
        dataProvider: fakeDataProvider,
        i18nProvider,
      }
  }
}
