import { sanitizeFetchType } from 'react-admin'
import { PassageDto } from '../../dto/device/passages/passage.dto'
import { ResourceName } from '../../ResourceName'
import {
  ToggleButtonAction,
  ToggleButtonState,
  ToggleType,
} from './passage-set-boolean-status-button'

type Mutation = {
  type: string
  resource: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
}

const turnOffMutation = (
  { id }: PassageDto,
  auditNote?: string,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('setPowerState'),
  resource: ResourceName.PASSAGES,
  payload: {
    passageId: id,
    powerState: false,
    auditComment: auditNote,
    recurringJobName,
    cronExpression,
  },
})

const turnOnMutation = (
  { id }: PassageDto,
  auditNote?: string,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('setPowerState'),
  resource: ResourceName.PASSAGES,
  payload: {
    passageId: id,
    powerState: true,
    auditComment: auditNote,
    recurringJobName,
    cronExpression,
  },
})

type State = {
  mutateQuery?: (
    passage: PassageDto,
    auditNote?: string,
    recurringJobName?: string,
    cronExpression?: string,
  ) => Mutation
} & ToggleButtonState

export const passageSetPowerButtonInitialState = {
  type: ToggleType.OFF,
  text: 'resources.passages.actions.setPowerState.turnOn',
}

export const passageSetPowerButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case ToggleType.OFF: {
      return {
        type,
        text: 'resources.passages.actions.setPowerState.turnOn',
        mutateQuery: turnOnMutation,
      }
    }
    case ToggleType.ON: {
      return {
        type,
        text: 'resources.passages.actions.setPowerState.turnOff',
        mutateQuery: turnOffMutation,
      }
    }
    default:
      throw new Error()
  }
}
