import { DataProvider } from 'react-admin'
import { UserDto } from '../../dto/user.dto'

const fakeUsers: UserDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeUsers, total: fakeUsers.length }),
} as DataProvider

export default provider
