import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import { SelectInput, TextField, TextInput } from 'react-admin'
import { ImportProperties } from '../../../core/enum/ImportProperties'
import { TicketType } from '../../../core/enum/TicketType'
import { BookingType } from '../../../core/enum/BookingType'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { ResourceName } from '../../../core/ResourceName'
import { BookingDisabledOption } from '../../../core/enum/BookingDisabledOption'

export const MappingStaticValueInput = ({ ...props }) => {
  const { property, ...rest } = props
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  switch (property) {
    case ImportProperties.CUSTOMER_FIRSTNAME:
    case ImportProperties.CUSTOMER_LASTNAME:
    case ImportProperties.CUSTOMER_PESEL:
    case ImportProperties.CUSTOMER_DOCUMENT_NUMBER:
    case ImportProperties.CUSTOMER_COMPANY:
    case ImportProperties.TICKET_CODE:
      return <TextInput {...rest} source="staticValue" fullWidth />
    case ImportProperties.TICKET_TYPE:
      return (
        <SelectInput
          {...rest}
          source="staticValue"
          choices={Object.entries(TicketType).map(([value]) => ({
            id: value,
            name: `resources.enums.ticketType.${value}`,
          }))}
          fullWidth
          resettable
        />
      )
    case ImportProperties.BOOKING_TYPE:
      return (
        <SelectInput
          {...rest}
          source="staticValue"
          choices={Object.entries(BookingType).map(([value]) => ({
            id: value,
            name: `resources.enums.bookingType.${value}`,
          }))}
          fullWidth
          resettable
        />
      )
    case ImportProperties.DISABLED:
      return (
        <SelectInput
          {...rest}
          source="staticValue"
          choices={Object.entries(BookingDisabledOption).map(([value]) => ({
            id: value,
            name: `resources.enums.bookingDisabled.${value}`,
          }))}
          fullWidth
          resettable
        />
      )
    case ImportProperties.SEAT_ID:
      return (
        <FilteredReferenceInput
          {...rest}
          source="staticValue"
          reference={ResourceName.SEATS}
          sort={{ field: 'rowAlias', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="combinedFullAlias"
          selectWithPaginationInputProps={{
            optionText: 'combinedFullAlias',
            showFilter: true,
            options: {
              resettable: true,
            },
          }}
          fullWidth
        />
      )
    case ImportProperties.BLOCK_ID:
      return (
        <FilteredReferenceInput
          {...rest}
          source="staticValue"
          reference={ResourceName.BLOCKS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
            options: {
              resettable: true,
            },
          }}
          fullWidth
        />
      )
    case ImportProperties.VARIANT_ID:
      return (
        <FilteredReferenceInput
          {...rest}
          source="staticValue"
          reference={ResourceName.VARIANTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
            options: {
              resettable: true,
            },
          }}
          fullWidth
        />
      )
    case ImportProperties.EVENT_ID:
      return (
        <FilteredReferenceInput
          {...rest}
          source="staticValue"
          reference={ResourceName.EVENTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
            options: {
              resettable: true,
            },
          }}
          fullWidth
        />
      )
    default:
      return <TextField {...rest} source="staticValue" />
  }
}
