import { sanitizeFetchType } from 'react-admin'
import {
  ToggleType,
  ToggleButtonAction,
  ToggleButtonState,
} from './toggle-booking-blockade-button'
import {
  BookingReleaseLockParams,
  BookingSetLockParams,
} from './bookings.provider'
import { ResourceName } from '../ResourceName'

type Mutation = {
  type: string
  resource: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
}

const setLockMutation = ({
  id,
  bookingDisabled,
}: BookingSetLockParams): Mutation => ({
  type: sanitizeFetchType('setLock'),
  resource: ResourceName.BOOKINGS,
  payload: {
    id,
    bookingDisabled,
  },
})

const releaseLockMutation = ({ id }: BookingReleaseLockParams): Mutation => ({
  type: sanitizeFetchType('releaseLock'),
  resource: ResourceName.BOOKINGS,
  payload: {
    id,
  },
})

type State = {
  mutateQuery?: (params: BookingSetLockParams) => Mutation
} & ToggleButtonState

export const blockTicketButtonInitialState = {
  type: ToggleType.DISABLED,
  text: 'resources.bookings.actions.blockTicketNA',
}

export const toggleTicketButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case ToggleType.BLOCK: {
      return {
        type,
        text: 'resources.bookings.actions.blockTicket',
        mutateQuery: setLockMutation,
      }
    }
    case ToggleType.UNBLOCK: {
      return {
        type,
        text: 'resources.bookings.actions.unblockTicket',
        mutateQuery: releaseLockMutation,
      }
    }
    case ToggleType.DISABLED: {
      return blockTicketButtonInitialState
    }
    default:
      throw new Error()
  }
}
