import {
  Menu,
  useMediaQuery,
  useTheme,
  Tooltip,
  makeStyles,
  Box,
} from '@material-ui/core'
import { Security, Close } from '@material-ui/icons'
import React, { useMemo } from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
  useListContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { HardwareVersions } from '../../../core/enum/HardwareVersions'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import List from '../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import FilterProps from '../../common/FilterProps'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import {
  CheckIfAnyModuleHasError,
  CheckLastRefreshError,
  DeviceStatusesPreview,
} from '../common/modules/ModuleStatus'
import { PassageResetCountersBulkButton } from './passage-list/PassageResetCountersBulkButton'
import { PassageSetFreePassBackwardBulkButton } from './passage-list/PassageSetFreePassBackwardBulkButton'
import { PassageSetFreePassForwardBulkButton } from './passage-list/PassageSetFreePassForwardBulkButton'
import { PassageSetLockBulkButton } from './passage-list/PassageSetLockBulkButton'
import { PassageSetPowerBulkButton } from './passage-list/PassageSetPowerBulkButton'
import { PassageSetPPOZBulkButton } from './passage-list/PassageSetPPOZBulkButton'
import { PassageSetFreePassBackwardCompactButton } from './passage-list/PassageSetFreePassBackwardCompactButton'
import { PassageSetFreePassForwardCompactButton } from './passage-list/PassageSetFreePassForwardCompactButton'
import { PassageSetPowerCompactButton } from './passage-list/PassageSetPowerCompactButton'
import { PassageSetPPOZCompactButton } from './passage-list/PassageSetPPOZCompactButton'
import {
  TurnstileDto,
  TurnstileStatusData,
} from '../../../core/dto/device/common/modules/turnstile.dto'
import { getModulesFromJsonStatus } from '../../../core/devices/common/modules/module.mapper'
import { PassageSetReverseDirectionCompactButton } from './passage-list/PassageSetReverseDirectionCompactButton'
import { DeviceCategoryExtendedDto } from '../../../core/dto/device-category/device-category-extended.dto'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'

const useStyles = makeStyles((theme) => ({
  compactStatus: {
    display: 'flex',
    gap: theme.spacing(0.5),
    height: 34,
    cursor: 'default',
  },
  iconContainer: {
    position: 'relative',
    width: 34,
  },
  icon: {
    position: 'absolute',
    left: 5,
    top: 5,
  },
  crossIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
}))

const PassageFilters = (props: FilterProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          label="resources.palms.fields.categoryId"
          source="categoryIdWithDescendants"
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      <SelectInput
        source="hardwareVersion"
        choices={Object.entries(HardwareVersions).map(([value]) => ({
          id: value,
          name: `resources.enums.hardwareVersions.${value}`,
        }))}
      />
    </Filter>
  )
}

const PassageGridExpand = ({ ...props }) => {
  const { record } = props
  return (
    <DeviceStatusesPreview
      jsonStatus={record.jsonStatus}
      deviceType={DeviceTypes.PASSAGE}
    />
  )
}

const PassagesBulkActions = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const PassageSetFreePassBackwardAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetFreePassBackwardBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PassageSetFreePassBackwardRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetFreePassBackwardBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const PassageSetFreePassForwardAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetFreePassForwardBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PassageSetFreePassForwardRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetFreePassForwardBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const PassageSetPowerAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetPowerBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PassageSetPowerRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetPowerBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const PassageSetPPOZAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetPPOZBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PassageSetPPOZRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetPPOZBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const PassageSetLockAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetLockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PassageSetLockRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageSetLockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const PassageResetCountersAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageResetCountersBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PassageResetCountersRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PassageResetCountersBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  return (
    <div>
      <Button
        onClick={handleClick}
        label="const.selectAction"
        variant="text"
        color="default"
        useSmallVersionBreakpoint={false}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl != null}
        onClose={handleClose}
        onClick={handleClose}
      >
        <PassageSetFreePassBackwardAction />
        <PassageSetFreePassBackwardRecurringAction />
        <PassageSetFreePassForwardAction />
        <PassageSetFreePassForwardRecurringAction />
        <PassageSetPowerAction />
        <PassageSetPowerRecurringAction />
        <PassageSetPPOZAction />
        <PassageSetPPOZRecurringAction />
        <PassageSetLockAction />
        <PassageSetLockRecurringAction />
        <PassageResetCountersAction />
        <PassageResetCountersRecurringAction />
      </Menu>
    </div>
  )
}

const CompactStatus = ({ ...props }) => {
  const { record } = props
  const { jsonStatus: rawJsonStatus, statusIndex } = record
  const modules = getModulesFromJsonStatus(rawJsonStatus)
  const turnstileStatusDataLength = (modules?.[0] as TurnstileDto)
    ?.turnstileStatusData?.length
  let convertedStatusIndex = 0
  if (
    turnstileStatusDataLength &&
    turnstileStatusDataLength > 0 &&
    statusIndex !== undefined &&
    statusIndex < turnstileStatusDataLength
  ) {
    convertedStatusIndex = statusIndex
  }
  const status =
    turnstileStatusDataLength === undefined
      ? (modules?.[0] as TurnstileStatusData)
      : (modules?.[0] as TurnstileDto)?.turnstileStatusData?.[
          convertedStatusIndex
        ]
  const translate = useTranslate()
  const classes = useStyles()

  if (status) {
    return (
      <Box
        className={classes.compactStatus}
        onClick={(event) => event.stopPropagation()}
      >
        <PassageSetPowerCompactButton PowerTurnedOn={status?.powerTurnedOn} />
        <PassageSetPPOZCompactButton FireAlarm={status?.fireAlarm} />
        <PassageSetFreePassForwardCompactButton
          FreePassForward={status?.freePassForward}
        />
        <PassageSetFreePassBackwardCompactButton
          FreePassBackward={status?.freePassBackward}
        />
        <PassageSetReverseDirectionCompactButton
          reversedDirection={record?.reversedDirection}
        />
        <Tooltip title={translate('resources.modules.fields.keyLock')}>
          <div className={classes.iconContainer}>
            <Security className={classes.icon} />
            {!status?.keyLock ? (
              <Close fontSize="large" className={classes.crossIcon} />
            ) : undefined}
          </div>
        </Tooltip>
      </Box>
    )
  }
  return <div />
}

export const PassagesList = ({ ...props }: ListProps) => {
  const { loading } = useListContext()
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const hasAuthority = useHasAuthority()

  const postRowStyle = (record: any) => {
    const passageError = CheckLastRefreshError(record.lastRefresh)
    let passageWarning = false
    if (!passageError) {
      // Check for modules error
      passageWarning = CheckIfAnyModuleHasError(
        record.jsonStatus,
        DeviceTypes.PASSAGE,
      )
    }
    if (passageError) {
      return {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      }
    }
    if (passageWarning) {
      return {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
      }
    }
    return {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    }
  }

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<PassageFilters />}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
      bulkActionButtons={<PassagesBulkActions />}
    >
      <Datagrid
        rowStyle={postRowStyle}
        size="small"
        loaded={!loading}
        rowClick={(id) => `${ResourceName.PASSAGES}/${id}/show`}
        isRowExpandable={(record) =>
          record?.jsonStatus !== undefined && record?.jsonStatus !== null
        }
        expand={<PassageGridExpand />}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        {!xSmallScreen && hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link={false}
            sortBy="name"
          >
            <FunctionField<DeviceCategoryExtendedDto>
              source="hierarchyString"
              render={(record?: DeviceCategoryExtendedDto) =>
                `${record?.id}. ${record?.hierarchyString}`
              }
            />
          </ReferenceField>
        )}
        <CompactStatus source="jsonStatus" sortable={false} />
      </Datagrid>
    </List>
  )
}
