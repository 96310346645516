import { Autorenew } from '@material-ui/icons'
import React, { useState, useReducer, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ToggleType } from '../../../../core/devices/palms/palm-lock-button'
import {
  palmLockButtonInitialState,
  palmLockButtonReducer,
} from '../../../../core/devices/palms/palm-lock-button.reducer'
import { PalmDto } from '../../../../core/dto/device/palm/palm.dto'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { PalmLockDialog } from '../PalmDialogs'

export const PalmLockButton = ({ ...props }) => {
  const { asRecurringJob, recurringMethod } = props
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const [{ type, text, mutateQuery }, dispatch] = useReducer(
    palmLockButtonReducer,
    palmLockButtonInitialState,
  )
  const [lockDialogOpen, setLockDialogOpen] = useState(false)
  const [disabledId, setDisabledId] = useState(0)
  const [unlockDialogOpen, setUnlockDialogOpen] = useState(false)
  const palm = useRecordContext<PalmDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  useEffect(() => {
    if (
      (!asRecurringJob && palm.disabledId) ||
      (asRecurringJob && recurringMethod === ToggleType.UNLOCK)
    )
      dispatch({ type: ToggleType.UNLOCK })
    else dispatch({ type: ToggleType.LOCK })
  }, [asRecurringJob, palm, recurringMethod])

  const onClick = async () => {
    if (type === ToggleType.UNLOCK) setUnlockDialogOpen(true)
    else setLockDialogOpen(true)
  }

  const handleLock = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    let query
    if (!asRecurringJob) {
      query = mutateQuery?.(palm, disabledId)
    } else {
      query = mutateQuery?.(
        palm,
        disabledId,
        recurringJobName as string,
        cronExpression as string,
      )
    }
    await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    refresh()
  }

  return (
    <>
      <ConfirmDialog
        open={unlockDialogOpen}
        confirm={handleLock}
        source="lock"
        cancel={() => setUnlockDialogOpen(false)}
        action="remove"
        resource={ResourceName.PALMS}
        asRecurringJob={asRecurringJob}
      />
      <PalmLockDialog
        open={lockDialogOpen}
        close={() => setLockDialogOpen(false)}
        disabledId={disabledId}
        setDisabledId={setDisabledId}
        handleLock={handleLock}
        asRecurringJob={asRecurringJob}
      />
      <Button
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        label={!asRecurringJob ? text : `${text}-recurring`}
        size="small"
        useSmallVersionBreakpoint={false}
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
        disabled={
          !hasAuthority(Authority.PALMS_LOCK_BUTTON) ||
          loading ||
          mutationLoading
        }
        onClick={onClick}
      />
    </>
  )
}
