import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  Show,
  ShowProps,
  useTranslate,
  TitleProps,
  TabbedShowLayout,
  Tab,
  useShowContext,
  TextField,
  EditButton,
  BooleanField,
  ResourceContextProvider,
  ReferenceField,
  FunctionField,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DynamicCounterDto } from '../../../core/dto/dynamic-counter.dto'
import { DynamicCounterParametersList } from './dynamic-counter-show/DynamicCounterParametersList'
import { SchemeDto } from '../../../core/dto/scheme/scheme.dto'

interface DynamicCounterTitleProps extends TitleProps {
  record?: DynamicCounterDto
}

const ShowTitle = (data: DynamicCounterTitleProps) => {
  const { record: counter } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.dynamic-counters.titles.show')}: {counter?.id}.{' '}
      {counter?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: counter } = useShowContext<DynamicCounterDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath={`/${ResourceName.DYNAMIC_COUNTERS}`}
          record={counter}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_DYNAMIC_COUNTERS)}
        />
      </Box>
    </>
  )
}

const DynamicCounterTabs = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()
  const { record: counter } = useShowContext<DynamicCounterDto>()

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.dynamic-counters.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <BooleanField source="active" />
        <TextField source="sqlQuery" />
        <TextField source="state" />
        <TextField source="limit" />
        {counter?.schemeId && hasAuthority(Authority.VIEW_SCHEMES) && (
          <ReferenceField
            source="schemeId"
            reference={ResourceName.SCHEMES}
            link={false}
            sortable={false}
          >
            <FunctionField<SchemeDto>
              source="name"
              render={(scheme?: SchemeDto) => `${scheme?.id}. ${scheme?.name}`}
            />
          </ReferenceField>
        )}
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_DYNAMIC_COUNTER_PARAMETERS) && (
        <Tab
          label={translate('resources.dynamic-counters.tabs.parameters')}
          path="parameters"
        >
          <ResourceContextProvider
            value={ResourceName.DYNAMIC_COUNTER_PARAMETERS}
          >
            <DynamicCounterParametersList
              title=" "
              filter={{ dynamicCounterId: counter?.id }}
            />
          </ResourceContextProvider>
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const DynamicCounterShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <DynamicCounterTabs />
  </Show>
)
