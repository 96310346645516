const API_V1 = `${process.env.REACT_APP_API_BASE_URL}/api/v1`
export const AUTH_URL = `${API_V1}/Authorization/Authenticate`
export const ROLES_URL = `${API_V1}/User/GetRoles`
export const ACCESS_USINGS = `${API_V1}/ticketManagement/AccessUsings`
export const ACCESSES_URL = `${API_V1}/ticketManagement/Accesses`
export const ACCESS_DATES_URL = `${API_V1}/ticketManagement/AccessDates`
export const BOOKINGS_URL = `${API_V1}/ticketManagement/Bookings`
export const CUSTOMERS_URL = `${API_V1}/ticketManagement/Customers`
export const EVENTS_URL = `${API_V1}/ticketManagement/Events`
export const LOGS_URL = `${API_V1}/ticketManagement/Logs`
export const TICKETS_URL = `${API_V1}/ticketManagement/Tickets`
export const VARIANTS_URL = `${API_V1}/ticketManagement/Variants`
export const SEATS_URL = `${API_V1}/ticketManagement/Seats`
export const BLOCKS_URL = `${API_V1}/ticketManagement/Blocks`
export const TRIBUNES_URL = `${API_V1}/ticketManagement/Tribunes`
export const OBJECTS_URL = `${API_V1}/ticketManagement/Objects`
export const ENGINES_URL = `${API_V1}/deviceManagement/Engines`
export const PALMS_URL = `${API_V1}/deviceManagement/Palms`
export const ADMINISTRATOR_URL = `${API_V1}/Administrator`
export const ENTRANCES_URL = `${API_V1}/deviceManagement/Entrances`
export const DEVICE_CATEGORIES_URL = `${API_V1}/deviceManagement/DeviceCategories`
export const PASSAGES_URL = `${API_V1}/deviceManagement/Passages`
export const DETECTORS_URL = `${API_V1}/deviceManagement/Detectors`
export const CAMERAS_URL = `${API_V1}/deviceManagement/Cameras`
export const CAMERA_SERVERS_URL = `${API_V1}/deviceManagement/CameraServers`
export const JOBS_URL = `${API_V1}/Jobs`
export const OFFLINE_SERVERS_URL = `${API_V1}/deviceManagement/OfflineServers`
export const CURRENT_USER_URL = `${API_V1}/User`
export const STATISTICS_URL = `${API_V1}/Statistics`
export const SCHEMES_URL = `${API_V1}/ticketManagement/Schemes`
export const CSI_URL = `${API_V1}/CSI`
export const SERVICE_LINK_URL = `${API_V1}/KartaMieszkanca`
export const DYNAMIC_STATISTICS_URL = `${API_V1}/DynamicStatistics`
export const DYNAMIC_STATISTIC_PARAMETERS_URL = `${API_V1}/DynamicStatisticParameters`
export const DYNAMIC_COUNTERS_URL = `${API_V1}/DynamicCounters`
export const DYNAMIC_COUNTERS_PARAMETERS_URL = `${API_V1}/DynamicCounterParameters`
export const DEPOSITORS_URL = `${API_V1}/deviceManagement/Depositors`
export const DYNAMIC_PROPERTY_DEFINITION_URL = `${API_V1}/dynamicProperties/DynamicPropertyDefinitions`
export const ACCESS_BONUS_URL = `${API_V1}/ticketManagement/AccessBonuses`
export const VERIFIER_DEVICE_DISABLED_URL = `${API_V1}/deviceManagement/VerifierDeviceDisabled`
export const VERIFIER_DEVICES_SCHEME_URL = `${API_V1}/deviceManagement/VerifierSchemeData`
export const TS_CANS_URL = `${API_V1}/deviceManagement/TSCans`
export const DOORS_URL = `${API_V1}/deviceManagement/Doors`
export const TERMINALS_URL = `${API_V1}/deviceManagement/Terminals`
export const TICKET_IMPORTS_URL = `${API_V1}/ticketManagement/TicketImports`
export const TICKET_IMPORT_MAPPINGS_URL = `${API_V1}/ticketManagement/TicketImportMappings`
export const TICKET_IMPORT_TEMPLATES_URL = `${API_V1}/ticketManagement/TicketImportTemplates`
