import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Close, Save } from '@material-ui/icons'
import React, { useState } from 'react'
import { SaveButton, useTranslate } from 'react-admin'
import DraggableComponent from './DraggableComponent'
import Button from './customized-mui-components/Button'
import { CreateRecurringJobButton } from './CreateRecurringJobButton'

const useStyles = makeStyles((theme: Theme) => ({
  dialogProperty: {
    marginBottom: theme.spacing(1),
  },
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  actionsButton: {
    margin: '2%',
    width: '96%',
  },
  formToolbar: {
    backgroundColor: 'inherit',
    padding: 0,
    margin: 0,
  },
  dialogContent: {
    padding: '8px',
  },
  iconContainer: {
    width: 34,
    height: 34,
  },
  icon: {
    position: 'relative',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'relative',
    left: -24,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
}))

// temporary input
// replace it when we have default audit comments definitions
const AuditCommentInput = ({ ...props }) => {
  const { auditCommentValue, setAuditCommentValue, ...rest } = props
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <TextField
      className={classes.dialogProperty}
      label={translate('resources.audit-comment.fields.audit-comment')}
      variant="outlined"
      value={auditCommentValue}
      onChange={(e) => setAuditCommentValue(e.target.value)}
      fullWidth
      {...rest}
    />
  )
}

export const AuditCommentActionDialog = ({ ...props }) => {
  const { open, handleClose, mutate, asRecurringJob, ...rest } = props
  const classes = useStyles()
  const translate = useTranslate()

  const [auditCommentValue, setAuditCommentValue] = useState<string>()

  const handleMutate = (recurringJobName?: string, cronExpression?: string) => {
    mutate(recurringJobName, cronExpression, auditCommentValue)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(`resources.audit-comment.name`)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <AuditCommentInput
          auditCommentValue={auditCommentValue}
          setAuditCommentValue={setAuditCommentValue}
        />
        {!asRecurringJob ? (
          <Button
            variant="contained"
            color="primary"
            label="ra.action.save"
            useSmallVersionBreakpoint={false}
            startIcon={<Save />}
            onClick={handleMutate as any}
            size="medium"
            {...rest}
          />
        ) : (
          <CreateRecurringJobButton
            size="small"
            saveRecurringJob={handleMutate}
            variant="contained"
            label="const.yes"
            className={classes.actionsButton}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export const AuditCommentFormDialog = ({ ...props }) => {
  const { open, handleClose, ...rest } = props
  const classes = useStyles()
  const translate = useTranslate()

  const [auditCommentValue, setAuditCommentValue] = useState<string>()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(`resources.audit-comment.name`)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <AuditCommentInput
          auditCommentValue={auditCommentValue}
          setAuditCommentValue={setAuditCommentValue}
        />
        <SaveButton
          {...rest}
          disabled={!auditCommentValue}
          transform={(data) => ({ ...data, auditComment: auditCommentValue })}
        />
      </DialogContent>
    </Dialog>
  )
}

export const AuditCommentActionButton = ({ ...props }) => {
  const { buttonProps, ...rest } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        useSmallVersionBreakpoint={false}
        onClick={handleOpenDialog}
        size="medium"
        {...buttonProps}
      />
      <AuditCommentActionDialog
        {...rest}
        open={dialogOpen}
        handleClose={handleCloseDialog}
      />
    </>
  )
}

export const AuditCommentFormButton = ({ ...props }) => {
  const { buttonProps, ...rest } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        label="ra.action.save"
        useSmallVersionBreakpoint={false}
        startIcon={<Save />}
        onClick={handleOpenDialog}
        size="medium"
        {...buttonProps}
      />
      <AuditCommentFormDialog
        {...rest}
        open={dialogOpen}
        handleClose={handleCloseDialog}
      />
    </>
  )
}
