import { DataProvider } from 'react-admin'
import { CustomerDto } from '../../dto/customer.dto'
import { CustomerDocumentType } from '../../enum/CustomerDocumentType'
import { Gender } from '../../enum/Gender'

const fakeCustomers: CustomerDto[] = [
  {
    id: 1,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 2,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 3,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 4,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 5,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 6,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 7,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 8,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 9,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
  {
    id: 10,
    type: 'MANUAL',
    firstname: 'Jan',
    lastname: 'Kowalski',
    company: 'ABC',
    birthdate: '2021-07-15T08:50:27.2054',
    documentType: CustomerDocumentType.ID_CARD,
    pesel: '12345678901',
    documentNumber: '',
    nationality: 'PL',
    gender: Gender.MALE,
  },
]

const provider = {
  getOne: (resource, { id }: { id: string }) => {
    const found = fakeCustomers.find((c) => `${c.id}` === id)
    if (found) {
      return Promise.resolve({
        data: found,
      })
    }
    return Promise.reject(new Error())
  },
} as DataProvider

export default provider
