import { Dialog, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'
import {
  LinearProgress,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRefresh,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button, {
  ButtonProps,
} from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'

export const ClearAllEntranceDeviceRelationsButton = (props: ButtonProps) => {
  const notify = useNotify()
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  const handleClearAllEntranceDeviceRelations = async () => {
    await mutate(
      {
        type: sanitizeFetchType('clearAllEntranceDeviceRelations'),
        resource: ResourceName.ENTRANCES,
        payload: {},
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
    notify(
      'resources.entrances-matrix.notifications.entranceDeviceRelationsCleared',
    )
    setConfirmationDialogOpen(false)
    refresh()
  }

  return (
    <>
      <Dialog id="in-progress-dialog" open={mutationLoading}>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={confirmationDialogOpen}
        cancel={() => {
          setConfirmationDialogOpen(false)
        }}
        confirm={handleClearAllEntranceDeviceRelations}
        source="clearAllEntranceDeviceRelations"
        action="remove"
        resource={ResourceName.ENTRANCES_MATRIX}
      />
      <Button
        variant="contained"
        color="primary"
        label="resources.entrances-matrix.actions.clearAllEntranceDeviceRelations"
        useSmallVersionBreakpoint={false}
        {...props}
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={
          !hasAuthority(Authority.CLEAR_ENTRANCES_SET) || mutationLoading
        }
      />
    </>
  )
}
