import { Box, Divider, Grid } from '@material-ui/core'
import React, { ReactElement } from 'react'
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DepositorDto } from '../../../core/dto/device/depositor/depositor.dto'
import { ResourceName } from '../../../core/ResourceName'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DepositorRemoveCredentialsButton } from './depositor-show/DepositorRemoveCredentialsButton'
import { DepositorSetCredentialsButton } from './depositor-show/DepositorSetCredentialsButton'
import { CameraDto } from '../../../core/dto/device/camera/cameras/camera.dto'
import { VerifierDeviceDisabledDto } from '../../../core/dto/device/verifier-device-disabled/verifier-device-disabled.dto'
import { DeviceCategoryExtendedDto } from '../../../core/dto/device-category/device-category-extended.dto'

interface DepositorTitleProps extends TitleProps {
  record?: DepositorDto
}

const ShowTitle = (data: DepositorTitleProps) => {
  const { record: depositor } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.depositors.titles.show')}: {depositor?.id}.{' '}
      {depositor?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: depositor } = useShowContext<DepositorDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <DepositorSetCredentialsButton />
          </Grid>
          <Grid item>
            <DepositorRemoveCredentialsButton />
          </Grid>
          <Grid item>
            <EditButton
              basePath={`/${ResourceName.DEPOSITORS}`}
              record={depositor}
              variant="contained"
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_DEPOSITORS)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const DepositorTabs = ({ ...props }) => {
  const translate = useTranslate()
  const { record: depositor } = useShowContext<DepositorDto>()
  const hasAuthority = useHasAuthority()

  const eventsFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.DEPOSITORS,
    })

  const entrancesFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="note" alwaysOn />],
      resource: ResourceName.DEPOSITORS,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.depositors.tabs.general">
        <TextField label="ID" source="id" />
        <TextField source="name" />
        <FunctionField<DepositorDto>
          source="isWatched"
          render={(record?: DepositorDto) => (
            <div>
              {record?.isWatched
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <DateField
          source="lastRefresh"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        <TextField source="address" />
        <FunctionField<DepositorDto>
          source="direction"
          render={(record?: DepositorDto) => (
            <div>
              {translate(
                `resources.enums.deviceDirection.${record?.direction}`,
              )}
            </div>
          )}
        />
        {depositor?.categoryId &&
          hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
            <ReferenceField
              source="categoryId"
              reference={ResourceName.DEVICE_CATEGORIES}
              link="show"
            >
              <FunctionField<DeviceCategoryExtendedDto>
                source="hierarchyString"
                render={(record?: DeviceCategoryExtendedDto) =>
                  `${record?.id}. ${record?.hierarchyString}`
                }
              />
            </ReferenceField>
          )}
        {depositor?.cameraId && hasAuthority(Authority.VIEW_CAMERAS) && (
          <ReferenceField
            source="cameraId"
            reference={ResourceName.CAMERAS}
            link="show"
          >
            <FunctionField<CameraDto>
              source="name"
              render={(camera?: CameraDto) =>
                `${camera?.id}. ${camera?.description}`
              }
            />
          </ReferenceField>
        )}
        {depositor?.disabledId && (
          <ReferenceField
            source="disabledId"
            label="resources.depositors.fields.disabled"
            reference={ResourceName.VERIFIER_DEVICE_DISABLED}
            link="show"
          >
            <FunctionField<VerifierDeviceDisabledDto>
              source="name"
              render={(disabled?: VerifierDeviceDisabledDto) =>
                `${disabled?.id}. ${disabled?.name}`
              }
            />
          </ReferenceField>
        )}
        <FunctionField<DepositorDto>
          source="onlineInfixCeck"
          render={(record?: DepositorDto) => (
            <div>
              {record?.onlineInfixCeck
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <FunctionField<DepositorDto>
          source="multiplicity"
          render={(record?: DepositorDto) => (
            <div>
              {translate(
                `resources.enums.multiplicities.${record?.multiplicity}`,
              )}
            </div>
          )}
        />
        {depositor?.supervisorId && hasAuthority(Authority.VIEW_PALMS) && (
          <ReferenceField
            source="supervisorId"
            reference={ResourceName.PALMS}
            link="show"
          >
            <FunctionField
              source="name"
              render={(palm) => `${palm?.id}. ${palm?.name}`}
            />
          </ReferenceField>
        )}
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_EVENTS) && (
        <Tab
          path="events"
          label={translate('resources.depositors.tabs.events')}
        >
          <>
            <RelationTab<DepositorDto>
              resource={ResourceName.EVENTS}
              source="eventsIds"
              mode={hasAuthority(Authority.EDIT_DEPOSITORS) ? 'edit' : 'show'}
              attachMethod="attachEvents"
              detachMethod="detachEvents"
              attachRequestPayload={(r, ids) => ({
                depositorId: r.id,
                eventsIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                depositorId: r.id,
                eventsIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVerifierDeviceId === true
              }
              filters={eventsFilters() as ReactElement[]}
              filterDefaultValues={{
                verifierDeviceId: depositor?.id,
                filterVerifierDeviceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ENTRANCES) && (
        <Tab
          path="entrances"
          label={translate('resources.depositors.tabs.entrances')}
        >
          <>
            <RelationTab<DepositorDto>
              resource={ResourceName.ENTRANCES}
              source="entrancesIds"
              mode={hasAuthority(Authority.EDIT_DEPOSITORS) ? 'edit' : 'show'}
              attachMethod="attachEntrances"
              detachMethod="detachEntrances"
              attachRequestPayload={(r, ids) => ({
                depositorId: r.id,
                entrancesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                depositorId: r.id,
                entrancesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVerifierDeviceId === true
              }
              filters={entrancesFilters() as ReactElement[]}
              filterDefaultValues={{
                verifierDeviceId: depositor?.id,
                filterVerifierDeviceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="note" />
            </RelationTab>
          </>
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const DepositorShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <DepositorTabs />
  </Show>
)
