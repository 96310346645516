import { DataProvider, sanitizeFetchType } from 'react-admin'
import accessDatesProvider from './access-dates/access-dates.provider'
import accessUsingsProvider from './access-usings/access-usings.provider'
import accessesProvider from './accesses/access.provider'
import companiesProvider from './administration/companies/companies.provider'
import permissionsProvider from './administration/permissions/permissions.provider'
import rolesProvider from './administration/roles/roles.provider'
import usersProvider from './administration/users/users.provider'
import blocksProvider from './blocks/blocks.provider'
import bookingsProvider from './bookings/bookings.provider'
import customersProvider from './customers/customers.provider'
import deviceCategoriesProvider from './device-categories/device-categories.provider'
import detectorsProvider from './devices/detectors/detectors.provider'
import engineProvider from './devices/engines/engines.provider'
import palmProvider from './devices/palms/palms.provider'
import passagesProvider from './devices/passages/passages.provider'
import entrancesProvider from './entrances/entrance.provider'
import eventsProvider from './events/event.provider'
import logsProvider from './extended-logs/extended-logs.provider'
import objectsProvider from './objects/objects.provider'
import { ResourceName } from './ResourceName'
import schemesProvider from './schemes/schemes.provider'
import seatsProvider from './seats/seats.provider'
import statsOverallViewProvider from './stats/overall-view/overall-view.provider'
import statsPeriodViewProvider from './stats/period-view/period-view.provider'
import statsPassageCountersViewProvider from './stats/passage-counters-view/passage-counters-view.provider'
import statsPeriodListProvider from './stats/period-view/period-list.provider'
import statsEntrancesViewProvider from './stats/event-entrances-view/event-entrances-view.provider'
import ticketsProvider from './tickets/tickets.provider'
import tribunesProvider from './tribunes/tribunes.provider'
import variantsProvider from './variants/variants.provider'
import camerasProvider from './devices/camera/cameras/cameras.provider'
import cameraServersProvider from './devices/camera/camera-servers/camera-servers.provider'
import jobsProvider from './jobs/jobs.provider'
import recurringJobsProvider from './recurring-jobs/recurring-jobs.provider'
import offlineServersProvider from './devices/offline-servers/offline-servers.provider'
import devicesBaseProvider from './devices/common/device-base/devices-base.provider'
import currentUserProvider from './current-user/current-user.provider'
import verifierDevicesProvider from './devices/common/verifier-device-base/verifier-devices.provider'
import logSchemeDataProvider from './log-scheme-data/log-scheme-data.provider'
import palmSchemeDataProvider from './palm-scheme-data/palm-scheme-data.provider'
import entrancesSetProvider from './entrances-set/entrances-set.provider'
import statsDynamicStatisticsProvider from './stats/dynamic-statistics/dynamic-statistics.provider'
import statsDynamicStatisticsExecutionProvider from './stats/dynamic-statistics/dynamic-statistics-execution.provider'
import statsDynamicStatisticParametersProvider from './stats/dynamic-statistics/parameters/dynamic-statistic-parameters.provider'
import technicalAssistanceProtocolProvider from './stats/technical-assistance-protocol/technical-assistance-protocol.provider'
import syncLogsProvider from './sync-logs/sync-log.provider'
import serviceLinkProvider from './service-link/service-link.provider'
import depositorsProvider from './devices/depositors/depositors.provider'
import dynamicPropertyDefinitionsProvider from './administration/dynamic-property-definitions/dynamic-property-definitions.provider'
import dynamicCountersProvider from './administration/dynamic-counters/dynamic-counters.provider'
import dynamicCounterParametersProvider from './administration/dynamic-counters/parameters/dynamic-counter-parameters.provider'
import accessBonusProvider from './access-bonus/access-bonus.provider'
import verifierDeviceDisabledProvider from './devices/verifier-device-disabled/verifier-device-disabled.provider'
import verifierSchemeData from './verifier-scheme-data/verifier-scheme-data.provider'
import doorsProvider from './devices/doors/doors.provider'
import terminalsProvider from './devices/terminals/terminals.provider'
import tsCansProvider from './devices/ts-cans/ts-cans.provider'
import ticketImportsProvider from './ticket-imports/ticket-imports.provider'
import ticketImportMappingsProvider from './ticket-import-mappings/ticket-import-mappings.provider'
import ticketImportTemplatesProvider from './ticket-import-templates/ticket-import-templates.provider'

const dataProviders: Record<ResourceName, DataProvider> = {
  [ResourceName.ACCESS_DATES]: accessDatesProvider,
  [ResourceName.ACCESSES]: accessesProvider,
  [ResourceName.ACCESS_USINGS]: accessUsingsProvider,
  [ResourceName.BOOKINGS]: bookingsProvider,
  [ResourceName.CAMERAS]: camerasProvider,
  [ResourceName.CAMERA_SERVERS]: cameraServersProvider,
  [ResourceName.COMPANIES]: companiesProvider,
  [ResourceName.CURRENT_USER]: currentUserProvider,
  [ResourceName.CUSTOMERS]: customersProvider,
  [ResourceName.DETECTORS]: detectorsProvider,
  [ResourceName.DEVICES_BASE]: devicesBaseProvider,
  [ResourceName.VERIFIER_DEVICES]: verifierDevicesProvider,
  [ResourceName.ENGINES]: engineProvider,
  [ResourceName.ENTRANCES]: entrancesProvider,
  [ResourceName.ENTRANCES_SET]: entrancesSetProvider,
  [ResourceName.EVENTS]: eventsProvider,
  [ResourceName.LOGS]: logsProvider,
  [ResourceName.OBJECTS]: objectsProvider,
  [ResourceName.PALMS]: palmProvider,
  [ResourceName.ROLES]: rolesProvider,
  [ResourceName.PERMISSIONS]: permissionsProvider,
  [ResourceName.PASSAGES]: passagesProvider,
  [ResourceName.STATS_OVERALL_VIEW]: statsOverallViewProvider,
  [ResourceName.STATS_OVERALL_VIEW_LIST]: eventsProvider,
  [ResourceName.STATS_PERIOD_VIEW]: statsPeriodViewProvider,
  [ResourceName.STATS_PERIOD_VIEW_LIST]: eventsProvider,
  [ResourceName.STATS_PERIOD_LIST]: statsPeriodListProvider,
  [ResourceName.STATS_PASSAGE_COUNTERS_VIEW]: statsPassageCountersViewProvider,
  [ResourceName.STATS_EVENT_ENTRANCES_VIEW]: statsEntrancesViewProvider,
  [ResourceName.STATS_EVENT_ENTRANCES_VIEW_LIST]: eventsProvider,
  [ResourceName.STATS_DYNAMIC_STATISTICS]: statsDynamicStatisticsProvider,
  [ResourceName.STATS_DYNAMIC_STATISTICS_EXECUTION]:
    statsDynamicStatisticsExecutionProvider,
  [ResourceName.STATS_DYNAMIC_STATISTIC_PARAMETERS]:
    statsDynamicStatisticParametersProvider,
  [ResourceName.TICKETS]: ticketsProvider,
  [ResourceName.TRIBUNES]: tribunesProvider,
  [ResourceName.BLOCKS]: blocksProvider,
  [ResourceName.SEATS]: seatsProvider,
  [ResourceName.USERS]: usersProvider,
  [ResourceName.VARIANTS]: variantsProvider,
  [ResourceName.DEVICE_CATEGORIES]: deviceCategoriesProvider,
  [ResourceName.JOBS]: jobsProvider,
  [ResourceName.RECURRING_JOBS]: recurringJobsProvider,
  [ResourceName.OFFLINE_SERVERS]: offlineServersProvider,
  [ResourceName.SERVICE_BOOKINGS]: bookingsProvider,
  [ResourceName.ENTRANCES_MATRIX]: verifierDevicesProvider,
  [ResourceName.SCHEMES]: schemesProvider,
  [ResourceName.LOG_SCHEME_DATA]: logSchemeDataProvider,
  [ResourceName.PALM_SCHEME_DATA]: palmSchemeDataProvider,
  [ResourceName.TECHNICAL_ASSISTANCE_PROTOCOL]:
    technicalAssistanceProtocolProvider,
  [ResourceName.SYNC_LOGS]: syncLogsProvider,
  [ResourceName.SERVICE_LINK]: serviceLinkProvider,
  [ResourceName.DEPOSITORS]: depositorsProvider,
  [ResourceName.DYNAMIC_PROPERTY_DEFINITIONS]:
    dynamicPropertyDefinitionsProvider,
  [ResourceName.DYNAMIC_COUNTERS]: dynamicCountersProvider,
  [ResourceName.DYNAMIC_COUNTER_PARAMETERS]: dynamicCounterParametersProvider,
  [ResourceName.ACCESS_BONUS]: accessBonusProvider,
  [ResourceName.VERIFIER_DEVICE_DISABLED]: verifierDeviceDisabledProvider,
  [ResourceName.VERIFIER_SCHEME_DATA]: verifierSchemeData,
  [ResourceName.DOORS]: doorsProvider,
  [ResourceName.TERMINALS]: terminalsProvider,
  [ResourceName.TS_CANS]: tsCansProvider,
  [ResourceName.TICKET_IMPORTS]: ticketImportsProvider,
  [ResourceName.TICKET_IMPORT_MAPPINGS]: ticketImportMappingsProvider,
  [ResourceName.TICKET_IMPORT_TEMPLATES]: ticketImportTemplatesProvider,
}

export default (
  fetchType: string,
  resource: string,
  params: unknown,
): Promise<unknown> => {
  const selectedProvider = dataProviders[resource as ResourceName]
  const type = sanitizeFetchType(fetchType)
  return selectedProvider[type](resource, params)
}
