import {
  Box,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { InputProps, Labeled, useLoading, useTranslate } from 'react-admin'
import { useField } from 'react-final-form'
import Button, {
  ButtonProps,
} from '../../common/customized-mui-components/Button'

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    background: theme.palette.background.default,
    margin: 0,
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    '& > div:last-child': {
      margin: 0,
    },
  },
  fileName: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
}))

interface FileInputProps extends InputProps {
  buttonProps?: ButtonProps
}

const FileInputBase = (props: FileInputProps) => {
  const { buttonProps, record, source } = props
  const classes = useStyles()
  const loading = useLoading()
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [value, setValue] = useState<File>(record?.[source])

  const {
    input: { onChange },
  } = useField(source)

  const handleChangeFile = (e: any) => {
    onChange({
      rawFile: e?.target?.files?.[0],
      src: e?.target?.value,
    })
  }

  let refInput: HTMLInputElement

  const clickImportButton = () => {
    refInput.value = ''
    refInput.click()
  }

  useEffect(() => {
    setValue(record?.[source]?.rawFile)
  }, [record, source])

  return (
    <Box m={2} className={classNames(classes.container)}>
      <Box>
        <Tooltip title={translate('common.import.button-tooltip')}>
          <Box>
            <Button
              color="primary"
              disabled={loading}
              label="common.import.button-load-description"
              onClick={clickImportButton}
              size={smallScreen ? 'large' : 'medium'}
              useSmallVersionBreakpoint={false}
              variant="outlined"
              {...buttonProps}
            />
          </Box>
        </Tooltip>
        <input
          accept=".csv,.tsv"
          className={classes.input}
          onChange={handleChangeFile}
          ref={(ref) => {
            refInput = ref as HTMLInputElement
          }}
          type="file"
        />
      </Box>
      {value?.name && (
        <Box ml={2}>
          <Typography className={classes.fileName}>{value?.name}</Typography>
        </Box>
      )}
    </Box>
  )
}

export const FileInput = (props: FileInputProps) => {
  const { formData, record, ...rest } = props
  const { fullWidth, label, resource, source } = rest

  return (
    <Labeled
      fullWidth={fullWidth}
      label={label}
      resource={resource}
      source={source}
    >
      <FileInputBase record={formData || record} {...rest} />
    </Labeled>
  )
}
