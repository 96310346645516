import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'

const RecurringJobFilters = ({ ...filterProps }) => (
  <Filter {...filterProps}>
    <TextInput key="name" source="name" alwaysOn resettable />
  </Filter>
)

export const RecurringJobList = (props: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <List
      exporter={false}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      filters={<RecurringJobFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid
        padding={smallScreen ? 'checkbox' : 'default'}
        rowClick={(id) => `${ResourceName.RECURRING_JOBS}/${id}/show`}
      >
        {!smallScreen && <TextField source="recurringJobId" />}
        <TextField source="name" />
        <TextField source="cron" />
        {!smallScreen && (
          <TextField
            source="jobParameters.method"
            label="resources.recurring-jobs.fields.jobTargetMethod"
          />
        )}
        <DateField source="lastExecution" />
        <DateField source="nextExecution" />
        {!smallScreen && <DateField source="createdAt" />}
      </Datagrid>
    </List>
  )
}
