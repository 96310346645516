import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import {
  Delete,
  Edit,
  FormatListBulleted,
  Visibility,
} from '@material-ui/icons'
import React, { useState } from 'react'
import {
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  FunctionField,
  Identifier,
  Link,
  Pagination,
  ShowButton,
  TextField,
  TextInput,
  useDelete,
  useRefresh,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DynamicStatisticDto } from '../../../core/dto/dynamic-statistic.dto'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import List from '../../common/customized-ra-components/List'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DynamicStatisticParametersDialog } from './DynamicStatisticsDialogs'

const DynamicStatisticsFilters = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

const PreviewButton = ({ ...props }) => {
  const { statistic } = props
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'))

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  return (
    <>
      <DynamicStatisticParametersDialog
        open={dialogOpen}
        close={handleCloseDialog}
        id={statistic?.id}
      />
      {largeScreen ? (
        <Button
          label="resources.stats-dynamic-statistics.actions.preview"
          startIcon={<FormatListBulleted />}
          onClick={handleOpenDialog}
        />
      ) : (
        <IconButton size="small" onClick={handleOpenDialog}>
          <FormatListBulleted color="primary" />
        </IconButton>
      )}
    </>
  )
}

export const DynamicStatisticsList = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [deleteOne] = useDelete()
  const refresh = useRefresh()

  const handleDelete = (statistic?: DynamicStatisticDto) => {
    if (statistic) {
      deleteOne(
        ResourceName.STATS_DYNAMIC_STATISTICS,
        statistic.id as Identifier,
      )
      refresh()
    }
  }

  return (
    <List
      {...props}
      exporter={false}
      filters={<DynamicStatisticsFilters />}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      bulkActionButtons={false}
    >
      <Datagrid hasBulkActions={false}>
        <TextField source="id" label="ID" />
        <TextField source="name" />
        {hasAuthority(Authority.EXECUTE_DYNAMIC_STATISTICS) && (
          <FunctionField<DynamicStatisticDto>
            render={(statistic?: DynamicStatisticDto) => (
              <PreviewButton statistic={statistic} />
            )}
          />
        )}
        <FunctionField<DynamicStatisticDto>
          render={(statistic?: DynamicStatisticDto) =>
            largeScreen ? (
              <ShowButton record={statistic} />
            ) : (
              <Link
                to={`/${ResourceName.STATS_DYNAMIC_STATISTICS}/${statistic?.id}/show`}
              >
                <IconButton size="small">
                  <Visibility />
                </IconButton>
              </Link>
            )
          }
        />
        {hasAuthority(Authority.EDIT_DYNAMIC_STATISTICS) && (
          <FunctionField<DynamicStatisticDto>
            render={(statistic?: DynamicStatisticDto) =>
              largeScreen ? (
                <EditButton record={statistic} />
              ) : (
                <Link
                  to={`/${ResourceName.STATS_DYNAMIC_STATISTICS}/${statistic?.id}`}
                >
                  <IconButton size="small">
                    <Edit />
                  </IconButton>
                </Link>
              )
            }
          />
        )}
        {hasAuthority(Authority.DELETE_DYNAMIC_STATISTICS) && (
          <FunctionField<DynamicStatisticDto>
            render={(statistic?: DynamicStatisticDto) =>
              largeScreen ? (
                <DeleteButton
                  record={statistic}
                  undoable={false}
                  resource={ResourceName.STATS_DYNAMIC_STATISTICS}
                />
              ) : (
                <IconButton
                  size="small"
                  onClick={() => handleDelete(statistic)}
                >
                  <Delete color="error" />
                </IconButton>
              )
            }
          />
        )}
      </Datagrid>
    </List>
  )
}
