export enum DynamicCounterParameterTypes {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DATE = 'DATE',
  CURRENT_DATE = 'CURRENT_DATE',
  CULTURE = 'CULTURE',
  CURRENT_USER_ID = 'CURRENT_USER_ID',
  USER_ID = 'USER_ID',
  COMPANY_ID = 'COMPANY_ID',
  PERMISSION_ID = 'PERMISSION_ID',
  ROLE_ID = 'ROLE_ID',
  CAMERA_ID = 'CAMERA_ID',
  CAMERA_SERVER_ID = 'CAMERA_SERVER_ID',
  PALM_ID = 'PALM_ID',
  PALM_SCHEME_DATA_ID = 'PALM_SCHEME_DATA_ID',
  DEPOSITOR_ID = 'DEPOSITOR_ID',
  DEVICE_BASE_ID = 'DEVICE_BASE_ID',
  ENGINE_ID = 'ENGINE_ID',
  OFFLINE_SERVER_ID = 'OFFLINE_SERVER_ID',
  PASSAGE_ID = 'PASSAGE_ID',
  PASSAGE_COUNTER_ID = 'PASSAGE_COUNTER_ID',
  VERIFIER_DEVICE_BASE_ID = 'VERIFIER_DEVICE_BASE_ID',
  DEVICE_CATEGORY_ID = 'DEVICE_CATEGORY_ID',
  ENTRANCE_ID = 'ENTRANCE_ID',
  ENTRANCE_SET_ID = 'ENTRANCE_SET_ID',
  CAMERA_TYPE = 'CAMERA_TYPE',
  DEVICE_DIRECTION = 'DEVICE_DIRECTION',
  HARDWARE_VERSION = 'HARDWARE_VERSION',
  PALM_THEME = 'PALM_THEME',
  PASSAGE_COUNTER_TYPE = 'PASSAGE_COUNTER_TYPE',
  PASSAGE_DIRECTION = 'PASSAGE_DIRECTION',
  LOG_ID = 'LOG_ID',
  LOG_SCHEME_DATA_ID = 'LOG_SCHEME_DATA_ID',
  ACCESS_ID = 'ACCESS_ID',
  ACCESS_DATE_ID = 'ACCESS_DATE_ID',
  ACCESS_USING_ID = 'ACCESS_USING_ID',
  BLOCK_ID = 'BLOCK_ID',
  BOOKING_ID = 'BOOKING_ID',
  CUSTOMER_ID = 'CUSTOMER_ID',
  EVENT_ID = 'EVENT_ID',
  OBJECT_ID = 'OBJECT_ID',
  SCHEME_ID = 'SCHEME_ID',
  SEAT_ID = 'SEAT_ID',
  TICKET_ID = 'TICKET_ID',
  TRIBUNE_ID = 'TRIBUNE_ID',
  VARIANT_ID = 'VARIANT_ID',
  ACCESS_ACTIVE_PERIOD_STARTING_OPTION = 'ACCESS_ACTIVE_PERIOD_STARTING_OPTION',
  ACCESS_BONUS = 'ACCESS_BONUS',
  ACCESS_DATE_TYPE = 'ACCESS_DATE_TYPE',
  BLOCK_TYPE = 'BLOCK_TYPE',
  BOOKING_DISABLED_OPTION = 'BOOKING_DISABLED_OPTION',
  BOOKING_TYPE = 'BOOKING_TYPE',
  CUSTOMER_DOCUMENT_TYPE = 'CUSTOMER_DOCUMENT_TYPE',
  CUSTOMER_TYPE = 'CUSTOMER_TYPE',
  EVENT_STATUS = 'EVENT_STATUS',
  GENDER = 'GENDER',
  TICKET_TYPES = 'TICKET_TYPES',
  TRIBUNE_TYPES = 'TRIBUNE_TYPES',
  REQUEST__DYNAMIC_COUNTER_DIRECTION = 'REQUEST__DYNAMIC_COUNTER_DIRECTION',
  REQUEST__TICKET_ID = 'REQUEST__TICKET_ID',
  REQUEST__TICKET_CODE = 'REQUEST__TICKET_CODE',
  REQUEST__TICKET_TYPE = 'REQUEST__TICKET_TYPE',
  REQUEST__TICKET_ACTIVE = 'REQUEST__TICKET_ACTIVE',
  REQUEST__TICKET_BOOKING_ID = 'REQUEST__TICKET_BOOKING_ID',
}
