import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  ListButton,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { TicketImportMappingDto } from '../../core/dto/ticket-import-mapping.dto'
import { ImportProperties } from '../../core/enum/ImportProperties'
import { Authority } from '../../core/auth/Authority'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { ResourceName } from '../../core/ResourceName'
import { useHasAuthority } from '../hooks/useHasAuthority'

export const validateTicketImportMapping = (values: TicketImportMappingDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.property) {
    errors.property = 'ra.validation.required'
  }
  if (!values.csvPropertyName && !values.staticValue) {
    errors.csvPropertyName = 'ra.validation.one_required'
    errors.staticValue = 'ra.validation.one_required'
  }
  if (values.csvPropertyName && values.staticValue) {
    errors.csvPropertyName = 'ra.validation.both_prohibited'
    errors.staticValue = 'ra.validation.both_prohibited'
  }
  if (!values.importTemplateId) {
    errors.importTemplateId = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return (
    <span>{translate('resources.ticket-import-mappings.titles.create')}</span>
  )
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const TicketImportMappingCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Create {...props} title={<CreateTitle />}>
      <SimpleForm
        redirect="list"
        validate={validateTicketImportMapping}
        toolbar={<CreateToolbar />}
      >
        <SelectInput
          source="property"
          choices={Object.entries(ImportProperties).map(([value]) => ({
            id: value,
            name: `resources.enums.ImportProperties.${value}`,
          }))}
          isRequired
        />
        <TextInput source="csvPropertyName" isRequired />
        <TextInput source="staticValue" />
        {hasAuthority(Authority.VIEW_TICKET_IMPORT_TEMPLATES) && (
          <FilteredReferenceInput
            source="importTemplateId"
            defaultValue={null}
            reference={ResourceName.TICKET_IMPORT_TEMPLATES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="name"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
      </SimpleForm>
    </Create>
  )
}
