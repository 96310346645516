import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Create,
  CreateProps,
  ListButton,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DepositorDto } from '../../../core/dto/device/depositor/depositor.dto'
import { DeviceDirection } from '../../../core/enum/DeviceDirection'
import { Multiplicities } from '../../../core/enum/Multiplicities'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const validateDepositorCreate = (values: DepositorDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.address) {
    errors.address = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.depositors.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const DepositorCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  const defaultValues = {
    multiplicity: Multiplicities.SINGLE,
  }

  return (
    <Create {...props} title={<CreateTitle />}>
      <SimpleForm
        redirect="show"
        validate={validateDepositorCreate}
        toolbar={<CreateToolbar />}
        initialValues={defaultValues}
      >
        <TextInput source="name" isRequired />
        <BooleanInput source="isWatched" />
        <TextInput source="address" isRequired />
        <SelectInput
          source="direction"
          defaultValue={DeviceDirection.IN}
          choices={Object.entries(DeviceDirection).map(([value]) => ({
            id: value,
            name: `resources.enums.deviceDirection.${value}`,
          }))}
        />
        {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <FilteredReferenceInput
            source="categoryId"
            defaultValue={null}
            reference={ResourceName.DEVICE_CATEGORIES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'hierarchyString',
              showFilter: true,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_CAMERAS) && (
          <FilteredReferenceInput
            source="cameraId"
            defaultValue={null}
            reference={ResourceName.CAMERAS}
            sort={{ field: 'description', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'description',
              showFilter: true,
            }}
          />
        )}
        <BooleanInput source="onlineInfixCeck" />
        <SelectInput
          source="multiplicity"
          choices={Object.entries(Multiplicities).map(([value]) => ({
            id: value,
            name: `resources.enums.multiplicities.${value}`,
          }))}
        />
        {hasAuthority(Authority.VIEW_PALMS) && (
          <FilteredReferenceInput
            source="supervisorId"
            defaultValue={null}
            reference={ResourceName.PALMS}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="name"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
      </SimpleForm>
    </Create>
  )
}
