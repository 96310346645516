import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyResult,
  Identifier,
} from 'react-admin'
import { VARIANTS_URL } from '../api-urls'
import { DataProviderExtensionResult } from '../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  recurringJobParamsComposer,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { RecurringJobCreationParamsDto } from '../dto/recurring-job-creation-params.dto'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'
import { VariantAccessesDto } from '../dto/variant-accesses.dto'
import { VariantDto } from '../dto/variant.dto'
import filterMapper from './variants-filter.mapper'
import { mapSortVariantParam } from './variants-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<VariantDto>> => {
    const filterParams = `v=>${filterParamsComposer('v', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortVariantParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<VariantDto[]>(
      `${VARIANTS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<VariantDto>> => {
    const data = await get<VariantDto>(VARIANTS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<VariantDto>,
  ): Promise<UpdateResult<VariantDto | RecurringJobDetailsDto>> => {
    const recurringJobParams = data as RecurringJobCreationParamsDto
    const isRecurringJob = recurringJobParams?.recurringJobName
    const update = await patch<
      UpdateVariantRequest,
      VariantDto | RecurringJobDetailsDto
    >(
      VARIANTS_URL,
      {
        id: id as number,
        name:
          data.name !== previousData.name || isRecurringJob
            ? data.name
            : undefined,
        active:
          data.active !== previousData.active || isRecurringJob
            ? data.active
            : undefined,
      },
      recurringJobParams && recurringJobParams,
    )
    if ((update as RecurringJobDetailsDto)?.recurringJobId)
      return Promise.resolve({ data: previousData as VariantDto })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<VariantDto>,
  ): Promise<CreateResult> => {
    const created = await post<CreateVariantRequest[], VariantDto[]>(
      VARIANTS_URL,
      [
        {
          name: data?.name,
          active: data?.active || false,
        },
      ],
    )
    return Promise.resolve({ data: created[0] })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<VariantDto>> => {
    const data = await get<VariantDto[]>(
      VARIANTS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  attachAccesses: async (
    resource: string,
    { variantId, accessesIds, ...rest }: VariantAccessesDto,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const attachAccesses = await put<
      VariantAccessesDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${VARIANTS_URL}/AttachAccesses${query ? `?${query}` : ''}`, {
      variantId,
      accessesIds,
    })
    if ((attachAccesses as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: attachAccesses as RecurringJobDetailsDto,
      }
    return {
      data: attachAccesses,
    }
  },
  detachAccesses: async (
    resource: string,
    { variantId, accessesIds, ...rest }: VariantAccessesDto,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const detachAccesses = await put<
      VariantAccessesDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${VARIANTS_URL}/DetachAccesses${query ? `?${query}` : ''}`, {
      variantId,
      accessesIds,
    })
    if ((detachAccesses as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: detachAccesses as RecurringJobDetailsDto,
      }
    return {
      data: detachAccesses,
    }
  },
} as VariantDataProvider

interface VariantDataProvider extends DataProvider {
  attachAccesses: (
    resource: string,
    params: VariantAccessesDto,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  detachAccesses: (
    resource: string,
    params: VariantAccessesDto,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >
}

interface ChangeStateResponse {
  jobId: Identifier
}

type CreateVariantRequest = Pick<VariantDto, 'name'> &
  Partial<Pick<VariantDto, 'active'>>

type UpdateVariantRequest = Pick<VariantDto, 'id'> &
  Partial<Pick<VariantDto, 'name' | 'active'>>

export default provider
