import { DataProvider } from 'react-admin'
import { PalmDto } from '../../dto/device/palm/palm.dto'

const fakePalms: PalmDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakePalms, total: fakePalms.length }),
} as DataProvider

export default provider
