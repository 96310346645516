import { Box, Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Labeled,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useField } from 'react-final-form'
import { PassageDto } from '../../../core/dto/device/passages/passage.dto'
import { SPOM4Directions } from '../../../core/enum/SPOM4Directions'
import { ResourceName } from '../../../core/ResourceName'
import { EngineDto } from '../../../core/dto/device/engine/engine.dto'

export const SPOM4FullAddressField = ({
  source,
  record,
}: {
  source: keyof PassageDto
  record?: PassageDto
}) => {
  const translate = useTranslate()
  const addressParts = (record && (record[source] as string))?.split('&')

  return (
    <Box>
      <Grid container direction="column">
        {addressParts && addressParts[0] && (
          <Grid item>
            <Labeled resource={ResourceName.PASSAGES} source="spom4_ipGate">
              <TextField emptyText={addressParts[0]} />
            </Labeled>
          </Grid>
        )}
        {addressParts && addressParts[1] && (
          <Grid item>
            <Labeled resource={ResourceName.PASSAGES} source="spom4_tscanHost">
              <TextField emptyText={addressParts[1]} />
            </Labeled>
          </Grid>
        )}
        {addressParts && addressParts[2] && addressParts[2] in SPOM4Directions && (
          <Grid item>
            <Labeled resource={ResourceName.PASSAGES} source="spom4_direction">
              <TextField
                emptyText={translate(
                  `resources.enums.spom4Directions.${addressParts[2]}`,
                )}
              />
            </Labeled>
          </Grid>
        )}
        {addressParts && addressParts[3] && (
          <Grid item>
            <Labeled
              resource={ResourceName.PASSAGES}
              source="spom4_rs485Address"
            >
              <TextField emptyText={addressParts[3]} />
            </Labeled>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export const SPOM4IpGateAddressField = ({
  source,
  record,
}: {
  source: keyof PassageDto | keyof EngineDto
  record?: PassageDto | EngineDto
}) => {
  const addressParts = (record && (record[source] as string))?.split('&')

  return (
    <TextField
      source="spom4_ipGate"
      emptyText={addressParts && addressParts[0]}
    />
  )
}

export const SPOM4FullAddressInput = ({
  ...props
}: SPOM4FullAddressInputProps) => {
  const { source, record, className } = props
  const {
    input: { onChange },
  } = useField(source)

  const initialAddressParts = (record && (record[source] as string))?.split('&')
  const [ipGate, setIpGate] = useState(
    initialAddressParts && initialAddressParts[0] ? initialAddressParts[0] : '',
  )
  const [tscanHost, setTScanHost] = useState(
    initialAddressParts && initialAddressParts[1] ? initialAddressParts[1] : '',
  )
  const [direction, setDirection] = useState(
    initialAddressParts && initialAddressParts[2] ? initialAddressParts[2] : '',
  )
  const [rs485Address, setRS485Address] = useState(
    initialAddressParts && initialAddressParts[3] ? initialAddressParts[3] : '',
  )
  const getFinalValue = useCallback(
    () => `${ipGate}&${tscanHost}&${direction}&${rs485Address}`,
    [ipGate, tscanHost, direction, rs485Address],
  )

  useEffect(() => {
    onChange({ target: { value: getFinalValue() } })
  }, [getFinalValue, onChange])

  return (
    <Box>
      <Grid container direction="column">
        <Grid item>
          <TextInput
            className={className}
            resource={ResourceName.PASSAGES}
            source="spom4_ipGate"
            defaultValue={ipGate}
            value={ipGate}
            onChange={(e) => {
              setIpGate(e.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextInput
            className={className}
            resource={ResourceName.PASSAGES}
            source="spom4_tscanHost"
            defaultValue={tscanHost}
            value={tscanHost}
            onChange={(e) => {
              setTScanHost(e.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <SelectInput
            className={className}
            resource={ResourceName.PASSAGES}
            source="spom4_direction"
            defaultValue={direction}
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
            choices={Object.entries(SPOM4Directions).map(([value]) => ({
              id: value,
              name: `resources.enums.spom4Directions.${value}`,
            }))}
          />
        </Grid>
        <Grid item>
          <TextInput
            className={className}
            resource={ResourceName.PASSAGES}
            source="spom4_rs485Address"
            defaultValue={rs485Address}
            value={rs485Address}
            onChange={(e) => {
              setRS485Address(e.target.value)
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export interface SPOM4FullAddressInputProps {
  source: keyof PassageDto
  record?: PassageDto
  className?: string
}
