import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { SERVICE_LINK_URL } from '../api-urls'
import { get, getWithPagination, patch, post } from '../common/fetch.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { ServiceLinkDto } from '../dto/service-link.dto'
import filterMapper from './service-link-filter.mapper'
import { mapSortLogParam } from './service-link-sort.mapper'
import { ServiceTypes } from '../enum/ServiceTypes'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'
import { DataProviderExtensionResult } from '../common/data-provider'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<ServiceLinkDto>> => {
    const filterParams = `a=>${filterParamsComposer('a', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortLogParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<ServiceLinkDto[]>(
      `${SERVICE_LINK_URL}/GetServiceLinkByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<ServiceLinkDto>> => {
    const data = await get<ServiceLinkDto>(
      `${SERVICE_LINK_URL}/GetServiceLink`,
      `/${id}`,
    )

    return {
      data,
    }
  },
  create: async (
    resource: string,
    { data }: CreateParams<ServiceLinkDto>,
  ): Promise<CreateResult<ServiceLinkDto>> => {
    const event = await post<CreateServiceLinkRequest[], ServiceLinkDto[]>(
      `${SERVICE_LINK_URL}/PostServiceLink`,
      [data],
    )

    return {
      data: event[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<ServiceLinkDto>,
  ): Promise<UpdateResult<ServiceLinkDto>> => {
    const update = await patch<{ [n: string]: any }, ServiceLinkDto>(
      `${SERVICE_LINK_URL}/PatchServiceLink`,
      {
        id,
        serviceId:
          data.serviceId !== previousData.serviceId
            ? data.serviceId
            : undefined,
        serviceType:
          data.serviceType !== previousData.serviceType
            ? data.serviceType
            : undefined,
        apiUrl: data.apiUrl !== previousData.apiUrl ? data.apiUrl : undefined,
        authorizationHash:
          data.authorizationHash !== previousData.authorizationHash
            ? data.authorizationHash
            : undefined,
        eventId:
          data.eventId !== previousData.eventId ? data.eventId : undefined,
        blockId:
          data.blockId !== previousData.blockId ? data.blockId : undefined,
        customerId:
          data.customerId !== previousData.customerId
            ? data.customerId
            : undefined,
        variantId:
          data.variantId !== previousData.variantId
            ? data.variantId
            : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  syncCards: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    params: undefined,
  ): Promise<GetOneResult<SyncCardsResponse>> => {
    const data = await post<undefined, SyncCardsResponse>(
      `${SERVICE_LINK_URL}/SyncCardsWithBookingsAsyncJob`,
      undefined,
    )
    return Promise.resolve({ data })
  },
} as ServiceLinkDataProvider

interface ServiceLinkDataProvider extends DataProvider {
  syncCards: (
    resource: string,
    params: undefined,
  ) => Promise<DataProviderExtensionResult<SyncCardsResponse>>
}

type SyncCardsResponse =
  | {
      readonly jobId: number
    }
  | RecurringJobDetailsDto

interface CreateServiceLinkRequest {
  readonly serviceId: number
  readonly serviceType: ServiceTypes
  readonly apiUrl: string
  readonly authorizationHash: string
  readonly eventId: number
  readonly blockId: number
  readonly customerId: number
  readonly variantId: number
}

export default provider
