import { DataProvider } from 'react-admin'
import { PassageDto } from '../../dto/device/passages/passage.dto'

const fakePassages: PassageDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakePassages, total: fakePassages.length }),
} as DataProvider

export default provider
