import { sanitizeFetchType } from 'react-admin'
import { PassageDto } from '../../dto/device/passages/passage.dto'
import { ResourceName } from '../../ResourceName'
import {
  ToggleButtonAction,
  ToggleButtonState,
  ToggleType,
} from './passage-reset-counters-button'

type Mutation = {
  type: string
  resource: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
}

const resetMutation = (
  { id }: PassageDto,
  auditNote?: string,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('resetCounters'),
  resource: ResourceName.PASSAGES,
  payload: {
    passageId: id,
    auditComment: auditNote,
    recurringJobName,
    cronExpression,
  },
})

type State = {
  mutateQuery?: (
    passage: PassageDto,
    auditNote?: string,
    recurringJobName?: string,
    cronExpression?: string,
  ) => Mutation
} & ToggleButtonState

export const passageResetCountersButtonInitialState = {
  type: ToggleType.DISABLED,
  text: 'resources.passages.actions.resetCounters.disabled',
}

export const passageResetCountersButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case ToggleType.RESET: {
      return {
        type,
        text: 'resources.passages.actions.resetCounters.reset',
        mutateQuery: resetMutation,
      }
    }
    case ToggleType.DISABLED: {
      return passageResetCountersButtonInitialState
    }
    default:
      throw new Error()
  }
}
