import { DataProvider } from 'react-admin'
import { EngineDto } from '../../dto/device/engine/engine.dto'

const fakeEngines: EngineDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeEngines, total: fakeEngines.length }),
} as DataProvider

export default provider
