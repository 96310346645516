export enum ToggleType {
  OFF = 'OFF',
  ON = 'ON',
}

export type ToggleButtonState = {
  type: ToggleType
  text: string
}

export type ToggleButtonAction = { type: ToggleType }
