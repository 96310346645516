import React, { useState } from 'react'
import { useLoading } from 'react-admin'
import { TicketImportDialog } from './TicketImportDialog'
import { ResourceName } from '../../core/ResourceName'
import Button from '../common/customized-mui-components/Button'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'

export const TicketImportButton = ({ ...props }) => {
  const { dialogProps, buttonProps } = props
  const [createOpen, setCreateOpen] = useState<boolean>(false)
  const hasAuthority = useHasAuthority()
  const loading = useLoading()

  const handleCloseCreate = () => {
    setCreateOpen(false)
  }

  const handleOpenCreate = () => {
    setCreateOpen(true)
  }

  return (
    <>
      <TicketImportDialog
        {...dialogProps}
        basePath="/ticket-imports"
        handleClose={handleCloseCreate}
        resource={ResourceName.TICKET_IMPORTS}
        open={createOpen}
      />
      <Button
        onClick={handleOpenCreate}
        label="resources.ticket-imports.actions.importTicketsAsyncJob"
        useSmallVersionBreakpoint={false}
        disabled={!hasAuthority(Authority.IMPORT_TICKETS) || loading}
        variant="contained"
        color="primary"
        size="small"
        {...buttonProps}
      />
    </>
  )
}
