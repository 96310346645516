import { fetchUtils, HttpError } from 'react-admin'
import { RecurringJobCreationParamsDto } from '../dto/recurring-job-creation-params.dto'
import { ContentRangeHeaderValue, contentRangeParser } from './header.parser'
import { AuditCommentParamsDto } from '../dto/audit-comment-params.dto'

const getToken = () => localStorage.getItem('token') as string

const baseHeaders: HeadersInit = {
  'Content-Type': 'application/json',
}

const authHeaders = (token = getToken()): HeadersInit => ({
  ...baseHeaders,
  Authorization: `Bearer ${token}`,
})

const csvAuthHeaders = (token = getToken()): HeadersInit => ({
  Authorization: `Bearer ${token}`,
})

const fetchBlob = (url, options: fetchUtils.Options = {}) => {
  const requestHeaders = fetchUtils.createHeadersFromOptions(options)

  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) => {
      if (response?.status < 200 || response?.status >= 300) {
        return response
          .text()
          .then((text) => ({
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            body: text,
          }))
          .then(({ status, statusText, body }) => {
            const json = JSON.parse(body)
            return Promise.reject(
              new HttpError((json && json.message) || statusText, status, json),
            )
          })
      }
      return response.blob().then((blob) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: blob,
      }))
    })
    .then(({ status, headers, body }) =>
      Promise.resolve({ status, headers, body }),
    )
}

const getWithHeaders = <T>(url: string, headers: HeadersInit) =>
  fetchUtils
    .fetchJson(url, {
      method: 'GET',
      headers: new Headers(headers),
    })
    .then(({ json }) => json as Promise<T>)

const postWithHeaders = <T, K>(url: string, body: T, headers: HeadersInit) =>
  fetchUtils
    .fetchJson(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers(headers),
    })
    .then(({ json }) => json as Promise<K>)

const postFileWithHeaders = <K>(
  url: string,
  body: FormData,
  headers: HeadersInit,
) =>
  fetchUtils
    .fetchJson(url, {
      method: 'POST',
      body,
      headers: new Headers(headers),
    })
    .then(({ json }) => json as Promise<K>)

const getBlobWithHeaders = (url: string, headers: HeadersInit) =>
  fetchBlob(url, {
    method: 'GET',
    headers: new Headers(headers),
  }).then((data) => data)

export const get = <T>(baseUrl: string, path?: string) =>
  getWithHeaders<T>(`${baseUrl}${path || ''}`, authHeaders())

export const getBlob = (baseUrl: string, path?: string) =>
  getBlobWithHeaders(`${baseUrl}${path || ''}`, authHeaders())

export const getWithToken = <T>(url: string, token: string) =>
  getWithHeaders<T>(url, authHeaders(token))

export const getByConditions = <T>(
  baseUrl: string,
  path: string,
): Promise<T[]> =>
  getWithHeaders<T[]>(`${baseUrl}/GetByConditions/${path}`, authHeaders())

export const getWithPagination = <T>(baseUrl: string, path: string) =>
  fetchUtils
    .fetchJson(`${baseUrl}${path}`, {
      method: 'GET',
      headers: new Headers(authHeaders()),
    })
    .then(({ json, headers }) =>
      Promise.resolve({
        data: json as T,
        range: contentRangeParser(
          headers.get('content-range') as ContentRangeHeaderValue,
        ),
      }),
    )

export const authPost = <T, K>(url: string, body: T) =>
  postWithHeaders<T, K>(url, body, baseHeaders)

export const recurringJobParamsComposer = (
  recurringJobParams: RecurringJobCreationParamsDto,
): string | undefined => {
  const queryParams: string[] = []
  if (
    recurringJobParams?.recurringJobName &&
    recurringJobParams?.cronExpression
  ) {
    const { recurringJobName, cronExpression } = recurringJobParams
    queryParams.push(`recurringJobName=${recurringJobName}`)
    queryParams.push(`cronExpression=${cronExpression}`)
  }
  return queryParams.length > 0
    ? queryParams.reduce((p, c) => `${p}&${c}`)
    : undefined
}

export const auditCommentParamComposer = (
  auditCommentParams: AuditCommentParamsDto,
): string | undefined => {
  let queryParam: string | undefined
  if (auditCommentParams?.auditComment) {
    const { auditComment } = auditCommentParams
    queryParam = `auditComment=${auditComment}`
  }
  return queryParam
}

export const post = <T, K>(url: string, body: T) =>
  postWithHeaders<T, K>(url, body, authHeaders())

export const postFile = <K>(url: string, body: FormData) =>
  postFileWithHeaders<K>(url, body, csvAuthHeaders())

export const postWithPagination = <T, K>(url: string, body: T) =>
  fetchUtils
    .fetchJson(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers(authHeaders()),
    })
    .then(({ json, headers }) =>
      Promise.resolve({
        ...(json as K),
        range: contentRangeParser(
          headers.get('content-range') as ContentRangeHeaderValue,
        ),
      }),
    )

export const patch = <T, K>(
  baseUrl: string,
  body: T,
  recurringJobParams?: RecurringJobCreationParamsDto,
) => {
  const query =
    recurringJobParams && recurringJobParamsComposer(recurringJobParams)
  return fetchUtils
    .fetchJson(`${baseUrl}${query ? `?${query}` : ''}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: new Headers(authHeaders()),
    })
    .then(({ json }) => json as Promise<K>)
}

export const patchWithAuditComment = <T, K>(
  baseUrl: string,
  body: T,
  additionalParams?: RecurringJobCreationParamsDto | AuditCommentParamsDto,
) => {
  const recurringJobQuery =
    additionalParams &&
    recurringJobParamsComposer(
      additionalParams as RecurringJobCreationParamsDto,
    )
  const auditCommentQuery =
    additionalParams &&
    auditCommentParamComposer(additionalParams as AuditCommentParamsDto)
  const queryParams: string[] = []
  if (recurringJobQuery) {
    queryParams.push(recurringJobQuery)
  }
  if (auditCommentQuery) {
    queryParams.push(auditCommentQuery)
  }
  const query = queryParams?.length
    ? queryParams.reduce((p, c) => `${p}&${c}`)
    : undefined
  return fetchUtils
    .fetchJson(`${baseUrl}${query ? `?${query}` : ''}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: new Headers(authHeaders()),
    })
    .then(({ json }) => json as Promise<K>)
}

export const put = <T, K>(baseUrl: string, body: T) =>
  fetchUtils
    .fetchJson(baseUrl, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: new Headers(authHeaders()),
    })
    .then(({ json }) => json as Promise<K>)

export const remove = <T>(baseUrl: string, path: string) =>
  fetchUtils
    .fetchJson(`${baseUrl}${path || ''}`, {
      method: 'DELETE',
      headers: new Headers(authHeaders()),
    })
    .then(({ json }) => json as Promise<T>)
