import { createMuiTheme, defaultTheme, RaThemeOptions } from 'react-admin'
import { blue, blueGrey, lightBlue } from '@material-ui/core/colors'
import {
  Palette,
  PaletteColor,
  PaletteOptions,
} from '@material-ui/core/styles/createPalette'
import { PaletteColorOptions, Theme } from '@material-ui/core'

interface ExtendedThemeOptions extends RaThemeOptions {
  palette?: ExtendedPaleteOptions
}

interface ExtendedPaleteOptions extends PaletteOptions {
  chart?: PaletteColorOptions
  rjvTheme?: any
}

export interface ExtendedTheme extends Theme {
  palette: ExtendedPalete
}

export interface ExtendedPalete extends Palette {
  chart: PaletteColor
  rjvTheme: any
}

const extendedThemeOptions = {
  ...defaultTheme,
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 200,
    },
  },
  overrides: {
    RaFilter: {
      form: {
        margin: 0,
      },
    },
    MuiTableCell: {
      body: {
        color: 'inherit',
      },
    },
    RaRowForm: {
      actionColumn: {
        padding: 0,
      },
    },
    MuiIconButton: {
      root: {
        color: 'inherit',
      },
    },
  },
  sidebar: {
    width: 240,
    closedWidth: 55,
  },
} as ExtendedThemeOptions

const extendedLightThemeOptions: ExtendedThemeOptions = {
  ...extendedThemeOptions,
  palette: {
    ...extendedThemeOptions.palette,
    type: 'light',
    primary: {
      light: blue[400],
      main: blue[600],
      dark: blue[800],
      contrastText: '#fff',
    },
    secondary: {
      light: blueGrey[50],
      main: blueGrey[100],
      dark: blueGrey[400],
      contrastText: '#111',
    },
    chart: {
      light: lightBlue[300],
      main: lightBlue[400],
      dark: lightBlue[600],
      contrastText: '#111',
    },
  },
}

const extendedDarkThemeOptions: ExtendedThemeOptions = {
  ...extendedThemeOptions,
  palette: {
    ...extendedThemeOptions.palette,
    type: 'dark',
    primary: {
      light: lightBlue[200],
      main: lightBlue[400],
      dark: lightBlue[700],
      contrastText: '#fff',
    },
    secondary: {
      light: blueGrey[500],
      main: blueGrey[600],
      dark: blueGrey[800],
      contrastText: '#fff',
    },
    chart: {
      light: lightBlue[500],
      main: lightBlue[700],
      dark: lightBlue[800],
      contrastText: '#fff',
    },
  },
}

const lightTheme = createMuiTheme(extendedLightThemeOptions)
const darkTheme = createMuiTheme(extendedDarkThemeOptions)

export const extendedLightTheme: ExtendedTheme = {
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    rjvTheme: {
      base00: lightTheme.palette.background.default,
      base01: lightTheme.palette.background.paper,
      base02: lightTheme.palette.background.paper,
      base03: lightTheme.palette.secondary.dark,
      base04: lightTheme.palette.primary.main,
      base05: lightTheme.palette.primary.main,
      base06: lightTheme.palette.primary.light,
      //label:
      base07: lightTheme.palette.text.secondary,
      base08: lightTheme.palette.secondary.dark,
      //string:
      base09: lightTheme.palette.secondary.contrastText,
      //null:
      base0A: lightTheme.palette.secondary.light,
      base0B: lightTheme.palette.secondary.dark,
      base0C: lightTheme.palette.secondary.main,
      //collapse arrow:
      base0D: lightTheme.palette.primary.contrastText,
      base0E: lightTheme.palette.primary.dark,
      //int:
      base0F: lightTheme.palette.primary.main,
    },
  },
} as ExtendedTheme

export const extendedDarkTheme: ExtendedTheme = {
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    rjvTheme: {
      base00: darkTheme.palette.background.default,
      base01: darkTheme.palette.background.paper,
      base02: darkTheme.palette.background.paper,
      base03: darkTheme.palette.secondary.dark,
      base04: darkTheme.palette.primary.main,
      base05: darkTheme.palette.primary.main,
      base06: darkTheme.palette.primary.light,
      //label:
      base07: darkTheme.palette.text.secondary,
      base08: darkTheme.palette.secondary.dark,
      //string:
      base09: darkTheme.palette.secondary.contrastText,
      //null:
      base0A: darkTheme.palette.secondary.light,
      base0B: darkTheme.palette.secondary.dark,
      base0C: darkTheme.palette.secondary.main,
      //collapse arrow:
      base0D: darkTheme.palette.primary.contrastText,
      base0E: darkTheme.palette.primary.dark,
      //int:
      base0F: darkTheme.palette.primary.main,
    },
  },
} as ExtendedTheme
