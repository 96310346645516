const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  id: (lambdaParameter, value) => `${lambdaParameter}.Id.Equals(${value})`,
  ticketsIds: (lambdaParameter, value) => {
    if (!value) {
      return null
    }
    return `(new int[] {${(
      value as number[]
    )?.toString()}}.Contains(${lambdaParameter}.TicketId))`
  },
  accessId: (lambdaParameter, value) =>
    `(${lambdaParameter}.AccessId.Equals(${value}))`,
  bookingId: (lambdaParameter, value) =>
    `(${lambdaParameter}.Ticket.BookingId.Equals(${value}))`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
