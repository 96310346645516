import PersonIcon from '@material-ui/icons/Person'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ViewComfyIcon from '@material-ui/icons/ViewComfy'
import React from 'react'
import { Resource } from 'react-admin'
import { Authority } from '../core/auth/Authority'
import { ResourceName } from '../core/ResourceName'
import { CompanyCreate } from './administration/companies/CompanyCreate'
import { CompanyEdit } from './administration/companies/CompanyEdit'
import { CompanyList } from './administration/companies/CompanyList'
import { CompanyShow } from './administration/companies/CompanyShow'
import { RoleCreate } from './administration/roles/RoleCreate'
import { RoleEdit } from './administration/roles/RoleEdit'
import { RoleList } from './administration/roles/RoleList'
import { RoleShow } from './administration/roles/RoleShow'
import { UserCreate } from './administration/users/UserCreate'
import { UserEdit } from './administration/users/UserEdit'
import { UserList } from './administration/users/UserList'
import { UserShow } from './administration/users/UserShow'
import { BookingShow } from './bookings/BookingShow'
import { ServiceBookingCreate } from './bookings/service-bookings/ServiceBookingCreate'
import { ServiceBookingEdit } from './bookings/service-bookings/ServiceBookingEdit'
import { ServiceBookingList } from './bookings/service-bookings/ServiceBookingList'
import { AccessDateCreate } from './configuration/access-dates/AccessDateCreate'
import { AccessCreate } from './configuration/accesses/AccessCreate'
import { AccessEdit } from './configuration/accesses/AccessEdit'
import { AccessList } from './configuration/accesses/AccessList'
import { AccessShow } from './configuration/accesses/AccessShow'
import { EntrancesMatrixList } from './configuration/entrances-matrix/EntrancesMatrixList'
import { EntranceCreate } from './configuration/entrances/EntranceCreate'
import { EntranceEdit } from './configuration/entrances/EntranceEdit'
import { EntranceList } from './configuration/entrances/EntranceList'
import { EntranceShow } from './configuration/entrances/EntranceShow'
import { VariantCreate } from './configuration/variants/VariantCreate'
import { VariantEdit } from './configuration/variants/VariantEdit'
import { VariantList } from './configuration/variants/VariantList'
import { VariantShow } from './configuration/variants/VariantShow'
import { CurrentUserEdit } from './current-user/CurrentUserEdit'
import { CustomersCreate } from './customers/CustomerCreate'
import { CustomerEdit } from './customers/CustomerEdit'
import { CustomerList } from './customers/CustomerList'
import { CustomerDetailsView } from './customers/CustomerShow'
import { DeviceCategoryList } from './device-categories/DeviceCategoryList'
import { CameraServerCreate } from './devices/camera/camera-servers/CameraServerCreate'
import { CameraServerEdit } from './devices/camera/camera-servers/CameraServerEdit'
import { CameraServerList } from './devices/camera/camera-servers/CameraServerList'
import { CameraServerShow } from './devices/camera/camera-servers/CameraServerShow'
import { CameraCreate } from './devices/camera/cameras/CameraCreate'
import { CameraEdit } from './devices/camera/cameras/CameraEdit'
import { CameraList } from './devices/camera/cameras/CameraList'
import { CameraShow } from './devices/camera/cameras/CameraShow'
import { DetectorCreate } from './devices/detectors/DetectorCreate'
import { DetectorEdit } from './devices/detectors/DetectorEdit'
import { DetectorList } from './devices/detectors/DetectorList'
import { DetectorShow } from './devices/detectors/DetectorShow'
import { EngineCreate } from './devices/engines/EngineCreate'
import { EngineEdit } from './devices/engines/EngineEdit'
import { EngineList } from './devices/engines/EngineList'
import { EngineShow } from './devices/engines/EngineShow'
import { OfflineServerCreate } from './devices/offline-servers/OfflineServerCreate'
import { OfflineServerEdit } from './devices/offline-servers/OfflineServerEdit'
import { OfflineServerList } from './devices/offline-servers/OfflineServerList'
import { OfflineServerShow } from './devices/offline-servers/OfflineServerShow'
import { PalmCreate } from './devices/palms/PalmCreate'
import { PalmEdit } from './devices/palms/PalmEdit'
import { PalmList } from './devices/palms/PalmList'
import { PalmShow } from './devices/palms/PalmShow'
import { PassageCreate } from './devices/passages/PassageCreate'
import { PassageEdit } from './devices/passages/PassageEdit'
import { PassagesList } from './devices/passages/PassageList'
import { PassageShow } from './devices/passages/PassageShow'
import { EventCreate } from './events/EventCreate'
import { EventEdit } from './events/EventEdit'
import { EventList } from './events/EventList'
import { EventShow } from './events/EventShow'
import { hasAuthorityWithGivenPermissions as hasAuthority } from './hooks/useHasAuthority'
import { LogList } from './logs/LogList'
import BlockCreate from './objects/Blocks/BlockCreate'
import BlocksList from './objects/Blocks/BlocksList'
import ObjectCreate from './objects/Objects/ObjectCreate'
import ObjectEdit from './objects/Objects/ObjectEdit'
import ObjectList from './objects/Objects/ObjectList'
import ObjectShow from './objects/Objects/ObjectShow'
import SeatCreate from './objects/Seats/SeatCreate'
import SeatsList from './objects/Seats/SeatsList'
import TribuneCreate from './objects/Tribunes/TribuneCreate'
import TribunesList from './objects/Tribunes/TribunesList'
import { RecurringJobList } from './jobs/recurring-jobs/RecurringJobList'
import { RecurringJobShow } from './jobs/recurring-jobs/RecurringJobShow'
import { DynamicStatisticCreate } from './stats/dynamic-statistics/DynamicStatisticsCreate'
import { DynamicStatisticEdit } from './stats/dynamic-statistics/DynamicStatisticsEdit'
import { DynamicStatisticsList } from './stats/dynamic-statistics/DynamicStatisticsList'
import { DynamicStatisticsShow } from './stats/dynamic-statistics/DynamicStatisticsShow'
import { EventEntrancesView } from './stats/event-entrances-view/EventEntrancesView'
import { EventEntrancesViewList } from './stats/event-entrances-view/EventEntrancesViewList'
import { OverallView } from './stats/overall-view/OverallView'
import { OverallViewList } from './stats/overall-view/OverallViewList'
import { PeriodView } from './stats/period-view/PeriodView'
import { PeriodViewList } from './stats/period-view/PeriodViewList'
import { TicketImport } from './tickets/TicketImport'
import { TicketList } from './tickets/TicketList'
import { BookingCreate } from './bookings/BookingCreate'
import { BookingEdit } from './bookings/BookingEdit'
import { LogSchemeDataList } from './configuration/scheme-data/log-scheme-data/LogSchemeDataList'
import { PalmSchemeDataList } from './configuration/scheme-data/palm-scheme-data/PalmSchemeDataList'
import { PalmSchemeDataShow } from './configuration/scheme-data/palm-scheme-data/PalmSchemeDataShow'
import { PalmSchemeDataEdit } from './configuration/scheme-data/palm-scheme-data/PalmSchemeDataEdit'
import { LogSchemeDataShow } from './configuration/scheme-data/log-scheme-data/LogSchemeDataShow'
import { LogSchemeDataEdit } from './configuration/scheme-data/log-scheme-data/LogSchemeDataEdit'
import { SyncLogsList } from './sync-logs/SyncLogsList'
import { ServiceLinkList } from './service-link/ServiceLinkList'
import { ServiceLinkShow } from './service-link/ServiceLinkShow'
import { ServiceLinkCreate } from './service-link/ServiceLinkCreate'
import { ServiceLinkEdit } from './service-link/ServiceLinkEdit'
import { JobList } from './jobs/job-details/JobList'
import { JobShow } from './jobs/job-details/JobShow'
import { DepositorsList } from './devices/depositors/DepositorList'
import { DepositorShow } from './devices/depositors/DepositorShow'
import { DepositorEdit } from './devices/depositors/DepositorEdit'
import { DepositorCreate } from './devices/depositors/DepositorCreate'
import { DynamicPropertyDefinitionsList } from './administration/dynamic-property-definitions/DynamicPropertyDefinitionsList'
import { DynamicPropertyDefinitionEdit } from './administration/dynamic-property-definitions/DynamicPropertyDefinitonEdit'
import { DynamicPropertyDefinitionCreate } from './administration/dynamic-property-definitions/DynamicPropertyDefinitionCreate'
import { DynamicCountersList } from './administration/dynamic-counters/DynamicCountersList'
import { DynamicCounterShow } from './administration/dynamic-counters/DynamicCounterShow'
import { DynamicCounterEdit } from './administration/dynamic-counters/DynamicCounterEdit'
import { DynamicCounterCreate } from './administration/dynamic-counters/DynamicCounterCreate'
import { AccessBonusList } from './configuration/access-bonus/AccessBonusList'
import { AccessBonusCreate } from './configuration/access-bonus/AccessBonusCreate'
import { AccessBonusEdit } from './configuration/access-bonus/AccessBonusEdit'
import { AccessBonusShow } from './configuration/access-bonus/AccessBonusShow'
import { VerifierDeviceDisabledList } from './devices/verifier-device-disabled/VerifierDeviceDisabledList'
import { VerifierDeviceDisabledEdit } from './devices/verifier-device-disabled/VerifierDeviceDisabledEdit'
import { VerifierDeviceDisabledCreate } from './devices/verifier-device-disabled/VerifierDeviceDisabledCreate'
import { VerifierDeviceDisabledShow } from './devices/verifier-device-disabled/VerifierDeviceDisabledShow'
import { VerifierSchemeDataList } from './configuration/scheme-data/verifier-scheme-data/VerifierSchemeDataList'
import { VerifierSchemeDataShow } from './configuration/scheme-data/verifier-scheme-data/VerifierSchemeDataShow'
import { VerifierSchemeDataEdit } from './configuration/scheme-data/verifier-scheme-data/VerifierSchemeDataEdit'
import { VerifierSchemeDataCreate } from './configuration/scheme-data/verifier-scheme-data/VerifierSchemeDataCreate'
import { DoorList } from './devices/doors/DoorList'
import { DoorShow } from './devices/doors/DoorShow'
import { DoorEdit } from './devices/doors/DoorEdit'
import { DoorCreate } from './devices/doors/DoorCreate'
import { TerminalList } from './devices/terminals/TerminalList'
import { TerminalShow } from './devices/terminals/TerminalShow'
import { TerminalEdit } from './devices/terminals/TerminalEdit'
import { TerminalCreate } from './devices/terminals/TerminalCreate'
import { TSCanList } from './devices/ts-cans/TSCanList'
import { TSCanShow } from './devices/ts-cans/TSCanShow'
import { TSCanEdit } from './devices/ts-cans/TSCanEdit'
import { TSCanCreate } from './devices/ts-cans/TSCanCreate'
import { TicketImportsList } from './ticket-imports/TicketImportsList'
import { TicketImportShow } from './ticket-imports/TicketImportShow'
import { TicketImportMappingsList } from './ticket-import-mappings/TicketImportMappingsList'
import { TicketImportMappingEdit } from './ticket-import-mappings/TicketImportMappingEdit'
import { TicketImportMappingCreate } from './ticket-import-mappings/TicketImportMappingCreate'
import { TicketImportTemplateCreate } from './ticket-import-templates/TicketImportTemplateCreate'
import { TicketImportTemplateEdit } from './ticket-import-templates/TicketImportTemplateEdit'
import { TicketImportTemplatesList } from './ticket-import-templates/TicketImportTemplatesList'
import { TicketImportTemplateShow } from './ticket-import-templates/TicketImportTemplateShow'

export const generateResourcesBasedOnPermissions = (
  permissions: Set<string>,
) => [
  <Resource name="Dummy" />,
  hasAuthority(Authority.VIEW_JOBS, permissions) && (
    <Resource name={ResourceName.JOBS} list={JobList} show={JobShow} />
  ),
  hasAuthority(Authority.VIEW_TICKETS, permissions) && (
    <Resource
      icon={ReceiptIcon}
      name={ResourceName.TICKETS}
      create={TicketImport}
      list={TicketList}
    />
  ),
  hasAuthority(Authority.VIEW_ACCESS_USINGS, permissions) && (
    <Resource name={ResourceName.ACCESS_USINGS} />
  ),
  hasAuthority(Authority.VIEW_CUSTOMERS, permissions) && (
    <Resource
      icon={PersonIcon}
      name={ResourceName.CUSTOMERS}
      show={CustomerDetailsView}
      list={CustomerList}
      edit={
        hasAuthority(Authority.EDIT_CUSTOMERS, permissions)
          ? CustomerEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_CUSTOMERS, permissions)
          ? CustomersCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_LOGS, permissions) && (
    <Resource name={ResourceName.LOGS} list={LogList} />
  ),
  hasAuthority(Authority.VIEW_EVENTS, permissions) && (
    <Resource
      name={ResourceName.EVENTS}
      list={EventList}
      show={EventShow}
      edit={
        hasAuthority(Authority.EDIT_EVENTS, permissions) ? EventEdit : undefined
      }
      create={
        hasAuthority(Authority.CREATE_EVENTS, permissions)
          ? EventCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_STATS_OVERALL_VIEW, permissions) && (
    <Resource
      icon={ViewComfyIcon}
      name={ResourceName.STATS_OVERALL_VIEW_LIST}
      list={OverallViewList}
    />
  ),
  hasAuthority(Authority.VIEW_STATS_OVERALL_VIEW, permissions) && (
    <Resource
      icon={ViewComfyIcon}
      name={ResourceName.STATS_OVERALL_VIEW}
      list={OverallView}
    />
  ),
  hasAuthority(Authority.VIEW_STATS_EVENT_STANDS_PERIOD, permissions) && (
    <Resource
      icon={ViewComfyIcon}
      name={ResourceName.STATS_PERIOD_VIEW_LIST}
      list={PeriodViewList}
    />
  ),
  hasAuthority(Authority.VIEW_STATS_EVENT_STANDS_PERIOD, permissions) && (
    <Resource
      icon={ViewComfyIcon}
      name={ResourceName.STATS_PERIOD_VIEW}
      show={PeriodView}
    />
  ),
  hasAuthority(Authority.VIEW_STATS_EVENT_STANDS_PERIOD, permissions) && (
    <Resource icon={ViewComfyIcon} name={ResourceName.STATS_PERIOD_LIST} />
  ),
  hasAuthority(Authority.VIEW_STATS_PASSAGE_COUNTERS, permissions) && (
    <Resource name={ResourceName.STATS_PASSAGE_COUNTERS_VIEW} />
  ),
  hasAuthority(Authority.VIEW_STATS_EVENT_ENTRANCES, permissions) && (
    <Resource
      name={ResourceName.STATS_EVENT_ENTRANCES_VIEW_LIST}
      list={EventEntrancesViewList}
    />
  ),
  hasAuthority(Authority.VIEW_STATS_EVENT_ENTRANCES, permissions) && (
    <Resource
      name={ResourceName.STATS_EVENT_ENTRANCES_VIEW}
      list={EventEntrancesView}
    />
  ),
  hasAuthority(Authority.VIEW_DYNAMIC_STATISTICS, permissions) && (
    <Resource
      name={ResourceName.STATS_DYNAMIC_STATISTICS}
      list={DynamicStatisticsList}
      show={DynamicStatisticsShow}
      edit={
        hasAuthority(Authority.EDIT_DYNAMIC_STATISTICS, permissions)
          ? DynamicStatisticEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_DYNAMIC_STATISTICS, permissions)
          ? DynamicStatisticCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.EXECUTE_DYNAMIC_STATISTICS, permissions) && (
    <Resource name={ResourceName.STATS_DYNAMIC_STATISTICS_EXECUTION} />
  ),
  hasAuthority(Authority.VIEW_DYNAMIC_STATISTIC_PARAMETERS, permissions) && (
    <Resource name={ResourceName.STATS_DYNAMIC_STATISTIC_PARAMETERS} />
  ),
  hasAuthority(Authority.VIEW_PALMS, permissions) && (
    <Resource
      name={ResourceName.PALMS}
      list={PalmList}
      show={PalmShow}
      edit={
        hasAuthority(Authority.EDIT_PALMS, permissions) ? PalmEdit : undefined
      }
      create={
        hasAuthority(Authority.CREATE_PALMS, permissions)
          ? PalmCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ACCESS_DATES, permissions) && (
    <Resource
      name={ResourceName.ACCESS_DATES}
      create={
        hasAuthority(Authority.CREATE_ACCESS_DATES, permissions)
          ? AccessDateCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ACCESSES, permissions) && (
    <Resource
      name={ResourceName.ACCESSES}
      list={AccessList}
      show={AccessShow}
      edit={
        hasAuthority(Authority.EDIT_ACCESSES, permissions)
          ? AccessEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_ACCESSES, permissions)
          ? AccessCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_BOOKINGS, permissions) && (
    <Resource
      name={ResourceName.BOOKINGS}
      show={BookingShow}
      edit={
        hasAuthority(Authority.EDIT_BOOKINGS, permissions)
          ? BookingEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_BOOKINGS, permissions)
          ? BookingCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_COMPANIES, permissions) && (
    <Resource
      name={ResourceName.COMPANIES}
      list={CompanyList}
      show={CompanyShow}
      edit={
        hasAuthority(Authority.EDIT_COMPANIES, permissions)
          ? CompanyEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_COMPANIES, permissions)
          ? CompanyCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ENGINES, permissions) && (
    <Resource
      name={ResourceName.ENGINES}
      list={EngineList}
      show={EngineShow}
      edit={
        hasAuthority(Authority.EDIT_ENGINES, permissions)
          ? EngineEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_ENGINES, permissions)
          ? EngineCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ENTRANCES, permissions) && (
    <Resource
      name={ResourceName.ENTRANCES}
      list={EntranceList}
      show={EntranceShow}
      edit={
        hasAuthority(Authority.EDIT_ENTRANCES, permissions)
          ? EntranceEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_ENTRANCES, permissions)
          ? EntranceCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_VARIANTS, permissions) && (
    <Resource
      name={ResourceName.VARIANTS}
      list={VariantList}
      show={VariantShow}
      edit={
        hasAuthority(Authority.EDIT_VARIANTS, permissions)
          ? VariantEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_VARIANTS, permissions)
          ? VariantCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_USERS, permissions) && (
    <Resource
      name={ResourceName.USERS}
      list={UserList}
      show={UserShow}
      edit={
        hasAuthority(Authority.EDIT_USERS, permissions) ? UserEdit : undefined
      }
      create={
        hasAuthority(Authority.CREATE_USERS, permissions)
          ? UserCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_OBJECTS, permissions) && (
    <Resource
      name={ResourceName.OBJECTS}
      list={ObjectList}
      show={ObjectShow}
      edit={
        hasAuthority(Authority.EDIT_OBJECTS, permissions)
          ? ObjectEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_OBJECTS, permissions)
          ? ObjectCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_TRIBUNES, permissions) && (
    <Resource
      name={ResourceName.TRIBUNES}
      list={TribunesList}
      create={
        hasAuthority(Authority.CREATE_TRIBUNES, permissions)
          ? TribuneCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_BLOCKS, permissions) && (
    <Resource
      name={ResourceName.BLOCKS}
      list={BlocksList}
      create={
        hasAuthority(Authority.CREATE_BLOCKS, permissions)
          ? BlockCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_SEATS, permissions) && (
    <Resource
      name={ResourceName.SEATS}
      list={SeatsList}
      create={
        hasAuthority(Authority.CREATE_SEATS, permissions)
          ? SeatCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_DEVICE_CATEGORIES, permissions) && (
    <Resource name={ResourceName.DEVICE_CATEGORIES} list={DeviceCategoryList} />
  ),
  hasAuthority(Authority.VIEW_SCHEMES, permissions) && (
    <Resource name={ResourceName.SCHEMES} />
  ),
  hasAuthority(Authority.VIEW_LOG_SCHEME_DATA, permissions) && (
    <Resource
      name={ResourceName.LOG_SCHEME_DATA}
      list={LogSchemeDataList}
      show={LogSchemeDataShow}
      edit={
        hasAuthority(Authority.EDIT_LOG_SCHEME_DATA, permissions)
          ? LogSchemeDataEdit
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_PALM_SCHEME_DATA, permissions) && (
    <Resource
      name={ResourceName.PALM_SCHEME_DATA}
      list={PalmSchemeDataList}
      show={PalmSchemeDataShow}
      edit={
        hasAuthority(Authority.EDIT_PALM_SCHEME_DATA, permissions)
          ? PalmSchemeDataEdit
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_PASSAGES, permissions) && (
    <Resource
      name={ResourceName.PASSAGES}
      list={PassagesList}
      show={PassageShow}
      edit={
        hasAuthority(Authority.EDIT_PASSAGES, permissions)
          ? PassageEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_PASSAGES, permissions)
          ? PassageCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_DETECTORS, permissions) && (
    <Resource
      name={ResourceName.DETECTORS}
      list={DetectorList}
      show={DetectorShow}
      edit={
        hasAuthority(Authority.EDIT_DETECTORS, permissions)
          ? DetectorEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_DETECTORS, permissions)
          ? DetectorCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ROLES, permissions) && (
    <Resource
      name={ResourceName.ROLES}
      list={RoleList}
      show={RoleShow}
      edit={
        hasAuthority(Authority.EDIT_ROLES, permissions) ? RoleEdit : undefined
      }
      create={
        hasAuthority(Authority.CREATE_ROLES, permissions)
          ? RoleCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ROLES, permissions) && (
    <Resource name={ResourceName.PERMISSIONS} />
  ),
  hasAuthority(Authority.VIEW_CAMERAS, permissions) && (
    <Resource
      name={ResourceName.CAMERAS}
      list={CameraList}
      show={CameraShow}
      edit={
        hasAuthority(Authority.EDIT_CAMERAS, permissions)
          ? CameraEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_CAMERAS, permissions)
          ? CameraCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_CAMERA_SERVERS, permissions) && (
    <Resource
      name={ResourceName.CAMERA_SERVERS}
      list={CameraServerList}
      show={CameraServerShow}
      edit={
        hasAuthority(Authority.EDIT_CAMERA_SERVERS, permissions)
          ? CameraServerEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_CAMERA_SERVERS, permissions)
          ? CameraServerCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_OFFLINE_SERVERS, permissions) && (
    <Resource
      name={ResourceName.OFFLINE_SERVERS}
      list={OfflineServerList}
      show={OfflineServerShow}
      edit={
        hasAuthority(Authority.EDIT_OFFLINE_SERVERS, permissions)
          ? OfflineServerEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_OFFLINE_SERVERS, permissions)
          ? OfflineServerCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_DEVICES_BASE, permissions) && (
    <Resource name={ResourceName.DEVICES_BASE} />
  ),
  hasAuthority(Authority.VIEW_VERIFIER_DEVICES, permissions) && (
    <Resource name={ResourceName.VERIFIER_DEVICES} />
  ),
  hasAuthority(Authority.VIEW_USER_MANAGEMENT, permissions) && (
    <Resource name={ResourceName.CURRENT_USER} edit={CurrentUserEdit} />
  ),
  hasAuthority(Authority.VIEW_SERVICE_BOOKINGS, permissions) && (
    <Resource
      name={ResourceName.SERVICE_BOOKINGS}
      list={ServiceBookingList}
      edit={
        hasAuthority(Authority.EDIT_SERVICE_BOOKINGS, permissions)
          ? ServiceBookingEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_SERVICE_BOOKINGS, permissions)
          ? ServiceBookingCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_ENTRANCES_SET, permissions) && (
    <Resource name={ResourceName.ENTRANCES_MATRIX} list={EntrancesMatrixList} />
  ),
  hasAuthority(Authority.VIEW_ENTRANCES_SET, permissions) && (
    <Resource name={ResourceName.ENTRANCES_SET} />
  ),
  hasAuthority(Authority.VIEW_RECURRING_JOBS, permissions) && (
    <Resource
      name={ResourceName.RECURRING_JOBS}
      list={RecurringJobList}
      show={RecurringJobShow}
    />
  ),
  hasAuthority(Authority.VIEW_TECHNICAL_ASSISTANCE_PROTOCOL, permissions) && (
    <Resource name={ResourceName.TECHNICAL_ASSISTANCE_PROTOCOL} />
  ),
  hasAuthority(Authority.VIEW_CSI, permissions) && (
    <Resource name={ResourceName.SYNC_LOGS} list={SyncLogsList} />
  ),
  hasAuthority(Authority.VIEW_SERVICE_LINK, permissions) && (
    <Resource
      name={ResourceName.SERVICE_LINK}
      list={ServiceLinkList}
      show={ServiceLinkShow}
      edit={
        hasAuthority(Authority.EDIT_SERVICE_LINK, permissions)
          ? ServiceLinkEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_SERVICE_LINK, permissions)
          ? ServiceLinkCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_DEPOSITORS, permissions) && (
    <Resource
      name={ResourceName.DEPOSITORS}
      list={DepositorsList}
      show={DepositorShow}
      edit={
        hasAuthority(Authority.EDIT_DEPOSITORS, permissions)
          ? DepositorEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_DEPOSITORS, permissions)
          ? DepositorCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_DYNAMIC_PROPERTY_DEFINITIONS, permissions) && (
    <Resource
      name={ResourceName.DYNAMIC_PROPERTY_DEFINITIONS}
      list={DynamicPropertyDefinitionsList}
      edit={
        hasAuthority(Authority.EDIT_DYNAMIC_PROPERTY_DEFINITIONS, permissions)
          ? DynamicPropertyDefinitionEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_DYNAMIC_PROPERTY_DEFINITIONS, permissions)
          ? DynamicPropertyDefinitionCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_DYNAMIC_COUNTERS, permissions) && (
    <Resource
      name={ResourceName.DYNAMIC_COUNTERS}
      list={DynamicCountersList}
      show={DynamicCounterShow}
      edit={
        hasAuthority(Authority.EDIT_DYNAMIC_COUNTERS, permissions)
          ? DynamicCounterEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_DYNAMIC_COUNTERS, permissions)
          ? DynamicCounterCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_DYNAMIC_COUNTER_PARAMETERS, permissions) && (
    <Resource name={ResourceName.DYNAMIC_COUNTER_PARAMETERS} />
  ),
  hasAuthority(Authority.VIEW_ACCESS_BONUSES, permissions) && (
    <Resource
      name={ResourceName.ACCESS_BONUS}
      list={AccessBonusList}
      show={AccessBonusShow}
      edit={
        hasAuthority(Authority.EDIT_ACCESS_BONUSES, permissions)
          ? AccessBonusEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_ACCESS_BONUSES, permissions)
          ? AccessBonusCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_VERIFIER_DEVICE_DISABLED, permissions) && (
    <Resource
      name={ResourceName.VERIFIER_DEVICE_DISABLED}
      list={VerifierDeviceDisabledList}
      show={VerifierDeviceDisabledShow}
      edit={
        hasAuthority(Authority.EDIT_VERIFIER_DEVICE_DISABLED, permissions)
          ? VerifierDeviceDisabledEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_VERIFIER_DEVICE_DISABLED, permissions)
          ? VerifierDeviceDisabledCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_VERIFIER_SCHEME_DATA, permissions) && (
    <Resource
      name={ResourceName.VERIFIER_SCHEME_DATA}
      list={VerifierSchemeDataList}
      show={VerifierSchemeDataShow}
      edit={
        hasAuthority(Authority.EDIT_VERIFIER_SCHEME_DATA, permissions)
          ? VerifierSchemeDataEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_VERIFIER_SCHEME_DATA, permissions)
          ? VerifierSchemeDataCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_DOORS, permissions) && (
    <Resource
      name={ResourceName.DOORS}
      list={DoorList}
      show={DoorShow}
      edit={
        hasAuthority(Authority.EDIT_DOORS, permissions) ? DoorEdit : undefined
      }
      create={
        hasAuthority(Authority.CREATE_DOORS, permissions)
          ? DoorCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_TERMINALS, permissions) && (
    <Resource
      name={ResourceName.TERMINALS}
      list={TerminalList}
      show={TerminalShow}
      edit={
        hasAuthority(Authority.EDIT_TERMINALS, permissions)
          ? TerminalEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_TERMINALS, permissions)
          ? TerminalCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_TICKET_IMPORTS, permissions) && (
    <Resource
      name={ResourceName.TICKET_IMPORTS}
      list={TicketImportsList}
      show={TicketImportShow}
    />
  ),
  hasAuthority(Authority.VIEW_TICKET_IMPORT_MAPPINGS, permissions) && (
    <Resource
      name={ResourceName.TICKET_IMPORT_MAPPINGS}
      list={TicketImportMappingsList}
      edit={
        hasAuthority(Authority.EDIT_TICKET_IMPORT_MAPPINGS, permissions)
          ? TicketImportMappingEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_TICKET_IMPORT_MAPPINGS, permissions)
          ? TicketImportMappingCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_TS_CANS, permissions) && (
    <Resource
      name={ResourceName.TS_CANS}
      list={TSCanList}
      show={TSCanShow}
      edit={
        hasAuthority(Authority.EDIT_TS_CANS, permissions)
          ? TSCanEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_TS_CANS, permissions)
          ? TSCanCreate
          : undefined
      }
    />
  ),
  hasAuthority(Authority.VIEW_TICKET_IMPORT_TEMPLATES, permissions) && (
    <Resource
      name={ResourceName.TICKET_IMPORT_TEMPLATES}
      list={TicketImportTemplatesList}
      show={TicketImportTemplateShow}
      edit={
        hasAuthority(Authority.EDIT_TICKET_IMPORT_TEMPLATES, permissions)
          ? TicketImportTemplateEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_TICKET_IMPORT_TEMPLATES, permissions)
          ? TicketImportTemplateCreate
          : undefined
      }
    />
  ),
]
