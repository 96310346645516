import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import { STATISTICS_URL } from '../../api-urls'
import { get } from '../../common/fetch.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { EventStandsResponse, ObjectEventStands } from './overall-view'

const provider = {
  getList: async (
    resource: string,
    { filter }: TicketAPIGetListParams,
  ): Promise<GetListResult<ObjectEventStands>> => {
    const { id } = filter
    const path = `/${id}`

    const { objects } = await get<EventStandsResponse>(
      `${STATISTICS_URL}/GetEventStands`,
      path,
    )

    return Promise.resolve({
      data: Object.keys(objects).map((k) => objects[k]),
      total: Object.entries(objects).length,
    })
  },
  getOne: async (
    resource: string,
    { id: eventId }: GetOneParams,
  ): Promise<GetOneResult<EventStandsResponse>> => {
    const overallViewData = await get<EventStandsResponse>(
      STATISTICS_URL,
      `/GetEventStands/${eventId}`,
    )

    return {
      data: overallViewData,
    }
  },
} as DataProvider

export default provider
