import { ButtonGroup } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Create,
  CreateProps,
  Datagrid,
  DateTimeInput,
  FormTab,
  GET_LIST,
  List,
  ListButton,
  Pagination,
  ResourceContextProvider,
  sanitizeFetchType,
  SaveButton,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  TopToolbar,
  useEditContext,
  useListContext,
  useMutation,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useField } from 'react-final-form'
import { EventDto } from '../../core/dto/event/event.dto'
import { ObjectDto } from '../../core/dto/object.dto'
import { ResourceName } from '../../core/ResourceName'
import { CheckboxField } from '../common/CheckboxField'
import Button from '../common/customized-mui-components/Button'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'

const validateEventCreate = (values: EventDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!Date.parse(values.dateStart))
    errors.dateStart = 'ra.validation.incorrect_date'
  if (!Date.parse(values.dateEnd))
    errors.dateEnd = 'ra.validation.incorrect_date'
  if (!values.dateStart) {
    errors.dateStart = 'ra.validation.required'
  }
  if (!values.dateEnd) {
    errors.dateEnd = 'ra.validation.required'
  }
  if (new Date(values.dateStart) > new Date(values.dateEnd)) {
    errors.dateStart = 'ra.validation.incorrect_date'
    errors.dateEnd = 'ra.validation.incorrect_date'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.events.titles.create')}</span>
}

const RelationInputTab = ({
  sourceProperty,
  source,
}: {
  sourceProperty: keyof EventDto
  source: ResourceName.OBJECTS
}) => {
  const [mutate] = useMutation()
  const notify = useNotify()
  const { basePath } = useEditContext<EventDto>()
  const {
    input: { onChange, value: relationsIds },
  } = useField<number[]>(sourceProperty)

  const handleAttach = (idsToAttach: number[]) => {
    switch (source) {
      case ResourceName.OBJECTS:
        onChange([...relationsIds, ...idsToAttach])
        break
      default:
        notify(`Source '${source}' is not implemented yet.`)
    }
  }

  const handleDetach = (idsToDetach: number[]) => {
    switch (source) {
      case ResourceName.OBJECTS:
        onChange(relationsIds.filter((id: number) => !idsToDetach.includes(id)))
        break
      default:
        notify(`Source '${source}' is not implemented yet.`)
    }
  }

  const isAttached = (relatedRecordId: number) => {
    switch (source) {
      case ResourceName.OBJECTS:
        return relationsIds?.includes(relatedRecordId)
      default:
        notify(`Source '${source}' is not implemented yet.`)
        return false
    }
  }

  // const listFilters = () => {
  //   switch (source) {
  //     case ResourceName.OBJECTS:
  //       return ([<TextInput source="name" alwaysOn />])
  //     default: return ([])
  //   }
  // }

  const listSort = () => {
    switch (source) {
      case ResourceName.OBJECTS:
        return { field: 'name', order: 'ASC' }
      default:
        return undefined
    }
  }

  const listColumns = () => {
    switch (source) {
      case ResourceName.OBJECTS:
        return [
          <TextField source="id" label="ID" key="id" />,
          <TextField source="name" key="name" />,
        ]
      default:
        return []
    }
  }

  const Actions = () => {
    const { filterValues } = useListContext<ObjectDto>()
    const getFilteredRelatedRecordsIds = async () =>
      (
        await mutate(
          {
            type: sanitizeFetchType(GET_LIST),
            resource: source,
            payload: {
              filter: {
                ...filterValues,
              },
            },
          },
          { returnPromise: true, onFailure: (msg) => notify(msg, 'error') },
        )
      )?.data?.map((record: ObjectDto) => record?.id)

    const handleAttachAll = async () =>
      handleAttach(await getFilteredRelatedRecordsIds())

    const handleDetachAll = async () =>
      handleDetach(await getFilteredRelatedRecordsIds())

    return (
      <TopToolbar>
        {/* <FilterButton/> */}
        <ButtonGroup>
          <Button
            variant="contained"
            onClick={() => handleAttachAll()}
            label="common.related-records.actions.attach-all"
            useSmallVersionBreakpoint={false}
          />
          <Button
            variant="outlined"
            onClick={() => handleDetachAll()}
            label="common.related-records.actions.detach-all"
            useSmallVersionBreakpoint={false}
          />
        </ButtonGroup>
      </TopToolbar>
    )
  }

  const AttachedCheckbox = ({ ...props }) => (
    <CheckboxField
      {...props}
      sortable={false}
      checkBySource={(id: number) => (id ? isAttached(id) : false)}
      source="id"
      onClick={(id: number) =>
        isAttached(id as number)
          ? () => handleDetach([id as number])
          : () => handleAttach([id as number])
      }
    />
  )

  return (
    <ResourceContextProvider value={source}>
      <List
        basePath={basePath}
        exporter={false}
        title=" "
        // filters={listFilters()} // FilterForm cannot be descendant of another Form component
        sort={listSort()}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
        perPage={20}
        actions={<Actions />}
      >
        <>
          <Datagrid size="small" style={{ tableLayout: 'fixed' }}>
            {listColumns()}
            <AttachedCheckbox label="common.related-records.fields.is-attached" />
          </Datagrid>
        </>
      </List>
    </ResourceContextProvider>
  )
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const EventCreate = (props: CreateProps) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  return (
    <Create {...props} title={<CreateTitle />}>
      <TabbedForm
        redirect="show"
        toolbar={<CreateToolbar />}
        validate={validateEventCreate}
      >
        <FormTab label={translate('resources.events.tabs.general')}>
          <TextInput source="name" isRequired />
          <DateTimeInput source="dateStart" isRequired />
          <DateTimeInput source="dateEnd" isRequired />
          <TextInput source="externalId" />
          <TextInput source="infix" />
          <BooleanInput source="active" />
        </FormTab>
        {hasAuthority(Authority.VIEW_OBJECTS) && (
          <FormTab label={translate('resources.events.tabs.objects')}>
            <RelationInputTab
              sourceProperty="objectsIds"
              source={ResourceName.OBJECTS}
            />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  )
}
