import { DataProvider } from 'react-admin'
import { EventStandsPeriodRAListResponse } from '../../stats/period-view/period-list.provider'

const fakeStatsPeriodsList: EventStandsPeriodRAListResponse[] = []

const provider = {
  getOne: (resource, { id }: { id: string }) => {
    const found = fakeStatsPeriodsList.find((v) => `${v.id}` === id)
    if (found) {
      return Promise.resolve({
        data: found,
      })
    }
    return Promise.reject(new Error())
  },
} as DataProvider

export default provider
