import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close, FilterTiltShift, TrackChanges } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { StanbyMode } from '../../../core/enum/StanbyMode'
import { ResourceName } from '../../../core/ResourceName'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import Button from '../../common/customized-mui-components/Button'
import DraggableComponent from '../../common/DraggableComponent'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { TerminalSetWorkingModeRequest } from '../../../core/enum/TerminalSetWorkingModeRequest'

const useStyles = makeStyles({
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  actionsButton: {
    margin: '2%',
    width: '96%',
  },
  iconContainer: {
    width: 34,
    height: 34,
  },
  icon: {
    position: 'relative',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'relative',
    left: -24,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
})

export const SetStandbyModeDialog = ({
  open,
  close,
  standbyMode,
  setStandbyMode,
  handleStandbyMode,
  asRecurringJob,
}: {
  open: boolean
  close: () => void
  standbyMode?: StanbyMode
  setStandbyMode: (value: StanbyMode) => void
  handleStandbyMode: (
    recurringJobName?: string,
    cronExpression?: string,
  ) => void
  asRecurringJob?: boolean
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const handleSetStandbyMode = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (asRecurringJob) handleStandbyMode(recurringJobName, cronExpression)
    else handleStandbyMode()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleSetStandbyMode}
        source="setStandbyMode"
        action="set"
        resource={ResourceName.TERMINALS}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.terminals.dialogs.setStandbyMode.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Select
          variant="outlined"
          value={standbyMode}
          onChange={(e) => setStandbyMode(e.target.value as StanbyMode)}
          fullWidth
          displayEmpty
        >
          <MenuItem value={StanbyMode.ALWAYS_ON}>
            <ListItemIcon className={classes.iconContainer}>
              <TrackChanges className={classes.icon} />
            </ListItemIcon>
            {translate(`resources.enums.standbyMode.${StanbyMode.ALWAYS_ON}`)}
          </MenuItem>
          <MenuItem value={StanbyMode.ALWAYS_OFF}>
            <ListItemIcon className={classes.iconContainer}>
              <TrackChanges className={classes.icon} />
              <Close className={classes.crossIcon} />
            </ListItemIcon>
            {translate(`resources.enums.standbyMode.${StanbyMode.ALWAYS_OFF}`)}
          </MenuItem>
          <MenuItem value={StanbyMode.SENSOR}>
            <ListItemIcon className={classes.iconContainer}>
              <FilterTiltShift className={classes.icon} />
            </ListItemIcon>
            {translate(`resources.enums.standbyMode.${StanbyMode.SENSOR}`)}
          </MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
          disabled={
            !Object.values(StanbyMode).includes(standbyMode as StanbyMode)
          }
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export const SetWorkingModeDialog = ({
  open,
  close,
  workingMode,
  setWorkingMode,
  handleWorkingMode,
  asRecurringJob,
}: {
  open: boolean
  close: () => void
  workingMode?: TerminalSetWorkingModeRequest
  setWorkingMode: (value: TerminalSetWorkingModeRequest) => void
  handleWorkingMode: (
    recurringJobName?: string,
    cronExpression?: string,
  ) => void
  asRecurringJob?: boolean
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const handleSetWorkingMode = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (asRecurringJob) handleWorkingMode(recurringJobName, cronExpression)
    else handleWorkingMode()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleSetWorkingMode}
        source="setWorkingMode"
        action="set"
        resource={ResourceName.TERMINALS}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.terminals.dialogs.setWorkingMode.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Select
          variant="outlined"
          value={workingMode}
          onChange={(e) =>
            setWorkingMode(e.target.value as TerminalSetWorkingModeRequest)
          }
          fullWidth
          displayEmpty
        >
          <MenuItem value={TerminalSetWorkingModeRequest.Standard}>
            {translate(
              `resources.enums.WorkingMode.${TerminalSetWorkingModeRequest.Standard}`,
            )}
          </MenuItem>
          <MenuItem value={TerminalSetWorkingModeRequest.LoopEnteringsOpens}>
            {translate(
              `resources.enums.WorkingMode.${TerminalSetWorkingModeRequest.LoopEnteringsOpens}`,
            )}
          </MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
          disabled={
            !Object.values(TerminalSetWorkingModeRequest).includes(
              workingMode as TerminalSetWorkingModeRequest,
            )
          }
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export const OpenForOneVehicleDialog = ({
  open,
  close,
  note,
  setNote,
  handleOpen,
}: {
  open: boolean
  close: () => void
  note?: string
  setNote: (value: string) => void
  handleOpen: () => void
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const handleOpenForVehicle = () => {
    handleOpen()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleOpenForVehicle}
        source="openForOneVehicle"
        action="set"
        resource={ResourceName.TERMINALS}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.terminals.dialogs.openForOneVehicle.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <TextField
          variant="outlined"
          value={note}
          onChange={(e) => setNote(e?.target?.value)}
          fullWidth
          label={translate('resources.terminals.fields.opening-note')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
          disabled={!note}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export const TerminalLockDialog = ({
  open,
  close,
  disabledId,
  setDisabledId,
  handleLock,
  asRecurringJob,
}: {
  open: boolean
  close: () => void
  disabledId: number
  setDisabledId: (value: number) => void
  handleLock: (recurringJobName?: string, cronExpression?: string) => void
  asRecurringJob?: boolean
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    setDisabledId(0)
    setError(false)
    setErrorText('')
    setConfirmOpen(false)
    close()
  }

  const handleConfirm = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (asRecurringJob) handleLock(recurringJobName, cronExpression)
    else handleLock()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleConfirm}
        source="lock"
        action="set"
        resource={ResourceName.TERMINALS}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.terminals.dialogs.lock.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={close}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Form onSubmit={() => undefined}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <FilteredReferenceInput
                source="disabled"
                defaultValue={null}
                reference={ResourceName.VERIFIER_DEVICE_DISABLED}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={smallScreen ? 5 : 15}
                allowEmpty
                filterSource="name"
                selectWithPaginationInputProps={{
                  optionText: 'name',
                  showFilter: true,
                  error,
                  helperText: errorText,
                }}
                fullWidth
                onChange={(e) => setDisabledId(e.target.value)}
                value={disabledId}
                resource={ResourceName.TERMINALS}
              />
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}
