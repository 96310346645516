const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  locale: () => null,
  translatedLogSchemeDataText: (lambdaParameter, value, otherValues) =>
    otherValues?.locale != null
      ? `${lambdaParameter}.Name.Contains("${value}") || ${lambdaParameter}.LogSchemeData.Any(lsd => lsd.Language.Equals("${otherValues.locale}") && lsd.Text.Contains("${value}"))`
      : null,
  translatedPalmSchemeDataText: (lambdaParameter, value, otherValues) =>
    otherValues?.locale != null
      ? `${lambdaParameter}.Name.Contains("${value}") || ${lambdaParameter}.PalmSchemeData.Any(psd => psd.Language.Equals("${otherValues.locale}") && psd.Text.Contains("${value}"))`
      : null,
  ids: (lambdaParameter, value) =>
    `new int%3F[] {${(
      value as number[]
    )?.toString()}}.Contains(${lambdaParameter}.Id)`,
  parentIds: (lambdaParameter, value) =>
    `new int%3F[] {${(
      value as number[]
    )?.toString()}}.Contains(${lambdaParameter}.ParentId)`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
  translatedLogSchemeDataTextSearch: (lambdaParameter, value, otherValues) =>
    otherValues?.locale != null
      ? `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}") || ${lambdaParameter}.LogSchemeData.Any(lsd => lsd.Language.Equals("${otherValues.locale}") && lsd.Text.Contains("${value}"))`
      : null,
  translatedPalmSchemeDataTextSearch: (lambdaParameter, value, otherValues) =>
    otherValues?.locale != null
      ? `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}") || ${lambdaParameter}.PalmSchemeData.Any(psd => psd.Language.Equals("${otherValues.locale}") && psd.Text.Contains("${value}"))`
      : null,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
