import {
  makeStyles,
  Slider,
  TextField,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FormDataConsumer, Labeled } from 'react-admin'
import { useField } from 'react-final-form'

const useStyles = makeStyles((theme: Theme) => ({
  slider: {
    width: '100%',
  },
  standardWidth: {
    width: 255,
  },
  numberInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    marginLeft: theme.spacing(1),
    width: 70,
  },
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    maxWidth: 315,
  },
}))

const ValueLabel = ({ ...props }) => {
  const { children, value } = props

  return (
    <Tooltip title={value}>
      <div>{children}</div>
    </Tooltip>
  )
}

const SliderInputBase = ({ ...props }) => {
  const { labelFormat, label, max, min, record, source, step } = props

  const classes = useStyles()

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [value, setValue] = useState<number>(record?.[source] || min)

  const {
    input: { onChange },
  } = useField(source)

  const handleChangeSlider = (e: any, v: number | number[]) => {
    onChange(v as number)
  }

  const handleChangeText = (e: any) => {
    onChange(e?.target?.value as number)
  }

  useEffect(() => {
    setValue(record?.[source])
  }, [record, source])

  return (
    <span className={classes.container}>
      <Labeled className={classes.standardWidth} label={label || ''}>
        <Slider
          step={step}
          marks
          min={min}
          max={max}
          value={value}
          onChange={handleChangeSlider}
          color="primary"
          className={classes.slider}
          valueLabelDisplay={!smallScreen ? 'auto' : 'on'}
          valueLabelFormat={labelFormat}
          ValueLabelComponent={ValueLabel}
        />
      </Labeled>
      <TextField
        type="number"
        value={value}
        onChange={handleChangeText}
        inputProps={{
          min,
          max,
        }}
        variant="outlined"
        className={classes.numberInput}
        label={false}
      />
    </span>
  )
}

export const SliderInput = ({ ...props }) => {
  const { record, ...rest } = props
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <SliderInputBase record={formData || record} {...rest} />
      )}
    </FormDataConsumer>
  )
}
