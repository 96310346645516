import React from 'react'
import {
  FunctionField,
  Record,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin'
import { ImportProperties } from '../../../core/enum/ImportProperties'
import { ResourceName } from '../../../core/ResourceName'
import { TicketImportMappingDto } from '../../../core/dto/ticket-import-mapping.dto'
import { BlockDto } from '../../../core/dto/block.dto'
import { VariantDto } from '../../../core/dto/variant.dto'
import { EventDto } from '../../../core/dto/event/event.dto'

// fix for ReferenceFields linking to ticket-import-template
const linkToType = (record: Record, reference: string) => {
  const { staticValue } = record

  return `/${reference}/${staticValue}/show`
}

export const MappingStaticValueField = ({ ...props }) => {
  const { record: template, ...rest } = props
  const { property } = template
  const translate = useTranslate()

  switch (property) {
    case ImportProperties.CUSTOMER_FIRSTNAME:
    case ImportProperties.CUSTOMER_LASTNAME:
    case ImportProperties.CUSTOMER_PESEL:
    case ImportProperties.CUSTOMER_DOCUMENT_NUMBER:
    case ImportProperties.CUSTOMER_COMPANY:
    case ImportProperties.TICKET_CODE:
      return <TextField {...rest} source="staticValue" />
    case ImportProperties.TICKET_TYPE:
      return (
        <FunctionField<TicketImportMappingDto>
          {...rest}
          source="staticValue"
          render={(mapping?: TicketImportMappingDto) =>
            translate(`resources.enums.ticketType.${mapping?.staticValue}`)
          }
        />
      )
    case ImportProperties.BOOKING_TYPE:
      return (
        <FunctionField<TicketImportMappingDto>
          {...rest}
          source="staticValue"
          render={(mapping?: TicketImportMappingDto) =>
            translate(`resources.enums.bookingType.${mapping?.staticValue}`)
          }
        />
      )
    case ImportProperties.DISABLED:
      return (
        <FunctionField<TicketImportMappingDto>
          {...rest}
          source="staticValue"
          render={(mapping?: TicketImportMappingDto) =>
            translate(`resources.enums.bookingDisabled.${mapping?.staticValue}`)
          }
        />
      )
    case ImportProperties.SEAT_ID:
      return (
        <ReferenceField
          {...rest}
          source="staticValue"
          reference={ResourceName.SEATS}
          link={linkToType}
        >
          <TextField source="rowAlias" />
        </ReferenceField>
      )
    case ImportProperties.BLOCK_ID:
      return (
        <ReferenceField
          {...rest}
          source="staticValue"
          reference={ResourceName.BLOCKS}
          link={linkToType}
        >
          <FunctionField<BlockDto>
            source="name"
            render={(record?: BlockDto) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    case ImportProperties.VARIANT_ID:
      return (
        <ReferenceField
          {...rest}
          source="staticValue"
          reference={ResourceName.VARIANTS}
          link={linkToType}
        >
          <FunctionField<VariantDto>
            source="name"
            render={(record?: VariantDto) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    case ImportProperties.EVENT_ID:
      return (
        <ReferenceField
          {...rest}
          source="staticValue"
          reference={ResourceName.EVENTS}
          link={linkToType}
        >
          <FunctionField<EventDto>
            source="name"
            render={(record?: EventDto) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    default:
      return <TextField {...rest} source="staticValue" />
  }
}
