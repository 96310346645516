import { Menu, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useMemo } from 'react'
import {
  ListProps,
  TextField,
  Pagination,
  DateField,
  TextInput,
  Filter,
  Datagrid,
  ReferenceField,
  FunctionField,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'
import QuickFilter from '../../common/QuickFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { AccessAttachVariantRecurringBulkButton } from './access-list/AccessAttachVariantRecurringBulkButton'
import { AccessDetachVariantRecurringBulkButton } from './access-list/AccessDetachVariantRecurringBulkButton'
import { AccessBonusDto } from '../../../core/dto/access-bonus.dto'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

const AccessFilters = (props: FilterProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      <TextInput source="entranceId" />
      {hasAuthority(Authority.VIEW_ACCESS_BONUSES) && (
        <FilteredReferenceInput
          source="bonusId"
          reference={ResourceName.ACCESS_BONUS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            showFilter: true,
            optionText: (choice: AccessBonusDto) =>
              `${choice.name} (${choice.description})`,
          }}
        />
      )}
      <QuickFilter source="allowReenter" defaultValue />
      <QuickFilter source="multipleEntry" defaultValue />
      <QuickFilter source="isTicketUnlockedWithNextDay" defaultValue />
    </Filter>
  )
}

const AccessBulkActions = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const AccessAttachVariantRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <AccessAttachVariantRecurringBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const AccessDetachVariantRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <AccessDetachVariantRecurringBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  return (
    <div>
      <Button
        onClick={handleClick}
        label="const.selectAction"
        variant="text"
        color="default"
        useSmallVersionBreakpoint={false}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <AccessAttachVariantRecurringAction />
        <AccessDetachVariantRecurringAction />
      </Menu>
    </div>
  )
}

export const AccessList = (props: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  return (
    <List
      perPage={20}
      exporter={false}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      bulkActionButtons={<AccessBulkActions />}
      filters={<AccessFilters />}
      hasCreate={hasAuthority(Authority.CREATE_ACCESSES)}
    >
      <Datagrid rowClick={(id) => `${ResourceName.ACCESSES}/${id}/show`}>
        <TextField source="id" />
        <TextField source="name" />
        {hasAuthority(Authority.VIEW_ACCESS_BONUSES) && (
          <ReferenceField
            source="bonusId"
            reference={ResourceName.ACCESS_BONUS}
            link="show"
          >
            <FunctionField<AccessBonusDto>
              render={(record?: AccessBonusDto) =>
                `${record?.id}. ${record?.name} (${record?.description})`
              }
            />
          </ReferenceField>
        )}
        <TextField source="entranceId" />
        {!smallScreen && <DateField source="modifiedAt" />}
        {!smallScreen && <DateField source="createdAt" />}
      </Datagrid>
    </List>
  )
}
