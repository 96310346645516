import { DataProvider } from 'react-admin'
import { TicketDto } from '../../dto/ticket.dto'
import { TicketType } from '../../enum/TicketType'

const fakeTickets: TicketDto[] = [
  {
    id: 1,
    ticketCode: 'A',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52815,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 2,
    ticketCode: 'A',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52816,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 3,
    ticketCode: '010000011492',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52817,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 4,
    ticketCode: '411000006572',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52818,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 5,
    ticketCode: '871000006824',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52819,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 6,
    ticketCode: '370000011495',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52820,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 7,
    ticketCode: '450000011501',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52821,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 8,
    ticketCode: '630000011502',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52822,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 9,
    ticketCode: '020000011503',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52823,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 10,
    ticketCode: '310000011504',
    ticketType: TicketType.K,
    active: true,
    bookingId: 52824,
    accessUsingsIds: [],
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeTickets, total: fakeTickets.length }),
} as DataProvider

export default provider
