import {
  Card,
  CardContent,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  SimplePaletteColorOptions,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import jsonexport from 'jsonexport'
import React, { cloneElement, useContext, useState } from 'react'
import {
  ShowProps,
  useTranslate,
  List,
  useListContext,
  Filter,
  ResourceContextProvider,
  Show,
  SimpleShowLayout,
  TopToolbar,
  sanitizeListRestProps,
  FilterContext,
  ListActionsProps,
  ExportButton,
  FilterButton,
  downloadCSV,
  useShowContext,
  Labeled,
} from 'react-admin'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ResourceName } from '../../../core/ResourceName'
import { SliderInput } from '../../common/SliderInput'
import { ExtendedTheme } from '../../layout/app.theme'
import { EventStandsPeriodRAListResponse } from '../../../core/stats/period-view/period-list.provider'
import { EventStandsPeriodRAResponse } from '../../../core/stats/period-view/period-view.provider'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'

const useStyles = makeStyles(() => ({
  container: {
    margin: `0 auto`,
  },
}))

const ViewTitle = ({ ...props }) => {
  const { record } = props
  const t = useTranslate()
  return (
    <span>
      {t(`resources.${ResourceName.STATS_PERIOD_VIEW}.name`)}:{' '}
      {record?.eventName}{' '}
      {record?.eventDateStart &&
        new Date(record?.eventDateStart).toLocaleString()}
    </span>
  )
}

const PeriodViewTooltip = ({ ...props }) => {
  const { payload } = props
  const translate = useTranslate()

  return payload && payload.length > 0 ? (
    <Card>
      <CardContent>
        <Typography>
          {translate('resources.stats-period-view.fields.date')}:{' '}
          {payload[0].payload.name}
        </Typography>
        <Typography>
          {translate('resources.stats-period-view.fields.entered')}:{' '}
          {payload[0].payload.value}
        </Typography>
        <Typography>
          {translate(
            'resources.stats-period-view.fields.enteredTillPreviousPeriod',
          )}
          : {payload[0].payload.valueTillPreviousPeriod}
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <div />
  )
}

const PeriodViewGrid = ({ ...props }) => {
  const { data, filterValues, ids, eventData, mode } = props

  const chartData = ids.map((id: number) => {
    const { enteredTillPreviousPeriod, eventTimeReference, enteredTotal } =
      data[id]
    const eventTimestamp = new Date(eventData.eventDateStart).getTime()
    return {
      name: new Date(
        eventTimestamp + eventTimeReference * 60000,
      ).toLocaleString(),
      value: enteredTotal,
      valueTillPreviousPeriod: enteredTillPreviousPeriod,
    }
  })

  const theme = useTheme<ExtendedTheme>()

  const classes = useStyles()

  const { setFilters } = useListContext(props)

  const handleChartScroll = (e: any) => {
    const {
      nativeEvent: { deltaY },
    } = e
    if (deltaY > 0 && filterValues?.minutesInterval > 2) {
      setFilters(
        {
          ...filterValues,
          minutesInterval: filterValues?.minutesInterval - 2,
        },
        {},
      )
    }
    if (deltaY < 0 && filterValues?.minutesInterval < 20) {
      setFilters(
        {
          ...filterValues,
          minutesInterval: filterValues?.minutesInterval + 2,
        },
        {},
      )
    }
  }

  return (
    <div onWheel={handleChartScroll}>
      <ResponsiveContainer
        className={classes.container}
        width="95%"
        height={400}
      >
        <BarChart data={chartData} barCategoryGap={0}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<PeriodViewTooltip />} />
          <Bar
            dataKey={
              mode === 'incremental' ? 'value' : 'valueTillPreviousPeriod'
            }
            fill={(theme.palette.chart as SimplePaletteColorOptions)?.main}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

const PeriodViewFilters = ({ ...props }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <SliderInput
        source="firstPointReference"
        alwaysOn
        label="resources.stats-period-view.fields.firstPointReference"
        max={120}
        min={-240}
        step={10}
        labelFormat={(value: number) =>
          `${value} ${translate('const.minutes')}`
        }
      />
      <SliderInput
        source="minutesInterval"
        alwaysOn
        label="resources.stats-period-view.fields.minutesInterval"
        max={40}
        min={1}
        step={1}
        labelFormat={(value: number) =>
          `${value} ${translate('const.minutes')}`
        }
      />
      {hasAuthority(Authority.VIEW_PASSAGES) && (
        <FilteredReferenceInput
          source="deviceId"
          label="resources.stats-period-view.fields.deviceId"
          reference={ResourceName.PASSAGES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

const PeriodViewExport = (
  eventEntranceRows: EventStandsPeriodRAListResponse[],
  statistic: EventStandsPeriodRAResponse,
) => {
  jsonexport(eventEntranceRows, (err, csv) => {
    downloadCSV(
      csv,
      `event-periods (${statistic?.eventName} ${
        statistic?.eventDateStart &&
        new Date(statistic.eventDateStart).toLocaleString()
      })`,
    )
  })
}

interface PeriodViewActionsProps extends ListActionsProps {
  readonly mode?: string
  setMode: (value: 'incremental' | 'interval') => void
}

const PeriodViewActions = (props: PeriodViewActionsProps) => {
  const { className, filters: filtersProp, mode, setMode, ...rest } = props
  const resource = ResourceName.STATS_PERIOD_LIST
  const translate = useTranslate()

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const filters = useContext(FilterContext) || filtersProp
  const { currentSort, displayedFilters, filterValues, showFilter, total } =
    useListContext(props)
  const { record: statistic } = useShowContext<EventStandsPeriodRAResponse>()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Labeled label="resources.stats-period-view.fields.mode">
        <RadioGroup
          aria-label="mode"
          name="mode"
          value={mode}
          onChange={(e) =>
            setMode(e.target.value as 'incremental' | 'interval')
          }
          row={!smallScreen}
        >
          <FormControlLabel
            value="incremental"
            control={<Radio />}
            label={translate('resources.stats-period-view.mode.incremental')}
          />
          <FormControlLabel
            value="interval"
            control={<Radio />}
            label={translate('resources.stats-period-view.mode.interval')}
          />
        </RadioGroup>
      </Labeled>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      <ExportButton
        disabled={total === 0 || total >= 1000000}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        exporter={(data) =>
          PeriodViewExport(data, statistic as EventStandsPeriodRAResponse)
        }
        maxResults={1000000}
      />
    </TopToolbar>
  )
}

const PeriodsList = ({ ...props }) => {
  const { record } = props
  const [mode, setMode] = useState<'incremental' | 'interval'>('incremental')

  return (
    <ResourceContextProvider value={ResourceName.STATS_PERIOD_LIST}>
      <List
        actions={<PeriodViewActions mode={mode} setMode={setMode} />}
        pagination={false}
        title=" "
        filters={<PeriodViewFilters />}
        basePath={`/${ResourceName.STATS_PERIOD_VIEW}`}
        filter={{
          id: record?.id,
        }}
        filterDefaultValues={{
          firstPointReference: -60,
          minutesInterval: 5,
        }}
      >
        <PeriodViewGrid eventData={record} mode={mode} />
      </List>
    </ResourceContextProvider>
  )
}

export const PeriodView = (props: ShowProps) => (
  <Show title={<ViewTitle />} {...props}>
    <SimpleShowLayout>
      <PeriodsList />
    </SimpleShowLayout>
  </Show>
)
