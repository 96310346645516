import { AccessUsingDto } from '../dto/access-using.dto'

export enum ToggleType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type ToggleButtonState = {
  type: ToggleType
  text: string
}

export type ToggleButtonAction = {
  type: ToggleType
  data: AccessUsingDto
}
