// Part of this code comes from rehooks useLocalStorage
// (https://github.com/rehooks/local-storage/blob/master/src/use-localstorage.ts)
// We needed to reimplement it, because we don't want the hook to rerender
// the component subscribe to preference "density" when another component
// sets any other preference
import { useEffect, useState, useCallback } from 'react';
import get from 'lodash/get';
import { usePreferencesContext } from './usePreferencesContext';
import { RA_PREFERENCE_WORKSPACE } from './constants';
import { getPreferencesStorage, PreferencesChanged, readStorage, writeStorage, } from './preferencesStorage';
/**
 * useState-like hook with localStorage persistence. Syncs changes between tabs.
 *
 * Preferences are stored in a tree structure, to allow retrievalm of the complete
 * preferencs list and namespacing.
 *
 * @param {string} path Name of the preference. Separate with dots to namespace, e.g. 'posts.list.columns'. Leave empty to retrieve the entire preference tree.
 * @param {any} defaultValue Default value
 *
 * @return {Object} A value and a setter for the value, in an array - just like for useState()
 *
 * Depending on the argument passed to usePreferences(), the return tuple concerns
 * either a single value, or the whole preference tree.
 *
 * @example // Here is how to **read a single value** from the preference store, with a default value
 *
 * import { usePreferences } from '@react-admin/ra-preferences';
 *
 * const PostList = props => {
 *     const [density] = usePreferences(
 *         'posts.list.density',
 *         'small'
 *     );
 *
 *     return (
 *         <List {...props}>
 *             <Datagrid size={density}>
 *                 ...
 *             </Datagrid>
 *         </List>
 *     );
 * }
 *
 * @example // To **write a single value** use the second return value:
 *
 * const ChangeDensity: FC<any> = () => {
 *     const [density, setDensity] = usePreferences(
 *         'posts.list.density',
 *         'small'
 *     );
 *
 *     const changeDensity = (): void => {
 *         setDensity(density === 'small' ? 'medium' : 'small');
 *     };
 *
 *     return (
 *         <Button onClick={changeDensity}>
 *             {`Change density (current ${density})`}
 *         </Button>
 *     );
 * };
 *
 * @example // To **read and write the entire preferences tree**, don't pass any argument to the hook. You will find this option useful when building a preferences Form:
 *
 * import { usePreferences } from '@react-admin/ra-preferences';
 * import { useNotify } from 'react-admin';
 * import { Form, Field } from 'react-final-form'
 *
 * const PreferencesPane = () => {
 *     const [preferences, setPreferences] = usePreferences();
 *     const notify = useNotify();
 *
 *     const handleSave = values => {
 *         setPreferences(values);
 *         notify('preferences saved');
 *     }
 *
 *     return (
 *         <Form
 *             initialValues={preferences}
 *             onSubmit={handleSave}
 *             render={({ handleSubmit }) => (
 *                 <form onSubmit={handleSubmit}>
 *                     <div>
 *                         <label>Post list density</label>
 *                         <Field name="posts.list.density" component="select">
 *                             <option value="small">Small</option>
 *                             <option value="medium">Medium</option>
 *                         </Field>
 *                     </div>
 *                     <button type="submit">Submit</button>
 *                 </form>
 *             )}
 *         />
 *     );
 * }
 *
 * **Tip**: The preferences API is synchronous, because preferences are stored in memory, and replicated in localStorage. So even though localStorage has an async API, the preferences API is synchronous.
 */
export var usePreferences = function (key, defaultValue) {
    if (key === void 0) { key = ''; }
    var _a = useState(function () {
        var storedValue = readStorage(key);
        return typeof storedValue === 'undefined' ? defaultValue : storedValue;
    }), localState = _a[0], updateLocalState = _a[1];
    var preferencesContext = usePreferencesContext();
    var onPreferencesChange = function (event) {
        // preference events are custom events we send when modifying  preferences
        // from the same tabs (because local storage events won't fire in the same tab)
        // custom events payload contain full preferences, so we get the value we're
        // subscribed to from the new preferences
        var newValue = get(event.detail, key /* my own key */);
        // and we update our state no matter what. If same value, it won't redraw anyway
        updateLocalState(newValue);
    };
    var onLocalStorageChange = function (event) {
        if (event.key !== RA_PREFERENCE_WORKSPACE) {
            return;
        }
        // localStorage events send the full localstorage value (ie. full preferences)
        // there's a chance we're concerned or not by the event
        // so we get the value we're subscribed to from the new preferences
        var preferences = JSON.parse(event.newValue);
        var newValue = get(preferences, key /* my own key */);
        // and we update our state no matter what. If same value, it won't redraw anyway
        updateLocalState(newValue);
    };
    // when the key changes, update localState to reflect it.
    useEffect(function () {
        var storedValue = readStorage(key);
        updateLocalState(typeof storedValue === 'undefined' ? defaultValue : storedValue);
    }, [key]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(function () {
        // the custom storage event allows us to update our component
        // when a change occurs in localStorage outside of our component (same browser tab)
        window.addEventListener(PreferencesChanged.eventName, onPreferencesChange);
        // the storage event only works in the context of other documents (eg. other browser tabs)
        window.addEventListener('storage', onLocalStorageChange);
        var canWrite = getPreferencesStorage().getItem(RA_PREFERENCE_WORKSPACE) === null;
        // write initial value to the local storage if it's not present or contains invalid JSON data.
        if (defaultValue !== undefined && canWrite) {
            writeStorage(key, defaultValue);
        }
        return function () {
            window.removeEventListener(PreferencesChanged.eventName, onPreferencesChange);
            window.removeEventListener('storage', onLocalStorageChange);
        };
    }, [key]); // eslint-disable-line react-hooks/exhaustive-deps
    var writeState = useCallback(function (value) {
        var previousData = readStorage();
        var data = writeStorage(key, value);
        // PreferencesContext is optional
        if (preferencesContext) {
            preferencesContext.updatePreferences(data, previousData);
        }
    }, [key, preferencesContext]);
    return [localState === undefined ? defaultValue : localState, writeState];
};
