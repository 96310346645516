import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  useTranslate,
  SimpleForm,
  TextInput,
  SaveButton,
  ListButton,
  Toolbar,
  ToolbarProps,
} from 'react-admin'
import { VerifierDeviceDisabledDto } from '../../../core/dto/device/verifier-device-disabled/verifier-device-disabled.dto'

const validateVerifierDeviceDisabledEdit = (
  values: VerifierDeviceDisabledDto,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return (
    <span>{translate('resources.verifier-device-disabled.titles.create')}</span>
  )
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const VerifierDeviceDisabledCreate = (props: CreateProps) => (
  <Create {...props} hasShow={false} title={<CreateTitle />}>
    <SimpleForm
      redirect="show"
      toolbar={<CreateToolbar />}
      validate={validateVerifierDeviceDisabledEdit}
    >
      <TextInput source="name" isRequired />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
)
