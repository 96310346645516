import { Menu, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useMemo } from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import List from '../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import FilterProps from '../../common/FilterProps'
import QuickFilter from '../../common/QuickFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import {
  CheckIfAnyModuleHasError,
  CheckLastRefreshError,
} from '../common/modules/ModuleStatus'
import { PalmAttachEventRecurringBulkButton } from './palm-list/PalmAttachEventRecurringBulkButton'
import { PalmDetachEventRecurringBulkButton } from './palm-list/PalmDetachEventRecurringBulkButton'
import { PalmLockBulkButton } from './palm-list/PalmLockBulkButton'
import { PalmUnlockBulkButton } from './palm-list/PalmUnlockBulkButton'
import { PalmStatus } from './palm-show/PalmStatus'
import { DeviceCategoryExtendedDto } from '../../../core/dto/device-category/device-category-extended.dto'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'

const PalmFilters = (props: FilterProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          label="resources.palms.fields.categoryId"
          source="categoryIdWithDescendants"
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      <QuickFilter source="eventActive" defaultValue />
    </Filter>
  )
}

const PalmGridExpand = ({ ...props }) => <PalmStatus {...props} hideDevices />

const PalmsBulkActions = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const PalmLockAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PalmLockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PalmUnlockAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PalmUnlockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PalmLockRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PalmLockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const PalmUnlockRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PalmUnlockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const PalmAttachEventRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PalmAttachEventRecurringBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const PalmDetachEventRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <PalmDetachEventRecurringBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  return (
    <div>
      <Button
        onClick={handleClick}
        label="const.selectAction"
        variant="text"
        color="default"
        useSmallVersionBreakpoint={false}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <PalmLockAction />
        <PalmLockRecurringAction />
        <PalmUnlockAction />
        <PalmUnlockRecurringAction />
        <PalmAttachEventRecurringAction />
        <PalmDetachEventRecurringAction />
      </Menu>
    </div>
  )
}

export const PalmList = (props: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  const postRowStyle = (record: any) => {
    const palmError = CheckLastRefreshError(record.lastRefresh)
    let palmWarning = false
    if (!palmError) {
      // Check for modules error
      palmWarning = CheckIfAnyModuleHasError(
        record.jsonStatus,
        DeviceTypes.PALM,
      )
    }
    if (palmError) {
      return {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      }
    }
    if (record.jsonStatus) {
      const status = JSON.parse(record.jsonStatus)
      const { percentageBatteryLevel } = status
      if (percentageBatteryLevel < 20) {
        return {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        }
      }
    }
    if (palmWarning) {
      return {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
      }
    }
    return {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    }
  }

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<PalmFilters />}
      bulkActionButtons={<PalmsBulkActions />}
    >
      <Datagrid
        rowStyle={postRowStyle}
        size="small"
        rowClick={(id) => `${ResourceName.PALMS}/${id}/show`}
        isRowExpandable={(record) =>
          record?.jsonStatus !== undefined && record?.jsonStatus !== null
        }
        expand={<PalmGridExpand />}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link={false}
            sortBy="name"
          >
            <FunctionField<DeviceCategoryExtendedDto>
              source="hierarchyString"
              render={(record?: DeviceCategoryExtendedDto) =>
                `${record?.id}. ${record?.hierarchyString}`
              }
            />
          </ReferenceField>
        )}
        {!smallScreen && hasAuthority(Authority.VIEW_VERIFIER_DEVICE_DISABLED) && (
          <ReferenceField
            source="disabledId"
            reference={ResourceName.VERIFIER_DEVICE_DISABLED}
            link={false}
            sortBy="name"
            label="resources.palms.fields.disabled"
          >
            <FunctionField
              source="name"
              render={(record) => `${record?.id}. ${record?.name}`}
            />
          </ReferenceField>
        )}
        {!smallScreen && <TextField source="mobileDeviceId" sortable={false} />}
      </Datagrid>
    </List>
  )
}
