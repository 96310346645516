import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
  DeleteParams,
} from 'react-admin'
import { DYNAMIC_STATISTICS_URL } from '../../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  remove,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { DynamicStatisticDto } from '../../dto/dynamic-statistic.dto'
import { mapSortDynamicStatisticsParam } from './dynamic-statistics-sort.mapper'
import filterMapper from './dynamic-statistics-filter.mapper'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import { DynamicStatisticParameterDto } from '../../dto/dynamic-statistic-parameter.dto'

const provider = {
  getList: async (
    resource: string,
    { filter, pagination, sort }: TicketAPIGetListParams,
  ): Promise<GetListResult<DynamicStatisticDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortDynamicStatisticsParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DynamicStatisticDto[]>(
      `${DYNAMIC_STATISTICS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DynamicStatisticDto>> => {
    const data = await get<DynamicStatisticDto>(
      DYNAMIC_STATISTICS_URL,
      `/Get/${id}`,
    )

    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<DynamicStatisticDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      UpdateDynamicStatisticRequest,
      DynamicStatisticDto
    >(`${DYNAMIC_STATISTICS_URL}/Patch`, {
      id: id as number,
      name: data.name !== previousData.name ? data.name : undefined,
      sqlQuery:
        data.sqlQuery !== previousData.sqlQuery ? data.sqlQuery : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<DynamicStatisticDto>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateDynamicStatisticRequest[],
      DynamicStatisticDto[]
    >(`${DYNAMIC_STATISTICS_URL}/Post`, [
      {
        name: data?.name,
        sqlQuery: data?.sqlQuery,
      },
    ])
    return Promise.resolve({ data: created[0] })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<CreateResult> => {
    const deleted = await remove<DynamicStatisticDto>(
      DYNAMIC_STATISTICS_URL,
      `/Delete/${id}`,
    )

    return Promise.resolve({ data: deleted })
  },
  execute: async (
    resource: string,
    {
      id,
      rangeFrom,
      rangeTo,
      dynamicallyDefinedParameters,
    }: ExecuteDynamicStatisticRequest,
  ): Promise<DataProviderExtensionResult<ExecuteDynamicStatisticResponse>> => {
    const data = await post<
      ExecuteDynamicStatisticRequestBody,
      ExecuteDynamicStatisticResponse
    >(
      `${DYNAMIC_STATISTICS_URL}/Execute/${id}?rangeFrom=${rangeFrom}&rangeTo=${rangeTo}`,
      {
        dynamicallyDefinedParameters,
      },
    )

    return Promise.resolve({ data })
  },
  getParametersToFullfill: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<DataProviderExtensionResult<DynamicStatisticParameterDto[]>> => {
    const data = await get<DynamicStatisticParameterDto[]>(
      DYNAMIC_STATISTICS_URL,
      `/GetParametersToFullfill/${id}`,
    )

    return Promise.resolve({ data })
  },
  attachRoles: async (
    resource: string,
    params: StatisticRolesRequest,
  ): Promise<CacheableDataProviderExtensionResult<StatisticRolesRequest>> => {
    const statisticRoles = await put<
      StatisticRolesRequest,
      StatisticRolesRequest
    >(`${DYNAMIC_STATISTICS_URL}/AttachRoles`, params)
    return {
      data: statisticRoles,
    }
  },
  detachRoles: async (
    resource: string,
    params: StatisticRolesRequest,
  ): Promise<CacheableDataProviderExtensionResult<StatisticRolesRequest>> => {
    const statisticRoles = await put<
      StatisticRolesRequest,
      StatisticRolesRequest
    >(`${DYNAMIC_STATISTICS_URL}/DetachRoles`, params)
    return {
      data: statisticRoles,
    }
  },
  attachCompanies: async (
    resource: string,
    params: StatisticCompaniesRequest,
  ): Promise<
    CacheableDataProviderExtensionResult<StatisticCompaniesRequest>
  > => {
    const statisticCompanies = await put<
      StatisticCompaniesRequest,
      StatisticCompaniesRequest
    >(`${DYNAMIC_STATISTICS_URL}/AttachCompanies`, params)
    return {
      data: statisticCompanies,
    }
  },
  detachCompanies: async (
    resource: string,
    params: StatisticCompaniesRequest,
  ): Promise<
    CacheableDataProviderExtensionResult<StatisticCompaniesRequest>
  > => {
    const statisticCompanies = await put<
      StatisticCompaniesRequest,
      StatisticCompaniesRequest
    >(`${DYNAMIC_STATISTICS_URL}/DetachCompanies`, params)
    return {
      data: statisticCompanies,
    }
  },
} as DynamicStatisticsDataProvider

interface DynamicStatisticsDataProvider extends DataProvider {
  execute: (
    resource: string,
    params: ExecuteDynamicStatisticRequest,
  ) => Promise<DataProviderExtensionResult<ExecuteDynamicStatisticResponse>>

  getParametersToFullfill: (
    resource: string,
    params: GetOneParams,
  ) => Promise<DataProviderExtensionResult<DynamicStatisticParameterDto[]>>

  attachRoles: (
    resource: string,
    params: StatisticRolesRequest,
  ) => Promise<CacheableDataProviderExtensionResult<StatisticRolesRequest>>

  detachRoles: (
    resource: string,
    params: StatisticRolesRequest,
  ) => Promise<CacheableDataProviderExtensionResult<StatisticRolesRequest>>

  attachCompanies: (
    resource: string,
    params: StatisticCompaniesRequest,
  ) => Promise<CacheableDataProviderExtensionResult<StatisticCompaniesRequest>>

  detachCompanies: (
    resource: string,
    params: StatisticCompaniesRequest,
  ) => Promise<CacheableDataProviderExtensionResult<StatisticCompaniesRequest>>
}

interface ExecuteDynamicStatisticRequest {
  id: number
  rangeFrom: number
  rangeTo: number
  dynamicallyDefinedParameters: {
    [name: string]: string
  }
}

interface ExecuteDynamicStatisticRequestBody {
  dynamicallyDefinedParameters: {
    [name: string]: string
  }
}

interface ExecuteDynamicStatisticResponse {
  data: string[]
}

interface StatisticRolesRequest {
  dynamicStatisticId: number
  rolesIds: number[]
}

interface StatisticCompaniesRequest {
  dynamicStatisticId: number
  companiesIds: number[]
}

interface CreateDynamicStatisticRequest {
  name: string
  sqlQuery: string
}

interface UpdateDynamicStatisticRequest {
  id: number
  name?: string
  sqlQuery?: string
}

export default provider
