import {
  DateField,
  Filter,
  FunctionField,
  Link,
  ListProps,
  Pagination,
  TextField,
  TextInput,
  useTranslate,
  SelectInput,
  Datagrid,
} from 'react-admin'
import React from 'react'
import {
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Event as EventIcon, Sort } from '@material-ui/icons'
import { ResourceName } from '../../core/ResourceName'
import { EventDto } from '../../core/dto/event/event.dto'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'
import { DateTime } from '../../core/common/date-time'
import FilterProps from '../common/FilterProps'
import { EventStatus } from '../../core/enum/EventStatus'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import List from '../common/customized-ra-components/List'

const useStyles = makeStyles((theme) => ({
  actionColumnsWithoutPadding: {
    // applies always for last column
    '& tr > td:last-child': {
      padding: 0,
      width: theme.spacing(6),
    },
    // only applies when there are four columns and targets the penultimate column
    '& tr > td:first-child:nth-last-child(4) ~ td:nth-last-child(2)': {
      padding: 0,
      width: theme.spacing(6),
    },
  },
}))

const LastEvents = () => {
  const t = useTranslate()
  const classes = useStyles()
  const hasAuthority = useHasAuthority()
  return (
    <Datagrid className={classes.actionColumnsWithoutPadding}>
      <FunctionField<EventDto>
        label={t('resources.events.fields.name')}
        render={(record?: EventDto) => {
          const { id, name } = record as EventDto
          return `${id}. ${name}`
        }}
      />
      <FunctionField<EventDto>
        label={t('resources.events.fields.status')}
        render={(record?: EventDto) => {
          const { status } = record as EventDto
          return t(`resources.enums.eventStatus.${status.toLowerCase()}`)
        }}
      />
      <FunctionField<EventDto>
        render={(event?: EventDto) => (
          <Link to={`/${ResourceName.EVENTS}/${event?.id}/show`}>
            <IconButton>
              <EventIcon />
            </IconButton>
          </Link>
        )}
      />
      {hasAuthority(Authority.VIEW_STATS_OVERALL_VIEW) && (
        <FunctionField<EventDto>
          render={(event?: EventDto) =>
            event?.objectsIds &&
            event.objectsIds.length > 0 && (
              <Link
                to={`/${ResourceName.STATS_OVERALL_VIEW}?${new URLSearchParams({
                  filter: `{"id":"${event?.id}"}`,
                })}`}
              >
                <IconButton>
                  <Sort style={{ transform: 'scaleX(-1)' }} />
                </IconButton>
              </Link>
            )
          }
        />
      )}
    </Datagrid>
  )
}

const EventDateField = ({
  source,
  label,
}: {
  source: string
  label?: string
}) => (
  <FunctionField<EventDto>
    source={source}
    label={label}
    render={(record?: EventDto) => {
      const { dateStart, dateEnd } = {
        dateStart: new Date(record?.dateStart as DateTime),
        dateEnd: new Date(record?.dateEnd as DateTime),
      }
      if (dateStart.toDateString() === dateEnd.toDateString())
        return (
          <>
            <DateField source="dateStart" />
            <span>
              {' '}
              {dateStart.getHours() < 10 && 0}
              {dateStart.getHours()}:
            </span>
            <span>
              {dateStart.getMinutes() < 10 && 0}
              {dateStart.getMinutes()} -{' '}
            </span>
            <span>
              {dateEnd.getHours() < 10 && 0}
              {dateEnd.getHours()}:
            </span>
            <span>
              {dateEnd.getMinutes() < 10 && 0}
              {dateEnd.getMinutes()}
            </span>
          </>
        )
      return (
        <>
          <span>
            <DateField source="dateStart" showTime />
          </span>
          <span> - </span>
          <span>
            <DateField source="dateEnd" showTime />
          </span>
        </>
      )
    }}
  />
)

interface EventListProps extends ListProps {
  compactMode?: boolean
}

const EventFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      <SelectInput
        source="status"
        choices={Object.entries(EventStatus).map(([value]) => ({
          id: value,
          name: `resources.enums.eventStatus.${value.toLowerCase()}`,
        }))}
      />
      {hasAuthority(Authority.VIEW_OBJECTS) && (
        <FilteredReferenceInput
          source="objectId"
          reference={ResourceName.OBJECTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_COMPANIES) && (
        <FilteredReferenceInput
          source="companyId"
          reference={ResourceName.COMPANIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

export const EventList = ({
  compactMode = false,
  ...props
}: EventListProps) => {
  const t = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return !compactMode ? (
    <List
      sort={{
        field: 'dateStart',
        order: 'DESC',
      }}
      {...props}
      exporter={false}
      filters={<EventFilters />}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      bulkActionButtons={false}
    >
      <Datagrid
        size="small"
        rowClick={(id) => `/${ResourceName.EVENTS}/${id}/show`}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        {!smallScreen && <DateField source="dateStart" showTime />}
        {!smallScreen && <DateField source="dateEnd" showTime />}
        {smallScreen && (
          <EventDateField
            source="dateStart"
            label="resources.events.fields.date"
          />
        )}
        <FunctionField<EventDto>
          source="status"
          render={(r?: EventDto) =>
            t(`resources.enums.eventStatus.${r?.status.toLowerCase()}`)
          }
        />
      </Datagrid>
    </List>
  ) : (
    <List
      sort={{
        field: 'dateStart',
        order: 'DESC',
      }}
      {...props}
      actions={false}
      filters={undefined}
      perPage={10}
      pagination={false}
      bulkActionButtons={false}
    >
      <LastEvents />
    </List>
  )
}
