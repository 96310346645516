const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  ticketCode: (lambdaParameter, value) =>
    `${lambdaParameter}.TicketCode.Contains("${value}")`,
  bookingId: (lambdaParameter, value) =>
    `${lambdaParameter}.BookingId.Equals(${value})`,
  customerId: (lambdaParameter, value) =>
    `${lambdaParameter}.Booking.CustomerId.Equals(${value})`,
  date: (lambdaParameter, value) =>
    `${lambdaParameter}.Date > DateTimeOffset.FromUnixTimeMilliseconds(${
      value.toString().split(';')[0]
    }).ToLocalTime().DateTime && ${lambdaParameter}.Date < DateTimeOffset.FromUnixTimeMilliseconds(${
      value.toString().split(';')[1]
    }).ToLocalTime().DateTime`,
  deviceName: (lambdaParameter, value) =>
    `${lambdaParameter}.Device.Name.Contains("${value}")`,
  text: (lambdaParameter, value) =>
    `${lambdaParameter}.Data.Text.Contains("${value}")`,
  objectId: (lambdaParameter, value) =>
    `${lambdaParameter}.Booking.Event.Objects.Any(o=>o.Id.Equals(${value}))`,
  eventId: (lambdaParameter, value) =>
    `${lambdaParameter}.Booking.Event.Id.Equals(${value})`,
  deviceId: (lambdaParameter, value) =>
    `${lambdaParameter}.Device.Id.Equals(${value})`,
  categoryIdWithDescendants: () => null,
  categoriesIds: (lambdaParameter, value) =>
    `new int%3F[] {${(
      value as number[]
    )?.toString()}}.Contains(${lambdaParameter}.Device.CategoryId)`,
  schemeIdWithDescendants: () => null,
  schemesIds: (lambdaParameter, value) =>
    `new int%3F[] {${(
      value as number[]
    )?.toString()}}.Contains(${lambdaParameter}.SchemeId)`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Text).Contains("${value}")`,
  simpleView: (lambdaParameter, value) =>
    `${lambdaParameter}.Scheme.LogSchemeData.Any(lsd => lsd.Language == "${value}" && !lsd.HiddenInSimpleView)`,
  variantId: (lambdaParameter, value) =>
    `${lambdaParameter}.Booking.VariantId.Equals(${value})`,
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
