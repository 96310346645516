import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DeleteButton,
  Filter,
  FilterProps,
  FormDataConsumer,
  FunctionField,
  List,
  ListProps,
  Pagination,
  required,
  sanitizeListRestProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { DynamicStatisticParameterTypes } from '../../../../core/enum/DynamicStatisticParameterTypes'
import { ResourceName } from '../../../../core/ResourceName'
import Button from '../../../common/customized-mui-components/Button'
import {
  CreateDialog,
  CreateDialogProps,
} from '../../../common/customized-ra-components/CreateDialog'
import {
  EditDialog,
  EditDialogProps,
} from '../../../common/customized-ra-components/EditDialog'
import { ParameterValueField } from '../common/ParameterValueField'
import { ParameterValueInput } from '../common/ParameterValueInput'
import { Authority } from '../../../../core/auth/Authority'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { DynamicStatisticParameterDto } from '../../../../core/dto/dynamic-statistic-parameter.dto'

const useStyles = makeStyles({
  editToolbar: {
    display: 'flex',
    '&>button:last-child': {
      marginLeft: 'auto',
    },
  },
})

const validateDynamicStatisticParameters = (
  values: DynamicStatisticParameterDto,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.key) {
    errors.key = 'ra.validation.required'
  }
  if (
    values.dynamicallyDefined &&
    !values.description &&
    values.type !== DynamicStatisticParameterTypes.CURRENT_DATE &&
    values.type !== DynamicStatisticParameterTypes.CURRENT_USER_ID
  ) {
    errors.description = 'ra.validation.required'
  }
  if (
    !values.dynamicallyDefined &&
    !values.value &&
    values.type !== DynamicStatisticParameterTypes.CURRENT_DATE &&
    values.type !== DynamicStatisticParameterTypes.CURRENT_USER_ID
  ) {
    errors.value = 'ra.validation.required'
  }
  return errors
}

const ListFilters = (props: Omit<FilterProps, 'children'>) => (
  <Filter {...props}>
    <TextInput source="key" alwaysOn />
  </Filter>
)

export const parameterTypeAuthority = {
  [DynamicStatisticParameterTypes.STRING]: undefined,
  [DynamicStatisticParameterTypes.INTEGER]: undefined,
  [DynamicStatisticParameterTypes.DATE]: undefined,
  [DynamicStatisticParameterTypes.CURRENT_DATE]: undefined,
  [DynamicStatisticParameterTypes.CULTURE]: undefined,
  [DynamicStatisticParameterTypes.CURRENT_USER_ID]: undefined,
  [DynamicStatisticParameterTypes.USER_ID]: Authority.VIEW_USERS,
  [DynamicStatisticParameterTypes.COMPANY_ID]: Authority.VIEW_COMPANIES,
  [DynamicStatisticParameterTypes.PERMISSION_ID]: Authority.VIEW_ROLES,
  [DynamicStatisticParameterTypes.ROLE_ID]: Authority.VIEW_ROLES,
  [DynamicStatisticParameterTypes.CAMERA_ID]: Authority.VIEW_CAMERAS,
  [DynamicStatisticParameterTypes.CAMERA_SERVER_ID]:
    Authority.VIEW_CAMERA_SERVERS,
  [DynamicStatisticParameterTypes.PALM_ID]: Authority.VIEW_PALMS,
  [DynamicStatisticParameterTypes.PALM_SCHEME_DATA_ID]:
    Authority.VIEW_PALM_SCHEME_DATA,
  [DynamicStatisticParameterTypes.DEPOSITOR_ID]: Authority.VIEW_DEPOSITORS,
  [DynamicStatisticParameterTypes.DEVICE_BASE_ID]: Authority.VIEW_DEVICES_BASE,
  [DynamicStatisticParameterTypes.ENGINE_ID]: Authority.VIEW_ENGINES,
  [DynamicStatisticParameterTypes.OFFLINE_SERVER_ID]:
    Authority.VIEW_OFFLINE_SERVERS,
  [DynamicStatisticParameterTypes.PASSAGE_ID]: Authority.VIEW_PASSAGES,
  [DynamicStatisticParameterTypes.PASSAGE_COUNTER_ID]: Authority.VIEW_PASSAGES,
  [DynamicStatisticParameterTypes.VERIFIER_DEVICE_BASE_ID]:
    Authority.VIEW_VERIFIER_DEVICES,
  [DynamicStatisticParameterTypes.DEVICE_CATEGORY_ID]:
    Authority.VIEW_DEVICE_CATEGORIES,
  [DynamicStatisticParameterTypes.ENTRANCE_ID]: Authority.VIEW_ENTRANCES,
  [DynamicStatisticParameterTypes.ENTRANCE_SET_ID]:
    Authority.VIEW_ENTRANCES_SET,
  [DynamicStatisticParameterTypes.CAMERA_TYPE]: undefined,
  [DynamicStatisticParameterTypes.DEVICE_DIRECTION]: undefined,
  [DynamicStatisticParameterTypes.HARDWARE_VERSION]: undefined,
  [DynamicStatisticParameterTypes.PALM_THEME]: undefined,
  [DynamicStatisticParameterTypes.PASSAGE_COUNTER_TYPE]: undefined,
  [DynamicStatisticParameterTypes.PASSAGE_DIRECTION]: undefined,
  [DynamicStatisticParameterTypes.LOG_ID]: Authority.VIEW_LOGS,
  [DynamicStatisticParameterTypes.LOG_SCHEME_DATA_ID]:
    Authority.VIEW_LOG_SCHEME_DATA,
  [DynamicStatisticParameterTypes.ACCESS_ID]: Authority.VIEW_ACCESSES,
  [DynamicStatisticParameterTypes.ACCESS_DATE_ID]: Authority.VIEW_ACCESS_DATES,
  [DynamicStatisticParameterTypes.ACCESS_USING_ID]:
    Authority.VIEW_ACCESS_USINGS,
  [DynamicStatisticParameterTypes.BLOCK_ID]: Authority.VIEW_BLOCKS,
  [DynamicStatisticParameterTypes.BOOKING_ID]: Authority.VIEW_BOOKINGS,
  [DynamicStatisticParameterTypes.CUSTOMER_ID]: Authority.VIEW_CUSTOMERS,
  [DynamicStatisticParameterTypes.EVENT_ID]: Authority.VIEW_EVENTS,
  [DynamicStatisticParameterTypes.OBJECT_ID]: Authority.VIEW_OBJECTS,
  [DynamicStatisticParameterTypes.SCHEME_ID]: Authority.VIEW_SCHEMES,
  [DynamicStatisticParameterTypes.SEAT_ID]: Authority.VIEW_SEATS,
  [DynamicStatisticParameterTypes.TICKET_ID]: Authority.VIEW_TICKETS,
  [DynamicStatisticParameterTypes.TRIBUNE_ID]: Authority.VIEW_TRIBUNES,
  [DynamicStatisticParameterTypes.VARIANT_ID]: Authority.VIEW_VARIANTS,
  [DynamicStatisticParameterTypes.ACCESS_ACTIVE_PERIOD_STARTING_OPTION]:
    undefined,
  [DynamicStatisticParameterTypes.ACCESS_BONUS]: undefined,
  [DynamicStatisticParameterTypes.ACCESS_DATE_TYPE]: undefined,
  [DynamicStatisticParameterTypes.BLOCK_TYPE]: undefined,
  [DynamicStatisticParameterTypes.BOOKING_DISABLED_OPTION]: undefined,
  [DynamicStatisticParameterTypes.BOOKING_TYPE]: undefined,
  [DynamicStatisticParameterTypes.CUSTOMER_DOCUMENT_TYPE]: undefined,
  [DynamicStatisticParameterTypes.CUSTOMER_TYPE]: undefined,
  [DynamicStatisticParameterTypes.EVENT_STATUS]: undefined,
  [DynamicStatisticParameterTypes.GENDER]: undefined,
  [DynamicStatisticParameterTypes.TICKET_TYPES]: undefined,
  [DynamicStatisticParameterTypes.TRIBUNE_TYPES]: undefined,
}

const ParameterTypeInput = ({ ...props }) => {
  const translate = useTranslate()
  const { change } = useForm()
  const hasAuthority = useHasAuthority()

  const authorizedParameterTypes = Object.entries(parameterTypeAuthority)
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([key, value]) => !value || hasAuthority(value))
    .map(([key]) => key)

  const handleChange = () => {
    change('value', undefined)
  }

  return (
    <SelectInput
      {...props}
      source="type"
      choices={authorizedParameterTypes.map((key: string) => ({
        id: key,
        name: translate(
          `resources.enums.DynamicStatisticParameterTypes.${key}`,
        ),
      }))}
      validate={required()}
      fullWidth
      onChange={handleChange}
    />
  )
}

const EditToolbar = ({ ...props }) => {
  const { handleClose, invalid, ...rest } = props
  const classes = useStyles()
  const hasAuthority = useHasAuthority()

  return (
    <Toolbar {...rest} className={classes.editToolbar}>
      <SaveButton disabled={invalid} redirect={false} onClick={handleClose} />
      <DeleteButton
        redirect={false}
        onClick={handleClose}
        disabled={!hasAuthority(Authority.DELETE_DYNAMIC_STATISTIC_PARAMETERS)}
      />
    </Toolbar>
  )
}

const ParametersEditDialog = (props: EditDialogProps) => {
  const { handleClose } = props

  return (
    <EditDialog {...props}>
      <SimpleForm
        redirect={false}
        toolbar={<EditToolbar handleClose={handleClose} />}
        validate={validateDynamicStatisticParameters}
      >
        <TextField source="id" label="ID" fullWidth />
        <TextInput source="key" validate={required()} fullWidth />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            formData?.dynamicallyDefined &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_DATE &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_USER_ID ? (
              <TextInput
                source="description"
                validate={
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_DATE &&
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_USER_ID
                    ? required()
                    : undefined
                }
                {...rest}
                fullWidth
              />
            ) : (
              <TextField source="description" />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            formData.type !== DynamicStatisticParameterTypes.CURRENT_DATE &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_USER_ID ? (
              <BooleanInput
                source="dynamicallyDefined"
                validate={
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_DATE &&
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_USER_ID
                    ? required()
                    : undefined
                }
                fullWidth
                {...rest}
              />
            ) : (
              <TextField source="dynamicallyDefined" />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            !formData?.dynamicallyDefined &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_DATE &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_USER_ID ? (
              <ParameterValueInput
                validate={
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_DATE &&
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_USER_ID
                    ? required()
                    : undefined
                }
                fullWidth
                {...rest}
              />
            ) : (
              <TextField source="value" />
            )
          }
        </FormDataConsumer>
        <ParameterTypeInput fullWidth />
      </SimpleForm>
    </EditDialog>
  )
}

const CreateToolbar = ({ ...props }) => {
  const { handleClose, invalid, ...rest } = props

  return (
    <Toolbar {...rest}>
      <SaveButton disabled={invalid} redirect={false} onClick={handleClose} />
    </Toolbar>
  )
}

const ParametersCreateDialog = (props: CreateDialogProps) => {
  const { handleClose } = props
  const { record: statistic } = useShowContext()

  return (
    <CreateDialog {...props}>
      <SimpleForm
        initialValues={{
          statisticId: statistic?.id,
          dynamicallyDefined: false,
          type: DynamicStatisticParameterTypes.STRING,
        }}
        redirect={false}
        toolbar={<CreateToolbar handleClose={handleClose} />}
        validate={validateDynamicStatisticParameters}
      >
        <TextInput source="key" validate={required()} fullWidth />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            formData?.dynamicallyDefined &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_DATE &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_USER_ID ? (
              <TextInput
                source="description"
                validate={
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_DATE &&
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_USER_ID
                    ? required()
                    : undefined
                }
                {...rest}
                fullWidth
              />
            ) : (
              <TextField source="description" />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            formData.type !== DynamicStatisticParameterTypes.CURRENT_DATE &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_USER_ID ? (
              <BooleanInput
                source="dynamicallyDefined"
                validate={
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_DATE &&
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_USER_ID
                    ? required()
                    : undefined
                }
                fullWidth
                {...rest}
              />
            ) : (
              <TextField source="dynamicallyDefined" />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            !formData?.dynamicallyDefined &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_DATE &&
            formData.type !== DynamicStatisticParameterTypes.CURRENT_USER_ID ? (
              <ParameterValueInput
                validate={
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_DATE &&
                  formData.type !==
                    DynamicStatisticParameterTypes.CURRENT_USER_ID
                    ? required()
                    : undefined
                }
                fullWidth
                {...rest}
              />
            ) : (
              <TextField source="value" />
            )
          }
        </FormDataConsumer>
        <ParameterTypeInput fullWidth />
      </SimpleForm>
    </CreateDialog>
  )
}

const DynamicStatisticParametersActions = ({ ...props }) => {
  const { handleOpenCreate, ...rest } = props
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <Button
        label={translate('ra.action.create')}
        onClick={handleOpenCreate}
        startIcon={<Add />}
        disabled={
          !hasAuthority(Authority.EDIT_DYNAMIC_STATISTICS) ||
          !hasAuthority(Authority.CREATE_DYNAMIC_STATISTIC_PARAMETERS)
        }
      />
    </TopToolbar>
  )
}

const DynamicStatisticParametersGrid = ({ ...props }) => {
  const { handleOpenEdit, ...rest } = props
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Datagrid
      {...rest}
      padding={smallScreen ? 'checkbox' : 'default'}
      hasBulkActions={false}
      rowClick={
        hasAuthority(Authority.EDIT_DYNAMIC_STATISTIC_PARAMETERS) &&
        hasAuthority(Authority.EDIT_DYNAMIC_STATISTICS)
          ? handleOpenEdit
          : undefined
      }
      rowStyle={
        hasAuthority(Authority.EDIT_DYNAMIC_STATISTIC_PARAMETERS) &&
        hasAuthority(Authority.EDIT_DYNAMIC_STATISTICS)
          ? (record) => ({
              color:
                !parameterTypeAuthority[record?.type] ||
                hasAuthority(parameterTypeAuthority[record?.type])
                  ? 'default'
                  : theme.palette.grey[500],
              cursor:
                !parameterTypeAuthority[record?.type] ||
                hasAuthority(parameterTypeAuthority[record?.type])
                  ? 'pointer'
                  : 'default',
            })
          : undefined
      }
    >
      <TextField source="id" label="ID" />
      <TextField source="key" />
      <TextField source="description" />
      <BooleanField source="dynamicallyDefined" />
      <ParameterValueField source="value" />
      <FunctionField
        source="type"
        render={(record, source) =>
          translate(
            `resources.enums.DynamicStatisticParameterTypes.${
              record?.[source as string]
            }`,
          )
        }
      />
    </Datagrid>
  )
}

export const DynamicStatisticParametersList = (props: ListProps) => {
  const { basePath } = useListContext()
  const [createOpen, setCreateOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [editId, setEditId] = useState<string | undefined>(undefined)
  const hasAuthority = useHasAuthority()

  const handleOpenCreate = () => {
    setCreateOpen(true)
  }

  const handleCloseCreate = () => {
    setCreateOpen(false)
  }

  const handleOpenEdit = (
    id: string,
    base: string,
    record: DynamicStatisticParameterDto,
  ) => {
    if (
      !parameterTypeAuthority[record?.type] ||
      hasAuthority(parameterTypeAuthority[record?.type] as any)
    ) {
      setEditId(id)
      setEditOpen(true)
    }
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
  }

  return (
    <>
      <ParametersCreateDialog
        {...props}
        basePath="/stats-dynamic-statistics"
        handleClose={handleCloseCreate}
        resource={ResourceName.STATS_DYNAMIC_STATISTIC_PARAMETERS}
        open={createOpen}
      />
      <ParametersEditDialog
        {...props}
        basePath="/stats-dynamic-statistics"
        handleClose={handleCloseEdit}
        id={editId as string}
        resource={ResourceName.STATS_DYNAMIC_STATISTIC_PARAMETERS}
        open={editOpen}
      />
      <List
        {...props}
        actions={
          <DynamicStatisticParametersActions
            handleOpenCreate={handleOpenCreate}
          />
        }
        basePath={basePath}
        bulkActionButtons={false}
        empty={false}
        exporter={false}
        filters={<ListFilters />}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
        perPage={20}
      >
        <DynamicStatisticParametersGrid handleOpenEdit={handleOpenEdit} />
      </List>
    </>
  )
}
