var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { fade, Fade, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OpenEditorButton } from './OpenEditorButton';
import { usePreferencesEditor } from '../usePreferencesEditor';
export var Configurable = function (props) {
    var children = props.children, editableEl = props.editableEl, editor = props.editor, openButtonLabel = props.openButtonLabel;
    var classes = useStyles(props);
    var buttonRef = useRef();
    var _a = useState(false), showEditorButton = _a[0], setShowEditorButton = _a[1];
    var _b = usePreferencesEditor(), isEnabled = _b.isEnabled, setEditor = _b.setEditor;
    var handleMouseEnter = function (event) {
        event.stopPropagation();
        setShowEditorButton(true);
    };
    var handleMouseLeave = function (event) {
        // We don't want to hide the configuration button if users hover it
        // To ensure that, we check whether the cursor is still over the editor target (the table element for the Datagrid for example)
        var targetRect = event.target.getBoundingClientRect();
        var isMouseHoverTarget = event.clientX > targetRect.left &&
            event.clientX < targetRect.right &&
            event.clientY > targetRect.top &&
            event.clientY < targetRect.bottom;
        if (!isMouseHoverTarget) {
            setShowEditorButton(false);
        }
    };
    useEffect(function () {
        if (!editableEl) {
            return;
        }
        if (isEnabled) {
            editableEl.classList.add(classes.element);
            editableEl.addEventListener('mouseover', handleMouseEnter);
            editableEl.addEventListener('mouseleave', handleMouseLeave);
        }
        return function () {
            setShowEditorButton(false);
            editableEl.classList.remove(classes.element);
            editableEl.removeEventListener('mouseover', handleMouseEnter);
            editableEl.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [classes, editableEl, isEnabled]);
    var handleOpenEditor = function () {
        setEditor(editor);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Popper, { open: showEditorButton, anchorEl: editableEl, placement: "top-end", transition: true, className: classes.popper, modifiers: popperModifiers }, function (_a) {
            var TransitionProps = _a.TransitionProps;
            return (React.createElement(Fade, __assign({}, TransitionProps, { timeout: 350 }),
                React.createElement(OpenEditorButton, { ref: buttonRef, onClick: handleOpenEditor, label: openButtonLabel })));
        }),
        children));
};
var popperModifiers = {
    inner: { enabled: true },
    flip: { enabled: false },
    hide: { enabled: false },
    preventOverflow: {
        enabled: false,
        padding: 0,
    },
};
var useStyles = makeStyles(function (theme) { return ({
    popper: {
        zIndex: theme.zIndex.modal - 1,
    },
    element: {
        boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, " + fade(theme.palette.primary.main, 0.3) + " 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
        '&:hover': {
            boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, " + theme.palette.primary.main + " 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
        },
    },
}); }, {
    name: 'RaPreferencesEditor',
});
