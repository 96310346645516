import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import plTranslation from './locales/pl/translation.json'
import enTranslation from './locales/en/translation.json'

const identity = localStorage.getItem('username')
const lng = identity && localStorage.getItem(`${identity}.lng`)

i18n.use(initReactI18next).init({
  lng: lng || 'pl',
  fallbackLng: 'pl',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    pl: { translation: plTranslation },
    en: { translation: enTranslation },
  },
})

export default i18n
