var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import * as React from 'react';
import { useState } from 'react';
import { getFieldLabelTranslationArgs, useTranslate, } from 'react-admin';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useResourceModel } from '.';
/**
 * A component which provides a configuration UI to tweak a resource model reference field
 * @param {ReferenceFieldInputProps} props
 * @param {String} props.resource The resource
 */
export var ReferenceFieldInput = function (_a) {
    var label = _a.label, resource = _a.resource, value = _a.value, onChange = _a.onChange;
    var model = useResourceModel(resource)[0];
    var fields = (model === null || model === void 0 ? void 0 : model.fields) || {};
    var translate = useTranslate();
    var displayField = inferBestReferenceDisplayField(fields || {});
    var initialValue = value || displayField;
    var _b = useState(function () {
        var fieldChoices = Object.keys(fields).map(function (field) { return ({
            id: field,
            name: translate.apply(void 0, getFieldLabelTranslationArgs({
                label: model.fields[field].props.label,
                resource: resource,
                source: model.fields[field].props.source,
            })),
        }); });
        if (value && value.indexOf('{{') > -1) {
            fieldChoices.push({
                id: value,
                name: value,
            });
        }
        return fieldChoices;
    }), choices = _b[0], setChoices = _b[1];
    var autocompleteFilter = createFilterOptions();
    var handleKeyChange = function (event, newKey) {
        if (typeof newKey === 'string') {
            setChoices(function (prev) { return __spreadArray(__spreadArray([], prev), [{ id: newKey, name: newKey }]); });
            onChange(newKey);
        }
        else if (newKey && newKey.inputValue) {
            // Create a new value from the user input
            setChoices(function (prev) { return __spreadArray(__spreadArray([], prev), [
                { id: newKey.inputValue, name: newKey.inputValue },
            ]); });
            onChange(newKey.inputValue);
        }
        else if (newKey && newKey.id) {
            onChange(newKey.id);
        }
        else {
            onChange(undefined);
        }
    };
    return (React.createElement(Autocomplete, { freeSolo: true, options: choices, value: initialValue, onChange: handleKeyChange, filterOptions: function (options, params) {
            var filtered = autocompleteFilter(options, params);
            // Suggest the creation of a new value
            if (params.inputValue !== '') {
                filtered.push({
                    inputValue: params.inputValue,
                    name: "Add computed field: \"" + params.inputValue + "\"",
                });
            }
            return filtered;
        }, selectOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true, renderInput: function (params) { return React.createElement(TextField, __assign({}, params, { label: label })); }, renderOption: function (option) { return option.name; }, getOptionLabel: function (option) {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
                return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
                return option.inputValue;
            }
            // Regular option
            return option.name;
        } }));
};
var FAVORITE_DISPLAY_FIELD_NAMES = [
    'name',
    'title',
    'label',
    'reference',
    'email',
];
var inferBestReferenceDisplayField = function (types) {
    var best = Object.entries(types).find(function (entry) {
        return FAVORITE_DISPLAY_FIELD_NAMES.includes(entry[0].toLowerCase());
    });
    if (best) {
        return best[0];
    }
};
