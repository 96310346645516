import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  BooleanInput,
  EditButton,
  FunctionField,
  List,
  Pagination,
  ResourceContextProvider,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { CompanyDto } from '../../../core/dto/company.dto'
import { UserDto } from '../../../core/dto/user.dto'
import { ResourceName } from '../../../core/ResourceName'
import {
  EditableDatagrid,
  RowForm,
} from '../../../lib/@react-admin/ra-editable-datagrid/esm/src'
import { EventList } from '../../events/EventList'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { validateUserCreate } from '../users/UserCreate'
import { validateUserEdit } from '../users/UserEdit'

interface CompanyTitleProps extends TitleProps {
  record?: CompanyDto
}

const ShowTitle = (data: CompanyTitleProps) => {
  const { record: company } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.companies.titles.show')}: {company?.id}.{' '}
      {company?.name}
    </span>
  )
}

const CompanyEvents = () => {
  const { record: company, basePath } = useShowContext()
  return (
    <ResourceContextProvider value={ResourceName.EVENTS}>
      <EventList
        basePath={basePath}
        filter={{ companyId: company?.id }}
        filters={undefined}
        actions={false}
        title=" "
      />
    </ResourceContextProvider>
  )
}

const CompanyUserCreate = ({ ...props }) => {
  const { companyId, ...rest } = props
  return (
    <RowForm
      initialValues={{
        companyId,
        requirePasswordUpdate: false,
      }}
      {...rest}
      validate={validateUserCreate}
    >
      <TextField source="id" />
      <TextInput source="userName" />
      <TextInput source="password" />
      <BooleanInput source="requirePasswordUpdate" />
    </RowForm>
  )
}

const CompanyUserEdit = ({ ...props }) => (
  <RowForm {...props} validate={validateUserEdit}>
    <TextField source="id" />
    <TextInput source="userName" />
    <BooleanInput source="requirePasswordUpdate" />
  </RowForm>
)

const CompanyUsersGrid = ({ ...props }) => {
  const { record: company } = useShowContext()
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()

  return (
    <EditableDatagrid
      {...props}
      actions={hasAuthority(Authority.EDIT_USERS) ? undefined : false}
      editForm={<CompanyUserEdit />}
      createForm={
        hasAuthority(Authority.EDIT_USERS) &&
        hasAuthority(Authority.CREATE_USERS) ? (
          <CompanyUserCreate companyId={company?.id} />
        ) : undefined
      }
      hasBulkActions={false}
      defaultTitle={null}
      noDelete
    >
      <TextField source="id" label="ID" />
      <TextField source="userName" />
      <FunctionField<UserDto>
        source="requirePasswordUpdate"
        render={(record?: UserDto) => (
          <div>
            {record?.requirePasswordUpdate
              ? translate('const.yes')
              : translate('const.no')}
          </div>
        )}
      />
    </EditableDatagrid>
  )
}

const CompanyUsers = () => {
  const { record: company, basePath } = useShowContext()

  return (
    <ResourceContextProvider value={ResourceName.USERS}>
      <List
        title=" "
        actions={false}
        exporter={false}
        basePath={basePath}
        filter={{ companyId: company?.id }}
        filters={undefined}
        perPage={20}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} />}
        bulkActionButtons={false}
        empty={false}
      >
        <CompanyUsersGrid />
      </List>
    </ResourceContextProvider>
  )
}

const GeneralActions = () => {
  const { record: company } = useShowContext<CompanyDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath={`/${ResourceName.COMPANIES}`}
          record={company}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_COMPANIES)}
        />
      </Box>
    </>
  )
}

export const CompanyShow = (props: ShowProps) => {
  const hasAuthority = useHasAuthority()

  return (
    <Show {...props} actions={false} title={<ShowTitle />}>
      <TabbedShowLayout>
        <Tab label="resources.companies.tabs.general">
          <TextField source="id" label="ID" />
          <TextField source="name" />
          <GeneralActions />
        </Tab>
        {hasAuthority(Authority.VIEW_EVENTS) && (
          <Tab label="resources.companies.tabs.events" path="events">
            <CompanyEvents />
          </Tab>
        )}
        {hasAuthority(Authority.VIEW_USERS) && (
          <Tab label="resources.companies.tabs.users" path="users">
            <CompanyUsers />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  )
}
