const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  schemeId: (lambdaParameter, value) =>
    `${lambdaParameter}.Scheme.Id.Equals(${value})`,
  verifierDeviceId: (lambdaParameter, value) =>
    `${lambdaParameter}.VerifierDevice.Id.Equals(${value})`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
