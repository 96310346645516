import { DataProvider } from 'react-admin'
import { EntranceDto } from '../../dto/entrance.dto'

const fakeEntrances: EntranceDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeEntrances, total: fakeEntrances.length }),
} as DataProvider

export default provider
