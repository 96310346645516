import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { VerifierDeviceDisabledDto } from '../../../core/dto/device/verifier-device-disabled/verifier-device-disabled.dto'

interface VerifierDeviceDisabledTitleProps extends TitleProps {
  record?: VerifierDeviceDisabledDto
}

const ShowTitle = (data: VerifierDeviceDisabledTitleProps) => {
  const { record: verifierDeviceDisabled } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.verifier-device-disabled.titles.show')}:{' '}
      {verifierDeviceDisabled?.id}. {verifierDeviceDisabled?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: verifierDeviceDisabled } =
    useShowContext<VerifierDeviceDisabledDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath={`/${ResourceName.VERIFIER_DEVICE_DISABLED}`}
          record={verifierDeviceDisabled}
          variant="contained"
          disabled={!hasAuthority(Authority.EDIT_VERIFIER_DEVICE_DISABLED)}
        />
      </Box>
    </>
  )
}

export const VerifierDeviceDisabledShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="description" />
      <GeneralActions />
    </SimpleShowLayout>
  </Show>
)
