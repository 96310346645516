import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
} from 'react-admin'
import { ENTRANCES_URL } from '../api-urls'
import { get, getWithPagination, post, put } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import {
  TicketAPIGetListParams,
  TicketAPIGetManyRefrenceParams,
} from '../common/ticket-api-get-list.params'
import filterMapper from './entrances-set-filter.mapper'
import { mapSortEntrancesSetParam } from './entrances-set-sort.mapper'
import { DataProviderExtensionResult } from '../common/data-provider'
import { EntrancesSetDto } from '../dto/entrances-set.dto'
import { RestoreEntranceSetWarnigTypes } from '../enum/RestoreEntranceSetWarnigTypes'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<EntrancesSetDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortEntrancesSetParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<EntrancesSetDto[]>(
      `${ENTRANCES_URL}/GetEntrancesSetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<EntrancesSetDto>> => {
    const data = await get<EntrancesSetDto>(
      `${ENTRANCES_URL}/GetEntrancesSet`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<EntrancesSetDto>> => {
    const { data } = await getWithPagination<EntrancesSetDto[]>(
      `${ENTRANCES_URL}/GetEntrancesSetByConditions/`,
      `es=>new int[] {${ids.toString()}}.Contains(es.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  getManyReference: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetManyRefrenceParams,
  ): Promise<GetManyReferenceResult<EntrancesSetDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortEntrancesSetParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<EntrancesSetDto[]>(
      `${ENTRANCES_URL}/GetEntrancesSetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateEntrancesSetRequest>,
  ): Promise<CreateResult<EntrancesSetDto>> => {
    const entrancesSet = await post<CreateEntrancesSetRequest, EntrancesSetDto>(
      `${ENTRANCES_URL}/CreateEntrancesSet`,
      data,
    )
    return {
      data: entrancesSet,
    }
  },
  restore: async (
    resource: string,
    data: RestoreEntrancesSetRequest,
  ): Promise<DataProviderExtensionResult<RestoreEntrancesSetResponse>> => {
    const restoreResponse = await put<
      RestoreEntrancesSetRequest,
      RestoreEntrancesSetResponse
    >(`${ENTRANCES_URL}/RestoreEntrancesSet`, data)
    return {
      data: restoreResponse,
    }
  },
} as EntranceDataProvider

interface EntranceDataProvider extends DataProvider {
  restore: (
    resource: string,
    params: RestoreEntrancesSetRequest,
  ) => Promise<DataProviderExtensionResult<RestoreEntrancesSetResponse>>
}

export interface CreateEntrancesSetRequest {
  readonly description: string
}

export interface RestoreEntrancesSetRequest {
  readonly entranceSetId: number
}

export interface RestoreEntrancesSetResponse {
  readonly isSuccessfull: boolean
  readonly warnings: RestoreEntrancesSetWarning[]
}

export interface RestoreEntrancesSetWarning {
  readonly description: string
  readonly warningType: RestoreEntranceSetWarnigTypes
  readonly deviceId: number | null
  readonly entranceId: number | null
}

export default provider
