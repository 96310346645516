import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { ACCESS_DATES_URL } from '../api-urls'
import { DateTime, ignoreTimezoneOffset } from '../common/date-time'
import { get, getWithPagination, patch, post } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { AccessDateDto } from '../dto/access-date.dto'
import { AccessDateTypes } from '../enum/AccessDateTypes'
import { DayOfWeek } from '../enum/DayOfWeek'
import filterMapper from './access-dates-filter.mapper'
import { mapSortAccessDatesParam } from './access-dates-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<AccessDateDto>> => {
    const filterParams = `ad=>${filterParamsComposer(
      'ad',
      filter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortAccessDatesParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<AccessDateDto[]>(
      `${ACCESS_DATES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<AccessDateDto>> => {
    const data = await get<AccessDateDto>(ACCESS_DATES_URL, `/${id}`)
    return {
      data,
    }
  },
  update: async (
    resource: string,
    { data, previousData }: UpdateParams<AccessDateDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<UpdateAccessDateRequest, AccessDateDto>(
      ACCESS_DATES_URL,
      {
        id: data.id,
        activeFrom:
          data.activeFrom !== previousData.activeFrom
            ? (ignoreTimezoneOffset(
                new Date(data.activeFrom),
              ).toISOString() as DateTime)
            : undefined,
        activeTo:
          data.activeTo !== previousData.activeTo
            ? (ignoreTimezoneOffset(
                new Date(data.activeTo),
              ).toISOString() as DateTime)
            : undefined,
        type: data.type !== previousData.type ? data.type : undefined,
        priority:
          data.priority !== previousData.priority ? data.priority : undefined,
        dayOfWeek:
          data.dayOfWeek !== previousData.dayOfWeek
            ? data.dayOfWeek
            : undefined,
        timeFrom:
          data.timeFrom !== previousData.timeFrom ? data.timeFrom : undefined,
        timeTo: data.timeTo !== previousData.timeTo ? data.timeTo : undefined,
        accessId:
          data.accessId !== previousData.accessId ? data.accessId : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<AccessDateDto>,
  ): Promise<CreateResult> => {
    const created = await post<CreateAccessDateRequest[], AccessDateDto[]>(
      ACCESS_DATES_URL,
      [
        {
          ...data,
          activeFrom: ignoreTimezoneOffset(
            new Date(data.activeFrom),
          ).toISOString() as DateTime,
          activeTo: ignoreTimezoneOffset(
            new Date(data.activeTo),
          ).toISOString() as DateTime,
        },
      ],
    )
    return Promise.resolve({ data: created[0] })
  },
} as DataProvider

interface CreateAccessDateRequest {
  readonly activeFrom: DateTime
  readonly activeTo: DateTime
  readonly type: AccessDateTypes
  readonly priority?: number
  readonly dayOfWeek?: DayOfWeek
  readonly timeFrom: DateTime
  readonly timeTo: DateTime
  readonly accessId: number
}

interface UpdateAccessDateRequest {
  readonly id: Identifier
  readonly activeFrom?: DateTime
  readonly activeTo?: DateTime
  readonly type?: AccessDateTypes
  readonly priority?: number
  readonly dayOfWeek?: DayOfWeek
  readonly timeFrom?: DateTime
  readonly timeTo?: DateTime
  readonly accessId?: number
}

export default provider
