import { mapping as verifierDeviceBaseMapping } from '../common/verifier-device-base/verifier-device-sort.mapper'

export const mapping: Record<string, string> = {
  ...verifierDeviceBaseMapping,
  address: 'p=>p.Address',
  hardwareVersion: 'p=>p.hardwareVersion',
}

const mapSortEngineParam = (key: string) => mapping[key]

export default mapSortEngineParam
