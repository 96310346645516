import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Create,
  CreateProps,
  ListButton,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { DynamicCounterDto } from '../../../core/dto/dynamic-counter.dto'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { ResourceName } from '../../../core/ResourceName'

interface DynamicPropertyDefinitionTitleProps extends TitleProps {
  record?: DynamicCounterDto
}

const validateDynamicCounterCreate = (values: DynamicCounterDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.sqlQuery) {
    errors.sqlQuery = 'ra.validation.required'
  }
  if (!values.state && values.state !== 0) {
    errors.state = 'ra.validation.required'
  }
  if (!values.limit && values.limit !== 0) {
    errors.limit = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = (props: DynamicPropertyDefinitionTitleProps) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.dynamic-counters.titles.create')}: {record?.id}.{' '}
      {record?.name}
    </span>
  )
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton redirect={() => '/dynamic-counters'} />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const DynamicCounterCreate = (props: CreateProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Create {...props} title={<CreateTitle />} actions={false}>
      <SimpleForm
        redirect="show"
        validate={validateDynamicCounterCreate}
        toolbar={<CreateToolbar />}
        initialValues={{
          active: false,
        }}
      >
        <TextInput source="name" />
        <BooleanInput source="active" />
        <TextInput source="sqlQuery" fullWidth multiline />
        <NumberInput source="state" />
        <NumberInput source="limit" />
        {hasAuthority(Authority.VIEW_SCHEMES) && (
          <FilteredReferenceInput
            source="schemeId"
            defaultValue={null}
            reference={ResourceName.SCHEMES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
      </SimpleForm>
    </Create>
  )
}
