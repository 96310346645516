import jsonExport from 'jsonexport/dist'
import React, { cloneElement, useContext } from 'react'
import {
  Datagrid,
  DateField,
  TextField,
  useTranslate,
  downloadCSV,
  Pagination,
  useListContext,
  useQuery,
  GET_ONE,
  ListActionsProps,
  ExportButton,
  TopToolbar,
  sanitizeListRestProps,
  ListProps,
  FilterButton,
  FilterContext,
} from 'react-admin'
import { EventDto } from '../../../core/dto/event/event.dto'
import { ResourceName } from '../../../core/ResourceName'
import { EventEntrance } from '../../../core/stats/event-entrances-view/event-entrances-view'
import List from '../../common/customized-ra-components/List'
import { MaskField } from '../../common/MaskField'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'

const ViewTitle = () => {
  const {
    filterValues: { eventId },
  } = useListContext()
  const hasAuthority = useHasAuthority()
  const { data: event } = useQuery(
    {
      resource: ResourceName.EVENTS,
      type: GET_ONE,
      payload: {
        id: eventId,
      },
    },
    {
      enabled: !!eventId && hasAuthority(Authority.VIEW_EVENTS),
    },
  )

  const t = useTranslate()

  return (
    <span>
      {t(`resources.${ResourceName.STATS_EVENT_ENTRANCES_VIEW}.name`)}
      {event &&
        `: ${event?.name} ${
          event?.dateStart && new Date(event.dateStart).toLocaleString()
        }`}
    </span>
  )
}

const EventEntrancesViewExport = (
  eventEntranceRows: EventEntrance[],
  event?: EventDto,
) => {
  jsonExport(eventEntranceRows, (err, csv) => {
    downloadCSV(
      csv,
      `event-entrances ${
        event &&
        `(${event?.name} ${
          event?.dateStart && new Date(event.dateStart).toLocaleString()
        })`
      }`,
    )
  })
}

const EventEntrancesViewActions = (props: ListActionsProps) => {
  const { className, filters: filtersProp, ...rest } = props
  const { currentSort, displayedFilters, filterValues, showFilter, total } =
    useListContext(props)
  const resource = ResourceName.STATS_EVENT_ENTRANCES_VIEW
  const filters = useContext(FilterContext) || filtersProp
  const {
    filterValues: { eventId },
  } = useListContext()
  const hasAuthority = useHasAuthority()

  const { data: event } = useQuery(
    {
      resource: ResourceName.EVENTS,
      type: GET_ONE,
      payload: {
        id: eventId,
      },
    },
    {
      enabled: !!eventId && hasAuthority(Authority.VIEW_EVENTS),
    },
  )

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      <ExportButton
        disabled={total === 0 || total >= 1000000}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        exporter={(data) => EventEntrancesViewExport(data, event)}
        maxResults={1000000}
      />
    </TopToolbar>
  )
}

export const EventEntrancesView = (props: ListProps) => (
  <List
    title={<ViewTitle />}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    sort={{ field: 'entranceDateTime', order: 'DESC' }}
    {...props}
    bulkActionButtons={false}
    actions={<EventEntrancesViewActions />}
  >
    <Datagrid>
      <TextField source="id" label="Id" />
      <TextField source="customerName" />
      <TextField source="customerSurname" />
      <MaskField source="customerPESEL" />
      <DateField
        source="entranceDateTime"
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
      />
      <TextField source="entranceDevice" />
    </Datagrid>
  </List>
)
