import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import List from '../../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../../common/FilteredReferenceInput'
import FilterProps from '../../../common/FilterProps'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { CameraServerDto } from '../../../../core/dto/device/camera/camera-servers/camera-server.dto'

const CameraFilters = (props: FilterProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Filter {...props}>
      <TextInput source="description" alwaysOn />
      {hasAuthority(Authority.VIEW_CAMERA_SERVERS) && (
        <FilteredReferenceInput
          source="serverId"
          reference={ResourceName.CAMERA_SERVERS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

export const CameraList = ({ ...props }: ListProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
      filters={<CameraFilters />}
      bulkActionButtons={false}
    >
      <Datagrid
        size="small"
        rowClick={(id) => `/${ResourceName.CAMERAS}/${id}/show`}
      >
        <TextField source="id" label="ID" />
        <TextField source="description" />
        {!xSmallScreen && <TextField source="serverSideName" />}
        {hasAuthority(Authority.VIEW_CAMERA_SERVERS) && (
          <ReferenceField
            source="serverId"
            reference={ResourceName.CAMERA_SERVERS}
            link={false}
          >
            <FunctionField<CameraServerDto>
              source="name"
              render={(server?: CameraServerDto) =>
                `${server?.id}. ${server?.name}`
              }
            />
          </ReferenceField>
        )}
      </Datagrid>
    </List>
  )
}
