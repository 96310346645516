const mapping: Record<string, string> = {
  id: 'ad=>ad.Id',
  activeFrom: 'ad=>ad.ActiveFrom',
  activeTo: 'ad=>ad.ActiveTo',
  timeFrom: 'ad=>ad.TimeFrom',
  timeTo: 'ad=>ad.TimeTo',
  type: 'ad=>ad.Type',
}

export const mapSortAccessDatesParam = (key: string) => mapping[key]
