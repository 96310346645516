import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import { useListContext } from 'ra-core'
import React, { cloneElement, useContext } from 'react'
import {
  Datagrid,
  Filter,
  FilterButton,
  FilterContext,
  FunctionField,
  List,
  ListActionsProps,
  ListProps,
  Pagination,
  required,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import { BlockDto } from '../../../core/dto/block.dto'
import { BlockType } from '../../../core/enum/BlockType'
import {
  EditableDatagrid,
  RowForm,
} from '../../../lib/@react-admin/ra-editable-datagrid'
import ParameterizedCreateButton from '../../common/ParameterizedCreateButton'
import Empty from '../Empty'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { ResourceName } from '../../../core/ResourceName'

const ListFilters = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      <TextInput source="id" />
      {hasAuthority(Authority.VIEW_TRIBUNES) && (
        <FilteredReferenceInput
          source="tribuneId"
          reference={ResourceName.TRIBUNES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
      <TextInput source="code" />
    </Filter>
  )
}

const EditForm = ({ ...props }) => (
  <RowForm {...props}>
    <TextField source="id" />
    <TextInput source="name" validate={required()} />
    <TextField source="tribuneId" />
    <SelectInput
      source="type"
      choices={Object.entries(BlockType).map(([value]) => ({
        id: value,
        name: `resources.enums.blockType.${value}`,
      }))}
    />
    <TextInput source="code" />
  </RowForm>
)

const BlocksGrid = ({ ...props }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  if (hasAuthority(Authority.EDIT_BLOCKS))
    return (
      <EditableDatagrid
        {...props}
        size="small"
        editForm={<EditForm />}
        noDelete
      >
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="tribuneId" />
        <FunctionField<BlockDto>
          source="type"
          render={(r?: BlockDto) =>
            translate(`resources.enums.blockType.${r?.type}`)
          }
        />
        <TextField source="code" />
      </EditableDatagrid>
    )

  return (
    <Datagrid {...props} size="small">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="tribuneId" />
      <FunctionField<BlockDto>
        source="type"
        render={(r?: BlockDto) =>
          translate(`resources.enums.blockType.${r?.type}`)
        }
      />
      <TextField source="code" />
    </Datagrid>
  )
}

interface BlockListActions extends ListActionsProps {
  objectId?: number
}

const ListActions = ({ objectId, ...props }: BlockListActions) => {
  const { filters: filtersProp, ...rest } = props
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(rest)
  const resource = ResourceName.BLOCKS
  const filters = useContext(FilterContext) || filtersProp

  return (
    <TopToolbar {...rest}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      <ParameterizedCreateButton
        searchParams={{ objectId }}
        disabled={!hasAuthority(Authority.CREATE_BLOCKS)}
      />
    </TopToolbar>
  )
}

const EmptyView = ({ objectId }: { objectId?: number }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <Box>
      <Empty text={translate('resources.blocks.empty')} />
      <TopToolbar>
        <ParameterizedCreateButton
          searchParams={{ objectId }}
          disabled={!hasAuthority(Authority.CREATE_BLOCKS)}
        />
      </TopToolbar>
    </Box>
  )
}

interface BlockListProps extends ListProps {
  objectId?: number
}

const BlocksList = ({ objectId, ...props }: BlockListProps) => {
  const { basePath } = useListContext()

  return (
    <List
      {...props}
      basePath={basePath}
      exporter={false}
      filters={<ListFilters />}
      actions={<ListActions objectId={objectId} />}
      bulkActionButtons={false}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      empty={<EmptyView objectId={objectId} />}
    >
      <BlocksGrid />
    </List>
  )
}

export default BlocksList
