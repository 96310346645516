import { mapping as verifierDeviceBaseMapper } from '../common/verifier-device-base/verifier-device-filter.mapper'

const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  ...verifierDeviceBaseMapper,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
