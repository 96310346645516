import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  useTranslate,
  TitleProps,
  SimpleForm,
  TextField,
  TextInput,
  SaveButton,
  ShowButton,
  Toolbar,
  ToolbarProps,
  BooleanInput,
  DateField,
  SelectInput,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DetectorDto } from '../../../core/dto/device/detector/detector.dto'
import { HardwareVersions } from '../../../core/enum/HardwareVersions'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const validateDetectorEdit = (values: DetectorDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (values.hardwareVersion === HardwareVersions.MODULO5 && !values.tsCanId) {
    errors.tsCanId = 'ra.validation.required'
  }
  return errors
}

interface EngineTitleProps extends TitleProps {
  record?: DetectorDto
}

const EditTitle = (data: EngineTitleProps) => {
  const { record } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.detectors.titles.edit')}: {record?.id}.{' '}
      {record?.name}{' '}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const Detector = ({ ...props }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <SimpleForm
      {...props}
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateDetectorEdit}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" isRequired />
      <BooleanInput source="isWatched" />
      <DateField
        source="lastRefresh"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
      />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          source="categoryId"
          defaultValue={null}
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_CAMERAS) && (
        <FilteredReferenceInput
          source="cameraId"
          defaultValue={null}
          reference={ResourceName.CAMERAS}
          sort={{ field: 'description', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'description',
            showFilter: true,
          }}
        />
      )}
      <SelectInput
        source="hardwareVersion"
        choices={Object.entries(HardwareVersions)
          .filter((hw) => hw[1] !== HardwareVersions.SPOM4)
          .map(([value]) => ({
            id: value,
            name: `resources.enums.hardwareVersions.${value}`,
          }))}
      />
      {hasAuthority(Authority.VIEW_TS_CANS) && (
        <FilteredReferenceInput
          source="tsCanId"
          defaultValue={null}
          reference={ResourceName.TS_CANS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </SimpleForm>
  )
}

export const DetectorEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <Detector />
  </Edit>
)
