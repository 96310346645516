import { createStyles, makeStyles, Theme } from '@material-ui/core'
import * as React from 'react'
import { useShowContext, useTranslate } from 'react-admin'
import { useHistory } from 'react-router'
import { Authority } from '../../../core/auth/Authority'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.white,
      margin: '2px',
    },
  }),
)

export const ToggleBookingAccessButton = () => {
  const classes = useStyles()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const translate = useTranslate()
  const history = useHistory()

  const onClick = () => {
    history.push('show/accesses')
  }

  return (
    <Button
      disabled={!hasAuthority(Authority.VIEW_ACCESS_USINGS) || loading}
      className={classes.button}
      variant="contained"
      onClick={onClick}
      label={translate('resources.bookings.actions.unblockAccess')}
      useSmallVersionBreakpoint={false}
    />
  )
}
