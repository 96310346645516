import { Autorenew } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  useShowContext,
  useRefresh,
  sanitizeFetchType,
  useNotify,
  useMutation,
  useTranslate,
} from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { SetWorkingModeDialog } from '../TerminalDialogs'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { TerminalDto } from '../../../../core/dto/device/terminals/terminal.dto'
import { TerminalSetWorkingModeRequest } from '../../../../core/enum/TerminalSetWorkingModeRequest'

export const TerminalSetWorkingModeButton = ({ ...props }) => {
  const { asRecurringJob } = props
  const { loading, record: terminal } = useShowContext<TerminalDto>()
  const translate = useTranslate()
  const notify = useNotify()
  const [workingModeDialogOpen, setWorkingModeDialogOpen] = useState(false)
  const [workingMode, setWorkingMode] = useState<
    TerminalSetWorkingModeRequest | undefined
  >(undefined)
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  const handleSetWorkingModeDialog = () => {
    setWorkingModeDialogOpen(true)
  }

  const handleWorkingMode = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (!asRecurringJob)
      await mutate(
        {
          type: sanitizeFetchType('setWorkingMode'),
          resource: ResourceName.TERMINALS,
          payload: {
            terminalId: terminal?.id,
            workingMode,
          },
        },
        {
          returnPromise: true,
          onFailure: (err) => notify(err?.message, 'error'),
        },
      )
    else
      await mutate(
        {
          type: sanitizeFetchType('setWorkingMode'),
          resource: ResourceName.TERMINALS,
          payload: {
            terminalId: terminal?.id,
            workingMode,
            recurringJobName,
            cronExpression,
          },
        },
        {
          returnPromise: true,
          onFailure: (err) => notify(err?.message, 'error'),
        },
      )
    refresh()
  }

  return (
    <>
      <SetWorkingModeDialog
        open={workingModeDialogOpen}
        close={() => setWorkingModeDialogOpen(false)}
        workingMode={workingMode}
        setWorkingMode={setWorkingMode}
        handleWorkingMode={handleWorkingMode}
        asRecurringJob={asRecurringJob}
      />
      <Button
        size="small"
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        label={
          !asRecurringJob
            ? 'resources.terminals.actions.setWorkingMode'
            : translate('common.recurring-job.buttons.create-recurring-job')
        }
        useSmallVersionBreakpoint={false}
        onClick={handleSetWorkingModeDialog}
        disabled={
          !hasAuthority(Authority.TERMINALS_SET_WORKING_MODE) ||
          loading ||
          mutationLoading
        }
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
      />
    </>
  )
}
