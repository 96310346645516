import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
  Identifier,
} from 'react-admin'
import { ENTRANCES_URL } from '../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  recurringJobParamsComposer,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import {
  TicketAPIGetListParams,
  TicketAPIGetManyRefrenceParams,
} from '../common/ticket-api-get-list.params'
import { EntranceDto } from '../dto/entrance.dto'
import { EntranceDevicesDto } from '../dto/entrances-devices.dto'
import filterMapper from './entrance-filter.mapper'
import { mapSortEntranceParam } from './entrance-sort.mapper'
import { DataProviderExtensionResult } from '../common/data-provider'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'
import { RecurringJobCreationParamsDto } from '../dto/recurring-job-creation-params.dto'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<EntranceDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortEntranceParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<EntranceDto[]>(
      `${ENTRANCES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<EntranceDto>> => {
    const data = await get<EntranceDto>(ENTRANCES_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<EntranceDto>> => {
    const { data } = await getWithPagination<EntranceDto[]>(
      `${ENTRANCES_URL}/GetByConditions/`,
      `e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  getManyReference: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetManyRefrenceParams,
  ): Promise<GetManyReferenceResult<EntranceDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortEntranceParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<EntranceDto[]>(
      `${ENTRANCES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateEntranceRequest>,
  ): Promise<CreateResult<EntranceDto>> => {
    const entrance = await post<CreateEntranceRequest[], EntranceDto[]>(
      ENTRANCES_URL,
      [data],
    )
    return {
      data: entrance[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<EntranceDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      EntranceDto
    >(ENTRANCES_URL, {
      id,
      note: data.note !== previousData.name ? data.note : undefined,
    })
    return Promise.resolve({ data: update })
  },
  attachDevices: async (
    resource: string,
    { entranceId, verifierDevicesIds, ...rest }: EntranceDevicesDto,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const attachDevices = await put<
      EntranceDevicesDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${ENTRANCES_URL}/AttachDevices${query ? `?${query}` : ''}`, {
      entranceId,
      verifierDevicesIds,
    })
    if ((attachDevices as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: attachDevices as RecurringJobDetailsDto,
      }
    return {
      data: attachDevices,
    }
  },
  detachDevices: async (
    resource: string,
    { entranceId, verifierDevicesIds, ...rest }: EntranceDevicesDto,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const detachDevices = await put<
      EntranceDevicesDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${ENTRANCES_URL}/DetachDevices${query ? `?${query}` : ''}`, {
      entranceId,
      verifierDevicesIds,
    })
    if ((detachDevices as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: detachDevices as RecurringJobDetailsDto,
      }
    return {
      data: detachDevices,
    }
  },
  clearAllEntranceDeviceRelations: async (): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const entranceDevices = await put<
      undefined,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${ENTRANCES_URL}/ClearAllEntranceDeviceRelations`, undefined)
    if ((entranceDevices as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: entranceDevices as RecurringJobDetailsDto,
      }
    return {
      data: entranceDevices,
    }
  },
} as EntranceDataProvider

interface EntranceDataProvider extends DataProvider {
  attachDevices: (
    resource: string,
    params: EntranceDevicesDto,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  detachDevices: (
    resource: string,
    params: EntranceDevicesDto,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  clearAllEntranceDeviceRelations: () => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >
}

export interface CreateEntranceRequest {
  readonly description: string
}

interface ChangeStateResponse {
  jobId: Identifier
}

export default provider
