import { MutableRefObject, useEffect } from 'react'

export const useOutsideClick = (
  ref: MutableRefObject<any>,
  method: (() => void) | false,
) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && method) {
        method?.()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [method, ref])
}
