import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  UpdateParams,
  UpdateResult,
  Identifier,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import { SEATS_URL } from '../api-urls'
import { get, getWithPagination, post, patch } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { SeatExtendedDto } from '../dto/seat/seat-extended.dto'
import { SeatDto } from '../dto/seat/seat.dto'
import { SeatCreateParams } from './seats-create.params'
import filterMapper from './seats-filter.mapper'
import { mapSortSeatParam } from './seats-sort.mapper'

type CreateSeatRequest = Array<SeatCreateParams>

const extendSeatDto = (seats: SeatDto[]): SeatExtendedDto[] =>
  seats.map((seat) => ({
    ...seat,
    combinedFullAlias: `${seat.rowAlias} / ${seat.numberAlias}`,
  })) as SeatExtendedDto[]

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<SeatExtendedDto>> => {
    const filterParams = `v=>${filterParamsComposer('v', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortSeatParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<SeatDto[]>(`${SEATS_URL}/GetByConditions`, path)

    return Promise.resolve({
      data: extendSeatDto(data),
      total,
    })
  },
  getOne: async (
    resource: string,
    { id: seatId }: GetOneParams,
  ): Promise<GetOneResult<SeatExtendedDto>> => {
    const seat = await get<SeatDto>(SEATS_URL, `/${seatId}`)
    return {
      data: extendSeatDto([seat])?.[0],
    }
  },
  create: async (
    resource,
    { data }: CreateParams<SeatCreateParams>,
  ): Promise<CreateResult> => {
    const request: CreateSeatRequest = [data]
    await post<CreateSeatRequest, SeatDto[]>(SEATS_URL, request)
    return Promise.resolve({
      data: { id: 0 }, // need to return something
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<SeatDto>,
  ): Promise<UpdateResult<SeatExtendedDto>> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, SeatDto>(
      SEATS_URL,
      {
        id,
        rowAlias:
          data.rowAlias !== previousData.rowAlias ? data.rowAlias : undefined,
        numberAlias:
          data.numberAlias !== previousData.numberAlias
            ? data.numberAlias
            : undefined,
        blockId:
          data.blockId !== previousData.blockId ? data.blockId : undefined,
      },
    )
    return Promise.resolve({ data: extendSeatDto([update])?.[0] })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<SeatExtendedDto>> => {
    const data = await get<SeatDto[]>(
      SEATS_URL,
      `/GetByConditions/s=>new int[] {${ids.toString()}}.Contains(s.Id)`,
    )
    return Promise.resolve({ data: extendSeatDto(data) })
  },
} as DataProvider

export default provider
