import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { CAMERAS_URL } from '../../../api-urls'
import { DataProviderExtensionResult } from '../../../common/data-provider'
import { DateTime } from '../../../common/date-time'
import {
  get,
  getBlob,
  getWithPagination,
  patch,
  post,
} from '../../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../../common/ticket-api-get-list.params'
import { CameraDto } from '../../../dto/device/camera/cameras/camera.dto'
import { getDeviceCategoryChildren } from '../../devices.utils'
import filterMapper from './camera-filter.mapper'
import { mapSortCameraParam } from './camera-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<CameraDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `c=>${filterParamsComposer(
      'c',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortCameraParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<CameraDto[]>(
      `${CAMERAS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<CameraDto>> => {
    const data = await get<CameraDto>(CAMERAS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<CameraDto>> => {
    const data = await get<CameraDto[]>(
      `${CAMERAS_URL}/GetByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<CameraDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, CameraDto>(
      CAMERAS_URL,
      {
        id,
        description:
          data.description !== previousData.description
            ? data.description
            : undefined,
        serverSideName:
          data.serverSideName !== previousData.serverSideName
            ? data.serverSideName
            : undefined,
        serverId:
          data.serverId !== previousData.serverId ? data.serverId : undefined,
        devicesIds:
          data.devicesIds !== previousData.devicesIds
            ? data.devicesIds
            : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateCameraRequest>,
  ): Promise<CreateResult<CameraDto>> => {
    const camera = await post<CreateCameraRequest[], CameraDto[]>(CAMERAS_URL, [
      data,
    ])
    return {
      data: camera[0],
    }
  },
  openStream: async (
    resource: string,
    { cameraId, streamStart, logId }: OpenStreamParams,
  ): Promise<DataProviderExtensionResult<OpenStreamResponse>> => {
    const queryParams = Array<string>()
    if (streamStart) queryParams.push(`streamStart=${streamStart}`)
    if (logId) queryParams.push(`logId=${logId}`)

    const path =
      queryParams.length > 0 ? queryParams.reduce((p, c) => `${p}&${c}`) : null

    const openStream = await get<OpenStreamResponse>(
      `${CAMERAS_URL}/OpenStream`,
      `/${cameraId}${path != null ? `/?${path}` : ''}`,
    )
    return {
      data: openStream,
    }
  },
  downloadClip: async (
    resource: string,
    { cameraId, streamStart }: DownloadClipParams,
  ): Promise<DataProviderExtensionResult<any>> => {
    const queryParams = Array<string>()
    if (streamStart) queryParams.push(`streamStart=${streamStart}`)

    const path =
      queryParams.length > 0 ? queryParams.reduce((p, c) => `${p}&${c}`) : null

    const downloadClip = await getBlob(
      `${CAMERAS_URL}/DownloadClip`,
      `/${cameraId}${path != null ? `/?${path}` : ''}`,
    )
    return {
      data: {
        blob: downloadClip,
      },
    }
  },
} as CameraDataProvider

interface CameraDataProvider extends DataProvider {
  openStream: (
    resource: string,
    params: OpenStreamParams,
  ) => Promise<DataProviderExtensionResult<OpenStreamResponse>>

  downloadClip: (
    resource: string,
    params: OpenStreamParams,
  ) => Promise<DataProviderExtensionResult<any>>
}

export interface OpenStreamParams {
  cameraId: number
  streamStart?: DateTime
  logId?: number
}

export interface OpenStreamResponse {
  cameraId: number
  jobId: string
  streamPath: string
}

export interface DownloadClipParams {
  cameraId: number
  streamStart?: DateTime
}

export interface CreateCameraRequest {
  readonly description: string
  readonly serverSideName: string
  readonly serverId: number
}

export default provider
