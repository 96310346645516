import { DataProvider } from 'react-admin'
import { DetectorDto } from '../../dto/device/detector/detector.dto'

const fakeDetectors: DetectorDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeDetectors, total: fakeDetectors.length }),
} as DataProvider

export default provider
