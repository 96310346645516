import { Grid, withStyles } from '@material-ui/core'

export const VCenterGrid = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
})(Grid)

export const VHCenterGrid = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
})(Grid)
