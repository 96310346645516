import { Box, Divider, Grid } from '@material-ui/core'
import React, { ReactElement } from 'react'
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ToggleType } from '../../../core/devices/doors/door-lock-button'
import { ResourceName } from '../../../core/ResourceName'
import DropdownButton from '../../common/DropdownButton'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DeviceStatusesTab } from '../common/modules/ModuleStatus'
import { DoorDto } from '../../../core/dto/device/doors/door.dto'
import { DoorForceReportStateButton } from './door-show/DoorForceReportStateButton'
import { DoorResetModulesButton } from './door-show/DoorResetModulesButton'
import { DoorStandbyModeButton } from './door-show/DoorStandbyModeButton'
import { DoorLockButton } from './door-show/DoorLockButton'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'
import { DoorSetStateButton } from './door-show/DoorStateButton'

interface DoorTitleProps extends TitleProps {
  record?: DoorDto
}

const ShowTitle = (data: DoorTitleProps) => {
  const { record: door } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.doors.titles.show')}: {door?.id}. {door?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: door } = useShowContext<DoorDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton mainAction={<DoorSetStateButton />}>
              <DoorSetStateButton asRecurringJob />
            </DropdownButton>
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton mainAction={<DoorStandbyModeButton />}>
              <DoorStandbyModeButton asRecurringJob />
            </DropdownButton>
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton mainAction={<DoorLockButton />}>
              <DoorLockButton
                asRecurringJob
                recurringMethod={ToggleType.LOCK}
              />
              <DoorLockButton
                asRecurringJob
                recurringMethod={ToggleType.UNLOCK}
              />
            </DropdownButton>
          </Grid>
          <Grid item>
            <EditButton
              basePath={`/${ResourceName.DOORS}`}
              record={door}
              variant="contained"
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_DOORS)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const SetStateActions = () => (
  <>
    <Box p={1} display="block" textAlign="right">
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item>
          <DoorForceReportStateButton />
        </Grid>
        <Grid item>
          <DoorResetModulesButton />
        </Grid>
      </Grid>
    </Box>
  </>
)

const DoorTabs = ({ ...props }) => {
  const translate = useTranslate()
  const { record: door } = useShowContext<DoorDto>()
  const hasAuthority = useHasAuthority()

  const eventsFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.DOORS,
    })

  const entrancesFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="note" alwaysOn />],
      resource: ResourceName.DOORS,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.doors.tabs.general">
        <TextField label="ID" source="id" />
        <TextField source="name" />
        <FunctionField<DoorDto>
          source="isWatched"
          render={(record?: DoorDto) => (
            <div>
              {record?.isWatched
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <DateField
          source="lastRefresh"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        <FunctionField<DoorDto>
          source="direction"
          render={(record?: DoorDto) => (
            <div>
              {translate(
                `resources.enums.deviceDirection.${record?.direction}`,
              )}
            </div>
          )}
        />
        {door?.categoryId && hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link="show"
          >
            <TextField source="hierarchyString" />
          </ReferenceField>
        )}
        {door?.cameraId && hasAuthority(Authority.VIEW_CAMERAS) && (
          <ReferenceField
            source="cameraId"
            reference={ResourceName.CAMERAS}
            link="show"
          >
            <TextField source="description" />
          </ReferenceField>
        )}
        <FunctionField<DoorDto>
          source="stanbyMode"
          render={(record?: DoorDto) => (
            <div>
              {translate(`resources.enums.standbyMode.${record?.stanbyMode}`)}
            </div>
          )}
        />
        {door?.disabledId && (
          <ReferenceField
            source="disabledId"
            label="resources.doors.fields.disabled"
            reference={ResourceName.VERIFIER_DEVICE_DISABLED}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <FunctionField<DoorDto>
          source="onlineInfixCeck"
          render={(record?: DoorDto) => (
            <div>
              {record?.onlineInfixCeck
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <FunctionField<DoorDto>
          source="multiplicity"
          render={(record?: DoorDto) => (
            <div>
              {translate(
                `resources.enums.multiplicities.${record?.multiplicity}`,
              )}
            </div>
          )}
        />
        {door?.tsCanId && hasAuthority(Authority.VIEW_TS_CANS) && (
          <ReferenceField
            source="tsCanId"
            reference={ResourceName.TS_CANS}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {door?.supervisorId && hasAuthority(Authority.VIEW_PALMS) && (
          <ReferenceField
            source="supervisorId"
            reference={ResourceName.PALMS}
            link="show"
          >
            <FunctionField
              source="name"
              render={(palm) => `${palm?.id}. ${palm?.name}`}
            />
          </ReferenceField>
        )}
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_EVENTS) && (
        <Tab path="events" label={translate('resources.doors.tabs.events')}>
          <>
            <RelationTab<DoorDto>
              resource={ResourceName.EVENTS}
              source="eventsIds"
              mode={hasAuthority(Authority.EDIT_DOORS) ? 'edit' : 'show'}
              attachMethod="attachEvents"
              detachMethod="detachEvents"
              attachRequestPayload={(r, ids) => ({
                doorId: r.id,
                eventsIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                doorId: r.id,
                eventsIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVerifierDeviceId === true
              }
              filters={eventsFilters() as ReactElement[]}
              filterDefaultValues={{
                verifierDeviceId: door?.id,
                filterVerifierDeviceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ENTRANCES) && (
        <Tab
          path="entrances"
          label={translate('resources.doors.tabs.entrances')}
        >
          <>
            <RelationTab<DoorDto>
              resource={ResourceName.ENTRANCES}
              source="entrancesIds"
              mode={hasAuthority(Authority.EDIT_DOORS) ? 'edit' : 'show'}
              attachMethod="attachEntrances"
              detachMethod="detachEntrances"
              attachRequestPayload={(r, ids) => ({
                doorId: r.id,
                entrancesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                doorId: r.id,
                entrancesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVerifierDeviceId === true
              }
              filters={entrancesFilters() as ReactElement[]}
              filterDefaultValues={{
                verifierDeviceId: door?.id,
                filterVerifierDeviceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="note" />
            </RelationTab>
          </>
        </Tab>
      )}
      {door?.jsonStatus && (
        <Tab label="resources.doors.tabs.statuses" path="statuses">
          <DeviceStatusesTab
            jsonStatus={door.jsonStatus}
            deviceType={DeviceTypes.DOORS}
          />
          <SetStateActions />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const DoorShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <DoorTabs />
  </Show>
)
