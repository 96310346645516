import { DataProvider } from 'react-admin'
import { ServiceLinkDto } from '../../dto/service-link.dto'

const fakeServiceLinkList: ServiceLinkDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeServiceLinkList,
      total: fakeServiceLinkList.length,
    }),
} as DataProvider

export default provider
