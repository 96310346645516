import { useMediaQuery, useTheme } from '@material-ui/core'
import React, { useContext } from 'react'
import {
  CreateButton,
  Datagrid,
  Filter,
  FilterButton,
  FilterContext,
  FunctionField,
  ListProps,
  Pagination,
  ReferenceField,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { ResourceName } from '../../core/ResourceName'
import cloneOrCreateElement from '../common/cloneOrCreateElement'
import List from '../common/customized-ra-components/List'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { SyncCardsButton } from './service-link-show/SyncCardsButton'
import { EventDto } from '../../core/dto/event/event.dto'

const ServiceLinkFilters = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="apiUrl" alwaysOn />
  </Filter>
)

const ServiceLinkActions = ({ ...props }) => {
  const { className, filters: filtersProp, ...rest } = props
  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const resource = ResourceName.SYNC_LOGS
  const filters = useContext(FilterContext) || filtersProp

  const hasAuthority = useHasAuthority()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {hasAuthority(Authority.SYNC_SERVICE_LINK) && <SyncCardsButton />}
      {filtersProp
        ? cloneOrCreateElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {hasAuthority(Authority.CREATE_SERVICE_LINK) && <CreateButton />}
    </TopToolbar>
  )
}

export const ServiceLinkList = (props: ListProps) => {
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <List
      {...props}
      exporter={false}
      filters={<ServiceLinkFilters />}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
      bulkActionButtons={false}
      actions={<ServiceLinkActions />}
      empty={false}
    >
      <Datagrid size="small" rowClick="show">
        {!smallScreen && <TextField source="id" label="ID" />}
        <TextField source="serviceId" />
        <FunctionField
          source="serviceType"
          render={(record) =>
            translate(`resources.enums.serviceType.${record?.serviceType}`)
          }
        />
        {hasAuthority(Authority.VIEW_EVENTS) && (
          <ReferenceField
            source="eventId"
            reference={ResourceName.EVENTS}
            link="show"
          >
            <FunctionField<EventDto>
              source="name"
              render={(event?: EventDto) => `${event?.id}. ${event?.name}`}
            />
          </ReferenceField>
        )}
        <TextField source="apiUrl" />
      </Datagrid>
    </List>
  )
}
