import * as React from 'react';
import { Button } from 'react-admin';
import { Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
/**
 * A toolbar tailored for custom fields
 * @param {FieldFormToolbarProps} props
 * @param {FieldDefinition} field The edited field which may be an empty object for new ones.
 * @param {VoidFunction} onCancel An event handler called to cancel either the edition or creation.
 * @param {VoidFunction} onDelete An event handler called to delete the current field (only for existing ones).
 */
export var FieldFormToolbar = function (_a) {
    var field = _a.field, onCancel = _a.onCancel, onDelete = _a.onDelete;
    var classes = useStyles();
    return (
    // TODO: remove once react-admin 16.1 is out
    // @ts-ignore
    React.createElement(Toolbar, { className: classes.toolbar },
        React.createElement(Button, { variant: "contained", type: "submit", label: "Save" },
            React.createElement(SaveIcon, null)),
        React.createElement("span", { className: classes.spacer }),
        !!(field === null || field === void 0 ? void 0 : field.id) && (React.createElement(Button, { label: "ra.action.delete", onClick: onDelete })),
        React.createElement(Button, { label: "ra.action.cancel", onClick: onCancel })));
};
var useStyles = makeStyles(function (theme) { return ({
    toolbar: {
        backgroundColor: theme.palette.type === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
    },
    spacer: {
        flexGrow: 1,
    },
}); });
