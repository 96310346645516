import { DataProvider } from 'react-admin'
import { LogSchemeDataDto } from '../../dto/log-scheme-data.dto'

const fakeLogSchemeData: LogSchemeDataDto[] = [
  {
    id: 1,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 2,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 3,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 4,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 5,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 6,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 7,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 8,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 9,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
  {
    id: 10,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    color: '#fff000',
    schemeId: 1,
    hiddenInSimpleView: false,
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeLogSchemeData,
      total: fakeLogSchemeData.length,
    }),
} as DataProvider

export default provider
