import { AuthProvider } from 'react-admin'

const authProvider: AuthProvider = {
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  checkError: () => Promise.resolve(),
  checkAuth: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(new Set([])),
  getIdentity: () =>
    Promise.resolve({
      id: 'no-auth',
      fullName: 'no-auth',
    }),
}

export default authProvider
