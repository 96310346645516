import {
  CreateParams,
  CreateResult,
  GetListResult,
  GetManyParams,
  GetManyResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { TICKETS_URL } from '../api-urls'
import {
  get,
  getWithPagination,
  patchWithAuditComment,
  post,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { TicketDto } from '../dto/ticket.dto'
import { TicketType } from '../enum/TicketType'
import filterMapper from './ticket-filter.mapper'
import { mapSortTicketParam } from './ticket-sort.mapper'
import { CreateManyParams, ImportCsvDataProvider } from '../csv'
import { BookingType } from '../enum/BookingType'
import { BookingDisabledOption } from '../enum/BookingDisabledOption'
import { RecurringJobCreationParamsDto } from '../dto/recurring-job-creation-params.dto'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'
import { AuditCommentParamsDto } from '../dto/audit-comment-params.dto'

const provider = {
  getList: async (
    resource: string,
    { filter, pagination, sort }: TicketAPIGetListParams,
  ): Promise<GetListResult<TicketDto>> => {
    const filterParams = `t=>${filterParamsComposer('t', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortTicketParam)
    const path = `/${filterParams}${pathParams ? `?${pathParams}` : ''}`

    const {
      data,
      range: { total },
    } = await getWithPagination<TicketDto[]>(
      `${TICKETS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<TicketDto>> => {
    const filterParams = `t=>${filterParamsComposer(
      't',
      { ids },
      filterMapper,
    )}`
    const data = await get<TicketDto[]>(
      `${TICKETS_URL}/GetByConditions/`,
      filterParams,
    )
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<UpdateTicketRARequest>,
  ): Promise<UpdateResult<TicketDto | RecurringJobDetailsDto>> => {
    const additionalParams = data as
      | RecurringJobCreationParamsDto
      | AuditCommentParamsDto
    const isRecurringJob = (additionalParams as RecurringJobCreationParamsDto)
      ?.recurringJobName
    const update = await patchWithAuditComment<
      UpdateTicketRequest,
      TicketDto | RecurringJobDetailsDto
    >(
      TICKETS_URL,
      {
        id,
        ticketCode:
          data.ticketCode !== previousData.ticketCode || isRecurringJob
            ? data.ticketCode
            : undefined,
        ticketType:
          data.ticketType !== previousData.ticketType || isRecurringJob
            ? data.ticketType
            : undefined,
        active:
          data.active !== previousData.active || isRecurringJob
            ? data.active
            : undefined,
        bookingId:
          data.bookingId !== previousData.bookingId || isRecurringJob
            ? data.bookingId
            : undefined,
      },
      additionalParams && additionalParams,
    )
    if ((update as RecurringJobDetailsDto)?.recurringJobId)
      return Promise.resolve({ data: previousData as TicketDto })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource,
    { data }: CreateParams<CreateTicketRequest>,
  ): Promise<CreateResult> => {
    const ticket = await post<CreateTicketRequest[], TicketDto[]>(TICKETS_URL, [
      data,
    ])
    return Promise.resolve({
      data: ticket[0],
    })
  },
  createMany: async (
    resource,
    { data }: CreateManyParams<ImportTicketRequest>,
  ): Promise<any> => {
    const job = await post<ImportTicketRequest[], TicketDto[]>(
      `${TICKETS_URL}/ImportAsyncJob`,
      data,
    )
    return { data: job }
  },
} as ImportCsvDataProvider

interface CreateTicketRequest {
  readonly ticketCode: string
  readonly ticketType: TicketType
  readonly active: boolean
  readonly bookingId: number
}

interface ImportTicketRequest {
  readonly customerFirstName: string
  readonly customerLastname: string
  readonly customerPesel: string
  readonly customerDocumentNumber: string
  readonly ticketCode: string
  readonly ticketType: TicketType
  readonly bookingType: BookingType
  readonly disabled: BookingDisabledOption
  readonly blockId: number
  readonly variantId: number
  readonly eventId: number
}

interface UpdateTicketRequest {
  readonly id: Identifier
  readonly ticketCode?: string
  readonly ticketType?: TicketType
  readonly active?: boolean
  readonly bookingId?: number
}

interface UpdateTicketRARequest extends UpdateTicketRequest {
  readonly auditComment: string
}

export default provider
