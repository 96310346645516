import { DataProvider } from 'react-admin'
import { VariantDto } from '../../dto/variant.dto'

const fakeVariants: VariantDto[] = [
  {
    id: 1,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 2,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 3,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 4,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 5,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 6,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 7,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 8,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 9,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
  {
    id: 10,
    name: '',
    active: false,
    bookingsIds: [],
    accessesIds: [],
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeVariants, total: fakeVariants.length }),
} as DataProvider

export default provider
