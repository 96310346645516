import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  AccountBalanceWallet,
  ArrowForward,
  CenterFocusStrong,
  Keyboard,
  MeetingRoom,
  PhoneAndroid,
  Receipt,
  Storage,
} from '@material-ui/icons'
import jsonExport from 'jsonexport/dist'
import React, { cloneElement, useContext } from 'react'
import {
  BooleanInput,
  Datagrid,
  DateField,
  downloadCSV,
  ExportButton,
  Filter,
  FilterButton,
  FilterContext,
  FunctionField,
  ListActionsProps,
  ListProps,
  Pagination,
  Record,
  sanitizeListRestProps,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin'
import { useHistory } from 'react-router'
import { Authority } from '../../core/auth/Authority'
import { getContrastColor } from '../../core/common/visual.utils'
import { DeviceBaseWithTypeInfoDto } from '../../core/dto/device/common/device-base/device-base-with-type-info.dto'
import { ExtendedLog } from '../../core/dto/extended-log'
import { DeviceTypes } from '../../core/enum/DeviceTypes'
import { ResourceName } from '../../core/ResourceName'
import List from '../common/customized-ra-components/List'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { OpenCameraPreviewDialogButton } from '../devices/camera/cameras/CameraPreviewDialog'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { DateFilterInput } from './DateFilterInput'
import { VCenterGrid, VHCenterGrid } from './LogsComponents'

interface LogExpandProps {
  readonly noBooking?: boolean
  readonly noCamera?: boolean
  readonly record?: ExtendedLog
}

interface LogListProps extends ListProps {
  readonly compactMode?: boolean
  readonly noBooking?: boolean
  readonly noCamera?: boolean
}

const BookingButton = ({ ...props }) => {
  const { style } = props
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const history = useHistory()
  return (
    <FunctionField
      source="bookingId"
      render={(record: Record | undefined) =>
        record?.bookingId && (
          <>
            <ShowButton
              color="inherit"
              basePath="/bookings"
              label={record.bookingId.toString()}
              record={{ id: record.bookingId }}
              icon={<Receipt />}
              style={style}
            />
            {xSmallScreen && (
              <Typography
                color="inherit"
                onClick={() =>
                  history.push(`/bookings/${record.bookingId}/show`)
                }
              >
                {record.bookingId}
              </Typography>
            )}
          </>
        )
      }
    />
  )
}

const LogExpand = ({ noBooking, noCamera, record }: LogExpandProps) => {
  const translate = useTranslate()
  return (
    <Box padding={1}>
      <Grid container spacing={1}>
        <VCenterGrid item xs={4}>
          {translate('resources.logs.fields.description')}:
        </VCenterGrid>
        <VCenterGrid item xs={8}>
          <TextField source="text" />
        </VCenterGrid>
        {!noBooking && record?.bookingId && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <VCenterGrid item xs={4}>
              {translate('resources.logs.fields.bookingId')}:
            </VCenterGrid>
            <VHCenterGrid item xs={8}>
              <BookingButton />
            </VHCenterGrid>
          </>
        )}
        {!noCamera &&
          record?.cameraId !== null &&
          record?.cameraId !== undefined && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <VCenterGrid item xs={4}>
                {translate('resources.logs.fields.cameraId')}:
              </VCenterGrid>
              <VHCenterGrid item xs={8}>
                <OpenCameraPreviewDialogButton
                  startDate={record.date}
                  cameraData={{
                    id: record?.cameraId,
                    description:
                      record?.cameraDescription !== null
                        ? record?.cameraDescription
                        : '',
                    serverId: record?.serverId as number,
                  }}
                  logId={record.id}
                  label=""
                  props={{ variant: undefined }}
                />
              </VHCenterGrid>
            </>
          )}
      </Grid>
    </Box>
  )
}

const LogFilters = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <TextInput key="ticketCode" source="ticketCode" alwaysOn />
      <BooleanInput source="simpleView" alwaysOn />
      <FilteredReferenceInput
        source="deviceId"
        reference={ResourceName.DEVICES_BASE}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={smallScreen ? 5 : 15}
        filterSource="search"
        selectWithPaginationInputProps={{
          optionText: 'name',
          showFilter: true,
          rowIcon: (record: DeviceBaseWithTypeInfoDto) => {
            switch (record?.type) {
              case DeviceTypes.ENGINE:
                return <AccountBalanceWallet />
              case DeviceTypes.PALM:
                return <PhoneAndroid />
              case DeviceTypes.DETECTOR:
                return <CenterFocusStrong />
              case DeviceTypes.PASSAGE:
                return <ArrowForward />
              case DeviceTypes.OFFLINE_SERVER:
                return <Storage />
              case DeviceTypes.DOORS:
                return <MeetingRoom />
              case DeviceTypes.TERMINALS:
                return <Keyboard />
              default:
                return <div />
            }
          },
        }}
      />
      <TextInput
        source="deviceName"
        key="deviceName"
        label="resources.logs.filters.deviceName"
      />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          label="resources.logs.filters.devieCategoryId"
          source="categoryIdWithDescendants"
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_SCHEMES) && (
        <FilteredReferenceInput
          label="resources.logs.filters.schemeId"
          source="schemeIdWithDescendants"
          reference={ResourceName.SCHEMES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="translatedLogSchemeDataTextSearch"
          selectWithPaginationInputProps={{
            optionText: 'translatedLogSchemeDataHierarchyString',
            showFilter: true,
          }}
        />
      )}
      <DateFilterInput source="date" key="date" />
      {hasAuthority(Authority.VIEW_BOOKINGS) && (
        <FilteredReferenceInput
          source="bookingId"
          reference={ResourceName.BOOKINGS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="id"
          selectWithPaginationInputProps={{
            optionText: 'id',
            showFilter: true,
            hideId: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_VARIANTS) && (
        <FilteredReferenceInput
          source="variantId"
          reference={ResourceName.VARIANTS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_OBJECTS) && (
        <FilteredReferenceInput
          source="objectId"
          reference={ResourceName.OBJECTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_EVENTS) && (
        <FilteredReferenceInput
          label="resources.logs.fields.event"
          source="eventId"
          reference={ResourceName.EVENTS}
          sort={{ field: 'dateStart', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="nameWithDateSearch"
          selectWithPaginationInputProps={{
            optionText: 'nameWithDate',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

const LogActions = (props: ListActionsProps) => {
  const { className, exporter, filters: filtersProp, ...rest } = props
  const { currentSort, displayedFilters, filterValues, showFilter, total } =
    useListContext(props)
  const resource = ResourceName.LOGS
  const filters = useContext(FilterContext) || filtersProp
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {exporter !== false && (
        <ExportButton
          disabled={total === 0 || total >= 150000}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={150000}
        />
      )}
    </TopToolbar>
  )
}

const LogListExport = (extendedLogs: ExtendedLog[]) => {
  const extendedLogsForExport = extendedLogs.map((log) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { cameraId, cameraDescription, color, ...postForExport } = log
    return postForExport
  })
  jsonExport(extendedLogsForExport, (err, csv) => {
    downloadCSV(csv, 'logs')
  })
}

export const LogList = ({
  compactMode = false,
  noBooking = false,
  noCamera = false,
  ...props
}: LogListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const hasAuthority = useHasAuthority()
  return (
    <List
      exporter={LogListExport}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      filters={<LogFilters />}
      actions={<LogActions />}
      sort={{ field: 'date', order: 'DESC' }}
      filterDefaultValues={{
        simpleView: true,
      }}
      {...props}
      bulkActionButtons={false}
    >
      <Datagrid
        expand={
          smallScreen || compactMode ? (
            <LogExpand noBooking={noBooking} noCamera={noCamera} />
          ) : undefined
        }
        rowStyle={(record: Record) => ({
          background: record && record.color,
          color:
            record && record.color !== 'transparent'
              ? getContrastColor(record.color)
              : 'inherit',
        })}
        padding={xSmallScreen ? 'checkbox' : 'default'}
      >
        <DateField
          source="date"
          options={{
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        <TextField source="deviceName" />
        <TextField source="ticketCode" />
        {!compactMode && !smallScreen && (
          <TextField
            source="text"
            label="resources.logs.fields.description"
            sortable={false}
          />
        )}
        {!compactMode &&
          !noBooking &&
          !smallScreen &&
          (hasAuthority(Authority.VIEW_BOOKINGS) ? (
            <BookingButton source="bookingId" />
          ) : (
            <TextField source="bookingId" />
          ))}
        {!compactMode && !smallScreen && <TextField source="scheme" />}
        {!compactMode &&
          !noCamera &&
          !smallScreen &&
          (hasAuthority(Authority.VIEW_CAMERAS) ? (
            <FunctionField<ExtendedLog>
              source="cameraId"
              sortable={false}
              render={(record?: ExtendedLog) =>
                record?.cameraId !== null &&
                record?.cameraId !== undefined && (
                  <OpenCameraPreviewDialogButton
                    startDate={record.date}
                    cameraData={{
                      id: record?.cameraId,
                      description:
                        record?.cameraDescription !== null
                          ? record?.cameraDescription
                          : '',
                      serverId: record?.serverId as number,
                    }}
                    logId={record.id}
                    label=""
                    props={{
                      variant: undefined,
                    }}
                  />
                )
              }
            />
          ) : (
            <TextField source="cameraId" sortable={false} />
          ))}
      </Datagrid>
    </List>
  )
}
