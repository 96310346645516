import React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  ListProps,
  List,
  Pagination,
  Datagrid,
  TextField,
  Identifier,
} from 'react-admin'
import { MobilePostPagination } from '../../common/Pagination'

export const AccessList = (props: ListProps) => {
  const theme = useTheme()
  return (
    <List
      perPage={20}
      exporter={false}
      pagination={
        useMediaQuery(theme.breakpoints.up('md')) ? (
          <Pagination rowsPerPageOptions={[20, 50, 100]} />
        ) : (
          <MobilePostPagination />
        )
      }
      {...props}
      bulkActionButtons={false}
    >
      <Datagrid
        rowClick={(id: Identifier) =>
          `?${new URLSearchParams({ id } as any).toString()}`
        }
      >
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="entranceId" />
      </Datagrid>
    </List>
  )
}
