import { mapping as deviceBaseMapper } from '../common/device-base/device-base-filter.mapper'

const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  ...deviceBaseMapper,
  hardwareVersion: (lambdaParameter, value) =>
    `${lambdaParameter}.HardwareVersion.Equals(TicketAPI.Models.Enums.DeviceManagement.HardwareVersions.${value})`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
