import { ButtonGroup, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React from 'react'
import cloneOrCreateElement from './cloneOrCreateElement'
import Button from './customized-mui-components/Button'

const useStyles = makeStyles({
  menuItem: {
    padding: 0,
    '& button': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
})

const DropdownMenu = ({ ...props }) => {
  const { items, forwardedRef, ...rest } = props
  const classes = useStyles()

  return React.Children.map(items, (child) => (
    <MenuItem
      className={classes.menuItem}
      ref={forwardedRef}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {cloneOrCreateElement(child, rest)}
    </MenuItem>
  ))
}

const DropdownButton = ({ ...props }) => {
  const { children, mainAction, onClick, label, ...rest } = props
  const [dropdownAnchor, setDropdownAnchor] = React.useState(null)
  const dropdownOpen = Boolean(dropdownAnchor)

  const handleOpenDropdown = (event) => {
    setDropdownAnchor(event.currentTarget)
  }

  const handleCloseDropdown = () => {
    setDropdownAnchor(null)
  }

  const DropdownMenuForwardRef = React.forwardRef((refProps: any, ref) => (
    <DropdownMenu items={children} forwardedRef={ref} {...refProps} />
  ))

  const DefaultAction = () => (
    <Button
      variant="contained"
      label={label}
      onClick={onClick}
      useSmallVersionBreakpoint={false}
      size="small"
    />
  )

  return (
    <>
      <ButtonGroup style={{ verticalAlign: 'middle' }}>
        {mainAction ? (
          cloneOrCreateElement(mainAction, rest)
        ) : (
          <DefaultAction />
        )}
        <Button
          variant={rest.variant ? rest.variant : 'contained'}
          color={rest.color ? rest.color : 'primary'}
          size={rest.size ? rest.size : 'small'}
          useSmallVersionBreakpoint={false}
          aria-controls="button-menu"
          aria-haspopup="true"
          onClick={handleOpenDropdown}
        >
          <ExpandMore />
        </Button>
      </ButtonGroup>
      <Menu
        id="button-menu"
        getContentAnchorEl={null}
        anchorEl={dropdownAnchor}
        keepMounted
        open={dropdownOpen}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DropdownMenuForwardRef {...rest} variant="text" />
      </Menu>
    </>
  )
}

export default DropdownButton
