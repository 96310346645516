import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import { useTranslate } from 'react-admin'
import {
  RestoreEntrancesSetResponse,
  RestoreEntrancesSetWarning,
} from '../../../../core/entrances-set/entrances-set.provider'
import { RestoreEntranceSetWarnigTypes } from '../../../../core/enum/RestoreEntranceSetWarnigTypes'
import DraggableComponent from '../../../common/DraggableComponent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      paddingBottom: '1rem',
      paddingTop: '1rem',
    },
    dialogActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      userSelect: 'none',
    },
    title: {
      paddingLeft: '10px',
      width: '100%',
    },
    closeButton: {
      margin: 0,
    },
    warningIcon: {
      color: theme.palette.warning.main,
    },
  }),
)

export const RestoreEntrancesSetWarningsDialog = ({
  status,
  close,
}: {
  status: RestoreEntrancesSetResponse | undefined
  close: () => void
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  const renderGlobalStatus = () => (
    <WarningIcon className={classes.warningIcon} />
  )

  const renderWarningDetails = (element: RestoreEntrancesSetWarning) => {
    let result
    switch (element.warningType) {
      case RestoreEntranceSetWarnigTypes.DEVICE_NOT_EXISTS:
        result = `${translate(
          'resources.entrances-matrix.dialogs.restoreEntrancesSetWarning.warning.deviceNotExistP1',
        )}: '${element.deviceId}' ${translate(
          'resources.entrances-matrix.dialogs.restoreEntrancesSetWarning.warning.notExists',
        )}`
        break
      case RestoreEntranceSetWarnigTypes.ENTRANCE_NOT_EXISTS:
        result = `${translate(
          'resources.entrances-matrix.dialogs.restoreEntrancesSetWarning.warning.entranceNotExistP1',
        )}: '${element.entranceId}' ${translate(
          'resources.entrances-matrix.dialogs.restoreEntrancesSetWarning.warning.notExists',
        )}`
        break
      default:
        result = element.description
        break
    }
    return <Typography>{result}</Typography>
  }

  return (
    <Dialog
      open={status !== undefined}
      onClose={close}
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogActions
        className={classes.dialogActions}
        style={{ cursor: 'move' }}
      >
        <Typography className={classes.title} id="draggable-dialog-title">
          {translate(
            `resources.entrances-matrix.dialogs.restoreEntrancesSetWarning.title`,
          )}
        </Typography>
        {renderGlobalStatus()}
        <IconButton
          size="small"
          className={classes.closeButton}
          onClick={close}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        {status?.warnings?.length ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {status.warnings.map((warning, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell>{renderWarningDetails(warning)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <Divider />
    </Dialog>
  )
}
