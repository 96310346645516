import { sanitizeFetchType } from 'react-admin'
import { AccessUsingDto } from '../dto/access-using.dto'
import { ResourceName } from '../ResourceName'
import {
  ToggleButtonAction,
  ToggleButtonState,
  ToggleType,
} from './access-using-activation-button'
import { BookingDto } from '../dto/booking.dto'

type Mutation = {
  type: string
  resource: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
}

const deactivateMutation = (
  accessUsing: AccessUsingDto,
  booking: BookingDto,
): Mutation => ({
  type: sanitizeFetchType('deactivateUse'),
  resource: ResourceName.BOOKINGS,
  payload: {
    id: booking.id,
    accessUsingId: accessUsing.id,
  },
})

const activateMutation = (
  accessUsing: AccessUsingDto,
  booking: BookingDto,
): Mutation => ({
  type: sanitizeFetchType('activateUse'),
  resource: ResourceName.BOOKINGS,
  payload: {
    id: booking.id,
    accessUsingId: accessUsing.id,
  },
})

type State = {
  mutateQuery?: (accessUsing: AccessUsingDto, booking: BookingDto) => Mutation
} & ToggleButtonState

export const activationButtonInitialState = {
  type: ToggleType.INACTIVE,
  text: 'resources.access-usings.actions.activate',
}

export const toggleActivationButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case ToggleType.ACTIVE: {
      return {
        type,
        text: 'resources.access-usings.actions.deactivate',
        mutateQuery: deactivateMutation,
      }
    }
    case ToggleType.INACTIVE: {
      return {
        type,
        text: 'resources.access-usings.actions.activate',
        mutateQuery: activateMutation,
      }
    }
    default:
      throw new Error()
  }
}
