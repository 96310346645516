import { createStyles, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useShowContext } from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { SetCredentialsDialog } from '../DepositorDialogs'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: '2px',
    },
  }),
)

export const DepositorSetCredentialsButton = () => {
  const { loading } = useShowContext()
  const classes = useStyles()
  const [credentialsModalOpen, setCredentialsModalOpen] = useState(false)
  const hasAuthority = useHasAuthority()

  const handleSetCredentialsDialog = () => {
    setCredentialsModalOpen(true)
  }

  return (
    <>
      <SetCredentialsDialog
        open={credentialsModalOpen}
        close={() => setCredentialsModalOpen(false)}
      />
      <Button
        variant="contained"
        onClick={handleSetCredentialsDialog}
        color="primary"
        className={classes.button}
        label="resources.depositors.actions.setCredentials"
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.DEPOSITORS_MANAGE_CREDENTIALS_BUTTON) ||
          loading
        }
      />
    </>
  )
}
