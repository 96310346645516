import { DataProvider, GetOneParams, GetOneResult } from 'react-admin'
import { STATISTICS_URL } from '../../api-urls'
import { getBlob } from '../../common/fetch.utils'

const provider = {
  getOne: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    { id }: GetOneParams,
  ): Promise<GetOneResult> => {
    const blob = await getBlob(
      STATISTICS_URL,
      `/GetTechnicalAssistanceProtocol/${id}`,
    )

    return Promise.resolve({
      data: {
        id,
        blob,
      },
    })
  },
} as DataProvider

export default provider
