import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import FilterProps from '../../common/FilterProps'
import QuickFilter from '../../common/QuickFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const TSCanFilters = (props: FilterProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          label="resources.terminals.fields.categoryId"
          source="categoryIdWithDescendants"
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      <QuickFilter source="eventActive" defaultValue />
    </Filter>
  )
}

export const TSCanList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    exporter={false}
    filters={<TSCanFilters />}
    bulkActionButtons={false}
  >
    <Datagrid
      size="small"
      rowClick={(id) => `${ResourceName.TS_CANS}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="address" />
    </Datagrid>
  </List>
)
