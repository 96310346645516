import { DataProvider } from 'react-admin'
import { AccessDto } from '../../dto/access.dto'
import { AccessActivePeriodStartingOptions } from '../../enum/AccessActivePeriodStartingOptions'
import { DayOfWeek } from '../../enum/DayOfWeek'

const fakeAccesses: AccessDto[] = [
  {
    id: 1,
    name: 'access1',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 2,
    name: 'access2',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 3,
    name: 'access3',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 4,
    name: 'access4',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 5,
    name: 'access5',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 6,
    name: 'access6',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 7,
    name: 'access7',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 8,
    name: 'access8',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 9,
    name: 'access9',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
  {
    id: 10,
    name: 'access10',
    bonusId: 1,
    entranceId: 0,
    allowReenter: false,
    multipleEntry: false,
    multiple: 0,
    usePriority: 0,
    isTicketUnlockedWithNextDay: false,
    releaseTime: '2021-07-15T08:50:27.2054',
    releaseDayOfWeek: DayOfWeek.Friday,
    releaseDayOfMonth: 1,
    delay: '',
    activePeriod: '',
    activePeriodStartingFrom: AccessActivePeriodStartingOptions.FIRST_USE,
    variantsIds: [],
    accessDatesIds: [],
    createdById: 0,
    modifiedById: 0,
    createdAt: '2021-07-15T08:50:27.2054',
    modifiedAt: '2021-07-15T08:50:27.2054',
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeAccesses, total: fakeAccesses.length }),
} as DataProvider

export default provider
