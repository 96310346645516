import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { TERMINALS_URL } from '../../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  recurringJobParamsComposer,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { TerminalDto } from '../../dto/device/terminals/terminal.dto'
import filterMapper from './terminal-filter.mapper'
import mapSortTerminalParam from './terminal-sort.mapper'
import { getDeviceCategoryChildren } from '../devices.utils'
import { DeviceDirection } from '../../enum/DeviceDirection'
import { Multiplicities } from '../../enum/Multiplicities'
import { RecurringJobCreationParamsDto } from '../../dto/recurring-job-creation-params.dto'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import { RecurringJobDetailsDto } from '../../dto/recurring-job-details.dto'
import { TerminalEventsDto } from '../../dto/device/terminals/terminal-events.dto'
import { TerminalEntrancesDto } from '../../dto/device/terminals/terminal-entrances.dto'
import { SetStandbyModeDto } from '../../dto/device/terminals/set-standby-mode.dto'
import { TerminalSetWorkingModeRequest } from '../../enum/TerminalSetWorkingModeRequest'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<TerminalDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortTerminalParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<TerminalDto[]>(
      `${TERMINALS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<TerminalDto>> => {
    const data = await get<TerminalDto>(TERMINALS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<TerminalDto>> => {
    const data = await get<TerminalDto[]>(
      TERMINALS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateTerminalRequest>,
  ): Promise<CreateResult<TerminalDto>> => {
    const terminal = await post<CreateTerminalRequest[], TerminalDto[]>(
      TERMINALS_URL,
      [data],
    )
    return {
      data: terminal[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<TerminalDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      TerminalDto
    >(TERMINALS_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
      tsCanId: data.tsCanId !== previousData.tsCanId ? data.tsCanId : undefined,
      supervisorId:
        data.supervisorId !== previousData.supervisorId
          ? data.supervisorId
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  attachEvents: async (
    resource: string,
    params: TerminalEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<TerminalEventsDto>> => {
    const doorEvents = await put<TerminalEventsDto, TerminalEventsDto>(
      `${TERMINALS_URL}/AttachEvents`,
      params,
    )
    return {
      data: doorEvents,
    }
  },
  detachEvents: async (
    resource: string,
    params: TerminalEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<TerminalEventsDto>> => {
    const doorEvents = await put<TerminalEventsDto, TerminalEventsDto>(
      `${TERMINALS_URL}/DetachEvents`,
      params,
    )
    return {
      data: doorEvents,
    }
  },
  attachEntrances: async (
    resource: string,
    params: TerminalEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<TerminalEntrancesDto>> => {
    const doorEntrances = await put<TerminalEntrancesDto, TerminalEntrancesDto>(
      `${TERMINALS_URL}/AttachEntrances`,
      params,
    )
    return {
      data: doorEntrances,
    }
  },
  detachEntrances: async (
    resource: string,
    params: TerminalEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<TerminalEntrancesDto>> => {
    const doorEntrances = await put<TerminalEntrancesDto, TerminalEntrancesDto>(
      `${TERMINALS_URL}/DetachEntrances`,
      params,
    )
    return {
      data: doorEntrances,
    }
  },
  lock: async (
    resource: string,
    { terminalId, disabledId, ...rest }: LockParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const lockDoor = await put<
      LockParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${TERMINALS_URL}/Lock/${query ? `?${query}` : ''}`, {
      terminalId,
      disabledId,
    })
    if ((lockDoor as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: lockDoor as RecurringJobDetailsDto,
      }
    return {
      data: lockDoor,
    }
  },
  unlock: async (
    resource: string,
    { terminalId, ...rest }: UnlockParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const unlockDoor = await put<
      UnlockParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${TERMINALS_URL}/Unlock/${query ? `?${query}` : ''}`, {
      terminalId,
    })
    if ((unlockDoor as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: unlockDoor as RecurringJobDetailsDto,
      }
    return {
      data: unlockDoor,
    }
  },
  setStandbyMode: async (
    resource: string,
    { terminalId, stanbyMode, ...rest }: SetStandbyModeDto,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const setStandby = await put<
      SetStandbyModeDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${TERMINALS_URL}/SetStandbyMode/${query ? `?${query}` : ''}`, {
      terminalId,
      stanbyMode,
    })
    if ((setStandby as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setStandby as RecurringJobDetailsDto,
      }
    return {
      data: setStandby,
    }
  },
  forceReportState: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const forceReportState = await get<undefined>(
      TERMINALS_URL,
      `/ForceReportState/${params?.id}`,
    )
    return {
      data: forceReportState,
    }
  },
  resetModules: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const resetModules = await get<undefined>(
      TERMINALS_URL,
      `/ResetModules/${params?.id}`,
    )
    return {
      data: resetModules,
    }
  },
  openForSingleVehicle: async (
    resource: string,
    { terminalId, note }: OpenForSingleVehicleParams,
  ): Promise<DataProviderExtensionResult<{ terminalId: number }>> => {
    const setStandby = await put<
      OpenForSingleVehicleParams,
      { terminalId: number }
    >(`${TERMINALS_URL}/OpenForSingleVehicle`, {
      terminalId,
      note,
    })
    return {
      data: setStandby,
    }
  },
  setPrinterPower: async (
    resource: string,
    { terminalId, powerState, ...rest }: SetPrinterPowerParams,
  ): Promise<
    DataProviderExtensionResult<{ terminalId: number } | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const setPower = await put<
      SetPrinterPowerParams,
      { terminalId: number } | RecurringJobDetailsDto
    >(`${TERMINALS_URL}/SetPrinterPower/${query ? `?${query}` : ''}`, {
      terminalId,
      powerState,
    })
    if ((setPower as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setPower as RecurringJobDetailsDto,
      }
    return {
      data: setPower,
    }
  },
  setWorkingMode: async (
    resource: string,
    { terminalId, workingMode, ...rest }: SetWorkingModeParams,
  ): Promise<
    DataProviderExtensionResult<{ terminalId: number } | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const setWorkingMode = await put<
      SetWorkingModeParams,
      { terminalId: number } | RecurringJobDetailsDto
    >(`${TERMINALS_URL}/SetWorkingMode/${query ? `?${query}` : ''}`, {
      terminalId,
      workingMode,
    })
    if ((setWorkingMode as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setWorkingMode as RecurringJobDetailsDto,
      }
    return {
      data: setWorkingMode,
    }
  },
} as TerminalsDataProvider

interface TerminalsDataProvider extends DataProvider {
  attachEvents: (
    resource: string,
    params: TerminalEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<TerminalEventsDto>>

  detachEvents: (
    resource: string,
    params: TerminalEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<TerminalEventsDto>>

  attachEntrances: (
    resource: string,
    params: TerminalEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<TerminalEntrancesDto>>

  detachEntrances: (
    resource: string,
    params: TerminalEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<TerminalEntrancesDto>>

  lock: (
    resource: string,
    params: LockParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  unlock: (
    resource: string,
    params: UnlockParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setStandbyMode: (
    resource: string,
    params: SetStandbyModeDto,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  forceReportState: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  resetModules: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  openForSingleVehicle: (
    resource: string,
    params: OpenForSingleVehicleParams,
  ) => Promise<DataProviderExtensionResult<{ terminalId: number }>>

  setPrinterPower: (
    resource: string,
    params: SetPrinterPowerParams,
  ) => Promise<
    DataProviderExtensionResult<{ terminalId: number } | RecurringJobDetailsDto>
  >

  setWorkingMode: (
    resource: string,
    params: SetWorkingModeParams,
  ) => Promise<
    DataProviderExtensionResult<{ terminalId: number } | RecurringJobDetailsDto>
  >
}

export interface CreateTerminalRequest {
  readonly name: string
  readonly isWatched: boolean
  readonly direction: DeviceDirection
  readonly categoryId: number
  readonly disabledId: number
  readonly jsonStatus: string
  readonly onlineInfixCeck: boolean
  readonly multiplicity: Multiplicities
  readonly cameraId: number
  readonly tsCanId: number
  readonly supervisorId: number
}

export interface LockParams extends RecurringJobCreationParamsDto {
  terminalId: number
  disabledId: number
}

export interface UnlockParams extends RecurringJobCreationParamsDto {
  terminalId: number
}

interface ChangeStateResponse {
  jobId: Identifier
}

interface OpenForSingleVehicleParams {
  terminalId: number
  note: string
}

interface SetPrinterPowerParams extends RecurringJobCreationParamsDto {
  terminalId: number
  powerState: boolean
}

interface SetWorkingModeParams extends RecurringJobCreationParamsDto {
  terminalId: number
  workingMode: TerminalSetWorkingModeRequest
}

export default provider
