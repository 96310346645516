import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  DateTimeInput,
  GET_ONE,
  Identifier,
  ListButton,
  NumberInput,
  sanitizeFetchType,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useQuery,
  useTranslate,
} from 'react-admin'
import { useLocation } from 'react-router'
import { formatDate, formatTime } from '../../../core/common/date-time'
import { AccessDateDto } from '../../../core/dto/access-date.dto'
import { AccessDateTypes } from '../../../core/enum/AccessDateTypes'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { DayOfWeek } from '../../../core/enum/DayOfWeek'

const useStyles = makeStyles({
  resettableSelectInput: {
    '& > div > div > button': {
      width: '32px',
      height: '32px',
    },
  },
})

interface AccessDateCreateToolbarProps extends ToolbarProps {
  readonly accessId?: number
}

const CreateToolbar = (props: AccessDateCreateToolbarProps) => {
  const { accessId, ...rest } = props
  const cancelLink = accessId ? `/accesses/${accessId}/show/dates` : undefined

  return (
    <Toolbar {...rest}>
      <SaveButton />
      <ListButton
        icon={<Cancel />}
        label="const.cancel"
        to={cancelLink || '/accesses'}
      />
    </Toolbar>
  )
}

const validateAccessDateCreate = (values: AccessDateDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.activeFrom) {
    errors.activeFrom = 'ra.validation.required'
  }
  if (!values.activeTo) {
    errors.activeTo = 'ra.validation.required'
  }
  if (!values.type) {
    errors.type = 'ra.validation.required'
  }
  if (!values.timeFrom) {
    errors.timeFrom = 'ra.validation.required'
  }
  if (!values.timeTo) {
    errors.timeTo = 'ra.validation.required'
  }
  if (!values.accessId) {
    errors.accessId = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = ({ accessId }: { accessId?: Identifier }) => {
  const translate = useTranslate()
  const { data: access } = useQuery(
    {
      type: sanitizeFetchType(GET_ONE),
      resource: ResourceName.ACCESSES,
      payload: {
        id: accessId,
      },
    },
    { enabled: accessId !== undefined },
  )

  return (
    <span>{`${translate('resources.access-dates.titles.create')}${
      access ? `: ${access?.name} (${access?.id})` : ''
    }`}</span>
  )
}

export const AccessDateCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { search } = useLocation()
  const accessIdRaw = new URLSearchParams(search).get('accessId')
  const accessId: number | undefined =
    accessIdRaw !== null ? Number(accessIdRaw) : undefined
  const classes = useStyles()

  const defaultValue = () => ({
    accessId,
  })

  return (
    <Create {...props} title={<CreateTitle accessId={accessId} />}>
      <SimpleForm
        redirect={accessId ? `/accesses/${accessId}/show/dates` : 'show'}
        validate={validateAccessDateCreate}
        initialValues={defaultValue}
        toolbar={<CreateToolbar accessId={accessId} />}
      >
        {!accessId ? (
          <FilteredReferenceInput
            source="accessId"
            defaultValue={null}
            reference={ResourceName.ACCESSES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        ) : (
          <NumberInput source="accessId" disabled isRequired />
        )}
        <DateTimeInput
          source="activeFrom"
          type="date"
          format={formatDate}
          parse={(value: string) => (value === '' ? null : value)}
          isRequired
        />
        <DateTimeInput
          source="activeTo"
          type="date"
          format={formatDate}
          parse={(value: string) => (value === '' ? null : value)}
          isRequired
        />
        <DateTimeInput
          source="timeFrom"
          type="time"
          format={formatTime}
          parse={(value: string) => (value === '' ? null : value)}
          inputProps={{ step: 1 }}
          isRequired
        />
        <DateTimeInput
          source="timeTo"
          type="time"
          format={formatTime}
          parse={(value: string) => (value === '' ? null : value)}
          inputProps={{ step: 1 }}
          isRequired
        />
        <SelectInput
          source="dayOfWeek"
          choices={[
            ...Object.entries(DayOfWeek).map(([value]) => ({
              id: value,
              name: `resources.enums.dayOfWeek.${value}`,
            })),
            {
              id: null,
              name: 'N/A',
            },
          ]}
          className={classes.resettableSelectInput}
        />
        <SelectInput
          source="type"
          choices={Object.entries(AccessDateTypes).map(([value]) => ({
            id: value,
            name: `resources.enums.accessDateTypes.${value}`,
          }))}
        />
        <NumberInput source="priority" />
      </SimpleForm>
    </Create>
  )
}
