import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { addTreeMethodsBasedOnParentAndPosition } from '../../lib/@react-admin/ra-tree/esm/src'
import { DEVICE_CATEGORIES_URL } from '../api-urls'
import { get, getWithPagination, patch, post } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { DeviceCategoryExtendedDto } from '../dto/device-category/device-category-extended.dto'
import { DeviceCategoryDto } from '../dto/device-category/device-category.dto'
import filterMapper from './device-category-filter.mapper'
import { mapSortDeviceCategoryParam } from './device-category-sort.mapper'

const extendDeviceCategoryDto = (
  deviceCategories: DeviceCategoryDto[],
): DeviceCategoryExtendedDto[] =>
  deviceCategories.map((deviceCategory) => ({
    ...deviceCategory,
    hierarchyString: deviceCategory.hierarchy
      ?.slice()
      .map((h) => h.name)
      .join('->'),
  })) as DeviceCategoryExtendedDto[]

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<DeviceCategoryExtendedDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortDeviceCategoryParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DeviceCategoryDto[]>(
      `${DEVICE_CATEGORIES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data: extendDeviceCategoryDto(data),
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DeviceCategoryExtendedDto>> => {
    const data = await get<DeviceCategoryDto>(DEVICE_CATEGORIES_URL, `/${id}`)
    return Promise.resolve({
      data: extendDeviceCategoryDto([data])?.[0],
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<DeviceCategoryExtendedDto>> => {
    const data = await get<DeviceCategoryDto[]>(
      DEVICE_CATEGORIES_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data: extendDeviceCategoryDto(data) })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateDeviceCategoryRequest>,
  ): Promise<CreateResult<DeviceCategoryExtendedDto>> => {
    const deviceCategory = await post<
      CreateDeviceCategoryRequest[],
      DeviceCategoryDto[]
    >(DEVICE_CATEGORIES_URL, [data])

    return {
      data: extendDeviceCategoryDto(deviceCategory)?.[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<DeviceCategoryDto>,
  ): Promise<UpdateResult<DeviceCategoryExtendedDto>> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      DeviceCategoryDto
    >(DEVICE_CATEGORIES_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
      parentId:
        data.parentId !== previousData.parentId ? data.parentId : undefined,
    })
    return Promise.resolve({ data: extendDeviceCategoryDto([update])?.[0] })
  },
} as DataProvider

// interface DeviceCategoryDataProvider extends DataProvider {
// }

export interface CreateDeviceCategoryRequest {
  readonly name: string
  readonly parentId?: number
}

export default addTreeMethodsBasedOnParentAndPosition(
  provider,
  'parentId',
  'id',
  false,
)
