import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import List from '../common/customized-ra-components/List'
import { TicketImportMappingDto } from '../../core/dto/ticket-import-mapping.dto'
import { ResourceName } from '../../core/ResourceName'

const TicketImportMappingsFilters = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

export const TicketImportMappingsList = (props: ListProps) => {
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const translate = useTranslate()

  return (
    <List
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<TicketImportMappingsFilters />}
      {...props}
      bulkActionButtons={false}
      exporter={false}
      empty={false}
    >
      <Datagrid padding={xSmallScreen ? 'checkbox' : 'default'} rowClick="edit">
        <TextField source="id" label="Id" />
        <FunctionField<TicketImportMappingDto>
          source="property"
          render={(r?: TicketImportMappingDto) => (
            <div>
              {translate(`resources.enums.ImportProperty.${r?.property}`)}
            </div>
          )}
        />
        <TextField source="csvPropertyName" />
        <TextField source="staticValue" />
        <ReferenceField
          source="importTemplateId"
          reference={ResourceName.TICKET_IMPORT_TEMPLATES}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
