import { sanitizeFetchType } from 'react-admin'
import { PalmDto } from '../../dto/device/palm/palm.dto'
import { ResourceName } from '../../ResourceName'
import {
  ToggleButtonAction,
  ToggleButtonState,
  ToggleType,
} from './palm-lock-button'

type Mutation = {
  type: string
  resource: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
}

const lockMutation = (
  { id }: PalmDto,
  disabledId?: number,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('lock'),
  resource: ResourceName.PALMS,
  payload: {
    palmId: id,
    disabledId,
    recurringJobName,
    cronExpression,
  },
})

const unlockMutation = (
  { id }: PalmDto,
  disabledId?: number,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('unlock'),
  resource: ResourceName.PALMS,
  payload: {
    palmId: id,
    recurringJobName,
    cronExpression,
  },
})

type State = {
  mutateQuery?: (
    palm: PalmDto,
    disabledId?: number,
    recurringJobName?: string,
    cronExpression?: string,
  ) => Mutation
} & ToggleButtonState

export const palmLockButtonInitialState = {
  type: ToggleType.UNLOCK,
  text: 'resources.palms.actions.unlock',
}

export const palmLockButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case ToggleType.LOCK: {
      return {
        type,
        text: 'resources.palms.actions.lock',
        mutateQuery: lockMutation,
      }
    }
    case ToggleType.UNLOCK: {
      return {
        type,
        text: 'resources.palms.actions.unlock',
        mutateQuery: unlockMutation,
      }
    }
    default:
      throw new Error()
  }
}
