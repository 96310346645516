import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import { Delete, Edit, Visibility } from '@material-ui/icons'
import React from 'react'
import {
  ListProps,
  Pagination,
  TextField,
  TextInput,
  Filter,
  FunctionField,
  Datagrid,
  BooleanField,
  ShowButton,
  Link,
  EditButton,
  DeleteButton,
  useDelete,
  Identifier,
  useRefresh,
} from 'react-admin'
import FilterProps from '../../common/FilterProps'
import List from '../../common/customized-ra-components/List'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import { DynamicCounterDto } from '../../../core/dto/dynamic-counter.dto'
import { ResourceName } from '../../../core/ResourceName'

const DynamicCountersFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

export const DynamicCountersList = (props: ListProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [deleteOne] = useDelete()
  const refresh = useRefresh()

  const handleDelete = (counter?: DynamicCounterDto) => {
    if (counter && counter.id) {
      deleteOne(ResourceName.STATS_DYNAMIC_STATISTICS, counter.id as Identifier)
      refresh()
    }
  }

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<DynamicCountersFilters />}
      bulkActionButtons={false}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <Datagrid size="small">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <BooleanField source="active" />
        <TextField source="state" sortable={false} />
        <TextField source="limit" sortable={false} />
        <FunctionField<DynamicCounterDto>
          render={(counter?: DynamicCounterDto) =>
            largeScreen ? (
              <ShowButton record={counter} />
            ) : (
              <Link
                to={`/${ResourceName.DYNAMIC_COUNTERS}/${counter?.id}/show`}
              >
                <IconButton size="small">
                  <Visibility />
                </IconButton>
              </Link>
            )
          }
        />
        {hasAuthority(Authority.EDIT_DYNAMIC_COUNTERS) && (
          <FunctionField<DynamicCounterDto>
            render={(counter?: DynamicCounterDto) =>
              largeScreen ? (
                <EditButton record={counter} />
              ) : (
                <Link to={`/${ResourceName.DYNAMIC_COUNTERS}/${counter?.id}`}>
                  <IconButton size="small">
                    <Edit />
                  </IconButton>
                </Link>
              )
            }
          />
        )}
        {hasAuthority(Authority.DELETE_DYNAMIC_COUNTERS) && (
          <FunctionField<DynamicCounterDto>
            render={(counter?: DynamicCounterDto) =>
              largeScreen ? (
                <DeleteButton
                  record={counter}
                  undoable={false}
                  resource={ResourceName.DYNAMIC_COUNTERS}
                />
              ) : (
                <IconButton size="small" onClick={() => handleDelete(counter)}>
                  <Delete color="error" />
                </IconButton>
              )
            }
          />
        )}
      </Datagrid>
    </List>
  )
}
