import { makeStyles } from '@material-ui/core'
import React from 'react'
import {
  ArrayInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  sanitizeFetchType,
  useEditContext,
  useQuery,
} from 'react-admin'
import { ResourceName } from '../../../../../core/ResourceName'

const useStyles = makeStyles({
  arrayInput: {
    '& > ul > li > div > div > p': {
      display: 'none',
    },
  },
})

export const VerifierSchemeJsonInput = ({ ...props }) => {
  const { formData, ...rest } = props
  const { record } = props
  const { loading, loaded } = useEditContext()
  const classes = useStyles()

  //existing data
  const scheme = { ...record, ...formData }

  //api module data
  const { data: modules } = useQuery({
    type: sanitizeFetchType('getVerifierTSCanModules'),
    resource: ResourceName.VERIFIER_SCHEME_DATA,
    payload: {
      id: scheme?.id,
    },
  })

  if (loading || !loaded || !modules) return <div />

  return (
    <ArrayInput
      {...rest}
      source="moduleSchemeMappings"
      className={classes.arrayInput}
    >
      <SimpleFormIterator>
        <SelectInput
          source="key"
          choices={modules.map((value) => ({
            id: value.tsCanModuleTypeId,
            name: value.tsCanModuleName,
          }))}
          fullWidth
        />
        <ArrayInput source="values" fullWidth className={classes.arrayInput}>
          <SimpleFormIterator>
            <NumberInput
              source="value"
              label={false}
              variant="standard"
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
  )
}
