var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useMemo, forwardRef } from 'react';
import { Link as RouterLink, } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
export var ListItemLink = function (props) {
    var children = props.children, to = props.to;
    var renderLink = useMemo(function () {
        // eslint-disable-next-line react/display-name
        return forwardRef(function (itemProps, ref) { return (React.createElement(RouterLink, __assign({ to: to, ref: ref }, itemProps))); });
    }, [to]);
    return (React.createElement("li", null,
        React.createElement(ListItem, { button: true, component: renderLink }, children)));
};
