import { DataProvider } from 'react-admin'
import { BlockDto } from '../../dto/block.dto'
import { BlockType } from '../../enum/BlockType'

const fakeBlocks: BlockDto[] = [
  {
    id: 0,
    name: 'Sektor 1',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 1,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
  {
    id: 1,
    name: 'Sektor 2',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 1,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
  {
    id: 2,
    name: 'Sektor 3',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 1,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
  {
    id: 3,
    name: 'Sektor 4',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 1,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
  {
    id: 4,
    name: 'Sektor 5',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 1,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
  {
    id: 5,
    name: 'Sektor 6',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 1,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
  {
    id: 6,
    name: 'Sektor 7',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 1,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
  {
    id: 7,
    name: 'Sektor 8',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 2,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
  {
    id: 8,
    name: 'Sektor 9',
    type: BlockType.NUMBERED,
    code: '1234',
    tribuneId: 2,
    bookingsIds: [12, 13],
    seatsIds: [1, 2, 3, 4, 5],
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeBlocks, total: fakeBlocks.length }),
} as DataProvider

export default provider
