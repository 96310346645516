const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  language: (lambdaParameter, value) =>
    `${lambdaParameter}.Language.Equals("${value}")`,
  schemeIdWithDescendants: () => null,
  schemesIds: (lambdaParameter, value) =>
    `new int%3F[] {${(
      value as number[]
    )?.toString()}}.Contains(${lambdaParameter}.SchemeId)`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Text).Contains("${value}")`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
