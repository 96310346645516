import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import {
  DEPOSITORS_URL,
  DETECTORS_URL,
  DOORS_URL,
  ENGINES_URL,
  OFFLINE_SERVERS_URL,
  PALMS_URL,
  PASSAGES_URL,
  TERMINALS_URL,
} from '../../../api-urls'
import { get, getWithPagination } from '../../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../../common/ticket-api-get-list.params'
import { DeviceBaseWithTypeInfoDto } from '../../../dto/device/common/device-base/device-base-with-type-info.dto'
import { DeviceBaseDto } from '../../../dto/device/common/device-base/device-base.dto'
import { DetectorDto } from '../../../dto/device/detector/detector.dto'
import { EngineDto } from '../../../dto/device/engine/engine.dto'
import { OfflineServerDto } from '../../../dto/device/offline-servers/offline-server.dto'
import { PalmDto } from '../../../dto/device/palm/palm.dto'
import { PassageDto } from '../../../dto/device/passages/passage.dto'
import { DeviceTypes } from '../../../enum/DeviceTypes'
import { ResourceName } from '../../../ResourceName'
import deviceBaseFilterMapper from './device-base-filter.mapper'
import detectorsProvider from '../../detectors/detectors.provider'
import enginesProvider from '../../engines/engines.provider'
import offlineServersProvider from '../../offline-servers/offline-servers.provider'
import palmsProvider from '../../palms/palms.provider'
import passagesProvider from '../../passages/passages.provider'
import doorsProvider from '../../doors/doors.provider'
import terminalsProvider from '../../terminals/terminals.provider'
import mapSortDeviceParam from './device-base-sort.mapper'
import { getDeviceCategoryChildren } from '../../devices.utils'
import { DepositorDto } from '../../../dto/device/depositor/depositor.dto'
import { DoorDto } from '../../../dto/device/doors/door.dto'
import { TerminalDto } from '../../../dto/device/terminals/terminal.dto'

const mapDeviceType = (
  devices: DeviceBaseDto[],
  type: DeviceTypes,
): DeviceBaseWithTypeInfoDto[] =>
  devices.map((device) => ({
    ...device,
    type,
  })) as DeviceBaseWithTypeInfoDto[]

const provider = {
  getList: async (
    resource: string,
    {
      filter: { deviceTypes, ...filter },
      sort,
      pagination,
    }: TicketAPIGetListParams,
  ): Promise<GetListResult<DeviceBaseWithTypeInfoDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const deviceTypeFilter = deviceTypes as DeviceTypes[]
    const data: DeviceBaseWithTypeInfoDto[] = []
    // Call all types to get total devices count
    const minimalListParams = {
      filter: extendedFilter,
      pagination: { page: 1, perPage: 1 },
    } as TicketAPIGetListParams
    const totalEngines =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.ENGINE)
        ? (
            await enginesProvider.getList(
              ResourceName.ENGINES,
              minimalListParams,
            )
          )?.total
        : 0
    const totalPalms =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.PALM)
        ? (await palmsProvider.getList(ResourceName.PALMS, minimalListParams))
            ?.total
        : 0
    const totalDepositors =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.DEPOSITOR)
        ? (
            await palmsProvider.getList(
              ResourceName.DEPOSITORS,
              minimalListParams,
            )
          )?.total
        : 0
    const totalDetectors =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.DETECTOR)
        ? (
            await detectorsProvider.getList(
              ResourceName.DETECTORS,
              minimalListParams,
            )
          )?.total
        : 0
    const totalPassages =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.PASSAGE)
        ? (
            await passagesProvider.getList(
              ResourceName.PASSAGES,
              minimalListParams,
            )
          )?.total
        : 0
    const totalOfflineServers =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.OFFLINE_SERVER)
        ? (
            await offlineServersProvider.getList(
              ResourceName.OFFLINE_SERVERS,
              minimalListParams,
            )
          )?.total
        : 0
    const totalDoors =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.DOORS)
        ? (await doorsProvider.getList(ResourceName.DOORS, minimalListParams))
            ?.total
        : 0
    const totalTerminals =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.TERMINALS)
        ? (
            await terminalsProvider.getList(
              ResourceName.TERMINALS,
              minimalListParams,
            )
          )?.total
        : 0
    // Calculate which endpoints should be called
    const availableEndpointsElementsMap: EndpointCallMapElement[] = []
    /* eslint-disable */
    for (var _i = 0; _i < totalEngines; _i++)
      availableEndpointsElementsMap.push({
        index: _i,
        deviceType: DeviceTypes.ENGINE,
      })
    for (var i = 0; i < totalPalms; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.PALM,
      })
    for (var i = 0; i < totalDepositors; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.DEPOSITOR,
      })
    for (var i = 0; i < totalDetectors; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.DETECTOR,
      })
    for (var i = 0; i < totalPassages; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.PASSAGE,
      })
    for (var i = 0; i < totalOfflineServers; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.OFFLINE_SERVER,
      })
    for (var i = 0; i < totalDoors; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.DOORS,
      })
    for (var i = 0; i < totalTerminals; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.TERMINALS,
      })
    /* eslint-disable */
    const fromIndex = (pagination.page - 1) * pagination.perPage
    const toIndex = fromIndex + pagination.perPage
    const endpointsCallMap = availableEndpointsElementsMap.slice(
      fromIndex,
      toIndex,
    )

    if (
      endpointsCallMap.some((value) => value.deviceType === DeviceTypes.ENGINE)
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        deviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(sort, undefined, mapSortDeviceParam)
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.ENGINE,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.ENGINE)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: enginesData } = await getWithPagination<EngineDto[]>(
        `${ENGINES_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(enginesData, DeviceTypes.ENGINE))
    }

    if (
      endpointsCallMap.some(
        (value) => value.deviceType === DeviceTypes.DEPOSITOR,
      )
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        deviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(sort, undefined, mapSortDeviceParam)
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.DEPOSITOR,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.DEPOSITOR)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: depositorsData } = await getWithPagination<DepositorDto[]>(
        `${DEPOSITORS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(depositorsData, DeviceTypes.DEPOSITOR))
    }

    if (
      endpointsCallMap.some((value) => value.deviceType === DeviceTypes.PALM)
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        deviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(sort, undefined, mapSortDeviceParam)
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.PALM,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.PALM)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: palmsData } = await getWithPagination<PalmDto[]>(
        `${PALMS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(palmsData, DeviceTypes.PALM))
    }

    if (
      endpointsCallMap.some(
        (value) => value.deviceType === DeviceTypes.DETECTOR,
      )
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        deviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(sort, undefined, mapSortDeviceParam)
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.DETECTOR,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.DETECTOR)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: detectorsData } = await getWithPagination<DetectorDto[]>(
        `${DETECTORS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(detectorsData, DeviceTypes.DETECTOR))
    }

    if (
      endpointsCallMap.some((value) => value.deviceType === DeviceTypes.PASSAGE)
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        deviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(sort, undefined, mapSortDeviceParam)
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.PASSAGE,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.PASSAGE)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: passagesData } = await getWithPagination<PassageDto[]>(
        `${PASSAGES_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(passagesData, DeviceTypes.PASSAGE))
    }

    if (
      endpointsCallMap.some(
        (value) => value.deviceType === DeviceTypes.OFFLINE_SERVER,
      )
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        deviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(sort, undefined, mapSortDeviceParam)
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.OFFLINE_SERVER,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.OFFLINE_SERVER)
            ?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: offlineServersData } = await getWithPagination<
        OfflineServerDto[]
      >(`${OFFLINE_SERVERS_URL}/GetByConditions`, path)
      data.push(
        ...mapDeviceType(offlineServersData, DeviceTypes.OFFLINE_SERVER),
      )
    }

    if (
      endpointsCallMap.some((value) => value.deviceType === DeviceTypes.DOORS)
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        deviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(sort, undefined, mapSortDeviceParam)
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.DOORS,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.DOORS)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: doors } = await getWithPagination<DoorDto[]>(
        `${DOORS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(doors, DeviceTypes.DOORS))
    }

    if (
      endpointsCallMap.some(
        (value) => value.deviceType === DeviceTypes.TERMINALS,
      )
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        deviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(sort, undefined, mapSortDeviceParam)
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.TERMINALS,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.TERMINALS)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: terminalsData } = await getWithPagination<TerminalDto[]>(
        `${TERMINALS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(terminalsData, DeviceTypes.TERMINALS))
    }

    return Promise.resolve({
      data,
      total: availableEndpointsElementsMap.length,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DeviceBaseWithTypeInfoDto>> => {
    try {
      const data = await get<EngineDto>(ENGINES_URL, `/${id}`)
      return Promise.resolve({ data: { ...data, type: DeviceTypes.ENGINE } })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<PalmDto>(PALMS_URL, `/${id}`)
      return Promise.resolve({ data: { ...data, type: DeviceTypes.PALM } })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<DepositorDto>(DEPOSITORS_URL, `/${id}`)
      return Promise.resolve({ data: { ...data, type: DeviceTypes.DEPOSITOR } })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<DetectorDto>(DETECTORS_URL, `/${id}`)
      return Promise.resolve({ data: { ...data, type: DeviceTypes.DETECTOR } })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<PassageDto>(PASSAGES_URL, `/${id}`)
      return Promise.resolve({ data: { ...data, type: DeviceTypes.PASSAGE } })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<OfflineServerDto>(OFFLINE_SERVERS_URL, `/${id}`)
      return Promise.resolve({
        data: { ...data, type: DeviceTypes.OFFLINE_SERVER },
      })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<DoorDto>(DOORS_URL, `/${id}`)
      return Promise.resolve({
        data: { ...data, type: DeviceTypes.DOORS },
      })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<TerminalDto>(TERMINALS_URL, `/${id}`)
      return Promise.resolve({
        data: { ...data, type: DeviceTypes.TERMINALS },
      })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
      return Promise.reject(error)
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<DeviceBaseWithTypeInfoDto>> => {
    const enginesData = await get<EngineDto[]>(
      ENGINES_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const palmsData = await get<PalmDto[]>(
      PALMS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const depositorsData = await get<DepositorDto[]>(
      DEPOSITORS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const detectorsData = await get<DetectorDto[]>(
      DETECTORS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const passagesData = await get<PassageDto[]>(
      PASSAGES_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const offlineServersData = await get<OfflineServerDto[]>(
      OFFLINE_SERVERS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const doorsData = await get<DoorDto[]>(
      DOORS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const terminalsData = await get<TerminalDto[]>(
      TERMINALS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    return Promise.resolve({
      data: [
        ...mapDeviceType(enginesData, DeviceTypes.ENGINE),
        ...mapDeviceType(palmsData, DeviceTypes.PALM),
        ...mapDeviceType(depositorsData, DeviceTypes.DEPOSITOR),
        ...mapDeviceType(detectorsData, DeviceTypes.DETECTOR),
        ...mapDeviceType(passagesData, DeviceTypes.PASSAGE),
        ...mapDeviceType(offlineServersData, DeviceTypes.OFFLINE_SERVER),
        ...mapDeviceType(doorsData, DeviceTypes.DOORS),
        ...mapDeviceType(terminalsData, DeviceTypes.TERMINALS),
      ],
    })
  },
} as DataProvider

interface EndpointCallMapElement {
  readonly index: number
  readonly deviceType: DeviceTypes
}

export default provider
