import {
  DataProvider,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { CURRENT_USER_URL } from '../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../common/data-provider'
import { get, patch, put } from '../common/fetch.utils'
import { UserSettingsDto } from '../dto/user-settings.dto'
import { UserDto } from '../dto/user.dto'

const provider = {
  getOne: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    params: GetOneParams,
  ): Promise<GetOneResult<UserDto>> => {
    const data = await get<UserDto>(CURRENT_USER_URL)
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { data, previousData }: UpdateParams<UserDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ [n: string]: any }, UserDto>(
      CURRENT_USER_URL,
      {
        userName:
          data.userName !== previousData.userName ? data.userName : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  getSettings: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
  ): Promise<DataProviderExtensionResult<UserSettingsDto>> => {
    const settings = await get<UserSettingsDto>(
      `${CURRENT_USER_URL}/GetSettings/`,
    )
    return {
      data: settings,
    }
  },
  patchSettings: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    params: UserSettingsDto,
  ): Promise<DataProviderExtensionResult<UserSettingsDto>> => {
    const settings = await patch<UserSettingsDto, UserSettingsDto>(
      `${CURRENT_USER_URL}/PatchSettings/`,
      params,
    )
    return {
      data: settings,
    }
  },
  changePassword: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    params: ChangeUserPasswordRequest,
  ): Promise<DataProviderExtensionResult<ChangeUserPasswordResponse>> => {
    const settings = await put<
      ChangeUserPasswordRequest,
      ChangeUserPasswordResponse
    >(`${CURRENT_USER_URL}/ChangePassword/`, params)
    return {
      data: settings,
    }
  },
  getAPIVersion: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
  ): Promise<DataProviderExtensionResult<GetAPIVersionResponse>> => {
    const version = await get<GetAPIVersionResponse>(
      CURRENT_USER_URL,
      '/GetAPIVersion',
    )

    return {
      data: version as GetAPIVersionResponse,
    }
  },
  getApplicationSettings: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
  ): Promise<DataProviderExtensionResult<ApplicationSettings>> => {
    const settings = await get<ApplicationSettings>(
      `${CURRENT_USER_URL}/GetApplicationSettings/`,
    )
    return {
      data: settings,
    }
  },
  patchApplicationSettings: async (
    resource: string,
    params: ApplicationSettings,
  ): Promise<DataProviderExtensionResult<ApplicationSettings>> => {
    const settings = await patch<ApplicationSettings, ApplicationSettings>(
      `${CURRENT_USER_URL}/PatchApplicationSettings/`,
      params,
    )
    return {
      data: settings,
    }
  },
} as UserDataProvider

interface UserDataProvider extends DataProvider {
  changePassword: (
    resource: string,
    params: ChangeUserPasswordRequest,
  ) => Promise<CacheableDataProviderExtensionResult<ChangeUserPasswordResponse>>

  getSettings: (
    resource: string,
  ) => Promise<CacheableDataProviderExtensionResult<UserSettingsDto>>

  patchSettings: (
    resource: string,
    params: UserSettingsDto,
  ) => Promise<CacheableDataProviderExtensionResult<UserSettingsDto>>

  getAPIVersion: (
    resource: string,
  ) => Promise<DataProviderExtensionResult<GetAPIVersionResponse>>

  getApplicationSettings: (
    resource: string,
  ) => Promise<CacheableDataProviderExtensionResult<ApplicationSettings>>

  patchApplicationSettings: (
    resource: string,
    params: ApplicationSettings,
  ) => Promise<CacheableDataProviderExtensionResult<ApplicationSettings>>
}

interface ChangeUserPasswordRequest {
  readonly newPassword: string
}

interface ChangeUserPasswordResponse {
  readonly password: string
  readonly id: number
  readonly userName: string
}

interface GetAPIVersionResponse {
  readonly version: string
}

interface ApplicationSettings {
  id: number
  patchTicketAuditComment: boolean
  patchBookingAuditComment: boolean
  patchAccessUsingAuditComment: boolean
}

export default provider
