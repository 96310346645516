import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  AccountBalanceWallet,
  ArrowForward,
  CenterFocusStrong,
  Keyboard,
  MeetingRoom,
  PhoneAndroid,
  Storage,
} from '@material-ui/icons'
import React from 'react'
import {
  DateTimeInput,
  NumberInput,
  required,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { DeviceBaseWithTypeInfoDto } from '../../../../core/dto/device/common/device-base/device-base-with-type-info.dto'
import { AccessActivePeriodStartingOptions } from '../../../../core/enum/AccessActivePeriodStartingOptions'
import { AccessDateTypes } from '../../../../core/enum/AccessDateTypes'
import { BlockType } from '../../../../core/enum/BlockType'
import { CameraTypes } from '../../../../core/enum/CameraTypes'
import { Cultures } from '../../../../core/enum/Cultures'
import { CustomerDocumentType } from '../../../../core/enum/CustomerDocumentType'
import { CustomerTypes } from '../../../../core/enum/CustomerTypes'
import { DeviceDirection } from '../../../../core/enum/DeviceDirection'
import { EventStatus } from '../../../../core/enum/EventStatus'
import { Gender } from '../../../../core/enum/Gender'
import { HardwareVersions } from '../../../../core/enum/HardwareVersions'
import { PalmThemes } from '../../../../core/enum/PalmThemes'
import { PassageCounterType } from '../../../../core/enum/PassageCounterType'
import { PassageDirections } from '../../../../core/enum/PassageDirections'
import { TicketType } from '../../../../core/enum/TicketType'
import { TribuneType } from '../../../../core/enum/TribuneType'
import { ResourceName } from '../../../../core/ResourceName'
import FilteredReferenceInput from '../../../common/FilteredReferenceInput'
import { DeviceTypes } from '../../../../core/enum/DeviceTypes'
import { BookingType } from '../../../../core/enum/BookingType'
import { BookingDisabledOption } from '../../../../core/enum/BookingDisabledOption'
import { DynamicCounterParameterTypes } from '../../../../core/enum/DynamicCounterParameterTypes'
import { CustomerDto } from '../../../../core/dto/customer.dto'
import { parameterTypeAuthority } from '../dynamic-counter-show/DynamicCounterParametersList'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { AccessBonusDto } from '../../../../core/dto/access-bonus.dto'
import { dateTimeInputDefaultParse } from '../../../../core/common/date-time'

export const ParameterValueInput = ({ ...props }) => {
  const { type, source, ...rest } = props
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { getState } = useForm()
  const { values: formData } = getState()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  const inputType =
    (type ?? formData?.type) &&
    (!parameterTypeAuthority[type ?? formData?.type] ||
      hasAuthority(parameterTypeAuthority[type ?? formData?.type]))
      ? type ?? formData?.type
      : DynamicCounterParameterTypes.STRING

  switch (inputType) {
    case DynamicCounterParameterTypes.INTEGER: {
      return (
        <NumberInput
          source={source || 'value'}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.DATE: {
      return (
        <DateTimeInput
          parse={dateTimeInputDefaultParse}
          source={source || 'value'}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.CURRENT_DATE: {
      return <TextField source={source || 'value'} {...rest} />
    }
    case DynamicCounterParameterTypes.CULTURE: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(Cultures).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.Cultures.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.CURRENT_USER_ID: {
      return <TextField source={source || 'value'} {...rest} />
    }
    case DynamicCounterParameterTypes.USER_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.USERS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'userName',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.COMPANY_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.COMPANIES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.PERMISSION_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.PERMISSIONS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ROLE_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.ROLES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.CAMERA_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.CAMERAS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'description',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.CAMERA_SERVER_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.CAMERA_SERVERS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.PALM_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.PALMS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.PALM_SCHEME_DATA_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.PALM_SCHEME_DATA}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'text',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.DEPOSITOR_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.DEPOSITORS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.DEVICE_BASE_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.DEVICES_BASE}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
            rowIcon: (record: DeviceBaseWithTypeInfoDto) => {
              switch (record?.type) {
                case DeviceTypes.ENGINE:
                  return <AccountBalanceWallet />
                case DeviceTypes.PALM:
                  return <PhoneAndroid />
                case DeviceTypes.DETECTOR:
                  return <CenterFocusStrong />
                case DeviceTypes.PASSAGE:
                  return <ArrowForward />
                case DeviceTypes.OFFLINE_SERVER:
                  return <Storage />
                case DeviceTypes.DOORS:
                  return <MeetingRoom />
                case DeviceTypes.TERMINALS:
                  return <Keyboard />
                default:
                  return <div />
              }
            },
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ENGINE_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.ENGINES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.OFFLINE_SERVER_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.OFFLINE_SERVERS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.PASSAGE_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.PASSAGES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.PASSAGE_COUNTER_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.PASSAGES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.VERIFIER_DEVICE_BASE_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          reference={ResourceName.VERIFIER_DEVICES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
            rowIcon: (record: DeviceBaseWithTypeInfoDto) => {
              switch (record?.type) {
                case DeviceTypes.ENGINE:
                  return <AccountBalanceWallet />
                case DeviceTypes.PALM:
                  return <PhoneAndroid />
                case DeviceTypes.DETECTOR:
                  return <CenterFocusStrong />
                case DeviceTypes.PASSAGE:
                  return <ArrowForward />
                case DeviceTypes.OFFLINE_SERVER:
                  return <Storage />
                case DeviceTypes.DOORS:
                  return <MeetingRoom />
                case DeviceTypes.TERMINALS:
                  return <Keyboard />
                default:
                  return <div />
              }
            },
          }}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.DEVICE_CATEGORY_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ENTRANCE_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.ENTRANCES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="idWithNote"
          selectWithPaginationInputProps={{
            optionText: 'note',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ENTRANCE_SET_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.ENTRANCES_SET}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'description',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.CAMERA_TYPE: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(CameraTypes).map((key: string) => ({
            id: key,
            name: `resources.enums.CameraTypes.${key}`,
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.DEVICE_DIRECTION: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(DeviceDirection).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.deviceDirection.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.HARDWARE_VERSION: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(HardwareVersions).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.hardwareVersions.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.PALM_THEME: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(PalmThemes).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.PalmThemes.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.PASSAGE_COUNTER_TYPE: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(PassageCounterType).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.PassageCounterType.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.PASSAGE_DIRECTION: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(PassageDirections).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.deviceDirection.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.LOG_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.LOGS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'text',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.LOG_SCHEME_DATA_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.LOG_SCHEME_DATA}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'text',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ACCESS_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.ACCESSES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ACCESS_DATE_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.ACCESS_DATES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="id"
          selectWithPaginationInputProps={{
            optionText: 'id',
            showFilter: true,
            hideId: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ACCESS_USING_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.ACCESS_USINGS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="id"
          selectWithPaginationInputProps={{
            optionText: 'id',
            showFilter: true,
            hideId: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.BLOCK_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.BLOCKS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.BOOKING_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.BLOCKS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="id"
          selectWithPaginationInputProps={{
            optionText: 'id',
            showFilter: true,
            hideId: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.CUSTOMER_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.CUSTOMERS}
          sort={{ field: 'lastname', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="info"
          selectWithPaginationInputProps={{
            optionText: (record: CustomerDto) =>
              `${record.lastname} ${record.firstname}`,
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.EVENT_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.EVENTS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.OBJECT_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.OBJECTS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.SCHEME_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.SCHEMES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.SEAT_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.SEATS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="combinedFullAlias"
          selectWithPaginationInputProps={{
            optionText: 'combinedFullAlias',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.TICKET_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.TICKETS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="ticketCode"
          selectWithPaginationInputProps={{
            optionText: 'ticketCode',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.TRIBUNE_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.TRIBUNES}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.VARIANT_ID: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.VARIANTS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ACCESS_ACTIVE_PERIOD_STARTING_OPTION: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(AccessActivePeriodStartingOptions).map(
            (key: string) => ({
              id: key,
              name: translate(
                `resources.enums.activePeriodStartingFrom.${key}`,
              ),
            }),
          )}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ACCESS_BONUS: {
      return (
        <FilteredReferenceInput
          source={source || 'value'}
          defaultValue={null}
          reference={ResourceName.ACCESS_BONUS}
          sort={{ field: 'id', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: (choice: AccessBonusDto) =>
              `${choice.name} (${choice.description})`,
            showFilter: true,
          }}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.ACCESS_DATE_TYPE: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(AccessDateTypes).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.accessDateTypes.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.BLOCK_TYPE: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(BlockType).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.blockType.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.BOOKING_DISABLED_OPTION: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(BookingDisabledOption).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.bookingDisabled.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.BOOKING_TYPE: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(BookingType).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.bookingType.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.CUSTOMER_DOCUMENT_TYPE: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(CustomerDocumentType).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.customerDocumentType.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.CUSTOMER_TYPE: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(CustomerTypes).map((key: string) => ({
            id: key,
            name: key,
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.EVENT_STATUS: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(EventStatus).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.eventStatus.${key.toLowerCase()}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.GENDER: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(Gender).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.gender.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.TICKET_TYPES: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(TicketType).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.ticketType.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.TRIBUNE_TYPES: {
      return (
        <SelectInput
          source={source || 'value'}
          choices={Object.keys(TribuneType).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.tribuneType.${key}`),
          }))}
          validate={required()}
          {...rest}
        />
      )
    }
    case DynamicCounterParameterTypes.REQUEST__DYNAMIC_COUNTER_DIRECTION: {
      return <TextField source={source || 'value'} {...props} />
    }
    case DynamicCounterParameterTypes.REQUEST__TICKET_ID: {
      return <TextField source={source || 'value'} {...props} />
    }
    case DynamicCounterParameterTypes.REQUEST__TICKET_CODE: {
      return <TextField source={source || 'value'} {...props} />
    }
    case DynamicCounterParameterTypes.REQUEST__TICKET_TYPE: {
      return <TextField source={source || 'value'} {...props} />
    }
    case DynamicCounterParameterTypes.REQUEST__TICKET_ACTIVE: {
      return <TextField source={source || 'value'} {...props} />
    }
    case DynamicCounterParameterTypes.REQUEST__TICKET_BOOKING_ID: {
      return <TextField source={source || 'value'} {...props} />
    }
    default: {
      return (
        <TextInput
          source={source || 'value'}
          validate={required()}
          {...rest}
          fullWidth
        />
      )
    }
  }
}
