import { Autorenew } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import { EventDto } from '../../../core/dto/event/event.dto'
import { DeactivateAccessUsingsDialog } from './DeactivateAccessUsingsDialog'

export const DeactivateAccessUsingsButton = ({ ...props }) => {
  const { asRecurringJob, ...rest } = props
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { record: event, loading } = useShowContext<EventDto>()
  const [
    deactivateAccessUsingsDialogOpen,
    setDeactivateAccessUsingsDialogOpen,
  ] = useState(false)
  const [mutate, { loading: mutationLoading }] = useMutation()
  const [mutationResult, setMutationResult] = useState<
    { jobId: number } | undefined
  >(undefined)
  const refresh = useRefresh()

  const handleDeactivateAccessUsings = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    setDeactivateAccessUsingsDialogOpen(false)
    let query
    if (!asRecurringJob) {
      query = {
        type: sanitizeFetchType('deactivateAccessUsings'),
        resource: ResourceName.EVENTS,
        payload: {
          eventId: event?.id,
        },
      }
    } else {
      query = {
        type: sanitizeFetchType('deactivateAccessUsings'),
        resource: ResourceName.EVENTS,
        payload: {
          eventId: event?.id,
          recurringJobName,
          cronExpression,
        },
      }
    }
    const { data: result } = await mutate(query, {
      returnPromise: true,
      onFailure: (msg) => notify(msg, 'error'),
    })
    setMutationResult(result)
  }

  const handleCloseDialog = () => {
    refresh()
  }

  const handleDeactivateAccessUsingsDialog = () => {
    setDeactivateAccessUsingsDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={deactivateAccessUsingsDialogOpen}
        confirm={handleDeactivateAccessUsings}
        source="deactivateAccessUsings"
        cancel={() => setDeactivateAccessUsingsDialogOpen(false)}
        action="set"
        resource={ResourceName.EVENTS}
        asRecurringJob={asRecurringJob}
      />
      <DeactivateAccessUsingsDialog
        handleClose={handleCloseDialog}
        open={!!mutationResult || !!mutationLoading}
        result={mutationResult}
      />
      <Button
        onClick={handleDeactivateAccessUsingsDialog}
        label={
          !asRecurringJob
            ? 'resources.events.actions.deactivateAccessUsings'
            : 'common.recurring-job.buttons.create-recurring-job'
        }
        {...rest}
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.EVENTS_DEACTIVATE_ACCESS_USINGS) ||
          loading ||
          mutationLoading
        }
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        size="small"
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
      />
    </>
  )
}
