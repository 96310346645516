import { Authority } from './Authority'

const mapping: Record<Authority, string[] | string[][]> = {
  // User management
  [Authority.VIEW_USER_MANAGEMENT]: ['ViewItems_User_Management'],

  // Logs
  [Authority.VIEW_LOGS]: ['ViewItems_Logs'],

  // Log scheme data
  [Authority.VIEW_LOG_SCHEME_DATA]: [
    'ViewItems_LogSchemeData_Show',
    'ViewItems_LogSchemeData_Edit',
    'ViewItems_LogSchemeData_Add',
  ],
  [Authority.EDIT_LOG_SCHEME_DATA]: ['ViewItems_LogSchemeData_Edit'],
  [Authority.CREATE_LOG_SCHEME_DATA]: ['ViewItems_LogSchemeData_Add'],

  // Stats
  [Authority.VIEW_STATS_OVERALL_VIEW]: ['ViewItems_Stats_OverallView'],
  [Authority.VIEW_STATS_EVENT_STANDS_PERIOD]: [
    'ViewItems_Stats_EventStandsPeriod',
  ],
  [Authority.VIEW_STATS_EVENT_ENTRANCES]: ['ViewItems_Stats_EventEntrances'],
  [Authority.VIEW_STATS_PASSAGE_COUNTERS]: ['ViewItems_Stats_PassageCounters'],
  [Authority.VIEW_TECHNICAL_ASSISTANCE_PROTOCOL]: [
    'ViewItems_TechnicalAssistanceProtocol_Show',
  ],

  // Dynamic statistics
  [Authority.VIEW_DYNAMIC_STATISTICS]: [
    'ViewItems_DynamicStatistics_Show',
    'ViewItems_DynamicStatistics_Edit',
    'ViewItems_DynamicStatistics_Add',
    'ViewItems_DynamicStatistics_Delete',
    'ViewItems_DynamicStatistics_Execute',
  ],
  [Authority.EDIT_DYNAMIC_STATISTICS]: ['ViewItems_DynamicStatistics_Edit'],
  [Authority.CREATE_DYNAMIC_STATISTICS]: ['ViewItems_DynamicStatistics_Add'],
  [Authority.DELETE_DYNAMIC_STATISTICS]: ['ViewItems_DynamicStatistics_Delete'],
  [Authority.EXECUTE_DYNAMIC_STATISTICS]: [
    'ViewItems_DynamicStatistics_Execute',
  ],

  // Dynamic statistic parameters
  [Authority.VIEW_DYNAMIC_STATISTIC_PARAMETERS]: [
    'ViewItems_DynamicStatisticParameters_Show',
    'ViewItems_DynamicStatisticParameters_Edit',
    'ViewItems_DynamicStatisticParameters_Add',
    'ViewItems_DynamicStatisticParameters_Delete',
  ],
  [Authority.EDIT_DYNAMIC_STATISTIC_PARAMETERS]: [
    'ViewItems_DynamicStatisticParameters_Edit',
  ],
  [Authority.CREATE_DYNAMIC_STATISTIC_PARAMETERS]: [
    'ViewItems_DynamicStatisticParameters_Add',
  ],
  [Authority.DELETE_DYNAMIC_STATISTIC_PARAMETERS]: [
    'ViewItems_DynamicStatisticParameters_Delete',
  ],

  // Companies
  [Authority.VIEW_COMPANIES]: [
    'ViewItems_Companies_Show',
    'ViewItems_Companies_Edit',
    'ViewItems_Companies_Add',
  ],
  [Authority.EDIT_COMPANIES]: ['ViewItems_Companies_Edit'],
  [Authority.CREATE_COMPANIES]: ['ViewItems_Companies_Add'],

  // Users
  [Authority.VIEW_USERS]: [
    'ViewItems_Users_Show',
    'ViewItems_Users_Edit',
    'ViewItems_Users_Add',
  ],
  [Authority.EDIT_USERS]: ['ViewItems_Users_Edit'],
  [Authority.CREATE_USERS]: ['ViewItems_Users_Add'],

  // Roles
  [Authority.VIEW_ROLES]: [
    'ViewItems_Roles_Show',
    'ViewItems_Roles_Edit',
    'ViewItems_Roles_Add',
  ],
  [Authority.EDIT_ROLES]: ['ViewItems_Roles_Edit'],
  [Authority.CREATE_ROLES]: ['ViewItems_Roles_Add'],

  // Access dates
  [Authority.VIEW_ACCESS_DATES]: [
    'ViewItems_AccessDates_Show',
    'ViewItems_AccessDates_Edit',
    'ViewItems_AccessDates_Add',
  ],
  [Authority.EDIT_ACCESS_DATES]: ['ViewItems_AccessDates_Edit'],
  [Authority.CREATE_ACCESS_DATES]: ['ViewItems_AccessDates_Add'],

  // Accesses
  [Authority.VIEW_ACCESSES]: [
    'ViewItems_Accesses_Show',
    'ViewItems_Accesses_Edit',
    'ViewItems_Accesses_Add',
  ],
  [Authority.EDIT_ACCESSES]: ['ViewItems_Accesses_Edit'],
  [Authority.CREATE_ACCESSES]: ['ViewItems_Accesses_Add'],

  // Access usings
  [Authority.VIEW_ACCESS_USINGS]: [
    'ViewItems_AccessUsings_Show',
    'ViewItems_AccessUsings_Edit',
    'ViewItems_AccessUsings_Add',
  ],
  [Authority.EDIT_ACCESS_USINGS]: ['ViewItems_AccessUsings_Edit'],
  [Authority.CREATE_ACCESS_USINGS]: ['ViewItems_AccessUsings_Add'],

  // Blocks
  [Authority.VIEW_BLOCKS]: [
    'ViewItems_Blocks_Show',
    'ViewItems_Blocks_Edit',
    'ViewItems_Blocks_Add',
  ],
  [Authority.EDIT_BLOCKS]: ['ViewItems_Blocks_Edit'],
  [Authority.CREATE_BLOCKS]: ['ViewItems_Blocks_Add'],

  // Bookings
  [Authority.VIEW_BOOKINGS]: [
    'ViewItems_Bookings_Show',
    'ViewItems_Bookings_Edit',
    'ViewItems_Bookings_Add',
    'ViewItems_Bookings_BlockadeButton',
    'ViewItems_Bookings_ToggleAccessUsingsButton',
  ],
  [Authority.EDIT_BOOKINGS]: ['ViewItems_Bookings_Edit'],
  [Authority.CREATE_BOOKINGS]: ['ViewItems_Bookings_Add'],
  [Authority.BOOKINGS_BLOCKADE_BUTTON]: ['ViewItems_Bookings_BlockadeButton'],
  [Authority.BOOKINGS_TOGGLE_ACCESS_USINGS_BUTTON]: [
    'ViewItems_Bookings_ToggleAccessUsingsButton',
  ],
  [Authority.BOOKINGS_RELEASE_ALL_USINGS]: [
    'ViewItems_Bookings_ReleaseAllUsingsButton',
  ],
  [Authority.BOOKINGS_CREATE_USE]: ['ViewItems_Bookings_CreateUseButton'],

  // Service bookings
  [Authority.VIEW_SERVICE_BOOKINGS]: [
    'ViewItems_ServiceBookings_Show',
    'ViewItems_ServiceBookings_Edit',
    'ViewItems_ServiceBookings_Add',
  ],
  [Authority.EDIT_SERVICE_BOOKINGS]: ['ViewItems_ServiceBookings_Edit'],
  [Authority.CREATE_SERVICE_BOOKINGS]: ['ViewItems_ServiceBookings_Add'],

  // Points bookings
  [Authority.VIEW_POINTS_BOOKINGS]: [
    'ViewItems_PointsBookings_Show',
    'ViewItems_PointsBookings_Edit',
    'ViewItems_PointsBookings_Add',
  ],
  [Authority.EDIT_POINTS_BOOKINGS]: ['ViewItems_PointsBookings_Edit'],
  [Authority.CREATE_POINTS_BOOKINGS]: ['ViewItems_PointsBookings_Add'],

  // Customers
  [Authority.VIEW_CUSTOMERS]: [
    'ViewItems_Customers_Show',
    'ViewItems_Customers_Edit',
    'ViewItems_Customers_Add',
  ],
  [Authority.EDIT_CUSTOMERS]: ['ViewItems_Customers_Edit'],
  [Authority.CREATE_CUSTOMERS]: ['ViewItems_Customers_Add'],

  // Events
  [Authority.VIEW_EVENTS]: [
    'ViewItems_Events_Show',
    'ViewItems_Events_Edit',
    'ViewItems_Events_Add',
    'ViewItems_Events_ChangeActiveStateButton',
    'ViewItems_Events_DeactivateAccessUsingsButton',
    'ViewItems_Events_ManageCompanies',
  ],
  [Authority.EDIT_EVENTS]: ['ViewItems_Events_Edit'],
  [Authority.CREATE_EVENTS]: ['ViewItems_Events_Add'],
  [Authority.EVENTS_CHANGE_ACTIVE_STATE]: [
    'ViewItems_Events_ChangeActiveStateButton',
  ],
  [Authority.EVENTS_DEACTIVATE_ACCESS_USINGS]: [
    'ViewItems_Events_DeactivateAccessUsingsButton',
  ],
  [Authority.EVENTS_MANAGE_COMPANIES]: ['ViewItems_Events_ManageCompanies'],

  // Recurring jobs
  [Authority.VIEW_RECURRING_JOBS]: ['ViewItems_RecurringJobs_Show'],
  [Authority.DELETE_RECURRING_JOBS]: ['ViewItems_RecurringJobs_Delete'],
  [Authority.TRIGGER_RECURRING_JOBS]: ['ViewItems_RecurringJobs_Trigger'],

  // Jobs
  [Authority.VIEW_JOBS]: ['ViewItems_Jobs_Show'],
  [Authority.DELETE_JOBS]: ['ViewItems_Jobs_Delete'],

  // Objects
  [Authority.VIEW_OBJECTS]: [
    'ViewItems_Objects_Show',
    'ViewItems_Objects_Edit',
    'ViewItems_Objects_Add',
  ],
  [Authority.EDIT_OBJECTS]: ['ViewItems_Objects_Edit'],
  [Authority.CREATE_OBJECTS]: ['ViewItems_Objects_Add'],

  // Schemes
  [Authority.VIEW_SCHEMES]: [
    'ViewItems_Schemes_Show',
    'ViewItems_Schemes_Edit',
    'ViewItems_Schemes_Add',
  ],
  [Authority.EDIT_SCHEMES]: ['ViewItems_Schemes_Edit'],
  [Authority.CREATE_SCHEMES]: ['ViewItems_Schemes_Add'],

  // Seats
  [Authority.VIEW_SEATS]: [
    'ViewItems_Seats_Show',
    'ViewItems_Seats_Edit',
    'ViewItems_Seats_Add',
  ],
  [Authority.EDIT_SEATS]: ['ViewItems_Seats_Edit'],
  [Authority.CREATE_SEATS]: ['ViewItems_Seats_Add'],

  // Tickets
  [Authority.VIEW_TICKETS]: [
    'ViewItems_Tickets_Show',
    'ViewItems_Tickets_Edit',
    'ViewItems_Tickets_Add',
  ],
  [Authority.EDIT_TICKETS]: ['ViewItems_Tickets_Edit'],
  [Authority.CREATE_TICKETS]: ['ViewItems_Tickets_Add'],

  // Tribunes
  [Authority.VIEW_TRIBUNES]: [
    'ViewItems_Tribunes_Show',
    'ViewItems_Tribunes_Edit',
    'ViewItems_Tribunes_Add',
  ],
  [Authority.EDIT_TRIBUNES]: ['ViewItems_Tribunes_Edit'],
  [Authority.CREATE_TRIBUNES]: ['ViewItems_Tribunes_Add'],

  // Variants
  [Authority.VIEW_VARIANTS]: [
    'ViewItems_Variants_Show',
    'ViewItems_Variants_Edit',
    'ViewItems_Variants_Add',
  ],
  [Authority.EDIT_VARIANTS]: ['ViewItems_Variants_Edit'],
  [Authority.CREATE_VARIANTS]: ['ViewItems_Variants_Add'],

  // Cameras
  [Authority.VIEW_CAMERAS]: [
    'ViewItems_Cameras_Show',
    'ViewItems_Cameras_Edit',
    'ViewItems_Cameras_Add',
    'ViewItems_Cameras_PreviewButton',
  ],
  [Authority.EDIT_CAMERAS]: ['ViewItems_Cameras_Edit'],
  [Authority.CREATE_CAMERAS]: ['ViewItems_Cameras_Add'],
  [Authority.CAMERAS_PREVIEW_BUTTON]: ['ViewItems_Cameras_PreviewButton'],

  // Camera servers
  [Authority.VIEW_CAMERA_SERVERS]: [
    'ViewItems_CameraServers_Show',
    'ViewItems_CameraServers_Edit',
    'ViewItems_CameraServers_Add',
    'ViewItems_CameraServers_ManageCredentialsButton',
  ],
  [Authority.EDIT_CAMERA_SERVERS]: ['ViewItems_CameraServers_Edit'],
  [Authority.CREATE_CAMERA_SERVERS]: ['ViewItems_CameraServers_Add'],
  [Authority.CAMERA_SERVERS_MANAGE_CREDENTIALS_BUTTON]: [
    'ViewItems_CameraServers_ManageCredentialsButton',
  ],

  // Engines
  [Authority.VIEW_ENGINES]: [
    'ViewItems_Engines_Show',
    'ViewItems_Engines_Edit',
    'ViewItems_Engines_Add',
    'ViewItems_Engines_LockButton',
    'ViewItems_Engines_SetStandbyModeButton',
    'ViewItems_Engines_ManageCredentialsButton',
    'ViewItems_Engines_RestartTSCanButton',
    'ViewItems_Engines_ForceReportStateButton',
    'ViewItems_Engines_ResetModulesButton',
  ],
  [Authority.EDIT_ENGINES]: ['ViewItems_Engines_Edit'],
  [Authority.CREATE_ENGINES]: ['ViewItems_Engines_Add'],
  [Authority.ENGINES_LOCK_BUTTON]: ['ViewItems_Engines_LockButton'],
  [Authority.ENGINES_SET_STANDBY_MODE_BUTTON]: [
    'ViewItems_Engines_SetStandbyModeButton',
  ],
  [Authority.ENGINES_MANAGE_CREDENTIALS_BUTTON]: [
    'ViewItems_Engines_ManageCredentialsButton',
  ],
  [Authority.ENGINES_RESTART_TSCAN_BUTTON]: [
    'ViewItems_Engines_RestartTSCanButton',
  ],
  [Authority.ENGINES_FORCE_REPORT_STATE_BUTTON]: [
    'ViewItems_Engines_ForceReportStateButton',
  ],
  [Authority.ENGINES_RESET_MODULES_BUTTON]: [
    'ViewItems_Engines_ResetModulesButton',
  ],

  // Offline servers
  [Authority.VIEW_OFFLINE_SERVERS]: [
    'ViewItems_OfflineServers_Show',
    'ViewItems_OfflineServers_Edit',
    'ViewItems_OfflineServers_Add',
  ],
  [Authority.EDIT_OFFLINE_SERVERS]: ['ViewItems_OfflineServers_Edit'],
  [Authority.CREATE_OFFLINE_SERVERS]: ['ViewItems_OfflineServers_Add'],
  [Authority.OFFLINE_SERVERS_MANAGE_CREDENTIALS_BUTTON]: [
    'ViewItems_OfflineServers_ManageCredentialsButton',
  ],
  [Authority.OFFLINE_SERVERS_FORCE_REPORT_STATE_BUTTON]: [
    'ViewItems_OfflineServers_ForceReportStateButton',
  ],
  [Authority.OFFLINE_SERVERS_RESTORE_REPLICATION_BUTTON]: [
    'ViewItems_OfflineServers_RestoreReplicationButton',
  ],

  // Palms
  [Authority.VIEW_PALMS]: [
    'ViewItems_Palms_Show',
    'ViewItems_Palms_Edit',
    'ViewItems_Palms_Add',
    'ViewItems_Palms_LockButton',
    'ViewItems_Palms_SetMobileDeviceIdButton',
  ],
  [Authority.EDIT_PALMS]: ['ViewItems_Palms_Edit'],
  [Authority.CREATE_PALMS]: ['ViewItems_Palms_Add'],
  [Authority.PALMS_LOCK_BUTTON]: ['ViewItems_Palms_LockButton'],
  [Authority.PALMS_SET_MOBILE_DEVICE_ID_BUTTON]: [
    'ViewItems_Palms_SetMobileDeviceIdButton',
  ],

  // Palm scheme data
  [Authority.VIEW_PALM_SCHEME_DATA]: [
    'ViewItems_PalmSchemeData_Show',
    'ViewItems_PalmSchemeData_Edit',
    'ViewItems_PalmSchemeData_Add',
  ],
  [Authority.EDIT_PALM_SCHEME_DATA]: ['ViewItems_PalmSchemeData_Edit'],
  [Authority.CREATE_PALM_SCHEME_DATA]: ['ViewItems_PalmSchemeData_Add'],

  // Passages
  [Authority.VIEW_PASSAGES]: [
    'ViewItems_Passages_Show',
    'ViewItems_Passages_Edit',
    'ViewItems_Passages_Add',
    'ViewItems_Passages_ManageCredentialsButton',
    'ViewItems_Passages_RestartTSCanButton',
    'ViewItems_Passages_SetFreePassButton',
    'ViewItems_Passages_SetPowerButton',
    'ViewItems_Passages_SetPPOZButton',
    'ViewItems_Passages_LockButton',
    'ViewItems_Passages_ForceReportStateButton',
    'ViewItems_Passages_ResetModulesButton',
    'ViewItems_Passages_SetReversedRotationDirectionButton',
  ],
  [Authority.EDIT_PASSAGES]: ['ViewItems_Passages_Edit'],
  [Authority.CREATE_PASSAGES]: ['ViewItems_Passages_Add'],
  [Authority.PASSAGES_COUNTERS_EDIT]: ['ViewItems_PassagesCounters_Edit'],
  [Authority.PASSAGES_MANAGE_CREDENTIALS_BUTTON]: [
    'ViewItems_Passages_ManageCredentialsButton',
  ],
  [Authority.PASSAGES_RESTART_TSCAN_BUTTON]: [
    'ViewItems_Passages_RestartTSCanButton',
  ],
  [Authority.PASSAGES_SET_FREE_PASS_BUTTON]: [
    'ViewItems_Passages_SetFreePassButton',
  ],
  [Authority.PASSAGES_SET_POWER_BUTTON]: ['ViewItems_Passages_SetPowerButton'],
  [Authority.PASSAGES_SET_PPOZ_BUTTON]: ['ViewItems_Passages_SetPPOZButton'],
  [Authority.PASSAGES_LOCK_BUTTON]: ['ViewItems_Passages_LockButton'],
  [Authority.PASSAGES_FORCE_REPORT_STATE_BUTTON]: [
    'ViewItems_Passages_ForceReportStateButton',
  ],
  [Authority.PASSAGES_RESET_MODULES_BUTTON]: [
    'ViewItems_Passages_ResetModulesButton',
  ],
  [Authority.PASSAGES_SET_REVERSED_ROTATION_DIRECTION_BUTTON]: [
    'ViewItems_Passages_SetReversedRotationDirectionButton',
  ],
  [Authority.PASSAGES_SET_OPEN]: ['ViewItems_Passages_SetOpenButton'],

  // Detectors
  [Authority.VIEW_DETECTORS]: [
    'ViewItems_Detectors_Show',
    'ViewItems_Detectors_Edit',
    'ViewItems_Detectors_Add',
    'ViewItems_Detectors_RestartTSCanButton',
    'ViewItems_Detectors_ForceReportStateButton',
  ],
  [Authority.EDIT_DETECTORS]: ['ViewItems_Detectors_Edit'],
  [Authority.CREATE_DETECTORS]: ['ViewItems_Detectors_Add'],
  [Authority.DETECTORS_RESTART_TSCAN_BUTTON]: [
    'ViewItems_Detectors_RestartTSCanButton',
  ],
  [Authority.DETECTORS_FORCE_REPORT_STATE_BUTTON]: [
    'ViewItems_Detectors_ForceReportStateButton',
  ],

  // Device categories
  [Authority.VIEW_DEVICE_CATEGORIES]: [
    'ViewItems_DeviceCategories_Show',
    'ViewItems_DeviceCategories_Edit',
    'ViewItems_DeviceCategories_Add',
  ],
  [Authority.EDIT_DEVICE_CATEGORIES]: ['ViewItems_DeviceCategories_Edit'],
  [Authority.CREATE_DEVICE_CATEGORIES]: ['ViewItems_DeviceCategories_Add'],

  // Entrances
  [Authority.VIEW_ENTRANCES]: [
    'ViewItems_Entrances_Show',
    'ViewItems_Entrances_Edit',
    'ViewItems_Entrances_Add',
  ],
  [Authority.EDIT_ENTRANCES]: ['ViewItems_Entrances_Edit'],
  [Authority.CREATE_ENTRANCES]: ['ViewItems_Entrances_Add'],

  // Entrances sets
  [Authority.VIEW_ENTRANCES_SET]: [
    'ViewItems_EntrancesSet_Show',
    'ViewItems_EntrancesSet_Edit',
    'ViewItems_EntrancesSet_Clear',
    'ViewItems_EntrancesSet_Create',
    'ViewItems_EntrancesSet_Restore',
  ],
  [Authority.EDIT_ENTRANCES_SET]: ['ViewItems_EntrancesSet_Edit'],
  [Authority.CLEAR_ENTRANCES_SET]: ['ViewItems_EntrancesSet_Clear'],
  [Authority.CREATE_ENTRANCES_SET]: ['ViewItems_EntrancesSet_Create'],
  [Authority.RESTORE_ENTRANCES_SET]: ['ViewItems_EntrancesSet_Restore'],

  // CSI
  [Authority.VIEW_CSI]: ['ViewItems_CSISyncLogs_Show'],
  [Authority.SYNC_STADIUM_BANS]: ['ViewItems_CSI_SyncStadiumBans'],

  // Service link
  [Authority.VIEW_SERVICE_LINK]: ['ViewItems_KartaMieszkanca_Show'],
  [Authority.EDIT_SERVICE_LINK]: ['ViewItems_KartaMieszkanca_Edit'],
  [Authority.CREATE_SERVICE_LINK]: ['ViewItems_KartaMieszkanca_Add'],
  [Authority.SYNC_SERVICE_LINK]: [
    'ViewItems_KartaMieszkanca_SyncCardsWithBookings',
  ],

  // Depositors
  [Authority.VIEW_DEPOSITORS]: [
    'ViewItems_Depositors_Show',
    'ViewItems_Depositors_Edit',
    'ViewItems_Depositors_Add',
    'ViewItems_Depositors_ManageCredentialsButton',
  ],
  [Authority.EDIT_DEPOSITORS]: ['ViewItems_Depositors_Edit'],
  [Authority.CREATE_DEPOSITORS]: ['ViewItems_Depositors_Add'],
  [Authority.DEPOSITORS_MANAGE_CREDENTIALS_BUTTON]: [
    'ViewItems_Depositors_ManageCredentialsButton',
  ],

  // Dynamic Property Definitions
  [Authority.VIEW_DYNAMIC_PROPERTY_DEFINITIONS]: [
    'ViewItems_DynamicPropertyDefinitions_Show',
  ],
  [Authority.EDIT_DYNAMIC_PROPERTY_DEFINITIONS]: [
    'ViewItems_DynamicPropertyDefinitions_Edit',
  ],
  [Authority.CREATE_DYNAMIC_PROPERTY_DEFINITIONS]: [
    'ViewItems_DynamicPropertyDefinitions_Add',
  ],

  // Dynamic Counters
  [Authority.VIEW_DYNAMIC_COUNTERS]: [
    'ViewItems_DynamicCounters_Show',
    'ViewItems_DynamicCounters_Edit',
    'ViewItems_DynamicCounters_Add',
    'ViewItems_DynamicCounters_Delete',
  ],
  [Authority.EDIT_DYNAMIC_COUNTERS]: ['ViewItems_DynamicCounters_Edit'],
  [Authority.CREATE_DYNAMIC_COUNTERS]: ['ViewItems_DynamicCounters_Add'],
  [Authority.DELETE_DYNAMIC_COUNTERS]: ['ViewItems_DynamicCounters_Delete'],

  // Dynamic Counter Parameters
  [Authority.VIEW_DYNAMIC_COUNTER_PARAMETERS]: [
    'ViewItems_DynamicCounterParameters_Show',
    'ViewItems_DynamicCounterParameters_Edit',
    'ViewItems_DynamicCounterParameters_Add',
    'ViewItems_DynamicCounterParameters_Delete',
  ],
  [Authority.EDIT_DYNAMIC_COUNTER_PARAMETERS]: [
    'ViewItems_DynamicCounterParameters_Edit',
  ],
  [Authority.CREATE_DYNAMIC_COUNTER_PARAMETERS]: [
    'ViewItems_DynamicCounterParameters_Add',
  ],
  [Authority.DELETE_DYNAMIC_COUNTER_PARAMETERS]: [
    'ViewItems_DynamicCounterParameters_Delete',
  ],

  // Access Bonus
  [Authority.VIEW_ACCESS_BONUSES]: ['ViewItems_AccessBonuses_Show'],
  [Authority.EDIT_ACCESS_BONUSES]: ['ViewItems_AccessBonuses_Edit'],
  [Authority.CREATE_ACCESS_BONUSES]: ['ViewItems_AccessBonuses_Add'],

  // Devices
  [Authority.VIEW_DEVICES_BASE]: [
    ['ViewItems_DevicesBase_Show'],
    [
      'ViewItems_Engines_Show',
      'ViewItems_Palms_Show',
      'ViewItems_Depositors_Show',
      'ViewItems_Passages_Show',
      'ViewItems_Detectors_Show',
      'ViewItems_OfflineServers_Show',
    ],
  ],

  [Authority.VIEW_VERIFIER_DEVICES]: [
    ['ViewItems_VerifierDevices_Show'],
    [
      'ViewItems_Engines_Show',
      'ViewItems_Palms_Show',
      'ViewItems_Depositors_Show',
    ],
  ],

  // Verifier Device Disabled
  [Authority.VIEW_VERIFIER_DEVICE_DISABLED]: [
    'ViewItems_VerifierDeviceDisabled_Show',
  ],
  [Authority.EDIT_VERIFIER_DEVICE_DISABLED]: [
    'ViewItems_VerifierDeviceDisabled_Edit',
  ],
  [Authority.CREATE_VERIFIER_DEVICE_DISABLED]: [
    'ViewItems_VerifierDeviceDisabled_Add',
  ],

  // Verifier Scheme Data
  [Authority.VIEW_VERIFIER_SCHEME_DATA]: [
    'ViewItems_VerifierDeviceDisabled_Show',
  ],
  [Authority.EDIT_VERIFIER_SCHEME_DATA]: [
    'ViewItems_VerifierDeviceDisabled_Edit',
  ],
  [Authority.CREATE_VERIFIER_SCHEME_DATA]: [
    'ViewItems_VerifierDeviceDisabled_Add',
  ],

  // Doors
  [Authority.VIEW_DOORS]: [
    'ViewItems_Doors_Show',
    'ViewItems_Doors_Edit',
    'ViewItems_Doors_Add',
  ],
  [Authority.EDIT_DOORS]: ['ViewItems_Doors_Edit'],
  [Authority.CREATE_DOORS]: ['ViewItems_Doors_Add'],
  [Authority.DOORS_SET_LOCK]: ['ViewItems_Doors_LockButton'],
  [Authority.DOORS_SET_STANDBY]: ['ViewItems_Doors_SetStandbyModeButton'],
  [Authority.DOORS_FORCE_REPORT_STATE]: [
    'ViewItems_Doors_ForceReportStateButton',
  ],
  [Authority.DOORS_RESET_MODULES]: ['ViewItems_Doors_ResetModulesButton'],
  [Authority.DOORS_SET_RELEASE]: ['ViewItems_Doors_SetDoorReleasedButton'],

  //Terminals
  [Authority.VIEW_TERMINALS]: [
    'ViewItems_Terminals_Show',
    'ViewItems_Terminals_Edit',
    'ViewItems_Terminals_Add',
  ],
  [Authority.EDIT_TERMINALS]: ['ViewItems_Terminals_Edit'],
  [Authority.CREATE_TERMINALS]: ['ViewItems_Terminals_Add'],
  [Authority.TERMINALS_SET_LOCK]: ['ViewItems_Terminals_LockButton'],
  [Authority.TERMINALS_SET_STANDBY]: [
    'ViewItems_Terminals_SetStandbyModeButton',
  ],
  [Authority.TERMINALS_FORCE_REPORT_STATE]: [
    'ViewItems_Terminals_ForceReportStateButton',
  ],
  [Authority.TERMINALS_RESET_MODULES]: [
    'ViewItems_Terminals_ResetModulesButton',
  ],
  [Authority.TERMINALS_OPEN_FOR_SINGLE_VEHICLE]: [
    'ViewItems_Terminals_OpenForSingleVehicleButton',
  ],
  [Authority.TERMINALS_SET_PRINTER_POWER]: [
    'ViewItems_Terminals_SetPrinterPowerButton',
  ],
  [Authority.TERMINALS_SET_WORKING_MODE]: [
    'ViewItems_Terminals_SetWorkingModeButton',
  ],

  // TSCans
  [Authority.VIEW_TS_CANS]: [
    'ViewItems_TSCans_Show',
    'ViewItems_TSCans_Edit',
    'ViewItems_TSCans_Add',
  ],
  [Authority.EDIT_TS_CANS]: ['ViewItems_TSCans_Edit'],
  [Authority.CREATE_TS_CANS]: ['ViewItems_TSCans_Add'],
  [Authority.TS_CANS_MANAGE_CREDENTIALS]: [
    'ViewItems_TSCans_ManageCredentialsButton',
  ],
  [Authority.TS_CANS_RESTART]: ['ViewItems_TSCans_RestartButton'],
  // Ticket Imports
  [Authority.VIEW_TICKET_IMPORTS]: ['ViewItems_TicketImport_Show'],
  [Authority.IMPORT_TICKETS]: ['ViewItems_TicketImport_ImportButton'],
  [Authority.REVERT_TICKET_IMPORT]: ['ViewItems_TicketImport_RevertButton'],
  [Authority.ACCEPT_TICKET_IMPORT]: ['ViewItems_TicketImport_AcceptButton'],

  // Ticket Import Mappings
  [Authority.VIEW_TICKET_IMPORT_MAPPINGS]: [
    'ViewItems_TicketImportMappings_Show',
  ],
  [Authority.EDIT_TICKET_IMPORT_MAPPINGS]: [
    'ViewItems_TicketImportMappings_Edit',
  ],
  [Authority.CREATE_TICKET_IMPORT_MAPPINGS]: [
    'ViewItems_TicketImportMappings_Add',
  ],
  [Authority.DELETE_TICKET_IMPORT_MAPPINGS]: [
    'ViewItems_TicketImportMappings_Delete',
  ],

  // Ticket Import Templates
  [Authority.VIEW_TICKET_IMPORT_TEMPLATES]: [
    'ViewItems_TicketImportTemplates_Show',
  ],
  [Authority.EDIT_TICKET_IMPORT_TEMPLATES]: [
    'ViewItems_TicketImportTemplates_Edit',
  ],
  [Authority.CREATE_TICKET_IMPORT_TEMPLATES]: [
    'ViewItems_TicketImportTemplates_Add',
  ],
  [Authority.DELETE_TICKET_IMPORT_TEMPLATES]: [
    'ViewItems_TicketImportTemplates_Delete',
  ],
}

export const mapAuthorityToRequiredPermissions = (authority: Authority) =>
  mapping[authority]
