import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, {
  Children,
  cloneElement,
  MouseEventHandler,
  ReactElement,
} from 'react'
import {
  EditContextProvider,
  EditProps,
  OnFailure,
  OnSuccess,
  Record,
  RedirectionSideEffect,
  TransformData,
  useEditController,
  useTranslate,
} from 'react-admin'

const useStyles = makeStyles({
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '450px',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    marginLeft: '10px',
    marginRight: 'auto',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 0,
  },
  form: {
    width: '100%',
  },
})

/* eslint-disable @typescript-eslint/no-unused-vars */
const sanitizeRestProps = ({
  basePath,
  handleClose,
  hasCreate,
  hasEdit,
  hasShow,
  hasList,
  history,
  id,
  location,
  match,
  onFailure,
  onSuccess,
  options,
  permissions,
  children,
  onClose,
  title,
  open,
  ...rest
}: EditDialogProps): any => rest
/* eslint-enable @typescript-eslint/no-unused-vars */

export interface EditDialogProps
  extends Omit<EditProps, 'classes'>,
    Omit<DialogProps, 'id' | 'title'> {
  children?: ReactElement
  handleClose?: () => void
}

const EditDialogContentView = ({
  children,
  onClose,
  handleClose,
  title,
  ...props
}: Omit<EditDialogProps, 'open'> & {
  onClose: MouseEventHandler
}): ReactElement => {
  const controllerProps = useEditController(props as EditProps)
  const {
    basePath,
    record,
    redirect: redirectTo,
    resource,
    save,
    saving,
    version,
  } = controllerProps

  const translate = useTranslate()
  const classes = useStyles()

  const handleSave = (
    data: Partial<Record>,
    redirect?: RedirectionSideEffect | undefined,
    callbacks?:
      | {
          onSuccess?: OnSuccess | undefined
          onFailure?: OnFailure | undefined
          transform?: TransformData | undefined
        }
      | undefined,
  ) => {
    save(data, redirect, callbacks)
    handleClose?.()
  }

  return (
    <>
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
      >
        <Typography className={classes.bulkProgressTitle}>
          {translate(title as string)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={onClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <EditContextProvider value={controllerProps}>
          {cloneElement(Children.only(children as any), {
            basePath,
            record,
            redirect:
              typeof children?.props.redirect === 'undefined'
                ? redirectTo
                : children.props.redirect,
            resource,
            save: handleSave,
            saving,
            version,
            className: classes.form,
          })}
        </EditContextProvider>
      </DialogContent>
    </>
  )
}

export const EditDialog = (props: EditDialogProps): ReactElement => {
  const { open, id, handleClose } = props

  return (
    <Dialog
      open={open}
      aria-labelledby="edit-dialog-title"
      {...sanitizeRestProps(props)}
      title={undefined}
    >
      {id && id !== 'create' ? (
        <EditDialogContentView
          {...props}
          onClose={handleClose as MouseEventHandler}
        />
      ) : null}
    </Dialog>
  )
}
