import React, { ChangeEvent, FC, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  withStyles,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useTranslate } from 'react-admin'

const RoundInputBox = withStyles(({ palette }) => ({
  root: {
    borderRadius: 16,
    width: '90%',
    marginLeft: '5%',
    display: 'flex',
    flexDirection: 'row-reverse',
    border: 'solid',
    borderColor: palette.primary.dark,
  },
}))(Box)

const RoundInputBoxBtn = withStyles(({ palette }) => ({
  root: {
    background: palette.primary.dark,
    variant: 'contained',
    borderRadius: 11,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    margin: 0,
    fontWeight: 'bold',
    '&:hover': {
      background: palette.primary.main,
    },
  },
}))(Button)

const RoundInputBoxFC = withStyles({
  root: {
    width: '100%',
    marginLeft: 13,
    marginTop: 2,
  },
})(FormControl)

export type SearchInputProps = {
  label: string
  onValue: (value: string) => void
  disabled: boolean
  error: string
}

const SearchInput: FC<Readonly<SearchInputProps>> = ({
  label,
  onValue,
  disabled,
  error,
}) => {
  const [inputError, setInputError] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const t = useTranslate()

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleClick = () => {
    if (inputValue) {
      setInputError(false)
      onValue(inputValue)
    } else {
      setInputError(true)
    }
  }

  return (
    <RoundInputBox>
      <RoundInputBoxBtn onClick={handleClick} disabled={disabled}>
        <SearchIcon style={{ color: 'white' }} />
      </RoundInputBoxBtn>
      <RoundInputBoxFC disabled={disabled}>
        <Input
          disableUnderline
          placeholder={t(label)}
          onInput={handleInput}
          onKeyDown={(ev) => ev.key === 'Enter' && handleClick()}
          error={inputError || !!error}
        />
        {inputError && (
          <FormHelperText error>{t('ra.validation.required')}</FormHelperText>
        )}
        {!!error && <FormHelperText error>{t(error)}</FormHelperText>}
      </RoundInputBoxFC>
    </RoundInputBox>
  )
}

export default SearchInput
