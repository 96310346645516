const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  bonusName: (lambdaParameter, value) =>
    `${lambdaParameter}.Bonus.Name.Contains("${value}")`,
  bonusDescription: (lambdaParameter, value) =>
    `${lambdaParameter}.Bonus.Description.Contains("${value}")`,
  bonusId: (lambdaParameter, value) =>
    `${lambdaParameter}.BonusId.Equals(${value})`,
  entranceId: (lambdaParameter, value) =>
    `${lambdaParameter}.EntranceId.Equals(${value})`,
  allowReenter: (lambdaParameter) => `${lambdaParameter}.AllowReenter`,
  multipleEntry: (lambdaParameter) => `${lambdaParameter}.MultipleEntry`,
  isTicketUnlockedWithNextDay: (lambdaParameter) =>
    `${lambdaParameter}.IsTicketUnlockedWithNextDay`,
  filterVariantId: () => null,
  variantId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterVariantId === false
      ? null
      : `${lambdaParameter}.Variants.Any(v=>v.Id.Equals(${value}))`,
  variant: (lambdaParameter, value) =>
    `${lambdaParameter}.Variants.Any(v=>v.Id.Equals(${value}))`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
