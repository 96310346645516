import {
  MenuItem,
  Collapse,
  Tooltip,
  ListItemIcon,
  Typography,
  List,
  Slide,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'
import React, { ReactElement, ReactNode, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxState } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: theme.spacing(5),
    paddingLeft: theme.spacing(2),
  },
  sidebarIsOpen: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4),
    },
    '& li > div': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4),
    },
    '& div > a > div': {
      paddingLeft: theme.spacing(4),
    },
  },
  sidebarIsClosed: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  slideIn: {
    transitionDelay: '200ms',
  },
  header: {
    paddingLeft: 0,
  },
}))

interface SubMenuProps {
  handleToggle: () => void
  icon: ReactElement
  isOpen: boolean
  name: string
  children: ReactNode
}

export const SubMenu = (props: SubMenuProps) => {
  const { handleToggle, icon, isOpen, name, children } = props
  const [expandIconVisible, setExpandIconVisible] = useState(true)
  const sidebarOpen = useSelector(
    (state: ReduxState) => state?.admin.ui.sidebarOpen,
  )
  const classes = useStyles()
  const containerRef = useRef(null)

  const handleIconChange = () => {
    handleToggle()
    setTimeout(() => {
      setExpandIconVisible(!expandIconVisible)
    }, 100)
  }

  const header = (
    <MenuItem button onClick={handleIconChange} className={classes.header}>
      <ListItemIcon className={classes.icon}>
        {expandIconVisible ? (
          <Slide direction="down" in={isOpen} ref={containerRef.current}>
            <ExpandMore />
          </Slide>
        ) : (
          <Slide direction="up" in={!isOpen} ref={containerRef.current}>
            {icon}
          </Slide>
        )}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  )

  return (
    <>
      {sidebarOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={
            sidebarOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </>
  )
}
