const mapping: Record<string, string> = {
  id: 'p=>p.Id',
  ticketCode: 'p=>p.TicketCode',
  bookingId: 't=>t.BookingId',
  customerFirstname: 't=>t.Booking.Customer.Firstname',
  customerLastname: 't=>t.Booking.Customer.Lastname',
  customerPesel: 't=>t.Booking.Customer.Pesel',
  eventName: 't=>t.Booking.Event.Name',
}

export const mapSortTicketParam = (key: string) => mapping[key]
