import {
  Dialog,
  DialogActions,
  makeStyles,
  createStyles,
  DialogContent,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  useTranslate,
  useShowContext,
  useMutation,
  useRefresh,
  sanitizeFetchType,
  useNotify,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import { UserDto } from '../../../core/dto/user.dto'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import DraggableComponent from '../../common/DraggableComponent'

const useStyles = makeStyles(() =>
  createStyles({
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    bulkProgressTitle: {
      paddingLeft: '10px',
      width: '100%',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
  }),
)

export const ChangePasswordDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const classes = useStyles()
  const [value, setValue] = useState<string>('')
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { record: user } = useShowContext<UserDto>()
  const [mutate] = useMutation()
  const refresh = useRefresh()
  const translate = useTranslate()
  const notify = useNotify()

  const handleClose = () => {
    setValue('')
    setError(false)
    setErrorText('')
    close()
  }

  const handleValidate = () => {
    setError(false)
    setErrorText('')
    // if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(value)) {
    //   setError(true)
    //   setErrorText(
    //     'resources.users.dialogs.changePassword.error-password-requirements',
    //   )
    // } else if (value.replace(/^[a-zA-Z0-9]*$/, '').length !== 0) {
    //   setError(true)
    //   setErrorText('resources.users.dialogs.changePassword.error-bad-string')
    // } else
    setConfirmOpen(true)
  }

  const handleChangeUserPassword = async () => {
    setConfirmOpen(false)
    await mutate(
      {
        type: sanitizeFetchType('changeUserPassword'),
        resource: ResourceName.USERS,
        payload: {
          userId: user?.id,
          newPassword: value,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
    setValue('')
    refresh()
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleChangeUserPassword}
        source="changePassword"
        action="set"
        resource={ResourceName.USERS}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.users.dialogs.changePassword.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <TextField
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          error={error}
          helperText={translate(errorText)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleValidate}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}
