import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { TSCanDto } from '../../../core/dto/device/ts-cans/ts-can.dto'

const validateTSCanEdit = (values: TSCanDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.address) {
    errors.address = 'ra.validation.required'
  }
  return errors
}

const EditTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.ts-cans.titles.create')}</span>
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const TSCanEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />}>
    <SimpleForm
      redirect="show"
      validate={validateTSCanEdit}
      toolbar={<EditToolbar />}
    >
      <TextInput source="name" isRequired />
      <TextInput source="address" isRequired />
    </SimpleForm>
  </Edit>
)
