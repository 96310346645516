import React, { useEffect } from 'react'
import {
  TextField,
  makeStyles,
  Theme,
  createStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { useField } from 'react-final-form'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      verticalAlign: 'center',
      marginBottom: theme.spacing(1),
    },
    mobileContainer: {
      display: 'flex',
      justifyContent: 'center',
      verticalAlign: 'center',
      marginBottom: theme.spacing(1),
      flexDirection: 'column',
    },
    input: {
      alignSelf: 'center',
    },
    icon: {
      marginTop: '4%',
    },
    mobileIcon: {
      alignSelf: 'center',
      transform: 'rotate(90deg)',
    },
  }),
)

export const DateFilterInput = ({ source }: { source: string }) => {
  const classes = useStyles()
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const defaultFromDate = React.useMemo(
    () => new Date(Date.now() - 2678400000),
    [],
  )
  defaultFromDate.setUTCHours(0, 0, 0, 0)
  const defaultToDate = new Date(Date.now())
  defaultToDate.setUTCHours(23, 59, 0, 0)

  const [dateFrom, setDateFrom] = React.useState(
    defaultFromDate.getTime().toString(),
  )
  const [dateTo, setDateTo] = React.useState(defaultToDate.getTime().toString())

  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source)

  useEffect(() => {
    onChange(
      [parseInt(dateFrom, 10).toString(), parseInt(dateTo, 10).toString()].join(
        ';',
      ),
    )
  }, [dateFrom, dateTo, onChange, defaultFromDate])

  const convertedDate = (date: Date) =>
    new Date(date.getTime()).toJSON().substring(0, 16)

  return (
    <span
      key="date"
      className={xSmallScreen ? classes.mobileContainer : classes.container}
    >
      <TextField
        variant="filled"
        defaultValue={convertedDate(defaultFromDate)}
        name={source}
        type="datetime-local"
        onChange={(e) => setDateFrom(Date.parse(e.target.value).toString())}
        error={!!(touched && error)}
        helperText={touched && error}
        className={classes.input}
        size="small"
      />
      <ArrowRightAltIcon
        className={xSmallScreen ? classes.mobileIcon : classes.icon}
      />
      <TextField
        variant="filled"
        defaultValue={convertedDate(defaultToDate)}
        name={source}
        type="datetime-local"
        onChange={(e) => setDateTo(Date.parse(e.target.value).toString())}
        error={!!(touched && error)}
        helperText={touched && error}
        size="small"
      />
    </span>
  )
}
