import { useMediaQuery, useTheme } from '@material-ui/core'
import jsonexport from 'jsonexport'
import React, { cloneElement, useContext } from 'react'
import {
  Pagination,
  TextField,
  ReferenceField,
  Filter,
  ListProps,
  Datagrid,
  FunctionField,
  Record,
  TextInput,
  downloadCSV,
  TopToolbar,
  sanitizeListRestProps,
  ExportButton,
  CreateButton,
  FilterButton,
  FilterContext,
  useListContext,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { BookingDto } from '../../../core/dto/booking.dto'
import { BookingType } from '../../../core/enum/BookingType'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const ServiceBookingFilters = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <TextInput source="ticketCode" alwaysOn />
      {hasAuthority(Authority.VIEW_VARIANTS) && (
        <FilteredReferenceInput
          source="variantId"
          reference={ResourceName.VARIANTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
      <TextInput source="customer" />
    </Filter>
  )
}

const ServiceBookingListActions = ({ ...props }) => {
  const { exporter, filters: filtersProp, ...restProps } = props
  const resource = ResourceName.TICKETS
  const hasAuthority = useHasAuthority()

  const { currentSort, displayedFilters, filterValues, showFilter, total } =
    useListContext(props)
  const filters = useContext(FilterContext) || filtersProp

  return (
    <TopToolbar {...sanitizeListRestProps(restProps)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {!hasAuthority(Authority.CREATE_TICKETS) &&
        hasAuthority(Authority.CREATE_BOOKINGS) && (
          <CreateButton resource={ResourceName.BOOKINGS} basePath="/bookings" />
        )}
      {exporter !== false && (
        <ExportButton
          disabled={total === 0 || total >= 150000}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={150000}
        />
      )}
    </TopToolbar>
  )
}

const ServiceBookingListExport = (bookings: BookingDto[]) => {
  jsonexport(bookings, (err, csv) => {
    downloadCSV(csv, 'service-bookings')
  })
}

export const ServiceBookingList = (props: ListProps) => {
  const hasAuthority = useHasAuthority()

  return (
    <List
      {...props}
      exporter={ServiceBookingListExport}
      perPage={20}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      filters={<ServiceBookingFilters />}
      filter={{
        type: BookingType.SERVICE,
      }}
      bulkActionButtons={false}
      actions={<ServiceBookingListActions />}
    >
      <Datagrid
        rowClick={
          hasAuthority(Authority.VIEW_BOOKINGS)
            ? (id) => `${ResourceName.BOOKINGS}/${id}/show`
            : undefined
        }
        hasBulkActions={false}
      >
        <TextField source="id" label="ID" />
        {hasAuthority(Authority.VIEW_CUSTOMERS) && (
          <ReferenceField
            source="customerId"
            reference={ResourceName.CUSTOMERS}
            link="show"
          >
            <>
              <TextField source="id" />. <TextField source="firstname" />{' '}
              <TextField source="lastname" />
            </>
          </ReferenceField>
        )}
        {hasAuthority(Authority.VIEW_VARIANTS) && (
          <ReferenceField
            source="variantId"
            reference={ResourceName.VARIANTS}
            link="show"
          >
            <>
              <TextField source="id" />. <TextField source="name" />
            </>
          </ReferenceField>
        )}
        {hasAuthority(Authority.VIEW_TICKETS) && (
          <FunctionField<BookingDto>
            source="ticketCode"
            render={(booking?: BookingDto) => (
              <ReferenceField
                source="ticketId"
                record={
                  {
                    ...booking,
                    ticketId:
                      booking?.ticketsIds?.[
                        (booking?.ticketsIds?.length as number) - 1
                      ],
                  } as Record
                }
                reference={ResourceName.TICKETS}
                link={false}
              >
                <TextField source="ticketCode" />
              </ReferenceField>
            )}
          />
        )}
      </Datagrid>
    </List>
  )
}
