import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import CachedIcon from '@material-ui/icons/Cached'
import React, { useEffect, useState } from 'react'
import {
  SaveButton,
  SaveButtonProps,
  useNotify,
  useTranslate,
} from 'react-admin'
import Cron, { CronError, Locale } from 'react-js-cron-mui'
import Button, { ButtonProps } from './customized-mui-components/Button'
import DraggableComponent from './DraggableComponent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTopBar: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    title: {
      paddingLeft: '10px',
      width: '100%',
    },
    closeButton: {
      margin: 0,
    },
    cron: {
      '& .react-js-cron-select': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
          backgroundColor: theme.palette.action.focus,
        },
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        padding: '0 4px',
      },
      '& .react-js-cron-clear-button': {
        padding: '4px 10px',
        fontSize: '0.8125rem',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        alignSelf: 'baseline',
      },
    },
  }),
)

const translation = (translate: (key: string) => string): Locale => ({
  everyText: translate('common.recurring-job.cron-component.every-text'),
  emptyMonths: translate('common.recurring-job.cron-component.empty-months'),
  emptyMonthDays: translate(
    'common.recurring-job.cron-component.empty-month-days',
  ),
  emptyMonthDaysShort: translate(
    'common.recurring-job.cron-component.empty-month-days-short',
  ),
  emptyWeekDays: translate(
    'common.recurring-job.cron-component.empty-week-days',
  ),
  emptyWeekDaysShort: translate(
    'common.recurring-job.cron-component.empty-week-days-short',
  ),
  emptyHours: translate('common.recurring-job.cron-component.empty-hours'),
  emptyMinutes: translate('common.recurring-job.cron-component.empty-minutes'),
  emptyMinutesForHourPeriod: translate(
    'common.recurring-job.cron-component.empty-minutes-for-hour-period',
  ),
  yearOption: translate('common.recurring-job.cron-component.year-option'),
  monthOption: translate('common.recurring-job.cron-component.month-option'),
  weekOption: translate('common.recurring-job.cron-component.week-option'),
  dayOption: translate('common.recurring-job.cron-component.day-option'),
  hourOption: translate('common.recurring-job.cron-component.hour-option'),
  minuteOption: translate('common.recurring-job.cron-component.minute-option'),
  rebootOption: translate('common.recurring-job.cron-component.reboot-option'),
  prefixPeriod: translate('common.recurring-job.cron-component.prefix-period'),
  prefixMonths: translate('common.recurring-job.cron-component.prefix-months'),
  prefixMonthDays: translate(
    'common.recurring-job.cron-component.prefix-month-days',
  ),
  prefixWeekDays: translate(
    'common.recurring-job.cron-component.prefix-week-days',
  ),
  prefixWeekDaysForMonthAndYearPeriod: translate(
    'common.recurring-job.cron-component.prefix-week-days-for-month-and-year-period',
  ),
  prefixHours: translate('common.recurring-job.cron-component.prefix-hours'),
  prefixMinutes: translate(
    'common.recurring-job.cron-component.prefix-minutes',
  ),
  prefixMinutesForHourPeriod: translate(
    'common.recurring-job.cron-component.prefix-minutes-for-hour-period',
  ),
  suffixMinutesForHourPeriod: translate(
    'common.recurring-job.cron-component.suffix-minutes-for-hour-period',
  ),
  errorInvalidCron: translate(
    'common.recurring-job.cron-component.error-invalid-cron',
  ),
  clearButtonText: translate(
    'common.recurring-job.cron-component.clear-button-text',
  ),
  weekDays: [
    // Order is important, the index will be used as value
    translate('common.recurring-job.cron-component.dayOfWeek.Sunday'), // Sunday must always be first, it's "0"
    translate('common.recurring-job.cron-component.dayOfWeek.Monday'),
    translate('common.recurring-job.cron-component.dayOfWeek.Tuesday'),
    translate('common.recurring-job.cron-component.dayOfWeek.Wednesday'),
    translate('common.recurring-job.cron-component.dayOfWeek.Thursday'),
    translate('common.recurring-job.cron-component.dayOfWeek.Friday'),
    translate('common.recurring-job.cron-component.dayOfWeek.Saturday'),
  ],
  months: [
    // Order is important, the index will be used as value
    translate('common.recurring-job.cron-component.months.January'),
    translate('common.recurring-job.cron-component.months.February'),
    translate('common.recurring-job.cron-component.months.March'),
    translate('common.recurring-job.cron-component.months.April'),
    translate('common.recurring-job.cron-component.months.May'),
    translate('common.recurring-job.cron-component.months.June'),
    translate('common.recurring-job.cron-component.months.July'),
    translate('common.recurring-job.cron-component.months.August'),
    translate('common.recurring-job.cron-component.months.September'),
    translate('common.recurring-job.cron-component.months.October'),
    translate('common.recurring-job.cron-component.months.November'),
    translate('common.recurring-job.cron-component.months.December'),
  ],
  // Order is important, the index will be used as value
  altWeekDays: [
    translate('resources.enums.dayOfWeekShort.Sunday'), // Sunday must always be first, it's "0"
    translate('resources.enums.dayOfWeekShort.Monday'),
    translate('resources.enums.dayOfWeekShort.Tuesday'),
    translate('resources.enums.dayOfWeekShort.Wednesday'),
    translate('resources.enums.dayOfWeekShort.Thursday'),
    translate('resources.enums.dayOfWeekShort.Friday'),
    translate('resources.enums.dayOfWeekShort.Saturday'),
  ],
  // Order is important, the index will be used as value
  altMonths: [
    translate('resources.enums.monthsShort.January'),
    translate('resources.enums.monthsShort.February'),
    translate('resources.enums.monthsShort.March'),
    translate('resources.enums.monthsShort.April'),
    translate('resources.enums.monthsShort.May'),
    translate('resources.enums.monthsShort.June'),
    translate('resources.enums.monthsShort.July'),
    translate('resources.enums.monthsShort.August'),
    translate('resources.enums.monthsShort.September'),
    translate('resources.enums.monthsShort.October'),
    translate('resources.enums.monthsShort.November'),
    translate('resources.enums.monthsShort.December'),
  ],
})

export const ComposeCronExpressionDialog = ({
  open,
  close,
  saveButton,
}: {
  open: boolean
  close: () => void
  saveButton: (
    recurringJobName: string,
    cronExpression: string,
    disabled: boolean,
    closeDialog: () => void,
  ) => JSX.Element
}) => {
  const [cronExpression, setCronExpression] = useState<string>('0 0 * * *')
  const [error, onError] = useState<CronError>()
  const [recurringJobName, setRecurringJobName] = useState<string>('')
  const classes = useStyles()
  const translate = useTranslate()
  const handleClose = () => {
    close()
  }

  useEffect(() => {
    if (!open) {
      setCronExpression('0 0 * * *')
      setRecurringJobName('')
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
      maxWidth="md"
    >
      <DialogActions
        className={classes.dialogTopBar}
        style={{ cursor: 'move' }}
      >
        <Typography className={classes.title} id="draggable-dialog-title">
          {translate('common.recurring-job.dialog.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <TextField
              error={recurringJobName.length === 0}
              helperText={
                recurringJobName.length === 0
                  ? translate('ra.validation.required')
                  : ''
              }
              required
              label={translate('common.recurring-job.dialog.fields.job-name')}
              variant="filled"
              value={recurringJobName}
              onChange={(e) => setRecurringJobName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              error={error !== undefined}
              helperText={error ? error.description : ''}
              label={translate(
                'common.recurring-job.dialog.fields.cron-expression',
              )}
              variant="filled"
              value={cronExpression}
              onChange={(e) => setCronExpression(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                <Divider />
              </Grid>
              <Grid item xs={2}>
                <Typography align="center">OR</Typography>
              </Grid>
              <Grid item xs={5}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Cron
              className={classes.cron}
              value={cronExpression}
              setValue={setCronExpression}
              onError={onError}
              leadingZero
              allowEmpty="for-default-value"
              clearButtonAction="fill-with-every"
              locale={translation(translate)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {saveButton(
          recurringJobName,
          cronExpression,
          recurringJobName.length === 0 || error !== undefined,
          handleClose,
        )}
      </DialogActions>
    </Dialog>
  )
}

export interface CreateRecurringJobButtonProps extends ButtonProps {
  saveRecurringJob: (recurringJobName: string, cronExpression: string) => void
}

export const CreateRecurringJobButton = (
  props: CreateRecurringJobButtonProps,
) => {
  const { saveRecurringJob, ...rest } = props
  const [
    composeCronExpressionDialogDialogOpen,
    setComposeCronExpressionDialogDialogOpen,
  ] = useState(false)
  const translate = useTranslate()

  const handleOpenDialog = () => setComposeCronExpressionDialogDialogOpen(true)

  const handleOnClick = (recurringJobName: string, cronExpression: string) => {
    saveRecurringJob(recurringJobName, cronExpression)
    setComposeCronExpressionDialogDialogOpen(false)
  }

  return (
    <>
      <ComposeCronExpressionDialog
        open={composeCronExpressionDialogDialogOpen}
        close={() => setComposeCronExpressionDialogDialogOpen(false)}
        saveButton={(recurringJobName, cronExpression, disabled) => (
          <Button
            startIcon={<CachedIcon />}
            label={translate('common.recurring-job.buttons.save-as-recurring')}
            variant="contained"
            size="small"
            onClick={() => handleOnClick(recurringJobName, cronExpression)}
            disabled={disabled}
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<CachedIcon />}
        size="medium"
        label={translate('common.recurring-job.buttons.create-recurring-job')}
        useSmallVersionBreakpoint={false}
        {...(rest as ButtonProps)}
        onClick={handleOpenDialog}
      />
    </>
  )
}

export interface SaveContextAsRecurringJobButtonProps extends SaveButtonProps {
  recurringJobName: string
  cronExpression: string
}

export const SaveContextAsRecurringJobButton = (
  props: SaveContextAsRecurringJobButtonProps,
) => {
  const notify = useNotify()
  const translate = useTranslate()
  const { recurringJobName, cronExpression, ...rest } = props
  return (
    <SaveButton
      icon={<CachedIcon />}
      label={translate('common.recurring-job.buttons.save-as-recurring')}
      submitOnEnter={false}
      {...rest}
      onClick={() =>
        notify(
          `${translate(
            'common.recurring-job.notifications.recurring-job-created-part-1',
          )} '${recurringJobName}' ${translate(
            'common.recurring-job.notifications.recurring-job-created-part-2',
          )}`,
        )
      }
      transform={(data) => ({
        ...data,
        recurringJobName,
        cronExpression,
      })}
    />
  )
}

export const CreateRecurringJobUsingSaveContextButton = (
  props: SaveButtonProps,
) => {
  const {
    handleSubmitWithRedirect,
    onSave,
    onSuccess,
    onFailure,
    transform,
    invalid,
    redirect,
    saving,
    submitOnEnter,
    basePath,
    handleSubmit,
    record,
    resource,
    undoable,
  } = props
  const [
    composeCronExpressionDialogDialogOpen,
    setComposeCronExpressionDialogDialogOpen,
  ] = useState(false)
  // const hasAuthority = useHasAuthority()
  const translate = useTranslate()

  const handleOpenDialog = () => setComposeCronExpressionDialogDialogOpen(true)

  return (
    <>
      <ComposeCronExpressionDialog
        open={composeCronExpressionDialogDialogOpen}
        close={() => setComposeCronExpressionDialogDialogOpen(false)}
        saveButton={(recurringJobName, cronExpression, disabled) => (
          <SaveContextAsRecurringJobButton
            {...{
              handleSubmitWithRedirect,
              onSave,
              onSuccess,
              onFailure,
              transform,
              invalid,
              redirect,
              saving,
              submitOnEnter,
              basePath,
              handleSubmit,
              record,
              resource,
              undoable,
            }}
            size="small"
            recurringJobName={recurringJobName}
            cronExpression={cronExpression}
            disabled={disabled}
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<CachedIcon />}
        size="medium"
        label={translate('common.recurring-job.buttons.create-recurring-job')}
        useSmallVersionBreakpoint={false}
        {...(props as ButtonProps)}
        onClick={handleOpenDialog}
        // disabled={
        //   !hasAuthority(Authority.RESTORE_ENTRANCES_SET)
        // }
      />
    </>
  )
}
