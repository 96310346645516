import { mapping as deviceMapping } from '../common/device-base/device-base-sort.mapper'

export const mapping: Record<string, string> = {
  ...deviceMapping,
  address: 'p=>p.Address',
  hardwareVersion: 'p=>p.hardwareVersion',
}

const mapSortDetectorParam = (key: string) => mapping[key]

export default mapSortDetectorParam
