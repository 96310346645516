import React from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { ResourceName } from '../../../../core/ResourceName'
import List from '../../../common/customized-ra-components/List'
import FilterProps from '../../../common/FilterProps'

const CameraServerFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

export const CameraServerList = ({ ...props }: ListProps) => {
  const translate = useTranslate()

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
      filters={<CameraServerFilters />}
      bulkActionButtons={false}
    >
      <Datagrid
        size="small"
        rowClick={(id) => `${ResourceName.CAMERA_SERVERS}/${id}/show`}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <TextField source="address" />
        <FunctionField
          source="type"
          render={(record) =>
            translate(`resources.enums.CameraTypes.${record?.type}`)
          }
        />
      </Datagrid>
    </List>
  )
}
