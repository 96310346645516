import {
  Box,
  makeStyles,
  Menu,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { useMemo } from 'react'
import {
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import List from '../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import FilterProps from '../../common/FilterProps'
import QuickFilter from '../../common/QuickFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import {
  CheckIfAnyModuleHasError,
  CheckLastRefreshError,
  DeviceStatusesPreview,
} from '../common/modules/ModuleStatus'
import { EngineAttachEventRecurringBulkButton } from './engine-list/EngineAttachEventRecurringBulkButton'
import { EngineDetachEventRecurringBulkButton } from './engine-list/EngineDetachEventRecurringBulkButton'
import { EngineLockBulkButton } from './engine-list/EngineLockBulkButton'
import { EngineStandbyModeBulkButton } from './engine-list/EngineStandbyModeBulkButton'
import { EngineUnlockBulkButton } from './engine-list/EngineUnlockBulkButton'
import { EngineStandbyModeCompactButton } from './engine-list/EngineStandbyModeCompactButton'
import { DeviceCategoryExtendedDto } from '../../../core/dto/device-category/device-category-extended.dto'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'

const useStyles = makeStyles((theme) => ({
  compactStatus: {
    display: 'flex',
    gap: theme.spacing(0.5),
    height: 34,
    cursor: 'default',
  },
}))

const EngineFilters = (props: FilterProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          label="resources.palms.fields.categoryId"
          source="categoryIdWithDescendants"
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      <QuickFilter source="eventActive" defaultValue />
    </Filter>
  )
}

const EngineGridExpand = ({ ...props }) => {
  const { record } = props
  return (
    <DeviceStatusesPreview
      jsonStatus={record.jsonStatus}
      deviceType={DeviceTypes.ENGINE}
    />
  )
}

const EngineBulkActions = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const EngineLockAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <EngineLockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const EngineUnlockAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <EngineUnlockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const EngineLockRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <EngineLockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const EngineUnlockRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <EngineUnlockBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const EngineStandbyModeAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <EngineStandbyModeBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const EngineStandbyModeRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <EngineStandbyModeBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
          asRecurringJob
        />
      )),
    [props.selectedIds],
  )

  const EngineAttachEventRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <EngineAttachEventRecurringBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  const EngineDetachEventRecurringAction = useMemo(
    () =>
      React.forwardRef((ref, forwardRefProps) => (
        <EngineDetachEventRecurringBulkButton
          selectedIds={props.selectedIds as number[]}
          innerRef={ref}
          {...forwardRefProps}
        />
      )),
    [props.selectedIds],
  )

  return (
    <div>
      <Button
        onClick={handleClick}
        label="const.selectAction"
        variant="text"
        color="default"
        useSmallVersionBreakpoint={false}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <EngineLockAction />
        <EngineLockRecurringAction />
        <EngineUnlockAction />
        <EngineUnlockRecurringAction />
        <EngineStandbyModeAction />
        <EngineStandbyModeRecurringAction />
        <EngineAttachEventRecurringAction />
        <EngineDetachEventRecurringAction />
      </Menu>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CompactStatus = ({ ...props }) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.compactStatus}
      onClick={(event) => event.stopPropagation()}
    >
      <EngineStandbyModeCompactButton />
    </Box>
  )
}

export const EngineList = ({ ...props }: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  const postRowStyle = (record: any) => {
    const engineError = CheckLastRefreshError(record.lastRefresh)
    let engineWarning = false
    if (!engineError) {
      // Check for modules error
      engineWarning = CheckIfAnyModuleHasError(
        record.jsonStatus,
        DeviceTypes.ENGINE,
      )
    }
    if (engineError) {
      return {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      }
    }
    if (engineWarning) {
      return {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
      }
    }
    return {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    }
  }

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<EngineFilters />}
      bulkActionButtons={<EngineBulkActions />}
    >
      <Datagrid
        rowStyle={postRowStyle}
        size="small"
        rowClick={(id) => `${ResourceName.ENGINES}/${id}/show`}
        isRowExpandable={(record) =>
          record?.jsonStatus !== undefined && record?.jsonStatus !== null
        }
        expand={<EngineGridExpand />}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link={false}
            sortBy="name"
          >
            <FunctionField<DeviceCategoryExtendedDto>
              source="hierarchyString"
              render={(record?: DeviceCategoryExtendedDto) =>
                `${record?.id}. ${record?.hierarchyString}`
              }
            />
          </ReferenceField>
        )}
        {!smallScreen && hasAuthority(Authority.VIEW_VERIFIER_DEVICE_DISABLED) && (
          <ReferenceField
            source="disabledId"
            reference={ResourceName.VERIFIER_DEVICE_DISABLED}
            link={false}
            sortBy="name"
            label="resources.engines.fields.disabled"
          >
            <FunctionField
              source="name"
              render={(record) => `${record?.id}. ${record?.name}`}
            />
          </ReferenceField>
        )}
        <CompactStatus source="status" sortable={false} />
      </Datagrid>
    </List>
  )
}
