import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
  DeleteParams,
} from 'react-admin'
import { DYNAMIC_STATISTIC_PARAMETERS_URL } from '../../../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../../common/ticket-api-get-list.params'
import { DynamicStatisticParameterDto } from '../../../dto/dynamic-statistic-parameter.dto'
import { DynamicStatisticParameterTypes } from '../../../enum/DynamicStatisticParameterTypes'
import filterMapper from './dynamic-statistic-parameters-filter.mapper'
import { mapSortDynamicStatisticParametersParam } from './dynamic-statistic-parameters-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, pagination, sort }: TicketAPIGetListParams,
  ): Promise<GetListResult<DynamicStatisticParameterDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortDynamicStatisticParametersParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DynamicStatisticParameterDto[]>(
      `${DYNAMIC_STATISTIC_PARAMETERS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DynamicStatisticParameterDto>> => {
    const data = await get<DynamicStatisticParameterDto>(
      DYNAMIC_STATISTIC_PARAMETERS_URL,
      `/Get/${id}`,
    )

    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<DynamicStatisticParameterDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      UpdateDynamicStatisticPropertyRequest,
      DynamicStatisticParameterDto
    >(`${DYNAMIC_STATISTIC_PARAMETERS_URL}/Patch`, {
      id: id as number,
      key: data.key !== previousData.key ? data.key : undefined,
      dynamicallyDefined:
        data.dynamicallyDefined !== previousData.dynamicallyDefined
          ? data.dynamicallyDefined
          : undefined,
      value: data.value !== previousData.value ? data.value : undefined,
      description:
        data.description !== previousData.description
          ? data.description
          : undefined,
      type: data.type !== previousData.type ? data.type : undefined,
      statisticId:
        data.statisticId !== previousData.statisticId
          ? data.statisticId
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<DynamicStatisticParameterDto>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateDynamicStatisticPropertyRequest[],
      DynamicStatisticParameterDto[]
    >(`${DYNAMIC_STATISTIC_PARAMETERS_URL}/Post`, [{ ...data }])
    return Promise.resolve({ data: created[0] })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<CreateResult> => {
    const deleted = await remove<DynamicStatisticParameterDto>(
      DYNAMIC_STATISTIC_PARAMETERS_URL,
      `/Delete/${id}`,
    )

    return Promise.resolve({ data: deleted })
  },
} as DataProvider

interface CreateDynamicStatisticPropertyRequest {
  key: string
  dynamicallyDefined: boolean
  value?: string
  description?: string
  type: DynamicStatisticParameterTypes
  statisticId: number
}

interface UpdateDynamicStatisticPropertyRequest {
  id: number
  key?: string
  dynamicallyDefined?: boolean
  value?: string
  description?: string
  type?: DynamicStatisticParameterTypes
  statisticId?: number
}

export default provider
