import { Paper } from '@material-ui/core'
import React from 'react'
import Draggable from 'react-draggable'

const DraggableComponent = ({ ...props }: any) => {
  const nodeRef = React.useRef(null)
  const handle = `#${props['aria-labelledby']}` || '#draggable-dialog-title'
  return (
    <Draggable
      nodeRef={nodeRef}
      handle={handle}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  )
}

export default DraggableComponent
