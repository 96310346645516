import { DataProvider } from 'react-admin'
import { CameraServerDto } from '../../dto/device/camera/camera-servers/camera-server.dto'

const fakeServers: CameraServerDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeServers, total: fakeServers.length }),
} as DataProvider

export default provider
