import React, { ReactNode } from 'react'
import { BooleanInput } from 'react-admin'
import filterMapper from '../../core/common/relation-tab-filter.mapper'
import { ResourceName } from '../../core/ResourceName'
import FilterProps from './FilterProps'

export interface RelationTabFilterProps extends FilterProps {
  readonly children?: ReactNode[]
  readonly source?: string
  readonly resource: string
}

const relationTabFilter = (props: RelationTabFilterProps) => {
  const { children, source, resource } = props

  return [
    ...(children ?? []),
    <BooleanInput
      label="common.related-records.actions.attached-only"
      source={source || filterMapper(resource as ResourceName)}
      alwaysOn
    />,
  ]
}

export default relationTabFilter
