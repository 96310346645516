import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import {
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { EntrancesSetDto } from '../../../../core/dto/entrances-set.dto'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button from '../../../common/customized-mui-components/Button'
import DraggableComponent from '../../../common/DraggableComponent'
import FilteredReferenceInput from '../../../common/FilteredReferenceInput'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogProperty: {
      marginBottom: theme.spacing(1),
    },
    dialogTopBar: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    title: {
      paddingLeft: '10px',
      width: '100%',
    },
    closeButton: {
      margin: 0,
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
    formToolbar: {
      backgroundColor: 'inherit',
      padding: 0,
      margin: 0,
    },
    dialogContent: {
      padding: '8px',
    },
  }),
)

const validateRestoreEntrancesSet = (values: EntrancesSetDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.id) {
    errors.id = 'ra.validation.required'
  }
  return errors
}

export const RestoreEntrancesSetDialog = ({
  open,
  close,
  setSelectedEntrancesSetId,
  handleRestoreEntrancesSet,
  asRecurringJob,
}: {
  open: boolean
  close: () => void
  setSelectedEntrancesSetId: (value: number | undefined) => void
  handleRestoreEntrancesSet: (
    recurringJobName?: string,
    cronExpression?: string,
  ) => void
  asRecurringJob?: boolean
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const Actions = (props: ToolbarProps) => (
    <Toolbar {...props} className={classes.formToolbar}>
      <SaveButton
        variant="contained"
        size="small"
        className={classes.actionsButton}
        onSave={(values) => {
          setSelectedEntrancesSetId((values as EntrancesSetDto)?.id)
          setConfirmOpen(true)
        }}
        onSubmit={() => setConfirmOpen(true)}
      />
      <Button
        variant="contained"
        onClick={handleClose}
        label={translate('const.cancel')}
        useSmallVersionBreakpoint={false}
        className={classes.actionsButton}
      />
    </Toolbar>
  )

  useEffect(() => {
    if (!open) {
      setSelectedEntrancesSetId(undefined)
      setConfirmOpen(false)
    }
  }, [open, setSelectedEntrancesSetId, setConfirmOpen])

  const handleConfirm = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (asRecurringJob)
      handleRestoreEntrancesSet(recurringJobName, cronExpression)
    else handleRestoreEntrancesSet()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => {
          setSelectedEntrancesSetId(undefined)
          setConfirmOpen(false)
        }}
        confirm={handleConfirm}
        source="restoreEntrancesSet"
        action="set"
        resource={ResourceName.ENTRANCES_MATRIX}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.dialogTopBar}
        style={{ cursor: 'move' }}
      >
        <Typography className={classes.title} id="draggable-dialog-title">
          {translate(
            'resources.entrances-matrix.dialogs.restoreEntrancesSet.title',
          )}
        </Typography>
        <IconButton
          size="small"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <SimpleForm
          toolbar={<Actions />}
          validate={validateRestoreEntrancesSet}
        >
          <FilteredReferenceInput
            label="resources.entrances-matrix.dialogs.restoreEntrancesSet.fields.entrancesSet"
            source="id"
            reference={ResourceName.ENTRANCES_SET}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="search"
            required
            selectWithPaginationInputProps={{
              optionText: 'description',
              showFilter: true,
            }}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}
