const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  key: (lambdaParameter, value) =>
    `${lambdaParameter}.Key.Contains("${value}")`,
  type: (lambdaParameter, value) =>
    `${lambdaParameter}.Type.Equals("${value}")`,
  statisticId: (lambdaParameter, value) =>
    `${lambdaParameter}.StatisticId.Equals(${value})`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
