import { CardContent, CardHeader } from '@material-ui/core'
import { stringify } from 'querystring'
import * as React from 'react'
import { useCallback, useState } from 'react'
import {
  GetListResult,
  GET_LIST,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import { TicketDto } from '../../core/dto/ticket.dto'
import { ResourceName } from '../../core/ResourceName'
import SearchInput from './SearchInput'

export const FindUserByTicket = () => {
  const history = useHistory()
  const notify = useNotify()
  const [error, setError] = useState('')
  const [mutate, { loading }] = useMutation()
  const t = useTranslate()

  const fetchTickets = useCallback(
    (ticketCode: string) =>
      mutate(
        {
          type: sanitizeFetchType(GET_LIST),
          resource: ResourceName.TICKETS,
          payload: {
            filter: { ticketCode },
          },
        },
        {
          returnPromise: true,
          onFailure: (err) => notify(err?.message, 'error'),
        },
      ) as Promise<GetListResult<TicketDto>>,
    [mutate, notify],
  )

  const onValue = useCallback(
    async (ticketCode: string) => {
      setError('')
      const {
        total,
        data: [onlyTicket],
      } = await fetchTickets(ticketCode)
      if (!total) {
        setError('common.find-user-by-ticket.error')
      } else if (total === 1) {
        history.push({
          pathname: `/${ResourceName.BOOKINGS}/${onlyTicket.bookingId}/show`,
        })
      } else {
        history.push({
          pathname: `/${ResourceName.TICKETS}`,
          search: stringify({
            filter: JSON.stringify({
              ticketCode,
            }),
          }),
        })
      }
    },
    [fetchTickets, history],
  )

  return (
    <>
      <CardHeader title={t('common.find-user-by-ticket.title')} />
      <CardContent>
        <SearchInput
          label="common.find-user-by-ticket.input-label"
          onValue={onValue}
          disabled={loading}
          error={error}
        />
      </CardContent>
    </>
  )
}
