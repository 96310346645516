import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  ListButton,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { validateDynamicStatisticCreate } from './DynamicStatisticsCreate'

const EditTitle = () => {
  const translate = useTranslate()
  return (
    <span>{translate('resources.stats-dynamic-statistics.titles.edit')}</span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const DynamicStatisticEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />}>
    <SimpleForm
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateDynamicStatisticCreate}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" />
      <TextInput source="sqlQuery" fullWidth multiline />
    </SimpleForm>
  </Edit>
)
