import {
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Inbox } from '@material-ui/icons'
import React, { cloneElement, useContext } from 'react'
import {
  DateField,
  Filter,
  Pagination,
  ReferenceField,
  ResourceComponentProps,
  TextField,
  TextInput,
  useTranslate,
  SelectInput,
  FunctionField,
  Datagrid,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  FilterContext,
  FilterButton,
  CreateButton,
  downloadCSV,
  ExportButton,
} from 'react-admin'
import jsonexport from 'jsonexport'
import { Authority } from '../../core/auth/Authority'
import { TicketDto } from '../../core/dto/ticket.dto'
import { BookingType } from '../../core/enum/BookingType'
import { ResourceName } from '../../core/ResourceName'
import List from '../common/customized-ra-components/List'
import DropdownButton from '../common/DropdownButton'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import FilterProps from '../common/FilterProps'
import { MaskField } from '../common/MaskField'
import QuickFilter from '../common/QuickFilter'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { TicketImportButton } from '../ticket-imports/TicketImportButton'

const useStyles = makeStyles((theme: Theme) => ({
  inactiveTicketCode: {
    color: theme.palette.error.main,
    textDecoration: 'line-through',
    textDecorationColor: theme.palette.error.main,
  },
  emptyMessage: {
    textAlign: 'center',
    opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
    margin: '0 1em',
    color:
      theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
  },
  emptyIcon: {
    width: '9em',
    height: '9em',
  },
  emptyToolbar: {
    marginTop: '2em',
    display: 'flex',
    gap: '1em',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const TicketExpand = () => {
  const t = useTranslate()
  return (
    <Box padding={1}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {t('resources.tickets.fields.bookingId')}
        </Grid>
        <Grid item xs={8} style={{ textAlign: 'center' }}>
          <TextField source="bookingId" />
        </Grid>
      </Grid>
    </Box>
  )
}

const TicketFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <TextInput source="ticketCode" alwaysOn />
      {hasAuthority(Authority.VIEW_EVENTS) && (
        <FilteredReferenceInput
          label="resources.tickets.fields.event"
          source="eventId"
          reference={ResourceName.EVENTS}
          sort={{ field: 'dateStart', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
      <SelectInput
        source="bookingType"
        choices={Object.entries(BookingType).map(([value]) => ({
          id: value,
          name: `resources.enums.bookingType.${value}`,
        }))}
      />
      {hasAuthority(Authority.VIEW_OBJECTS) && (
        <FilteredReferenceInput
          source="objectId"
          reference={ResourceName.OBJECTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_VARIANTS) && (
        <FilteredReferenceInput
          source="variantId"
          reference={ResourceName.VARIANTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
      <QuickFilter source="isBookingBanned" defaultValue />
      <TextInput source="customerName" />
    </Filter>
  )
}

const TicketListActions = ({ ...props }) => {
  const { exporter, filters: filtersProp, ...restProps } = props
  const resource = ResourceName.TICKETS
  const hasAuthority = useHasAuthority()

  const { currentSort, displayedFilters, filterValues, showFilter, total } =
    useListContext(props)
  const filters = useContext(FilterContext) || filtersProp

  return (
    <TopToolbar {...sanitizeListRestProps(restProps)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      {hasAuthority(Authority.CREATE_TICKETS) &&
        hasAuthority(Authority.CREATE_BOOKINGS) && (
          <DropdownButton
            variant="text"
            mainAction={
              <CreateButton
                resource={ResourceName.TICKETS}
                label="const.import"
                variant="text"
                color="primary"
                size="small"
              />
            }
          >
            <CreateButton
              resource={ResourceName.BOOKINGS}
              basePath="/bookings"
            />
          </DropdownButton>
        )}
      {hasAuthority(Authority.CREATE_TICKETS) &&
        hasAuthority(Authority.IMPORT_TICKETS) &&
        hasAuthority(Authority.VIEW_TICKET_IMPORT_TEMPLATES) && (
          <TicketImportButton
            buttonProps={{ label: 'Import 2.0', variant: 'text' }}
          />
        )}
      {!hasAuthority(Authority.CREATE_TICKETS) &&
        hasAuthority(Authority.CREATE_BOOKINGS) && (
          <CreateButton resource={ResourceName.BOOKINGS} basePath="/bookings" />
        )}
      {exporter !== false && (
        <ExportButton
          disabled={total === 0 || total >= 150000}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={150000}
        />
      )}
    </TopToolbar>
  )
}

const TicketEmpty = () => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <div className={classes.emptyMessage}>
        <Inbox className={classes.emptyIcon} />
        <Typography variant="h4" paragraph>
          {translate('resources.tickets.empty')}
        </Typography>
        <Typography variant="body1">
          {translate('resources.tickets.invite')}
        </Typography>
      </div>
      <div className={classes.emptyToolbar}>
        <CreateButton
          resource={ResourceName.BOOKINGS}
          basePath="/bookings"
          variant="contained"
        />
        <CreateButton
          resource={ResourceName.TICKETS}
          label="const.import"
          color="primary"
          size="small"
          variant="contained"
        />
      </div>
    </>
  )
}

const TicketListExport = (tickets: TicketDto[]) => {
  jsonexport(tickets, (err, csv) => {
    downloadCSV(csv, 'tickets')
  })
}

export const TicketList = (props: ResourceComponentProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const hasAuthority = useHasAuthority()

  return (
    <List
      {...props}
      exporter={TicketListExport}
      perPage={20}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      filters={<TicketFilters />}
      bulkActionButtons={false}
      actions={<TicketListActions />}
      empty={<TicketEmpty />}
    >
      <Datagrid
        padding={smallScreen ? 'checkbox' : 'default'}
        rowClick={
          hasAuthority(Authority.VIEW_BOOKINGS)
            ? (id, basePath, record) =>
                `${ResourceName.BOOKINGS}/${record?.bookingId}/show`
            : undefined
        }
        isRowExpandable={() => xSmallScreen}
        expand={<TicketExpand />}
        hasBulkActions={false}
      >
        {!smallScreen &&
          hasAuthority(Authority.VIEW_BOOKINGS) &&
          hasAuthority(Authority.VIEW_CUSTOMERS) && (
            <ReferenceField
              source="bookingId"
              label="resources.tickets.fields.firstname"
              reference={ResourceName.BOOKINGS}
              link={false}
              sortBy="customerFirstname"
            >
              <ReferenceField
                source="customerId"
                reference={ResourceName.CUSTOMERS}
                link={false}
              >
                <TextField source="firstname" />
              </ReferenceField>
            </ReferenceField>
          )}
        {!smallScreen &&
          hasAuthority(Authority.VIEW_BOOKINGS) &&
          hasAuthority(Authority.VIEW_CUSTOMERS) && (
            <ReferenceField
              source="bookingId"
              label="resources.tickets.fields.lastname"
              reference={ResourceName.BOOKINGS}
              link={false}
              sortBy="customerLastname"
            >
              <ReferenceField
                source="customerId"
                reference={ResourceName.CUSTOMERS}
                link={false}
              >
                <TextField source="lastname" />
              </ReferenceField>
            </ReferenceField>
          )}
        {!smallScreen &&
          hasAuthority(Authority.VIEW_BOOKINGS) &&
          hasAuthority(Authority.VIEW_CUSTOMERS) && (
            <ReferenceField
              source="bookingId"
              label="resources.tickets.fields.pesel"
              reference={ResourceName.BOOKINGS}
              link={false}
              sortBy="customerPesel"
            >
              <ReferenceField
                source="customerId"
                reference={ResourceName.CUSTOMERS}
                link={false}
              >
                <MaskField source="pesel" />
              </ReferenceField>
            </ReferenceField>
          )}
        {smallScreen &&
          hasAuthority(Authority.VIEW_BOOKINGS) &&
          hasAuthority(Authority.VIEW_CUSTOMERS) && (
            <ReferenceField
              source="bookingId"
              label="resources.tickets.fields.customer"
              reference={ResourceName.BOOKINGS}
              link={false}
              sortBy="customerPesel"
            >
              <ReferenceField
                source="customerId"
                reference={ResourceName.CUSTOMERS}
                link={false}
              >
                <>
                  <TextField source="firstname" />{' '}
                  <TextField source="lastname" /> <MaskField source="pesel" />
                </>
              </ReferenceField>
            </ReferenceField>
          )}
        <FunctionField<TicketDto>
          source="ticketCode"
          render={(record?: TicketDto) =>
            record?.active === true ? (
              <TextField source="ticketCode" />
            ) : (
              <TextField
                source="ticketCode"
                className={classes.inactiveTicketCode}
              />
            )
          }
        />
        {hasAuthority(Authority.VIEW_BOOKINGS) &&
          hasAuthority(Authority.VIEW_EVENTS) && (
            <ReferenceField
              source="bookingId"
              label="resources.tickets.fields.event"
              reference={ResourceName.BOOKINGS}
              link={false}
              sortBy="eventName"
            >
              <ReferenceField
                source="eventId"
                reference={ResourceName.EVENTS}
                link={false}
              >
                <>
                  <TextField source="name" /> <DateField source="dateStart" /> (
                  <TextField source="id" />)
                </>
              </ReferenceField>
            </ReferenceField>
          )}
        {!xSmallScreen && <TextField source="bookingId" />}
      </Datagrid>
    </List>
  )
}
