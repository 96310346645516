import { Dialog, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'
import {
  LinearProgress,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRefresh,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { RestoreEntrancesSetResponse } from '../../../../core/entrances-set/entrances-set.provider'
import { ResourceName } from '../../../../core/ResourceName'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { RestoreEntrancesSetDialog } from './RestoreEntrancesSetDialog'
import { RestoreEntrancesSetWarningsDialog } from './RestoreEntrancesSetWarningsDialog'

export const RestoreEntrancesSetButton = ({ ...props }) => {
  const { asRecurringJob, ...rest } = props
  const notify = useNotify()
  const [selectedEntrancesSetDialogOpen, setSelectedEntrancesSetDialogOpen] =
    useState(false)
  const [selectedEntrancesSetId, setSelectedEntrancesSetId] = useState<
    number | undefined
  >(undefined)
  const [responseWithWarnings, setResponseWithWarnings] = useState<
    RestoreEntrancesSetResponse | undefined
  >(undefined)
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  const handleRestoreEntrancesSetDialog = () => {
    setSelectedEntrancesSetDialogOpen(true)
  }

  const handleRestoreEntrancesSet = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    let query
    if (!asRecurringJob) {
      query = {
        type: sanitizeFetchType('restore'),
        resource: ResourceName.ENTRANCES_SET,
        payload: {
          entranceSetId: selectedEntrancesSetId,
          recurringJobName,
          cronExpression,
        },
      }
    } else {
      query = {
        type: sanitizeFetchType('restore'),
        resource: ResourceName.ENTRANCES_SET,
        payload: {
          entranceSetId: selectedEntrancesSetId,
        },
      }
    }
    const response = (
      await mutate(query, {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      })
    )?.data as RestoreEntrancesSetResponse
    notify(
      response.isSuccessfull
        ? 'resources.entrances-matrix.notifications.entranceSetRestored'
        : 'resources.entrances-matrix.notifications.entranceSetRestoringError',
    )
    if (response?.warnings?.length > 0) setResponseWithWarnings(response)
    setSelectedEntrancesSetDialogOpen(false)
    setSelectedEntrancesSetId(undefined)
    refresh()
  }

  return (
    <>
      <Dialog id="in-progress-dialog" open={mutationLoading}>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <RestoreEntrancesSetDialog
        open={selectedEntrancesSetDialogOpen}
        close={() => setSelectedEntrancesSetDialogOpen(false)}
        setSelectedEntrancesSetId={setSelectedEntrancesSetId}
        handleRestoreEntrancesSet={handleRestoreEntrancesSet}
        asRecurringJob={asRecurringJob}
      />
      <RestoreEntrancesSetWarningsDialog
        status={responseWithWarnings}
        close={() => setResponseWithWarnings(undefined)}
      />
      <Button
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        label={
          !asRecurringJob
            ? 'resources.entrances-matrix.actions.restoreEntrancesSet'
            : 'common.recurring-job.buttons.create-recurring-job'
        }
        useSmallVersionBreakpoint={false}
        {...rest}
        onClick={handleRestoreEntrancesSetDialog}
        disabled={
          !hasAuthority(Authority.RESTORE_ENTRANCES_SET) || mutationLoading
        }
      />
    </>
  )
}
