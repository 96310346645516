import { LocalFile, parse, ParseResult } from 'papaparse'

export const processCsvFile = async (importedFile: LocalFile) =>
  new Promise<string[][]>((res, rej) =>
    parse(
      importedFile,
      // default values https://www.papaparse.com/docs#config
      {
        skipEmptyLines: true,
        complete: (results: ParseResult<any>) => res(results.data),
        error: (error: Error) => rej(error),
      },
    ),
  )

export const processCsvData = (columnNames: string[], csvData: string[][]) =>
  csvData.reduce((prev, curr) => {
    const targetModelEntries = columnNames.map((v, index) => [
      v,
      curr[index] ?? null,
    ])
    return [...prev, Object.fromEntries(targetModelEntries)]
  }, [] as Array<Record<string, string | null>>)

export const detectExactFields = (
  fieldNames: string[],
  columnNames: string[],
) =>
  fieldNames.reduce(
    (prev, curr) =>
      columnNames.includes(curr)
        ? { ...prev, [curr]: curr }
        : { ...prev, [curr]: '' },
    {} as Record<string, string>,
  )
