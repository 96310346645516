import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  UpdateParams,
  UpdateResult,
  Identifier,
  DeleteParams,
  DeleteResult,
} from 'react-admin'
import { TICKET_IMPORT_TEMPLATES_URL } from '../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import filterMapper from './ticket-import-template-filter.mapper'
import { mapSortTicketImportTemplateParam } from './ticket-import-template-sort.mapper'
import { TicketImportTemplateDto } from '../dto/ticket-import-template.dto'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<TicketImportTemplateDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortTicketImportTemplateParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<TicketImportTemplateDto[]>(
      `${TICKET_IMPORT_TEMPLATES_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<TicketImportTemplateDto>> => {
    const data = await get<TicketImportTemplateDto>(
      TICKET_IMPORT_TEMPLATES_URL,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource,
    { data }: CreateParams<CreateTicketImportTemplateRequest>,
  ): Promise<CreateResult<TicketImportTemplateDto>> => {
    const object = await post<
      CreateTicketImportTemplateRequest[],
      TicketImportTemplateDto[]
    >(TICKET_IMPORT_TEMPLATES_URL, [data])
    return {
      data: object[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      TicketImportTemplateDto
    >(TICKET_IMPORT_TEMPLATES_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
    })
    return Promise.resolve({ data: update })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<TicketImportTemplateDto>> => {
    const data = await get<TicketImportTemplateDto[]>(
      TICKET_IMPORT_TEMPLATES_URL,
      `/GetByConditions/o=>new int[] {${ids.toString()}}.Contains(o.Id)`,
    )
    return Promise.resolve({ data })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    await remove<undefined>(TICKET_IMPORT_TEMPLATES_URL, `/Delete/${id}`)
    return Promise.resolve({ data: { id } })
  },
} as DataProvider

export interface CreateTicketImportTemplateRequest {
  readonly name: string
}

export default provider
