export enum ToggleType {
  SETID = 'SETID',
  RESETID = 'RESETID',
}

export type ToggleButtonState = {
  type: ToggleType
  text: string
}

export type ToggleButtonAction = { type: ToggleType }
