import { ButtonGroup } from '@material-ui/core'
import React from 'react'
import {
  Create,
  CreateProps,
  TextField,
  TextInput,
  useTranslate,
  sanitizeFetchType,
  GET_LIST,
  Pagination,
  Datagrid,
  ResourceContextProvider,
  List,
  TopToolbar,
  useListContext,
  useEditContext,
  useMutation,
  useNotify,
} from 'react-admin'
import { useField } from 'react-final-form'
import { RoleDto } from '../../../core/dto/role/role.dto'
import { ResourceName } from '../../../core/ResourceName'
import {
  WizardForm,
  WizardFormStep,
} from '../../../lib/@react-admin/ra-form-layout/esm/src'
import { CheckboxField } from '../../common/CheckboxField'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'

const validateRoleCreate = (values: RoleDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.roles.titles.create')}</span>
}

const RelationInputTab = ({ source }: { source: keyof RoleDto }) => {
  const [mutate] = useMutation()
  const { basePath } = useEditContext<RoleDto>()
  const {
    input: { onChange, value: relationsIds },
  } = useField<number[]>(source)

  const handleAttach = (idsToAttach: number[]) => {
    onChange([...relationsIds, ...idsToAttach])
  }

  const handleDetach = (idsToDetach: number[]) => {
    onChange(relationsIds.filter((id: number) => !idsToDetach.includes(id)))
  }

  const isAttached = (relatedRecordId: number) =>
    relationsIds?.includes(relatedRecordId)

  const listSort = () => ({ field: 'name', order: 'ASC' })

  const listColumns = () => [
    <TextField source="id" label="ID" key="id" />,
    <TextField source="name" key="name" />,
  ]

  const Actions = () => {
    const { filterValues } = useListContext<RoleDto>()
    const notify = useNotify()
    const getFilteredRelatedRecordsIds = async () =>
      (
        await mutate(
          {
            type: sanitizeFetchType(GET_LIST),
            resource: ResourceName.PERMISSIONS,
            payload: {
              filter: {
                ...filterValues,
              },
            },
          },
          {
            returnPromise: true,
            onFailure: (err) => notify(err?.message, 'error'),
          },
        )
      )?.data?.map((record: RoleDto) => record?.id)

    const handleAttachAll = async () =>
      handleAttach(await getFilteredRelatedRecordsIds())

    const handleDetachAll = async () =>
      handleDetach(await getFilteredRelatedRecordsIds())

    return (
      <TopToolbar>
        {/* <FilterButton/> */}
        <ButtonGroup>
          <Button
            variant="contained"
            onClick={() => handleAttachAll()}
            label="common.related-records.actions.attach-all"
            useSmallVersionBreakpoint={false}
          />
          <Button
            variant="outlined"
            onClick={() => handleDetachAll()}
            label="common.related-records.actions.detach-all"
            useSmallVersionBreakpoint={false}
          />
        </ButtonGroup>
      </TopToolbar>
    )
  }

  const AttachedCheckbox = ({ ...props }) => (
    <CheckboxField
      {...props}
      sortable={false}
      checkBySource={(id: number) => (id ? isAttached(id) : false)}
      source="id"
      onClick={(id: number) =>
        isAttached(id as number)
          ? () => handleDetach([id as number])
          : () => handleAttach([id as number])
      }
    />
  )

  return (
    <ResourceContextProvider value={ResourceName.PERMISSIONS}>
      <List
        basePath={basePath}
        exporter={false}
        title=" "
        sort={listSort()}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
        perPage={20}
        actions={<Actions />}
        bulkActionButtons={false}
      >
        <Datagrid size="small" style={{ tableLayout: 'fixed' }}>
          {listColumns()}
          <AttachedCheckbox label="common.related-records.fields.is-attached" />
        </Datagrid>
      </List>
    </ResourceContextProvider>
  )
}

export const RoleCreate = (props: CreateProps) => {
  const hasAuthority = useHasAuthority()

  return (
    <Create {...props} title={<CreateTitle />}>
      <WizardForm redirect="show" validate={validateRoleCreate}>
        <WizardFormStep label="resources.roles.tabs.general">
          <TextInput source="name" isRequired />
          <TextInput source="description" fullWidth multiline />
        </WizardFormStep>
        {hasAuthority(Authority.VIEW_ROLES) && (
          <WizardFormStep label="resources.roles.tabs.permissions">
            <RelationInputTab source="permissionsIds" />
          </WizardFormStep>
        )}
      </WizardForm>
    </Create>
  )
}
