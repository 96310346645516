import { MenuItem, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useListContext,
  sanitizeFetchType,
  GET_MANY,
  GetManyResult,
  useTranslate,
} from 'react-admin'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { ApiErrorDto } from '../../../../core/dto/error-handling/api-error.dto'
import {
  ProgressStatus,
  StateBulkProgressDialog,
} from '../../../common/StateBulkProgressDialog'
import { BulkActionProps } from '../../../common/BulkActionsProps'
import { PalmLockDialog } from '../PalmDialogs'
import { PalmDto } from '../../../../core/dto/device/palm/palm.dto'

export const PalmLockBulkButton = ({
  selectedIds,
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  innerRef,
  asRecurringJob,
  ...props
}: BulkActionProps) => {
  const { loading, refetch } = useListContext<PalmDto>()
  const notify = useNotify()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const [selectStateDialogOpen, setSelectStateDialogOpen] = useState(false)
  const [mutate, { loading: mutationLoading }] = useMutation()
  const [disabledId, setDisabledId] = useState(0)
  const [bulkStateDialogOpen, setBulkStateDialogOpen] = useState(false)
  const [bulkProgressStatus, setBulkProgressStatus] = useState<
    ProgressStatus[]
  >([])
  const updateBulkProgressStatus = (newStatus: ProgressStatus) => {
    const oldStatusIndex = bulkProgressStatus.findIndex(
      (s) => s.id === newStatus.id,
    )
    bulkProgressStatus.splice(oldStatusIndex, 1, newStatus)
  }
  const [selectedPalms, setSelectedPalms] = useState<PalmDto[]>([])

  const handleMutation = async (
    palm: PalmDto,
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    let error
    let query
    if (!asRecurringJob) {
      query = {
        type: sanitizeFetchType('lock'),
        resource: ResourceName.PALMS,
        payload: {
          palmId: palm.id,
          disabledId,
        },
      }
    } else {
      query = {
        type: sanitizeFetchType('lock'),
        resource: ResourceName.PALMS,
        payload: {
          palmId: palm.id,
          disabledId,
          recurringJobName,
          cronExpression,
        },
      }
    }
    let result = null
    try {
      result = await mutate(query, {
        returnPromise: true,
      })
    } catch (err) {
      error = err
    }
    if (error) {
      updateBulkProgressStatus({
        id: palm.id,
        status: 'error',
        error: error as ApiErrorDto,
      } as ProgressStatus)
      return error
    }
    updateBulkProgressStatus({
      id: palm.id,
      status: 'success',
    } as ProgressStatus)
    return result
  }

  const handleLock = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    setBulkStateDialogOpen(true)
    setSelectStateDialogOpen(false)
    const pendingRequests: Promise<any>[] = []
    const selectedPalmsResponse = (await mutate(
      {
        type: sanitizeFetchType(GET_MANY),
        resource: ResourceName.PALMS,
        payload: {
          ids: selectedIds,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => {
          notify(err?.message, 'error')
          setBulkStateDialogOpen(false)
        },
      },
    )) as GetManyResult<PalmDto>
    if (!selectedPalmsResponse.data.length) {
      setBulkStateDialogOpen(false)
      return
    }
    selectedPalmsResponse.data.forEach((palm: PalmDto) => {
      pendingRequests.push(
        handleMutation(palm, recurringJobName, cronExpression),
      )
      selectedPalms.push(palm)
      bulkProgressStatus.push({
        id: palm.id,
        status: 'pending',
      } as ProgressStatus)
    })
    await Promise.all(pendingRequests)
    refetch()
  }

  useEffect(() => {
    if (!bulkStateDialogOpen) {
      setBulkProgressStatus([])
      setSelectedPalms([])
    }
  }, [bulkStateDialogOpen])

  const handleDialogClose = () => {
    setSelectStateDialogOpen(false)
  }

  return (
    <MenuItem
      {...props}
      disabled={
        !selectedIds.length ||
        !hasAuthority(Authority.PALMS_LOCK_BUTTON) ||
        loading ||
        mutationLoading
      }
      onClick={() => setSelectStateDialogOpen(true)}
      button
    >
      <Typography>
        {translate(
          !asRecurringJob
            ? 'resources.palms.actions.lock'
            : 'resources.palms.actions.lock-recurring',
        )}
      </Typography>
      <PalmLockDialog
        open={selectStateDialogOpen}
        close={handleDialogClose}
        disabledId={disabledId}
        setDisabledId={setDisabledId}
        handleLock={handleLock}
        asRecurringJob={asRecurringJob}
      />
      <StateBulkProgressDialog
        open={bulkStateDialogOpen}
        close={() => setBulkStateDialogOpen(false)}
        elements={selectedPalms}
        method="lock"
        statuses={bulkProgressStatus}
        resource={ResourceName.PALMS}
      />
    </MenuItem>
  )
}
