import { DataProvider } from 'react-admin'
import { DepositorDto } from '../../dto/device/depositor/depositor.dto'

const fakeDepositors: DepositorDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeDepositors, total: fakeDepositors.length }),
} as DataProvider

export default provider
