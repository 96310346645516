export enum ToggleType {
  RESET = 'RESET',
  DISABLED = 'DISABLED',
}

export type ToggleButtonState = {
  type: ToggleType
  text: string
}

export type ToggleButtonAction = { type: ToggleType }
