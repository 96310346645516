const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  isSuccessfull: (lambdaParameter, value) =>
    `${lambdaParameter}.IsSuccessfull.Equals(${value})`,
  createdAt: (lambdaParameter, value) =>
    `${lambdaParameter}.CreatedAt.Equals(${value})`,
  createdFrom: (lambdaParameter, value) =>
    `${lambdaParameter}.CreatedAt > DateTime.Parse("${value}")`,
  createdTo: (lambdaParameter, value) =>
    `${lambdaParameter}.CreatedAt < DateTime.Parse("${value}")`,
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
