import { createStyles, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useNotify,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { PassageDto } from '../../../../core/dto/device/passages/passage.dto'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: '2px',
    },
  }),
)

export const PassageForceReportStateButton = () => {
  const { record: passage, loading } = useShowContext<PassageDto>()
  const classes = useStyles()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const notify = useNotify()

  const handleMutate = async () => {
    setConfirmDialogOpen(false)
    await mutate(
      {
        type: sanitizeFetchType('forceReportState'),
        resource: ResourceName.PASSAGES,
        payload: {
          id: passage?.id,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
  }

  const handleConfirm = () => {
    setConfirmDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        confirm={handleMutate}
        source="forceReportState"
        cancel={() => setConfirmDialogOpen(false)}
        action="set"
        resource={ResourceName.PASSAGES}
      />
      <Button
        variant="contained"
        onClick={handleConfirm}
        color="primary"
        className={classes.button}
        label="resources.passages.actions.forceReportState"
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.PASSAGES_FORCE_REPORT_STATE_BUTTON) ||
          loading ||
          mutationLoading
        }
      />
    </>
  )
}
