import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { BLOCKS_URL } from '../api-urls'
import { get, getWithPagination, patch, post } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { BlockDto } from '../dto/block.dto'
import { BlockCreateParams } from './blocks-create.params'
import filterMapper from './blocks-filter.mapper'
import { mapSortBlockParam } from './blocks-sort.mapper'

type CreateBlockRequest = Array<BlockCreateParams>

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<BlockDto>> => {
    const filterParams = `b=>${filterParamsComposer('b', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortBlockParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<BlockDto[]>(
      `${BLOCKS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id: blockId }: GetOneParams,
  ): Promise<GetOneResult<BlockDto>> => {
    const block = await get<BlockDto>(BLOCKS_URL, `/${blockId}`)
    return {
      data: block as BlockDto,
    }
  },
  create: async (
    resource,
    { data }: CreateParams<BlockCreateParams>,
  ): Promise<CreateResult> => {
    const request: CreateBlockRequest = [data]
    await post<CreateBlockRequest, BlockDto[]>(BLOCKS_URL, request)
    return Promise.resolve({
      data: { id: 0 },
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<BlockDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, BlockDto>(
      BLOCKS_URL,
      {
        id,
        name: data.name !== previousData.name ? data.name : undefined,
        tribuneId:
          data.tribuneId !== previousData.tribuneId
            ? data.tribuneId
            : undefined,
        type: data.type !== previousData.type ? data.type : undefined,
        code: data.code !== previousData.code ? data.code : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<BlockDto>> => {
    const data = await get<BlockDto[]>(
      BLOCKS_URL,
      `/GetByConditions/b=>new int[] {${ids.toString()}}.Contains(b.Id)`,
    )
    return Promise.resolve({ data })
  },
} as DataProvider

export default provider
