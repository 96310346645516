import { Box, Divider, Grid } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { TSCanDto } from '../../../core/dto/device/ts-cans/ts-can.dto'
import { TSCanRemoveCredentialsButton } from './ts-can-show/TSCanRemoveCredentialsButton'
import { TSCanSetCredentialsButton } from './ts-can-show/TSCanSetCredentialsButton'
import { TSCanRestartButton } from './ts-can-show/TSCanRestartButton'

interface TSCanTitleProps extends TitleProps {
  record?: TSCanDto
}

const ShowTitle = (data: TSCanTitleProps) => {
  const { record: terminal } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.ts-cans.titles.show')}: {terminal?.id}.{' '}
      {terminal?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: tscan } = useShowContext<TSCanDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item style={{ margin: '2px' }}>
            <TSCanSetCredentialsButton />
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <TSCanRemoveCredentialsButton />
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <TSCanRestartButton />
          </Grid>
          <Grid item>
            <EditButton
              basePath={`/${ResourceName.TS_CANS}`}
              record={tscan}
              variant="contained"
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_TS_CANS)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export const TSCanShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <SimpleShowLayout {...props}>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="address" />
      <GeneralActions />
    </SimpleShowLayout>
  </Show>
)
