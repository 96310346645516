import { DataProvider } from 'react-admin'
import { TicketImportTemplateDto } from '../../dto/ticket-import-template.dto'

const fakeTicketImportTemplates: TicketImportTemplateDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeTicketImportTemplates,
      total: fakeTicketImportTemplates.length,
    }),
} as DataProvider

export default provider
