import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { PALMS_URL } from '../../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  recurringJobParamsComposer,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { PalmEntrancesDto } from '../../dto/device/palm/palm-entrances.dto'
import { PalmEventsDto } from '../../dto/device/palm/palm-events.dto'
import { PalmDto } from '../../dto/device/palm/palm.dto'
import { RecurringJobCreationParamsDto } from '../../dto/recurring-job-creation-params.dto'
import { RecurringJobDetailsDto } from '../../dto/recurring-job-details.dto'
import { DeviceDirection } from '../../enum/DeviceDirection'
import { Multiplicities } from '../../enum/Multiplicities'
import { PalmResetMobileDeviceIdResults } from '../../enum/PalmResetMobileDeviceIdResults'
import { PalmSetMobileDeviceIdResults } from '../../enum/PalmSetMobileDeviceIdResults'
import { getDeviceCategoryChildren } from '../devices.utils'
import filterMapper from './palm-filter.mapper'
import mapSortPalmParam from './palm-sort.mapper'
import { PalmThemes } from '../../enum/PalmThemes'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<PalmDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortPalmParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<PalmDto[]>(`${PALMS_URL}/GetByConditions`, path)

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<PalmDto>> => {
    const data = await get<PalmDto>(PALMS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<PalmDto>> => {
    const data = await get<PalmDto[]>(
      PALMS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreatePalmRequest>,
  ): Promise<CreateResult<PalmDto>> => {
    const palm = await post<CreatePalmRequest[], PalmDto[]>(PALMS_URL, [data])
    return {
      data: palm[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<PalmDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, PalmDto>(
      PALMS_URL,
      {
        id,
        name: data.name !== previousData.name ? data.name : undefined,
        isWatched:
          data.isWatched !== previousData.isWatched
            ? data.isWatched
            : undefined,
        direction:
          data.direction !== previousData.direction
            ? data.direction
            : undefined,
        categoryId:
          data.categoryId !== previousData.categoryId
            ? data.categoryId
            : undefined,
        cameraId:
          data.cameraId !== previousData.cameraId ? data.cameraId : undefined,
        onlineInfixCeck:
          data.onlineInfixCeck !== previousData.onlineInfixCeck
            ? data.onlineInfixCeck
            : undefined,
        multiplicity:
          data.multiplicity !== previousData.multiplicity
            ? data.multiplicity
            : undefined,
        theme: data.theme !== previousData.theme ? data.theme : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  lock: async (
    resource: string,
    { palmId, disabledId, ...rest }: LockParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const lockPalm = await put<
      LockParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${PALMS_URL}/Lock/${query ? `?${query}` : ''}`, {
      palmId,
      disabledId,
    })
    if ((lockPalm as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: lockPalm as RecurringJobDetailsDto,
      }
    return {
      data: lockPalm,
    }
  },
  unlock: async (
    resource: string,
    { palmId, ...rest }: UnlockParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const unlockPalm = await put<
      UnlockParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${PALMS_URL}/Unlock/${query ? `?${query}` : ''}`, {
      palmId,
    })
    if ((unlockPalm as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: unlockPalm as RecurringJobDetailsDto,
      }
    return {
      data: unlockPalm,
    }
  },
  setMobileDeviceId: async (
    resource: string,
    { palmId, mobileDeviceId }: SetMobileDeviceIdParams,
  ): Promise<DataProviderExtensionResult<SetMobileDeviceIdResponse>> => {
    const data = await put<SetMobileDeviceIdParams, SetMobileDeviceIdResponse>(
      `${PALMS_URL}/SetMobileDeviceId`,
      {
        palmId,
        mobileDeviceId,
      },
    )
    return Promise.resolve({ data })
  },
  resetMobileDeviceId: async (
    resource: string,
    { palmId }: ResetMobileDeviceIdParams,
  ): Promise<DataProviderExtensionResult<ResetMobileDeviceIdResponse>> => {
    const data = await put<
      ResetMobileDeviceIdParams,
      ResetMobileDeviceIdResponse
    >(`${PALMS_URL}/ResetMobileDeviceId`, {
      palmId,
    })
    return Promise.resolve({ data: { ...data, mobileDeviceId: undefined } })
  },
  attachEvents: async (
    resource: string,
    { palmId, eventsIds }: PalmEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<PalmEventsDto>> => {
    const data = await put<PalmEventsDto, PalmEventsDto>(
      `${PALMS_URL}/AttachEvents`,
      {
        palmId,
        eventsIds,
      },
    )
    return Promise.resolve({ data })
  },
  detachEvents: async (
    resource: string,
    { palmId, eventsIds }: PalmEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<PalmEventsDto>> => {
    const data = await put<PalmEventsDto, PalmEventsDto>(
      `${PALMS_URL}/DetachEvents`,
      {
        palmId,
        eventsIds,
      },
    )
    return Promise.resolve({ data })
  },
  attachEntrances: async (
    resource: string,
    { palmId, entrancesIds }: PalmEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<PalmEntrancesDto>> => {
    const data = await put<PalmEntrancesDto, PalmEntrancesDto>(
      `${PALMS_URL}/AttachEntrances`,
      {
        palmId,
        entrancesIds,
      },
    )
    return Promise.resolve({ data })
  },
  detachEntrances: async (
    resource: string,
    { palmId, entrancesIds }: PalmEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<PalmEntrancesDto>> => {
    const data = await put<PalmEntrancesDto, PalmEntrancesDto>(
      `${PALMS_URL}/DetachEntrances`,
      {
        palmId,
        entrancesIds,
      },
    )
    return Promise.resolve({ data })
  },
} as PalmDataProvider

interface PalmDataProvider extends DataProvider {
  lock: (
    resource: string,
    params: LockParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  unlock: (
    resource: string,
    params: UnlockParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setMobileDeviceId: (
    resource: string,
    params: SetMobileDeviceIdParams,
  ) => Promise<DataProviderExtensionResult<SetMobileDeviceIdResponse>>

  resetMobileDeviceId: (
    resource: string,
    params: ResetMobileDeviceIdParams,
  ) => Promise<DataProviderExtensionResult<ResetMobileDeviceIdResponse>>

  attachEvents: (
    resource: string,
    params: PalmEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<PalmEventsDto>>

  detachEvents: (
    resource: string,
    params: PalmEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<PalmEventsDto>>

  attachEntrances: (
    resource: string,
    params: PalmEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<PalmEntrancesDto>>

  detachEntrances: (
    resource: string,
    params: PalmEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<PalmEntrancesDto>>
}

export interface CreatePalmRequest {
  readonly name: string
  readonly isWatched: boolean
  readonly direction: DeviceDirection
  readonly categoryId: number
  readonly disabled: string
  readonly jsonStatus: string
  readonly onlineInfixCeck: boolean
  readonly multiplicity: Multiplicities
  readonly mobileDeviceId: string
  readonly theme: PalmThemes
}

export interface LockParams {
  palmId: Identifier
  disabledId: number
}

export interface UnlockParams {
  palmId: Identifier
}

export interface SetMobileDeviceIdParams {
  palmId: Identifier
  mobileDeviceId: string
}

export interface SetMobileDeviceIdResponse {
  palmId: Identifier
  mobileDeviceId: string
  setResult: PalmSetMobileDeviceIdResults
}

export interface ResetMobileDeviceIdParams {
  palmId: Identifier
}

export interface ResetMobileDeviceIdResponse {
  palmId: Identifier
  resetResult: PalmResetMobileDeviceIdResults
}

interface ChangeStateResponse {
  jobId: Identifier
}

export default provider
