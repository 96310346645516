import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React, { useEffect } from 'react'
import {
  Create,
  DateTimeInput,
  ListButton,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useTranslate,
  CreateProps,
  FormDataConsumer,
  sanitizeFetchType,
  GET_ONE,
  useQuery,
  required,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { Authority } from '../../core/auth/Authority'
import { BlockDto } from '../../core/dto/block.dto'
import { BookingDto } from '../../core/dto/booking.dto'
import { CustomerDto } from '../../core/dto/customer.dto'
import { BlockType } from '../../core/enum/BlockType'
import { BookingType } from '../../core/enum/BookingType'
import { ResourceName } from '../../core/ResourceName'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { useHasAuthority } from '../hooks/useHasAuthority'

const validateBookingCreate = (values: BookingDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.eventId) {
    errors.eventId = 'ra.validation.required'
  }
  if (!values.customerId) {
    errors.customerId = 'ra.validation.required'
  }
  if (!values.variantId) {
    errors.variantId = 'ra.validation.required'
  }
  if (!values.blockId && !values.seatId) {
    errors.blockId = 'ra.validation.one_required'
    errors.seatId = 'ra.validation.one_required'
  }
  if (values.blockId && values.seatId) {
    errors.blockId = 'ra.validation.both_prohibited'
    errors.seatId = 'ra.validation.both_prohibited'
  }
  if (!values.type) {
    errors.type = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.bookings.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton
      resource={ResourceName.TICKETS}
      basePath="/tickets"
      icon={<Cancel />}
      label="const.cancel"
    />
  </Toolbar>
)

export const BookingCreate = (props: CreateProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const SeatInput = ({ formData, ...inputProps }: { formData: any }) => {
    const { data: block } = useQuery(
      {
        type: sanitizeFetchType(GET_ONE),
        resource: ResourceName.BLOCKS,
        payload: {
          id: formData?.blockId,
        },
      },
      { enabled: !!formData?.blockId },
    )
    const { change } = useForm()
    useEffect(() => {
      // ensure 'seatId' field is empty when block type is not numbered
      if (block?.type && block.type !== BlockType.NUMBERED) {
        change('seatId', null)
      }
    }, [block?.type, change])

    return (block as BlockDto)?.type === BlockType.NUMBERED ? (
      <FilteredReferenceInput
        {...inputProps}
        source="seatId"
        defaultValue={null}
        reference={ResourceName.SEATS}
        sort={{ field: 'rowAlias', order: 'ASC' }}
        perPage={smallScreen ? 5 : 15}
        validate={required()}
        filterSource="combinedFullAlias"
        selectWithPaginationInputProps={{
          optionText: 'combinedFullAlias',
          showFilter: true,
        }}
        filter={{
          belongsToBlock: formData?.blockId,
        }}
      />
    ) : (
      <></>
    )
  }

  return (
    <Create
      {...props}
      hasShow={false}
      title={<CreateTitle />}
      basePath="/bookings"
      transform={(booking) => {
        let result = booking
        // when both 'blockId' and 'seatId' are selected, then clear 'blockId'
        if (result.blockId && result.seatId) {
          result = { ...result, blockId: null }
        }
        return result
      }}
    >
      <SimpleForm
        redirect="show"
        toolbar={<CreateToolbar />}
        validate={validateBookingCreate}
      >
        {hasAuthority(Authority.VIEW_EVENTS) && (
          <FilteredReferenceInput
            source="eventId"
            defaultValue={null}
            reference={ResourceName.EVENTS}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
            filter={{
              hasObjects: true,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_CUSTOMERS) && (
          <FilteredReferenceInput
            source="customerId"
            defaultValue={null}
            reference={ResourceName.CUSTOMERS}
            sort={{ field: 'lastname', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="info"
            selectWithPaginationInputProps={{
              optionText: (choice: CustomerDto) =>
                `${choice.firstname} ${choice.lastname}`,
              showFilter: true,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_VARIANTS) && (
          <FilteredReferenceInput
            source="variantId"
            defaultValue={null}
            reference={ResourceName.VARIANTS}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_BLOCKS) && (
          <FormDataConsumer>
            {({ formData, ...inputProps }) => (
              <FilteredReferenceInput
                {...inputProps}
                source="blockId"
                defaultValue={null}
                reference={ResourceName.BLOCKS}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={smallScreen ? 5 : 15}
                isRequired
                filterSource="search"
                selectWithPaginationInputProps={{
                  optionText: 'name',
                  showFilter: true,
                }}
                filter={{
                  belongsToEvent: formData?.eventId,
                }}
              />
            )}
          </FormDataConsumer>
        )}
        {hasAuthority(Authority.VIEW_SEATS) && (
          <FormDataConsumer>
            {({ formData, ...inputProps }) => (
              <SeatInput {...inputProps} formData={formData} />
            )}
          </FormDataConsumer>
        )}
        <SelectInput
          source="type"
          isRequired
          choices={[
            {
              id: null,
              name: 'const.emptySelection',
            },
            {
              id: BookingType.NORMAL,
              name: `resources.enums.bookingType.${BookingType.NORMAL}`,
            },
            {
              id: BookingType.INFIX,
              name: `resources.enums.bookingType.${BookingType.INFIX}`,
            },
            {
              id: BookingType.ABO,
              name: `resources.enums.bookingType.${BookingType.ABO}`,
            },
          ]}
        />
        {/* <SelectInput
          source="disabled"
          choices={[
            {
              id: null,
              name: 'const.emptySelection',
            },
            ...Object.entries(BookingDisabledOption).map(([value]) => ({
              id: value,
              name: `resources.enums.bookingDisabled.${value}`,
            })),
          ]}
        /> */}
        <DateTimeInput
          source="activeFrom"
          parse={(value: string) => (value === '' ? null : value)}
        />
        <DateTimeInput
          source="activeTo"
          parse={(value: string) => (value === '' ? null : value)}
        />
      </SimpleForm>
    </Create>
  )
}
