import { DataProvider } from 'react-admin'
import { OfflineServerDto } from '../../dto/device/offline-servers/offline-server.dto'

const fakeServers: OfflineServerDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeServers, total: fakeServers.length }),
} as DataProvider

export default provider
