import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  useTranslate,
  SimpleForm,
  TextInput,
  SaveButton,
  ListButton,
  Toolbar,
  ToolbarProps,
  BooleanInput,
  SelectInput,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DetectorDto } from '../../../core/dto/device/detector/detector.dto'
import { HardwareVersions } from '../../../core/enum/HardwareVersions'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const validateDetectorCreate = (values: DetectorDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (values.hardwareVersion === HardwareVersions.MODULO5 && !values.tsCanId) {
    errors.tsCanId = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.detectors.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const Detector = ({ ...props }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  const defaultValues = {
    hardwareVersion: HardwareVersions.MODULO5,
  }

  return (
    <SimpleForm
      {...props}
      redirect="show"
      toolbar={<CreateToolbar />}
      validate={validateDetectorCreate}
      initialValues={defaultValues}
    >
      <TextInput source="name" isRequired />
      <BooleanInput source="isWatched" />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          source="categoryId"
          defaultValue={null}
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_CAMERAS) && (
        <FilteredReferenceInput
          source="cameraId"
          defaultValue={null}
          reference={ResourceName.CAMERAS}
          sort={{ field: 'description', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'description',
            showFilter: true,
          }}
        />
      )}
      <SelectInput
        source="hardwareVersion"
        choices={Object.entries(HardwareVersions)
          .filter((hw) => hw[1] !== HardwareVersions.SPOM4)
          .map(([value]) => ({
            id: value,
            name: `resources.enums.hardwareVersions.${value}`,
          }))}
      />
      {hasAuthority(Authority.VIEW_TS_CANS) && (
        <FilteredReferenceInput
          source="tsCanId"
          defaultValue={null}
          reference={ResourceName.TS_CANS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </SimpleForm>
  )
}

export const DetectorCreate = (props: CreateProps) => (
  <Create {...props} hasShow={false} title={<CreateTitle />}>
    <Detector />
  </Create>
)
