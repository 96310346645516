import { mapping as deviceSortMapping } from '../device-base/device-base-sort.mapper'

export const mapping: Record<string, string> = {
  ...deviceSortMapping,
  disabled: 'd=>d.disabled',
  onlineInfixCeck: 'd=>d.onlineInfixCeck',
  multiplicity: 'd=>d.multiplicity',
}

const mapSortVerifierDeviceParam = (key: string) => mapping[key]

export default mapSortVerifierDeviceParam
