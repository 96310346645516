import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import {
  DateField,
  Labeled,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin'
import { ResourceName } from '../../../../core/ResourceName'
import { DateTime } from '../../../../core/common/date-time'

const useStyles = makeStyles((theme) => ({
  statusTab: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  percentageFieldBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontSize: '0.875rem !important',
  },
  percentageFieldBackProgress: {
    position: 'absolute',
    left: 0,
  },
  largeScreenMainStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  smallScreenMainStatus: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  deviceBox: {
    border: `solid thin ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
  },
}))

export enum NetworkType {
  UNKNOWN = 'UNKNOWN',
  GSM = 'GSM',
  CDMA = 'CDMA',
  LTE = 'LTE',
}

const PalmDeviceInfo = ({ ...props }) => {
  const { label, record, hideDate, ...rest } = props
  const classes = useStyles()

  return (
    <Labeled
      resource={ResourceName.PALMS}
      source={label ? `jsonStatus.${label}` : ' '}
      label={label ? undefined : ' '}
      {...rest}
      fullWidth
    >
      <div className={classes.deviceBox}>
        <SimpleShowLayout record={record} resource={ResourceName.PALMS}>
          {!hideDate && record?.date && (
            <DateField
              source="date"
              label="resources.palms.fields.jsonStatus.date"
              showTime
            />
          )}
          {record?.deviceKey && (
            <TextField
              source="deviceKey"
              label="resources.palms.fields.jsonStatus.deviceKey"
            />
          )}
          {record?.manufacturerName && (
            <TextField
              source="manufacturerName"
              label="resources.palms.fields.jsonStatus.manufacturerName"
            />
          )}
          {record?.productId && (
            <TextField
              source="productId"
              label="resources.palms.fields.jsonStatus.productId"
            />
          )}
          {record?.productName && (
            <TextField
              source="productName"
              label="resources.palms.fields.jsonStatus.productName"
            />
          )}
          {record?.vendorId && (
            <TextField
              source="vendorId"
              label="resources.palms.fields.jsonStatus.vendorId"
            />
          )}
        </SimpleShowLayout>
      </div>
    </Labeled>
  )
}

export const PalmStatus = ({ ...props }) => {
  const {
    record: { jsonStatus: rawJsonStatus },
    hideDevices,
  } = props
  const classes = useStyles()
  const translate = useTranslate()
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'))

  let jsonStatus

  try {
    jsonStatus = JSON.parse(rawJsonStatus) as JsonStatus
    if (jsonStatus === null || jsonStatus === undefined)
      jsonStatus = {
        cellularSignal: {
          networkType: NetworkType.UNKNOWN,
          percentageCellularSignalLevel: -1,
        },
        percentageWifiSignalLevel: -1,
        percentageBatteryLevel: -1,
      }
  } catch {
    jsonStatus = {
      cellularSignal: {
        networkType: NetworkType.UNKNOWN,
        percentageCellularSignalLevel: -1,
      },
      percentageWifiSignalLevel: -1,
      percentageBatteryLevel: -1,
    }
  }

  const deviceStatus = jsonStatus?.litePlusIntentServiceStatus || {
    connectedDevices: undefined,
    lastAttachedDevice: undefined,
    lastConnectedDevice: undefined,
    lastDetachedDevice: undefined,
    lastGrantedDevice: undefined,
    lastRequestedDevice: undefined,
    lastUnallowedDevice: undefined,
  }

  const {
    connectedDevices,
    lastAttachedDevice,
    lastConnectedDevice,
    lastDetachedDevice,
    lastGrantedDevice,
    lastRequestedDevice,
    lastUnallowedDevice,
  } = deviceStatus

  return (
    <div className={classes.statusTab}>
      <div
        className={
          largeScreen
            ? classes.largeScreenMainStatus
            : classes.smallScreenMainStatus
        }
      >
        {jsonStatus?.cellularSignal?.percentageCellularSignalLevel !==
          undefined && (
          <Labeled
            resource={ResourceName.PALMS}
            source="jsonStatus.percentageCellularSignalLevel"
          >
            <div className={classes.percentageFieldBox}>
              <CircularProgress
                variant="determinate"
                value={100}
                color="secondary"
                className={classes.percentageFieldBackProgress}
                thickness={8}
              />
              <CircularProgress
                variant="determinate"
                value={
                  jsonStatus?.cellularSignal?.percentageCellularSignalLevel
                }
                color="primary"
                thickness={8}
              />
              <div>
                {jsonStatus?.cellularSignal?.percentageCellularSignalLevel !==
                -1
                  ? `${jsonStatus?.cellularSignal?.percentageCellularSignalLevel}%`
                  : 'NaN'}
                {jsonStatus?.cellularSignal?.networkType &&
                  ` / ${translate(
                    `resources.enums.NetworkType.${jsonStatus?.cellularSignal?.networkType}`,
                  )}`}
              </div>
            </div>
          </Labeled>
        )}
        {jsonStatus?.percentageWifiSignalLevel !== undefined && (
          <Labeled
            resource={ResourceName.PALMS}
            source="jsonStatus.percentageWifiSignalLevel"
          >
            <div className={classes.percentageFieldBox}>
              <CircularProgress
                variant="determinate"
                value={100}
                color="secondary"
                className={classes.percentageFieldBackProgress}
                thickness={8}
              />
              <CircularProgress
                variant="determinate"
                value={jsonStatus?.percentageWifiSignalLevel}
                color="primary"
                thickness={8}
              />
              <div>
                {jsonStatus?.percentageWifiSignalLevel !== -1
                  ? `${jsonStatus?.percentageWifiSignalLevel}%`
                  : 'NaN'}
              </div>
            </div>
          </Labeled>
        )}
        {jsonStatus?.percentageBatteryLevel !== undefined && (
          <Labeled
            resource={ResourceName.PALMS}
            source="jsonStatus.percentageBatteryLevel"
          >
            <div className={classes.percentageFieldBox}>
              <CircularProgress
                variant="determinate"
                value={100}
                color="secondary"
                className={classes.percentageFieldBackProgress}
                thickness={8}
              />
              <CircularProgress
                variant="determinate"
                value={jsonStatus?.percentageBatteryLevel}
                color="primary"
                thickness={8}
              />
              <span>
                {jsonStatus?.percentageBatteryLevel !== -1
                  ? `${jsonStatus?.percentageBatteryLevel}%`
                  : 'NaN'}
              </span>
            </div>
          </Labeled>
        )}
      </div>
      {!hideDevices && (
        <>
          <Grid container spacing={2}>
            {lastAttachedDevice && (
              <Grid item xs={12} sm={6} md={4}>
                <PalmDeviceInfo
                  label="lastAttachedDevice"
                  record={lastAttachedDevice}
                />
              </Grid>
            )}
            {lastConnectedDevice && (
              <Grid item xs={12} sm={6} md={4}>
                <PalmDeviceInfo
                  label="lastConnectedDevice"
                  record={lastConnectedDevice}
                />
              </Grid>
            )}
            {lastDetachedDevice && (
              <Grid item xs={12} sm={6} md={4}>
                <PalmDeviceInfo
                  label="lastDetachedDevice"
                  record={lastDetachedDevice}
                />
              </Grid>
            )}
            {lastGrantedDevice && (
              <Grid item xs={12} sm={6} md={4}>
                <PalmDeviceInfo
                  label="lastGrantedDevice"
                  record={lastGrantedDevice}
                />
              </Grid>
            )}
            {lastRequestedDevice && (
              <Grid item xs={12} sm={6} md={4}>
                <PalmDeviceInfo
                  label="lastRequestedDevice"
                  record={lastRequestedDevice}
                />
              </Grid>
            )}
            {lastUnallowedDevice && (
              <Grid item xs={12} sm={6} md={4}>
                <PalmDeviceInfo
                  label="lastUnallowedDevice"
                  record={lastUnallowedDevice}
                />
              </Grid>
            )}
          </Grid>
          {connectedDevices && connectedDevices.length > 0 && (
            <Accordion defaultExpanded>
              <AccordionSummary>
                {translate(
                  'resources.palms.fields.jsonStatus.connectedDevices',
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {connectedDevices.map((device) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <PalmDeviceInfo label={false} record={device} hideDate />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </div>
  )
}

interface JsonStatus {
  readonly cellularSignal: {
    readonly networkType: NetworkType
    readonly percentageCellularSignalLevel: number
  }
  readonly percentageWifiSignalLevel: number
  readonly percentageBatteryLevel: number
  readonly litePlusIntentServiceStatus?: {
    readonly connectedDevices?: DeviceInfo[]
    readonly lastAttachedDevice?: DeviceInfo
    readonly lastConnectedDevice?: DeviceInfo
    readonly lastDetachedDevice?: DeviceInfo
    readonly lastGrantedDevice?: DeviceInfo
    readonly lastRequestedDevice?: DeviceInfo
    readonly lastUnallowedDevice?: DeviceInfo
  }
}

interface DeviceInfo {
  readonly date?: DateTime
  readonly deviceKey?: string
  readonly manufacturerName?: string
  readonly productId?: number
  readonly productName?: string
  readonly vendorId?: number
}
