import { AuthProvider } from 'react-admin'
import { AUTH_URL, ROLES_URL } from '../api-urls'
import { authPost, getWithToken } from '../common/fetch.utils'
import currentUserProvider from '../current-user/current-user.provider'
import { AuthDto } from '../dto/auth.dto'
import { RoleDto } from '../dto/role/role.dto'
import i18nProvider from '../i18n.provider'
import { ResourceName } from '../ResourceName'
import { AuthRequest } from './auth.request'

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const { token } = await authPost<AuthRequest, AuthDto>(AUTH_URL, {
      login: username,
      password,
    })
    const roleDtos = await getWithToken<RoleDto[]>(ROLES_URL, token)
    const roles = roleDtos.map((r) => r.name)
    localStorage.setItem('username', username)
    localStorage.setItem('token', token)
    localStorage.setItem('roles', roles.join())
    // User preferences
    const { data: settings } = await currentUserProvider.getSettings(
      ResourceName.CURRENT_USER,
    )
    localStorage.setItem(
      'preferences',
      JSON.stringify({
        theme: settings?.frontDisplayMode,
        moduleStatusTimeout: settings?.moduleStatusTimeout,
        language: settings?.language,
        frontDashboardVisibility_TicketFinder:
          settings?.frontDashboardVisibility_TicketFinder,
        frontDashboardVisibility_LastLogs:
          settings?.frontDashboardVisibility_LastLogs,
        frontDashboardVisibility_LastEvents:
          settings?.frontDashboardVisibility_LastEvents,
        frontDashboardVisibility_CustomerFinder:
          settings?.frontDashboardVisibility_CustomerFinder,
      }),
    )
  },
  logout: () => {
    localStorage.removeItem('username')
    localStorage.removeItem('token')
    localStorage.removeItem('roles')
    return Promise.resolve()
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('username')
      localStorage.removeItem('token')
      localStorage.removeItem('roles')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: async () => {
    let preferences
    try {
      preferences = JSON.parse(localStorage?.preferences)
      if (preferences === null || preferences === undefined) preferences = {}
    } catch {
      preferences = {}
    }
    if (preferences?.language) i18nProvider.changeLocale(preferences.language)
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: (): Promise<Set<string>> => {
    const roles = localStorage.getItem('roles') as string
    return Promise.resolve(new Set(roles?.split(',') || []))
  },
  getIdentity: () =>
    Promise.resolve({
      id: localStorage.getItem('username') as string,
    }),
}

export default authProvider
