import { EventStatus } from '../../../enum/EventStatus'
import { mapping as deviceBaseFilterMapper } from '../device-base/device-base-filter.mapper'

export const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  ...deviceBaseFilterMapper,
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  filterEntranceId: () => null,
  entranceId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterEntranceId === false
      ? null
      : `${lambdaParameter}.Entrances.Any(en=>en.Id==${value})`,
  filterEventId: () => null,
  eventId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterEventId === false
      ? null
      : `${lambdaParameter}.Events.Any(e=>e.Id.Equals(${value}))`,
  eventActive: (lambdaParameter) =>
    `${lambdaParameter}.Events.Any(ev=>ev.Status.Equals(TicketAPI.Models.Enums.TicketManagement.EventStatuses.${EventStatus.ACTIVE}))`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
