import {
  Box,
  Tooltip,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close, DevicesFold, PriorityHigh } from '@mui/icons-material'
import classNames from 'classnames'
import camelcaseKeys from 'camelcase-keys'
import React from 'react'
import {
  Datagrid,
  Filter,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import {
  CheckIfAnyModuleHasError,
  CheckLastRefreshError,
  DeviceStatusesPreview,
} from '../common/modules/ModuleStatus'
import { TerminalPowerButton } from './TerminalPowerButton'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'
import PaperRoll from '../../../assets/icons/PaperRoll'
import { PrinterDto } from '../../../core/dto/device/common/modules/printer.dto'

const useStyles = makeStyles((theme) => ({
  compactStatus: {
    display: 'flex',
    gap: theme.spacing(0.5),
    height: 34,
    cursor: 'default',
  },
  iconError: {
    color: theme.palette.error.dark,
  },
  icon: {
    padding: 4,
  },
  iconContainer: {
    position: 'relative',
    width: 34,
  },
  statusIcon: {
    position: 'absolute',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
  exclamationIcon: {
    position: 'absolute',
    left: 2.5,
    top: 0,
    fontSize: 34,
  },
}))

const TerminalFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

const TerminalGridExpand = ({ ...props }) => {
  const { record } = props
  return (
    <DeviceStatusesPreview
      jsonStatus={record.jsonStatus}
      deviceType={DeviceTypes.TERMINALS}
    />
  )
}

const CompactStatus = ({ ...props }) => {
  const { record: terminal } = props
  const classes = useStyles()
  const translate = useTranslate()

  const jsonStatus =
    terminal?.jsonStatus &&
    camelcaseKeys(JSON.parse(terminal?.jsonStatus), { deep: true })
  const printerStatus = jsonStatus?.printerStatus as PrinterDto

  return (
    <Box
      className={classes.compactStatus}
      onClick={(event) => event.stopPropagation()}
    >
      {jsonStatus && (
        <Tooltip
          title={
            jsonStatus?.isDoorOpen
              ? translate('resources.enums.isCoverOpened.open')
              : translate('resources.enums.isCoverOpened.closed')
          }
        >
          <DevicesFold
            className={classNames(
              jsonStatus?.isDoorOpen && classes.iconError,
              classes.icon,
            )}
          />
        </Tooltip>
      )}
      {printerStatus && (
        <Tooltip
          title={
            //eslint-disable-next-line no-nested-ternary
            printerStatus?.paperNotPresent
              ? translate(
                  'resources.enums.printerPaperStatuses.paperNotPresent',
                )
              : printerStatus?.lowPaper
              ? translate('resources.enums.printerPaperStatuses.lowPaper')
              : translate('resources.enums.printerPaperStatuses.ok')
          }
        >
          <div className={classes.iconContainer}>
            <>
              <PaperRoll
                style={{
                  fill: 'transparent',
                  alignSelf: 'center',
                  opacity:
                    printerStatus?.paperNotPresent || printerStatus?.lowPaper
                      ? 0.5
                      : 1,
                }}
                className={classes.statusIcon}
              />
              {printerStatus?.paperNotPresent && (
                <Close fontSize="large" className={classes.crossIcon} />
              )}
              {!printerStatus?.paperNotPresent && printerStatus?.lowPaper && (
                <PriorityHigh
                  fontSize="large"
                  className={classes.exclamationIcon}
                />
              )}
            </>
          </div>
        </Tooltip>
      )}
      <TerminalPowerButton jsonStatus={jsonStatus} record={terminal} />
    </Box>
  )
}

export const TerminalList = ({ ...props }: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  const postRowStyle = (record: any) => {
    const terminalError = CheckLastRefreshError(record.lastRefresh)
    let terminalWarning = false
    if (!terminalError) {
      // Check for modules error
      terminalWarning = CheckIfAnyModuleHasError(
        record.jsonStatus,
        DeviceTypes.TERMINALS,
      )
    }
    if (terminalError) {
      return {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      }
    }
    if (terminalWarning) {
      return {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
      }
    }
    return {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    }
  }

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<TerminalFilters />}
      bulkActionButtons={false}
    >
      <Datagrid
        rowStyle={postRowStyle}
        size="small"
        rowClick={(id) => `${ResourceName.TERMINALS}/${id}/show`}
        isRowExpandable={(record) =>
          record?.jsonStatus !== undefined && record?.jsonStatus !== null
        }
        expand={<TerminalGridExpand />}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link={false}
            sortBy="name"
          >
            <TextField source="hierarchyString" />
          </ReferenceField>
        )}
        {!smallScreen && (
          <ReferenceField
            source="disabledId"
            reference={ResourceName.VERIFIER_DEVICE_DISABLED}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <CompactStatus source="status" sortable={false} />
      </Datagrid>
    </List>
  )
}
