import React from 'react'
import { ListProps } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { Authority } from '../../core/auth/Authority'
import {
  NodeActions,
  TreeWithDetails,
} from '../../lib/@react-admin/ra-tree/esm/src'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { DeviceCategoryCreate } from './DeviceCategoryCreate'
import { DeviceCategoryEdit } from './DeviceCategoryEdit'
import { DeviceCategoryShow } from './DeviceCategoryShow'

const useStyles = makeStyles({
  tree: {
    '& .rc-tree-title > div > button': {
      visibility: 'hidden',
    },
  },
})

export const DeviceCategoryList = ({ ...props }: ListProps) => {
  const hasAuthority = useHasAuthority()
  const classes = useStyles()

  return (
    <TreeWithDetails
      className={classes.tree}
      titleField="name"
      linkTo="show"
      show={DeviceCategoryShow}
      edit={
        hasAuthority(Authority.EDIT_DEVICE_CATEGORIES)
          ? DeviceCategoryEdit
          : undefined
      }
      create={
        hasAuthority(Authority.CREATE_DEVICE_CATEGORIES)
          ? DeviceCategoryCreate
          : undefined
      }
      {...props}
      allowMultipleRoots={hasAuthority(Authority.CREATE_DEVICE_CATEGORIES)}
      // eslint-disable-next-line react/no-children-prop
      nodeActions={<NodeActions children={[]} />}
    />
  )
}
