import React, { ReactElement } from 'react'
import {
  CreateProps,
  CreateView,
  ResourceContextProvider,
  useCreateController,
} from 'react-admin'
import {
  CreateContextProvider,
  OnFailure,
  OnSuccess,
  RedirectionSideEffect,
  TransformData,
} from 'ra-core'

export interface ImportViewProps extends CreateProps {
  readonly children: ReactElement
  readonly onSave: (
    record: any,
    redirect: RedirectionSideEffect,
    callbacks?: {
      onSuccess?: OnSuccess
      onFailure?: OnFailure
      transform?: TransformData
    },
  ) => void
  readonly saving: boolean
}

export const ImportView = (props: ImportViewProps) => {
  const { children, title, onSave, saving, ...rest } = props
  const createControllerProps = {
    ...useCreateController(props),
    save: onSave,
    saving,
    defaultTitle: title as string,
  }
  const { resource } = createControllerProps
  return (
    <ResourceContextProvider value={resource}>
      <CreateContextProvider value={createControllerProps}>
        <CreateView {...rest} {...createControllerProps}>
          {children}
        </CreateView>
      </CreateContextProvider>
    </ResourceContextProvider>
  )
}
