import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin'
import List from '../common/customized-ra-components/List'

const TicketImportTemplatesFilters = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

export const TicketImportTemplatesList = (props: ListProps) => {
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <List
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<TicketImportTemplatesFilters />}
      {...props}
      bulkActionButtons={false}
      exporter={false}
      empty={false}
    >
      <Datagrid padding={xSmallScreen ? 'checkbox' : 'default'} rowClick="show">
        <TextField source="id" label="Id" />
        <TextField source="name" />
      </Datagrid>
    </List>
  )
}
