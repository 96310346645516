import { Box, Divider, Grid } from '@material-ui/core'
import React, { ReactElement } from 'react'
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ToggleType } from '../../../core/devices/palms/palm-lock-button'
import { PalmDto } from '../../../core/dto/device/palm/palm.dto'
import { ResourceName } from '../../../core/ResourceName'
import DropdownButton from '../../common/DropdownButton'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { PalmDeviceIdButton } from './palm-show/PalmDeviceIdButton'
import { PalmLockButton } from './palm-show/PalmLockButton'
import { PalmStatus } from './palm-show/PalmStatus'
import { CameraDto } from '../../../core/dto/device/camera/cameras/camera.dto'
import { VerifierDeviceDisabledDto } from '../../../core/dto/device/verifier-device-disabled/verifier-device-disabled.dto'
import { DeviceCategoryExtendedDto } from '../../../core/dto/device-category/device-category-extended.dto'

interface PalmTitleProps extends TitleProps {
  record?: PalmDto
}

const ShowTitle = (data: PalmTitleProps) => {
  const { record: palm } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.palms.titles.show')}: {palm?.id}. {palm?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: palm } = useShowContext<PalmDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <PalmDeviceIdButton />
          </Grid>
          <Grid item>
            <DropdownButton mainAction={<PalmLockButton />}>
              <PalmLockButton
                asRecurringJob
                recurringMethod={ToggleType.LOCK}
              />
              <PalmLockButton
                asRecurringJob
                recurringMethod={ToggleType.UNLOCK}
              />
            </DropdownButton>
          </Grid>
          <Grid item>
            <EditButton
              basePath={`/${ResourceName.PALMS}`}
              record={palm}
              variant="contained"
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_PALMS)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const PalmTabs = ({ ...props }) => {
  const translate = useTranslate()
  const { record: palm } = useShowContext<PalmDto>()
  const hasAuthority = useHasAuthority()

  const eventsFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.PALMS,
    })

  const entrancesFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="note" alwaysOn />],
      resource: ResourceName.PALMS,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label={translate('resources.palms.tabs.general')}>
        <TextField label="ID" source="id" />
        <TextField source="name" />
        <FunctionField<PalmDto>
          source="isWatched"
          render={(record?: PalmDto) => (
            <div>
              {record?.isWatched
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <DateField
          source="lastRefresh"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        <FunctionField<PalmDto>
          source="direction"
          render={(record?: PalmDto) => (
            <div>
              {translate(
                `resources.enums.deviceDirection.${record?.direction}`,
              )}
            </div>
          )}
        />
        {palm?.categoryId && hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link="show"
          >
            <FunctionField<DeviceCategoryExtendedDto>
              source="hierarchyString"
              render={(record?: DeviceCategoryExtendedDto) =>
                `${record?.id}. ${record?.hierarchyString}`
              }
            />
          </ReferenceField>
        )}
        {palm?.cameraId && hasAuthority(Authority.VIEW_CAMERAS) && (
          <ReferenceField
            source="cameraId"
            reference={ResourceName.CAMERAS}
            link="show"
          >
            <FunctionField<CameraDto>
              source="name"
              render={(camera?: CameraDto) =>
                `${camera?.id}. ${camera?.description}`
              }
            />
          </ReferenceField>
        )}
        {palm?.disabledId && (
          <ReferenceField
            source="disabledId"
            label="resources.palms.fields.disabled"
            reference={ResourceName.VERIFIER_DEVICE_DISABLED}
            link="show"
          >
            <FunctionField<VerifierDeviceDisabledDto>
              source="name"
              render={(disabled?: VerifierDeviceDisabledDto) =>
                `${disabled?.id}. ${disabled?.name}`
              }
            />
          </ReferenceField>
        )}
        <FunctionField<PalmDto>
          source="onlineInfixCeck"
          render={(record?: PalmDto) => (
            <div>
              {record?.onlineInfixCeck
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <FunctionField<PalmDto>
          source="multiplicity"
          render={(record?: PalmDto) => (
            <div>
              {translate(
                `resources.enums.multiplicities.${record?.multiplicity}`,
              )}
            </div>
          )}
        />
        {palm?.mobileDeviceId && <TextField source="mobileDeviceId" />}
        {palm?.theme && (
          <FunctionField<PalmDto>
            source="theme"
            render={(record?: PalmDto) => (
              <div>
                {translate(`resources.enums.PalmThemes.${record?.theme}`)}
              </div>
            )}
          />
        )}
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_EVENTS) && (
        <Tab path="events" label={translate('resources.palms.tabs.events')}>
          <>
            <RelationTab<PalmDto>
              resource={ResourceName.EVENTS}
              source="eventsIds"
              mode={hasAuthority(Authority.EDIT_PALMS) ? 'edit' : 'show'}
              attachMethod="attachEvents"
              detachMethod="detachEvents"
              attachRequestPayload={(r, ids) => ({
                palmId: r.id,
                eventsIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                palmId: r.id,
                eventsIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVerifierDeviceId === true
              }
              filters={eventsFilters() as ReactElement[]}
              filterDefaultValues={{
                verifierDeviceId: palm?.id,
                filterVerifierDeviceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ENTRANCES) && (
        <Tab
          path="entrances"
          label={translate('resources.palms.tabs.entrances')}
        >
          <>
            <RelationTab<PalmDto>
              resource={ResourceName.ENTRANCES}
              source="entrancesIds"
              mode={hasAuthority(Authority.EDIT_PALMS) ? 'edit' : 'show'}
              attachMethod="attachEntrances"
              detachMethod="detachEntrances"
              attachRequestPayload={(r, ids) => ({
                palmId: r.id,
                entrancesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                palmId: r.id,
                entrancesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVerifierDeviceId === true
              }
              filters={entrancesFilters() as ReactElement[]}
              filterDefaultValues={{
                verifierDeviceId: palm?.id,
                filterVerifierDeviceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="note" />
            </RelationTab>
          </>
        </Tab>
      )}
      {palm?.jsonStatus && (
        <Tab label="resources.palms.tabs.statuses" path="statuses">
          <PalmStatus />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const PalmShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <PalmTabs />
  </Show>
)
