const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  serviceType: (lambdaParameter, value) =>
    `${lambdaParameter}.ServiceType.Equals(${value})`,
  apiUrl: (lambdaParameter, value) =>
    `${lambdaParameter}.ApiUrl.Contains("${value}")`,
  eventId: (lambdaParameter, value) =>
    `${lambdaParameter}.EventId.Equals("${value}")`,
  blockId: (lambdaParameter, value) =>
    `${lambdaParameter}.BlockId.Equals("${value}")`,
  customerId: (lambdaParameter, value) =>
    `${lambdaParameter}.CustomerId.Equals("${value}")`,
  variantId: (lambdaParameter, value) =>
    `${lambdaParameter}.VariantId.Equals("${value}")`,
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
