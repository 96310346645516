import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  BooleanInput,
  DateField,
  Edit,
  EditProps,
  FunctionField,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { EngineDto } from '../../../core/dto/device/engine/engine.dto'
import { DeviceDirection } from '../../../core/enum/DeviceDirection'
import { HardwareVersions } from '../../../core/enum/HardwareVersions'
import { Multiplicities } from '../../../core/enum/Multiplicities'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { SPOM4FullAddressInput } from '../passages/SPOM4Address'

const validateEngineEdit = (values: EngineDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (values.hardwareVersion === HardwareVersions.SPOM4) {
    const addressParts = values.tsCommAddress?.split('&')
    if (!addressParts?.[0]) {
      errors.spom4_ipGate = 'ra.validation.required'
    }
    if (!addressParts?.[1]) {
      errors.spom4_tscanHost = 'ra.validation.required'
    }
    if (!addressParts?.[2]) {
      errors.spom4_direction = 'ra.validation.required'
    }
    if (!addressParts?.[3]) {
      errors.spom4_rs485Address = 'ra.validation.required'
    }
  }
  if (values.hardwareVersion === HardwareVersions.MODULO5 && !values.tsCanId) {
    errors.tsCanId = 'ra.validation.required'
  }
  return errors
}

interface EngineTitleProps extends TitleProps {
  record?: EngineDto
}

const EditTitle = (data: EngineTitleProps) => {
  const { record } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.engines.titles.edit')}: {record?.id}. {record?.name}{' '}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const Engine = ({ ...props }) => {
  const translate = useTranslate()
  const { record: engine } = props
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  const [hardwareVersion, setHardwareVersion] = useState(engine.hardwareVersion)

  const TSCanInput = ({ ...p }) =>
    hasAuthority(Authority.VIEW_TS_CANS) ? (
      <FilteredReferenceInput
        {...p}
        source="tsCanId"
        defaultValue={null}
        reference={ResourceName.TS_CANS}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={smallScreen ? 5 : 15}
        allowEmpty
        filterSource="search"
        selectWithPaginationInputProps={{
          optionText: 'name',
          showFilter: true,
        }}
      />
    ) : (
      <div />
    )

  return (
    <SimpleForm
      {...props}
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateEngineEdit}
      transform={(formData) => ({
        ...formData,
        tsCommAddress:
          hardwareVersion === HardwareVersions.SPOM4
            ? formData?.tsCommAddress
            : undefined,
      })}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" isRequired />
      <BooleanInput source="isWatched" />
      <DateField
        source="lastRefresh"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
      />
      <SelectInput
        source="direction"
        choices={Object.entries(DeviceDirection).map(([value]) => ({
          id: value,
          name: `resources.enums.deviceDirection.${value}`,
        }))}
      />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          source="categoryId"
          defaultValue={null}
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_CAMERAS) && (
        <FilteredReferenceInput
          source="cameraId"
          defaultValue={null}
          reference={ResourceName.CAMERAS}
          sort={{ field: 'description', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'description',
            showFilter: true,
          }}
        />
      )}
      <FunctionField<EngineDto>
        source="stanbyMode"
        render={(record?: EngineDto) => (
          <div>
            {translate(`resources.enums.standbyMode.${record?.stanbyMode}`)}
          </div>
        )}
      />
      {engine?.disabled && <TextField source="disabled" />}
      <BooleanInput source="onlineInfixCeck" />
      <SelectInput
        source="multiplicity"
        choices={Object.entries(Multiplicities).map(([value]) => ({
          id: value,
          name: `resources.enums.multiplicities.${value}`,
        }))}
      />
      <SelectInput
        value={hardwareVersion}
        onChange={(e) => setHardwareVersion(e.target.value)}
        source="hardwareVersion"
        choices={Object.entries(HardwareVersions).map(([value]) => ({
          id: value,
          name: `resources.enums.hardwareVersions.${value}`,
        }))}
      />
      {hardwareVersion === HardwareVersions.SPOM4 && (
        <SPOM4FullAddressInput source="tsCommAddress" />
      )}
      {hardwareVersion === HardwareVersions.MODULO5 && (
        <TSCanInput source="tsCommAddress" />
      )}
      {hasAuthority(Authority.VIEW_PALMS) && (
        <FilteredReferenceInput
          source="supervisorId"
          defaultValue={null}
          reference={ResourceName.PALMS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </SimpleForm>
  )
}

export const EngineEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <Engine />
  </Edit>
)
