import { instanceOf } from 'prop-types'
import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import {
  DEPOSITORS_URL,
  DOORS_URL,
  ENGINES_URL,
  PALMS_URL,
  TERMINALS_URL,
} from '../../../api-urls'
import { CacheableDataProviderExtensionResult } from '../../../common/data-provider'
import { get, getWithPagination, put } from '../../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../../common/ticket-api-get-list.params'
import { VerifierDeviceBaseWithTypeInfoDto } from '../../../dto/device/common/verifier-device-base/verifier-device-base-with-type-info.dto'
import { VerifierDeviceBaseDto } from '../../../dto/device/common/verifier-device-base/verifier-device-base.dto'
import { EngineEntrancesDto } from '../../../dto/device/engine/engine-entrances.dto'
import { EngineEventsDto } from '../../../dto/device/engine/engine-events.dto'
import { EngineDto } from '../../../dto/device/engine/engine.dto'
import { PalmEntrancesDto } from '../../../dto/device/palm/palm-entrances.dto'
import { PalmEventsDto } from '../../../dto/device/palm/palm-events.dto'
import { PalmDto } from '../../../dto/device/palm/palm.dto'
import { DeviceTypes } from '../../../enum/DeviceTypes'
import { ResourceName } from '../../../ResourceName'
import verifierDeviceBaseFilterMapper from './verifier-device-filter.mapper'
import enginesProvider from '../../engines/engines.provider'
import palmsProvider from '../../palms/palms.provider'
import doorsProvider from '../../doors/doors.provider'
import terminalsProvider from '../../terminals/terminals.provider'
import mapSortVerifierDeviceParam from './verifier-device-sort.mapper'
import { getDeviceCategoryChildren } from '../../devices.utils'
import depositorsProvider from '../../depositors/depositors.provider'
import { DepositorDto } from '../../../dto/device/depositor/depositor.dto'
import { DepositorEventsDto } from '../../../dto/device/depositor/depositor-events.dto'
import { DepositorEntrancesDto } from '../../../dto/device/depositor/depositor-entrances.dto'
import { TerminalDto } from '../../../dto/device/terminals/terminal.dto'
import { DoorDto } from '../../../dto/device/doors/door.dto'
import { DoorEventsDto } from '../../../dto/device/doors/door-events.dto'
import { TerminalEventsDto } from '../../../dto/device/terminals/terminal-events.dto'
import { DoorEntrancesDto } from '../../../dto/device/doors/door-entrances.dto'
import { TerminalEntrancesDto } from '../../../dto/device/terminals/terminal-entrances.dto'

const mapDeviceType = (
  verifierDevices: VerifierDeviceBaseDto[],
  type: DeviceTypes,
): VerifierDeviceBaseWithTypeInfoDto[] =>
  verifierDevices.map((verifierDevice) => ({
    ...verifierDevice,
    type,
  })) as VerifierDeviceBaseWithTypeInfoDto[]

const provider = {
  getList: async (
    resource: string,
    {
      filter: { deviceTypes, ...filter },
      sort,
      pagination,
    }: TicketAPIGetListParams,
  ): Promise<GetListResult<VerifierDeviceBaseWithTypeInfoDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const deviceTypeFilter = deviceTypes as DeviceTypes[] | undefined
    const data: VerifierDeviceBaseWithTypeInfoDto[] = []
    // Call all types to get total devices count
    const minimalListParams = {
      filter: extendedFilter,
      pagination: { page: 1, perPage: 1 },
    } as TicketAPIGetListParams
    const totalEngines =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.ENGINE)
        ? (
            await enginesProvider.getList(
              ResourceName.ENGINES,
              minimalListParams,
            )
          )?.total
        : 0
    const totalPalms =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.PALM)
        ? (await palmsProvider.getList(ResourceName.PALMS, minimalListParams))
            ?.total
        : 0
    const totalDepositors =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.DEPOSITOR)
        ? (
            await depositorsProvider.getList(
              ResourceName.DEPOSITORS,
              minimalListParams,
            )
          )?.total
        : 0
    const totalDoors =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.DOORS)
        ? (await doorsProvider.getList(ResourceName.DOORS, minimalListParams))
            ?.total
        : 0
    const totalTerminals =
      deviceTypeFilter === undefined ||
      deviceTypeFilter.some((f) => f === DeviceTypes.TERMINALS)
        ? (
            await terminalsProvider.getList(
              ResourceName.TERMINALS,
              minimalListParams,
            )
          )?.total
        : 0
    // Calculate which endpoints should be called
    const availableEndpointsElementsMap: EndpointCallMapElement[] = []
    /* eslint-disable */
    for (var _i = 0; _i < totalEngines; _i++)
      availableEndpointsElementsMap.push({
        index: _i,
        deviceType: DeviceTypes.ENGINE,
      })
    for (var i = 0; i < totalPalms; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.PALM,
      })
    for (var i = 0; i < totalDepositors; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.DEPOSITOR,
      })
    for (var i = 0; i < totalDoors; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.DOORS,
      })
    for (var i = 0; i < totalTerminals; i++)
      availableEndpointsElementsMap.push({
        index: i,
        deviceType: DeviceTypes.TERMINALS,
      })
    /* eslint-disable */
    let endpointsCallMap
    if (pagination) {
      const fromIndex = (pagination.page - 1) * pagination.perPage
      const toIndex = fromIndex + pagination.perPage
      endpointsCallMap = availableEndpointsElementsMap.slice(fromIndex, toIndex)
    } else {
      endpointsCallMap = availableEndpointsElementsMap
    }

    if (
      endpointsCallMap.some((value) => value.deviceType === DeviceTypes.ENGINE)
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        verifierDeviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(
        sort,
        undefined,
        mapSortVerifierDeviceParam,
      )
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.ENGINE,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.ENGINE)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: enginesData } = await getWithPagination<EngineDto[]>(
        `${ENGINES_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(enginesData, DeviceTypes.ENGINE))
    }

    if (
      endpointsCallMap.some((value) => value.deviceType === DeviceTypes.PALM)
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        verifierDeviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(
        sort,
        undefined,
        mapSortVerifierDeviceParam,
      )
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.PALM,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.PALM)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: palmsData } = await getWithPagination<PalmDto[]>(
        `${PALMS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(palmsData, DeviceTypes.PALM))
    }

    if (
      endpointsCallMap.some(
        (value) => value.deviceType === DeviceTypes.DEPOSITOR,
      )
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        verifierDeviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(
        sort,
        undefined,
        mapSortVerifierDeviceParam,
      )
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.DEPOSITOR,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.DEPOSITOR)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: depositorsData } = await getWithPagination<DepositorDto[]>(
        `${DEPOSITORS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(depositorsData, DeviceTypes.DEPOSITOR))
    }

    if (
      endpointsCallMap.some((value) => value.deviceType === DeviceTypes.DOORS)
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        verifierDeviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(
        sort,
        undefined,
        mapSortVerifierDeviceParam,
      )
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.DOORS,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.DOORS)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: doors } = await getWithPagination<DoorDto[]>(
        `${DOORS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(doors, DeviceTypes.DOORS))
    }

    if (
      endpointsCallMap.some(
        (value) => value.deviceType === DeviceTypes.TERMINALS,
      )
    ) {
      const filterParams = `o=>${filterParamsComposer(
        'o',
        extendedFilter,
        verifierDeviceBaseFilterMapper,
      )}`
      let pathParams = queryParamsComposer(
        sort,
        undefined,
        mapSortVerifierDeviceParam,
      )
      const queryRangeParams: string[] = []
      queryRangeParams.push(
        `rangeFrom=${
          endpointsCallMap.find(
            (value) => value.deviceType === DeviceTypes.TERMINALS,
          )?.index
        }`,
      )
      queryRangeParams.push(
        `rangeTo=${
          endpointsCallMap
            .slice()
            .reverse()
            .find((value) => value.deviceType === DeviceTypes.TERMINALS)?.index
        }`,
      )
      pathParams += `&${queryRangeParams.reduce((p, c) => `${p}&${c}`)}`
      const path = `/${filterParams}?${pathParams ?? pathParams}`

      const { data: terminalsData } = await getWithPagination<TerminalDto[]>(
        `${TERMINALS_URL}/GetByConditions`,
        path,
      )
      data.push(...mapDeviceType(terminalsData, DeviceTypes.TERMINALS))
    }

    return Promise.resolve({
      data,
      total: availableEndpointsElementsMap.length,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<VerifierDeviceBaseWithTypeInfoDto>> => {
    try {
      const data = await get<EngineDto>(ENGINES_URL, `/${id}`)
      return Promise.resolve({ data: { ...data, type: DeviceTypes.ENGINE } })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<DepositorDto>(DEPOSITORS_URL, `/${id}`)
      return Promise.resolve({ data: { ...data, type: DeviceTypes.DEPOSITOR } })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<PalmDto>(PALMS_URL, `/${id}`)
      return Promise.resolve({ data: { ...data, type: DeviceTypes.PALM } })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<DoorDto>(DOORS_URL, `/${id}`)
      return Promise.resolve({
        data: { ...data, type: DeviceTypes.DOORS },
      })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
    }

    try {
      const data = await get<TerminalDto>(TERMINALS_URL, `/${id}`)
      return Promise.resolve({
        data: { ...data, type: DeviceTypes.TERMINALS },
      })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error(error)
      return Promise.reject(error)
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<VerifierDeviceBaseWithTypeInfoDto>> => {
    const enginesData = await get<EngineDto[]>(
      ENGINES_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const palmsData = await get<PalmDto[]>(
      PALMS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const depositorsData = await get<DepositorDto[]>(
      DEPOSITORS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const doorsData = await get<DoorDto[]>(
      DOORS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    const terminalsData = await get<TerminalDto[]>(
      TERMINALS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )

    return Promise.resolve({
      data: [
        ...mapDeviceType(enginesData, DeviceTypes.ENGINE),
        ...mapDeviceType(palmsData, DeviceTypes.PALM),
        ...mapDeviceType(depositorsData, DeviceTypes.DEPOSITOR),
        ...mapDeviceType(doorsData, DeviceTypes.DOORS),
        ...mapDeviceType(terminalsData, DeviceTypes.TERMINALS),
      ],
    })
  },
  attachEvents: async (
    resource: string,
    params:
      | EngineEventsDto
      | PalmEventsDto
      | DepositorEventsDto
      | DoorEventsDto
      | TerminalEventsDto,
  ): Promise<
    CacheableDataProviderExtensionResult<
      | EngineEventsDto
      | PalmEventsDto
      | DepositorEventsDto
      | DoorEventsDto
      | TerminalEventsDto
    >
  > => {
    const verifierDeviceEvents = await put<
      | EngineEventsDto
      | PalmEventsDto
      | DepositorEventsDto
      | DoorEventsDto
      | TerminalEventsDto,
      | EngineEventsDto
      | PalmEventsDto
      | DepositorEventsDto
      | DoorEventsDto
      | TerminalEventsDto
    >(
      `${
        instanceOf<EngineEventsDto>(params as any)
          ? ENGINES_URL
          : instanceOf<PalmEventsDto>(params as any)
          ? PALMS_URL
          : instanceOf<DepositorEventsDto>(params as any)
          ? DEPOSITORS_URL
          : instanceOf<DoorEventsDto>(params as any)
          ? DOORS_URL
          : TERMINALS_URL
      }/AttachEvents`,
      params,
    )
    return {
      data: verifierDeviceEvents,
    }
  },
  detachEvents: async (
    resource: string,
    params:
      | EngineEventsDto
      | PalmEventsDto
      | DepositorEventsDto
      | DoorEventsDto
      | TerminalEventsDto,
  ): Promise<
    CacheableDataProviderExtensionResult<
      | EngineEventsDto
      | PalmEventsDto
      | DepositorEventsDto
      | DoorEventsDto
      | TerminalEventsDto
    >
  > => {
    const verifierDeviceEvents = await put<
      | EngineEventsDto
      | PalmEventsDto
      | DepositorEventsDto
      | DoorEventsDto
      | TerminalEventsDto,
      | EngineEventsDto
      | PalmEventsDto
      | DepositorEventsDto
      | DoorEventsDto
      | TerminalEventsDto
    >(
      `${
        instanceOf<EngineEventsDto>(params as any)
          ? ENGINES_URL
          : instanceOf<PalmEventsDto>(params as any)
          ? PALMS_URL
          : instanceOf<DepositorEventsDto>(params as any)
          ? DEPOSITORS_URL
          : instanceOf<DoorEventsDto>(params as any)
          ? DOORS_URL
          : TERMINALS_URL
      }/DetachEvents`,
      params,
    )
    return {
      data: verifierDeviceEvents,
    }
  },
  attachEntrances: async (
    resource: string,
    params:
      | EngineEntrancesDto
      | PalmEntrancesDto
      | DepositorEntrancesDto
      | DoorEntrancesDto
      | TerminalEntrancesDto,
  ): Promise<
    CacheableDataProviderExtensionResult<
      | EngineEntrancesDto
      | PalmEntrancesDto
      | DepositorEntrancesDto
      | DoorEntrancesDto
      | TerminalEntrancesDto
    >
  > => {
    const verifierDeviceEntrances = await put<
      | EngineEntrancesDto
      | PalmEntrancesDto
      | DepositorEntrancesDto
      | DoorEntrancesDto
      | TerminalEntrancesDto,
      | EngineEntrancesDto
      | PalmEntrancesDto
      | DepositorEntrancesDto
      | DoorEntrancesDto
      | TerminalEntrancesDto
    >(
      `${
        instanceOf<EngineEntrancesDto>(params as any)
          ? ENGINES_URL
          : instanceOf<PalmEntrancesDto>(params as any)
          ? PALMS_URL
          : instanceOf<DepositorEntrancesDto>(params as any)
          ? DEPOSITORS_URL
          : instanceOf<DoorEntrancesDto>(params as any)
          ? DOORS_URL
          : TERMINALS_URL
      }/AttachEntrances`,
      params,
    )
    return {
      data: verifierDeviceEntrances,
    }
  },
  detachEntrances: async (
    resource: string,
    params:
      | EngineEntrancesDto
      | PalmEntrancesDto
      | DepositorEntrancesDto
      | DoorEntrancesDto
      | TerminalEntrancesDto,
  ): Promise<
    CacheableDataProviderExtensionResult<
      | EngineEntrancesDto
      | PalmEntrancesDto
      | DepositorEntrancesDto
      | DoorEntrancesDto
      | TerminalEntrancesDto
    >
  > => {
    const verifierDeviceEntrances = await put<
      | EngineEntrancesDto
      | PalmEntrancesDto
      | DepositorEntrancesDto
      | DoorEntrancesDto
      | TerminalEntrancesDto,
      | EngineEntrancesDto
      | PalmEntrancesDto
      | DepositorEntrancesDto
      | DoorEntrancesDto
      | TerminalEntrancesDto
    >(
      `${
        instanceOf<EngineEntrancesDto>(params as any)
          ? ENGINES_URL
          : instanceOf<PalmEntrancesDto>(params as any)
          ? PALMS_URL
          : instanceOf<DepositorEntrancesDto>(params as any)
          ? DEPOSITORS_URL
          : instanceOf<DoorEntrancesDto>(params as any)
          ? DOORS_URL
          : TERMINALS_URL
      }/DetachEntrances`,
      params,
    )
    return {
      data: verifierDeviceEntrances,
    }
  },
} as VerifierDeviceDataProvider

interface VerifierDeviceDataProvider extends DataProvider {
  attachEvents: (
    resource: string,
    params: EngineEventsDto | PalmEventsDto,
  ) => Promise<
    CacheableDataProviderExtensionResult<EngineEventsDto | PalmEventsDto>
  >

  detachEvents: (
    resource: string,
    params: EngineEventsDto | PalmEventsDto,
  ) => Promise<
    CacheableDataProviderExtensionResult<EngineEventsDto | PalmEventsDto>
  >

  attachEntrances: (
    resource: string,
    params: EngineEntrancesDto | PalmEntrancesDto,
  ) => Promise<
    CacheableDataProviderExtensionResult<EngineEntrancesDto | PalmEntrancesDto>
  >

  detachEntrances: (
    resource: string,
    params: EngineEntrancesDto | PalmEntrancesDto,
  ) => Promise<
    CacheableDataProviderExtensionResult<EngineEntrancesDto | PalmEntrancesDto>
  >
}

interface EndpointCallMapElement {
  readonly index: number
  readonly deviceType: DeviceTypes
}

export default provider
