import { DataProvider } from 'react-admin'
import { DynamicPropertyDefinitionBaseDTO } from '../../dto/dynamic-property-definition.dto'

const fakeDefinitions: DynamicPropertyDefinitionBaseDTO[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeDefinitions, total: fakeDefinitions.length }),
} as DataProvider

export default provider
