import { DataProvider } from 'react-admin'
import { CameraDto } from '../../dto/device/camera/cameras/camera.dto'

const fakeCameras: CameraDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeCameras, total: fakeCameras.length }),
} as DataProvider

export default provider
