import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import * as React from 'react'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  fitContent: {
    display: 'table',
  },
  cellOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  columnWidth: {
    minWidth: theme.spacing(6),
    maxWidth: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(10),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing(6),
    },
  },
  columnPadding: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
}))

interface ImportCsvPreviewProps {
  readonly columnNames: string[]
  readonly rows: string[][]
}

export const ImportCsvPreview = ({
  columnNames,
  rows,
}: ImportCsvPreviewProps) => {
  const classes = useStyles()
  return (
    <Paper className={classes.fitContent}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columnNames.map((columnName, columnIdx) => (
                <TableCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${columnIdx}-${columnName}`}
                  className={classNames(
                    classes.columnWidth,
                    classes.cellOverflow,
                    classes.columnPadding,
                  )}
                  size="medium"
                >
                  {columnName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={rowIdx}>
                {row.map((cell, cellIdx) => (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${cellIdx}-${cell}`}
                    className={classes.columnPadding}
                  >
                    <Typography
                      variant="body2"
                      className={classNames(
                        classes.cellOverflow,
                        classes.columnWidth,
                      )}
                    >
                      {cell}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
