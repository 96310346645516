var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { InferenceTypes } from 'react-admin';
import { FormControl, InputLabel, MenuItem, Select, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
export var FieldTypeInput = function (_a) {
    var label = _a.label, props = __rest(_a, ["label"]);
    var classes = useStyles();
    return (React.createElement(FormControl, { fullWidth: true, className: classes.root },
        React.createElement(InputLabel, { id: "type-label" }, label),
        React.createElement(Select, __assign({ labelId: "type-label", fullWidth: true }, props), FieldTypesChoices.map(function (type) { return (React.createElement(MenuItem, { key: type.id, value: type.id }, type.name)); }))));
};
var FieldTypesChoices = InferenceTypes.map(function (type) { return ({
    id: type,
    name: type,
}); });
var useStyles = makeStyles(function (theme) { return ({
    root: {
        marginBottom: theme.spacing(2),
    },
}); });
