import { sanitizeFetchType } from 'react-admin'
import { EventDto } from '../dto/event/event.dto'
import { EventStatus } from '../enum/EventStatus'
import { ResourceName } from '../ResourceName'
import {
  ToggleButtonAction,
  ToggleButtonState,
} from './handle-activation-button'

type Mutation = {
  type: string
  resource: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
}

const deactivateMutation = (
  { id }: EventDto,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('deactivate'),
  resource: ResourceName.EVENTS,
  payload: {
    eventId: id,
    recurringJobName,
    cronExpression,
  },
})

const activateMutation = (
  { id }: EventDto,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('activate'),
  resource: ResourceName.EVENTS,
  payload: {
    eventId: id,
    recurringJobName,
    cronExpression,
  },
})

type State = {
  mutateQuery?: (
    event: EventDto,
    recurringJobName?: string,
    cronExpression?: string,
  ) => Mutation
} & ToggleButtonState

export const activationButtonInitialState = {
  type: EventStatus.IN_TRANSITION,
  text: 'resources.events.actions.activateNA',
}

export const toggleActivationButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case EventStatus.ACTIVE: {
      return {
        type,
        text: 'resources.events.actions.deactivate',
        mutateQuery: deactivateMutation,
      }
    }
    case EventStatus.INACTIVE: {
      return {
        type,
        text: 'resources.events.actions.activate',
        mutateQuery: activateMutation,
      }
    }
    case EventStatus.IN_TRANSITION: {
      return activationButtonInitialState
    }
    default:
      throw new Error()
  }
}
