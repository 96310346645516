import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  EditProps,
  useTranslate,
  Toolbar,
  SaveButton,
  ShowButton,
  ToolbarProps,
} from 'react-admin'
import { Cancel } from '@material-ui/icons'
import { ObjectDto } from '../../../core/dto/object.dto'

const validateObjectEdit = (values: ObjectDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const ObjectEdit = (props: EditProps) => {
  const translate = useTranslate()
  return (
    <Edit
      {...props}
      hasShow={false}
      title={translate('resources.objects.edit')}
    >
      <SimpleForm
        toolbar={<EditToolbar />}
        redirect="show"
        validate={validateObjectEdit}
      >
        <TextField source="id" label="ID" />
        <TextInput source="name" isRequired />
      </SimpleForm>
    </Edit>
  )
}

export default ObjectEdit
