import { DataProvider, GetOneParams, GetOneResult } from 'react-admin'
import { STATISTICS_URL } from '../../api-urls'
import { get } from '../../common/fetch.utils'
import { PassagesCountersResponse } from './passage-counters-view'

const provider = {
  getOne: async (
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    resource: string,
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    params: GetOneParams,
  ): Promise<GetOneResult<PassagesCountersResponse>> => {
    const data = await get<PassagesCountersResponse>(
      STATISTICS_URL,
      `/GetPassageCounters`,
    )

    return {
      data,
    }
  },
} as DataProvider

export default provider
