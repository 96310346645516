var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslate, RefreshButton } from 'react-admin';
import { Paper, Typography } from '@material-ui/core';
import { darken, lighten, makeStyles } from '@material-ui/core/styles';
export var Warning = function (props) {
    var message = props.message, refresh = props.refresh, onRefresh = props.onRefresh;
    var classes = useStyles(props);
    var translate = useTranslate();
    return (React.createElement(Paper, { className: classes.root, role: "alert", square: true, elevation: 0 },
        React.createElement("div", { className: classes.message },
            React.createElement(Typography, { gutterBottom: true, component: "div" }, translate('ra-realtime.notification.title')),
            message),
        React.createElement("div", { className: classes.action }, refresh && React.createElement(RefreshButton, { onClick: onRefresh }))));
};
var useStyles = makeStyles(function (theme) {
    var getColor = theme.palette.type === 'light' ? darken : lighten;
    var getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;
    return {
        root: __assign(__assign({}, theme.typography.body2), { borderRadius: theme.shape.borderRadius, display: 'flex', padding: '6px 16px', margin: theme.spacing(1) + "px 0", color: getColor(theme.palette.warning.main, 0.6), backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9) }),
        message: {
            padding: theme.spacing(1) + "px 0",
        },
        action: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            paddingLeft: theme.spacing(2),
            marginRight: -theme.spacing(1),
        },
    };
}, {
    name: 'RaWarning',
});
