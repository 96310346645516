import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  DateTimeInput,
  ListButton,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { CustomerDto } from '../../core/dto/customer.dto'
import { CustomerDocumentType } from '../../core/enum/CustomerDocumentType'
import { Gender } from '../../core/enum/Gender'

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const validateCustomerCreate = (values: CustomerDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.firstname) {
    errors.firstname = 'ra.validation.required'
  }
  if (!values.lastname) {
    errors.lastname = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.customers.titles.create')}</span>
}

export const CustomersCreate = (props: CreateProps) => (
  <Create {...props} title={<CreateTitle />}>
    <SimpleForm
      redirect="show"
      validate={validateCustomerCreate}
      toolbar={<CreateToolbar />}
    >
      <TextInput source="firstname" isRequired />
      <TextInput source="lastname" isRequired />
      <TextInput source="company" />
      <DateTimeInput source="birthdate" />
      <SelectInput
        source="documentType"
        choices={Object.entries(CustomerDocumentType).map(([value]) => ({
          id: value,
          name: `resources.enums.customerDocumentType.${value}`,
        }))}
      />
      <TextInput source="pesel" />
      <TextInput source="documentNumber" />
      <TextInput source="nationality" />
      <SelectInput
        source="gender"
        choices={Object.entries(Gender).map(([value]) => ({
          id: value,
          name: `resources.enums.gender.${value}`,
        }))}
      />
    </SimpleForm>
  </Create>
)
