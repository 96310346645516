const mapping: Record<string, string> = {
  id: 'l=>l.Id',
  isSuccessfull: 'l=>l.IsSuccessfull',
  createdAt: 'l=>l.CreatedAt',
  fetchedBansCount: 'l=>l.FetchedBansCount',
  bannedBookingsCount: 'l=>l.BannedBookingsCount',
  unbannedBookingsCount: 'l=>l.UnbannedBookingsCount',
}

export const mapSortLogParam = (key: string) => mapping[key]
