import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  UpdateParams,
  UpdateResult,
  Identifier,
  DeleteResult,
  DeleteParams,
} from 'react-admin'
import { TICKET_IMPORT_MAPPINGS_URL } from '../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import filterMapper from './ticket-import-mapping-filter.mapper'
import { mapSortTicketImportMappingParam } from './ticket-import-mapping-sort.mapper'
import { TicketImportMappingDto } from '../dto/ticket-import-mapping.dto'
import { ImportProperties } from '../enum/ImportProperties'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<TicketImportMappingDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortTicketImportMappingParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<TicketImportMappingDto[]>(
      `${TICKET_IMPORT_MAPPINGS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<TicketImportMappingDto>> => {
    const data = await get<TicketImportMappingDto>(
      TICKET_IMPORT_MAPPINGS_URL,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource,
    { data }: CreateParams<CreateTicketImportMappingRequest>,
  ): Promise<CreateResult<TicketImportMappingDto>> => {
    const object = await post<
      CreateTicketImportMappingRequest[],
      TicketImportMappingDto[]
    >(TICKET_IMPORT_MAPPINGS_URL, [data])
    return {
      data: object[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      TicketImportMappingDto
    >(TICKET_IMPORT_MAPPINGS_URL, {
      id,
      property:
        data.property !== previousData.property ? data.property : undefined,
      csvPropertyName:
        data.csvPropertyName !== previousData.csvPropertyName
          ? data.csvPropertyName
          : undefined,
      staticValue:
        data.staticValue !== previousData.staticValue
          ? data.staticValue
          : undefined,
      importTemplateId:
        data.importTemplateId !== previousData.importTemplateId
          ? data.importTemplateId
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<TicketImportMappingDto>> => {
    const data = await get<TicketImportMappingDto[]>(
      TICKET_IMPORT_MAPPINGS_URL,
      `/GetByConditions/o=>new int[] {${ids.toString()}}.Contains(o.Id)`,
    )
    return Promise.resolve({ data })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    await remove<undefined>(TICKET_IMPORT_MAPPINGS_URL, `/Delete/${id}`)
    return Promise.resolve({ data: { id } })
  },
} as DataProvider

export interface CreateTicketImportMappingRequest {
  readonly property: ImportProperties
  readonly csvPropertyName: string
  readonly staticValue?: string
  readonly importTemplateId: number
}

export default provider
