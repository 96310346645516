import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  Datagrid,
  DeleteButton,
  Filter,
  FilterProps,
  FormDataConsumer,
  FunctionField,
  List,
  ListProps,
  Pagination,
  required,
  sanitizeListRestProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { ResourceName } from '../../../../core/ResourceName'
import Button from '../../../common/customized-mui-components/Button'
import {
  CreateDialog,
  CreateDialogProps,
} from '../../../common/customized-ra-components/CreateDialog'
import {
  EditDialog,
  EditDialogProps,
} from '../../../common/customized-ra-components/EditDialog'
import { ParameterValueField } from '../common/ParameterValueField'
import { DynamicCounterParameterTypes } from '../../../../core/enum/DynamicCounterParameterTypes'
import { ParameterValueInput } from '../common/ParameterValueInput'
import { Authority } from '../../../../core/auth/Authority'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { DynamicCounterParameterDto } from '../../../../core/dto/dynamic-counter-parameter.dto'

const useStyles = makeStyles({
  editToolbar: {
    display: 'flex',
    '&>button:last-child': {
      marginLeft: 'auto',
    },
  },
})

const ListFilters = (props: Omit<FilterProps, 'children'>) => {
  const translate = useTranslate()

  return (
    <Filter {...props}>
      <TextInput source="key" alwaysOn />
      <SelectInput
        source="type"
        choices={Object.keys(DynamicCounterParameterTypes).map(
          (key: string) => ({
            id: key,
            name: translate(
              `resources.enums.DynamicCounterParameterTypes.${key}`,
            ),
          }),
        )}
        alwaysOn
      />
    </Filter>
  )
}

export const parameterTypeAuthority = {
  [DynamicCounterParameterTypes.STRING]: undefined,
  [DynamicCounterParameterTypes.INTEGER]: undefined,
  [DynamicCounterParameterTypes.DATE]: undefined,
  [DynamicCounterParameterTypes.CURRENT_DATE]: undefined,
  [DynamicCounterParameterTypes.CULTURE]: undefined,
  [DynamicCounterParameterTypes.CURRENT_USER_ID]: undefined,
  [DynamicCounterParameterTypes.USER_ID]: Authority.VIEW_USERS,
  [DynamicCounterParameterTypes.COMPANY_ID]: Authority.VIEW_COMPANIES,
  [DynamicCounterParameterTypes.PERMISSION_ID]: Authority.VIEW_ROLES,
  [DynamicCounterParameterTypes.ROLE_ID]: Authority.VIEW_ROLES,
  [DynamicCounterParameterTypes.CAMERA_ID]: Authority.VIEW_CAMERAS,
  [DynamicCounterParameterTypes.CAMERA_SERVER_ID]:
    Authority.VIEW_CAMERA_SERVERS,
  [DynamicCounterParameterTypes.PALM_ID]: Authority.VIEW_PALMS,
  [DynamicCounterParameterTypes.PALM_SCHEME_DATA_ID]:
    Authority.VIEW_PALM_SCHEME_DATA,
  [DynamicCounterParameterTypes.DEPOSITOR_ID]: Authority.VIEW_DEPOSITORS,
  [DynamicCounterParameterTypes.DEVICE_BASE_ID]: Authority.VIEW_DEVICES_BASE,
  [DynamicCounterParameterTypes.ENGINE_ID]: Authority.VIEW_ENGINES,
  [DynamicCounterParameterTypes.OFFLINE_SERVER_ID]:
    Authority.VIEW_OFFLINE_SERVERS,
  [DynamicCounterParameterTypes.PASSAGE_ID]: Authority.VIEW_PASSAGES,
  [DynamicCounterParameterTypes.PASSAGE_COUNTER_ID]: Authority.VIEW_PASSAGES,
  [DynamicCounterParameterTypes.VERIFIER_DEVICE_BASE_ID]:
    Authority.VIEW_VERIFIER_DEVICES,
  [DynamicCounterParameterTypes.DEVICE_CATEGORY_ID]:
    Authority.VIEW_DEVICE_CATEGORIES,
  [DynamicCounterParameterTypes.ENTRANCE_ID]: Authority.VIEW_ENTRANCES,
  [DynamicCounterParameterTypes.ENTRANCE_SET_ID]: Authority.VIEW_ENTRANCES_SET,
  [DynamicCounterParameterTypes.CAMERA_TYPE]: undefined,
  [DynamicCounterParameterTypes.DEVICE_DIRECTION]: undefined,
  [DynamicCounterParameterTypes.HARDWARE_VERSION]: undefined,
  [DynamicCounterParameterTypes.PALM_THEME]: undefined,
  [DynamicCounterParameterTypes.PASSAGE_COUNTER_TYPE]: undefined,
  [DynamicCounterParameterTypes.PASSAGE_DIRECTION]: undefined,
  [DynamicCounterParameterTypes.LOG_ID]: Authority.VIEW_LOGS,
  [DynamicCounterParameterTypes.LOG_SCHEME_DATA_ID]:
    Authority.VIEW_LOG_SCHEME_DATA,
  [DynamicCounterParameterTypes.ACCESS_ID]: Authority.VIEW_ACCESSES,
  [DynamicCounterParameterTypes.ACCESS_DATE_ID]: Authority.VIEW_ACCESS_DATES,
  [DynamicCounterParameterTypes.ACCESS_USING_ID]: Authority.VIEW_ACCESS_USINGS,
  [DynamicCounterParameterTypes.BLOCK_ID]: Authority.VIEW_BLOCKS,
  [DynamicCounterParameterTypes.BOOKING_ID]: Authority.VIEW_BOOKINGS,
  [DynamicCounterParameterTypes.CUSTOMER_ID]: Authority.VIEW_CUSTOMERS,
  [DynamicCounterParameterTypes.EVENT_ID]: Authority.VIEW_EVENTS,
  [DynamicCounterParameterTypes.OBJECT_ID]: Authority.VIEW_OBJECTS,
  [DynamicCounterParameterTypes.SCHEME_ID]: Authority.VIEW_SCHEMES,
  [DynamicCounterParameterTypes.SEAT_ID]: Authority.VIEW_SEATS,
  [DynamicCounterParameterTypes.TICKET_ID]: Authority.VIEW_TICKETS,
  [DynamicCounterParameterTypes.TRIBUNE_ID]: Authority.VIEW_TRIBUNES,
  [DynamicCounterParameterTypes.VARIANT_ID]: Authority.VIEW_VARIANTS,
  [DynamicCounterParameterTypes.ACCESS_ACTIVE_PERIOD_STARTING_OPTION]:
    undefined,
  [DynamicCounterParameterTypes.ACCESS_BONUS]: undefined,
  [DynamicCounterParameterTypes.ACCESS_DATE_TYPE]: undefined,
  [DynamicCounterParameterTypes.BLOCK_TYPE]: undefined,
  [DynamicCounterParameterTypes.BOOKING_DISABLED_OPTION]: undefined,
  [DynamicCounterParameterTypes.BOOKING_TYPE]: undefined,
  [DynamicCounterParameterTypes.CUSTOMER_DOCUMENT_TYPE]: undefined,
  [DynamicCounterParameterTypes.CUSTOMER_TYPE]: undefined,
  [DynamicCounterParameterTypes.EVENT_STATUS]: undefined,
  [DynamicCounterParameterTypes.GENDER]: undefined,
  [DynamicCounterParameterTypes.TICKET_TYPES]: undefined,
  [DynamicCounterParameterTypes.TRIBUNE_TYPES]: undefined,
  [DynamicCounterParameterTypes.REQUEST__DYNAMIC_COUNTER_DIRECTION]: undefined,
  [DynamicCounterParameterTypes.REQUEST__TICKET_ID]: undefined,
  [DynamicCounterParameterTypes.REQUEST__TICKET_CODE]: undefined,
  [DynamicCounterParameterTypes.REQUEST__TICKET_TYPE]: undefined,
  [DynamicCounterParameterTypes.REQUEST__TICKET_ACTIVE]: undefined,
  [DynamicCounterParameterTypes.REQUEST__TICKET_BOOKING_ID]: undefined,
}

const ParameterTypeInput = ({ ...props }) => {
  const translate = useTranslate()
  const { change } = useForm()
  const hasAuthority = useHasAuthority()

  const authorizedParameterTypes = Object.entries(parameterTypeAuthority)
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([key, value]) => !value || hasAuthority(value))
    .map(([key]) => key)

  const handleChange = () => {
    change('value', undefined)
  }

  return (
    <SelectInput
      {...props}
      source="type"
      choices={authorizedParameterTypes.map((key: string) => ({
        id: key,
        name: translate(`resources.enums.DynamicCounterParameterTypes.${key}`),
      }))}
      validate={required()}
      fullWidth
      onChange={handleChange}
    />
  )
}

const EditToolbar = ({ ...props }) => {
  const { handleClose, ...rest } = props
  const classes = useStyles()
  const hasAuthority = useHasAuthority()

  return (
    <Toolbar {...rest} className={classes.editToolbar}>
      <SaveButton redirect={false} onClick={handleClose} />
      <DeleteButton
        redirect={false}
        onClick={handleClose}
        disabled={!hasAuthority(Authority.DELETE_DYNAMIC_COUNTER_PARAMETERS)}
      />
    </Toolbar>
  )
}

const ParametersEditDialog = (props: EditDialogProps) => {
  const { handleClose } = props

  return (
    <EditDialog {...props}>
      <SimpleForm
        redirect={false}
        toolbar={<EditToolbar handleClose={handleClose} />}
      >
        <TextField source="id" label="ID" fullWidth />
        <TextInput source="key" validate={required()} fullWidth />
        <ParameterTypeInput validate={required()} fullWidth />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            !formData?.dynamicallyDefined &&
            formData.type !== DynamicCounterParameterTypes.CURRENT_DATE &&
            formData.type !== DynamicCounterParameterTypes.CURRENT_USER_ID ? (
              <ParameterValueInput
                validate={
                  formData.type !== DynamicCounterParameterTypes.CURRENT_DATE &&
                  formData.type !== DynamicCounterParameterTypes.CURRENT_USER_ID
                    ? required()
                    : undefined
                }
                fullWidth
                {...rest}
              />
            ) : (
              <TextField source="value" />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </EditDialog>
  )
}

const CreateToolbar = ({ ...props }) => {
  const { handleClose, ...rest } = props

  return (
    <Toolbar {...rest}>
      <SaveButton redirect={false} onClick={handleClose} />
    </Toolbar>
  )
}

const ParametersCreateDialog = (props: CreateDialogProps) => {
  const { handleClose } = props
  const { record: counter } = useShowContext()

  return (
    <CreateDialog {...props}>
      <SimpleForm
        initialValues={{
          dynamicCounterId: counter?.id,
          type: DynamicCounterParameterTypes.STRING,
        }}
        redirect={false}
        toolbar={<CreateToolbar handleClose={handleClose} />}
      >
        <TextInput source="key" validate={required()} fullWidth />
        <ParameterTypeInput validate={required()} fullWidth />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            formData.type !== DynamicCounterParameterTypes.CURRENT_DATE &&
            formData.type !== DynamicCounterParameterTypes.CURRENT_USER_ID ? (
              <ParameterValueInput
                validate={
                  formData.type !== DynamicCounterParameterTypes.CURRENT_DATE &&
                  formData.type !== DynamicCounterParameterTypes.CURRENT_USER_ID
                    ? required()
                    : undefined
                }
                fullWidth
                {...rest}
              />
            ) : (
              <TextField source="value" />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </CreateDialog>
  )
}

const DynamicCounterParametersActions = ({ ...props }) => {
  const { handleOpenCreate, ...rest } = props
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <Button
        label={translate('ra.action.create')}
        onClick={handleOpenCreate}
        startIcon={<Add />}
        disabled={
          !hasAuthority(Authority.EDIT_DYNAMIC_COUNTERS) ||
          !hasAuthority(Authority.CREATE_DYNAMIC_COUNTER_PARAMETERS)
        }
      />
    </TopToolbar>
  )
}

const DynamicCounterParametersGrid = ({ ...props }) => {
  const { handleOpenEdit, ...rest } = props
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Datagrid
      {...rest}
      padding={smallScreen ? 'checkbox' : 'default'}
      hasBulkActions={false}
      rowClick={
        hasAuthority(Authority.EDIT_DYNAMIC_COUNTER_PARAMETERS) &&
        hasAuthority(Authority.EDIT_DYNAMIC_COUNTERS)
          ? handleOpenEdit
          : undefined
      }
      rowStyle={
        hasAuthority(Authority.EDIT_DYNAMIC_COUNTER_PARAMETERS) &&
        hasAuthority(Authority.EDIT_DYNAMIC_COUNTERS)
          ? (record) => ({
              color:
                !parameterTypeAuthority[record?.type] ||
                hasAuthority(parameterTypeAuthority[record?.type])
                  ? 'default'
                  : theme.palette.grey[500],
              cursor:
                !parameterTypeAuthority[record?.type] ||
                hasAuthority(parameterTypeAuthority[record?.type])
                  ? 'pointer'
                  : 'default',
            })
          : undefined
      }
    >
      <TextField source="id" label="ID" />
      <TextField source="key" />
      <ParameterValueField source="value" />
      <FunctionField
        source="type"
        render={(record, source) =>
          translate(
            `resources.enums.DynamicCounterParameterTypes.${
              record?.[source as string]
            }`,
          )
        }
      />
    </Datagrid>
  )
}

export const DynamicCounterParametersList = (props: ListProps) => {
  const { basePath } = useListContext()
  const [createOpen, setCreateOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [editId, setEditId] = useState<string | undefined>(undefined)
  const hasAuthority = useHasAuthority()

  const handleOpenCreate = () => {
    setCreateOpen(true)
  }

  const handleCloseCreate = () => {
    setCreateOpen(false)
  }

  const handleOpenEdit = (
    id: string,
    base: string,
    record: DynamicCounterParameterDto,
  ) => {
    if (
      !parameterTypeAuthority[record?.type] ||
      hasAuthority(parameterTypeAuthority[record?.type] as any)
    ) {
      setEditId(id)
      setEditOpen(true)
    }
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
  }

  return (
    <>
      <ParametersCreateDialog
        {...props}
        basePath="/dynamic-counters"
        handleClose={handleCloseCreate}
        resource={ResourceName.DYNAMIC_COUNTER_PARAMETERS}
        open={createOpen}
      />
      <ParametersEditDialog
        {...props}
        basePath="/dynamic-counters"
        handleClose={handleCloseEdit}
        id={editId as string}
        resource={ResourceName.DYNAMIC_COUNTER_PARAMETERS}
        open={editOpen}
      />
      <List
        {...props}
        actions={
          <DynamicCounterParametersActions
            handleOpenCreate={handleOpenCreate}
          />
        }
        basePath={basePath}
        bulkActionButtons={false}
        empty={false}
        exporter={false}
        filters={<ListFilters />}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
        perPage={20}
      >
        <DynamicCounterParametersGrid handleOpenEdit={handleOpenEdit} />
      </List>
    </>
  )
}
