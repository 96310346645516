import { JobStatuses } from '../../enum/JobStatuses'

export type ToggleButtonState = {
  type: string
  text: string
}

enum JobStatusInitialState {
  INITIAL_STATE = 'InitialState',
}

export const ReducerJobStatus = {
  ...JobStatuses,
  ...JobStatusInitialState,
}

type ReducerJobStatus = typeof ReducerJobStatus[keyof typeof ReducerJobStatus]

export type ToggleButtonAction = { type: ReducerJobStatus }
