import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { UserDto } from '../../../core/dto/user.dto'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'

interface UserTitleProps extends TitleProps {
  record?: UserDto
}

export const validateUserEdit = (values: UserDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.userName) {
    errors.userName = 'ra.validation.required'
  }
  return errors
}

const EditTitle = (props: UserTitleProps) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.users.titles.edit')}: {record?.id}.{' '}
      {record?.userName}{' '}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const UserEdit = (props: EditProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Edit {...props} title={<EditTitle />} actions={false}>
      <SimpleForm
        redirect="show"
        validate={validateUserEdit}
        toolbar={<EditToolbar />}
      >
        <TextField source="id" label="ID" />
        <TextInput source="userName" isRequired />
        {hasAuthority(Authority.VIEW_COMPANIES) && (
          <FilteredReferenceInput
            source="companyId"
            defaultValue={null}
            reference={ResourceName.COMPANIES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
        <BooleanInput source="requirePasswordUpdate" />
      </SimpleForm>
    </Edit>
  )
}
