import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
  DateField,
  ReferenceField,
  FunctionField,
} from 'react-admin'
import { OfflineServerDto } from '../../../core/dto/device/offline-servers/offline-server.dto'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DeviceStatusesTab } from '../common/modules/ModuleStatus'
import { OfflineServerSetCredentialsButton } from './offline-server-show/OfflineServerSetCredentialsButton'
import { OfflineServerRemoveCredentialsButton } from './offline-server-show/OfflineServerRemoveCredentialsButton'
import { OfflineServerForceReportStateButton } from './offline-server-show/OfflineServerForceReportStateButton'
import { OfflineServerRestoreReplicationButton } from './offline-server-show/OfflineServerRestoreReplicationButton'
import { CameraDto } from '../../../core/dto/device/camera/cameras/camera.dto'
import { DeviceCategoryExtendedDto } from '../../../core/dto/device-category/device-category-extended.dto'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'

interface OfflineServerTitleProps extends TitleProps {
  record?: OfflineServerDto
}

const ShowTitle = (data: OfflineServerTitleProps) => {
  const { record: server } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.offline-servers.titles.show')}: {server?.id}.{' '}
      {server?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: server } = useShowContext<OfflineServerDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <OfflineServerSetCredentialsButton />
        <OfflineServerRemoveCredentialsButton />
        <OfflineServerRestoreReplicationButton />
        <EditButton
          basePath={`/${ResourceName.OFFLINE_SERVERS}`}
          record={server}
          variant="contained"
          disabled={!hasAuthority(Authority.EDIT_OFFLINE_SERVERS)}
        />
      </Box>
    </>
  )
}

const SetStateActions = () => (
  <>
    <Box p={1} display="block" textAlign="right">
      <OfflineServerForceReportStateButton />
    </Box>
  </>
)

const OfflineServerTabs = ({ ...props }) => {
  const { record: server } = props
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.offline-servers.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <FunctionField<OfflineServerDto>
          source="isWatched"
          render={(record?: OfflineServerDto) => (
            <div>
              {record?.isWatched
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <DateField
          source="lastRefresh"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        <TextField source="address" />
        {server?.categoryId && hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link="show"
          >
            <FunctionField<DeviceCategoryExtendedDto>
              source="hierarchyString"
              render={(record?: DeviceCategoryExtendedDto) =>
                `${record?.id}. ${record?.hierarchyString}`
              }
            />
          </ReferenceField>
        )}
        {server?.cameraId && hasAuthority(Authority.VIEW_CAMERAS) && (
          <ReferenceField
            source="cameraId"
            reference={ResourceName.CAMERAS}
            link="show"
          >
            <FunctionField<CameraDto>
              source="name"
              render={(camera?: CameraDto) =>
                `${camera?.id}. ${camera?.description}`
              }
            />
          </ReferenceField>
        )}
        <GeneralActions />
      </Tab>
      {server?.jsonStatus && (
        <Tab label="resources.offline-servers.tabs.statuses" path="statuses">
          <DeviceStatusesTab
            jsonStatus={server.jsonStatus}
            deviceType={DeviceTypes.OFFLINE_SERVER}
          />
          <SetStateActions />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const OfflineServerShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <OfflineServerTabs />
  </Show>
)
