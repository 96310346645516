const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string
> = {
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  objectId: (lambdaParameter, value) => `${lambdaParameter}.ObjectId==${value}`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
