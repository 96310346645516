import { Box, Divider, Grid, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Show,
  ShowProps,
  TextField,
  TitleProps,
  useTranslate,
  EditButton,
  useShowContext,
  TabbedShowLayout,
  Tab,
  ReferenceField,
} from 'react-admin'
import ReactJson from 'react-json-view'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { VerifierSchemeDataDto } from '../../../../core/dto/verifier-scheme-data.dto'
import { ExtendedTheme } from '../../../layout/app.theme'
import { GetVerifierSchemeDataResponse } from '../../../../core/verifier-scheme-data/verifier-scheme-data.provider'

interface VerifierSchemeDataTitleProps extends TitleProps {
  record?: VerifierSchemeDataDto
}

const ShowTitle = (data: VerifierSchemeDataTitleProps) => {
  const { record: scheme } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.verifier-scheme-data.titles.show')}: {scheme?.id}
    </span>
  )
}

const GeneralActions = () => {
  const { record: scheme } = useShowContext<VerifierSchemeDataDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="flex" justifyContent="flex-end">
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <EditButton
              variant="contained"
              basePath="/verifier-scheme-data"
              record={scheme}
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_VERIFIER_SCHEME_DATA)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const VerifierSchemeDataJsonTab = ({ ...props }) => {
  const translate = useTranslate()
  const { record: scheme } = useShowContext<GetVerifierSchemeDataResponse>()
  const theme = useTheme<ExtendedTheme>()

  return (
    <Tab
      {...props}
      label={translate('resources.verifier-scheme-data.tabs.json')}
    >
      <ReactJson
        src={JSON.parse(scheme?.moduleSchemeMappingsJson as string)}
        theme={theme.palette.rjvTheme}
        iconStyle="triangle"
        name={translate(
          'resources.verifier-scheme-data.fields.moduleSchemeMappingsJson',
        )}
        style={{
          marginBottom: theme.spacing(1),
          padding: theme.spacing(1),
        }}
        displayObjectSize={false}
        displayDataTypes={false}
        quotesOnKeys={false}
      />
    </Tab>
  )
}

const VerifierSchemeDataGeneralTab = ({ ...props }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <Tab
      {...props}
      label={translate('resources.verifier-scheme-data.tabs.general')}
    >
      <TextField source="id" label="ID" />
      {hasAuthority(Authority.VIEW_SCHEMES) && (
        <ReferenceField
          source="schemeId"
          reference={ResourceName.SCHEMES}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      {hasAuthority(Authority.VIEW_VERIFIER_DEVICES) && (
        <ReferenceField
          source="verifierDeviceId"
          reference={ResourceName.VERIFIER_DEVICES}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      <GeneralActions />
    </Tab>
  )
}

const VerifierSchemeDataTabs = ({ ...props }) => {
  const { record: scheme } = useShowContext<VerifierSchemeDataDto>()

  return (
    <TabbedShowLayout {...props}>
      <VerifierSchemeDataGeneralTab />
      {scheme?.moduleSchemeMappingsJson && <VerifierSchemeDataJsonTab />}
    </TabbedShowLayout>
  )
}

export const VerifierSchemeDataShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <VerifierSchemeDataTabs />
  </Show>
)
