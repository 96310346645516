import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useRefresh,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'

const useStyles = makeStyles({
  button: {
    margin: '2px',
  },
})

export const TriggerRecurringJobButton = ({ ...props }) => {
  const { loading } = useShowContext()
  const { record: job } = props
  const classes = useStyles()
  const hasAuthority = useHasAuthority()
  const refresh = useRefresh()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const [triggerDialogOpen, setTriggerDialogOpen] = useState(false)

  const handleTrigger = async () => {
    setTriggerDialogOpen(false)
    await mutate(
      {
        type: sanitizeFetchType('triggerRecurringJob'),
        resource: ResourceName.RECURRING_JOBS,
        payload: {
          id: job?.id,
        },
      },
      { returnPromise: true },
    )
    refresh()
  }

  const handleTriggerDialog = () => {
    setTriggerDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={triggerDialogOpen}
        confirm={handleTrigger}
        source="triggerRecurringJob"
        cancel={() => setTriggerDialogOpen(false)}
        action="set"
        resource={ResourceName.RECURRING_JOBS}
      />
      <Button
        variant="contained"
        onClick={handleTriggerDialog}
        color="primary"
        className={classes.button}
        label="resources.recurring-jobs.actions.triggerRecurringJob"
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.TRIGGER_RECURRING_JOBS) ||
          loading ||
          mutationLoading
        }
      />
    </>
  )
}
