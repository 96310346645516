import { DataProvider } from 'react-admin'
import { DynamicCounterParameterDto } from '../../dto/dynamic-counter-parameter.dto'

const fakeDynamicCounterParameters: DynamicCounterParameterDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeDynamicCounterParameters,
      total: fakeDynamicCounterParameters.length,
    }),
} as DataProvider

export default provider
