import { Autorenew } from '@material-ui/icons'
import React, { useEffect, useReducer, useState } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { EventDto } from '../../../core/dto/event/event.dto'
import { EventStatus } from '../../../core/enum/EventStatus'
import {
  activationButtonInitialState,
  toggleActivationButtonReducer,
} from '../../../core/events/handle-activation-button.reducer'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { StatusTransitionDialog } from './StatusTransitionDialog'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import { ResourceName } from '../../../core/ResourceName'

export const ToggleActivationButton = ({ ...props }) => {
  const { asRecurringJob, recurringStatus } = props
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const [{ type, text, mutateQuery }, dispatch] = useReducer(
    toggleActivationButtonReducer,
    activationButtonInitialState,
  )
  const event = useRecordContext<EventDto>()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [transitionDialogOpen, setTransitionDialogOpen] = useState(false)
  const [skipTransitionDialog, setSkipTransitionDialog] = useState(false)
  const [mutate, { loading: mutationLoading }] = useMutation()

  const [jobId, setJobId] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!asRecurringJob) dispatch({ type: event.status })
    else dispatch({ type: recurringStatus })
  }, [asRecurringJob, event, recurringStatus])

  const handleCloseTransitionDialog = () => {
    setSkipTransitionDialog(false)
    setTransitionDialogOpen(false)
    setJobId(undefined)
  }

  const handleSkipTransitionDialog = () => {
    setSkipTransitionDialog(true)
    setTransitionDialogOpen(false)
  }

  const onClick = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    let query

    if (!asRecurringJob) {
      query = mutateQuery?.(event)
    } else {
      query = mutateQuery?.(
        event,
        recurringJobName as string,
        cronExpression as string,
      )
    }

    const { data } = await mutate(query, {
      returnPromise: true,
      onFailure: (msg) => notify(msg, 'error'),
    })

    setJobId(data?.jobId)
    setConfirmDialogOpen(false)
    setSkipTransitionDialog(false)
    setTransitionDialogOpen(true)
  }

  return (
    <>
      <StatusTransitionDialog
        handleCloseDialog={handleCloseTransitionDialog}
        handleSkipDialog={handleSkipTransitionDialog}
        jobId={jobId}
        open={transitionDialogOpen}
        skipDialog={skipTransitionDialog}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        confirm={onClick}
        source="toggleActivation"
        cancel={() => setConfirmDialogOpen(false)}
        action="set"
        resource={ResourceName.EVENTS}
        asRecurringJob={asRecurringJob}
      />
      <Button
        disabled={
          !hasAuthority(Authority.EVENTS_CHANGE_ACTIVE_STATE) ||
          type === EventStatus.IN_TRANSITION ||
          loading ||
          mutationLoading
        }
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        onClick={() => setConfirmDialogOpen(true)}
        label={!asRecurringJob ? text : `${text}-recurring`}
        useSmallVersionBreakpoint={false}
        size="small"
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
      />
    </>
  )
}
