import { DataProvider } from 'react-admin'
import { DoorDto } from '../../dto/device/doors/door.dto'

const fakeDoors: DoorDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeDoors, total: fakeDoors.length }),
} as DataProvider

export default provider
