import { createStyles, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useShowContext } from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { SetTSCanCredentialsDialog } from '../TSCanDialogs'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: '2px',
    },
  }),
)

export const TSCanSetCredentialsButton = () => {
  const { loading } = useShowContext()
  const classes = useStyles()
  const [tsCanCredentialsModalOpen, setTSCanCredentialsModalOpen] =
    useState(false)
  const hasAuthority = useHasAuthority()

  const handleSetTSCanCredentialsDialog = () => {
    setTSCanCredentialsModalOpen(true)
  }

  return (
    <>
      <SetTSCanCredentialsDialog
        open={tsCanCredentialsModalOpen}
        close={() => setTSCanCredentialsModalOpen(false)}
      />
      <Button
        variant="contained"
        onClick={handleSetTSCanCredentialsDialog}
        color="primary"
        className={classes.button}
        label="resources.ts-cans.actions.setCredentials"
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.TS_CANS_MANAGE_CREDENTIALS) || loading
        }
      />
    </>
  )
}
