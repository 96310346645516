import { Autorenew } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { SetStateDialog } from '../DoorDialogs'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { DoorDto } from '../../../../core/dto/device/doors/door.dto'

export const DoorSetStateButton = ({ ...props }) => {
  const { asRecurringJob } = props
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const [stateDialogOpen, setStateDialogOpen] = useState(false)
  const [released, setReleased] = useState(false)
  const door = useRecordContext<DoorDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  const handleSetState = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    await mutate(
      {
        type: sanitizeFetchType('setDoorState'),
        resource: ResourceName.DOORS,
        payload: {
          doorId: door?.id,
          released,
          recurringJobName,
          cronExpression,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
    refresh()
  }

  return (
    <>
      <SetStateDialog
        open={stateDialogOpen}
        close={() => setStateDialogOpen(false)}
        released={released}
        setReleased={setReleased}
        handleSetState={handleSetState}
        asRecurringJob={asRecurringJob}
      />
      <Button
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        label={
          !asRecurringJob
            ? 'resources.doors.actions.setState'
            : 'common.recurring-job.buttons.create-recurring-job'
        }
        useSmallVersionBreakpoint={false}
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
        disabled={
          !hasAuthority(Authority.DOORS_SET_RELEASE) ||
          loading ||
          mutationLoading
        }
        onClick={() => setStateDialogOpen(true)}
      />
    </>
  )
}
