export enum ResourceName {
  ACCESS_DATES = 'access-dates',
  ACCESSES = 'accesses',
  ACCESS_USINGS = 'access-usings',
  BOOKINGS = 'bookings',
  ENGINES = 'engines',
  ENTRANCES = 'entrances',
  ENTRANCES_SET = 'entrances-set',
  EVENTS = 'events',
  COMPANIES = 'companies',
  CUSTOMERS = 'customers',
  CURRENT_USER = 'current_user',
  LOGS = 'logs',
  OBJECTS = 'objects',
  PALMS = 'palms',
  ROLES = 'roles',
  PERMISSIONS = 'permissions',
  PASSAGES = 'passages',
  STATS_OVERALL_VIEW_LIST = 'stats-overall-view-list',
  STATS_OVERALL_VIEW = 'stats-overall-view',
  STATS_PERIOD_VIEW = 'stats-period-view',
  STATS_PERIOD_VIEW_LIST = 'stats-period-view-list',
  STATS_PERIOD_LIST = 'stats-period-list',
  STATS_PASSAGE_COUNTERS_VIEW = 'stats-passage-counters-view',
  STATS_EVENT_ENTRANCES_VIEW = 'stats-event-entrances-view',
  STATS_EVENT_ENTRANCES_VIEW_LIST = 'stats-event-entrances-view-list',
  STATS_DYNAMIC_STATISTICS = 'stats-dynamic-statistics',
  STATS_DYNAMIC_STATISTICS_EXECUTION = 'stats-dynamic-statistics-execution',
  STATS_DYNAMIC_STATISTIC_PARAMETERS = 'stats-dynamic-statistic-parameters',
  TICKETS = 'tickets',
  TRIBUNES = 'tribunes',
  BLOCKS = 'blocks',
  SEATS = 'seats',
  USERS = 'users',
  VARIANTS = 'variants',
  DEVICE_CATEGORIES = 'device-categories',
  DETECTORS = 'detectors',
  CAMERAS = 'cameras',
  CAMERA_SERVERS = 'camera-servers',
  JOBS = 'jobs',
  RECURRING_JOBS = 'recurring-jobs',
  OFFLINE_SERVERS = 'offline-servers',
  DEVICES_BASE = 'devices-base',
  VERIFIER_DEVICES = 'verifier-devices',
  SERVICE_BOOKINGS = 'service-bookings',
  ENTRANCES_MATRIX = 'entrances-matrix',
  SCHEMES = 'schemes',
  LOG_SCHEME_DATA = 'log-scheme-data',
  PALM_SCHEME_DATA = 'palm-scheme-data',
  TECHNICAL_ASSISTANCE_PROTOCOL = 'technical-assistance-protocol',
  SYNC_LOGS = 'sync-logs',
  SERVICE_LINK = 'service-link',
  DEPOSITORS = 'depositors',
  DYNAMIC_PROPERTY_DEFINITIONS = 'dynamic-property-definitions',
  DYNAMIC_COUNTERS = 'dynamic-counters',
  DYNAMIC_COUNTER_PARAMETERS = 'dynamic-counter-parameters',
  ACCESS_BONUS = 'access-bonuses',
  VERIFIER_DEVICE_DISABLED = 'verifier-device-disabled',
  VERIFIER_SCHEME_DATA = 'verifier-scheme-data',
  DOORS = 'doors',
  TERMINALS = 'terminals',
  TS_CANS = 'ts-cans',
  TICKET_IMPORTS = 'ticket-imports',
  TICKET_IMPORT_MAPPINGS = 'ticket-import-mappings',
  TICKET_IMPORT_TEMPLATES = 'ticket-import-templates',
}
