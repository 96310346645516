import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  AccountBalanceWallet,
  ArrowForward,
  Cancel,
  CenterFocusStrong,
  PhoneAndroid,
  Storage,
} from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  useTranslate,
  SaveButton,
  Toolbar,
  ToolbarProps,
  TextField,
  ShowButton,
  TabbedForm,
  FormTab,
  FormDataConsumer,
} from 'react-admin'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import FilteredReferenceInput from '../../../common/FilteredReferenceInput'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { DeviceBaseWithTypeInfoDto } from '../../../../core/dto/device/common/device-base/device-base-with-type-info.dto'
import { DeviceTypes } from '../../../../core/enum/DeviceTypes'
import { VerifierSchemeJsonInput } from './verifier-scheme-data-edit/VerifierSchemeJsonInput'
import { GetVerifierSchemeDataResponse } from '../../../../core/verifier-scheme-data/verifier-scheme-data.provider'

export const validateVerifierSchemeDataEdit = (
  values: GetVerifierSchemeDataResponse,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.schemeId) {
    errors.schemeId = 'ra.validation.required'
  }
  if (!values.verifierDeviceId) {
    errors.verifierDeviceId = 'ra.validation.required'
  }
  if (values.moduleSchemeMappings) {
    const { moduleSchemeMappings } = values
    if (
      moduleSchemeMappings?.some(
        (mapping) =>
          moduleSchemeMappings.indexOf(mapping) !==
          moduleSchemeMappings.lastIndexOf(mapping),
      )
    )
      errors.moduleSchemeMappings = 'ra.validation.duplicate_keys'
  }
  return errors
}

const EditTitle = ({ ...props }) => {
  const { record: scheme } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.verifier-scheme-data.titles.edit')}: {scheme?.id}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const VerifierSchemeDataEdit = (props: EditProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Edit {...props} hasShow={false} title={<EditTitle />}>
      <TabbedForm
        redirect="show"
        toolbar={<EditToolbar />}
        validate={validateVerifierSchemeDataEdit}
      >
        <FormTab label="resources.verifier-scheme-data.tabs.general">
          <TextField source="id" label="ID" />
          {hasAuthority(Authority.VIEW_SCHEMES) && (
            <FilteredReferenceInput
              source="schemeId"
              reference={ResourceName.SCHEMES}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={smallScreen ? 5 : 15}
              filterSource="translatedLogSchemeDataText"
              selectWithPaginationInputProps={{
                optionText: 'translatedLogSchemeDataHierarchyString',
                showFilter: true,
              }}
            />
          )}
          {hasAuthority(Authority.VIEW_VERIFIER_DEVICES) && (
            <FilteredReferenceInput
              source="verifierDeviceId"
              reference={ResourceName.VERIFIER_DEVICES}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={smallScreen ? 5 : 15}
              filterSource="name"
              selectWithPaginationInputProps={{
                optionText: 'name',
                showFilter: true,
                rowIcon: (record: DeviceBaseWithTypeInfoDto) => {
                  switch (record?.type) {
                    case DeviceTypes.ENGINE:
                      return <AccountBalanceWallet />
                    case DeviceTypes.PALM:
                      return <PhoneAndroid />
                    case DeviceTypes.DETECTOR:
                      return <CenterFocusStrong />
                    case DeviceTypes.PASSAGE:
                      return <ArrowForward />
                    case DeviceTypes.OFFLINE_SERVER:
                      return <Storage />
                    default:
                      return <div />
                  }
                },
              }}
            />
          )}
        </FormTab>
        <FormTab label="resources.verifier-scheme-data.tabs.json">
          <FormDataConsumer source="moduleSchemeMappings" fullWidth>
            {({ formData, ...rest }) => (
              <VerifierSchemeJsonInput
                formData={formData}
                fullWidth
                {...rest}
              />
            )}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
