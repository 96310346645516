import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  CreateParams,
  CreateResult,
  DeleteParams,
} from 'react-admin'
import { DYNAMIC_COUNTERS_URL } from '../../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { DynamicCounterDto } from '../../dto/dynamic-counter.dto'
import { mapSortDynamicCounterParams } from './dynamic-counter-sort.mapper'
import filterMapper from './dynamic-counter-filter.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, pagination, sort }: TicketAPIGetListParams,
  ): Promise<GetListResult<DynamicCounterDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortDynamicCounterParams,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DynamicCounterDto[]>(
      `${DYNAMIC_COUNTERS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DynamicCounterDto>> => {
    const data = await get<DynamicCounterDto>(DYNAMIC_COUNTERS_URL, `/${id}`)

    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<DynamicCounterDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<UpdateDynamicCounterRequest, DynamicCounterDto>(
      `${DYNAMIC_COUNTERS_URL}`,
      {
        id: id as number,
        name: data.name !== previousData.name ? data.name : undefined,
        active: data.active !== previousData.active ? data.active : undefined,
        sqlQuery:
          data.sqlQuery !== previousData.sqlQuery ? data.sqlQuery : undefined,
        state: data.state !== previousData.state ? data.state : undefined,
        limit: data.limit !== previousData.limit ? data.limit : undefined,
        schemeId:
          data.schemeId !== previousData.schemeId ? data.schemeId : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<DynamicCounterDto>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateDynamicCounterRequest[],
      DynamicCounterDto[]
    >(`${DYNAMIC_COUNTERS_URL}`, [
      {
        name: data?.name,
        active: data?.active,
        sqlQuery: data?.sqlQuery,
        state: data?.state,
        limit: data?.limit,
        schemeId: data?.schemeId,
      },
    ])
    return Promise.resolve({ data: created[0] })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<CreateResult> => {
    const deleted = await remove<DynamicCounterDto>(
      DYNAMIC_COUNTERS_URL,
      `/${id}`,
    )

    return Promise.resolve({ data: deleted })
  },
} as DataProvider

interface CreateDynamicCounterRequest {
  name: string
  active: boolean
  sqlQuery: string
  state: number
  limit: number
  schemeId: number
}

interface UpdateDynamicCounterRequest {
  id: number
  name?: string
  active?: boolean
  sqlQuery?: string
  state?: number
  limit?: number
  schemeId?: number
}

export default provider
