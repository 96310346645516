import { SET_THEME } from '../constants/reducer.actions'
import { FrontDisplayModes } from '../enum/FrontDisplayModes'

export type ThemeState = {
  theme: {
    darkMode: 'light' | 'dark'
  }
}

const initialState = () => {
  let theme
  try {
    theme = JSON.parse(localStorage?.preferences)?.theme
  } catch {
    theme = FrontDisplayModes.LIGHT
  }
  return { darkMode: theme.toLowerCase() }
}

export type SetThemeAction = {
  type: typeof SET_THEME
  payload?: FrontDisplayModes
}

function themeReducer(state = initialState(), action: SetThemeAction) {
  let preferences
  try {
    preferences = JSON.parse(localStorage?.preferences)
    if (preferences === null || preferences === undefined) preferences = {}
  } catch {
    preferences = {}
  }
  if (action?.type === SET_THEME) {
    if (action?.payload === FrontDisplayModes.DARK) {
      localStorage.setItem(
        'preferences',
        JSON.stringify({ ...preferences, theme: FrontDisplayModes.DARK }),
      )
      return {
        ...state,
        darkMode: FrontDisplayModes.DARK.toLowerCase(),
      }
    }
    if (action?.payload === FrontDisplayModes.LIGHT) {
      localStorage.setItem(
        'preferences',
        JSON.stringify({ ...preferences, theme: FrontDisplayModes.LIGHT }),
      )
      return {
        ...state,
        darkMode: FrontDisplayModes.LIGHT.toLowerCase(),
      }
    }
  }
  return state
}

export default themeReducer
