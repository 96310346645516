import { mapping as accessUsingSortMapper } from '../../access-usings/access-usings-sort.mapper'

const mapping: Record<string, string> = {
  ...accessUsingSortMapper,
  id: 'au=>au.Id',
  entranceDateTime: 'au=>au.Date',
  entranceDevice: 'au=>au.VerifierDeviceId',
}

export const mapSortEntranceViewParam = (key: string) => mapping[key]
