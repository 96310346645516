import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  CreateParams,
  CreateResult,
} from 'react-admin'
import { TICKET_IMPORTS_URL } from '../api-urls'
import { get, getWithPagination, post, postFile } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import filterMapper from './ticket-import-filter.mapper'
import { mapSortTicketImportParam } from './ticket-import-sort.mapper'
import { TicketImportDto } from '../dto/ticket-import.dto'
import { DataProviderExtensionResult } from '../common/data-provider'
import { TicketImportMode } from '../enum/TicketImportMode'
import { ResourceName } from '../ResourceName'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<TicketImportDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortTicketImportParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<TicketImportDto[]>(
      `${TICKET_IMPORTS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<TicketImportDto>> => {
    const data = await get<TicketImportDto>(TICKET_IMPORTS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateTicketImportParams>,
  ): Promise<CreateResult> => {
    const { importMode, ...rest } = data
    let importId
    if (importMode === TicketImportMode.IMPORT) {
      const { data: response } = await provider.importTicketsAsyncJob(
        ResourceName.TICKET_IMPORTS,
        rest as ImportTicketsAsyncJobParams,
      )
      importId = response?.importId
    } else {
      const { data: response } = await provider.generateTicketsAsyncJob(
        ResourceName.TICKET_IMPORTS,
        rest as GenerateTicketsAsyncJobParams,
      )
      importId = response?.importId
    }
    return Promise.resolve({ data: { id: importId } })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<TicketImportDto>> => {
    const data = await get<TicketImportDto[]>(
      TICKET_IMPORTS_URL,
      `/GetByConditions/o=>new int[] {${ids.toString()}}.Contains(o.Id)`,
    )
    return Promise.resolve({ data })
  },
  importTicketsAsyncJob: async (
    resource: string,
    params: ImportTicketsAsyncJobParams,
  ): Promise<DataProviderExtensionResult<ImportTicketsAsyncJobResponse>> => {
    const { csvFile, name, templateId } = params
    const body = new FormData()
    body.append('CSVFile', csvFile?.rawFile as File)
    const data = await postFile<ImportTicketsAsyncJobResponse>(
      `${TICKET_IMPORTS_URL}/ImportTicketsAsyncJob?name=${name}&templateId=${templateId}`,
      body,
    )
    return Promise.resolve({ data })
  },
  revertTicketsAsyncJob: async (
    resource: string,
    params: ImportActionAsyncJobParams,
  ): Promise<DataProviderExtensionResult<ImportActionAsyncJobResponse>> => {
    const { importId } = params
    const data = await post<undefined, ImportActionAsyncJobResponse>(
      `${TICKET_IMPORTS_URL}/RevertTicketsImportAsyncJob?importId=${importId}`,
      undefined,
    )
    return Promise.resolve({ data })
  },
  acceptTicketsImportAsyncJob: async (
    resource: string,
    params: ImportActionAsyncJobParams,
  ): Promise<DataProviderExtensionResult<ImportActionAsyncJobResponse>> => {
    const { importId } = params
    const data = await post<undefined, ImportActionAsyncJobResponse>(
      `${TICKET_IMPORTS_URL}/AcceptTicketsImportAsyncJob?importId=${importId}`,
      undefined,
    )
    return Promise.resolve({ data })
  },
  generateTicketsAsyncJob: async (
    resource: string,
    params: GenerateTicketsAsyncJobParams,
  ): Promise<DataProviderExtensionResult<ImportTicketsAsyncJobResponse>> => {
    const { name, templateId, ticketsCount } = params
    const data = await post<undefined, ImportTicketsAsyncJobResponse>(
      `${TICKET_IMPORTS_URL}/GenerateTicketsAsyncJob?name=${name}&templateId=${templateId}&ticketsCount=${ticketsCount}`,
      undefined,
    )
    return Promise.resolve({ data })
  },
} as TicketImportsDataProvider

interface TicketImportsDataProvider extends DataProvider {
  importTicketsAsyncJob: (
    resource: string,
    params: ImportTicketsAsyncJobParams,
  ) => Promise<DataProviderExtensionResult<ImportTicketsAsyncJobResponse>>

  revertTicketsAsyncJob: (
    resource: string,
    params: ImportActionAsyncJobParams,
  ) => Promise<DataProviderExtensionResult<ImportActionAsyncJobResponse>>

  acceptTicketsImportAsyncJob: (
    resource: string,
    params: ImportActionAsyncJobParams,
  ) => Promise<DataProviderExtensionResult<ImportActionAsyncJobResponse>>

  generateTicketsAsyncJob: (
    resource: string,
    params: GenerateTicketsAsyncJobParams,
  ) => Promise<DataProviderExtensionResult<ImportTicketsAsyncJobResponse>>
}

interface ImportTicketsAsyncJobParams {
  readonly name: string
  readonly templateId: number
  readonly csvFile?: {
    title: string
    src: string
    rawFile: File
  }
}

interface ImportTicketsAsyncJobResponse {
  readonly importId: number
  readonly jobId: string
}

interface ImportActionAsyncJobParams {
  readonly importId: number
}

interface ImportActionAsyncJobResponse {
  readonly jobId: string
}

interface GenerateTicketsAsyncJobParams {
  readonly name: string
  readonly templateId: number
  readonly ticketsCount: number
}

export interface CreateTicketImportParams {
  readonly name: string
  readonly templateId: number
  readonly csvFile?: {
    title: string
    src: string
    rawFile: File
  }
  readonly ticketsCount?: number
  readonly importMode: TicketImportMode
}

export default provider
