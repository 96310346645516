import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslate, useShowContext } from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { ChangePasswordDialog } from '../UserDialogs'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.white,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  }),
)

const ChangePasswordButton = () => {
  const { loading } = useShowContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const translate = useTranslate()
  const classes = useStyles()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <ChangePasswordDialog
        open={dialogOpen}
        close={() => setDialogOpen(false)}
      />
      <Button
        className={classes.button}
        variant="contained"
        label={translate('resources.users.actions.changePassword')}
        disabled={!hasAuthority(Authority.EDIT_USERS) || loading}
        onClick={() => setDialogOpen(true)}
      />
    </>
  )
}

export default ChangePasswordButton
