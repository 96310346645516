import { Tooltip } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import React from 'react'
import {
  ListProps,
  Pagination,
  TextField,
  TextInput,
  useListContext,
  Filter,
  FunctionField,
  useTranslate,
  Datagrid,
} from 'react-admin'
import { RoleDto } from '../../../core/dto/role/role.dto'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'

const RolesFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

export const RoleList = ({ ...props }: ListProps) => {
  const { loading } = useListContext()
  const translate = useTranslate()

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<RolesFilters />}
      bulkActionButtons={false}
    >
      <Datagrid
        size="small"
        loaded={!loading}
        rowClick={(id) => `${ResourceName.ROLES}/${id}/show`}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <FunctionField<RoleDto>
          sortable={false}
          source="isPredefined"
          render={(record?: RoleDto) =>
            record?.isPredefined ? (
              <div>{translate('const.yes')}</div>
            ) : (
              <div>{translate('const.no')}</div>
            )
          }
        />
        <FunctionField<RoleDto>
          sortable={false}
          source="description"
          render={(record?: RoleDto) =>
            record?.description ? (
              <Tooltip title={record?.description} enterTouchDelay={0}>
                <Info />
              </Tooltip>
            ) : undefined
          }
          onClick={(e) => e.stopPropagation()}
        />
      </Datagrid>
    </List>
  )
}
