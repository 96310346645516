import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { TS_CANS_URL } from '../../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { TSCanDto } from '../../dto/device/ts-cans/ts-can.dto'
import mapSortTSCanParam from './ts-can-sort.mapper'
import filterMapper from './ts-can-filter.mapper'
import { DataProviderExtensionResult } from '../../common/data-provider'
import { SetTSCanCredentialsDto } from '../../dto/device/ts-cans/set-tscan-credentials.dto'
import { RemoveTSCanCredentialsDto } from '../../dto/device/ts-cans/remove-tscan-credentials.dto'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<TSCanDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortTSCanParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<TSCanDto[]>(
      `${TS_CANS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<TSCanDto>> => {
    const data = await get<TSCanDto>(TS_CANS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<TSCanDto>> => {
    const data = await get<TSCanDto[]>(
      TS_CANS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateTSCanRequest>,
  ): Promise<CreateResult<TSCanDto>> => {
    const tscan = await post<CreateTSCanRequest[], TSCanDto[]>(TS_CANS_URL, [
      data,
    ])
    return {
      data: tscan[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<TSCanDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, TSCanDto>(
      TS_CANS_URL,
      {
        id,
        name: data.name !== previousData.name ? data.name : undefined,
        address:
          data.address !== previousData.address ? data.address : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  setTSCanCredentials: async (
    resource: string,
    params: SetTSCanCredentialsDto,
  ): Promise<DataProviderExtensionResult<SetTSCanCredentialsDto>> => {
    const setTSCanCredentials = await put<
      SetTSCanCredentialsDto,
      SetTSCanCredentialsDto
    >(`${TS_CANS_URL}/SetTSCanCredentials`, params)
    return {
      data: setTSCanCredentials,
    }
  },
  removeTSCanCredentials: async (
    resource: string,
    params: RemoveTSCanCredentialsDto,
  ): Promise<DataProviderExtensionResult<RemoveTSCanCredentialsDto>> => {
    const removeTSCanCredentials = await put<
      RemoveTSCanCredentialsDto,
      RemoveTSCanCredentialsDto
    >(`${TS_CANS_URL}/RemoveTSCanCredentials`, params)
    return {
      data: removeTSCanCredentials,
    }
  },
  restartTSCan: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const restartTSCan = await get<undefined>(
      TS_CANS_URL,
      `/RestartTSCan/${params?.id}`,
    )
    return {
      data: restartTSCan,
    }
  },
} as TSCansDataProvider

interface TSCansDataProvider extends DataProvider {
  setTSCanCredentials: (
    resource: string,
    params: SetTSCanCredentialsDto,
  ) => Promise<DataProviderExtensionResult<SetTSCanCredentialsDto>>

  removeTSCanCredentials: (
    resource: string,
    params: RemoveTSCanCredentialsDto,
  ) => Promise<DataProviderExtensionResult<RemoveTSCanCredentialsDto>>

  restartTSCan: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>
}

export interface CreateTSCanRequest {
  readonly name: string
  readonly address: string
}

export default provider
