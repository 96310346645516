import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  ArrayField,
  Datagrid,
  FunctionField,
  GET_ONE,
  TextField,
  useQuery,
  useTranslate,
  Title,
  SimpleShowLayout,
} from 'react-admin'
import React, { useMemo } from 'react'
import { ResourceName } from '../../../core/ResourceName'
import {
  PassagesCategory,
  PassageCounters,
} from '../../../core/stats/passage-counters-view/passage-counters-view'

const PassagesCountersGrid = () => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Datagrid resource={ResourceName.STATS_PASSAGE_COUNTERS_VIEW}>
      <TextField source="passage" />
      {!smallScreen && <TextField source="freeEntrancesCount" />}
      {!smallScreen && <TextField source="freeExitsCount" />}
      {smallScreen && (
        <FunctionField<PassageCounters>
          source="freeBothCount"
          render={(record?: PassageCounters) => (
            <div>
              {record?.freeEntrancesCount}/{record?.freeExitsCount}
            </div>
          )}
        />
      )}
      {!smallScreen && <TextField source="ticketEntrancesCount" />}
      {!smallScreen && <TextField source="ticketExitsCount" />}
      {smallScreen && (
        <FunctionField<PassageCounters>
          source="ticketBothCount"
          render={(record?: PassageCounters) => (
            <div>
              {record?.ticketEntrancesCount}/{record?.ticketExitsCount}
            </div>
          )}
        />
      )}
      {!smallScreen && <TextField source="unauthorizedEntrancesCount" />}
      {!smallScreen && <TextField source="unauthorizedExitsCount" />}
      {smallScreen && (
        <FunctionField<PassageCounters>
          source="unauthorizedBothCount"
          render={(record?: PassageCounters) => (
            <div>
              {record?.unauthorizedEntrancesCount}/
              {record?.unauthorizedExitsCount}
            </div>
          )}
        />
      )}
    </Datagrid>
  )
}

const PassageCategoriesGrid = () => {
  const PassageCategoriesGridMemo = useMemo(
    () => (
      <ArrayField label=" " source="childCategories" fullWidth>
        <PassageCategoriesGrid />
      </ArrayField>
    ),
    [],
  )

  const PassageCountersGridMemo = useMemo(
    () => (
      <ArrayField label=" " source="counters" fullWidth>
        <PassagesCountersGrid />
      </ArrayField>
    ),
    [],
  )

  const Expand = () => (
    <div style={{ padding: 0 }}>
      {PassageCategoriesGridMemo}
      {PassageCountersGridMemo}
    </div>
  )

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Datagrid
      resource={ResourceName.STATS_PASSAGE_COUNTERS_VIEW}
      expand={<Expand />}
      isRowExpandable={(record) =>
        !!record?.childCategories.length || !!record?.counters.length
      }
    >
      <TextField source="categoryName" />
      {!smallScreen && (
        <FunctionField<PassagesCategory>
          source="freeEntrancesCount"
          render={(record?: PassagesCategory) => (
            <div>{record?.countersSum.freeEntrancesCount}</div>
          )}
        />
      )}
      {!smallScreen && (
        <FunctionField<PassagesCategory>
          source="freeExitsCount"
          render={(record?: PassagesCategory) => (
            <div>{record?.countersSum.freeExitsCount}</div>
          )}
        />
      )}
      {smallScreen && (
        <FunctionField<PassagesCategory>
          source="freeBothCount"
          render={(record?: PassagesCategory) => (
            <div>
              {record?.countersSum.freeEntrancesCount}/
              {record?.countersSum.freeExitsCount}
            </div>
          )}
        />
      )}
      {!smallScreen && (
        <FunctionField<PassagesCategory>
          source="ticketEntrancesCount"
          render={(record?: PassagesCategory) => (
            <div>{record?.countersSum.ticketEntrancesCount}</div>
          )}
        />
      )}
      {!smallScreen && (
        <FunctionField<PassagesCategory>
          source="ticketExitsCount"
          render={(record?: PassagesCategory) => (
            <div>{record?.countersSum.ticketExitsCount}</div>
          )}
        />
      )}
      {smallScreen && (
        <FunctionField<PassagesCategory>
          source="ticketBothCount"
          render={(record?: PassagesCategory) => (
            <div>
              {record?.countersSum.ticketEntrancesCount}/
              {record?.countersSum.ticketExitsCount}
            </div>
          )}
        />
      )}
      {!smallScreen && (
        <FunctionField<PassagesCategory>
          source="unauthorizedEntrancesCount"
          render={(record?: PassagesCategory) => (
            <div>{record?.countersSum.unauthorizedEntrancesCount}</div>
          )}
        />
      )}
      {!smallScreen && (
        <FunctionField<PassagesCategory>
          source="unauthorizedExitsCount"
          render={(record?: PassagesCategory) => (
            <div>{record?.countersSum.unauthorizedExitsCount}</div>
          )}
        />
      )}
      {smallScreen && (
        <FunctionField<PassagesCategory>
          source="unauthorizedBothCount"
          render={(record?: PassagesCategory) => (
            <div>
              {record?.countersSum.unauthorizedEntrancesCount}/
              {record?.countersSum.unauthorizedExitsCount}
            </div>
          )}
        />
      )}
    </Datagrid>
  )
}

export const PassageCountersView = () => {
  const { data } = useQuery({
    resource: ResourceName.STATS_PASSAGE_COUNTERS_VIEW,
    type: GET_ONE,
    payload: {},
  })

  const translate = useTranslate()

  const PassageCountersGridMemo = useMemo(
    () => (
      <ArrayField
        record={data}
        label=" "
        source="uncategorizedCounters"
        fullWidth
      >
        <PassagesCountersGrid />
      </ArrayField>
    ),
    [data],
  )

  const PassageCategoriesGridMemo = useMemo(
    () => (
      <ArrayField
        record={data}
        label=" "
        source="categorizedCounters"
        fullWidth
      >
        <PassageCategoriesGrid />
      </ArrayField>
    ),
    [data],
  )

  return (
    <SimpleShowLayout>
      <>
        <Paper>
          <Title title="resources.stats-passage-counters-view.titles.list" />
          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">
                {translate(
                  'resources.stats-passage-counters-view.tabs.uncategorizedCounters',
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{PassageCountersGridMemo}</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography variant="h6">
                {translate(
                  'resources.stats-passage-counters-view.tabs.categorizedCounters',
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{PassageCategoriesGridMemo}</AccordionDetails>
          </Accordion>
        </Paper>
      </>
    </SimpleShowLayout>
  )
}
