const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  filterVerifierDeviceId: () => null,
  verifierDeviceId: (lambdaParameter, value, otherValues) =>
    otherValues.filterVerifierDeviceId === false
      ? null
      : `${lambdaParameter}.VerifierDevices.Any(vd=>vd.Id==${value})`,
  status: (lambdaParameter, value) =>
    `${lambdaParameter}.Status.Equals(TicketAPI.Models.Enums.TicketManagement.EventStatuses.${value})`,
  objectId: (lambdaParameter, value) =>
    `${lambdaParameter}.Objects.Any(ob=>ob.Id.Equals(${value}))`,
  companyId: (lambdaParameter, value) =>
    `${lambdaParameter}.Companies.Any(c=>c.Id.Equals(${value}))`,
  hasObjects: (lambdaParameter) => `${lambdaParameter}.Objects.Count>0`,
  hasDynamicBlocks: (lambdaParameter) =>
    `${lambdaParameter}.Objects.Any(o=>o.Tribunes.Any(t=>t.Blocks.Any(b=>b.Type.Equals(TicketAPI.Models.Enums.TicketManagement.BlockTypes.DYNAMIC))))`,
  nameWithDate: (lambdaParameter, value) => {
    const values = (value as string)
      .split(' ')
      .map((val) => val.trim())
      .filter((val) => val)
    return values
      .map(
        (val) =>
          `(${lambdaParameter}.Name.Contains("${val}") || "${val}".Contains(${lambdaParameter}.Name) || ${lambdaParameter}.DateStart.ToString().Contains("${val}") || "${val}".Contains(${lambdaParameter}.DateStart.ToString()))`,
      )
      .join(' && ')
  },
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
  nameWithDateSearch: (lambdaParameter, value) => {
    const values = (value as string)
      .split(' ')
      .map((val) => val.trim())
      .filter((val) => val)
    return values
      .map(
        (val) =>
          `((${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${val}") || "${val}".Contains(${lambdaParameter}.Id+${lambdaParameter}.Name) || ${lambdaParameter}.DateStart.ToString().Contains("${val}") || "${val}".Contains(${lambdaParameter}.DateStart.ToString()))`,
      )
      .join(' && ')
  },
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
