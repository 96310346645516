import { Box, Divider, useMediaQuery, useTheme } from '@material-ui/core'
import React, { ReactElement } from 'react'
import {
  ShowProps,
  Show,
  TitleProps,
  useTranslate,
  TabbedShowLayout,
  Tab,
  TextField,
  TextInput,
  EditButton,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { EntranceDto } from '../../../core/dto/entrance.dto'
import { ResourceName } from '../../../core/ResourceName'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

interface EntranceTitleProps extends TitleProps {
  record?: EntranceDto
}

const ShowTitle = (props: EntranceTitleProps) => {
  const translate = useTranslate()
  const { record: entrance } = props
  return (
    <span>
      {translate('resources.entrances.titles.show')}: {entrance?.id}.{' '}
      {entrance?.note}
    </span>
  )
}

const GeneralActions = () => {
  const { record: entrance } = useShowContext<EntranceDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="flex" justifyContent="flex-end">
        <EditButton
          variant="contained"
          basePath="/entrances"
          record={entrance}
          disabled={!hasAuthority(Authority.EDIT_ENTRANCES)}
        />
      </Box>
    </>
  )
}

const EntranceTabs = ({ ...props }) => {
  const { record: entrance } = props
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const devicesFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [
        <TextInput source="name" alwaysOn />,
        hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) ? (
          <FilteredReferenceInput
            label="resources.logs.filters.devieCategoryId"
            source="categoryIdWithDescendants"
            reference={ResourceName.DEVICE_CATEGORIES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'hierarchyString',
              showFilter: true,
            }}
            alwaysOn
          />
        ) : (
          <></>
        ),
      ],
      resource: ResourceName.ENTRANCES,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.entrances.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="note" />
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_ENGINES) && (
        <Tab path="engines" label="resources.entrances.tabs.engines">
          <>
            <RelationTab<EntranceDto>
              resource={ResourceName.ENGINES}
              source="verifierDevicesIds"
              mode={hasAuthority(Authority.EDIT_ENTRANCES) ? 'edit' : 'show'}
              attachMethod="attachDevices"
              detachMethod="detachDevices"
              attachRequestPayload={(r, ids) => ({
                entranceId: r.id,
                verifierDevicesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                entranceId: r.id,
                verifierDevicesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterEventId === true
              }
              filters={devicesFilters() as ReactElement[]}
              filterDefaultValues={{
                entranceId: entrance?.id,
                filterEntranceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_PALMS) && (
        <Tab path="palms" label="resources.entrances.tabs.palms">
          <>
            <RelationTab<EntranceDto>
              resource={ResourceName.PALMS}
              source="verifierDevicesIds"
              mode={hasAuthority(Authority.EDIT_ENTRANCES) ? 'edit' : 'show'}
              attachMethod="attachDevices"
              detachMethod="detachDevices"
              attachRequestPayload={(r, ids) => ({
                entranceId: r.id,
                verifierDevicesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                entranceId: r.id,
                verifierDevicesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterEventId === true
              }
              filters={devicesFilters() as ReactElement[]}
              filterDefaultValues={{
                entranceId: entrance?.id,
                filterEntranceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField source="id" label="ID" />
              <TextField source="name" key="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_DEPOSITORS) && (
        <Tab path="depositors" label="resources.entrances.tabs.depositors">
          <>
            <RelationTab<EntranceDto>
              resource={ResourceName.DEPOSITORS}
              source="verifierDevicesIds"
              mode={hasAuthority(Authority.EDIT_ENTRANCES) ? 'edit' : 'show'}
              attachMethod="attachDevices"
              detachMethod="detachDevices"
              attachRequestPayload={(r, ids) => ({
                entranceId: r.id,
                verifierDevicesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                entranceId: r.id,
                verifierDevicesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterEventId === true
              }
              filters={devicesFilters() as ReactElement[]}
              filterDefaultValues={{
                entranceId: entrance?.id,
                filterEntranceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField source="id" label="ID" />
              <TextField source="name" key="name" />
            </RelationTab>
          </>
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const EntranceShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <EntranceTabs />
  </Show>
)
