import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  ResourceContextProvider,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useShowController,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ObjectDto } from '../../../core/dto/object.dto'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import BlocksList from '../Blocks/BlocksList'
import SeatsList from '../Seats/SeatsList'
import TribunesList from '../Tribunes/TribunesList'

interface ObjectTitleProps extends TitleProps {
  record?: ObjectDto
}

const ObjectShowTitle = (data: ObjectTitleProps) => {
  const { record } = data
  const t = useTranslate()
  return (
    <span>
      {t('resources.objects.name')}: {record?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: object, basePath } = useShowContext<ObjectDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="flex" justifyContent="flex-end">
        <EditButton
          basePath={basePath}
          record={object}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_OBJECTS)}
        />
      </Box>
    </>
  )
}

const ObjectShow = (props: ShowProps) => {
  const t = useTranslate()
  const { record: object } = useShowController<ObjectDto>(props)
  const hasAuthority = useHasAuthority()

  return (
    <Show {...props} title={<ObjectShowTitle />} actions={false}>
      <TabbedShowLayout>
        <Tab label={t('resources.objects.tabs.general')}>
          <TextField source="id" label="ID" />
          <TextField source="name" />
          <GeneralActions />
        </Tab>
        {hasAuthority(Authority.VIEW_TRIBUNES) && (
          <Tab label={t('resources.objects.tabs.tribunes')}>
            <ResourceContextProvider value={ResourceName.TRIBUNES}>
              <TribunesList
                title=" "
                filter={{ objectId: object?.id }}
                objectId={object?.id}
              />
            </ResourceContextProvider>
          </Tab>
        )}
        {hasAuthority(Authority.VIEW_BLOCKS) && (
          <Tab label={t('resources.objects.tabs.blocks')}>
            <ResourceContextProvider value={ResourceName.BLOCKS}>
              <BlocksList
                title=" "
                filter={{ objectId: object?.id }}
                objectId={object?.id}
              />
            </ResourceContextProvider>
          </Tab>
        )}
        {hasAuthority(Authority.VIEW_SEATS) && (
          <Tab label={t('resources.objects.tabs.seats')}>
            <ResourceContextProvider value={ResourceName.SEATS}>
              <SeatsList
                title=" "
                filter={{ objectId: object?.id }}
                objectId={object?.id}
              />
            </ResourceContextProvider>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  )
}

export default ObjectShow
