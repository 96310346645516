import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import { Power, PowerOff } from '@material-ui/icons'
import React, { useReducer, useEffect } from 'react'
import {
  useListContext,
  useMutation,
  useNotify,
  useShowContext,
} from 'react-admin'
import {
  activationButtonInitialState,
  toggleActivationButtonReducer,
} from '../../../core/access-usings/access-using-activation-button.reducer'
import { ToggleType } from '../../../core/access-usings/access-using-activation-button'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'

export const AccessUsingActivationButton = ({ ...props }) => {
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const [{ type, text, mutateQuery }, dispatch] = useReducer(
    toggleActivationButtonReducer,
    activationButtonInitialState,
  )
  const { record: accessUsing, booking } = props
  const [mutate, { loading: mutationLoading }] = useMutation()
  const { refetch } = useListContext()

  useEffect(() => {
    dispatch({
      type: accessUsing.active ? ToggleType.ACTIVE : ToggleType.INACTIVE,
      data: accessUsing,
    })
  }, [accessUsing])

  const onClick = async () => {
    const query = mutateQuery?.(accessUsing, booking)
    await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    refetch?.()
  }

  return (
    <>
      {!xSmallScreen ? (
        <Button
          disabled={
            !hasAuthority(Authority.EDIT_ACCESS_USINGS) ||
            loading ||
            mutationLoading
          }
          variant="contained"
          onClick={onClick as () => void}
          label={text}
          useSmallVersionBreakpoint={false}
        />
      ) : (
        <IconButton
          color="primary"
          onClick={onClick as () => void}
          disabled={
            !hasAuthority(Authority.EDIT_ACCESS_USINGS) ||
            loading ||
            mutationLoading
          }
        >
          {type === ToggleType.ACTIVE ? <PowerOff /> : <Power />}
        </IconButton>
      )}
    </>
  )
}
