import { Box, createStyles, Divider, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { PassageDto } from '../../../core/dto/device/passages/passage.dto'
import { HardwareVersions } from '../../../core/enum/HardwareVersions'
import { ResourceName } from '../../../core/ResourceName'
import DropdownButton from '../../common/DropdownButton'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DeviceStatusesTab } from '../common/modules/ModuleStatus'
import { PassageForceReportStateButton } from './passage-show/PassageForceReportStateButton'
import { PassageResetCountersButton } from './passage-show/PassageResetCountersButton'
import { PassageSetFreePassBackwardButton } from './passage-show/PassageSetFreePassBackwardButton'
import { PassageSetFreePassForwardButton } from './passage-show/PassageSetFreePassForwardButton'
import { PassageSetLockButton } from './passage-show/PassageSetLockButton'
import { PassageSetPowerButton } from './passage-show/PassageSetPowerButton'
import { PassageSetPPOZButton } from './passage-show/PassageSetPPOZButton'
import { PassageResetModulesButton } from './passage-show/PassageResetModulesButton'
import { PassageSetReverseDirectionButton } from './passage-show/PassageSetReverseDirectionButton'
import { CameraDto } from '../../../core/dto/device/camera/cameras/camera.dto'
import { DeviceCategoryExtendedDto } from '../../../core/dto/device-category/device-category-extended.dto'
import { SPOM4FullAddressField } from './SPOM4Address'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'
import { PassageSetOpenButton } from './passage-show/PassageSetOpenButton'
import { PassageDirections } from '../../../core/enum/PassageDirections'

const useStyles = makeStyles(() =>
  createStyles({
    generalActions: {
      margin: '2px',
    },
  }),
)

interface PassageTitleProps extends TitleProps {
  record?: PassageDto
}

const ShowTitle = (data: PassageTitleProps) => {
  const { record: passage } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.passages.titles.show')}: {passage?.id}.{' '}
      {passage?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: passage, basePath } = useShowContext<PassageDto>()
  const hasAuthority = useHasAuthority()
  const classes = useStyles()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <EditButton
              className={classes.generalActions}
              basePath={basePath}
              record={passage}
              variant="contained"
              disabled={!hasAuthority(Authority.EDIT_PASSAGES)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const CountersActions = () => (
  <>
    <Divider />
    <Box p={1} display="block" textAlign="right">
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item style={{ margin: '2px' }}>
          <DropdownButton mainAction={<PassageResetCountersButton />}>
            <PassageResetCountersButton asRecurringJob />
          </DropdownButton>
        </Grid>
      </Grid>
    </Box>
  </>
)

const CountersTab = ({ ...props }) => (
  <SimpleShowLayout {...props}>
    <TextField source="freeEntrancesCount" />
    <TextField source="freeExitsCount" />
    <TextField source="ticketEntrancesCount" />
    <TextField source="ticketExitsCount" />
    <TextField source="unauthorizedEntrancesCount" />
    <TextField source="unauthorizedExitsCount" />
    <CountersActions />
  </SimpleShowLayout>
)

const SetStateActions = ({ ...props }) => {
  const { disabled, record: passage } = props

  return (
    <>
      <Box p={1} display="block" textAlign="right">
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton
              mainAction={
                <PassageSetFreePassBackwardButton disabled={disabled} />
              }
            >
              <PassageSetFreePassBackwardButton
                disabled={disabled}
                asRecurringJob
              />
            </DropdownButton>
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton
              mainAction={
                <PassageSetFreePassForwardButton disabled={disabled} />
              }
            >
              <PassageSetFreePassForwardButton
                disabled={disabled}
                asRecurringJob
              />
            </DropdownButton>
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton
              mainAction={<PassageSetPowerButton disabled={disabled} />}
            >
              <PassageSetPowerButton disabled={disabled} asRecurringJob />
            </DropdownButton>
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton
              mainAction={<PassageSetPPOZButton disabled={disabled} />}
            >
              <PassageSetPPOZButton disabled={disabled} asRecurringJob />
            </DropdownButton>
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton
              mainAction={
                <PassageSetOpenButton
                  disabled={disabled}
                  direction={PassageDirections.IN}
                />
              }
            >
              <PassageSetOpenButton
                disabled={disabled}
                direction={PassageDirections.IN}
                asRecurringJob
              />
            </DropdownButton>
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton
              mainAction={
                <PassageSetOpenButton
                  disabled={disabled}
                  direction={PassageDirections.OUT}
                />
              }
            >
              <PassageSetOpenButton
                disabled={disabled}
                direction={PassageDirections.OUT}
                asRecurringJob
              />
            </DropdownButton>
          </Grid>
          {passage?.hardwareVersion !== HardwareVersions.SPOM4 && (
            <Grid item style={{ margin: '2px' }}>
              <DropdownButton
                mainAction={<PassageSetLockButton disabled={disabled} />}
              >
                <PassageSetLockButton disabled={disabled} asRecurringJob />
              </DropdownButton>
            </Grid>
          )}
          {passage?.hardwareVersion !== HardwareVersions.SPOM4 && (
            <Grid item style={{ margin: '2px' }}>
              <DropdownButton
                mainAction={
                  <PassageSetReverseDirectionButton disabled={disabled} />
                }
              >
                <PassageSetReverseDirectionButton
                  disabled={disabled}
                  asRecurringJob
                />
              </DropdownButton>
            </Grid>
          )}
          {passage?.hardwareVersion !== HardwareVersions.SPOM4 && (
            <Grid item>
              <PassageForceReportStateButton />
            </Grid>
          )}
          {passage?.hardwareVersion !== HardwareVersions.SPOM4 && (
            <Grid item>
              <PassageResetModulesButton />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

const PassageTabs = ({ ...props }) => {
  const { record: passage } = props
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.passages.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <FunctionField<PassageDto>
          source="isWatched"
          render={(record?: PassageDto) => (
            <div>
              {record?.isWatched
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <DateField
          source="lastRefresh"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        {passage?.categoryId && hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link="show"
          >
            <FunctionField<DeviceCategoryExtendedDto>
              source="hierarchyString"
              render={(record?: DeviceCategoryExtendedDto) =>
                `${record?.id}. ${record?.hierarchyString}`
              }
            />
          </ReferenceField>
        )}
        {passage?.cameraId && hasAuthority(Authority.VIEW_CAMERAS) && (
          <ReferenceField
            source="cameraId"
            reference={ResourceName.CAMERAS}
            link="show"
          >
            <FunctionField<CameraDto>
              source="name"
              render={(camera?: CameraDto) =>
                `${camera?.id}. ${camera?.description}`
              }
            />
          </ReferenceField>
        )}
        {passage?.hardwareVersion === HardwareVersions.SPOM4 && (
          <SPOM4FullAddressField source="tsCommAddress" />
        )}
        <FunctionField<PassageDto>
          source="hardwareVersion"
          render={(record?: PassageDto) => (
            <div>
              {translate(
                `resources.enums.hardwareVersions.${record?.hardwareVersion}`,
              )}
            </div>
          )}
        />
        {passage?.tsCanId && hasAuthority(Authority.VIEW_TS_CANS) && (
          <ReferenceField
            source="tsCanId"
            reference={ResourceName.TS_CANS}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <GeneralActions />
      </Tab>
      <Tab label="resources.passages.tabs.counters" path="counters">
        <CountersTab />
      </Tab>
      <Tab label="resources.passages.tabs.statuses" path="statuses">
        {passage?.jsonStatus && (
          <DeviceStatusesTab
            jsonStatus={passage.jsonStatus}
            reversedDirection={passage?.reversedDirection}
            deviceType={DeviceTypes.PASSAGE}
          />
        )}
        <SetStateActions />
      </Tab>
    </TabbedShowLayout>
  )
}

export const PassageShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <PassageTabs />
  </Show>
)
