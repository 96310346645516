import { makeStyles } from '@material-ui/core'
import React from 'react'
import {
  Edit,
  EditProps,
  ReferenceField,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
  FunctionField,
  useEditContext,
  FormDataConsumer,
  ResourceContextProvider,
  Labeled,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { UserDto } from '../../core/dto/user.dto'
import { ResourceName } from '../../core/ResourceName'
import { useHasAuthority } from '../hooks/useHasAuthority'
import ChangePasswordButton from './current-user-show/CurrentUserChangePasswordButton'

const useStyles = makeStyles({
  editForm: {
    width: '100%',
  },
})

export const validateUserEdit = (values: UserDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.userName) {
    errors.userName = 'ra.validation.required'
  }
  return errors
}

const EditToolbar = (props: ToolbarProps) => {
  const { record } = useEditContext()
  return (
    <Toolbar {...props}>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <SaveButton
            {...rest}
            disabled={formData?.userName === record?.userName}
          />
        )}
      </FormDataConsumer>
      <ChangePasswordButton />
    </Toolbar>
  )
}

export const CurrentUserEdit = (props: EditProps) => {
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Edit {...props} title=" " actions={false} className={classes.editForm}>
      <SimpleForm
        redirect={false}
        validate={validateUserEdit}
        toolbar={<EditToolbar />}
        className={classes.editForm}
      >
        <TextField source="id" label="ID" />
        <TextInput
          source="userName"
          isRequired
          label="resources.users.fields.userName"
        />
        {hasAuthority(Authority.VIEW_COMPANIES) && (
          <ResourceContextProvider value={ResourceName.COMPANIES}>
            <Labeled label="resources.users.fields.companyId">
              <ReferenceField
                source="companyId"
                reference={ResourceName.COMPANIES}
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
            </Labeled>
          </ResourceContextProvider>
        )}
        <FunctionField<UserDto>
          source="requirePasswordUpdate"
          render={(record?: UserDto) => (
            <div>
              {record?.requirePasswordUpdate
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
          label="resources.users.fields.requirePasswordUpdate"
        />
      </SimpleForm>
    </Edit>
  )
}
