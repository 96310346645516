import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  FormDataConsumer,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { ServiceTypes } from '../../core/enum/ServiceTypes'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { ServiceLinkDto } from '../../core/dto/service-link.dto'
import { ResourceName } from '../../core/ResourceName'
import { CustomerDto } from '../../core/dto/customer.dto'
import { BlockType } from '../../core/enum/BlockType'

const validateServiceLinkEdit = (values: ServiceLinkDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.serviceId) {
    errors.serviceId = 'ra.validation.required'
  }
  if (!values.serviceType) {
    errors.serviceType = 'ra.validation.required'
  }
  if (!values.apiUrl) {
    errors.apiUrl = 'ra.validation.required'
  }
  if (!values.authorizationHash) {
    errors.authorizationHash = 'ra.validation.required'
  }
  if (!values.eventId) {
    errors.eventId = 'ra.validation.required'
  }
  if (!values.blockId) {
    errors.blockId = 'ra.validation.required'
  }
  if (!values.customerId) {
    errors.customerId = 'ra.validation.required'
  }
  if (!values.variantId) {
    errors.variantId = 'ra.validation.required'
  }
  return errors
}

const EditTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.service-link.titles.edit')}</span>
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const ServiceLinkEdit = (props: EditProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Edit {...props} title={<EditTitle />} actions={false}>
      <SimpleForm
        redirect="show"
        validate={validateServiceLinkEdit}
        toolbar={<EditToolbar />}
        initialValues={{
          serviceType: ServiceTypes.SERVICE,
        }}
      >
        <TextField source="id" label="ID" />
        <TextInput source="serviceId" isRequired />
        <SelectInput
          source="serviceType"
          choices={Object.entries(ServiceTypes).map(([value]) => ({
            id: value,
            name: `resources.enums.serviceType.${value}`,
          }))}
          isRequired
        />
        <TextInput source="apiUrl" isRequired />
        <TextInput source="authorizationHash" isRequired />
        {hasAuthority(Authority.VIEW_EVENTS) && (
          <FilteredReferenceInput
            source="eventId"
            defaultValue={null}
            reference={ResourceName.EVENTS}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
            filter={{
              hasObjects: true,
              hasDynamicBlocks: true,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_BLOCKS) && (
          <FormDataConsumer>
            {({ formData, ...inputProps }) => (
              <FilteredReferenceInput
                {...inputProps}
                source="blockId"
                defaultValue={null}
                reference={ResourceName.BLOCKS}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={smallScreen ? 5 : 15}
                isRequired
                filterSource="search"
                selectWithPaginationInputProps={{
                  optionText: 'name',
                  showFilter: true,
                }}
                filter={{
                  belongsToEvent: formData?.eventId,
                  type: BlockType.DYNAMIC,
                }}
              />
            )}
          </FormDataConsumer>
        )}
        {hasAuthority(Authority.VIEW_CUSTOMERS) && (
          <FilteredReferenceInput
            source="customerId"
            defaultValue={null}
            reference={ResourceName.CUSTOMERS}
            sort={{ field: 'lastname', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="info"
            selectWithPaginationInputProps={{
              optionText: (choice: CustomerDto) =>
                `${choice.firstname} ${choice.lastname}`,
              showFilter: true,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_VARIANTS) && (
          <FilteredReferenceInput
            source="variantId"
            defaultValue={null}
            reference={ResourceName.VARIANTS}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
      </SimpleForm>
    </Edit>
  )
}
