export enum ImportProperties {
  CUSTOMER_FIRSTNAME = 'CUSTOMER_FIRSTNAME',
  CUSTOMER_LASTNAME = 'CUSTOMER_LASTNAME',
  CUSTOMER_PESEL = 'CUSTOMER_PESEL',
  CUSTOMER_DOCUMENT_NUMBER = 'CUSTOMER_DOCUMENT_NUMBER',
  CUSTOMER_COMPANY = 'CUSTOMER_COMPANY',
  TICKET_CODE = 'TICKET_CODE',
  TICKET_TYPE = 'TICKET_TYPE',
  BOOKING_TYPE = 'BOOKING_TYPE',
  DISABLED = 'DISABLED',
  SEAT_ID = 'SEAT_ID',
  BLOCK_ID = 'BLOCK_ID',
  VARIANT_ID = 'VARIANT_ID',
  EVENT_ID = 'EVENT_ID',
}
