import { Dialog, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'
import {
  LinearProgress,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRefresh,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import Button, {
  ButtonProps,
} from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { CreateEntrancesSetDialog } from './CreateEntrancesSetDialog'

export const CreateEntrancesSetButton = (props: ButtonProps) => {
  const notify = useNotify()
  const [newDescriptionDialogOpen, setNewDescriptionDialogOpen] =
    useState(false)
  const [newSetDescription, setNewSetDescription] = useState<
    string | undefined
  >(undefined)
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  const handleCreateEntrancesSetDialog = () => {
    setNewDescriptionDialogOpen(true)
  }

  const handleCreateEntrancesSet = async () => {
    await mutate(
      {
        type: sanitizeFetchType('create'),
        resource: ResourceName.ENTRANCES_SET,
        payload: {
          data: {
            description: newSetDescription,
          },
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
    notify('resources.entrances-matrix.notifications.entranceSetCreated')
    setNewDescriptionDialogOpen(false)
    setNewSetDescription(undefined)
    refresh()
  }

  return (
    <>
      <Dialog id="in-progress-dialog" open={mutationLoading}>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <CreateEntrancesSetDialog
        open={newDescriptionDialogOpen}
        close={() => setNewDescriptionDialogOpen(false)}
        setNewSetDescription={setNewSetDescription}
        handleCreateEntrancesSet={handleCreateEntrancesSet}
      />
      <Button
        variant="contained"
        color="primary"
        label="resources.entrances-matrix.actions.createEntrancesSet"
        useSmallVersionBreakpoint={false}
        {...props}
        onClick={handleCreateEntrancesSetDialog}
        disabled={
          !hasAuthority(Authority.CREATE_ENTRANCES_SET) || mutationLoading
        }
      />
    </>
  )
}
