import React from 'react'
import {
  EditProps,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { DeviceCategoryDto } from '../../core/dto/device-category/device-category.dto'
import { EditNode } from '../../lib/@react-admin/ra-tree/esm/src'

const validateDeviceCategoryEdit = (values: DeviceCategoryDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const EditTitle = ({ ...props }) => {
  const translate = useTranslate()
  const { record } = props
  return (
    <span>
      {translate('resources.device-categories.titles.edit')}: {record?.id}.{' '}
      {record?.name}
    </span>
  )
}

const DeviceCategory = ({ ...props }) => (
  <SimpleForm
    {...props}
    validate={validateDeviceCategoryEdit}
    redirect="show"
    toolbar={<EditToolbar />}
  >
    <TextField source="id" label="ID" />
    <TextInput source="name" isRequired />
  </SimpleForm>
)

interface EditNodeProps extends EditProps {
  syncWithLocation?: boolean
}

export const DeviceCategoryEdit = ({
  //TreeWithDetails' props include syncWithLocation variable, which generates error when we pass it over to EditNode
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  syncWithLocation,
  ...props
}: EditNodeProps) => (
  <EditNode {...props} title={<EditTitle />} actions={false}>
    <DeviceCategory />
  </EditNode>
)
