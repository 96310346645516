import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import {
  CheckIfAnyModuleHasError,
  CheckLastRefreshError,
  DeviceStatusesPreview,
} from '../common/modules/ModuleStatus'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'

const DoorFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

const DoorGridExpand = ({ ...props }) => {
  const { record } = props
  return (
    <DeviceStatusesPreview
      jsonStatus={record.jsonStatus}
      deviceType={DeviceTypes.DOORS}
    />
  )
}

export const DoorList = ({ ...props }: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  const postRowStyle = (record: any) => {
    const doorError = CheckLastRefreshError(record.lastRefresh)
    let doorWarning = false
    if (!doorError) {
      // Check for modules error
      doorWarning = CheckIfAnyModuleHasError(
        record.jsonStatus,
        DeviceTypes.DOORS,
      )
    }
    if (doorError) {
      return {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
      }
    }
    if (doorWarning) {
      return {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
      }
    }
    return {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    }
  }

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<DoorFilters />}
      bulkActionButtons={false}
    >
      <Datagrid
        rowStyle={postRowStyle}
        size="small"
        rowClick={(id) => `${ResourceName.DOORS}/${id}/show`}
        isRowExpandable={(record) =>
          record?.jsonStatus !== undefined && record?.jsonStatus !== null
        }
        expand={<DoorGridExpand />}
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
        {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
          <ReferenceField
            source="categoryId"
            reference={ResourceName.DEVICE_CATEGORIES}
            link={false}
            sortBy="name"
          >
            <TextField source="hierarchyString" />
          </ReferenceField>
        )}
        {!smallScreen && (
          <ReferenceField
            source="disabledId"
            reference={ResourceName.VERIFIER_DEVICE_DISABLED}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
      </Datagrid>
    </List>
  )
}
