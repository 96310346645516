import { ButtonGroup } from '@material-ui/core'
import React, { ReactElement, ReactNode } from 'react'
import {
  Datagrid,
  GET_LIST,
  List,
  Pagination,
  Record,
  ResourceContextProvider,
  sanitizeFetchType,
  TextField,
  TopToolbar,
  useListContext,
  useMutation,
  useNotify,
  useQuery,
  useShowContext,
} from 'react-admin'
import { TicketAPISortPayload } from '../../core/common/ticket-api-get-list.params'
import { ResourceName } from '../../core/ResourceName'
import { CheckboxField } from './CheckboxField'
import Button from './customized-mui-components/Button'

const RelationTab = <RecordType extends Record = Record>({
  resource,
  source,
  mode,
  attachMethod,
  detachMethod,
  attachRequestPayload,
  detachRequestPayload,
  refetchListAfterChange = () => true,
  relationsRequestMethod,
  relationsRequestPayload,
  relationsMapper,
  filters,
  filterDefaultValues,
  sort,
  children,
}: {
  resource: ResourceName
  source: string
  mode: 'show' | 'edit'
  attachMethod: string
  detachMethod: string
  attachRequestPayload: (record: RecordType, idsToAttach: number[]) => any
  detachRequestPayload: (record: RecordType, idsToDetach: number[]) => any
  refetchListAfterChange?: (filterValues: any) => boolean
  relationsRequestMethod?: string
  relationsRequestPayload?: (record: RecordType) => any
  relationsMapper?: (records: Record[], source: string) => any
  filters?: ReactElement | ReactElement[]
  filterDefaultValues?: any
  sort?: TicketAPISortPayload
  children?: ReactNode[]
}) => {
  const [mutate] = useMutation()
  const notify = useNotify()
  const {
    record,
    basePath,
    refetch,
    resource: recordResource,
  } = useShowContext<RecordType>()

  const { data: relationsDto, refetch: refetchRelationsIds } = useQuery(
    {
      type: sanitizeFetchType(relationsRequestMethod as string),
      resource: recordResource as string,
      payload:
        record && relationsRequestPayload && relationsRequestPayload(record),
    },
    {
      enabled: !!relationsRequestMethod && !!relationsRequestPayload,
    },
  )

  const relations =
    relationsDto &&
    (relationsMapper ? relationsMapper(relationsDto, source) : relationsDto)

  const handleAttach = async (idsToAttach: number[]) => {
    await mutate(
      {
        type: sanitizeFetchType(attachMethod),
        resource: recordResource as string,
        payload:
          record &&
          attachRequestPayload &&
          attachRequestPayload(record, idsToAttach),
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
    if (refetch) {
      refetch()
    }
    refetchRelationsIds()
  }

  const handleDetach = async (idsToDetach: number[]) => {
    await mutate(
      {
        type: sanitizeFetchType(detachMethod),
        resource: recordResource as string,
        payload:
          record &&
          detachRequestPayload &&
          detachRequestPayload(record, idsToDetach),
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
    if (refetch) {
      refetch()
    }
    refetchRelationsIds()
  }

  const isAttached = (relatedRecordId: number) => {
    if (!!relationsRequestMethod && !!relationsRequestPayload && relations)
      return relations[source].includes(relatedRecordId)
    if (record && !relationsRequestMethod && !relationsRequestPayload)
      return record[source].includes(relatedRecordId)
    return false
  }

  const Actions = () => {
    const { filterValues, refetch: refetchList } = useListContext()
    const getFilteredRelatedRecordsIds = async () =>
      (
        await mutate(
          {
            type: sanitizeFetchType(GET_LIST),
            resource,
            payload: {
              filter: {
                ...filterValues,
              },
            },
          },
          {
            returnPromise: true,
            onFailure: (err) => notify(err?.message, 'error'),
          },
        )
      )?.data?.map((r: any) => r?.id)

    const handleAttachAll = async () => {
      await handleAttach(await getFilteredRelatedRecordsIds())
      refetchList()
    }

    const handleDetachAll = async () => {
      await handleDetach(await getFilteredRelatedRecordsIds())
      refetchList()
    }

    return (
      <TopToolbar>
        <ButtonGroup>
          <Button
            variant="contained"
            onClick={() => handleAttachAll()}
            label="common.related-records.actions.attach-all"
            useSmallVersionBreakpoint={false}
          />
          <Button
            variant="outlined"
            onClick={() => handleDetachAll()}
            label="common.related-records.actions.detach-all"
            useSmallVersionBreakpoint={false}
          />
        </ButtonGroup>
      </TopToolbar>
    )
  }

  const AttachedCheckbox = ({ ...props }) => {
    const { filterValues, refetch: refetchList } = useListContext()

    return (
      <CheckboxField
        {...props}
        disabled={mode !== 'edit'}
        sortable={false}
        checkBySource={(id: number) => (id ? isAttached(id) : false)}
        source="id"
        onClick={(id: number) =>
          isAttached(id as number)
            ? async () => {
                await handleDetach([id as number])
                if (refetchListAfterChange(filterValues)) {
                  refetchList()
                }
              }
            : async () => {
                await handleAttach([id as number])
                if (refetchListAfterChange(filterValues)) {
                  refetchList()
                }
              }
        }
      />
    )
  }

  return (
    <ResourceContextProvider value={resource}>
      <List
        basePath={basePath}
        exporter={false}
        title=" "
        filters={filters}
        filterDefaultValues={filterDefaultValues}
        sort={sort}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
        perPage={20}
        actions={mode === 'edit' ? <Actions /> : <></>}
      >
        <>
          <Datagrid size="small" style={{ tableLayout: 'fixed' }}>
            {children || <TextField source="id" />}
            <AttachedCheckbox label="common.related-records.fields.is-attached" />
          </Datagrid>
        </>
      </List>
    </ResourceContextProvider>
  )
}

export default RelationTab
