export const mapping: Record<string, string> = {
  id: 'au=>au.Id',
  date: 'au=>au.Date',
  direction: 'au=>au.Direction',
  active: 'au=>au.Active',
  ticketId: 'au=>au.TicketId',
  accessId: 'au=>au.AccessId',
  customerName: 'au => au.Ticket.Booking.Customer.Lastname',
  customerSurname: 'au=>au.Ticket.Booking.Customer.Firstname',
  customerPESEL: 'au=>au.Ticket.Booking.Customer.Pesel',
}

export const mapSortAccessUsingsParam = (key: string) => mapping[key]
