import { sanitizeFetchType } from 'react-admin'
import { ResourceName } from '../../ResourceName'
import {
  ToggleButtonAction,
  ToggleButtonState,
  ToggleType,
} from './terminal-lock-button'
import { TerminalDto } from '../../dto/device/terminals/terminal.dto'

type Mutation = {
  type: string
  resource: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  payload?: object
}

const lockMutation = (
  { id }: TerminalDto,
  disabledId?: number,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('lock'),
  resource: ResourceName.TERMINALS,
  payload: {
    terminalId: id,
    disabledId,
    recurringJobName,
    cronExpression,
  },
})

const unlockMutation = (
  { id }: TerminalDto,
  disabledId?: number,
  recurringJobName?: string,
  cronExpression?: string,
): Mutation => ({
  type: sanitizeFetchType('unlock'),
  resource: ResourceName.TERMINALS,
  payload: {
    terminalId: id,
    recurringJobName,
    cronExpression,
  },
})

type State = {
  mutateQuery?: (
    terminal: TerminalDto,
    disabledId?: number,
    recurringJobName?: string,
    cronExpression?: string,
  ) => Mutation
} & ToggleButtonState

export const terminalLockButtonInitialState = {
  type: ToggleType.UNLOCK,
  text: 'resources.terminals.actions.unlock',
}

export const terminalLockButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case ToggleType.LOCK: {
      return {
        type,
        text: 'resources.terminals.actions.lock',
        mutateQuery: lockMutation,
      }
    }
    case ToggleType.UNLOCK: {
      return {
        type,
        text: 'resources.terminals.actions.unlock',
        mutateQuery: unlockMutation,
      }
    }
    default:
      throw new Error()
  }
}
