import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { LOGS_URL } from '../api-urls'
import { get, getWithPagination, patch } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { getDeviceCategoryChildren } from '../devices/devices.utils'
import { LogSchemeDataDto } from '../dto/log-scheme-data.dto'
import { getSchemeChildren } from '../schemes/schemes.utils'
import filterMapper from './log-scheme-data-filter.mapper'
import { mapSortLogSchemeDataParam } from './log-scheme-data-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<LogSchemeDataDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    // Tree type filter for schemes
    if (extendedFilter?.schemeIdWithDescendants) {
      const schemesIds = await getSchemeChildren(
        extendedFilter.schemeIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, schemesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortLogSchemeDataParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<LogSchemeDataDto[]>(
      `${LOGS_URL}/GetLogSchemeDataByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<LogSchemeDataDto>> => {
    const data = await get<LogSchemeDataDto>(
      `${LOGS_URL}/GetLogSchemeData`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<LogSchemeDataDto>> => {
    const data = await get<LogSchemeDataDto[]>(
      LOGS_URL,
      `/GetLogSchemeDataByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<LogSchemeDataDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      LogSchemeDataDto
    >(`${LOGS_URL}/PatchLogSchemeData`, {
      id,
      text: data.text !== previousData.text ? data.text : undefined,
      color: data.color !== previousData.color ? data.color : undefined,
      hiddenInSimpleView:
        data.hiddenInSimpleView !== previousData.hiddenInSimpleView
          ? data.hiddenInSimpleView
          : undefined,
      // schemeId:
      //   data.schemeId !== previousData.schemeId ? data.schemeId : undefined,
    })
    return Promise.resolve({ data: update })
  },
} as DataProvider

export default provider
