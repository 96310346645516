import { Box, Divider, Grid, Tooltip, makeStyles } from '@material-ui/core'
import { DevicesFold } from '@mui/icons-material'
import React, { ReactElement } from 'react'
import classNames from 'classnames'
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ToggleType } from '../../../core/devices/terminals/terminal-lock-button'
import { ResourceName } from '../../../core/ResourceName'
import DropdownButton from '../../common/DropdownButton'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DeviceStatusesTab } from '../common/modules/ModuleStatus'
import { TerminalDto } from '../../../core/dto/device/terminals/terminal.dto'
import { TerminalForceReportStateButton } from './terminal-show/TerminalForceReportStateButton'
import { TerminalResetModulesButton } from './terminal-show/TerminalResetModulesButton'
import { TerminalLockButton } from './terminal-show/TerminalLockButton'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'
import { TerminalOpeningButton } from './terminal-show/TerminalOpeningButton'
import { TerminalSetWorkingModeButton } from './terminal-show/TerminalSetWorkingModeButton'

const useStyles = makeStyles((theme) => ({
  iconError: {
    color: theme.palette.error.main,
  },
  icon: {
    padding: 4,
  },
}))

interface TerminalTitleProps extends TitleProps {
  record?: TerminalDto
}

const ShowTitle = (data: TerminalTitleProps) => {
  const { record: terminal } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.terminals.titles.show')}: {terminal?.id}.{' '}
      {terminal?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: terminal } = useShowContext<TerminalDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item style={{ margin: '2px' }}>
            <TerminalOpeningButton />
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton mainAction={<TerminalSetWorkingModeButton />}>
              <TerminalSetWorkingModeButton asRecurringJob />
            </DropdownButton>
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton mainAction={<TerminalLockButton />}>
              <TerminalLockButton
                asRecurringJob
                recurringMethod={ToggleType.LOCK}
              />
              <TerminalLockButton
                asRecurringJob
                recurringMethod={ToggleType.UNLOCK}
              />
            </DropdownButton>
          </Grid>
          <Grid item>
            <EditButton
              basePath={`/${ResourceName.TERMINALS}`}
              record={terminal}
              variant="contained"
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_TERMINALS)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const SetStateActions = () => (
  <>
    <Box p={1} display="block" textAlign="right">
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item>
          <TerminalForceReportStateButton />
        </Grid>
        <Grid item>
          <TerminalResetModulesButton />
        </Grid>
      </Grid>
    </Box>
  </>
)

const TerminalTabs = ({ ...props }) => {
  const translate = useTranslate()
  const { record: terminal } = useShowContext<TerminalDto>()
  const hasAuthority = useHasAuthority()
  const classes = useStyles()

  const eventsFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.TERMINALS,
    })

  const entrancesFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="note" alwaysOn />],
      resource: ResourceName.TERMINALS,
    })

  const jsonStatus = terminal?.jsonStatus && JSON.parse(terminal?.jsonStatus)
  const terminalStatus =
    jsonStatus &&
    JSON.stringify(jsonStatus?.Modules?.map((module) => module?.Status))

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.terminals.tabs.general">
        <TextField label="ID" source="id" />
        <TextField source="name" />
        <FunctionField<TerminalDto>
          source="isDoorOpen"
          render={() => (
            <Tooltip
              title={
                jsonStatus?.IsDoorOpen
                  ? translate('resources.enums.isCoverOpened.open')
                  : translate('resources.enums.isCoverOpened.closed')
              }
            >
              <DevicesFold
                className={classNames(
                  jsonStatus?.IsDoorOpen && classes.iconError,
                  classes.icon,
                )}
              />
            </Tooltip>
          )}
        />
        <FunctionField<TerminalDto>
          source="isWatched"
          render={(record?: TerminalDto) => (
            <div>
              {record?.isWatched
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <DateField
          source="lastRefresh"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        <FunctionField<TerminalDto>
          source="direction"
          render={(record?: TerminalDto) => (
            <div>
              {translate(
                `resources.enums.deviceDirection.${record?.direction}`,
              )}
            </div>
          )}
        />
        {terminal?.categoryId &&
          hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
            <ReferenceField
              source="categoryId"
              reference={ResourceName.DEVICE_CATEGORIES}
              link="show"
            >
              <TextField source="hierarchyString" />
            </ReferenceField>
          )}
        {terminal?.cameraId && hasAuthority(Authority.VIEW_CAMERAS) && (
          <ReferenceField
            source="cameraId"
            reference={ResourceName.CAMERAS}
            link="show"
          >
            <TextField source="description" />
          </ReferenceField>
        )}
        <FunctionField<TerminalDto>
          source="stanbyMode"
          render={(record?: TerminalDto) => (
            <div>
              {translate(`resources.enums.standbyMode.${record?.stanbyMode}`)}
            </div>
          )}
        />
        {terminal?.disabledId && (
          <ReferenceField
            source="disabledId"
            label="resources.terminals.fields.disabled"
            reference={ResourceName.VERIFIER_DEVICE_DISABLED}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <FunctionField<TerminalDto>
          source="onlineInfixCeck"
          render={(record?: TerminalDto) => (
            <div>
              {record?.onlineInfixCeck
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <FunctionField<TerminalDto>
          source="multiplicity"
          render={(record?: TerminalDto) => (
            <div>
              {translate(
                `resources.enums.multiplicities.${record?.multiplicity}`,
              )}
            </div>
          )}
        />
        {terminal?.tsCanId && hasAuthority(Authority.VIEW_TS_CANS) && (
          <ReferenceField
            source="tsCanId"
            reference={ResourceName.TS_CANS}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {terminal?.supervisorId && hasAuthority(Authority.VIEW_PALMS) && (
          <ReferenceField
            source="supervisorId"
            reference={ResourceName.PALMS}
            link="show"
          >
            <FunctionField
              source="name"
              render={(palm) => `${palm?.id}. ${palm?.name}`}
            />
          </ReferenceField>
        )}
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_EVENTS) && (
        <Tab path="events" label={translate('resources.terminals.tabs.events')}>
          <>
            <RelationTab<TerminalDto>
              resource={ResourceName.EVENTS}
              source="eventsIds"
              mode={hasAuthority(Authority.EDIT_TERMINALS) ? 'edit' : 'show'}
              attachMethod="attachEvents"
              detachMethod="detachEvents"
              attachRequestPayload={(r, ids) => ({
                terminalId: r.id,
                eventsIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                terminalId: r.id,
                eventsIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVerifierDeviceId === true
              }
              filters={eventsFilters() as ReactElement[]}
              filterDefaultValues={{
                verifierDeviceId: terminal?.id,
                filterVerifierDeviceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ENTRANCES) && (
        <Tab
          path="entrances"
          label={translate('resources.terminals.tabs.entrances')}
        >
          <>
            <RelationTab<TerminalDto>
              resource={ResourceName.ENTRANCES}
              source="entrancesIds"
              mode={hasAuthority(Authority.EDIT_TERMINALS) ? 'edit' : 'show'}
              attachMethod="attachEntrances"
              detachMethod="detachEntrances"
              attachRequestPayload={(r, ids) => ({
                terminalId: r.id,
                entrancesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                terminalId: r.id,
                entrancesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVerifierDeviceId === true
              }
              filters={entrancesFilters() as ReactElement[]}
              filterDefaultValues={{
                verifierDeviceId: terminal?.id,
                filterVerifierDeviceId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="note" />
            </RelationTab>
          </>
        </Tab>
      )}
      {terminalStatus && (
        <Tab label="resources.terminals.tabs.statuses" path="statuses">
          <DeviceStatusesTab
            jsonStatus={terminal.jsonStatus}
            deviceType={DeviceTypes.TERMINALS}
          />
          <SetStateActions />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const TerminalShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <TerminalTabs />
  </Show>
)
