import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect } from 'react'
import {
  required,
  sanitizeFetchType,
  useQuery,
  useRedirect,
  useTranslate,
} from 'react-admin'
import { Form } from 'react-final-form'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import DraggableComponent from '../../common/DraggableComponent'
import { ParameterValueInput } from './common/ParameterValueInput'

const useStyles = makeStyles({
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: 0,
  },
  formButtons: {
    display: 'flex',
    '&>button:first-child': {
      margin: '2%',
      marginLeft: '0%',
      width: '98%',
    },
    '&>button:last-child': {
      margin: '2%',
      marginRight: '0%',
      width: '98%',
    },
  },
  form: {
    width: '100%',
  },
})

export const DynamicStatisticParametersDialog = ({ ...props }) => {
  const { open, close, id } = props
  const classes = useStyles()
  const translate = useTranslate()
  const redirect = useRedirect()

  const {
    data: parameters,
    loading,
    loaded,
  } = useQuery(
    {
      resource: ResourceName.STATS_DYNAMIC_STATISTICS,
      type: sanitizeFetchType('getParametersToFullfill'),
      payload: {
        id,
      },
    },
    {
      enabled: !!id && open,
    },
  )

  const onSubmit = (values) => {
    redirect(
      `/stats-dynamic-statistics/${id}/preview?${Object.entries(values)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`,
    )
  }

  useEffect(() => {
    if (parameters && parameters.length === 0 && open && !loading && loaded)
      redirect(`/stats-dynamic-statistics/${id}/preview`)
  }, [id, loaded, loading, open, parameters, redirect])

  return (
    <Dialog
      open={open}
      onClose={close}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(
            'resources.stats-dynamic-statistics.dialogs.parameters.title',
          )}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={close}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        {!loading && loaded ? (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                {parameters?.map((parameter) => (
                  <ParameterValueInput
                    key={parameter?.key}
                    source={parameter?.key}
                    type={parameter?.type}
                    label={parameter?.description}
                    fullWidth
                    validate={required()}
                  />
                ))}
                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    label={translate(
                      'resources.stats-dynamic-statistics.actions.execute',
                    )}
                    useSmallVersionBreakpoint={false}
                  />
                  <Button
                    variant="contained"
                    onClick={close}
                    label={translate('const.cancel')}
                    useSmallVersionBreakpoint={false}
                  />
                </div>
              </form>
            )}
          />
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
    </Dialog>
  )
}
