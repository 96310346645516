import { FormMappings, TransitionalFormMappings } from './csv-types'
import {
  STATIC_ENTRIES_KEY,
  STATIC_VALUE_ID,
  TRANSITIONAL_STATIC_ENTRIES_KEY,
} from './csv.consts'

const resolveTransitionalStaticEntriesLeftovers = (
  staticEntries: Record<string, string>,
  mappings: Record<string, string>,
): Record<string, string> =>
  Object.entries(staticEntries).reduce(
    (resolvedEntries, [entryKey, entryValue]) => {
      if (mappings[entryKey] === STATIC_VALUE_ID) {
        return {
          ...resolvedEntries,
          [entryKey]: entryValue,
        }
      }
      return resolvedEntries
    },
    {} as Record<string, string>,
  )

export const transitionalStaticFieldsResolver = (
  mappings: TransitionalFormMappings,
): FormMappings => {
  const {
    [TRANSITIONAL_STATIC_ENTRIES_KEY]: transitionalStaticEntries,
    ...restMappings
  } = mappings
  if (!transitionalStaticEntries) {
    return {
      ...restMappings,
    }
  }
  const resolvedStaticEntries = resolveTransitionalStaticEntriesLeftovers(
    transitionalStaticEntries,
    restMappings as Record<string, string>,
  )
  if (!Object.keys(resolvedStaticEntries).length) {
    return restMappings as FormMappings
  }
  const {
    [STATIC_ENTRIES_KEY]: staticEntries,
    ...restMappingsWithoutStaticEntries
  } = restMappings
  const restMappingsWithoutUnresolvedKeys = Object.entries(
    restMappingsWithoutStaticEntries as Record<string, string>,
  ).reduce((prev, [key, value]) => {
    if (resolvedStaticEntries[key]) {
      return prev
    }
    return {
      ...prev,
      [key]: value,
    }
  }, {} as Record<string, string>)
  return {
    [STATIC_ENTRIES_KEY]: {
      ...staticEntries,
      ...resolvedStaticEntries,
    },
    ...restMappingsWithoutUnresolvedKeys,
  }
}

const applyMappingsToRecord = (
  restMappings: Record<string, string>,
  csvRecord: Record<string, string | undefined | null>,
) =>
  Object.entries(restMappings).reduce(
    (mappedRecordCollector, [mappingKey, csvKey]) => {
      if (csvKey === '') {
        return {
          ...mappedRecordCollector,
          [mappingKey]: null,
        }
      }
      return {
        ...mappedRecordCollector,
        [mappingKey]: csvRecord[csvKey] || null,
      }
    },
    {} as Record<string, string | null>,
  )

export const applyMappingsToCsvData = (
  mappings: FormMappings,
  csvData: Array<Record<string, string | null | undefined>>,
) =>
  csvData.reduce((mappedDataCollector, csvRecord) => {
    const { [STATIC_ENTRIES_KEY]: staticEntries, ...restMappings } = mappings
    const mappedRecord = applyMappingsToRecord(
      restMappings as Record<string, string>,
      csvRecord,
    )
    return [
      ...mappedDataCollector,
      {
        ...mappedRecord,
        ...staticEntries,
      },
    ]
  }, [] as Array<Record<string, string | null>>)
