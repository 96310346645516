var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Modal, Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { usePreferences } from '../../usePreferences';
import Draggable from 'react-draggable';
import { usePreferencesEditor } from '../usePreferencesEditor';
import { PreferencesEditorDialogTitle } from './PreferencesEditorDialogTitle';
import { PreferencesEditorDialogContext } from './PreferencesEditorDialogContext';
import { PreferencesEditorRoot } from './PreferencesEditorRoot';
export var PreferencesEditorDialog = function (props) {
    var _a = usePreferencesEditor(), disable = _a.disable, editor = _a.editor, isEnabled = _a.isEnabled;
    var _b = useState(null), title = _b[0], setTitle = _b[1];
    var memoizedContext = useMemo(function () { return ({ setTitle: setTitle }); }, [setTitle]);
    var theme = useTheme();
    var classes = useStyles(props);
    var _c = usePreferences('preferencesDialog.position', {
        x: 
        // We want it positioned to the far right of the screen
        document.body.clientWidth -
            // So we remove its size (see the root css class)
            theme.breakpoints.values.sm / 2 -
            // And add a margin
            theme.spacing(1),
        y: theme.spacing(1),
    }), dialogPosition = _c[0], setDialogPosition = _c[1];
    useEffect(function () {
        if (dialogPosition.x > document.body.clientWidth) {
            setDialogPosition({
                x: 
                // We want it positioned to the far right of the screen
                document.body.clientWidth -
                    // So we remove its size (see the root css class)
                    theme.breakpoints.values.sm / 2 -
                    // And add a margin
                    theme.spacing(1),
                y: theme.spacing(1),
            });
        }
    }, [isEnabled, dialogPosition, setDialogPosition, theme]);
    var handleDraggableStop = function (e, data) {
        setDialogPosition({ x: data.x, y: data.y });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, __assign({ className: classes.root, onClose: disable, open: isEnabled, "aria-labelledby": "preferences-editor-dialog-title", disableBackdropClick: true, hideBackdrop: true, disableEnforceFocus: true, disableScrollLock: true, BackdropComponent: null }, props),
            React.createElement(Draggable, { handle: "#preferences-editor-dialog-title", cancel: '[class*="MuiDialogContent-root"]', defaultPosition: dialogPosition, onStop: handleDraggableStop },
                React.createElement(Paper, { className: classes.root, elevation: 3 },
                    React.createElement(PreferencesEditorDialogTitle, { title: title }),
                    React.createElement(PreferencesEditorDialogContext.Provider, { value: memoizedContext },
                        React.createElement("div", { className: classes.content }, editor || React.createElement(PreferencesEditorRoot, null))))))));
};
var useStyles = makeStyles(function (theme) { return ({
    root: {
        width: theme.breakpoints.values.sm / 2,
        transition: theme.transitions.create(['height', 'width']),
    },
    content: {
        overflowY: 'auto',
        maxHeight: '75vh',
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    instructions: {},
}); }, {
    name: 'RaPreferencesEditorDialog',
});
PreferencesEditorDialog.displayName = 'PreferencesEditorDialog';
