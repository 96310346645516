import { Paper, Theme, makeStyles } from '@material-ui/core'
import {
  AccountBalance,
  AccountBalanceWallet,
  Apartment,
  ArrowForward,
  Assessment,
  AssignmentLate,
  BarChart,
  Category,
  CenterFocusStrong,
  DirectionsWalk,
  Event,
  ExitToApp,
  FiberDvr,
  Group,
  List,
  Person,
  PhoneAndroid,
  Receipt,
  SettingsApplications,
  ShowChart,
  Sort,
  Storage,
  SupervisorAccount,
  VerifiedUser,
  VideoLabel,
  Videocam,
  ViewComfy,
  ViewStream,
  Ballot,
  Autorenew,
  WorkOutline,
  Work,
  PermDataSetting,
  LinearScale,
  Block,
  House,
  Archive,
  DynamicFeed,
  Money,
  FontDownload,
  Lock,
  ListAlt,
  MeetingRoom,
  CloudUpload,
  Apps,
  LocalParking,
} from '@material-ui/icons'
import classNames from 'classnames'
import React, { useState } from 'react'
import {
  DashboardMenuItem,
  RaThemeOverrides,
  ReduxState,
  ResourceDefinition,
  getResources,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'
import MenuItem from './MenuItem'
import { ResourceName } from '../../core/ResourceName'
import { SubMenu } from './AppSubmenu'
import MenuSubitem from './MenuSubitem'

interface AdminTheme extends Theme {
  sidebar?: {
    width?: number
    closedWidth?: number
  }
  overrides?: RaThemeOverrides
}

const useStyles = makeStyles((theme: AdminTheme) => ({
  root: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: '3px',
      marginLeft: '2px',
    },
    direction: 'rtl',
    userSelect: 'none',
    maxHeight: '93%',
    [theme.breakpoints.up('md')]: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: '10px',
      borderTopRightRadius: '10px',
      marginBottom: theme.spacing(1),
      marginTop: '8px',
      marginRight: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      height: '100%',
    },
  },
  child: {
    direction: 'ltr',
  },
  open: {
    width: theme.sidebar?.width,
  },
  closed: {
    width: theme.sidebar?.closedWidth,
  },
}))

export const AppMenu = () => {
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen)
  const classes = useStyles()
  const [menuState, setMenuState] = useState({
    menuStats: true,
    menuDevices: true,
    menuConfiguration: true,
    menuConfigurationScheme: true,
    menuAdministration: true,
    menuJobs: true,
    menuIntegrations: true,
  })

  const availableResources = new Set(
    useSelector<ReduxState, ResourceDefinition[]>(getResources).map(
      (r) => r.name as ResourceName,
    ),
  )
  const translate = useTranslate()

  const handleToggle = (
    menu:
      | 'menuStats'
      | 'menuDevices'
      | 'menuConfiguration'
      | 'menuConfigurationScheme'
      | 'menuAdministration'
      | 'menuJobs'
      | 'menuIntegrations',
  ) => setMenuState((state) => ({ ...state, [menu]: !state[menu] }))

  const anyStatsResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.STATS_OVERALL_VIEW_LIST) ||
    avRes.has(ResourceName.STATS_PERIOD_VIEW_LIST) ||
    avRes.has(ResourceName.STATS_PASSAGE_COUNTERS_VIEW) ||
    avRes.has(ResourceName.STATS_EVENT_ENTRANCES_VIEW_LIST) ||
    avRes.has(ResourceName.STATS_DYNAMIC_STATISTICS)

  const anyDeviceResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.PALMS) ||
    avRes.has(ResourceName.ENGINES) ||
    avRes.has(ResourceName.DETECTORS) ||
    avRes.has(ResourceName.DEVICE_CATEGORIES) ||
    avRes.has(ResourceName.PASSAGES) ||
    avRes.has(ResourceName.CAMERAS) ||
    avRes.has(ResourceName.CAMERA_SERVERS) ||
    avRes.has(ResourceName.OFFLINE_SERVERS) ||
    avRes.has(ResourceName.DEPOSITORS) ||
    avRes.has(ResourceName.VERIFIER_DEVICE_DISABLED) ||
    avRes.has(ResourceName.DOORS) ||
    avRes.has(ResourceName.TERMINALS) ||
    avRes.has(ResourceName.TS_CANS)

  const anyConfigurationResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.ACCESSES) ||
    avRes.has(ResourceName.VARIANTS) ||
    avRes.has(ResourceName.ENTRANCES) ||
    avRes.has(ResourceName.ENTRANCES_MATRIX) ||
    avRes.has(ResourceName.LOG_SCHEME_DATA) ||
    avRes.has(ResourceName.PALM_SCHEME_DATA) ||
    avRes.has(ResourceName.ACCESS_BONUS)

  const anyConfigurationSchemeResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.LOG_SCHEME_DATA) ||
    avRes.has(ResourceName.PALM_SCHEME_DATA) ||
    avRes.has(ResourceName.VERIFIER_SCHEME_DATA)

  const anyAdministrationResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.COMPANIES) ||
    avRes.has(ResourceName.ROLES) ||
    avRes.has(ResourceName.USERS) ||
    avRes.has(ResourceName.DYNAMIC_PROPERTY_DEFINITIONS) ||
    avRes.has(ResourceName.DYNAMIC_COUNTERS)

  const anyJobResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.JOBS) || avRes.has(ResourceName.RECURRING_JOBS)

  const anyIntegrationResourcesAvailable = (avRes: Set<ResourceName>) =>
    avRes.has(ResourceName.SYNC_LOGS) || avRes.has(ResourceName.SERVICE_LINK)

  return (
    <Paper
      className={classNames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
      elevation={2}
    >
      <div style={{ overflowX: 'hidden' }} className={classes.child}>
        <DashboardMenuItem />
        <MenuItem resource={ResourceName.TICKETS} icon={<Receipt />} />
        <MenuSubitem
          resource={ResourceName.TICKET_IMPORTS}
          icon={<CloudUpload />}
        />
        <MenuSubitem
          resource={ResourceName.TICKET_IMPORT_TEMPLATES}
          icon={<Apps />}
        />
        <MenuItem
          resource={ResourceName.SERVICE_BOOKINGS}
          icon={<AssignmentLate />}
        />
        <MenuItem resource={ResourceName.CUSTOMERS} icon={<Person />} />
        <MenuItem resource={ResourceName.LOGS} icon={<List />} />
        <MenuItem resource={ResourceName.EVENTS} icon={<Event />} />
        {anyStatsResourcesAvailable(availableResources) && (
          <SubMenu
            handleToggle={() => handleToggle('menuStats')}
            isOpen={menuState.menuStats}
            name={translate('side-menu.stats.name')}
            icon={<BarChart />}
          >
            <MenuItem
              resource={ResourceName.STATS_OVERALL_VIEW_LIST}
              icon={<Sort style={{ transform: 'scaleX(-1)' }} />}
            />
            <MenuItem
              resource={ResourceName.STATS_PERIOD_VIEW_LIST}
              icon={<ShowChart />}
            />
            <MenuItem
              resource={ResourceName.STATS_PASSAGE_COUNTERS_VIEW}
              icon={<ViewStream />}
            />
            <MenuItem
              resource={ResourceName.STATS_EVENT_ENTRANCES_VIEW_LIST}
              icon={<Ballot />}
            />
            <MenuItem
              resource={ResourceName.STATS_DYNAMIC_STATISTICS}
              icon={<PermDataSetting />}
            />
          </SubMenu>
        )}
        {anyDeviceResourcesAvailable(availableResources) && (
          <SubMenu
            handleToggle={() => handleToggle('menuDevices')}
            isOpen={menuState.menuDevices}
            name={translate('side-menu.devices.name')}
            icon={<VideoLabel />}
          >
            <MenuItem resource={ResourceName.PALMS} icon={<PhoneAndroid />} />
            <MenuItem
              resource={ResourceName.ENGINES}
              icon={<AccountBalanceWallet />}
            />
            <MenuItem
              resource={ResourceName.DETECTORS}
              icon={<CenterFocusStrong />}
            />
            <MenuItem
              resource={ResourceName.PASSAGES}
              icon={<ArrowForward />}
            />
            <MenuItem resource={ResourceName.DOORS} icon={<MeetingRoom />} />
            <MenuItem
              resource={ResourceName.TERMINALS}
              icon={<LocalParking />}
            />
            <MenuItem resource={ResourceName.DEPOSITORS} icon={<Archive />} />
            <MenuItem resource={ResourceName.CAMERAS} icon={<Videocam />} />
            <MenuItem
              resource={ResourceName.CAMERA_SERVERS}
              icon={<FiberDvr />}
            />
            <MenuItem
              resource={ResourceName.OFFLINE_SERVERS}
              icon={<Storage />}
            />
            <MenuItem
              resource={ResourceName.DEVICE_CATEGORIES}
              icon={<Category />}
            />
            <MenuItem resource={ResourceName.TS_CANS} icon={<ListAlt />} />
            <MenuItem
              resource={ResourceName.VERIFIER_DEVICE_DISABLED}
              icon={<Lock />}
            />
          </SubMenu>
        )}
        {anyConfigurationResourcesAvailable(availableResources) && (
          <SubMenu
            handleToggle={() => handleToggle('menuConfiguration')}
            isOpen={menuState.menuConfiguration}
            name={translate('side-menu.configuration.name')}
            icon={<SettingsApplications />}
          >
            <MenuItem resource={ResourceName.ACCESSES} icon={<ExitToApp />} />
            <MenuSubitem
              resource={ResourceName.ACCESS_BONUS}
              icon={<FontDownload />}
            />
            <MenuItem resource={ResourceName.VARIANTS} icon={<Assessment />} />
            <MenuItem
              resource={ResourceName.ENTRANCES}
              icon={<DirectionsWalk />}
            />
            <MenuItem
              resource={ResourceName.ENTRANCES_MATRIX}
              icon={<ViewComfy />}
            />
            {anyConfigurationSchemeResourcesAvailable(availableResources) && (
              <SubMenu
                handleToggle={() => handleToggle('menuConfigurationScheme')}
                isOpen={menuState.menuConfigurationScheme}
                name={translate('side-menu.configuration.submenu.scheme')}
                icon={<SettingsApplications />}
              >
                <MenuItem
                  resource={ResourceName.LOG_SCHEME_DATA}
                  icon={<List />}
                />
                <MenuItem
                  resource={ResourceName.PALM_SCHEME_DATA}
                  icon={<PhoneAndroid />}
                />
                <MenuItem
                  resource={ResourceName.VERIFIER_SCHEME_DATA}
                  icon={<AccountBalanceWallet />}
                />
              </SubMenu>
            )}
          </SubMenu>
        )}
        {anyAdministrationResourcesAvailable(availableResources) && (
          <SubMenu
            handleToggle={() => handleToggle('menuAdministration')}
            isOpen={menuState.menuAdministration}
            name={translate('side-menu.administration.name')}
            icon={<SupervisorAccount />}
          >
            <MenuItem resource={ResourceName.COMPANIES} icon={<Apartment />} />
            <MenuItem resource={ResourceName.ROLES} icon={<VerifiedUser />} />
            <MenuItem resource={ResourceName.USERS} icon={<Group />} />
            <MenuItem
              resource={ResourceName.DYNAMIC_PROPERTY_DEFINITIONS}
              icon={<DynamicFeed />}
              label="resources.dynamic-property-definitions.titles.menu"
            />
            <MenuItem
              resource={ResourceName.DYNAMIC_COUNTERS}
              icon={<Money />}
            />
          </SubMenu>
        )}
        {anyJobResourcesAvailable(availableResources) && (
          <SubMenu
            handleToggle={() => handleToggle('menuJobs')}
            isOpen={menuState.menuJobs}
            name={translate('side-menu.jobs.name')}
            icon={<Work />}
          >
            <MenuItem
              resource={ResourceName.RECURRING_JOBS}
              icon={<Autorenew />}
            />
            <MenuItem resource={ResourceName.JOBS} icon={<WorkOutline />} />
          </SubMenu>
        )}
        {anyIntegrationResourcesAvailable(availableResources) && (
          <SubMenu
            handleToggle={() => handleToggle('menuIntegrations')}
            isOpen={menuState.menuIntegrations}
            name={translate('side-menu.integrations.name')}
            icon={<LinearScale />}
          >
            <MenuItem resource={ResourceName.SYNC_LOGS} icon={<Block />} />
            <MenuItem resource={ResourceName.SERVICE_LINK} icon={<House />} />
          </SubMenu>
        )}
        <MenuItem resource={ResourceName.OBJECTS} icon={<AccountBalance />} />
      </div>
    </Paper>
  )
}
