import { DataProvider } from 'react-admin'
import { SeatDto } from '../../dto/seat/seat.dto'

const fakeSeats: SeatDto[] = [
  {
    id: 1,
    rowAlias: 'Rząd 1',
    numberAlias: '1',
    blockId: 0,
    bookingsIds: [12, 13],
  },
  {
    id: 2,
    rowAlias: 'Rząd 1',
    numberAlias: '2',
    blockId: 0,
    bookingsIds: [12, 13],
  },
  {
    id: 3,
    rowAlias: 'Rząd 1',
    numberAlias: '3',
    blockId: 0,
    bookingsIds: [12, 13],
  },
  {
    id: 4,
    rowAlias: 'Rząd 1',
    numberAlias: '4',
    blockId: 0,
    bookingsIds: [12, 13],
  },
  {
    id: 5,
    rowAlias: 'Rząd 1',
    numberAlias: '5',
    blockId: 0,
    bookingsIds: [12, 13],
  },
  {
    id: 6,
    rowAlias: 'Rząd 1',
    numberAlias: '6',
    blockId: 0,
    bookingsIds: [12, 13],
  },
  {
    id: 7,
    rowAlias: 'Rząd 1',
    numberAlias: '7',
    blockId: 0,
    bookingsIds: [12, 13],
  },
  {
    id: 8,
    rowAlias: 'Rząd 1',
    numberAlias: '8',
    blockId: 0,
    bookingsIds: [12, 13],
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeSeats, total: fakeSeats.length }),
} as DataProvider

export default provider
