import {
  Checkbox,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import classnames from 'classnames'
import { groupBy } from 'lodash'
import React, {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  Datagrid,
  DatagridHeaderCell,
  DatagridHeaderProps,
  DatagridProps,
  Filter,
  FilterButton,
  FilterContext,
  FunctionField,
  GET_LIST,
  ListActionsProps,
  ListProps,
  Pagination,
  RecordContextProvider,
  sanitizeFetchType,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useMutation,
  useNotify,
  useQueryWithStore,
  useRefresh,
  useResourceContext,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { VerifierDeviceBaseDto } from '../../../core/dto/device/common/verifier-device-base/verifier-device-base.dto'
import { EntranceDto } from '../../../core/dto/entrance.dto'
import { ResourceName } from '../../../core/ResourceName'
import { CheckboxField } from '../../common/CheckboxField'
import Button from '../../common/customized-mui-components/Button'
import List from '../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { ClearAllEntranceDeviceRelationsButton } from './entrances-matrix-list/ClearAllEntranceDeviceRelationsButton'
import { CreateEntrancesSetButton } from './entrances-matrix-list/CreateEntrancesSetButton'
import { RestoreEntrancesSetButton } from './entrances-matrix-list/RestoreEntrancesSetButton'
import DropdownButton from '../../common/DropdownButton'

const useStyles = makeStyles((theme) => ({
  actions: {
    position: 'sticky',
    right: theme.spacing(1),
  },
  grid: {
    // Sticky first header
    '& thead > tr:first-child > th:first-child': {
      position: 'sticky',
      left: theme.spacing(0),
      top: theme.spacing(0),
      zIndex: 3,
    },
    // Sticky first column
    '& tbody > tr > td:first-child': {
      position: 'sticky',
      left: theme.spacing(0),
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
    },
    '& thead > tr:nth-child(2)': {
      '& th > span': {
        zIndex: 1,
      },
      '& th:first-child': {
        position: 'sticky',
        left: theme.spacing(0),
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  checkboxChanged: {
    '& svg': {
      color: theme.palette.warning.main,
    },
  },
  stateActions: {
    color: theme.palette.warning.main,
  },
}))

const entranceKeyPrefix = 'entrance-'

interface EntranceVerifierPairChange {
  readonly entranceId: number
  readonly verifierDeviceId: number
  readonly action: 'attach' | 'detach'
}

interface EntrancesMatrixListActionsProps extends ListActionsProps {
  readonly getEntranceVerifierPairsChanges?: () => EntranceVerifierPairChange[]
  readonly clearChanges?: () => void
  readonly onSave?: () => void
}

interface EntrancesMatrixGridProps extends DatagridProps {
  readonly changes: EntranceVerifierPairChange[]
  readonly setChanges: (changes: EntranceVerifierPairChange[]) => void
}

const EntrancesMatrixFilters = ({ ...props }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          label="resources.entrances-matrix.fields.categoryId"
          source="categoryIdWithDescendants"
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_ENTRANCES) && (
        <FilteredReferenceInput
          label="resources.entrances-matrix.fields.entranceId"
          source="entranceId"
          reference={ResourceName.ENTRANCES}
          sort={{ field: 'note', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="idWithNote"
          selectWithPaginationInputProps={{
            optionText: 'note',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

const Actions = ({ ...props }: EntrancesMatrixListActionsProps) => {
  const [mutate] = useMutation()
  const {
    getEntranceVerifierPairsChanges,
    clearChanges,
    onSave,
    filters: filtersProp,
    ...restProps
  } = props
  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const resource = ResourceName.LOGS
  const filters = useContext(FilterContext) || filtersProp
  const classes = useStyles()
  const notify = useNotify()

  const handleAttach = async (entranceId: number, idsToAttach: number[]) => {
    await mutate(
      {
        type: sanitizeFetchType('attachDevices'),
        resource: ResourceName.ENTRANCES,
        payload: {
          entranceId,
          verifierDevicesIds: idsToAttach,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
  }

  const handleDetach = async (entranceId: number, idsToDetach: number[]) => {
    await mutate(
      {
        type: sanitizeFetchType('detachDevices'),
        resource: ResourceName.ENTRANCES,
        payload: {
          entranceId,
          verifierDevicesIds: idsToDetach,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
  }

  const handleSaveEntrancesMatrixState = async () => {
    const changes =
      getEntranceVerifierPairsChanges && getEntranceVerifierPairsChanges()
    if (changes) {
      const attachChanges = groupBy(
        changes.filter((ch) => ch.action === 'attach'),
        (ch) => ch.entranceId,
      )
      const detachChanges = groupBy(
        changes.filter((ch) => ch.action === 'detach'),
        (ch) => ch.entranceId,
      )
      /* eslint-disable */
      // Attach
      for (const [key, value] of Object.entries(attachChanges)) {
        await handleAttach(
          Number(key),
          value.flatMap((v) => v.verifierDeviceId),
        )
      }
      // Detach
      for (const [key, value] of Object.entries(detachChanges)) {
        await handleDetach(
          Number(key),
          value.flatMap((v) => v.verifierDeviceId),
        )
      }
      /* eslint-disable */
      notify('ra.notification.updated', 'info')
      if (onSave) onSave()
      if (clearChanges) clearChanges()
    }
  }

  return (
    <TopToolbar
      {...sanitizeListRestProps(restProps)}
      className={classes.actions}
    >
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      <CreateEntrancesSetButton variant="text" size="small" />
      <DropdownButton
        mainAction={<RestoreEntrancesSetButton size="small" color="primary" />}
        variant="text"
      >
        <RestoreEntrancesSetButton asRecurringJob />
      </DropdownButton>
      <ClearAllEntranceDeviceRelationsButton variant="text" size="small" />
      <Button
        className={classes.stateActions}
        label="ra.action.save"
        variant="text"
        size="small"
        onClick={handleSaveEntrancesMatrixState}
        disabled={
          getEntranceVerifierPairsChanges &&
          getEntranceVerifierPairsChanges().length === 0
        }
      >
        <SaveIcon />
      </Button>
      <Button
        className={classes.stateActions}
        label="ra.action.cancel"
        variant="text"
        size="small"
        onClick={clearChanges}
        disabled={
          getEntranceVerifierPairsChanges &&
          getEntranceVerifierPairsChanges().length === 0
        }
      >
        <CancelIcon />
      </Button>
    </TopToolbar>
  )
}

interface EntrancesMatrixGridHeaderProps extends DatagridHeaderProps {
  attachAllDevicesToEntrance: (entranceId: number) => void
  detachAllDevicesFromEntrance: (entranceId: number) => void
  isAllDevicesAssigned: (entranceId: number) => boolean
}

// Customized DatagridHeader
const EntrancesMatrixGridHeader = (props: EntrancesMatrixGridHeaderProps) => {
  const {
    children,
    classes,
    className,
    hasExpand = false,
    hasBulkActions = false,
    isRowSelectable,
    attachAllDevicesToEntrance,
    detachAllDevicesFromEntrance,
    isAllDevicesAssigned,
  } = props
  const hasAuthority = useHasAuthority()
  const resource = useResourceContext(props)
  const { currentSort, data, ids, onSelect, selectedIds, setSort } =
    useListContext(props)

  const updateSortCallback = useCallback(
    (event) => {
      event.stopPropagation()
      const newField = event.currentTarget.dataset.field
      const newOrder =
        currentSort.field === newField
          ? currentSort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event.currentTarget.dataset.order

      setSort(newField, newOrder)
    },
    [currentSort.field, currentSort.order, setSort],
  )

  const updateSort = updateSortCallback

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        const all = ids.concat(selectedIds.filter((id) => !ids.includes(id)))
        onSelect(
          isRowSelectable ? all.filter((id) => isRowSelectable(data[id])) : all,
        )
      } else {
        onSelect([])
      }
    },
    [data, ids, onSelect, isRowSelectable, selectedIds],
  )

  const selectableIds = isRowSelectable
    ? ids.filter((id) => isRowSelectable(data[id]))
    : ids

  return (
    <TableHead className={classnames(className, classes?.thead)}>
      <TableRow className={classnames(classes?.row, classes?.headerRow)}>
        {hasExpand && (
          <TableCell
            padding="none"
            className={classnames(classes?.headerCell, classes?.expandHeader)}
          />
        )}
        {hasBulkActions && selectedIds && (
          <TableCell padding="checkbox" className={classes?.headerCell}>
            <Checkbox
              disabled={!hasAuthority(Authority.EDIT_ENTRANCES_SET)}
              className="select-all"
              color="primary"
              checked={
                selectedIds.length > 0 &&
                selectableIds.length > 0 &&
                selectableIds.every((id) => selectedIds.includes(id))
              }
              onChange={handleSelectAll}
            />
          </TableCell>
        )}
        {Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridHeaderCell
              className={classes?.headerCell}
              currentSort={currentSort}
              field={field}
              isSorting={
                currentSort.field ===
                ((field.props as any).sortBy || (field.props as any).source)
              }
              key={(field.props as any).source || index}
              resource={resource}
              updateSort={updateSort}
            />
          ) : null,
        )}
      </TableRow>
      {/* Additional row for all devices to one entrance assignment */}
      <TableRow className={classnames(classes?.row, classes?.headerRow)}>
        {Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <TableCell
              className={classnames(className, field.props.headerClassName)}
              align={field.props.textAlign}
              variant="head"
            >
              {index > 0 ? (
                <Checkbox
                  disabled={!hasAuthority(Authority.EDIT_ENTRANCES_SET)}
                  checkedIcon={<LibraryAddCheckIcon color="primary" />}
                  className={classes?.headerCell}
                  key={field.key || index}
                  color="primary"
                  checked={isAllDevicesAssigned(
                    Number(
                      field.key?.toString().replace(entranceKeyPrefix, ''),
                    ),
                  )}
                  onClick={() => {
                    const entranceId = Number(
                      field.key?.toString().replace(entranceKeyPrefix, ''),
                    )
                    if (isAllDevicesAssigned(entranceId))
                      detachAllDevicesFromEntrance(entranceId)
                    else attachAllDevicesToEntrance(entranceId)
                  }}
                />
              ) : (
                <></>
              )}
            </TableCell>
          ) : null,
        )}
      </TableRow>
    </TableHead>
  )
}

const EntrancesMatrixGrid = ({ ...props }: EntrancesMatrixGridProps) => {
  const classes = useStyles()
  const hasAuthority = useHasAuthority()
  const {
    changes: entranceVerifierPairsChanges,
    setChanges: setEntranceVerifierPairsChanges,
    ...restProps
  } = props
  const { data: verifierDevices, filterValues } =
    useListContext<VerifierDeviceBaseDto>(restProps)
  const { data: entrances } = useQueryWithStore({
    type: sanitizeFetchType(GET_LIST),
    resource: ResourceName.ENTRANCES,
    payload: {
      filter: {},
    },
  })
  const { data: allVerifierDevices } = useQueryWithStore({
    type: sanitizeFetchType(GET_LIST),
    resource: ResourceName.VERIFIER_DEVICES,
    payload: {
      filter: filterValues,
    },
  })

  const addToAttach = (entranceId: number, idsToAttach: number[]) => {
    let updatedEntranceVerifierPairsChanges
    const alreadyAttached = allVerifierDevices.filter(
      (vd) =>
        idsToAttach.some((id) => (vd as VerifierDeviceBaseDto).id === id) &&
        (vd as VerifierDeviceBaseDto).entrancesIds.some(
          (eId) => entranceId === eId,
        ),
    ) as VerifierDeviceBaseDto[]
    const previouslyDetached = entranceVerifierPairsChanges.filter(
      (evp) =>
        evp.entranceId === entranceId &&
        idsToAttach.some((i) => evp.verifierDeviceId === i) &&
        evp.action === 'detach',
    )
    if (previouslyDetached.length > 0 || alreadyAttached.length > 0) {
      // remove detach elements first
      updatedEntranceVerifierPairsChanges = entranceVerifierPairsChanges.filter(
        (evp) => !previouslyDetached.some((pd) => evp === pd),
      )
      // add rest attach
      updatedEntranceVerifierPairsChanges = [
        ...updatedEntranceVerifierPairsChanges,
        ...idsToAttach
          .map(
            (i) =>
              ({
                entranceId,
                verifierDeviceId: i,
                action: 'attach',
              } as EntranceVerifierPairChange),
          )
          .filter(
            (att) =>
              !previouslyDetached.some(
                (pd) =>
                  att.entranceId === pd.entranceId &&
                  att.verifierDeviceId === pd.verifierDeviceId,
              ) &&
              !alreadyAttached.some((aa) => att.verifierDeviceId === aa.id),
          ),
      ]
    } else {
      updatedEntranceVerifierPairsChanges = [
        ...entranceVerifierPairsChanges,
        ...idsToAttach.map(
          (i) =>
            ({
              entranceId,
              verifierDeviceId: i,
              action: 'attach',
            } as EntranceVerifierPairChange),
        ),
      ]
    }
    setEntranceVerifierPairsChanges(updatedEntranceVerifierPairsChanges)
  }
  const addToDetach = (entranceId: number, idsToDetach: number[]) => {
    let updatedEntranceVerifierPairsChanges
    const alreadyDetached = allVerifierDevices.filter(
      (vd) =>
        idsToDetach.some((id) => (vd as VerifierDeviceBaseDto).id === id) &&
        (vd as VerifierDeviceBaseDto).entrancesIds.every(
          (eId) => entranceId !== eId,
        ),
    ) as VerifierDeviceBaseDto[]
    const previouslyAttached = entranceVerifierPairsChanges.filter(
      (evp) =>
        evp.entranceId === entranceId &&
        idsToDetach.some((i) => evp.verifierDeviceId === i) &&
        evp.action === 'attach',
    )
    if (previouslyAttached.length > 0 || alreadyDetached.length > 0) {
      // remove attach elements first
      updatedEntranceVerifierPairsChanges = entranceVerifierPairsChanges.filter(
        (evp) => !previouslyAttached.some((pa) => evp === pa),
      )
      // add rest detach
      updatedEntranceVerifierPairsChanges = [
        ...updatedEntranceVerifierPairsChanges,
        ...idsToDetach
          .map(
            (i) =>
              ({
                entranceId,
                verifierDeviceId: i,
                action: 'detach',
              } as EntranceVerifierPairChange),
          )
          .filter(
            (det) =>
              !previouslyAttached.some(
                (pa) =>
                  det.entranceId === pa.entranceId &&
                  det.verifierDeviceId === pa.verifierDeviceId,
              ) &&
              !alreadyDetached.some((ad) => det.verifierDeviceId === ad.id),
          ),
      ]
    } else {
      updatedEntranceVerifierPairsChanges = [
        ...entranceVerifierPairsChanges,
        ...idsToDetach.map(
          (i) =>
            ({
              entranceId,
              verifierDeviceId: i,
              action: 'detach',
            } as EntranceVerifierPairChange),
        ),
      ]
    }
    setEntranceVerifierPairsChanges(updatedEntranceVerifierPairsChanges)
  }
  const isAttached = (entranceId: number, verifierDeviceId: number) => {
    const verifierDevice = verifierDevices[verifierDeviceId]
    if (verifierDevice?.entrancesIds?.some((id) => id === entranceId)) {
      // check if element exists in 'detach' collection
      if (
        entranceVerifierPairsChanges.some(
          (evp) =>
            evp.entranceId === entranceId &&
            evp.verifierDeviceId === verifierDeviceId &&
            evp.action === 'detach',
        )
      )
        return false
      return true
    }
    // check if element exists in 'attach' collection
    if (
      entranceVerifierPairsChanges.some(
        (evp) =>
          evp.entranceId === entranceId &&
          evp.verifierDeviceId === verifierDeviceId &&
          evp.action === 'attach',
      )
    )
      return true
    return false
  }
  const isChanged = (entranceId: number, verifierDeviceId: number) =>
    entranceVerifierPairsChanges.some(
      (evp) =>
        evp.entranceId === entranceId &&
        evp.verifierDeviceId === verifierDeviceId,
    )

  const attachAllDevicesToEntrance = (entranceId: number) => {
    addToAttach(
      entranceId,
      allVerifierDevices?.map((vd) => (vd as VerifierDeviceBaseDto).id),
    )
  }

  const detachAllDevicesFromEntrance = (entranceId: number) => {
    addToDetach(
      entranceId,
      allVerifierDevices?.map((vd) => (vd as VerifierDeviceBaseDto).id),
    )
  }

  const isAllDevicesAssignedToEntrance = (entranceId: number) =>
    allVerifierDevices
      ? allVerifierDevices?.every(
          (vd) =>
            ((vd as VerifierDeviceBaseDto).entrancesIds.some(
              (eId) => entranceId === eId,
            ) &&
              !entranceVerifierPairsChanges.some(
                (evp) =>
                  evp.action === 'detach' &&
                  entranceId === evp.entranceId &&
                  (vd as VerifierDeviceBaseDto).id === evp.verifierDeviceId,
              )) ||
            entranceVerifierPairsChanges.some(
              (evp) =>
                evp.action === 'attach' &&
                entranceId === evp.entranceId &&
                (vd as VerifierDeviceBaseDto).id === evp.verifierDeviceId,
            ),
        )
      : false

  const renderEntranceCheckbox = (
    verifierDeviceId: number,
    entrance: EntranceDto,
  ) => (
    <RecordContextProvider value={entrance}>
      <CheckboxField
        disabled={!hasAuthority(Authority.EDIT_ENTRANCES_SET)}
        className={
          isChanged(entrance?.id, verifierDeviceId)
            ? classes.checkboxChanged
            : ''
        }
        source="id"
        label={entrance?.note}
        sortable={false}
        checkBySource={(id: number) => isAttached(id, verifierDeviceId)}
        onClick={(id: number) =>
          isAttached(id, verifierDeviceId)
            ? async () => {
                await addToDetach(id, [verifierDeviceId])
              }
            : async () => {
                await addToAttach(id, [verifierDeviceId])
              }
        }
      />
    </RecordContextProvider>
  )

  return (
    <Datagrid
      {...restProps}
      header={
        <EntrancesMatrixGridHeader
          attachAllDevicesToEntrance={attachAllDevicesToEntrance}
          detachAllDevicesFromEntrance={detachAllDevicesFromEntrance}
          isAllDevicesAssigned={isAllDevicesAssignedToEntrance}
        />
      }
      size="small"
    >
      <TextField source="name" />
      {entrances?.map((e) => (
        <FunctionField<VerifierDeviceBaseDto>
          key={`${entranceKeyPrefix}${e.id}`}
          label={e.note}
          sortable={false}
          render={(record?: VerifierDeviceBaseDto) =>
            record && renderEntranceCheckbox(record?.id, e)
          }
        />
      ))}
    </Datagrid>
  )
}

const StickyPagination = ({ ...props }) => {
  const classes = useStyles()

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ margin: 'auto' }} />
      <Pagination
        {...props}
        className={classes.actions}
        rowsPerPageOptions={[10, 20, 50, 100, 200]}
      />
    </div>
  )
}

export const EntrancesMatrixList = (props: ListProps) => {
  const [entranceVerifierPairsChanges, setEntranceVerifierPairsChanges] =
    useState<EntranceVerifierPairChange[]>([])
  const classes = useStyles()
  const refresh = useRefresh()
  const onSave = () => {
    refresh()
  }

  return (
    <List
      {...props}
      exporter={false}
      filters={<EntrancesMatrixFilters />}
      perPage={10}
      pagination={<StickyPagination />}
      bulkActionButtons={false}
      actions={
        <Actions
          getEntranceVerifierPairsChanges={() => entranceVerifierPairsChanges}
          clearChanges={() => setEntranceVerifierPairsChanges([])}
          onSave={onSave}
        />
      }
      sort={{ field: 'name', order: 'ASC' }}
    >
      <EntrancesMatrixGrid
        changes={entranceVerifierPairsChanges}
        setChanges={setEntranceVerifierPairsChanges}
        className={classes.grid}
      />
    </List>
  )
}
