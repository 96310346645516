import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Close, GetApp, Videocam, VideocamOff } from '@material-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import {
  GET_ONE,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useQuery,
  useShowContext,
  useTranslate,
} from 'react-admin'
import ReactHlsPlayer from 'react-hls-player/dist'
import Popout from 'react-popout'
import { TypeSocket } from 'typesocket'
import { Authority } from '../../../../core/auth/Authority'
import { DateTime } from '../../../../core/common/date-time'
import { CameraDto } from '../../../../core/dto/device/camera/cameras/camera.dto'
import { CameraTypes } from '../../../../core/enum/CameraTypes'
import { ResourceName } from '../../../../core/ResourceName'
import Button, {
  ButtonProps,
} from '../../../common/customized-mui-components/Button'
import DraggableComponent from '../../../common/DraggableComponent'
import { useHasAuthority } from '../../../hooks/useHasAuthority'

const useStyles = makeStyles(() =>
  createStyles({
    actions: {
      padding: 0,
      background: 'rgba(0,0,0,0.2)',
    },
    title: {
      paddingLeft: '10px',
      width: '100%',
    },
    button: {
      margin: '2px',
    },
    video: {
      maxWidth: '-webkit-fill-available',
      maxHeight: '500px',
    },
    dialogContent: {
      padding: 0,
    },
  }),
)

const CameraPreviewDialog = ({
  open,
  close,
  startDate,
  cameraData,
  logId,
}: {
  open: boolean
  close: () => void
  startDate?: DateTime
  cameraData?: CameraPreviewData
  logId?: number
}) => {
  let camera: CameraDto | CameraPreviewData | undefined
  const { record } = useShowContext<CameraDto>()
  if (cameraData) camera = cameraData
  else camera = record
  const playerRef = React.useRef<HTMLVideoElement>(null)
  const [jobId, setJobId] = useState<number | undefined>(undefined)
  const [streamPath, setStreamPath] = useState('')
  const [showHLSPlayer, setShowHLSPlayer] = useState(true)
  const [hlsStreamLoaded, setHLSStreamLoaded] = useState(false)
  const [maxHLSRetriesLeft, setMaxHLSRetriesLeft] = useState(30)
  const classes = useStyles()
  const notify = useNotify()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()

  const { data: server } = useQuery(
    // useQueryWithStore do not works in combination with 'enabled' param
    {
      type: GET_ONE,
      resource: ResourceName.CAMERA_SERVERS,
      payload: {
        id: camera?.serverId,
      },
    },
    {
      enabled: hasAuthority(Authority.VIEW_CAMERA_SERVERS),
    },
  )

  // Common
  const [openStream] = useMutation(
    {
      type: 'openStream',
      resource: ResourceName.CAMERAS,
      payload: {
        cameraId: camera?.id,
        streamStart: startDate,
        logId,
      },
    },
    {
      onSuccess: ({ data }) => {
        setStreamPath(data.streamPath)
        setJobId(data.jobId)
      },
    },
  )
  const [doCloseStream] = useMutation({
    type: 'deleteJob',
    resource: ResourceName.JOBS,
    payload: {
      jobId,
    },
  })
  const closeStream = useCallback(async () => {
    if (jobId) doCloseStream()
  }, [jobId, doCloseStream])
  const handleClose = useCallback(() => {
    close()
  }, [close])

  // Downloading clips
  const handleDownloadClip = async () => {
    const {
      data: {
        blob: { body, headers },
      },
    } = await mutate(
      {
        type: sanitizeFetchType('downloadClip'),
        resource: ResourceName.CAMERAS,
        payload: {
          cameraId: camera?.id,
          streamStart: startDate,
        },
      },
      {
        returnPromise: true,
        onFailure: (msg) => notify(msg, 'error'),
      },
    )

    const fileName = decodeURIComponent(
      headers?.get('Content-Disposition')?.split("filename*=UTF-8''")[1],
    )

    const objectUrl: string = URL.createObjectURL(body)
    const a: HTMLAnchorElement = document.createElement(
      'a',
    ) as HTMLAnchorElement

    a.href = objectUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(objectUrl)
  }

  // RTSP
  const reloadHLSPlayer = () => {
    setShowHLSPlayer(false)
  }
  const onHlsDataLoaded = () => {
    setHLSStreamLoaded(true)
  }
  const handleHLSTimeout = useCallback(() => {
    handleClose()
    notify('resources.cameras.dialogs.preview.streamTimeout')
  }, [handleClose, notify])
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      server?.type === CameraTypes.VDG_RTSP ||
      CameraTypes.HIKVISION_RTSP ||
      CameraTypes.DAHUA_RTSP
    ) {
      const interval = setInterval(() => {
        reloadHLSPlayer()
        setMaxHLSRetriesLeft(maxHLSRetriesLeft - 1)
      }, 1300)
      if (hlsStreamLoaded || !open) {
        clearInterval(interval)
      } else if (maxHLSRetriesLeft <= 0) {
        handleHLSTimeout()
      }
      return () => clearInterval(interval)
    }
  }, [hlsStreamLoaded, open, maxHLSRetriesLeft, handleHLSTimeout, server])
  useEffect(() => {
    if (
      (server?.type === CameraTypes.VDG_RTSP ||
        CameraTypes.HIKVISION_RTSP ||
        CameraTypes.DAHUA_RTSP) &&
      !showHLSPlayer
    )
      setShowHLSPlayer(true)
  }, [showHLSPlayer, server])
  useEffect(() => {
    if (
      server?.type === CameraTypes.VDG_RTSP ||
      CameraTypes.HIKVISION_RTSP ||
      CameraTypes.DAHUA_RTSP
    )
      playerRef.current?.addEventListener('loadeddata', onHlsDataLoaded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef.current])

  // WEBSOCKET
  const openWebsocketPlugin = useCallback(() => {
    const socket = new TypeSocket<string>('ws://localhost:3002/openWindow')
    socket.on('connected', () => {
      socket.send(streamPath)
    })
    const disconnectedListener = () =>
      notify('resources.cameras.dialogs.preview.WEBSOCKETPluginDisconnected')
    socket.on('disconnected', disconnectedListener)
    socket.on('rawMessage', (value) => {
      socket.off('disconnected', disconnectedListener)
      socket.disconnect()
      return value === 'OK'
        ? notify(
            'resources.cameras.dialogs.preview.WEBSOCKETPluginVideoOpening',
          )
        : notify(
            `${translate(
              'resources.cameras.dialogs.preview.WEBSOCKETPluginUnknownMessage',
            )} - ${value}`,
          )
    })
    socket.connect()
  }, [translate, notify, streamPath])
  useEffect(() => {
    if (
      open &&
      streamPath &&
      streamPath !== '' &&
      server?.type === CameraTypes.WEBSOCKET
    ) {
      openWebsocketPlugin()
      close()
    }
  }, [open, close, server, notify, translate, openWebsocketPlugin, streamPath])

  const renderCameraView = () => {
    switch (server?.type) {
      case CameraTypes.VDG_RTSP:
      case CameraTypes.HIKVISION_RTSP:
      case CameraTypes.DAHUA_RTSP:
        return (
          <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={DraggableComponent}
            aria-labelledby="draggable-dialog-title"
            maxWidth="md"
          >
            <DialogActions
              className={classes.actions}
              style={{ cursor: 'move' }}
            >
              <Typography className={classes.title} id="draggable-dialog-title">
                {camera?.description}
              </Typography>
              <IconButton
                size="small"
                className={classes.button}
                onClick={handleDownloadClip}
                disabled={mutationLoading}
              >
                <GetApp fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                className={classes.button}
                onClick={handleClose}
              >
                <Close fontSize="small" />
              </IconButton>
            </DialogActions>
            <DialogContent className={classes.dialogContent}>
              {showHLSPlayer && (
                <ReactHlsPlayer
                  className={classes.video}
                  playerRef={playerRef}
                  src={streamPath}
                  autoPlay
                  muted
                  controls
                  width="auto"
                  height="auto"
                />
              )}
            </DialogContent>
          </Dialog>
        )
      case CameraTypes.VDG_MJPEG:
      case CameraTypes.AXXON_MJPEG:
        return open && streamPath ? (
          <Popout
            url={streamPath}
            title={camera?.description}
            onClosing={handleClose}
          />
        ) : (
          <></>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    if (open) {
      openStream()
    }
  }, [open, openStream])

  useEffect(() => {
    if (!open) {
      setHLSStreamLoaded(false)
      setStreamPath('')
      closeStream()
      setJobId(undefined)
      setMaxHLSRetriesLeft(30)
    }
  }, [open, closeStream])

  return renderCameraView()
}

export const OpenCameraPreviewDialogButton = ({
  label,
  startDate,
  cameraData,
  logId,
  props,
}: {
  label?: string
  startDate?: DateTime
  cameraData?: CameraPreviewData
  logId?: number
  props?: ButtonProps
}) => {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)
  const hasAuthority = useHasAuthority()

  const onClose = () => {
    setDialogOpen(false)
  }

  const openDialog = () => {
    setDialogOpen(true)
  }

  return (
    <>
      <CameraPreviewDialog
        open={dialogOpen}
        close={onClose}
        startDate={startDate}
        cameraData={cameraData}
        logId={logId}
      />
      <Button
        variant="contained"
        color="inherit"
        className={classes.button}
        label={
          label == null ? 'resources.cameras.actions.openCameraPreview' : label
        }
        useSmallVersionBreakpoint={false}
        disabled={!hasAuthority(Authority.CAMERAS_PREVIEW_BUTTON)}
        {...props}
        onClick={openDialog}
      >
        {props?.disabled ? <VideocamOff /> : <Videocam />}
      </Button>
    </>
  )
}

export interface CameraPreviewData {
  readonly id: number
  readonly description: string
  readonly serverId: number
}
