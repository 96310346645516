import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { validateTicketImportTemplate } from './TicketImportTemplateCreate'

const EditTitle = () => {
  const translate = useTranslate()
  return (
    <span>{translate('resources.ticket-import-templates.titles.edit')}</span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const TicketImportTemplateEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />} actions={false}>
    <SimpleForm
      redirect="list"
      validate={validateTicketImportTemplate}
      toolbar={<EditToolbar />}
    >
      <TextField source="id" label="Id" />
      <TextInput source="name" isRequired />
    </SimpleForm>
  </Edit>
)
