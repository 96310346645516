import { DataProvider } from 'react-admin'
import { AccessBonusDto } from '../../dto/access-bonus.dto'

const fakeAccessBonus: AccessBonusDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeAccessBonus, total: fakeAccessBonus.length }),
} as DataProvider

export default provider
