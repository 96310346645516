import {
  Button as MuiButton,
  IconButton,
  PropTypes as MuiPropTypes,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { LocationDescriptor } from 'history'
import PropTypes from 'prop-types'
import { Record, RedirectionSideEffect, useTranslate } from 'ra-core'
import * as React from 'react'
import { ReactElement, ReactNode, SyntheticEvent } from 'react'

/**
 * A generic Button with side icon. Only the icon is displayed on small screens.
 *
 * The component translates the label. Pass the icon as child.
 * The icon displays on the left side of the button by default. Set alignIcon prop to 'right' to inverse.
 *
 * @example
 *
 * <Button label="Edit" color="secondary" onClick={doEdit}>
 *   <ContentCreate />
 * </Button>
 *
 */

const useStyles = makeStyles(
  {
    button: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    label: {
      paddingLeft: '0.5em',
    },
    labelRightIcon: {
      paddingRight: '0.5em',
    },
    smallIcon: {
      fontSize: 20,
    },
    mediumIcon: {
      fontSize: 22,
    },
    largeIcon: {
      fontSize: 24,
    },
  },
  { name: 'RaButton' },
)

export const sanitizeButtonRestProps = ({
  // The next props are injected by Toolbar
  basePath, // eslint-disable-line @typescript-eslint/no-unused-vars
  handleSubmit, // eslint-disable-line @typescript-eslint/no-unused-vars
  handleSubmitWithRedirect, // eslint-disable-line @typescript-eslint/no-unused-vars
  invalid, // eslint-disable-line @typescript-eslint/no-unused-vars
  onSave, // eslint-disable-line @typescript-eslint/no-unused-vars
  pristine, // eslint-disable-line @typescript-eslint/no-unused-vars
  record, // eslint-disable-line @typescript-eslint/no-unused-vars
  redirect, // eslint-disable-line @typescript-eslint/no-unused-vars
  resource, // eslint-disable-line @typescript-eslint/no-unused-vars
  saving, // eslint-disable-line @typescript-eslint/no-unused-vars
  submitOnEnter, // eslint-disable-line @typescript-eslint/no-unused-vars
  undoable, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...rest
}: any) => rest

const Button = (props: ButtonProps) => {
  const {
    alignIcon = 'left',
    children,
    classes: classesOverride, // eslint-disable-line @typescript-eslint/no-unused-vars
    className,
    color,
    disabled,
    label,
    size = 'small',
    useSmallVersionBreakpoint: useSmallVersion,
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const restProps = sanitizeButtonRestProps(rest)
  // eslint-disable-next-line no-nested-ternary
  return isXSmall && useSmallVersion ? (
    label && !disabled ? (
      <Tooltip title={translate(label, { _: label })}>
        <IconButton
          aria-label={translate(label, { _: label })}
          className={className}
          color={color}
          {...restProps}
        >
          {children}
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        className={className}
        color={color}
        disabled={disabled}
        {...restProps}
      >
        {children}
      </IconButton>
    )
  ) : (
    <MuiButton
      className={classnames(classes.button, className)}
      color={color}
      size={size}
      aria-label={label ? translate(label, { _: label }) : undefined}
      disabled={disabled}
      {...restProps}
    >
      {alignIcon === 'left' &&
        children &&
        React.cloneElement(children, {
          className: classes[`${size}Icon`],
        })}
      {label && (
        <span
          className={classnames({
            [classes.label]: alignIcon === 'left',
            [classes.labelRightIcon]: alignIcon !== 'left',
          })}
        >
          {translate(label, { _: label })}
        </span>
      )}
      {alignIcon === 'right' &&
        children &&
        React.cloneElement(children, {
          className: classes[`${size}Icon`],
        })}
    </MuiButton>
  )
}

interface Props {
  alignIcon?: 'left' | 'right'
  children?: ReactElement
  // eslint-disable-next-line @typescript-eslint/ban-types
  classes?: object
  className?: string
  color?: MuiPropTypes.Color
  component?: ReactNode
  to?: string | LocationDescriptor
  disabled?: boolean
  label?: string
  size?: 'small' | 'medium' | 'large'
  icon?: ReactElement
  redirect?: RedirectionSideEffect
  variant?: string
  // May be injected by Toolbar
  basePath?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void
  invalid?: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSave?: (values: object, redirect: RedirectionSideEffect) => void
  saving?: boolean
  submitOnEnter?: boolean
  pristine?: boolean
  record?: Record
  resource?: string
  undoable?: boolean
  useSmallVersionBreakpoint?: boolean
}

export type ButtonProps = Props & MuiButtonProps

Button.propTypes = {
  alignIcon: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  useSmallVersionBreakpoint: PropTypes.bool,
}

Button.defaultProps = {
  color: 'primary',
  size: 'small',
  useSmallVersionBreakpoint: true,
}

export default Button
