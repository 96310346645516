import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
  Identifier,
} from 'react-admin'
import { ADMINISTRATOR_URL } from '../../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { RoleDto } from '../../dto/role/role.dto'
import { UserDto } from '../../dto/user.dto'
import filterMapper from './users-filter.mapper'
import { mapSortUserParam } from './users-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<UserDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortUserParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<UserDto[]>(
      `${ADMINISTRATOR_URL}/GetUsersByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<UserDto>> => {
    const data = await get<UserDto>(
      `${ADMINISTRATOR_URL}/GetUserById`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<UserDto>> => {
    const data = await get<UserDto[]>(
      `${ADMINISTRATOR_URL}/GetUsersByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateUserRequest>,
  ): Promise<CreateResult> => {
    const created = await post<CreateUserRequest, UserDto>(
      `${ADMINISTRATOR_URL}/PostUser`,
      data,
    )
    return Promise.resolve({ data: created })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<UserDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, UserDto>(
      `${ADMINISTRATOR_URL}/PatchUser`,
      {
        id,
        userName:
          data.userName !== previousData.userName ? data.userName : undefined,
        companyId:
          data.companyId !== previousData.companyId
            ? data.companyId
            : undefined,
        requirePasswordUpdate:
          data.requirePasswordUpdate !== previousData.requirePasswordUpdate
            ? data.requirePasswordUpdate
            : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  changeUserPassword: async (
    resource: string,
    params: ChangeUserPasswordRequest,
  ): Promise<DataProviderExtensionResult<ChangeUserPasswordResponse>> => {
    const newPassword = await put<
      ChangeUserPasswordRequest,
      ChangeUserPasswordResponse
    >(`${ADMINISTRATOR_URL}/ChangeUserPassword`, params)
    return Promise.resolve({
      data: newPassword,
    })
  },
  generateResetUserPasswordToken: async (
    resource: string,
    params: GenerateResetUserPasswordTokenRequest,
  ): Promise<
    DataProviderExtensionResult<GenerateResetUserPasswordTokenResponse>
  > => {
    const token = await put<
      GenerateResetUserPasswordTokenRequest,
      GenerateResetUserPasswordTokenResponse
    >(`${ADMINISTRATOR_URL}/GenerateResetUserPasswordToken`, params)
    return {
      data: token,
    }
  },
  attachRoles: async (
    resource: string,
    params: UserRoles,
  ): Promise<DataProviderExtensionResult<UserRoles>> => {
    const token = await put<UserRoles, UserRoles>(
      `${ADMINISTRATOR_URL}/AttachRolesToUser`,
      params,
    )
    return {
      data: token,
    }
  },
  detachRoles: async (
    resource: string,
    params: UserRoles,
  ): Promise<DataProviderExtensionResult<UserRoles>> => {
    const token = await put<UserRoles, UserRoles>(
      `${ADMINISTRATOR_URL}/DetachRolesFromUser`,
      params,
    )
    return {
      data: token,
    }
  },
  getRoles: async (
    resource: string,
    { userId }: GetRolesParams,
  ): Promise<DataProviderExtensionResult<RoleDto[]>> => {
    const token = await get<RoleDto[]>(
      `${ADMINISTRATOR_URL}/GetUserRoles/`,
      userId as string,
    )
    return {
      data: token,
    }
  },
} as UserDataProvider

interface UserDataProvider extends DataProvider {
  changeUserPassword: (
    resource: string,
    params: ChangeUserPasswordRequest,
  ) => Promise<CacheableDataProviderExtensionResult<ChangeUserPasswordResponse>>

  generateResetUserPasswordToken: (
    resource: string,
    params: GenerateResetUserPasswordTokenRequest,
  ) => Promise<
    CacheableDataProviderExtensionResult<GenerateResetUserPasswordTokenResponse>
  >

  attachRoles: (
    resource: string,
    params: UserRoles,
  ) => Promise<CacheableDataProviderExtensionResult<UserRoles>>

  detachRoles: (
    resource: string,
    params: UserRoles,
  ) => Promise<CacheableDataProviderExtensionResult<UserRoles>>

  getRoles: (
    resource: string,
    params: GetRolesParams,
  ) => Promise<CacheableDataProviderExtensionResult<RoleDto[]>>
}

export interface CreateUserRequest {
  readonly userName: string
  readonly password: string
  readonly companyId: number
  readonly requirePasswordUpdate: boolean
}

interface ChangeUserPasswordRequest {
  readonly userId: number
  readonly newPassword: string
}

interface ChangeUserPasswordResponse {
  readonly password: string
  readonly id: number
  readonly userName: string
}

interface GenerateResetUserPasswordTokenRequest {
  readonly userId: number
}

interface GenerateResetUserPasswordTokenResponse {
  readonly userId: number
  readonly token: string
}

interface UserRoles {
  readonly userId: number
  readonly rolesIds: number[]
}

interface GetRolesParams {
  userId: Identifier
}

export default provider
