import { Tooltip, makeStyles } from '@material-ui/core'
import { Close, PowerSettingsNew } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'

const useStyles = makeStyles(() => ({
  iconContainer: {
    position: 'relative',
    width: 34,
  },
  icon: {
    position: 'absolute',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
}))

export const TerminalPowerButton = ({ ...props }) => {
  const { jsonStatus, record } = props
  const translate = useTranslate()
  const classes = useStyles()
  const [mutate, { loading }] = useMutation()
  const hasAuthority = useHasAuthority()
  const refresh = useRefresh()

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  const handleOpenConfirm = () => {
    setConfirmOpen(true)
  }

  const handleCloseConfirm = () => {
    setConfirmOpen(false)
  }

  const handleChangePowerState = () => {
    mutate({
      type: sanitizeFetchType('setPrinterPower'),
      resource: ResourceName.TERMINALS,
      payload: {
        terminalId: record?.id,
        powerState: !jsonStatus?.isPrinterOrCashlessPowered,
      },
    })
    handleCloseConfirm()
    refresh()
  }

  return (
    <div>
      <ConfirmDialog
        confirm={handleChangePowerState}
        source="setPrinterPower"
        resource={ResourceName.TERMINALS}
        action={jsonStatus?.isPrinterOrCashlessPowered ? 'remove' : 'set'}
        cancel={handleCloseConfirm}
        open={confirmOpen}
      />
      <Tooltip
        title={
          jsonStatus?.isPrinterOrCashlessPowered
            ? translate('resources.enums.isPrinterOrCashlessPowered.powered')
            : translate('resources.enums.isPrinterOrCashlessPowered.unpowered')
        }
        onClick={
          !loading && hasAuthority(Authority.TERMINALS_SET_PRINTER_POWER)
            ? handleOpenConfirm
            : undefined
        }
      >
        <div
          className={classes.iconContainer}
          style={{
            cursor:
              !loading && hasAuthority(Authority.TERMINALS_SET_PRINTER_POWER)
                ? 'pointer'
                : 'default',
          }}
        >
          <>
            <PowerSettingsNew className={classes.icon} />
            {!jsonStatus?.isPrinterOrCashlessPowered && (
              <Close fontSize="large" className={classes.crossIcon} />
            )}
          </>
        </div>
      </Tooltip>
    </div>
  )
}
