import { Cancel } from '@material-ui/icons'
import React, { useMemo } from 'react'
import {
  DateTimeInput,
  Edit,
  EditProps,
  FunctionField,
  Record,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { EventDto } from '../../core/dto/event/event.dto'

interface EventTitleProps extends TitleProps {
  record?: EventDto
}

const validateEventEdit = (values: EventDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!Date.parse(values.dateStart))
    errors.dateStart = 'ra.validation.incorrect_date'
  if (!Date.parse(values.dateEnd))
    errors.dateEnd = 'ra.validation.incorrect_date'
  if (!values.dateStart) {
    errors.dateStart = 'ra.validation.required'
  }
  if (!values.dateEnd) {
    errors.dateEnd = 'ra.validation.required'
  }
  if (new Date(values.dateStart) > new Date(values.dateEnd)) {
    errors.dateStart = 'ra.validation.incorrect_date'
    errors.dateEnd = 'ra.validation.incorrect_date'
  }
  return errors
}

const EditTitle = (data: EventTitleProps) => {
  const { record } = data
  const translate = useTranslate()
  const date = useMemo(
    () =>
      !((record?.dateStart as any) instanceof Date)
        ? record?.dateStart.replace('T', ' ')
        : '',
    [record?.dateStart],
  )
  return (
    <span>
      {translate('resources.events.titles.edit')}: {record?.name} {date}{' '}
      {record?.id}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const EventEdit = (props: EditProps) => {
  const translate = useTranslate()
  return (
    <Edit {...props} title={<EditTitle />} actions={false}>
      <SimpleForm
        redirect="show"
        toolbar={<EditToolbar />}
        validate={validateEventEdit}
      >
        <TextField source="id" label="ID" />
        <TextInput source="name" isRequired />
        <DateTimeInput source="dateStart" isRequired />
        <DateTimeInput source="dateEnd" isRequired />
        <TextInput source="externalId" />
        <TextInput source="infix" />
        <FunctionField
          source="status"
          render={(r: Record | undefined) => (
            <TextField
              emptyText={translate(
                `resources.enums.eventStatus.${r?.status.toLowerCase()}`,
              )}
            />
          )}
        />
      </SimpleForm>
    </Edit>
  )
}
