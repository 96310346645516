import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  BooleanInput,
  DateField,
  Edit,
  EditProps,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { PassageDto } from '../../../core/dto/device/passages/passage.dto'
import { HardwareVersions } from '../../../core/enum/HardwareVersions'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { SPOM4FullAddressInput } from './SPOM4Address'

const useStyles = makeStyles({
  numberInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

const validatePassageEdit = (values: PassageDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (values.hardwareVersion === HardwareVersions.SPOM4) {
    const addressParts = values.tsCommAddress?.split('&')
    if (!addressParts?.[0]) {
      errors.spom4_ipGate = 'ra.validation.required'
    }
    if (!addressParts?.[1]) {
      errors.spom4_tscanHost = 'ra.validation.required'
    }
    if (!addressParts?.[2]) {
      errors.spom4_direction = 'ra.validation.required'
    }
    if (!addressParts?.[3]) {
      errors.spom4_rs485Address = 'ra.validation.required'
    }
  }
  if (values.hardwareVersion === HardwareVersions.MODULO5 && !values.tsCanId) {
    errors.tsCanId = 'ra.validation.required'
  }
  return errors
}

interface PassageTitleProps extends TitleProps {
  record?: PassageDto
}

const EditTitle = (props: PassageTitleProps) => {
  const { record: passage } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.passages.titles.edit')}: {passage?.id}.{' '}
      {passage?.name}{' '}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const Passage = ({ ...props }) => {
  const theme = useTheme()
  const classes = useStyles()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  const { record: passage } = props
  const [hardwareVersion, setHardwareVersion] = useState(
    passage.hardwareVersion,
  )

  const TSCanInput = ({ ...p }) =>
    hasAuthority(Authority.VIEW_TS_CANS) ? (
      <FilteredReferenceInput
        {...p}
        source="tsCanId"
        defaultValue={null}
        reference={ResourceName.TS_CANS}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={smallScreen ? 5 : 15}
        allowEmpty
        filterSource="search"
        selectWithPaginationInputProps={{
          optionText: 'name',
          showFilter: true,
        }}
      />
    ) : (
      <div />
    )

  return (
    <SimpleForm
      {...props}
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validatePassageEdit}
      transform={(formData) => ({
        ...formData,
        tsCommAddress:
          hardwareVersion === HardwareVersions.SPOM4
            ? formData?.tsCommAddress
            : undefined,
      })}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" isRequired />
      <BooleanInput source="isWatched" />
      <DateField
        source="lastRefresh"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
      />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          source="categoryId"
          defaultValue={null}
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_CAMERAS) && (
        <FilteredReferenceInput
          source="cameraId"
          defaultValue={null}
          reference={ResourceName.CAMERAS}
          sort={{ field: 'description', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'description',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.PASSAGES_COUNTERS_EDIT) ? (
        <TextInput
          source="freeEntrancesCount"
          type="number"
          className={classes.numberInput}
        />
      ) : (
        <TextField source="freeEntrancesCount" />
      )}
      {hasAuthority(Authority.PASSAGES_COUNTERS_EDIT) ? (
        <TextInput
          source="freeExitsCount"
          type="number"
          className={classes.numberInput}
        />
      ) : (
        <TextField source="freeExitsCount" />
      )}
      {hasAuthority(Authority.PASSAGES_COUNTERS_EDIT) ? (
        <TextInput
          source="ticketEntrancesCount"
          type="number"
          className={classes.numberInput}
        />
      ) : (
        <TextField source="ticketEntrancesCount" />
      )}
      {hasAuthority(Authority.PASSAGES_COUNTERS_EDIT) ? (
        <TextInput
          source="ticketExitsCount"
          type="number"
          className={classes.numberInput}
        />
      ) : (
        <TextField source="ticketExitsCount" />
      )}
      {hasAuthority(Authority.PASSAGES_COUNTERS_EDIT) ? (
        <TextInput
          source="unauthorizedEntrancesCount"
          type="number"
          className={classes.numberInput}
        />
      ) : (
        <TextField source="unauthorizedEntrancesCount" />
      )}
      {hasAuthority(Authority.PASSAGES_COUNTERS_EDIT) ? (
        <TextInput
          source="unauthorizedExitsCount"
          type="number"
          className={classes.numberInput}
        />
      ) : (
        <TextField source="unauthorizedExitsCount" />
      )}
      <SelectInput
        value={hardwareVersion}
        onChange={(e) => setHardwareVersion(e.target.value)}
        source="hardwareVersion"
        choices={Object.entries(HardwareVersions).map(([value]) => ({
          id: value,
          name: `resources.enums.hardwareVersions.${value}`,
        }))}
      />
      {hardwareVersion === HardwareVersions.SPOM4 && (
        <SPOM4FullAddressInput source="tsCommAddress" />
      )}
      {hardwareVersion === HardwareVersions.MODULO5 && (
        <TSCanInput source="tsCommAddress" />
      )}
    </SimpleForm>
  )
}

export const PassageEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <Passage />
  </Edit>
)
