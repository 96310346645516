import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  DateTimeInput,
  Edit,
  EditProps,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { CustomerDto } from '../../core/dto/customer.dto'
import { CustomerDocumentType } from '../../core/enum/CustomerDocumentType'
import { Gender } from '../../core/enum/Gender'

const validateCustomerEdit = (values: CustomerDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.firstname) {
    errors.firstname = 'ra.validation.required'
  }
  if (!values.lastname) {
    errors.lastname = 'ra.validation.required'
  }
  return errors
}

interface CustomerTitleProps extends TitleProps {
  record?: CustomerDto
}

const EditTitle = (data: CustomerTitleProps) => {
  const { record } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.customers.titles.edit')}: {record?.id}.{' '}
      {record?.firstname} {record?.lastname}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const CustomerEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <SimpleForm
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateCustomerEdit}
    >
      <TextField source="id" label="ID" />
      <TextInput source="firstname" isRequired />
      <TextInput source="lastname" isRequired />
      <TextInput source="company" />
      <DateTimeInput source="birthdate" />
      <SelectInput
        source="documentType"
        choices={Object.entries(CustomerDocumentType).map(([value]) => ({
          id: value,
          name: `resources.enums.customerDocumentType.${value}`,
        }))}
      />
      <TextInput source="pesel" />
      <TextInput source="documentNumber" />
      <TextInput source="nationality" />
      <SelectInput
        source="gender"
        choices={Object.entries(Gender).map(([value]) => ({
          id: value,
          name: `resources.enums.gender.${value}`,
        }))}
      />
    </SimpleForm>
  </Edit>
)
