import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  AccountBalanceWallet,
  ArrowForward,
  Cancel,
  CenterFocusStrong,
  PhoneAndroid,
  Storage,
} from '@material-ui/icons'
import React from 'react'
import {
  CreateProps,
  useTranslate,
  SimpleForm,
  SaveButton,
  Toolbar,
  ToolbarProps,
  Create,
  ListButton,
} from 'react-admin'
import { VerifierSchemeDataDto } from '../../../../core/dto/verifier-scheme-data.dto'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import FilteredReferenceInput from '../../../common/FilteredReferenceInput'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { DeviceBaseWithTypeInfoDto } from '../../../../core/dto/device/common/device-base/device-base-with-type-info.dto'
import { DeviceTypes } from '../../../../core/enum/DeviceTypes'

export const validateVerifierSchemeDataCreate = (
  values: VerifierSchemeDataDto,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.schemeId) {
    errors.schemeId = 'ra.validation.required'
  }
  if (!values.verifierDeviceId) {
    errors.verifierDeviceId = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return (
    <span>{translate('resources.verifier-scheme-data.titles.create')}</span>
  )
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const VerifierSchemeDataCreate = (props: CreateProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Create {...props} hasShow={false} title={<CreateTitle />}>
      <SimpleForm
        redirect="show"
        toolbar={<CreateToolbar />}
        validate={validateVerifierSchemeDataCreate}
      >
        {hasAuthority(Authority.VIEW_SCHEMES) && (
          <FilteredReferenceInput
            source="schemeId"
            reference={ResourceName.SCHEMES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="translatedLogSchemeDataText"
            selectWithPaginationInputProps={{
              optionText: 'translatedLogSchemeDataHierarchyString',
              showFilter: true,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_VERIFIER_DEVICES) && (
          <FilteredReferenceInput
            source="verifierDeviceId"
            reference={ResourceName.VERIFIER_DEVICES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="name"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
              rowIcon: (record: DeviceBaseWithTypeInfoDto) => {
                switch (record?.type) {
                  case DeviceTypes.ENGINE:
                    return <AccountBalanceWallet />
                  case DeviceTypes.PALM:
                    return <PhoneAndroid />
                  case DeviceTypes.DETECTOR:
                    return <CenterFocusStrong />
                  case DeviceTypes.PASSAGE:
                    return <ArrowForward />
                  case DeviceTypes.OFFLINE_SERVER:
                    return <Storage />
                  default:
                    return <div />
                }
              },
            }}
          />
        )}
      </SimpleForm>
    </Create>
  )
}
