export enum TicketType {
  K = 'K',
  M = 'M',
  Z = 'Z',
  N = 'N',
  P = 'P',
  U = 'U',
  R = 'R',
  GENERATE = 'GENERATE',
}
