import { ResourceName } from '../ResourceName'

const mapping: Record<string, string> = {
  [ResourceName.ACCESSES]: 'filterAccessId',
  [ResourceName.ENGINES]: 'filterVerifierDeviceId',
  [ResourceName.DEPOSITORS]: 'filterVerifierDeviceId',
  [ResourceName.ENTRANCES]: 'filterEntranceId',
  [ResourceName.EVENTS]: 'filterEventId',
  [ResourceName.PALMS]: 'filterVerifierDeviceId',
  [ResourceName.VARIANTS]: 'filterVariantId',
  [ResourceName.ROLES]: 'filterRoleId',
  [ResourceName.USERS]: 'filterUserId',
  [ResourceName.STATS_DYNAMIC_STATISTICS]: 'filterDynamicStatisticId',
}

const filterMapper = (key: ResourceName) => mapping[key]

export default filterMapper
