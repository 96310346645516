import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import classNames from 'classnames'
import React, { cloneElement } from 'react'
import { useTranslate, SaveButtonProps } from 'react-admin'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      position: 'relative',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: 'RaSaveButton' },
)

export const SaveButton = ({ ...props }: SaveButtonProps) => {
  const { className, disabled, saving } = props
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Button
      className={classNames(classes.button, className)}
      color="primary"
      disabled={disabled}
      type="submit"
      variant="contained"
    >
      {saving ? (
        <CircularProgress
          className={classes.leftIcon}
          size={18}
          thickness={2}
        />
      ) : (
        cloneElement(<Save />, {
          className: classNames(classes.leftIcon, classes.icon),
        })
      )}
      {translate('ra.action.save')}
    </Button>
  )
}
