import React from 'react'
import {
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { DynamicStatisticParameterTypes } from '../../../../core/enum/DynamicStatisticParameterTypes'
import { ResourceName } from '../../../../core/ResourceName'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { AccessBonusDto } from '../../../../core/dto/access-bonus.dto'
import { parameterTypeAuthority } from '../dynamic-statistics-show/DynamicStatisticParameterList'

export const ParameterValueField = ({ ...props }) => {
  const { record: parameter, source: fieldSource } = props
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  const type =
    !parameterTypeAuthority[parameter.type] ||
    hasAuthority(parameterTypeAuthority[parameter.type])
      ? parameter.type
      : DynamicStatisticParameterTypes.STRING

  switch (type) {
    case DynamicStatisticParameterTypes.INTEGER: {
      return <TextField source={fieldSource || 'value'} {...props} />
    }
    case DynamicStatisticParameterTypes.DATE: {
      return (
        <DateField
          source={fieldSource || 'value'}
          options={{
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.CURRENT_DATE: {
      return <DateField showTime source={fieldSource || 'value'} {...props} />
    }
    case DynamicStatisticParameterTypes.CULTURE: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(`resources.enums.Cultures.${record?.[source as string]}`)
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.CURRENT_USER_ID: {
      return <TextField source={fieldSource || 'value'} {...props} />
    }
    case DynamicStatisticParameterTypes.USER_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.USERS}
          link={hasAuthority(Authority.VIEW_USERS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="userName"
            render={(record) => `${record?.id}. ${record?.userName}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.COMPANY_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.COMPANIES}
          link={hasAuthority(Authority.VIEW_COMPANIES) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.PERMISSION_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.PERMISSIONS}
          link={false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.ROLE_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.ROLES}
          link={hasAuthority(Authority.VIEW_ROLES) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.CAMERA_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.CAMERAS}
          link={hasAuthority(Authority.VIEW_CAMERAS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.CAMERA_SERVER_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.CAMERA_SERVERS}
          link={hasAuthority(Authority.VIEW_CAMERA_SERVERS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.PALM_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.PALMS}
          link={hasAuthority(Authority.VIEW_PALMS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.PALM_SCHEME_DATA_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.PALM_SCHEME_DATA}
          link={false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.text}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.DEPOSITOR_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.DEPOSITORS}
          link={hasAuthority(Authority.VIEW_DEPOSITORS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.DEVICE_BASE_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.DEVICES_BASE}
          link={false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.ENGINE_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.ENGINES}
          link={hasAuthority(Authority.VIEW_ENGINES) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.OFFLINE_SERVER_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.OFFLINE_SERVERS}
          link={hasAuthority(Authority.VIEW_OFFLINE_SERVERS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.PASSAGE_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.PASSAGES}
          link={hasAuthority(Authority.VIEW_PASSAGES) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.PASSAGE_COUNTER_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.PASSAGES}
          link={hasAuthority(Authority.VIEW_PASSAGES) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.VERIFIER_DEVICE_BASE_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.VERIFIER_DEVICES}
          link={false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.DEVICE_CATEGORY_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.DEVICE_CATEGORIES}
          link={hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.ENTRANCE_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.ENTRANCES}
          link={hasAuthority(Authority.VIEW_ENTRANCES) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="note"
            render={(record) => `${record?.id}. ${record?.note}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.ENTRANCE_SET_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.ENTRANCES_SET}
          link={false}
          {...props}
        >
          <FunctionField
            source="description"
            render={(record) => `${record?.id}. ${record?.description}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.CAMERA_TYPE: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.CameraTypes.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.DEVICE_DIRECTION: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.deviceDirection.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.HARDWARE_VERSION: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.hardwareVersions.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.PALM_THEME: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.PalmThemes.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.PASSAGE_COUNTER_TYPE: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.PassageCounterType.${
                record?.[source as string]
              }`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.PASSAGE_DIRECTION: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.deviceDirection.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.LOG_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.LOGS}
          link={false}
          {...props}
        >
          <FunctionField
            source="text"
            render={(record) => `${record?.id}. ${record?.text}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.LOG_SCHEME_DATA_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.LOG_SCHEME_DATA}
          link={false}
          {...props}
        >
          <FunctionField
            source="text"
            render={(record) => `${record?.id}. ${record?.text}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.ACCESS_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.ACCESSES}
          link={hasAuthority(Authority.VIEW_ACCESSES) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.ACCESS_DATE_ID: {
      return <TextField source={fieldSource || 'value'} {...props} />
    }
    case DynamicStatisticParameterTypes.ACCESS_USING_ID: {
      return <TextField source={fieldSource || 'value'} {...props} />
    }
    case DynamicStatisticParameterTypes.BLOCK_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.BLOCKS}
          link={false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.BOOKING_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.BOOKINGS}
          link={hasAuthority(Authority.VIEW_BOOKINGS) ? 'show' : false}
          {...props}
        >
          <TextField source="id" />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.CUSTOMER_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.CUSTOMERS}
          link={hasAuthority(Authority.VIEW_CUSTOMERS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            render={(record) => `${record?.firstName} ${record?.lastName}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.EVENT_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.EVENTS}
          link={hasAuthority(Authority.VIEW_EVENTS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.OBJECT_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.OBJECTS}
          link={hasAuthority(Authority.VIEW_OBJECTS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.SCHEME_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.SCHEMES}
          link={false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.SEAT_ID: {
      return <TextField source={fieldSource || 'value'} {...props} />
    }
    case DynamicStatisticParameterTypes.TICKET_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.TICKETS}
          link={false}
          {...props}
        >
          <TextField source="ticketCode" />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.TRIBUNE_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.TRIBUNES}
          link={false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.VARIANT_ID: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.VARIANTS}
          link={hasAuthority(Authority.VIEW_VARIANTS) ? 'show' : false}
          {...props}
        >
          <FunctionField
            source="name"
            render={(record) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.ACCESS_ACTIVE_PERIOD_STARTING_OPTION: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.activePeriodStartingFrom.${
                record?.[source as string]
              }`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.ACCESS_BONUS: {
      return (
        <ReferenceField
          source={fieldSource || 'value'}
          reference={ResourceName.ACCESS_BONUS}
          link={hasAuthority(Authority.VIEW_ACCESS_BONUSES) ? 'show' : false}
          {...props}
        >
          <FunctionField<AccessBonusDto>
            render={(record?: AccessBonusDto) =>
              `${record?.id}. ${record?.name} (${record?.description})`
            }
          />
        </ReferenceField>
      )
    }
    case DynamicStatisticParameterTypes.ACCESS_DATE_TYPE: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.accessDateTypes.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.BLOCK_TYPE: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(`resources.enums.blockType.${record?.[source as string]}`)
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.BOOKING_DISABLED_OPTION: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.bookingDisabled.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.BOOKING_TYPE: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.bookingType.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.CUSTOMER_DOCUMENT_TYPE: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.customerDocumentType.${
                record?.[source as string]
              }`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.CUSTOMER_TYPE: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) => record?.[source as string]}
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.EVENT_STATUS: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.eventStatus.${record?.[
                source as string
              ]?.toLowerCase()}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.GENDER: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(`resources.enums.gender.${record?.[source as string]}`)
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.TICKET_TYPES: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.ticketType.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    case DynamicStatisticParameterTypes.TRIBUNE_TYPES: {
      return (
        <FunctionField
          source={fieldSource || 'value'}
          render={(record, source) =>
            translate(
              `resources.enums.tribuneType.${record?.[source as string]}`,
            )
          }
          {...props}
        />
      )
    }
    default: {
      return <TextField source={fieldSource || 'value'} {...props} />
    }
  }
}
