import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  ListButton,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { DynamicCounterDto } from '../../../core/dto/dynamic-counter.dto'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { Authority } from '../../../core/auth/Authority'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { ResourceName } from '../../../core/ResourceName'

interface DynamicPropertyDefinitionTitleProps extends TitleProps {
  record?: DynamicCounterDto
}

const validateDynamicCounterEdit = (values: DynamicCounterDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.sqlQuery) {
    errors.sqlQuery = 'ra.validation.required'
  }
  if (!values.state && values.state !== 0) {
    errors.state = 'ra.validation.required'
  }
  if (!values.limit && values.limit !== 0) {
    errors.limit = 'ra.validation.required'
  }
  return errors
}

const EditTitle = (props: DynamicPropertyDefinitionTitleProps) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.dynamic-counters.titles.edit')}: {record?.id}.{' '}
      {record?.name}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton redirect={() => '/dynamic-counters'} />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const DynamicCounterEdit = (props: EditProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Edit {...props} title={<EditTitle />} actions={false}>
      <SimpleForm
        redirect="show"
        validate={validateDynamicCounterEdit}
        toolbar={<EditToolbar />}
      >
        <TextField source="id" label="ID" />
        <TextInput source="name" />
        <BooleanInput source="active" />
        <TextInput source="sqlQuery" fullWidth multiline />
        <NumberInput source="state" />
        <NumberInput source="limit" />
        {hasAuthority(Authority.VIEW_SCHEMES) && (
          <FilteredReferenceInput
            source="schemeId"
            reference={ResourceName.SCHEMES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            allowEmpty
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
      </SimpleForm>
    </Edit>
  )
}
