import { DataProvider, Record } from 'react-admin'

const fakeVerifierDeviceDisabled: Record[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeVerifierDeviceDisabled,
      total: fakeVerifierDeviceDisabled.length,
    }),
} as DataProvider

export default provider
