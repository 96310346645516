import { HttpError } from 'react-admin'
import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_ERROR, SHOW_API_ERROR_MODAL } from '../constants/reducer.actions'
import { ApiErrorAction } from './api-error-modal.reducer'

export interface ShowApiErrorAction {
  readonly type: typeof SHOW_API_ERROR_MODAL
  readonly payload?: HttpError
}

export const showApiError = (payload?: HttpError): ShowApiErrorAction => ({
  type: SHOW_API_ERROR_MODAL,
  payload,
})

export default function* apiErrorHandlerSaga() {
  yield takeEvery(FETCH_ERROR, function* showError(action) {
    yield put(showApiError((action as ApiErrorAction).error))
  })
}
