import {
  AccountBalanceWallet,
  ArrowForward,
  CenterFocusStrong,
  PhoneAndroid,
  Storage,
} from '@material-ui/icons'
import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  Filter,
  ListProps,
  Pagination,
  ReferenceField,
  TextField,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import List from '../../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../../common/FilteredReferenceInput'
import FilterProps from '../../../common/FilterProps'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { DeviceBaseWithTypeInfoDto } from '../../../../core/dto/device/common/device-base/device-base-with-type-info.dto'
import { DeviceTypes } from '../../../../core/enum/DeviceTypes'

const VerifierSchemeDataFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Filter {...props}>
      {hasAuthority(Authority.VIEW_SCHEMES) && (
        <FilteredReferenceInput
          source="schemeId"
          reference={ResourceName.SCHEMES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="translatedLogSchemeDataText"
          selectWithPaginationInputProps={{
            optionText: 'translatedLogSchemeDataHierarchyString',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_VERIFIER_DEVICES) && (
        <FilteredReferenceInput
          source="verifierDeviceId"
          reference={ResourceName.VERIFIER_DEVICES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
            rowIcon: (record: DeviceBaseWithTypeInfoDto) => {
              switch (record?.type) {
                case DeviceTypes.ENGINE:
                  return <AccountBalanceWallet />
                case DeviceTypes.PALM:
                  return <PhoneAndroid />
                case DeviceTypes.DETECTOR:
                  return <CenterFocusStrong />
                case DeviceTypes.PASSAGE:
                  return <ArrowForward />
                case DeviceTypes.OFFLINE_SERVER:
                  return <Storage />
                default:
                  return <div />
              }
            },
          }}
        />
      )}
    </Filter>
  )
}

export const VerifierSchemeDataList = ({ ...props }: ListProps) => {
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const hasAuthority = useHasAuthority()

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
      filters={<VerifierSchemeDataFilters />}
      bulkActionButtons={false}
      title="resources.verifier-scheme-data.titles.list"
    >
      <Datagrid
        size="small"
        rowClick={(id) => `/${ResourceName.VERIFIER_SCHEME_DATA}/${id}/show`}
      >
        {!xSmallScreen && <TextField source="id" label="ID" />}
        {hasAuthority(Authority.VIEW_SCHEMES) && (
          <ReferenceField
            source="schemeId"
            reference={ResourceName.SCHEMES}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {hasAuthority(Authority.VIEW_VERIFIER_DEVICES) && (
          <ReferenceField
            source="verifierDeviceId"
            reference={ResourceName.VERIFIER_DEVICES}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        )}
      </Datagrid>
    </List>
  )
}
