import { Box, Divider, Grid } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { ResourceName } from '../../core/ResourceName'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { EventDto } from '../../core/dto/event/event.dto'
import { BlockDto } from '../../core/dto/block.dto'
import { VariantDto } from '../../core/dto/variant.dto'

const ShowTitle = ({ ...props }) => {
  const { record: serviceLink } = props
  const translate = useTranslate()

  return (
    <span>
      {translate('resources.service-link.titles.show')}:{' '}
      {serviceLink?.serviceId}
    </span>
  )
}

const GeneralActions = () => {
  const { record } = useShowContext()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <EditButton
              basePath={`/${ResourceName.SERVICE_LINK}`}
              record={record}
              variant="contained"
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_SERVICE_LINK)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export const ServiceLinkShow = (props: ShowProps) => {
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()

  return (
    <Show {...props} actions={false} title={<ShowTitle />}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="serviceId" />
        <FunctionField
          source="serviceType"
          render={(record) =>
            translate(`resources.enums.serviceType.${record?.serviceType}`)
          }
        />
        <TextField source="apiUrl" />
        {hasAuthority(Authority.VIEW_EVENTS) && (
          <ReferenceField
            source="eventId"
            reference={ResourceName.EVENTS}
            link="show"
          >
            <FunctionField<EventDto>
              source="name"
              render={(event?: EventDto) => `${event?.id}. ${event?.name}`}
            />
          </ReferenceField>
        )}
        {hasAuthority(Authority.VIEW_BLOCKS) && (
          <ReferenceField
            source="blockId"
            reference={ResourceName.BLOCKS}
            link="show"
          >
            <FunctionField<BlockDto>
              source="name"
              render={(block?: BlockDto) => `${block?.id}. ${block?.name}`}
            />
          </ReferenceField>
        )}
        {hasAuthority(Authority.VIEW_CUSTOMERS) && (
          <ReferenceField
            source="customerId"
            reference={ResourceName.CUSTOMERS}
            link="show"
          >
            <FunctionField
              render={(record) =>
                `${record?.id}. ${record?.firstname} ${record?.lastname}`
              }
            />
          </ReferenceField>
        )}
        {hasAuthority(Authority.VIEW_VARIANTS) && (
          <ReferenceField
            source="variantId"
            reference={ResourceName.VARIANTS}
            link="show"
          >
            <FunctionField<VariantDto>
              source="name"
              render={(variant?: VariantDto) =>
                `${variant?.id}. ${variant?.name}`
              }
            />
          </ReferenceField>
        )}
        <GeneralActions />
      </SimpleShowLayout>
    </Show>
  )
}
