import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
  ReferenceField,
  FunctionField,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { CameraDto } from '../../../../core/dto/device/camera/cameras/camera.dto'
import { OpenCameraPreviewDialogButton } from './CameraPreviewDialog'
import { CameraServerDto } from '../../../../core/dto/device/camera/camera-servers/camera-server.dto'

interface CameraTitleProps extends TitleProps {
  record?: CameraDto
}

const ShowTitle = (data: CameraTitleProps) => {
  const { record: camera } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.cameras.titles.show')}: {camera?.id}.{' '}
      {camera?.description}
    </span>
  )
}

const GeneralActions = () => {
  const { record: camera } = useShowContext<CameraDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <OpenCameraPreviewDialogButton props={{ color: 'primary' }} />
        <EditButton
          basePath={`/${ResourceName.CAMERAS}`}
          record={camera}
          variant="contained"
          disabled={!hasAuthority(Authority.EDIT_CAMERAS)}
        />
      </Box>
    </>
  )
}

const CameraTabs = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.cameras.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="description" />
        <TextField source="serverSideName" />
        {hasAuthority(Authority.VIEW_CAMERA_SERVERS) && (
          <ReferenceField
            source="serverId"
            link="show"
            reference={ResourceName.CAMERA_SERVERS}
          >
            <FunctionField<CameraServerDto>
              source="name"
              render={(server?: CameraServerDto) =>
                `${server?.id}. ${server?.name}`
              }
            />
          </ReferenceField>
        )}
        <GeneralActions />
      </Tab>
    </TabbedShowLayout>
  )
}

export const CameraShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <CameraTabs />
  </Show>
)
