export enum ToggleType {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
}

export type ToggleButtonState = {
  type: ToggleType
  text: string
}

export type ToggleButtonAction = { type: ToggleType }
