import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyResult,
  GetManyParams,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
  Identifier,
} from 'react-admin'
import { ADMINISTRATOR_URL } from '../../api-urls'
import { get, getWithPagination, patch, post } from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { CompanyDto } from '../../dto/company.dto'
import filterMapper from './company-filter.mapper'
import { mapSortCompanyParam } from './company-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<CompanyDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortCompanyParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<CompanyDto[]>(
      `${ADMINISTRATOR_URL}/GetCompaniesByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<CompanyDto>> => {
    const data = await get<CompanyDto>(
      `${ADMINISTRATOR_URL}/GetCompanyById/`,
      id as string,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<CompanyDto>> => {
    const data = await get<CompanyDto[]>(
      ADMINISTRATOR_URL,
      `/GetCompaniesByConditions/c=>new int[] {${ids.toString()}}.Contains(c.Id)`,
    )
    return Promise.resolve({ data })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CompanyDto>,
  ): Promise<CreateResult> => {
    const created = await post<CreateCompanyRequest, CompanyDto>(
      `${ADMINISTRATOR_URL}/PostCompany`,
      data,
    )
    return Promise.resolve({ data: created })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<CompanyDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      CompanyDto
    >(`${ADMINISTRATOR_URL}/PatchCompany`, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
    })
    return Promise.resolve({ data: update })
  },
} as DataProvider

export interface CreateCompanyRequest {
  readonly name: string
}

export default provider
