import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  TitleProps,
  useTranslate,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  ShowButton,
  TextField,
} from 'react-admin'
import { EntranceDto } from '../../../core/dto/entrance.dto'

const validateEntranceEdit = (values: EntranceDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.note) {
    errors.note = 'ra.validation.required'
  }
  return errors
}

interface EntranceTitleProps extends TitleProps {
  record?: EntranceDto
}

const EditTitle = (data: EntranceTitleProps) => {
  const { record: entrance } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.entrances.titles.edit')}: {entrance?.id}.{' '}
      {entrance?.note}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const EntranceEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <SimpleForm
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateEntranceEdit}
    >
      <TextField source="id" label="ID" />
      <TextInput source="note" isRequired />
    </SimpleForm>
  </Edit>
)
