import { Chip } from '@material-ui/core'
import React from 'react'
import { useTranslate } from 'react-admin'
import { ResourceName } from '../../core/ResourceName'

interface QuickFilterProps {
  label?: string
  resource?: keyof ResourceName
  source: string
  defaultValue?: any
  alwaysOn?: boolean
}

const QuickFilter = (props: QuickFilterProps) => {
  const translate = useTranslate()
  const { label, resource, source, defaultValue, alwaysOn } = props // eslint-disable-line @typescript-eslint/no-unused-vars
  return (
    <Chip
      style={{ marginBottom: '1em' }}
      label={translate(
        `resources.${resource as string}.fields.${label || source}`,
      )}
    />
  )
}

export default QuickFilter
