import React, { useState } from 'react'
import { useShowContext, useRefresh } from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import terminalProvider from '../../../../core/devices/terminals/terminals.provider'
import { TerminalDto } from '../../../../core/dto/device/terminals/terminal.dto'
import { OpenForOneVehicleDialog } from '../TerminalDialogs'

export const TerminalOpeningButton = () => {
  const { loading, record: terminal } = useShowContext<TerminalDto>()
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [note, setNote] = useState<string>()
  const hasAuthority = useHasAuthority()
  const refresh = useRefresh()

  const handleOpenDialog = () => {
    setNoteDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setNote(undefined)
    setNoteDialogOpen(false)
  }

  const handleOpenGate = async () => {
    await terminalProvider.openForSingleVehicle(ResourceName.TERMINALS, {
      terminalId: terminal?.id as number,
      note: note as string,
    })
    refresh()
  }

  return (
    <>
      <OpenForOneVehicleDialog
        open={noteDialogOpen}
        close={handleCloseDialog}
        note={note}
        setNote={setNote}
        handleOpen={handleOpenGate}
      />
      <Button
        size="small"
        variant="contained"
        color="primary"
        label="resources.terminals.actions.openForOneVehicle"
        useSmallVersionBreakpoint={false}
        onClick={handleOpenDialog}
        disabled={
          !hasAuthority(Authority.TERMINALS_OPEN_FOR_SINGLE_VEHICLE) || loading
        }
      />
    </>
  )
}
