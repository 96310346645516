import { ExtendedLog } from '../dto/extended-log'
import { ExtendedLogDto } from '../dto/extended-log.dto'

export const mapToExtendedLog = (
  {
    id,
    date,
    ticketCode,
    bookingId,
    scheme,
    device: { name: deviceName, camera },
    data,
  }: ExtendedLogDto,
  locale: string,
): ExtendedLog => {
  const logEntry = data.filter(({ language }) => language.includes(locale))[0]
  return {
    id,
    date,
    deviceName,
    ticketCode,
    text: logEntry?.text || scheme,
    color: data[0].color || 'transparent',
    bookingId,
    cameraId: camera?.id,
    cameraDescription: camera?.description,
    cameraType: camera?.type,
    serverId: camera?.serverId,
    scheme,
  }
}
