import { DataProvider } from 'react-admin'
import { TSCanDto } from '../../dto/device/ts-cans/ts-can.dto'

const fakeTSCans: TSCanDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeTSCans, total: fakeTSCans.length }),
} as DataProvider

export default provider
