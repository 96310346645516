export enum ToggleType {
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  DISABLED = 'DISABLED',
}

export type ToggleButtonState = {
  type: ToggleType
  text: string
}

export type ToggleButtonAction = { type: ToggleType }
