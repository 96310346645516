import { GetApp as Download } from '@material-ui/icons'
import React from 'react'
import {
  GET_ONE,
  useMutation,
  useNotify,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { EventDto } from '../../../core/dto/event/event.dto'

export const TechnicalAssistanceProtocolButton = ({ ...props }) => {
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { record: event, loading } = useShowContext<EventDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  const handleTechnicalAssistanceProtocol = async () => {
    const {
      data: {
        blob: { body, headers },
      },
    } = await mutate(
      {
        type: GET_ONE,
        resource: ResourceName.TECHNICAL_ASSISTANCE_PROTOCOL,
        payload: {
          id: event?.id,
        },
      },
      {
        returnPromise: true,
        onFailure: (msg) => notify(msg, 'error'),
      },
    )

    const fileName = decodeURIComponent(
      headers?.get('Content-Disposition')?.split("filename*=UTF-8''")[1],
    )

    const objectUrl: string = URL.createObjectURL(body)
    const a: HTMLAnchorElement = document.createElement(
      'a',
    ) as HTMLAnchorElement

    a.href = objectUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(objectUrl)

    refresh()
  }

  return (
    <Button
      onClick={handleTechnicalAssistanceProtocol}
      label="resources.events.actions.technical-assistance-protocol"
      variant="contained"
      color="primary"
      startIcon={<Download />}
      {...props}
      useSmallVersionBreakpoint={false}
      disabled={
        !hasAuthority(Authority.VIEW_TECHNICAL_ASSISTANCE_PROTOCOL) ||
        loading ||
        mutationLoading
      }
      size="small"
    />
  )
}
