import React from 'react'
import {
  ListProps,
  Pagination,
  Filter,
  TextField,
  TextInput,
  Datagrid,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'

const EntranceFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="note" alwaysOn />
  </Filter>
)

export const EntranceList = (props: ListProps) => (
  <List
    {...props}
    exporter={false}
    filters={<EntranceFilters />}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    bulkActionButtons={false}
  >
    <Datagrid
      size="small"
      rowClick={(id) => `${ResourceName.ENTRANCES}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="note" />
    </Datagrid>
  </List>
)
