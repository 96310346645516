import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  FunctionField,
  ListButton,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { DynamicPropertyDefinitionBaseDTO } from '../../../core/dto/dynamic-property-definition.dto'
import { DynamicPropertyDataTypes } from '../../../core/enum/DynamicPropertyDataTypes'

interface DynamicPropertyDefinitionTitleProps extends TitleProps {
  record?: DynamicPropertyDefinitionBaseDTO
}

const validateDynamicPropertyDefinitionEdit = (
  values: DynamicPropertyDefinitionBaseDTO,
) => {
  const errors: { [n: string]: string } = {}
  if (!values.key) {
    errors.key = 'ra.validation.required'
  }
  if (!values.type) {
    errors.type = 'ra.validation.required'
  }
  return errors
}

const EditTitle = (props: DynamicPropertyDefinitionTitleProps) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.dynamic-property-definitions.titles.edit')}:{' '}
      {record?.id}. {record?.key}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton redirect={() => '/dynamic-property-definitions'} />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const DynamicPropertyDefinitionEdit = (props: EditProps) => {
  const translate = useTranslate()

  return (
    <Edit {...props} title={<EditTitle />} actions={false}>
      <SimpleForm
        redirect="show"
        validate={validateDynamicPropertyDefinitionEdit}
        toolbar={<EditToolbar />}
      >
        <TextField source="id" label="ID" />
        <FunctionField
          source="definitionOwnerType"
          render={(record) =>
            translate(
              `resources.enums.DefinitionOwnerTypes.${record?.definitionOwnerType}`,
            )
          }
        />
        <TextInput source="key" isRequired />
        <SelectInput
          source="type"
          choices={Object.keys(DynamicPropertyDataTypes).map((key: string) => ({
            id: key,
            name: translate(`resources.enums.DynamicPropertyDataTypes.${key}`),
          }))}
          isRequired
        />
      </SimpleForm>
    </Edit>
  )
}
