import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  DateField,
  Edit,
  EditProps,
  ReferenceField,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useEditContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DepositorDto } from '../../../core/dto/device/depositor/depositor.dto'
import { DeviceDirection } from '../../../core/enum/DeviceDirection'
import { Multiplicities } from '../../../core/enum/Multiplicities'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const validateDepositorEdit = (values: DepositorDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.address) {
    errors.address = 'ra.validation.required'
  }
  return errors
}

interface DepositorTitleProps extends TitleProps {
  record?: DepositorDto
}

const EditTitle = (data: DepositorTitleProps) => {
  const { record } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.depositors.titles.edit')}: {record?.id}.{' '}
      {record?.name}{' '}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const Depositor = ({ ...props }) => {
  const { record: depositor } = useEditContext<DepositorDto>()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <SimpleForm
      {...props}
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateDepositorEdit}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" isRequired />
      <BooleanInput source="isWatched" />
      <DateField
        source="lastRefresh"
        showTime
        options={{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
      />
      <TextInput source="address" isRequired />
      <SelectInput
        source="direction"
        choices={Object.entries(DeviceDirection).map(([value]) => ({
          id: value,
          name: `resources.enums.deviceDirection.${value}`,
        }))}
      />
      {hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
        <FilteredReferenceInput
          source="categoryId"
          defaultValue={null}
          reference={ResourceName.DEVICE_CATEGORIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'hierarchyString',
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_CAMERAS) && (
        <FilteredReferenceInput
          source="cameraId"
          defaultValue={null}
          reference={ResourceName.CAMERAS}
          sort={{ field: 'description', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'description',
            showFilter: true,
          }}
        />
      )}
      {depositor?.disabledId && (
        <ReferenceField
          source="disabledId"
          label="resources.depositors.fields.disabled"
          reference={ResourceName.VERIFIER_DEVICE_DISABLED}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      <BooleanInput source="onlineInfixCeck" />
      <SelectInput
        source="multiplicity"
        choices={Object.entries(Multiplicities).map(([value]) => ({
          id: value,
          name: `resources.enums.multiplicities.${value}`,
        }))}
      />
      {hasAuthority(Authority.VIEW_PALMS) && (
        <FilteredReferenceInput
          source="supervisorId"
          defaultValue={null}
          reference={ResourceName.PALMS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="name"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </SimpleForm>
  )
}

export const DepositorEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <Depositor />
  </Edit>
)
