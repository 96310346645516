import { Autorenew } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  useShowContext,
  useRefresh,
  sanitizeFetchType,
  useNotify,
  useMutation,
  useTranslate,
} from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { SetStandbyModeDialog } from '../EngineDialogs'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { EngineDto } from '../../../../core/dto/device/engine/engine.dto'
import { StanbyMode } from '../../../../core/enum/StanbyMode'
import engineProvider from '../../../../core/devices/engines/engines.provider'

export const EngineStandbyModeButton = ({ ...props }) => {
  const { asRecurringJob } = props
  const { loading, record: engine } = useShowContext<EngineDto>()
  const translate = useTranslate()
  const notify = useNotify()
  const [standbyModeDialogOpen, setStandbyModeDialogOpen] = useState(false)
  const [standbyMode, setStandbyMode] = useState<StanbyMode | undefined>(
    engine?.stanbyMode || undefined,
  )
  const hasAuthority = useHasAuthority()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  const handleSetStandbyModeDialog = () => {
    setStandbyModeDialogOpen(true)
  }

  const handleStandbyMode = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (!asRecurringJob)
      await mutate(
        {
          type: sanitizeFetchType('setStandbyMode'),
          resource: ResourceName.ENGINES,
          payload: {
            engineId: engine?.id,
            stanbyMode: standbyMode,
          },
        },
        {
          returnPromise: true,
          onFailure: (err) => notify(err?.message, 'error'),
        },
      )
    else
      await engineProvider.setStandbyMode(ResourceName.EVENTS, {
        engineId: engine?.id as number,
        stanbyMode: standbyMode as StanbyMode,
        recurringJobName,
        cronExpression,
      })
    refresh()
  }

  return (
    <>
      <SetStandbyModeDialog
        open={standbyModeDialogOpen}
        close={() => setStandbyModeDialogOpen(false)}
        standbyMode={standbyMode}
        setStandbyMode={setStandbyMode}
        handleStandbyMode={handleStandbyMode}
        asRecurringJob={asRecurringJob}
      />
      <Button
        size="small"
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        label={
          !asRecurringJob
            ? 'resources.engines.actions.setStandbyMode'
            : translate('common.recurring-job.buttons.create-recurring-job')
        }
        useSmallVersionBreakpoint={false}
        onClick={handleSetStandbyModeDialog}
        disabled={
          !hasAuthority(Authority.ENGINES_SET_STANDBY_MODE_BUTTON) ||
          loading ||
          mutationLoading
        }
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
      />
    </>
  )
}
