import { isEqual } from 'lodash'
import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { VERIFIER_DEVICES_SCHEME_URL } from '../api-urls'
import { get, getWithPagination, patch, post } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import filterMapper from './verifier-scheme-data-filter.mapper'
import { mapSortVerifierSchemeDataParam } from './verifier-scheme-data-sort.mapper'
import { VerifierSchemeDataDto } from '../dto/verifier-scheme-data.dto'
import { CacheableDataProviderExtensionResult } from '../common/data-provider'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<VerifierSchemeDataDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortVerifierSchemeDataParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<VerifierSchemeDataDto[]>(
      `${VERIFIER_DEVICES_SCHEME_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<GetVerifierSchemeDataResponse>> => {
    const data = await get<VerifierSchemeDataDto>(
      `${VERIFIER_DEVICES_SCHEME_URL}`,
      `/${id}`,
    )
    const moduleSchemeMappings = data?.moduleSchemeMappingsJson
      ? Object.entries(JSON.parse(data?.moduleSchemeMappingsJson || '{}')).map(
          ([key, values]) => ({
            key,
            values: (values as string[])?.map((value) => ({ value })),
          }),
        )
      : undefined
    return Promise.resolve({
      data: {
        ...data,
        moduleSchemeMappings: moduleSchemeMappings as any,
      },
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<GetVerifierSchemeDataResponse>,
  ): Promise<UpdateResult> => {
    let moduleSchemeMappingsJson = data?.moduleSchemeMappingsJson
    if (
      !isEqual(data?.moduleSchemeMappings, previousData?.moduleSchemeMappings)
    ) {
      const { moduleSchemeMappings } = data
      moduleSchemeMappingsJson = JSON.stringify(
        Object.fromEntries(
          moduleSchemeMappings?.map((mapping) => [
            mapping?.key,
            mapping?.values?.map(({ value }) => parseInt(value as any, 10)),
          ]),
        ),
      )
    }
    const update = await patch<
      { id: Identifier; [n: string]: any },
      VerifierSchemeDataDto
    >(`${VERIFIER_DEVICES_SCHEME_URL}`, {
      id,
      moduleSchemeMappingsJson:
        moduleSchemeMappingsJson !== previousData.moduleSchemeMappingsJson
          ? moduleSchemeMappingsJson
          : undefined,
      schemeId:
        data.schemeId !== previousData.schemeId ? data.schemeId : undefined,
      verifierDeviceId:
        data.verifierDeviceId !== previousData.verifierDeviceId
          ? data.verifierDeviceId
          : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<VerifierSchemeDataCreateRequest>,
  ): Promise<CreateResult> => {
    const created = await post<
      VerifierSchemeDataCreateRequest[],
      VerifierSchemeDataDto[]
    >(VERIFIER_DEVICES_SCHEME_URL, [data])
    return Promise.resolve({ data: created[0] })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<VerifierSchemeDataDto>> => {
    const data = await get<VerifierSchemeDataDto[]>(
      VERIFIER_DEVICES_SCHEME_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  getVerifierTSCanModules: async (
    resource: string,
    { id },
  ): Promise<CacheableDataProviderExtensionResult<TSCanModule[]>> => {
    const modules = await get<TSCanModule[]>(
      `${VERIFIER_DEVICES_SCHEME_URL}`,
      `/GetVerifierTSCanModules/${id}`,
    )
    return Promise.resolve({ data: modules })
  },
} as VerifierSchemeDataProvider

interface VerifierSchemeDataProvider extends DataProvider {
  getVerifierTSCanModules: (
    resource: string,
    params: { id: number },
  ) => Promise<CacheableDataProviderExtensionResult<TSCanModule[]>>
}

interface VerifierSchemeDataCreateRequest {
  readonly moduleSchemeMappingsJson: string
  readonly schemeId: number
}

export interface TSCanModule {
  tsCanModuleName: string
  tsCanModuleTypeId: number
  tsCanModuleAddress: string
  tsCanModuleLacanAddress: string
}

export interface ModuleSchemeMapping {
  key: number
  values: { value: number }[]
}

export interface GetVerifierSchemeDataResponse {
  readonly id: number
  readonly moduleSchemeMappingsJson: string
  readonly moduleSchemeMappings: ModuleSchemeMapping[]
  readonly schemeId: number
  readonly verifierDeviceId: number
}

export default provider
