import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { RoleDto } from '../../../core/dto/role/role.dto'

interface RoleTitleProps extends TitleProps {
  record?: RoleDto
}

const validateRoleEdit = (values: RoleDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

const EditTitle = (props: RoleTitleProps) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.roles.titles.edit')}: {record?.id}. {record?.name}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const RoleEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />} actions={false}>
    <SimpleForm
      redirect="show"
      validate={validateRoleEdit}
      toolbar={<EditToolbar />}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" isRequired />
      <TextInput source="description" fullWidth multiline />
    </SimpleForm>
  </Edit>
)
