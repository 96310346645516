import {
  Dialog,
  DialogActions,
  makeStyles,
  Typography,
  IconButton,
  TextField,
  DialogContent,
} from '@material-ui/core'
import { Close, Power, PowerOff } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import DraggableComponent from '../../common/DraggableComponent'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import { PassageDirections } from '../../../core/enum/PassageDirections'
import { AuditCommentActionDialog } from '../../common/AuditCommentDialog'

const useStyles = makeStyles({
  bulkProgressTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  actionsButton: {
    margin: '2%',
    width: '96%',
  },
})

export const PassageSelectStateDialog = ({
  open,
  close,
  state,
  setState,
  confirm,
  method,
  asRecurringJob,
  OnIcon,
  OffIcon,
  withAuditNote,
}: {
  open: boolean
  close: () => void
  state: boolean
  setState: (value: boolean) => void
  confirm: (
    recurringJobName?: string,
    cronExpression?: string,
    auditNote?: string,
  ) => void
  method: string
  asRecurringJob?: boolean
  OnIcon?: React.ReactElement
  OffIcon?: React.ReactElement
  withAuditNote?: boolean
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()
  const classes = useStyles()

  const handleClose = () => {
    setState(false)
    setConfirmOpen(false)
    close()
  }

  const handleConfirm = (
    recurringJobName?: string,
    cronExpression?: string,
    auditNote?: string,
  ) => {
    if (asRecurringJob) confirm(recurringJobName, cronExpression, auditNote)
    else confirm(undefined, undefined, auditNote)
    handleClose()
  }

  const handleOpenConfirm = (value: boolean) => {
    setState(value)
    setConfirmOpen(true)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      {!withAuditNote ? (
        <ConfirmDialog
          open={confirmOpen}
          cancel={() => setConfirmOpen(false)}
          confirm={handleConfirm}
          source={method}
          action={state ? 'set' : 'remove'}
          resource={ResourceName.PASSAGES}
          asRecurringJob={asRecurringJob}
        />
      ) : (
        <AuditCommentActionDialog
          open={confirmOpen}
          handleClose={() => setConfirmOpen(false)}
          mutate={handleConfirm}
          asRecurringJob={asRecurringJob}
        />
      )}
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(`resources.passages.dialogs.${method}.title`)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleOpenConfirm(false)}
          label={translate('const.turnOff')}
          useSmallVersionBreakpoint={false}
          endIcon={OffIcon ?? <PowerOff />}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={() => handleOpenConfirm(true)}
          label={translate('const.turnOn')}
          useSmallVersionBreakpoint={false}
          endIcon={OnIcon ?? <Power />}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export const PassageSetOpenDialog = ({ ...props }) => {
  const { open, handleOpenPassage, handleClose, direction, asRecurringJob } =
    props
  const [timeInSeconds, setTimeInSeconds] = useState<number>(0)
  const translate = useTranslate()
  const classes = useStyles()

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  const handleConfirm = () => {
    handleOpenPassage(timeInSeconds)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleConfirm}
        source="setOpen"
        action={direction === PassageDirections.IN ? 'set' : 'remove'}
        resource={ResourceName.PASSAGES}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(`resources.passages.dialogs.setOpen.title.${direction}`)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <TextField
          variant="outlined"
          value={timeInSeconds}
          onChange={(e) => setTimeInSeconds(parseInt(e?.target?.value, 10))}
          fullWidth
          label={translate('resources.passages.fields.timeInSeconds')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
          disabled={!timeInSeconds || timeInSeconds === 0}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}
