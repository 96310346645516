import { Box, Divider, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ListProps,
  Pagination,
  Record,
  ReferenceField,
  ResourceContextProvider,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutProps,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { CustomerDto } from '../../core/dto/customer.dto'
import { EventDto } from '../../core/dto/event/event.dto'
import { ResourceName } from '../../core/ResourceName'
import { MobilePostPagination } from '../common/Pagination'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { LogList } from '../logs/LogList'
import { VariantDto } from '../../core/dto/variant.dto'

const ShowTitle = (data: CustomerTitleProps) => {
  const { record: customer } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.customers.titles.show')}: {customer?.id}.{' '}
      {customer?.firstname} {customer?.lastname}
    </span>
  )
}

const GeneralActions = () => {
  const { record: customer } = useShowContext<CustomerDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath={`/${ResourceName.CUSTOMERS}`}
          record={customer}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_CUSTOMERS)}
        />
      </Box>
    </>
  )
}

export const CustomerDetailsTab = ({
  displayActions = true,
  ...props
}: CustomerDetailsTabProps) => {
  const { record: customer } = useShowContext<CustomerDto>()
  const translate = useTranslate()

  return (
    <SimpleShowLayout {...props}>
      <TextField source="firstname" />
      <TextField source="lastname" />
      {customer?.company && <TextField source="company" />}
      {customer?.birthdate && (
        <DateField
          source="birthdate"
          options={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
        />
      )}
      {customer?.documentType && (
        <FunctionField
          source="documentType"
          render={(r: Record | undefined) => (
            <div>
              {translate(
                `resources.enums.customerDocumentType.${r?.documentType}`,
              )}
            </div>
          )}
        />
      )}
      {customer?.pesel && <TextField source="pesel" />}
      {customer?.documentNumber && <TextField source="documentNumber" />}
      {customer?.nationality && <TextField source="nationality" />}
      {customer?.gender && (
        <FunctionField
          source="gender"
          render={(r: Record | undefined) => (
            <div>{translate(`resources.enums.gender.${r?.gender}`)}</div>
          )}
        />
      )}
      {displayActions && <GeneralActions />}
    </SimpleShowLayout>
  )
}

const BookingsList = (props: ListProps) => {
  const theme = useTheme()
  const hasAuthority = useHasAuthority()

  return (
    <List
      {...props}
      perPage={20}
      exporter={false}
      pagination={
        useMediaQuery(theme.breakpoints.up('md')) ? (
          <Pagination rowsPerPageOptions={[20, 50, 100]} />
        ) : (
          <MobilePostPagination />
        )
      }
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={(id) => `/${ResourceName.BOOKINGS}/${id}/show`}>
        <TextField source="id" />
        {hasAuthority(Authority.VIEW_EVENTS) && (
          <ReferenceField
            source="eventId"
            reference={ResourceName.EVENTS}
            link={false}
          >
            <FunctionField<EventDto>
              render={() => (
                <div>
                  {' '}
                  <TextField source="id" />. <TextField source="name" /> (
                  <DateField source="dateStart" />){' '}
                </div>
              )}
            />
          </ReferenceField>
        )}
        {hasAuthority(Authority.VIEW_VARIANTS) && (
          <ReferenceField
            reference={ResourceName.VARIANTS}
            source="variantId"
            link={false}
          >
            <FunctionField<VariantDto>
              source="name"
              render={(variant?: VariantDto) =>
                `${variant?.id}. ${variant?.name}`
              }
            />
          </ReferenceField>
        )}
      </Datagrid>
    </List>
  )
}

export const BookingsTab = () => {
  const { record: customer, basePath } = useShowContext<CustomerDto>()

  return (
    <ResourceContextProvider value={ResourceName.BOOKINGS}>
      <BookingsList
        basePath={basePath}
        filter={{ customerId: customer?.id }}
        filters={undefined}
        actions={false}
        title=" "
      />
    </ResourceContextProvider>
  )
}

export const LogsTab = () => {
  const { record: customer, basePath } = useShowContext<CustomerDto>()
  return (
    <ResourceContextProvider value={ResourceName.LOGS}>
      <LogList
        basePath={basePath}
        filter={{ customerId: customer?.id }}
        filters={undefined}
        actions={false}
        title=" "
      />
    </ResourceContextProvider>
  )
}

const CustomerTabs = ({ ...props }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  return (
    <TabbedShowLayout {...props}>
      <Tab label={translate('resources.customers.tabs.profile')}>
        <CustomerDetailsTab displayActions />
      </Tab>
      {hasAuthority(Authority.VIEW_BOOKINGS) && (
        <Tab
          path="bookings"
          label={translate('resources.customers.tabs.bookings')}
        >
          <BookingsTab />
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_LOGS) && (
        <Tab path="logs" label={translate('resources.customers.tabs.logs')}>
          <LogsTab />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const CustomerDetailsView = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <CustomerTabs />
  </Show>
)

export interface CustomerDetailsTabProps
  extends Omit<TabbedShowLayoutProps, 'children'> {
  displayActions: boolean
}

interface CustomerTitleProps extends TitleProps {
  record?: CustomerDto
}
