const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  filterRoleId: () => null,
  roleId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterRoleId === false
      ? null
      : `${lambdaParameter}.Roles.Any(u=>u.Id.Equals(${value}))`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
