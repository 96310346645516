import { DataProvider } from 'react-admin'
import { PalmSchemeDataDto } from '../../dto/palm-scheme-data.dto'

const fakePalmSchemeData: PalmSchemeDataDto[] = [
  {
    id: 1,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 2,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 3,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 4,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 5,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 6,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 7,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 8,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 9,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
  {
    id: 10,
    language: 'en',
    text: 'Lorem ipsum dolor sit amet',
    backgroundColor: '#fff000',
    backgroundImage: '',
    sound: '',
    positiveValidation: false,
    schemeId: 1,
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakePalmSchemeData,
      total: fakePalmSchemeData.length,
    }),
} as DataProvider

export default provider
