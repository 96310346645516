import { DEVICE_CATEGORIES_URL } from '../api-urls'
import { get, getByConditions } from '../common/fetch.utils'
import { filterParamsComposer } from '../common/get-by-conditions.utils'
import deviceCategoryfilterMapper from '../device-categories/device-category-filter.mapper'
import { DeviceCategoryDto } from '../dto/device-category/device-category.dto'

// Tree type filter for device categories
const getDeviceCategoryChildren = async (categoryId: number) => {
  let categoriesIds: number[] = []
  // eslint-disable-next-line no-await-in-loop
  const selectedCategory = await get<DeviceCategoryDto>(
    DEVICE_CATEGORIES_URL,
    `/${categoryId}`,
  )
  categoriesIds.push(selectedCategory?.id)
  let resolveParents = true
  while (resolveParents) {
    const parentCategoriesfilters = { parentIds: categoriesIds }
    // eslint-disable-next-line no-await-in-loop
    const childrenCategories = await getByConditions<DeviceCategoryDto>(
      `${DEVICE_CATEGORIES_URL}`,
      `o=>${filterParamsComposer(
        'o',
        parentCategoriesfilters,
        deviceCategoryfilterMapper,
      )}`,
    )
    const newChildren = childrenCategories
      .map((cc) => cc.id)
      // eslint-disable-next-line no-loop-func
      .filter((cc) => !categoriesIds.includes(cc))
    if (newChildren.length === 0) {
      resolveParents = false
    } else {
      categoriesIds = categoriesIds.concat(newChildren)
    }
  }
  return categoriesIds
}

export { getDeviceCategoryChildren }
