import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  ListProps,
  Pagination,
  TextField,
  TextInput,
  Filter,
  Datagrid,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import FilterProps from '../../common/FilterProps'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import List from '../../common/customized-ra-components/List'

const CompanyFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      {hasAuthority(Authority.VIEW_EVENTS) && (
        <FilteredReferenceInput
          source="eventId"
          reference={ResourceName.EVENTS}
          sort={{ field: 'dateStart', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

export const CompanyList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    exporter={false}
    filters={<CompanyFilters />}
    bulkActionButtons={false}
  >
    <Datagrid
      size="small"
      rowClick={(id) => `${ResourceName.COMPANIES}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="name" />
    </Datagrid>
  </List>
)
