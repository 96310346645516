import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  ListProps,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { JobStatuses } from '../../../core/enum/JobStatuses'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { JobDetailsRaResponse } from '../../../core/jobs/jobs.provider'

const JobFilters = ({ ...filterProps }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Filter {...filterProps}>
      <TextInput
        key="jobTargetMethod"
        source="jobTargetMethod"
        alwaysOn
        resettable
      />
      <SelectInput
        source="jobStatus"
        choices={Object.values(JobStatuses).map((value) => ({
          id: value,
          name: `resources.enums.jobStatus.${value}`,
        }))}
      />
      {hasAuthority(Authority.VIEW_RECURRING_JOBS) && (
        <FilteredReferenceInput
          source="recurringJobParentId"
          reference={ResourceName.RECURRING_JOBS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

export const JobList = (props: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslate()

  return (
    <List
      exporter={false}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      filters={<JobFilters />}
      sort={{ field: 'jobCreationDate', order: 'DESC' }}
      {...props}
      bulkActionButtons={false}
    >
      <Datagrid
        padding={smallScreen ? 'checkbox' : 'default'}
        rowClick={(id) => `${ResourceName.JOBS}/${id}/show`}
      >
        {!smallScreen && <TextField source="jobId" label="Id" />}
        <FunctionField<JobDetailsRaResponse>
          source="jobStatus"
          render={(record?: JobDetailsRaResponse) =>
            translate(`resources.enums.jobStatus.${record?.jobStatus}`)
          }
        />
        <TextField source="jobTargetMethod" />
        <DateField source="jobCreationDate" showTime />
        <DateField source="jobExpirationDate" showTime />
      </Datagrid>
    </List>
  )
}
