import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  ListButton,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { ImportProperties } from '../../core/enum/ImportProperties'
import { Authority } from '../../core/auth/Authority'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { ResourceName } from '../../core/ResourceName'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { validateTicketImportMapping } from './TicketImportMappingCreate'

const EditTitle = () => {
  const translate = useTranslate()
  return (
    <span>{translate('resources.ticket-import-mappings.titles.edit')}</span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const TicketImportMappingEdit = (props: EditProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()

  return (
    <Edit {...props} title={<EditTitle />}>
      <SimpleForm
        redirect="list"
        validate={validateTicketImportMapping}
        toolbar={<EditToolbar />}
      >
        <TextField source="id" label="Id" />
        <SelectInput
          source="property"
          choices={Object.entries(ImportProperties).map(([value]) => ({
            id: value,
            name: `resources.enums.ImportProperties.${value}`,
          }))}
          isRequired
        />
        <TextInput source="csvPropertyName" isRequired />
        <TextInput source="staticValue" />
        {hasAuthority(Authority.VIEW_TICKET_IMPORT_TEMPLATES) && (
          <FilteredReferenceInput
            source="importTemplateId"
            defaultValue={null}
            reference={ResourceName.TICKET_IMPORT_TEMPLATES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="name"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
      </SimpleForm>
    </Edit>
  )
}
