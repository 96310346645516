import { Box, Divider, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  DateField,
  DeleteButton,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useTranslate,
  Pagination,
  Record,
  ReferenceManyField,
} from 'react-admin'
import ReactJson from 'react-json-view'
import { RecurringJobDetailsDto } from '../../../core/dto/recurring-job-details.dto'
import { RecurringJobRAResponse } from '../../../core/recurring-jobs/recurring-jobs.provider'
import { ResourceName } from '../../../core/ResourceName'
import { ExtendedTheme } from '../../layout/app.theme'
import { TriggerRecurringJobButton } from './recurring-job-show/TriggerRecurringJobButton'

interface RecurringJobTitleProps extends TitleProps {
  record?: RecurringJobRAResponse
}

const ShowTitle = (data: RecurringJobTitleProps) => {
  const { record: job } = data
  const translate = useTranslate()

  return (
    <span>
      {translate('resources.recurring-jobs.titles.show')}: {job?.name} {job?.id}
    </span>
  )
}

const GeneralActions = ({ ...props }) => (
  <>
    <Divider />
    <Box p={1} display="flex" justifyContent="flex-end">
      <TriggerRecurringJobButton {...props} />
      <DeleteButton {...props} />
    </Box>
  </>
)

const RecurringJobGeneralTab = ({ ...props }) => {
  const translate = useTranslate()
  const { record: job } = props
  const theme = useTheme<ExtendedTheme>()

  return (
    <Tab {...props} label={translate('resources.recurring-jobs.tabs.general')}>
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="cron" />
      <TextField
        source="jobParameters.method"
        label="resources.recurring-jobs.fields.jobTargetMethod"
      />
      {job?.error && <TextField source="error" />}
      {job?.lastExecution && <DateField source="lastExecution" showTime />}
      <DateField source="nextExecution" showTime />
      <DateField source="createdAt" showTime />
      {(job as RecurringJobDetailsDto)?.jobParameters?.jobPayload && (
        <>
          <ReactJson
            src={(job as RecurringJobDetailsDto)?.jobParameters?.jobPayload}
            theme={theme.palette.rjvTheme}
            iconStyle="triangle"
            name={translate('resources.recurring-jobs.fields.jobParameters')}
            style={{
              marginBottom: theme.spacing(1),
              padding: theme.spacing(1),
            }}
          />
        </>
      )}
      <GeneralActions />
    </Tab>
  )
}

const RecurringJobLastExecutionTab = ({ ...props }) => {
  const translate = useTranslate()
  const { record: job } = props
  const theme = useTheme<ExtendedTheme>()

  return (
    <Tab
      label={translate('resources.recurring-jobs.tabs.last-execution-details')}
      path="last-execution"
      {...props}
    >
      <TextField
        source="lastExecutionDetails.jobId"
        label="resources.recurring-jobs.fields.jobId"
      />
      <TextField
        source="lastExecutionDetails.jobStatus"
        label="resources.recurring-jobs.fields.jobStatus"
      />
      <DateField
        source="lastExecutionDetails.jobCreationDate"
        label="resources.recurring-jobs.fields.jobCreationDate"
        showTime
      />
      <DateField
        source="lastExecutionDetails.jobExpirationDate"
        label="resources.recurring-jobs.fields.jobExpirationDate"
        showTime
      />
      {job?.lastExecutionDetails?.jobResult && (
        <TextField
          source="lastExecutionDetails.jobResult.statusCode"
          label="resources.recurring-jobs.fields.statusCode"
        />
      )}
      {job?.lastExecutionDetails?.jobResult?.value && (
        <>
          <ReactJson
            src={job?.lastExecutionDetails?.jobResult?.value}
            theme={theme.palette.rjvTheme}
            iconStyle="triangle"
            name={translate(
              'resources.recurring-jobs.fields.lastExecutionJobResult',
            )}
            style={{
              marginBottom: theme.spacing(1),
              padding: theme.spacing(1),
            }}
          />
        </>
      )}
    </Tab>
  )
}

const RecurringJobOldExecutionTab = ({ ...props }) => {
  const translate = useTranslate()
  const theme = useTheme<ExtendedTheme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const ExecutionExpand = ({ ...expandProps }) => {
    const { record: job } = expandProps

    return (
      <ReactJson
        src={job?.jobResult.value}
        theme={theme.palette.rjvTheme}
        iconStyle="triangle"
        name={translate(
          'resources.recurring-jobs.fields.oldExecutionJobResult',
        )}
        style={{
          marginBottom: theme.spacing(1),
          padding: theme.spacing(1),
        }}
      />
    )
  }

  return (
    <Tab
      label={translate('resources.recurring-jobs.tabs.old-execution-details')}
      {...props}
      path="/old-execution"
    >
      <ReferenceManyField
        reference={ResourceName.JOBS}
        perPage={20}
        target="recurringJobParentId"
        sort={{
          field: 'jobCreationDate',
          order: 'DESC',
        }}
        addLabel={false}
        fullWidth
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      >
        <Datagrid
          padding={smallScreen ? 'checkbox' : 'default'}
          expand={ExecutionExpand}
          isRowExpandable={(record: Record) => record?.jobResult?.value}
          rowClick="show"
        >
          <TextField source="jobId" />
          <TextField source="jobStatus" />
          <TextField source="jobTargetMethod" />
          <DateField source="jobCreationDate" />
          <DateField source="jobExpirationDate" />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
  )
}

const RecurringJobTabs = ({ ...props }) => {
  const { record } = props

  return (
    <TabbedShowLayout {...props}>
      <RecurringJobGeneralTab />
      {record?.lastExecution && <RecurringJobLastExecutionTab />}
      {record?.lastExecution && <RecurringJobOldExecutionTab />}
    </TabbedShowLayout>
  )
}

export const RecurringJobShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <RecurringJobTabs />
  </Show>
)
