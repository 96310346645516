import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import {
  ArrayField,
  Datagrid,
  GET_ONE,
  List,
  ListProps,
  sanitizeFetchType,
  TextField,
  useExpanded,
  useListContext,
  useQuery,
  useTranslate,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'

const useStyles = makeStyles({
  noBottomBorder: {
    borderBottom: 'none',
  },
  quarterWidth: {
    width: '25%',
  },
  block: {
    display: 'block',
  },
})

const ListTitle = ({ ...props }) => {
  const {
    filterValues: { id },
  } = useListContext(props)

  const { data: overallView } = useQuery({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.STATS_OVERALL_VIEW,
    payload: { id },
  })

  const t = useTranslate()

  return (
    <span>
      {t(`resources.${ResourceName.STATS_OVERALL_VIEW}.name`)}:{' '}
      {overallView?.eventName}{' '}
      {overallView?.eventDateStart &&
        new Date(overallView?.eventDateStart).toLocaleString()}
    </span>
  )
}

const BlockGrid = (props: any) => {
  const {
    record: { bookingsCountPerType },
  } = props
  const classes = useStyles()
  const translate = useTranslate()

  const firstColumnEntries = [
    { source: 'abo', value: bookingsCountPerType?.ABO },
    { source: 'infix', value: bookingsCountPerType?.INFIX },
    { source: 'normal', value: bookingsCountPerType?.NORMAL },
    { source: 'points', value: bookingsCountPerType?.POINTS },
    { source: 'service', value: bookingsCountPerType?.SERVICE },
  ].map(
    ({ source, value }) =>
      !!value && (
        <div key={source}>
          {translate(`resources.stats-overall-view.fields.${source}`)}
        </div>
      ),
  )

  const secondColumnEntries = [
    { source: 'enteredAbo', value: bookingsCountPerType?.ABO },
    { source: 'enteredInfix', value: bookingsCountPerType?.INFIX },
    { source: 'enteredNormal', value: bookingsCountPerType?.NORMAL },
    { source: 'enteredPoints', value: bookingsCountPerType?.POINTS },
    { source: 'enteredService', value: bookingsCountPerType?.SERVICE },
  ].map(
    ({ source, value }) =>
      !!value && (
        <TextField
          key={source}
          source="entered"
          label=" "
          record={value}
          className={classes.block}
        />
      ),
  )

  const thirdColumnEntries = [
    { source: 'soldAbo', value: bookingsCountPerType?.ABO },
    { source: 'soldInfix', value: bookingsCountPerType?.INFIX },
    { source: 'soldNormal', value: bookingsCountPerType?.NORMAL },
    { source: 'soldPoints', value: bookingsCountPerType?.POINTS },
    { source: 'soldService', value: bookingsCountPerType?.SERVICE },
  ].map(
    ({ source, value }) =>
      !!value && (
        <TextField
          key={source}
          source="sold"
          label=" "
          record={value}
          className={classes.block}
        />
      ),
  )

  return (
    <TableContainer style={{ paddingTop: '10px', marginBottom: '10px' }}>
      <Table padding="none">
        <TableBody>
          <TableRow>
            <TableCell
              style={{ width: '15%' }}
              className={classes.noBottomBorder}
            />
            <TableCell
              style={{ width: '40%' }}
              className={classes.noBottomBorder}
            >
              {firstColumnEntries}
            </TableCell>
            <TableCell
              style={{ width: '15%' }}
              className={classes.noBottomBorder}
            >
              {secondColumnEntries}
            </TableCell>
            <TableCell
              style={{ width: '15%' }}
              className={classes.noBottomBorder}
            >
              {thirdColumnEntries}
            </TableCell>
            <TableCell
              style={{ width: '15%' }}
              className={classes.noBottomBorder}
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TribuneGrid = () => {
  const translate = useTranslate()

  const TribuneHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '15%' }} />
        <TableCell style={{ width: '40%' }}>
          {translate('resources.stats-overall-view.fields.block')}
        </TableCell>
        <TableCell style={{ width: '15%' }} />
        <TableCell style={{ width: '15%' }} />
        <TableCell style={{ width: '15%' }} />
      </TableRow>
    </TableHead>
  )

  return (
    <ArrayField source="blocks">
      <Datagrid
        expand={<BlockGrid />}
        padding="none"
        header={<TribuneHeader />}
        isRowExpandable={(record) => !!record.sold}
      >
        <TextField source="name" label="Sektor" />
        <TextField source="entered" label=" " />
        <TextField source="sold" label=" " />
        <TextField source="capacity" label=" " />
      </Datagrid>
    </ArrayField>
  )
}

const ObjectGrid = () => {
  const translate = useTranslate()

  const objectHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '10%' }} />
        <TableCell style={{ width: '45%' }}>
          {translate('resources.stats-overall-view.fields.tribune')}
        </TableCell>
        <TableCell style={{ width: '15%' }} />
        <TableCell style={{ width: '15%' }} />
        <TableCell style={{ width: '15%' }} />
      </TableRow>
    </TableHead>
  )

  return (
    <ArrayField source="tribunes">
      <Datagrid expand={<TribuneGrid />} padding="none" header={objectHeader}>
        <TextField source="name" label="Trybuna" />
        <TextField source="entered" label=" " />
        <TextField source="sold" label=" " />
        <TextField source="capacity" label=" " />
      </Datagrid>
    </ArrayField>
  )
}

export const OverallView = (props: ListProps) => {
  const translate = useTranslate()

  const overallHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '5%' }} />
        <TableCell style={{ width: '50%' }}>
          {translate('resources.stats-overall-view.fields.name')}
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          {translate('resources.stats-overall-view.fields.entered')}
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          {translate('resources.stats-overall-view.fields.sold')}
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          {translate('resources.stats-overall-view.fields.capacity')}
        </TableCell>
      </TableRow>
    </TableHead>
  )

  const MainGrid = ({ ...mainGridProps }) => {
    const { ids, data } = useListContext(mainGridProps)
    const [expanded, toogleExpand] = useExpanded(
      ResourceName.STATS_OVERALL_VIEW,
      ids?.[0],
    )
    useEffect(() => {
      if (!expanded) toogleExpand()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
      <Datagrid
        {...mainGridProps}
        resource={ResourceName.STATS_OVERALL_VIEW}
        expand={<ObjectGrid />}
        padding="none"
        header={overallHeader}
        optimized
        hasBulkActions={false}
      >
        <TextField source="name" label="Obiekt" />
        <TextField source="entered" />
        <TextField source="sold" />
        <TextField source="capacity" />
      </Datagrid>
    )
  }

  return (
    <List title={<ListTitle />} actions={false} {...props}>
      <MainGrid />
    </List>
  )
}
