import { Chip } from '@material-ui/core'
import * as React from 'react'
import {
  Labeled,
  LabeledProps,
  useRecordContext,
  useTranslate,
} from 'react-admin'

export const MappedChipField = <T extends unknown>({
  mapper,
  source,
  ...props
}: Omit<LabeledProps, 'children'> & { mapper: (value: T) => string }) => {
  const record = useRecordContext()
  const t = useTranslate()
  const mappedValue = mapper(record[source as string])
  return (
    <Labeled {...props} source={source}>
      <>
        <Chip label={t(mappedValue)} />
      </>
    </Labeled>
  )
}
