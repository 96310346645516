import * as React from 'react'
import { TextField, TextFieldProps, useRecordContext } from 'react-admin'
import { maskMiddlePart } from '../../core/common/mask.utils'

export const MaskField = ({ source, ...rest }: TextFieldProps) => {
  const record = useRecordContext()
  const properRecord = record[source as string]
    ? {
        ...record,
        [source as string]: maskMiddlePart(record[source as string], 2),
      }
    : record
  return <TextField {...rest} record={properRecord} source={source} />
}
