import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  EditProps,
  ListButton,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { AccessBonusDto } from '../../../core/dto/access-bonus.dto'

const validateAccessCreate = (values: AccessBonusDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.description) {
    errors.description = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.access-bonuses.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const AccessBonusCreate = (props: EditProps) => (
  <Create {...props} hasShow={false} title={<CreateTitle />}>
    <SimpleForm
      redirect="list"
      toolbar={<CreateToolbar />}
      validate={validateAccessCreate}
    >
      <TextInput source="name" isRequired />
      <TextInput source="description" isRequired />
    </SimpleForm>
  </Create>
)
