import { Cancel } from '@material-ui/icons'
import * as React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  CreateProps,
  useTranslate,
  Toolbar,
  ToolbarProps,
  SaveButton,
  ListButton,
} from 'react-admin'
import { ObjectDto } from '../../../core/dto/object.dto'

const validateObjectCreate = (values: ObjectDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const ObjectCreate = (props: CreateProps) => {
  const translate = useTranslate()
  return (
    <Create {...props} title={translate('resources.objects.create')}>
      <SimpleForm
        redirect="list"
        toolbar={<CreateToolbar />}
        validate={validateObjectCreate}
      >
        <TextInput source="name" isRequired />
      </SimpleForm>
    </Create>
  )
}

export default ObjectCreate
