const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  description: (lambdaParameter, value) =>
    `${lambdaParameter}.Description.Contains("${value}")`,
  info: (lambdaParameter, value) =>
    `${value
      .toString()
      .split(' ')
      .map(
        (q: string) =>
          `(${lambdaParameter}.Name+${lambdaParameter}.Description).Contains("${q}")`,
      )
      .join('&&')}`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name+${lambdaParameter}.Description).Contains("${value}")`,
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
