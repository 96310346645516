import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close, FilterTiltShift, TrackChanges } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { StanbyMode } from '../../../core/enum/StanbyMode'
import { ResourceName } from '../../../core/ResourceName'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import Button from '../../common/customized-mui-components/Button'
import DraggableComponent from '../../common/DraggableComponent'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

const useStyles = makeStyles({
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  actionsButton: {
    margin: '2%',
    width: '96%',
  },
  iconContainer: {
    width: 34,
    height: 34,
  },
  icon: {
    position: 'relative',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'relative',
    left: -24,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
})

export const SetStandbyModeDialog = ({
  open,
  close,
  standbyMode,
  setStandbyMode,
  handleStandbyMode,
  asRecurringJob,
}: {
  open: boolean
  close: () => void
  standbyMode?: StanbyMode
  setStandbyMode: (value: StanbyMode) => void
  handleStandbyMode: (
    recurringJobName?: string,
    cronExpression?: string,
  ) => void
  asRecurringJob?: boolean
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const handleSetStandbyMode = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (asRecurringJob) handleStandbyMode(recurringJobName, cronExpression)
    else handleStandbyMode()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleSetStandbyMode}
        source="setStandbyMode"
        action="set"
        resource={ResourceName.DOORS}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.doors.dialogs.setStandbyMode.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Select
          variant="outlined"
          value={standbyMode}
          onChange={(e) => setStandbyMode(e.target.value as StanbyMode)}
          fullWidth
          displayEmpty
        >
          <MenuItem value={StanbyMode.ALWAYS_ON}>
            <ListItemIcon className={classes.iconContainer}>
              <TrackChanges className={classes.icon} />
            </ListItemIcon>
            {translate(`resources.enums.standbyMode.${StanbyMode.ALWAYS_ON}`)}
          </MenuItem>
          <MenuItem value={StanbyMode.ALWAYS_OFF}>
            <ListItemIcon className={classes.iconContainer}>
              <TrackChanges className={classes.icon} />
              <Close className={classes.crossIcon} />
            </ListItemIcon>
            {translate(`resources.enums.standbyMode.${StanbyMode.ALWAYS_OFF}`)}
          </MenuItem>
          <MenuItem value={StanbyMode.SENSOR}>
            <ListItemIcon className={classes.iconContainer}>
              <FilterTiltShift className={classes.icon} />
            </ListItemIcon>
            {translate(`resources.enums.standbyMode.${StanbyMode.SENSOR}`)}
          </MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
          disabled={
            !Object.values(StanbyMode).includes(standbyMode as StanbyMode)
          }
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export const DoorLockDialog = ({
  open,
  close,
  disabledId,
  setDisabledId,
  handleLock,
  asRecurringJob,
}: {
  open: boolean
  close: () => void
  disabledId: number
  setDisabledId: (value: number) => void
  handleLock: (recurringJobName?: string, cronExpression?: string) => void
  asRecurringJob?: boolean
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    setDisabledId(0)
    setError(false)
    setErrorText('')
    setConfirmOpen(false)
    close()
  }

  const handleConfirm = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    if (asRecurringJob) handleLock(recurringJobName, cronExpression)
    else handleLock()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleConfirm}
        source="lock"
        action="set"
        resource={ResourceName.DOORS}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.doors.dialogs.lock.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={close}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Form onSubmit={() => undefined}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <FilteredReferenceInput
                source="disabled"
                defaultValue={null}
                reference={ResourceName.VERIFIER_DEVICE_DISABLED}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={smallScreen ? 5 : 15}
                allowEmpty
                filterSource="name"
                selectWithPaginationInputProps={{
                  optionText: 'name',
                  showFilter: true,
                  error,
                  helperText: errorText,
                }}
                fullWidth
                onChange={(e) => setDisabledId(e.target.value)}
                value={disabledId}
                resource={ResourceName.DOORS}
              />
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}

export const SetStateDialog = ({
  open,
  close,
  released,
  setReleased,
  handleSetState,
  asRecurringJob,
}: {
  open: boolean
  close: () => void
  released?: boolean
  setReleased: (value: boolean) => void
  handleSetState: (recurringJobName?: string, cronExpression?: string) => void
  asRecurringJob?: boolean
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const handleState = (recurringJobName?: string, cronExpression?: string) => {
    if (asRecurringJob) handleSetState(recurringJobName, cronExpression)
    else handleSetState()
    handleClose()
  }

  const handleReleaseDoor = () => {
    setReleased(true)
    setConfirmOpen(true)
  }

  const handleWithholdDoor = () => {
    setReleased(false)
    setConfirmOpen(true)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleState}
        source="setState"
        action={released ? 'set' : 'remove'}
        resource={ResourceName.DOORS}
        asRecurringJob={asRecurringJob}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.doors.dialogs.setState.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleReleaseDoor}
          label={translate('resources.doors.dialogs.setState.actions.release')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={handleWithholdDoor}
          label={translate('resources.doors.dialogs.setState.actions.withhold')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}
