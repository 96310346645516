import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  useTranslate,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  ToolbarProps,
  TextField,
  ShowButton,
  FormDataConsumer,
  SelectInput,
} from 'react-admin'
import { CameraServerDto } from '../../../../core/dto/device/camera/camera-servers/camera-server.dto'
import { CameraTypes } from '../../../../core/enum/CameraTypes'
import { WebProtocols } from '../../../../core/enum/WebProtocols'
import { CameraTimeZones } from '../../../../core/enum/CameraTimeZones'

const validateCameraServerEdit = (values: CameraServerDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.type) {
    errors.type = 'ra.validation.required'
  }
  if (values.type !== CameraTypes.WEBSOCKET && !values.webProtocol) {
    errors.webProtocol = 'ra.validation.required'
  }
  if (values.type !== CameraTypes.WEBSOCKET && !values.address) {
    errors.address = 'ra.validation.required'
  }
  if (
    (values.type === CameraTypes.VDG_RTSP ||
      values.type === CameraTypes.VDG_MJPEG ||
      values.type === CameraTypes.WEBSOCKET) &&
    !values.serverId
  ) {
    errors.serverId = 'ra.validation.required'
  }
  if (!values.timeZone) {
    errors.timeZone = 'ra.validation.required'
  }
  if (!values.delay) {
    errors.delay = 'ra.validation.required'
  }
  if (!values.downloadClipDuration) {
    errors.downloadClipDuration = 'ra.validation.required'
  }
  return errors
}

const EditTitle = ({ ...props }) => {
  const { record: server } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.camera-servers.titles.edit')}: {server?.id}.{' '}
      {server?.name}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const CameraServer = ({ ...props }) => (
  <SimpleForm
    {...props}
    redirect="show"
    toolbar={<EditToolbar />}
    validate={validateCameraServerEdit}
  >
    <TextField source="id" label="ID" />
    <TextInput source="name" isRequired />
    <SelectInput
      source="type"
      choices={Object.entries(CameraTypes).map(([value]) => ({
        id: value,
        name: `resources.enums.CameraTypes.${value}`,
      }))}
      isRequired
    />
    <SelectInput
      source="timeZone"
      choices={Object.entries(CameraTimeZones).map(([value]) => ({
        id: value,
        name: `resources.enums.CameraTimeZones.${value}`,
      }))}
      isRequired
    />
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData?.type !== CameraTypes.WEBSOCKET && (
          <SelectInput
            {...rest}
            source="webProtocol"
            choices={Object.entries(WebProtocols).map(([value]) => ({
              id: value,
              name: `resources.enums.WebProtocols.${value}`,
            }))}
            isRequired
          />
        )
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData?.type !== CameraTypes.WEBSOCKET && (
          <TextInput {...rest} source="address" isRequired />
        )
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData?.type !== CameraTypes.HIKVISION_RTSP &&
        formData?.type !== CameraTypes.AXXON_MJPEG &&
        formData?.type !== CameraTypes.DAHUA_RTSP && (
          <TextInput {...rest} source="serverId" isRequired />
        )
      }
    </FormDataConsumer>
    <TextInput
      source="delay"
      type="time"
      inputProps={{
        step: 1,
      }}
      isRequired
    />
    <TextInput source="downloadClipDuration" type="number" isRequired />
  </SimpleForm>
)

export const CameraServerEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <CameraServer />
  </Edit>
)
