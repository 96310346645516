import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import { useListContext } from 'ra-core'
import React, { cloneElement, useContext } from 'react'
import {
  Datagrid,
  Filter,
  FilterButton,
  FilterContext,
  List,
  ListActionsProps,
  ListProps,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate,
} from 'react-admin'
import {
  EditableDatagrid,
  RowForm,
} from '../../../lib/@react-admin/ra-editable-datagrid'
import ParameterizedCreateButton from '../../common/ParameterizedCreateButton'
import Empty from '../Empty'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { ResourceName } from '../../../core/ResourceName'

const ListFilters = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <TextInput source="numberAlias" alwaysOn />
      <TextInput source="id" />
      <TextInput source="rowAlias" />
      {hasAuthority(Authority.VIEW_BLOCKS) && (
        <FilteredReferenceInput
          source="blockId"
          reference={ResourceName.BLOCKS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

const EditForm = ({ ...props }) => (
  <RowForm {...props}>
    <TextField source="id" />
    <TextInput source="rowAlias" />
    <TextInput source="numberAlias" />
    <TextField source="blockId" />
  </RowForm>
)

const SeatsGrid = ({ ...props }) => {
  const hasAuthority = useHasAuthority()

  if (hasAuthority(Authority.EDIT_SEATS))
    return (
      <EditableDatagrid
        {...props}
        size="small"
        editForm={<EditForm />}
        noDelete
      >
        <TextField source="id" />
        <TextField source="rowAlias" />
        <TextField source="numberAlias" />
        <TextField source="blockId" />
      </EditableDatagrid>
    )

  return (
    <Datagrid {...props} size="small">
      <TextField source="id" />
      <TextField source="rowAlias" />
      <TextField source="numberAlias" />
      <TextField source="blockId" />
    </Datagrid>
  )
}

interface SeatListActions extends ListActionsProps {
  objectId?: number
}

const ListActions = ({ objectId, ...props }: SeatListActions) => {
  const { filters: filtersProp, ...rest } = props
  const hasAuthority = useHasAuthority()

  const { displayedFilters, filterValues, showFilter } = useListContext(rest)
  const resource = ResourceName.SEATS
  const filters = useContext(FilterContext) || filtersProp

  return (
    <TopToolbar {...rest}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      <ParameterizedCreateButton
        searchParams={{ objectId }}
        disabled={!hasAuthority(Authority.CREATE_SEATS)}
      />
    </TopToolbar>
  )
}

const EmptyView = ({ objectId }: { objectId?: number }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <Box>
      <Empty text={translate('resources.seats.empty')} />
      <TopToolbar>
        <ParameterizedCreateButton
          searchParams={{ objectId }}
          disabled={!hasAuthority(Authority.CREATE_SEATS)}
        />
      </TopToolbar>
    </Box>
  )
}

interface SeatsListProps extends ListProps {
  objectId?: number
}

const SeatsList = ({ objectId, ...props }: SeatsListProps) => {
  const { basePath } = useListContext()

  return (
    <List
      {...props}
      exporter={false}
      basePath={basePath}
      filters={<ListFilters />}
      actions={<ListActions objectId={objectId} />}
      bulkActionButtons={false}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      empty={<EmptyView objectId={objectId} />}
    >
      <SeatsGrid />
    </List>
  )
}

export default SeatsList
