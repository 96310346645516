import { STATIC_VALUE_ID } from './csv.consts'

type Choice = { id: string; name: string }

const primarySelectChoices: Choice[] = [
  { id: '', name: '(none)' },
  { id: STATIC_VALUE_ID, name: '(static value)' },
]

export const choicesReducer = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  choices: Choice[] = primarySelectChoices,
  columnNames: string[],
): Choice[] =>
  columnNames.length
    ? primarySelectChoices.concat(
        columnNames.map((columnName) => ({ id: columnName, name: columnName })),
      )
    : []
