import React from 'react'
import {
  Datagrid,
  Filter,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'
import { ResourceName } from '../../../core/ResourceName'

const VerifierDeviceDisabledFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <TextInput source="description" />
  </Filter>
)

export const VerifierDeviceDisabledList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    exporter={false}
    filters={<VerifierDeviceDisabledFilters />}
    bulkActionButtons={false}
  >
    <Datagrid
      size="small"
      rowClick={(id) => `${ResourceName.VERIFIER_DEVICE_DISABLED}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
)
