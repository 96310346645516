import React from 'react'
import { ReferenceInput, ReferenceInputProps } from 'react-admin'
import {
  SelectWithPaginationInput,
  SelectWithPaginationInputProps,
} from './SelectWithPaginationInput'

const FilteredReferenceInput = ({
  ...referenceInputProps
}: FilteredReferenceInputProps) => {
  const {
    filterSource,
    reference,
    source,
    perPage,
    selectWithPaginationInputProps,
    filter,
    variant,
    ...referenceInputRest
  } = referenceInputProps
  const [filterValue, setFilterValue] = React.useState('')

  return (
    <ReferenceInput
      {...referenceInputRest}
      perPage={perPage}
      source={source}
      reference={reference}
      filter={
        filterValue !== '' && filterSource
          ? { ...filter, [filterSource]: filterValue }
          : filter
      }
    >
      <SelectWithPaginationInput
        {...selectWithPaginationInputProps}
        paginationProps={
          perPage ? { rowsPerPageOptions: [perPage] } : undefined
        }
        onFilterValueChange={(f) => setFilterValue(f)}
        variant={variant}
      />
    </ReferenceInput>
  )
}

export interface FilteredReferenceInputProps
  extends Omit<ReferenceInputProps, 'children'> {
  filterSource?: string
  selectWithPaginationInputProps?: SelectWithPaginationInputProps
}

export default FilteredReferenceInput
