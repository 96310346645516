const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  filterUserId: () => null,
  userId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterUserId === false
      ? null
      : `${lambdaParameter}.Users.Any(u=>u.Id.Equals(${value}))`,
  filterParentRoleId: () => null,
  parentRoleId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterParentRoleId === false
      ? null
      : `${lambdaParameter}.ParentRoles.Any(u=>u.Id.Equals(${value}))`,
  filterChildRoleId: () => null,
  childRoleId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterChildRoleId === false
      ? null
      : `${lambdaParameter}.ChildRoles.Any(u=>u.Id.Equals(${value}))`,
  filterDynamicStatisticId: () => null,
  dynamicStatisticId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterDynamicStatisticId === false
      ? null
      : `${lambdaParameter}.DynamicStatistics.Any(u=>u.Id.Equals(${value}))`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
