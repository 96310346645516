var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { v4 as createUuid } from 'uuid';
import { required, SelectInput, TextInput } from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { TemplateInfoButton } from '../TemplateInfoButton';
import { FieldFormToolbar } from './FieldFormToolbar';
export var ComputedFieldEditor = function (_a) {
    var availableColumns = _a.availableColumns, _b = _a.field, field = _b === void 0 ? {} : _b, onCancel = _a.onCancel, onClose = _a.onClose, onDelete = _a.onDelete, onSave = _a.onSave, props = __rest(_a, ["availableColumns", "field", "onCancel", "onClose", "onDelete", "onSave"]);
    var classes = useStyles();
    return (React.createElement(Form, { initialValues: __assign({ id: createUuid() }, field), onSubmit: onSave }, function (formProps) { return (React.createElement("form", { onSubmit: formProps.handleSubmit },
        React.createElement("div", { className: classes.form },
            React.createElement(TextInput, { source: "label", validate: required(), fullWidth: true }),
            React.createElement(TextInput, { source: "template", fullWidth: true, InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(TemplateInfoButton, { resource: props.resource }))),
                }, validate: required() }),
            React.createElement(SelectInput, { source: "alignment", choices: ALIGNMENT_CHOICES, validate: required(), initialValue: "left", fullWidth: true })),
        React.createElement(FieldFormToolbar, { field: field, onCancel: onCancel, onDelete: onDelete }))); }));
};
var ALIGNMENT_CHOICES = [
    { id: 'left', name: 'Left' },
    { id: 'center', name: 'Center' },
    { id: 'right', name: 'Right' },
    { id: 'justify', name: 'Justify' },
];
var useStyles = makeStyles(function (theme) { return ({
    form: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}); });
