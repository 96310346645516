import React from 'react'
import {
  Datagrid,
  Filter,
  FilterProps,
  ListProps,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'

const ListFilters = (props: Omit<FilterProps, 'children'>) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

const ObjectList = ({ ...props }: ListProps) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilters />}
    bulkActionButtons={false}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
  >
    <Datagrid
      size="small"
      rowClick={(id) => `${ResourceName.OBJECTS}/${id}/show`}
    >
      <TextField source="id" label="ID" />
      <TextField source="name" />
    </Datagrid>
  </List>
)

export default ObjectList
