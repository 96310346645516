const mapping: Record<string, string> = {
  recurringJobId: 'l=>l.RecurringJobId',
  name: 'l=>l.Name',
  cron: 'l=>l.Cron',
  'jobParameters.method': 'l=>l.JobParameters.Method',
  queue: 'l=>l.Queue',
  lastExecution: 'l=>l.LastExecution',
  nextExecution: 'l=>l.NextExecution',
  createdAt: 'l=>l.CreatedAt',
}

export const mapSortRecurringJobParam = (key: string) => mapping[key]
