import { DataProvider } from 'react-admin'
import { TribuneDto } from '../../dto/tribune.dto'
import { TribuneType } from '../../enum/TribuneType'

const fakeTribunes: TribuneDto[] = [
  {
    id: 0,
    name: 'Trybuna A',
    code: 'AB999',
    type: TribuneType.VIRTUAL,
    objectId: 4,
    blocksIds: [],
  },
  {
    id: 1,
    name: 'Trybuna A',
    code: 'AB000',
    type: TribuneType.MANUAL,
    objectId: 2,
    blocksIds: [],
  },
  {
    id: 2,
    name: 'Trybuna B',
    code: 'AB890',
    type: TribuneType.MANUAL,
    objectId: 4,
    blocksIds: [],
  },
  {
    id: 3,
    name: 'Trybuna B',
    code: 'AB777',
    type: TribuneType.MANUAL,
    objectId: 2,
    blocksIds: [],
  },
  {
    id: 4,
    name: 'Trybuna A',
    code: 'AB456',
    type: TribuneType.MANUAL,
    objectId: 4,
    blocksIds: [],
  },
  {
    id: 5,
    name: 'Trybuna A',
    code: 'AB333',
    type: TribuneType.MANUAL,
    objectId: 2,
    blocksIds: [],
  },
  {
    id: 6,
    name: 'Trybuna C',
    code: 'AB225',
    type: TribuneType.MANUAL,
    objectId: 4,
    blocksIds: [],
  },
  {
    id: 7,
    name: 'Trybuna C',
    code: 'AB222',
    type: TribuneType.MANUAL,
    objectId: 2,
    blocksIds: [],
  },
  {
    id: 8,
    name: 'Trybuna D',
    code: 'AB333',
    type: TribuneType.MANUAL,
    objectId: 4,
    blocksIds: [],
  },
  {
    id: 9,
    name: 'Trybuna D',
    code: 'AB555',
    type: TribuneType.MANUAL,
    objectId: 2,
    blocksIds: [],
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeTribunes, total: fakeTribunes.length }),
} as DataProvider

export default provider
