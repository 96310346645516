import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import { LOGS_URL } from '../api-urls'
import { get, getWithPagination } from '../common/fetch.utils'
import { ExtendedLogDto } from '../dto/extended-log.dto'
import { ExtendedLog } from '../dto/extended-log'
import { filterMapper } from './extended-log-filter.mapper'
import { mapSortLogParam } from './extended-log-sort.mapper'
import { mapToExtendedLog } from './extended-logs.mapper'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { getDeviceCategoryChildren } from '../devices/devices.utils'
import { getSchemeChildren } from '../schemes/schemes.utils'
import i18nProvider from '../i18n.provider'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<ExtendedLog>> => {
    const locale = i18nProvider.getLocale()
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    // Tree type filter for schemes
    if (extendedFilter?.schemeIdWithDescendants) {
      const schemesIds = await getSchemeChildren(
        extendedFilter.schemeIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, schemesIds }
    }
    // Assigning locale for simple-view filter
    if (extendedFilter?.simpleView) {
      extendedFilter = { ...extendedFilter, simpleView: locale }
    } else {
      extendedFilter = Object.fromEntries(
        Object.entries(extendedFilter).filter(([key]) => key !== 'simpleView'),
      )
    }
    const filterParams = `a=>${filterParamsComposer(
      'a',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortLogParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<ExtendedLogDto[]>(
      `${LOGS_URL}/GetExtendedLogByConditions`,
      path,
    )

    const extendedLogs = data.map((dto) => mapToExtendedLog(dto, locale))

    return Promise.resolve({
      data: extendedLogs,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id: seatId }: GetOneParams,
  ): Promise<GetOneResult<ExtendedLog>> => {
    const locale = i18nProvider.getLocale()

    const log = await get<ExtendedLogDto>(
      `${LOGS_URL}/GetExtendedLog`,
      `/${seatId}`,
    )
    const extendedLog = mapToExtendedLog(log, locale)

    return {
      data: extendedLog,
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<ExtendedLog>> => {
    const locale = i18nProvider.getLocale()

    const logs = await get<ExtendedLogDto[]>(
      LOGS_URL,
      `/GetExtendedLogByConditions/s=>new int[] {${ids.toString()}}.Contains(s.Id)`,
    )
    const extendedLogs = logs.map((log) => mapToExtendedLog(log, locale))

    return Promise.resolve({ data: extendedLogs })
  },
} as DataProvider

export default provider
