import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  Identifier,
  CreateParams,
  CreateResult,
} from 'react-admin'
import { DETECTORS_URL } from '../../api-urls'
import { DataProviderExtensionResult } from '../../common/data-provider'
import { get, getWithPagination, patch, post } from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { DetectorDto } from '../../dto/device/detector/detector.dto'
import { HardwareVersions } from '../../enum/HardwareVersions'
import { getDeviceCategoryChildren } from '../devices.utils'
import filterMapper from './detector-filter.mapper'
import mapSortDetectorParam from './detector-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<DetectorDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortDetectorParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DetectorDto[]>(
      `${DETECTORS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DetectorDto>> => {
    const data = await get<DetectorDto>(DETECTORS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<DetectorDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      DetectorDto
    >(DETECTORS_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
      isWatched:
        data.isWatched !== previousData.isWatched ? data.isWatched : undefined,
      categoryId:
        data.categoryId !== previousData.categoryId
          ? data.categoryId
          : undefined,
      cameraId:
        data.cameraId !== previousData.cameraId ? data.cameraId : undefined,
      hardwareVersion:
        data.hardwareVersion !== previousData.hardwareVersion
          ? data.hardwareVersion
          : undefined,
      tsCanId: data.tsCanId !== previousData.tsCanId ? data.tsCanId : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateDetectorRequest>,
  ): Promise<CreateResult<DetectorDto>> => {
    const engine = await post<CreateDetectorRequest[], DetectorDto[]>(
      DETECTORS_URL,
      [data],
    )
    return {
      data: engine[0],
    }
  },
  forceReportState: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const forceReportState = await get<undefined>(
      DETECTORS_URL,
      `/ForceReportState/${params?.id}`,
    )
    return {
      data: forceReportState,
    }
  },
  restartTSCan: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const restartTSCan = await get<undefined>(
      DETECTORS_URL,
      `/RestartTSCan/${params?.id}`,
    )
    return {
      data: restartTSCan,
    }
  },
} as DetectorsDataProvider

interface DetectorsDataProvider extends DataProvider {
  forceReportState: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  restartTSCan: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>
}

export interface CreateDetectorRequest {
  readonly name: string
  readonly isWatched: boolean
  readonly categoryId: number
  readonly jsonStatus: string
  readonly hardwareVersion: HardwareVersions
  readonly tsCanId: number
}

export default provider
