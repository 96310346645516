/**
 * @example
 * maskMiddlePart('importantValue', 3) === 'imp********lue'
 */
export const maskMiddlePart = (word: string, threshold: number) => {
  const firstChars = word.substring(0, threshold)
  const lastChars = word.slice(-threshold)
  const restCharsLength = word.length - firstChars.length - lastChars.length
  return `${firstChars}${'*'.repeat(
    restCharsLength > 0 ? restCharsLength : 0,
  )}${lastChars}`
}
