import { DataProvider } from 'react-admin'
import { ExtendedLog } from '../../dto/extended-log'
import { CameraTypes } from '../../enum/CameraTypes'

const fakeLogs: ExtendedLog[] = [
  {
    id: 1,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 52815,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 2,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 52816,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 3,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 1111,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 4,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 11112,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 5,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 16612,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 6,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 167782,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 7,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 122782,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 8,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 189782,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 9,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 183332,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
  {
    id: 10,
    date: '2021-07-15T08:50:27.2054',
    ticketCode: 'A',
    bookingId: 656565,
    deviceName: 'Device name',
    text: 'MF',
    color: 'transparent',
    cameraId: 1,
    cameraDescription: 'Camera name',
    cameraType: CameraTypes.VDG_RTSP,
    scheme: 'scheme',
    serverId: 1,
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeLogs, total: fakeLogs.length }),
} as DataProvider

export default provider
