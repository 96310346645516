import { Box, Divider, Tooltip } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import React, { ReactElement } from 'react'
import {
  Show,
  ShowProps,
  useTranslate,
  TitleProps,
  TabbedShowLayout,
  Tab,
  TextField,
  FunctionField,
  ReferenceField,
  EditButton,
  useShowContext,
  Record,
  TextInput,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { UserDto } from '../../../core/dto/user.dto'
import { ResourceName } from '../../../core/ResourceName'
import RelationTab from '../../common/RelationTab'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import ChangePasswordButton from './UserShow/UserChangePasswordButton'
import relationTabFilter from '../../common/RelationTabFilter'
import { CompanyDto } from '../../../core/dto/company.dto'

interface UserTitleProps extends TitleProps {
  record?: UserDto
}

const ShowTitle = (data: UserTitleProps) => {
  const { record: user } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.users.titles.show')}: {user?.id}. {user?.userName}
    </span>
  )
}

const UserRoles = ({ ...props }) => {
  const { record: user } = props
  const hasAuthority = useHasAuthority()

  const UserRolesMapper = (records: Record[], source: string) => ({
    [source]: records.map((record: Record) => record.id),
  })

  const rolesFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.USERS,
    })

  return (
    <RelationTab<UserDto>
      resource={ResourceName.ROLES}
      source="rolesIds"
      mode={hasAuthority(Authority.EDIT_USERS) ? 'edit' : 'show'}
      attachMethod="attachRoles"
      detachMethod="detachRoles"
      attachRequestPayload={(r, ids) => ({
        userId: r.id,
        rolesIds: ids,
      })}
      detachRequestPayload={(r, ids) => ({
        userId: r.id,
        rolesIds: ids,
      })}
      refetchListAfterChange={(filters) => filters?.filterUserId === true}
      relationsRequestMethod="getRoles"
      relationsRequestPayload={(r) => ({ userId: r.id })}
      relationsMapper={UserRolesMapper}
      filters={rolesFilters() as ReactElement[]}
      filterDefaultValues={{
        userId: user?.id,
        filterUserId: false,
      }}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <TextField source="id" label="ID" />
      <TextField source="name" key="name" />
      <FunctionField
        key="description"
        render={(record) =>
          record?.description ? (
            <Tooltip title={record?.description}>
              <Info />
            </Tooltip>
          ) : (
            <div />
          )
        }
      />
    </RelationTab>
  )
}

const GeneralActions = () => {
  const { record: user } = useShowContext<UserDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <ChangePasswordButton />
        <EditButton
          basePath={`/${ResourceName.USERS}`}
          record={user}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_USERS)}
        />
      </Box>
    </>
  )
}

export const UserShow = (props: ShowProps) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  return (
    <Show {...props} actions={false} title={<ShowTitle />}>
      <TabbedShowLayout>
        <Tab label="resources.users.tabs.general">
          <TextField source="id" label="ID" />
          <TextField source="userName" />
          {hasAuthority(Authority.VIEW_COMPANIES) && (
            <ReferenceField
              source="companyId"
              reference={ResourceName.COMPANIES}
              link="show"
            >
              <FunctionField<CompanyDto>
                source="name"
                render={(company?: CompanyDto) =>
                  `${company?.id}. ${company?.name}`
                }
              />
            </ReferenceField>
          )}
          <FunctionField<UserDto>
            source="requirePasswordUpdate"
            render={(record?: UserDto) => (
              <div>
                {record?.requirePasswordUpdate
                  ? translate('const.yes')
                  : translate('const.no')}
              </div>
            )}
          />
          <GeneralActions />
        </Tab>
        {hasAuthority(Authority.VIEW_ROLES) && (
          <Tab label="resources.users.tabs.roles" path="roles">
            <UserRoles />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  )
}
