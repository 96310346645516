import {
  DataProvider,
  UpdateParams,
  UpdateResult,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  CreateParams,
  CreateResult,
  Record,
} from 'react-admin'
import {
  get,
  getWithPagination,
  patchWithAuditComment,
  post,
} from '../common/fetch.utils'
import { BOOKINGS_URL } from '../api-urls'
import {
  BookingUpdateRARequest,
  BookingUpdateRequest,
} from './booking-update.request'
import { BookingDto } from '../dto/booking.dto'
import filterMapper from './booking-filter.mapper'
import { mapSortBookingParam } from './booking-sort.mapper'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { BookingType } from '../enum/BookingType'
import { BookingDisabledOption } from '../enum/BookingDisabledOption'
import { DateTime } from '../common/date-time'
import { RecurringJobCreationParamsDto } from '../dto/recurring-job-creation-params.dto'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'
import { AuditCommentParamsDto } from '../dto/audit-comment-params.dto'
import { CacheableDataProviderExtensionResult } from '../common/data-provider'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<BookingDto>> => {
    const filterParams = `a=>${filterParamsComposer('a', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortBookingParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<BookingDto[]>(
      `${BOOKINGS_URL}/GetByConditions`,
      path,
    )

    return {
      data,
      total,
    }
  },
  getOne: async (
    resource: string,
    { id: bookingId }: GetOneParams,
  ): Promise<GetOneResult<BookingDto>> => {
    const booking = await get<BookingDto>(BOOKINGS_URL, `/${bookingId}`)

    return {
      data: booking,
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<BookingDto>> => {
    const data = await get<BookingDto[]>(
      `${BOOKINGS_URL}/GetByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateBookingRequest>,
  ): Promise<CreateResult<BookingDto>> => {
    const booking = await post<CreateBookingRequest[], BookingDto[]>(
      BOOKINGS_URL,
      [data],
    )
    return {
      data: booking[0],
    }
  },
  update: async (
    resource: string,
    { data, previousData }: UpdateParams<BookingUpdateRARequest>,
  ): Promise<UpdateResult<BookingDto | RecurringJobDetailsDto>> => {
    const additionalParams = data as
      | RecurringJobCreationParamsDto
      | AuditCommentParamsDto
    const isRecurringJob = (additionalParams as RecurringJobCreationParamsDto)
      ?.recurringJobName
    const booking = await patchWithAuditComment<
      BookingUpdateRequest,
      BookingDto | RecurringJobDetailsDto
    >(
      BOOKINGS_URL,
      {
        id: data.id,
        // disabled:
        //   data?.disabled !== previousData?.disabled || isRecurringJob
        //     ? data?.disabled
        //     : undefined,
        activeFrom:
          data?.activeFrom !== previousData?.activeFrom || isRecurringJob
            ? data?.activeFrom
            : undefined,
        activeTo:
          data?.activeTo !== previousData?.activeTo || isRecurringJob
            ? data?.activeTo
            : undefined,
        eventId:
          data?.eventId !== previousData?.eventId || isRecurringJob
            ? data?.eventId
            : undefined,
        seatId:
          data?.seatId !== previousData?.seatId || isRecurringJob
            ? data?.seatId
            : undefined,
        blockId:
          data?.blockId !== previousData?.blockId || isRecurringJob
            ? data?.blockId
            : undefined,
        customerId:
          data?.customerId !== previousData?.customerId || isRecurringJob
            ? data?.customerId
            : undefined,
        variantId:
          data?.variantId !== previousData?.variantId || isRecurringJob
            ? data?.variantId
            : undefined,
      },
      additionalParams,
    )
    if ((booking as RecurringJobDetailsDto)?.recurringJobId)
      return Promise.resolve({ data: previousData as BookingDto })
    return Promise.resolve({
      data: { ...booking, ticketsIds: [] },
    })
  },
  activateUse: async (
    resource: string,
    { id, accessUsingId }: BookingUseActivationParams,
  ): Promise<CacheableDataProviderExtensionResult<Record>> => {
    await post<undefined, undefined>(
      `${BOOKINGS_URL}/ActivateUse/${id}?accessUsingId=${accessUsingId}`,
      undefined,
    )
    return Promise.resolve({ data: { id } })
  },
  deactivateUse: async (
    resource: string,
    { id, accessUsingId }: BookingUseActivationParams,
  ): Promise<CacheableDataProviderExtensionResult<Record>> => {
    await post<undefined, undefined>(
      `${BOOKINGS_URL}/DeactivateUse/${id}?accessUsingId=${accessUsingId}`,
      undefined,
    )
    return Promise.resolve({ data: { id } })
  },
  setLock: async (
    resource: string,
    { id, bookingDisabled }: BookingSetLockParams,
  ): Promise<CacheableDataProviderExtensionResult<Record>> => {
    await post<undefined, undefined>(
      `${BOOKINGS_URL}/SetLock/${id}?bookingDisabled=${bookingDisabled}`,
      undefined,
    )
    return Promise.resolve({ data: { id } })
  },
  releaseLock: async (
    resource: string,
    { id }: BookingReleaseLockParams,
  ): Promise<CacheableDataProviderExtensionResult<Record>> => {
    await post<undefined, undefined>(
      `${BOOKINGS_URL}/ReleaseLock/${id}`,
      undefined,
    )
    return Promise.resolve({ data: { id } })
  },
  releaseAllUsings: async (
    resource: string,
    { id, accessId }: BookingUsingsParams,
  ): Promise<CacheableDataProviderExtensionResult<Record>> => {
    await post<undefined, undefined>(
      `${BOOKINGS_URL}/ReleaseAllUsings/${id}?accessId=${accessId}`,
      undefined,
    )
    return Promise.resolve({ data: { id } })
  },
  createUse: async (
    resource: string,
    { id, accessId }: BookingUsingsParams,
  ): Promise<CacheableDataProviderExtensionResult<Record>> => {
    await post<undefined, undefined>(
      `${BOOKINGS_URL}/CreateUse/${id}?accessId=${accessId}`,
      undefined,
    )
    return Promise.resolve({ data: { id } })
  },
} as BookingsDataProvider

interface BookingsDataProvider extends DataProvider {
  activateUse: (
    resource: string,
    params: BookingUseActivationParams,
  ) => Promise<CacheableDataProviderExtensionResult<Record>>

  deactivateUse: (
    resource: string,
    params: BookingUseActivationParams,
  ) => Promise<CacheableDataProviderExtensionResult<Record>>

  setLock: (
    resource: string,
    params: BookingSetLockParams,
  ) => Promise<CacheableDataProviderExtensionResult<Record>>

  releaseLock: (
    resource: string,
    params: BookingReleaseLockParams,
  ) => Promise<CacheableDataProviderExtensionResult<Record>>

  releaseAllUsings: (
    resource: string,
    params: BookingUsingsParams,
  ) => Promise<CacheableDataProviderExtensionResult<Record>>

  createUse: (
    resource: string,
    params: BookingUsingsParams,
  ) => Promise<CacheableDataProviderExtensionResult<Record>>
}

interface BookingUseActivationParams {
  readonly id: number
  readonly accessUsingId: number
}

export interface BookingSetLockParams {
  readonly id: number
  readonly bookingDisabled: BookingDisabledOption
}

export interface BookingReleaseLockParams {
  readonly id: number
}

interface BookingUsingsParams {
  readonly id: number
  readonly accessId: number
}

export interface CreateBookingRequest {
  readonly type: BookingType
  readonly disabled?: BookingDisabledOption
  readonly activeFrom?: DateTime
  readonly activeTo?: DateTime
  readonly eventId?: number
  readonly seatId?: number
  readonly blockId?: number
  readonly customerId?: number
  readonly variantId?: number
}

export default provider
