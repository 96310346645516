import { MenuItem, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import {
  useMutation,
  useListContext,
  sanitizeFetchType,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { BulkActionProps } from '../../../common/BulkActionsProps'
import { DepositorEventsRecurringDialog } from '../DepositorDialogs'
import { DepositorDto } from '../../../../core/dto/device/depositor/depositor.dto'

export const DepositorsAttachEventRecurringBulkButton = ({
  selectedIds,
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  innerRef,
  ...props
}: BulkActionProps) => {
  const { loading, refetch } = useListContext<DepositorDto>()
  const notify = useNotify()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const [selectStateDialogOpen, setSelectStateDialogOpen] = useState(false)
  const [mutate, { loading: mutationLoading }] = useMutation()
  const [eventId, setEventId] = useState<number | undefined>()

  const handleMutation = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    const query = {
      type: sanitizeFetchType('attachDevices'),
      resource: ResourceName.EVENTS,
      payload: {
        eventId,
        verifierDevicesIds: selectedIds,
        recurringJobName,
        cronExpression,
      },
    }
    const result = await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    return result
  }

  const handleAttachEvents = async (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    setSelectStateDialogOpen(false)
    handleMutation(recurringJobName, cronExpression)
    refetch()
  }

  const handleDialogClose = () => {
    setSelectStateDialogOpen(false)
  }

  return (
    <MenuItem
      {...props}
      disabled={
        !selectedIds.length ||
        !hasAuthority(Authority.EDIT_ENGINES) ||
        loading ||
        mutationLoading
      }
      onClick={() => setSelectStateDialogOpen(true)}
      button
    >
      <Typography>
        {translate('resources.depositors.actions.attachEvents-recurring')}
      </Typography>
      <DepositorEventsRecurringDialog
        open={selectStateDialogOpen}
        close={handleDialogClose}
        setSelectedEventId={setEventId}
        handleAttachment={handleAttachEvents}
        method="attach"
      />
    </MenuItem>
  )
}
