import { DataProvider } from 'react-admin'
import { DeviceCategoryDto } from '../../dto/device-category/device-category.dto'

const fakeDeviceCategories: DeviceCategoryDto[] = [
  {
    id: 1,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 2,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 3,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 4,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 5,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 6,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 7,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 8,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 9,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
  {
    id: 10,
    name: 'device category fake name',
    parentId: 1,
    devicesIds: [1, 2, 3, 4, 5],
    hierarchy: [
      { id: 1, name: 'name1' },
      { id: 2, name: 'name2' },
      { id: 3, name: 'name3' },
      { id: 4, name: 'name4' },
      { id: 5, name: 'name5' },
    ],
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeDeviceCategories,
      total: fakeDeviceCategories.length,
    }),
} as DataProvider

export default provider
