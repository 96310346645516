import { DataProvider } from 'react-admin'
import { EventEntrance } from '../../stats/event-entrances-view/event-entrances-view'

const fakeStatsEntrancesViewList: EventEntrance[] = []

const provider = {
  getOne: (resource, { id }: { id: string }) => {
    const found = fakeStatsEntrancesViewList.find((v) => `${v.id}` === id)
    if (found) {
      return Promise.resolve({
        data: found,
      })
    }
    return Promise.reject(new Error())
  },
} as DataProvider

export default provider
