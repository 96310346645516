import jsonexport from 'jsonexport/dist'
import React, { useMemo } from 'react'
import {
  Datagrid,
  ExportButton,
  ResourceContextProvider,
  Show,
  TextField,
  TopToolbar,
  useTranslate,
  sanitizeListRestProps,
  downloadCSV,
  ListActionsProps,
  useListContext,
  useShowContext,
  List,
} from 'react-admin'
import { DynamicStatisticDto } from '../../../core/dto/dynamic-statistic.dto'
import { ResourceName } from '../../../core/ResourceName'

const ViewTitle = ({ ...props }) => {
  const { record } = props
  const t = useTranslate()
  return (
    <span>
      {t(`resources.${ResourceName.STATS_DYNAMIC_STATISTICS}.titles.show`)}:{' '}
      {record?.name}
    </span>
  )
}

const DynamicStatisticsGrid = ({ ...props }) => {
  const { data, ids, loaded, loading } = props

  const gridColumns = useMemo(
    () =>
      !loading &&
      loaded &&
      Object.keys(data[ids[0]]).map(
        (key: string) =>
          key !== 'id' && (
            <TextField source={key} label={key} key={key} sortable={false} />
          ),
      ),
    [data, ids, loading, loaded],
  )

  return <Datagrid {...props}>{gridColumns}</Datagrid>
}

const DynamicStatisticsViewExport = (
  statistics: any[],
  record?: DynamicStatisticDto,
) => {
  jsonexport(statistics, (err, csv) => {
    downloadCSV(csv, `${record?.name} ${new Date().toLocaleString()}`)
  })
}

const DynamicStatisticsViewActions = (props: ListActionsProps) => {
  const { className, ...rest } = props
  const { filterValues, total } = useListContext(props)
  const { record } = useShowContext<DynamicStatisticDto>()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton
        disabled={total === 0 || total >= 1000000}
        resource={ResourceName.STATS_DYNAMIC_STATISTICS_EXECUTION}
        filterValues={filterValues}
        exporter={(data) => DynamicStatisticsViewExport(data, record)}
        maxResults={1000000}
      />
    </TopToolbar>
  )
}

const DynamicStatisticsList = ({ ...props }) => {
  const { params, ...rest } = props
  const { record } = rest
  return (
    <ResourceContextProvider
      value={ResourceName.STATS_DYNAMIC_STATISTICS_EXECUTION}
    >
      <List
        actions={<DynamicStatisticsViewActions />}
        title=" "
        basePath={`/${ResourceName.STATS_DYNAMIC_STATISTICS}`}
        filter={{
          id: record?.id,
          dynamicallyDefinedParameters: params,
        }}
        bulkActionButtons={false}
      >
        <DynamicStatisticsGrid />
      </List>
    </ResourceContextProvider>
  )
}

export const DynamicStatisticsView = ({ ...props }) => {
  const {
    match,
    location: { search },
  } = props

  const urlSearchParams = new URLSearchParams(search)
  const params = Object.fromEntries(urlSearchParams.entries())

  return (
    <Show
      title={<ViewTitle />}
      id={match?.params?.id}
      resource={ResourceName.STATS_DYNAMIC_STATISTICS}
      basePath="/stats-dynamic-statistics"
      hasEdit={false}
      component="div"
    >
      <DynamicStatisticsList params={params} />
    </Show>
  )
}
