import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  ListProps,
  Pagination,
  TextField,
  TextInput,
  useListContext,
  Filter,
  ReferenceField,
  Datagrid,
  FunctionField,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import FilterProps from '../../common/FilterProps'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { CompanyDto } from '../../../core/dto/company.dto'

const UsersFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      <TextInput source="userName" alwaysOn />
      {hasAuthority(Authority.VIEW_COMPANIES) && (
        <FilteredReferenceInput
          source="companyId"
          reference={ResourceName.COMPANIES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

export const UserList = ({ ...props }: ListProps) => {
  const { loading } = useListContext()
  const hasAuthority = useHasAuthority()

  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      exporter={false}
      filters={<UsersFilters />}
      bulkActionButtons={false}
    >
      <Datagrid
        size="small"
        loaded={!loading}
        rowClick={(id) => `${ResourceName.USERS}/${id}/show`}
      >
        <TextField source="id" label="ID" />
        <TextField source="userName" />
        {hasAuthority(Authority.VIEW_COMPANIES) && (
          <ReferenceField
            source="companyId"
            reference={ResourceName.COMPANIES}
            link="show"
            sortable={false}
          >
            <FunctionField<CompanyDto>
              source="name"
              render={(company?: CompanyDto) =>
                `${company?.id}. ${company?.name}`
              }
            />
          </ReferenceField>
        )}
      </Datagrid>
    </List>
  )
}
