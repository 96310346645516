export const mapping: Record<string, string> = {
  id: 'd=>d.Id',
  name: 'd=>d.Name',
  isWatched: 'd=>d.IsWatched',
  lastRefresh: 'd=>d.LastRefresh',
}

const mapSortDeviceParam = (key: string) => mapping[key]

export default mapSortDeviceParam
