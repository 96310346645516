import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { EVENTS_URL } from '../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../common/data-provider'
import { DateTime, ignoreTimezoneOffset } from '../common/date-time'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  recurringJobParamsComposer,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { EventCompaniesDto } from '../dto/event/event-companies.dto'
import { EventDevicesDto } from '../dto/event/event-devices.dto'
import { EventExtendedDto } from '../dto/event/event-extended.dto'
import { EventObjectsDto } from '../dto/event/event-objects.dto'
import { EventDto } from '../dto/event/event.dto'
import { RecurringJobCreationParamsDto } from '../dto/recurring-job-creation-params.dto'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'
import { EventStatus } from '../enum/EventStatus'
import filterMapper from './event-filter.mapper'
import { mapSortEventParam } from './event-sort.mapper'

const extendEventDto = (events: EventDto[]): EventExtendedDto[] =>
  events.map((event) => ({
    ...event,
    nameWithDate: `${event.name}  (${event.dateStart.slice(0, 10)})`,
  })) as EventExtendedDto[]

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<EventExtendedDto>> => {
    const filterParams = `e=>${filterParamsComposer('e', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortEventParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<EventDto[]>(
      `${EVENTS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data: extendEventDto(data),
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<EventExtendedDto>> => {
    const event = await get<EventDto>(EVENTS_URL, `/${id}`)

    return {
      data: extendEventDto([event])?.[0],
    }
  },
  create: async (
    resource: string,
    { data }: CreateParams<EventDto>,
  ): Promise<CreateResult<EventDto>> => {
    const event = await post<CreateEventRequest[], EventDto[]>(EVENTS_URL, [
      {
        ...data,
        dateStart: ignoreTimezoneOffset(
          new Date(data.dateStart),
        ).toISOString() as DateTime,
        dateEnd: ignoreTimezoneOffset(
          new Date(data.dateEnd),
        ).toISOString() as DateTime,
      },
    ])

    return {
      data: event[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<EventDto>,
  ): Promise<UpdateResult<EventExtendedDto>> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, EventDto>(
      EVENTS_URL,
      {
        id,
        name: data.name !== previousData.name ? data.name : undefined,
        dateStart:
          data.dateStart !== previousData.dateStart
            ? (ignoreTimezoneOffset(
                new Date(data.dateStart),
              ).toISOString() as DateTime)
            : undefined,
        dateEnd:
          data.dateEnd !== previousData.dateEnd
            ? (ignoreTimezoneOffset(
                new Date(data.dateEnd),
              ).toISOString() as DateTime)
            : undefined,
        externalId:
          data.externalId !== previousData.externalId
            ? data.externalId
            : undefined,
        infix: data.infix !== previousData.infix ? data.infix : undefined,
      },
    )
    return Promise.resolve({ data: extendEventDto([update])?.[0] })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<EventExtendedDto>> => {
    const data = await get<EventDto[]>(
      EVENTS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data: extendEventDto(data) })
  },
  activate: async (
    resource: string,
    { eventId, ...rest }: ChangeStateParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    let data

    if (!rest?.cronExpression || !rest?.recurringJobName) {
      data = await put<undefined, ChangeStateResponse | RecurringJobDetailsDto>(
        `${EVENTS_URL}/ActivateAsyncJob/${eventId}`,
        undefined,
      )
    } else {
      const query = recurringJobParamsComposer(
        rest as RecurringJobCreationParamsDto,
      )
      data = await put<undefined, ChangeStateResponse | RecurringJobDetailsDto>(
        `${EVENTS_URL}/Activate/${eventId}${query ? `?${query}` : ''}`,
        undefined,
      )
    }

    return Promise.resolve({ data })
  },
  deactivate: async (
    resource: string,
    { eventId, ...rest }: ChangeStateParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    let data

    if (!rest?.cronExpression || !rest?.recurringJobName) {
      data = await put<undefined, ChangeStateResponse | RecurringJobDetailsDto>(
        `${EVENTS_URL}/DeactivateAsyncJob/${eventId}`,
        undefined,
      )
    } else {
      const query = recurringJobParamsComposer(
        rest as RecurringJobCreationParamsDto,
      )
      data = await put<undefined, ChangeStateResponse | RecurringJobDetailsDto>(
        `${EVENTS_URL}/Deactivate/${eventId}${query ? `?${query}` : ''}`,
        undefined,
      )
    }

    return Promise.resolve({ data })
  },
  getCompanies: async (
    resource: string,
    { eventId }: GetCompaniesParams,
  ): Promise<CacheableDataProviderExtensionResult<EventCompaniesDto>> => {
    const companies = await get<EventCompaniesDto>(
      `${EVENTS_URL}`,
      `/GetCompanies/${eventId}`,
    )
    return {
      data: companies,
    }
  },
  attachCompanies: async (
    resource: string,
    params: EventCompaniesDto,
  ): Promise<CacheableDataProviderExtensionResult<EventCompaniesDto>> => {
    const eventCompanies = await put<EventCompaniesDto, EventCompaniesDto>(
      `${EVENTS_URL}/AttachCompanies`,
      params,
    )
    return {
      data: eventCompanies,
    }
  },
  detachCompanies: async (
    resource: string,
    params: EventCompaniesDto,
  ): Promise<CacheableDataProviderExtensionResult<EventCompaniesDto>> => {
    const eventCompanies = await put<EventCompaniesDto, EventCompaniesDto>(
      `${EVENTS_URL}/DetachCompanies`,
      params,
    )
    return {
      data: eventCompanies,
    }
  },
  attachObjects: async (
    resource: string,
    params: EventObjectsDto,
  ): Promise<CacheableDataProviderExtensionResult<EventObjectsDto>> => {
    const eventObjects = await put<EventObjectsDto, EventObjectsDto>(
      `${EVENTS_URL}/AttachObjects`,
      params,
    )
    return {
      data: eventObjects,
    }
  },
  detachObjects: async (
    resource: string,
    params: EventObjectsDto,
  ): Promise<CacheableDataProviderExtensionResult<EventObjectsDto>> => {
    const eventObjects = await put<EventObjectsDto, EventObjectsDto>(
      `${EVENTS_URL}/DetachObjects`,
      params,
    )
    return {
      data: eventObjects,
    }
  },
  getDevices: async (
    resource: string,
    { eventId }: GetDevicesParams,
  ): Promise<CacheableDataProviderExtensionResult<EventDevicesDto>> => {
    const devices = await get<EventDevicesDto>(
      `${EVENTS_URL}`,
      `/GetDevices/${eventId}`,
    )
    return {
      data: devices,
    }
  },
  attachDevices: async (
    resource: string,
    { eventId, verifierDevicesIds, ...rest }: ChangeDevicesStateParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const attachDevices = await put<
      EventDevicesDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${EVENTS_URL}/AttachDevices${query ? `?${query}` : ''}`, {
      eventId: eventId as number,
      verifierDevicesIds,
    })
    if ((attachDevices as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: attachDevices as RecurringJobDetailsDto,
      }
    return {
      data: attachDevices,
    }
  },
  detachDevices: async (
    resource: string,
    { eventId, verifierDevicesIds, ...rest }: ChangeDevicesStateParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const detachDevices = await put<
      EventDevicesDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${EVENTS_URL}/DetachDevices${query ? `?${query}` : ''}`, {
      eventId: eventId as number,
      verifierDevicesIds,
    })
    if ((detachDevices as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: detachDevices as RecurringJobDetailsDto,
      }
    return {
      data: detachDevices,
    }
  },
  deactivateAccessUsings: async (
    resource: string,
    { eventId, ...rest }: ChangeStateParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const deactivateAccessUsings = await put<
      undefined,
      ChangeStateResponse | RecurringJobDetailsDto
    >(
      `${EVENTS_URL}/DeactivateAccessUsingsAsyncJob/${eventId}${
        query ? `?${query}` : ''
      }`,
      undefined,
    )
    if ((deactivateAccessUsings as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: deactivateAccessUsings as RecurringJobDetailsDto,
      }
    return {
      data: deactivateAccessUsings,
    }
  },
} as EventDataProvider

interface EventDataProvider extends DataProvider {
  activate: (
    resource: string,
    params: ChangeStateParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  deactivate: (
    resource: string,
    params: ChangeStateParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  getCompanies: (
    resource: string,
    params: GetCompaniesParams,
  ) => Promise<CacheableDataProviderExtensionResult<EventCompaniesDto>>

  attachCompanies: (
    resource: string,
    params: EventCompaniesDto,
  ) => Promise<CacheableDataProviderExtensionResult<EventCompaniesDto>>

  detachCompanies: (
    resource: string,
    params: EventCompaniesDto,
  ) => Promise<CacheableDataProviderExtensionResult<EventCompaniesDto>>

  getObjectsList: (
    resource: string,
    params: GetObjectsParams,
  ) => Promise<CacheableDataProviderExtensionResult<EventObjectsDto>>

  attachObjects: (
    resource: string,
    params: EventObjectsDto,
  ) => Promise<CacheableDataProviderExtensionResult<EventObjectsDto>>

  detachObjects: (
    resource: string,
    params: EventObjectsDto,
  ) => Promise<CacheableDataProviderExtensionResult<EventObjectsDto>>

  getDevices: (
    resource: string,
    params: GetDevicesParams,
  ) => Promise<CacheableDataProviderExtensionResult<EventDevicesDto>>

  attachDevices: (
    resource: string,
    params: ChangeDevicesStateParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  detachDevices: (
    resource: string,
    params: ChangeDevicesStateParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  deactivateAccessUsings: (
    resource: string,
    params: ChangeStateParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >
}

export interface ChangeStateParams extends RecurringJobCreationParamsDto {
  eventId: Identifier
}

export interface ChangeDevicesStateParams extends ChangeStateParams {
  readonly verifierDevicesIds: number[]
}

export interface ChangeStateResponse {
  jobId: Identifier
}

export interface GetCompaniesParams {
  eventId: Identifier
}

export interface GetObjectsParams {
  eventId: Identifier
}

export interface GetDevicesParams {
  eventId: Identifier
}

export interface CreateEventRequest {
  readonly name: string
  readonly status?: EventStatus
  readonly dateStart: DateTime
  readonly dateEnd: DateTime
  readonly externalId?: string
  readonly infix?: string
  readonly objectsIds?: number[]
}

export default provider
