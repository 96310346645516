import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  CircularProgress,
  LinearProgress,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { Close, Error } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import HelpIcon from '@material-ui/icons/Help'
import React from 'react'
import { useTranslate } from 'react-admin'
import { PassageDto } from '../../core/dto/device/passages/passage.dto'
import DraggableComponent from './DraggableComponent'
import { ApiErrorDto } from '../../core/dto/error-handling/api-error.dto'
import { ResourceName } from '../../core/ResourceName'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bulkProgressActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      userSelect: 'none',
    },
    bulkProgressTitle: {
      paddingLeft: '10px',
      width: '100%',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
    successIcon: {
      color: theme.palette.success.main,
    },
    warningIcon: {
      color: theme.palette.warning.main,
    },
  }),
)

export interface ProgressStatus {
  readonly id: number
  readonly status: 'error' | 'pending' | 'success'
  readonly error?: ApiErrorDto
}

export const StateBulkProgressDialog = ({
  open,
  close,
  elements,
  method,
  statuses,
  resource,
}: {
  open: boolean
  close: () => void
  elements: any[]
  method: string
  statuses: ProgressStatus[]
  resource: ResourceName
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  let pending = true

  const renderGlobalStatus = () => {
    if (!statuses.length) {
      pending = false
      return <></>
    }
    if (statuses.some((s) => s.status === 'pending')) {
      pending = true
      return <CircularProgress size="20px" />
    }
    if (statuses.every((s) => s.status === 'error')) {
      pending = false
      return <Error className={classes.errorIcon} />
    }
    if (statuses.every((s) => s.status === 'success')) {
      pending = false
      return <CheckCircleIcon className={classes.successIcon} />
    }
    pending = false
    return <WarningIcon className={classes.warningIcon} />
  }

  const renderStatus = (element: any) => {
    switch (
      statuses &&
      statuses.filter((status) => status?.id === element?.id)?.[0]?.status
    ) {
      case 'pending':
        return <CircularProgress size="20px" />
      case 'success':
        return <CheckCircleIcon className={classes.successIcon} />
      case 'error':
        return <Error className={classes.errorIcon} />
      default:
        return <HelpIcon className={classes.warningIcon} />
    }
  }

  const renderDetails = (element: PassageDto) => {
    const renderedStatus =
      statuses && statuses.filter((status) => status?.id === element?.id)?.[0]
    let result
    if (renderedStatus?.error?.body) {
      result = `${renderedStatus?.error?.body?.message?.toString()}
    (${renderedStatus?.error?.body?.identifier?.toString()})`
    } else {
      result = renderedStatus?.error?.message
    }
    return <Typography>{result}</Typography>
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogActions
        className={classes.bulkProgressActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(`resources.${resource}.dialogs.${method}.bulk.title`)}
        </Typography>
        {renderGlobalStatus()}
        {!pending && (
          <IconButton
            size="small"
            className={classes.bulkProgressCloseButton}
            onClick={close}
          >
            <Close fontSize="small" />
          </IconButton>
        )}
      </DialogActions>
      <DialogContent>
        {elements?.length ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>
                    <Typography>
                      {translate(
                        `resources.${resource}.dialogs.${method}.bulk.fields.name`,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {translate(
                        `resources.${resource}.dialogs.${method}.bulk.fields.status`,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {translate(
                        `resources.${resource}.dialogs.${method}.bulk.fields.details`,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {elements.map((element) => (
                  <TableRow key={element?.id}>
                    <TableCell>
                      <Typography>{element?.id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{element?.name}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      {renderStatus(element)}
                    </TableCell>
                    <TableCell>{renderDetails(element)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <Divider />
    </Dialog>
  )
}
