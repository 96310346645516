import { makeStyles } from '@material-ui/core'
import React from 'react'
import {
  ListProps,
  Pagination,
  TextField,
  FunctionField,
  useTranslate,
  Filter,
  TextInput,
  Datagrid,
  useQuery,
  GET_MANY,
  Record,
  Labeled,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { Authority } from '../../../core/auth/Authority'
import { VariantDto } from '../../../core/dto/variant.dto'
import { ResourceName } from '../../../core/ResourceName'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const useStyles = makeStyles((theme) => ({
  expand: {
    padding: 8,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}))

const VariantsFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

const VariantListExpand = ({ ...props }) => {
  const { record } = props
  const classes = useStyles()

  const { data: accesses } = useQuery(
    {
      resource: ResourceName.ACCESSES,
      type: GET_MANY,
      payload: {
        ids: record?.accessesIds?.slice(0, 5),
      },
    },
    {
      enabled: !!record && !!record.accessesIds && !!record.accessesIds.length,
    },
  )

  return (
    <div className={classes.expand}>
      <Labeled resource={ResourceName.VARIANTS} source="accessesIds">
        <div>
          {accesses
            ?.map((access) => (
              <Link
                className={classes.link}
                to={`/accesses/${access?.id}/show`}
              >
                {access?.name}
              </Link>
            ))
            ?.reduce((previous, current) => [previous, ', ', current])}
          {record?.accessesIds?.length > 5 && '...'}
        </div>
      </Labeled>
    </div>
  )
}

export const VariantList = (props: ListProps) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  return (
    <List
      perPage={20}
      exporter={false}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      bulkActionButtons={false}
      filters={<VariantsFilters />}
      hasCreate={hasAuthority(Authority.CREATE_VARIANTS)}
    >
      <Datagrid
        rowClick={(id) => `${ResourceName.VARIANTS}/${id}/show`}
        expand={<VariantListExpand />}
        isRowExpandable={(record?: Record) =>
          !!record?.accessesIds && !!record.accessesIds.length
        }
      >
        <TextField source="id" />
        <TextField source="name" />
        <FunctionField<VariantDto>
          source="active"
          render={(record?: VariantDto) =>
            record?.active ? translate('const.yes') : translate('const.no')
          }
        />
      </Datagrid>
    </List>
  )
}
