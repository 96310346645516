import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
  GetManyParams,
  GetManyResult,
} from 'react-admin'
import { OBJECTS_URL } from '../api-urls'
import { get, getWithPagination, patch, post } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { ObjectDto } from '../dto/object.dto'
import filterMapper from './object-filter.mapper'
import { mapSortObjectParam } from './object-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<ObjectDto>> => {
    const filterParams = `o=>${filterParamsComposer('o', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortObjectParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<ObjectDto[]>(
      `${OBJECTS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<ObjectDto>> => {
    const data = await get<ObjectDto>(OBJECTS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource,
    { data }: CreateParams<CreateObjectRequest>,
  ): Promise<CreateResult<ObjectDto>> => {
    const object = await post<CreateObjectRequest[], ObjectDto[]>(OBJECTS_URL, [
      data,
    ])
    return {
      data: object[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, ObjectDto>(
      OBJECTS_URL,
      {
        id,
        name: data.name !== previousData.name ? data.name : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<ObjectDto>> => {
    const data = await get<ObjectDto[]>(
      OBJECTS_URL,
      `/GetByConditions/o=>new int[] {${ids.toString()}}.Contains(o.Id)`,
    )
    return Promise.resolve({ data })
  },
} as DataProvider

export interface CreateObjectRequest {
  readonly name: string
}

export default provider
