import { DataProvider } from 'react-admin'
import { TicketImportDto } from '../../dto/ticket-import.dto'

const fakeTicketImports: TicketImportDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeTicketImports,
      total: fakeTicketImports.length,
    }),
} as DataProvider

export default provider
