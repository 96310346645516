import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  ListButton,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { CompanyDto } from '../../../core/dto/company.dto'

const validateCompanyCreate = (values: CompanyDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.companies.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const CompanyCreate = (props: CreateProps) => (
  <Create {...props} title={<CreateTitle />}>
    <SimpleForm
      redirect="show"
      validate={validateCompanyCreate}
      toolbar={<CreateToolbar />}
    >
      <TextInput source="name" isRequired />
    </SimpleForm>
  </Create>
)
