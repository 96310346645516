import React, { cloneElement, useCallback, useMemo, } from 'react';
import PropTypes from 'prop-types';
import { useCreate, useNotify, useRefresh, CRUD_CREATE, useSaveModifiers, SaveContextProvider, useResourceContext, } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { CreateRowContext } from './CreateRowContext';
var EditableDatagridCreateForm = function (props) {
    var basePath = props.basePath, classes = props.classes, expand = props.expand, hasBulkActions = props.hasBulkActions, createForm = props.createForm, hasStandaloneCreateForm = props.hasStandaloneCreateForm, isStandaloneCreateFormVisible = props.isStandaloneCreateFormVisible, closeStandaloneCreateForm = props.closeStandaloneCreateForm;
    var history = useHistory();
    var notify = useNotify();
    var refresh = useRefresh();
    var resource = useResourceContext(props);
    var _a = useCreate(resource, {}), create = _a[0], saving = _a[1].loading;
    var hideCreateForm = useCallback(function () {
        if (hasStandaloneCreateForm) {
            closeStandaloneCreateForm();
        }
        else {
            history.push(basePath);
        }
    }, [basePath, closeStandaloneCreateForm, hasStandaloneCreateForm, history]);
    var defaultOnSuccess = useCallback(function () {
        notify('ra.notification.created', 'info', {
            smart_count: 1,
        });
        refresh();
        hideCreateForm();
    }, [hideCreateForm, notify, refresh]);
    var defaultOnFailure = useCallback(function (error) {
        notify(typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error', 'warning');
    }, [notify]);
    var _b = useSaveModifiers({
        onFailure: defaultOnFailure,
        onSuccess: defaultOnSuccess,
    }), onSuccessRef = _b.onSuccessRef, setOnSuccess = _b.setOnSuccess, onFailureRef = _b.onFailureRef, setOnFailure = _b.setOnFailure, transformRef = _b.transformRef, setTransform = _b.setTransform;
    var save = useCallback(function (data, _, // unused redirectTo
    _a) {
        var // unused redirectTo
        _b = _a === void 0 ? {} : _a, onSuccessFromSave = _b.onSuccess, onFailureFromSave = _b.onFailure, transformFromSave = _b.transform;
        return Promise.resolve(transformFromSave
            ? transformFromSave(data)
            : transformRef.current
                ? transformRef.current(data)
                : data).then(function (data) {
            return create({ payload: { data: data } }, {
                action: CRUD_CREATE,
                onSuccess: onSuccessFromSave
                    ? onSuccessFromSave
                    : !!onSuccessRef.current
                        ? onSuccessRef.current
                        : defaultOnSuccess,
                onFailure: onFailureFromSave
                    ? onFailureFromSave
                    : !!onFailureRef.current
                        ? onFailureRef.current
                        : defaultOnFailure,
            });
        });
    }, [
        create,
        defaultOnFailure,
        defaultOnSuccess,
        onFailureRef,
        onSuccessRef,
        transformRef,
    ]);
    var saveContext = {
        save: save,
        saving: saving,
        setOnSuccess: setOnSuccess,
        setOnFailure: setOnFailure,
        setTransform: setTransform,
        onSuccessRef: onSuccessRef,
        onFailureRef: onFailureRef,
        transformRef: transformRef,
    };
    var createContext = useMemo(function () { return ({
        close: hideCreateForm,
    }); }, [hideCreateForm]);
    var createFormElement = (React.createElement(SaveContextProvider, { value: saveContext },
        React.createElement(CreateRowContext.Provider, { value: createContext }, cloneElement(createForm, {
            classes: classes,
            expand: expand,
            hasBulkActions: hasBulkActions,
            id: 'new_record',
            quitEditMode: hideCreateForm,
            record: {},
            resource: resource,
            basePath: basePath,
            save: save,
            saving: saving,
            selectable: false,
        }))));
    if (hasStandaloneCreateForm) {
        // create form triggered by state
        return isStandaloneCreateFormVisible && createFormElement;
    }
    // create form in a route
    return React.createElement(Route, { path: basePath + "/create" }, createFormElement);
};
EditableDatagridCreateForm.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.any,
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool.isRequired,
    resource: PropTypes.string,
    createForm: PropTypes.element,
    hasStandaloneCreateForm: PropTypes.bool,
    isStandaloneCreateFormVisible: PropTypes.bool.isRequired,
    closeStandaloneCreateForm: PropTypes.func.isRequired,
};
export default EditableDatagridCreateForm;
