import { DataProvider } from 'react-admin'
import { DateTime } from '../../common/date-time'
import { EventStandsResponse } from '../../stats/overall-view/overall-view'

const fakeStatsOverallViewList: EventStandsResponse[] = [
  {
    id: 1,
    eventName: 'testEvent1',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    capacity: 0,
    sold: 0,
    entered: 0,
    eventExternalId: 'test1',
    objects: [],
  },
  {
    id: 2,
    eventName: 'testEvent2',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    capacity: 0,
    sold: 0,
    entered: 0,
    eventExternalId: 'test2',
    objects: [],
  },
  {
    id: 3,
    eventName: 'testEvent3',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    capacity: 0,
    sold: 0,
    entered: 0,
    eventExternalId: 'test3',
    objects: [],
  },
  {
    id: 4,
    eventName: 'testEvent4',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    capacity: 0,
    sold: 0,
    entered: 0,
    eventExternalId: 'test4',
    objects: [],
  },
  {
    id: 5,
    eventName: 'testEvent5',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    capacity: 0,
    sold: 0,
    entered: 0,
    eventExternalId: 'test5',
    objects: [],
  },
]

const provider = {
  getOne: (resource, { id }: { id: string }) => {
    const found = fakeStatsOverallViewList.find((v) => `${v.id}` === id)
    if (found) {
      return Promise.resolve({
        data: found,
      })
    }
    return Promise.reject(new Error())
  },
} as DataProvider

export default provider
