import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Close, History, Settings } from '@material-ui/icons'
import React, { forwardRef, useState } from 'react'
import {
  AppBar,
  AppBarProps,
  CoreLayoutProps,
  Labeled,
  MenuItemLink,
  TextField,
  UserMenu,
  UserMenuProps,
  sanitizeFetchType,
  useQuery,
  useTranslate,
} from 'react-admin'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import { ThemeState } from '../../core/common/theme.reducer'
import { Layout } from '../../lib/@react-admin/ra-enterprise/esm/src'
import ApiErrorModal from '../common/ApiErrorModal'
import { extendedDarkTheme, extendedLightTheme } from './app.theme'
import { AppMenu } from './AppMenu'
import guiVersionJson from '../../build.json'
import { ResourceName } from '../../core/ResourceName'
import DraggableComponent from '../common/DraggableComponent'

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  menuItem: {
    color: theme.palette.text.secondary,
  },
  menuItemIcon: {
    minWidth: theme.spacing(5),
  },
  dialogContent: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
}))

const AppVersionDialog = ({ ...props }) => {
  const { open, handleClose } = props
  const classes = useStyles()
  const translate = useTranslate()

  const { InformationalVersion: guiVersion } = guiVersionJson
  const { data: apiVersionResponse } = useQuery(
    {
      type: sanitizeFetchType('getAPIVersion'),
      resource: ResourceName.CURRENT_USER,
      payload: {},
    },
    {
      enabled: open,
    },
  )

  const versions = { apiVersion: apiVersionResponse?.version, guiVersion }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('versions.name')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <Labeled label="versions.fields.apiVersion" source="apiVersion">
          <TextField record={versions as any} source="apiVersion" />
        </Labeled>
        <Labeled label="versions.fields.guiVersion" source="guiVersion">
          <TextField record={versions as any} source="guiVersion" />
        </Labeled>
      </DialogContent>
    </Dialog>
  )
}

const AppVersionButton = ({ ...props }) => {
  const { handleOpenVersionDialog, onClick, ...rest } = props
  const translate = useTranslate()
  const classes = useStyles()

  const handleOnClick = () => {
    handleOpenVersionDialog()
    onClick()
  }

  return (
    <MenuItem {...rest} className={classes.menuItem} onClick={handleOnClick}>
      <ListItemIcon className={classes.menuItemIcon}>
        <History />
      </ListItemIcon>
      {translate('versions.name')}
    </MenuItem>
  )
}

interface TicketAPIUserMenuProps extends UserMenuProps {
  readonly handleOpenVersionDialog: () => void
}

const AppUserMenu = (props: TicketAPIUserMenuProps) => {
  const { handleOpenVersionDialog, ...rest } = props
  const translate = useTranslate()

  const AppVersionButtonForwardRef = forwardRef((forwardRefProps, ref) => (
    <AppVersionButton
      innerRef={ref}
      {...forwardRefProps}
      handleOpenVersionDialog={handleOpenVersionDialog}
    />
  ))

  return (
    <UserMenu {...rest}>
      <MenuItemLink
        to="/configuration"
        primaryText={translate('settings.name')}
        leftIcon={<Settings />}
      />
      <AppVersionButtonForwardRef />
    </UserMenu>
  )
}

const AppTopBar = (props: AppBarProps) => {
  const classes = useStyles()
  const modalEl = document.getElementById('api-error-modal')

  const [versionDialogOpen, setVersionDialogOpen] = useState<boolean>(false)

  const handleOpenVersionDialog = () => {
    setVersionDialogOpen(true)
  }

  const handleCloseVersionDialog = () => {
    setVersionDialogOpen(false)
  }

  return (
    <>
      {modalEl && createPortal(<ApiErrorModal />, modalEl)}
      <AppVersionDialog
        open={versionDialogOpen}
        handleClose={handleCloseVersionDialog}
      />
      <AppBar
        {...props}
        userMenu={
          <AppUserMenu handleOpenVersionDialog={handleOpenVersionDialog} />
        }
        position="absolute"
      >
        <Typography
          variant="h6"
          className={classes.title}
          id="react-admin-title"
        />
      </AppBar>
    </>
  )
}

export const AppLayout = (props: CoreLayoutProps) => {
  const theme = useSelector((state: ThemeState) =>
    state.theme.darkMode === 'dark' ? extendedDarkTheme : extendedLightTheme,
  )

  return <Layout {...props} menu={AppMenu} appBar={AppTopBar} theme={theme} />
}
