import { Box } from '@material-ui/core'
import * as React from 'react'
import {
  Datagrid,
  FilterButton,
  List,
  ListActionsProps,
  ListProps,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin'
import {
  EditableDatagrid,
  RowForm,
} from '../../../lib/@react-admin/ra-editable-datagrid'
import ParameterizedCreateButton from '../../common/ParameterizedCreateButton'
import Empty from '../Empty'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'

const ListFilters = () => [<TextInput source="name" alwaysOn />]
const EditForm = ({ ...props }) => (
  <RowForm {...props}>
    <TextField source="id" label="ID" />
    <TextInput source="name" />
  </RowForm>
)

const TribuneGrid = ({ ...props }) => {
  const hasAuthority = useHasAuthority()

  if (hasAuthority(Authority.EDIT_TRIBUNES))
    return (
      <EditableDatagrid
        {...props}
        size="small"
        editForm={<EditForm />}
        noDelete
      >
        <TextField source="id" label="ID" />
        <TextField source="name" />
      </EditableDatagrid>
    )

  return (
    <Datagrid {...props} size="small">
      <TextField source="id" label="ID" />
      <TextField source="name" />
    </Datagrid>
  )
}

interface TribuneListActions extends ListActionsProps {
  objectId?: number
}

const ListActions = ({ objectId }: TribuneListActions) => {
  const hasAuthority = useHasAuthority()

  return (
    <TopToolbar>
      <FilterButton />
      <ParameterizedCreateButton
        searchParams={{ objectId }}
        disabled={!hasAuthority(Authority.CREATE_TRIBUNES)}
      />
    </TopToolbar>
  )
}

const EmptyView = ({ objectId }: { objectId?: number }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <Box>
      <Empty text={translate('resources.tribunes.empty')} />
      <TopToolbar>
        <ParameterizedCreateButton
          searchParams={{ objectId }}
          disabled={!hasAuthority(Authority.CREATE_TRIBUNES)}
        />
      </TopToolbar>
    </Box>
  )
}

interface TribuneListProps extends ListProps {
  objectId?: number
}

const TribunesList = ({ objectId, ...props }: TribuneListProps) => {
  const { basePath } = useListContext()

  return (
    <List
      {...props}
      basePath={basePath}
      exporter={false}
      filters={ListFilters()}
      actions={<ListActions objectId={objectId} />}
      bulkActionButtons={false}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      empty={<EmptyView objectId={objectId} />}
    >
      <TribuneGrid />
    </List>
  )
}

export default TribunesList
