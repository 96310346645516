import {
  CircularProgress,
  Dialog,
  DialogActions,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close, InsertLink } from '@material-ui/icons'
import React from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useHistory } from 'react-router'
import Button from '../../common/customized-mui-components/Button'
import DraggableComponent from '../../common/DraggableComponent'

const useStyles = makeStyles((theme: Theme) => ({
  dialogProperty: {
    marginBottom: theme.spacing(1),
  },
  dialog: {
    '& > div > div': {
      minWidth: '500px',
    },
  },
  actionsButton: {
    margin: '2%',
    width: '96%',
  },
  dialogActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  dialogTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  dialogCloseButton: {
    margin: 0,
  },
}))

interface JobResult {
  readonly jobId: number
}

interface SyncCardsDialogProps {
  readonly handleClose: () => void
  readonly open: boolean
  readonly result: JobResult | undefined
}

export const SyncCardsDialog = (props: SyncCardsDialogProps) => {
  const { handleClose, open, result } = props
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()
  const notify = useNotify()

  const handleRedirect = () => {
    if (result && result.jobId) {
      const { jobId } = result
      history.push(`/jobs/${jobId}/show`)
    } else {
      notify('ra.page.error')
      handleClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-confirm-title"
      className={classes.dialog}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogActions
        className={classes.dialogActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.dialogTitle}
          id="draggable-confirm-title"
        >
          {translate('common.job.dialog.result')}
        </Typography>
        <IconButton
          size="small"
          className={classes.dialogCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleRedirect}
          label={translate('common.job.actions.redirect')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
          startIcon={result ? <InsertLink /> : <CircularProgress size="1rem" />}
          disabled={!result}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('ra.action.close')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}
