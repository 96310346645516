import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import Button from '../../common/customized-mui-components/Button'
import DraggableComponent from '../../common/DraggableComponent'
import { BookingDisabledOption } from '../../../core/enum/BookingDisabledOption'

const useStyles = makeStyles((theme: Theme) => ({
  dialogProperty: {
    marginBottom: theme.spacing(1),
  },
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  actionsButton: {
    margin: '2%',
    width: '96%',
  },
  formToolbar: {
    backgroundColor: 'inherit',
    padding: 0,
    margin: 0,
  },
  dialogContent: {
    padding: '8px',
  },
}))

export const ToggleBookingBlockadeDialog = ({
  open,
  close,
  disabledOption,
  setDisabledOption,
  handleOpenConfirm,
}: {
  open: boolean
  close: () => void
  disabledOption: BookingDisabledOption | undefined
  setDisabledOption: (value: BookingDisabledOption | undefined) => void
  handleOpenConfirm: () => void
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const handleSetDisabledOption = (e) => {
    setDisabledOption(e?.target?.value)
  }

  useEffect(() => {
    if (!open) {
      setDisabledOption(undefined)
    }
  }, [open, setDisabledOption])

  return (
    <Dialog
      open={open}
      onClose={close}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(`resources.bookings.dialogs.toggleBlockade.title`)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={close}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <Select
          variant="outlined"
          value={disabledOption}
          onChange={handleSetDisabledOption}
          fullWidth
        >
          <MenuItem value={BookingDisabledOption.ADMIN}>
            {translate(
              `resources.enums.bookingDisabled.${BookingDisabledOption.ADMIN}`,
            )}
          </MenuItem>
          <MenuItem value={BookingDisabledOption.BLACK_LISTED}>
            {translate(
              `resources.enums.bookingDisabled.${BookingDisabledOption.BLACK_LISTED}`,
            )}
          </MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleOpenConfirm}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
          disabled={!disabledOption}
        />
        <Button
          variant="contained"
          onClick={close}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}
