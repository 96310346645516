import { useTheme } from '@material-ui/core'
import React from 'react'
import {
  DateField,
  FunctionField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useTranslate,
} from 'react-admin'
import ReactJson from 'react-json-view'
import { JobDetailsRaResponse } from '../../../core/jobs/jobs.provider'
import { ExtendedTheme } from '../../layout/app.theme'

interface JobTitleProps extends TitleProps {
  record?: JobDetailsRaResponse
}

const ShowTitle = (data: JobTitleProps) => {
  const { record: job } = data
  const translate = useTranslate()

  return (
    <span>
      {translate('resources.jobs.titles.show')}: {job?.jobId}
    </span>
  )
}

const JobGeneralTab = ({ ...props }) => {
  const { record: job } = props
  const translate = useTranslate()

  return (
    <Tab {...props} label="resources.jobs.tabs.general">
      <TextField source="jobId" />
      <FunctionField<JobDetailsRaResponse>
        source="jobStatus"
        render={(record?: JobDetailsRaResponse) =>
          translate(`resources.enums.jobStatus.${record?.jobStatus}`)
        }
      />
      {job?.jobName && <TextField source="jobName" />}
      <TextField source="jobTargetMethod" />
      <DateField source="jobCreationDate" showTime />
      <DateField source="jobExpirationDate" showTime />
      {job?.jobExecutionDate && (
        <DateField source="jobExecutionDate" showTime />
      )}
      {job?.jobScheduledAtDate && (
        <DateField source="jobScheduledAtDate" showTime />
      )}
    </Tab>
  )
}

const JobResultTab = ({ ...props }) => {
  const translate = useTranslate()
  const { record: job } = props
  const theme = useTheme<ExtendedTheme>()

  return (
    <Tab {...props} label="resources.jobs.tabs.result" path="result">
      <TextField source="jobResult.statusCode" />
      {job?.jobResult?.value && (
        <>
          <ReactJson
            src={job?.jobResult?.value}
            theme={theme.palette.rjvTheme}
            iconStyle="triangle"
            name={translate(
              'resources.recurring-jobs.fields.oldExecutionJobResult',
            )}
            style={{
              marginBottom: theme.spacing(1),
              padding: theme.spacing(1),
            }}
          />
        </>
      )}
    </Tab>
  )
}

const JobTabs = ({ ...props }) => {
  const { record } = props

  return (
    <TabbedShowLayout {...props}>
      <JobGeneralTab />
      {record?.jobResult && <JobResultTab />}
    </TabbedShowLayout>
  )
}

export const JobShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <JobTabs />
  </Show>
)
