import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DetectorDto } from '../../../core/dto/device/detector/detector.dto'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DeviceStatusesTab } from '../common/modules/ModuleStatus'
import { DetectorForceReportStateButton } from './detector-show/DetectorForceReportStateButton'
import { CameraDto } from '../../../core/dto/device/camera/cameras/camera.dto'
import { DeviceCategoryExtendedDto } from '../../../core/dto/device-category/device-category-extended.dto'
import { DeviceTypes } from '../../../core/enum/DeviceTypes'

interface EngineTitleProps extends TitleProps {
  record?: DetectorDto
}

const ShowTitle = (data: EngineTitleProps) => {
  const { record: detector } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.detectors.titles.show')}: {detector?.id}.{' '}
      {detector?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: detector } = useShowContext<DetectorDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath={`/${ResourceName.DETECTORS}`}
          record={detector}
          variant="contained"
          disabled={!hasAuthority(Authority.EDIT_DETECTORS)}
        />
      </Box>
    </>
  )
}

const SetStateActions = () => (
  <>
    <Box p={1} display="block" textAlign="right">
      <DetectorForceReportStateButton />
    </Box>
  </>
)

const DetectorTabs = ({ ...props }) => {
  const translate = useTranslate()
  const { record: detector } = useShowContext<DetectorDto>()
  const hasAuthority = useHasAuthority()
  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.detectors.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <FunctionField<DetectorDto>
          source="isWatched"
          render={(record?: DetectorDto) => (
            <div>
              {record?.isWatched
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
        <DateField
          source="lastRefresh"
          showTime
          options={{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }}
        />
        {detector?.categoryId &&
          hasAuthority(Authority.VIEW_DEVICE_CATEGORIES) && (
            <ReferenceField
              source="categoryId"
              reference={ResourceName.DEVICE_CATEGORIES}
              link="show"
            >
              <FunctionField<DeviceCategoryExtendedDto>
                source="hierarchyString"
                render={(record?: DeviceCategoryExtendedDto) =>
                  `${record?.id}. ${record?.hierarchyString}`
                }
              />
            </ReferenceField>
          )}
        {detector?.cameraId && hasAuthority(Authority.VIEW_CAMERAS) && (
          <ReferenceField
            source="cameraId"
            reference={ResourceName.CAMERAS}
            link="show"
          >
            <FunctionField<CameraDto>
              source="name"
              render={(camera?: CameraDto) =>
                `${camera?.id}. ${camera?.description}`
              }
            />
          </ReferenceField>
        )}
        <FunctionField<DetectorDto>
          source="hardwareVersion"
          render={(record?: DetectorDto) => (
            <div>
              {translate(
                `resources.enums.hardwareVersions.${record?.hardwareVersion}`,
              )}
            </div>
          )}
        />
        {detector?.tsCanId && hasAuthority(Authority.VIEW_TS_CANS) && (
          <ReferenceField
            source="tsCanId"
            reference={ResourceName.TS_CANS}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        <GeneralActions />
      </Tab>
      {detector?.jsonStatus && (
        <Tab label="resources.detectors.tabs.statuses" path="statuses">
          <DeviceStatusesTab
            jsonStatus={detector.jsonStatus}
            deviceType={DeviceTypes.DETECTOR}
          />
          <SetStateActions />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const DetectorShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <DetectorTabs />
  </Show>
)
