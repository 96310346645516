import { Box, Divider, useMediaQuery, useTheme } from '@material-ui/core'
import React, { ReactElement } from 'react'
import {
  ShowProps,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  FunctionField,
  useTranslate,
  useShowContext,
  TabbedShowLayoutProps,
  TitleProps,
  EditButton,
  TextInput,
  ReferenceField,
} from 'react-admin'
import { VariantDto } from '../../../core/dto/variant.dto'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'
import { AccessBonusDto } from '../../../core/dto/access-bonus.dto'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

type VariantTabsProps = Omit<TabbedShowLayoutProps, 'children'>

interface VariantTitleProps extends TitleProps {
  record?: VariantDto
}

const ShowTitle = (data: VariantTitleProps) => {
  const { record: variant } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.variants.titles.show')}: {variant?.id}.{' '}
      {variant?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: variant } = useShowContext<VariantDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath="/variants"
          record={variant}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_VARIANTS)}
        />
      </Box>
    </>
  )
}

const VariantTabs = (props: VariantTabsProps) => {
  const { record: variant } = props
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const relationFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [
        <TextInput source="name" alwaysOn />,
        <FilteredReferenceInput
          source="variant"
          reference={ResourceName.VARIANTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          selectWithPaginationInputProps={{
            showFilter: true,
          }}
          alwaysOn
        />,
      ],
      resource: ResourceName.VARIANTS,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.variants.tabs.general">
        <TextField source="id" />
        <TextField source="name" />
        <FunctionField<VariantDto>
          source="active"
          render={(r?: VariantDto) => (
            <div>
              {r?.active ? translate('const.yes') : translate('const.no')}
            </div>
          )}
        />
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_ACCESSES) && (
        <Tab path="accesses" label="resources.variants.tabs.accesses">
          <>
            <RelationTab<VariantDto>
              resource={ResourceName.ACCESSES}
              source="accessesIds"
              mode={hasAuthority(Authority.EDIT_VARIANTS) ? 'edit' : 'show'}
              attachMethod="attachAccesses"
              detachMethod="detachAccesses"
              attachRequestPayload={(r, ids) => ({
                variantId: r.id,
                accessesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                variantId: r.id,
                accessesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterVariantId === true
              }
              filters={relationFilters() as ReactElement[]}
              filterDefaultValues={{
                variantId: variant?.id,
                filterVariantId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="name" />
              {!smallScreen && hasAuthority(Authority.VIEW_ACCESS_BONUSES) && (
                <ReferenceField
                  source="bonusId"
                  reference={ResourceName.ACCESS_BONUS}
                  link={(access) => `/access-bonuses/${access?.bonusId}/show`}
                >
                  <FunctionField<AccessBonusDto>
                    render={(record?: AccessBonusDto) =>
                      `${record?.id}. ${record?.name} (${record?.description})`
                    }
                  />
                </ReferenceField>
              )}
              {!smallScreen && (
                <TextField source="entranceId" key="entranceId" />
              )}
            </RelationTab>
          </>
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const VariantShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <VariantTabs />
  </Show>
)
