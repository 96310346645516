import {
  ReducerJobStatus,
  ToggleButtonAction,
  ToggleButtonState,
} from './restore-replication-button'

export const restoreReplicationButtonInitialState = {
  type: 'InitialState',
  text: 'resources.offline-servers.actions.restoreReplication',
  disabled: false,
}

type State = {
  disabled: boolean
} & ToggleButtonState

export const restoreReplicationButtonReducer = (
  state: State,
  { type }: ToggleButtonAction,
): State => {
  switch (type) {
    case ReducerJobStatus.INITIAL_STATE: {
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplication',
        disabled: false,
      }
    }
    case ReducerJobStatus.SUCCEEDED: {
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplicationNA',
        disabled: false,
      }
    }
    case ReducerJobStatus.FAILED: {
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplicationNA',
        disabled: false,
      }
    }
    case ReducerJobStatus.DELETED: {
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplicationNA',
        disabled: false,
      }
    }
    case ReducerJobStatus.ENQUEUED: {
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplicationNA',
        disabled: true,
      }
    }
    case ReducerJobStatus.SCHEDULED: {
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplicationNA',
        disabled: true,
      }
    }
    case ReducerJobStatus.AWAITING: {
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplicationNA',
        disabled: true,
      }
    }
    case ReducerJobStatus.PROCESSING: {
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplicationNA',
        disabled: true,
      }
    }
    default:
      return {
        type,
        text: 'resources.offline-servers.actions.restoreReplicationERR',
        disabled: true,
      }
  }
}
