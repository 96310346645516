import { DataProvider, GetListResult } from 'react-admin'
import { STATISTICS_URL } from '../../api-urls'
import { getWithPagination } from '../../common/fetch.utils'
import { queryParamsComposer } from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { mapSortEntranceViewParam } from './event-entrance-view-sort.mapper'
import { EventEntrance } from './event-entrances-view'

const provider = {
  getList: async (
    resource: string,
    { filter, pagination, sort }: TicketAPIGetListParams,
  ): Promise<GetListResult<EventEntrance>> => {
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortEntranceViewParam,
    )
    const path = `/${filter?.eventId}${pathParams ? `?${pathParams}` : ''}`

    const {
      data,
      range: { total },
    } = await getWithPagination<EventEntrance[]>(
      `${STATISTICS_URL}/GetEventEntrances`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
} as DataProvider

export default provider
