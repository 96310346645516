import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import {
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { CreateEntranceRequest } from '../../../../core/entrances/entrance.provider'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button from '../../../common/customized-mui-components/Button'
import DraggableComponent from '../../../common/DraggableComponent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogProperty: {
      marginBottom: theme.spacing(1),
    },
    dialogTopBar: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    title: {
      paddingLeft: '10px',
      width: '100%',
    },
    closeButton: {
      margin: 0,
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
    formToolbar: {
      backgroundColor: 'inherit',
      padding: 0,
      margin: 0,
    },
    dialogContent: {
      padding: '8px',
    },
  }),
)

const validateEntrancesSetCreate = (values: CreateEntranceRequest) => {
  const errors: { [n: string]: string } = {}
  if (!values.description) {
    errors.description = 'ra.validation.required'
  }
  return errors
}

export const CreateEntrancesSetDialog = ({
  open,
  close,
  setNewSetDescription,
  handleCreateEntrancesSet,
}: {
  open: boolean
  close: () => void
  setNewSetDescription: (value: string | undefined) => void
  handleCreateEntrancesSet: () => void
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const Actions = (props: ToolbarProps) => (
    <Toolbar {...props} className={classes.formToolbar}>
      <SaveButton
        variant="contained"
        size="small"
        className={classes.actionsButton}
        onSave={(values) => {
          setNewSetDescription((values as CreateEntranceRequest)?.description)
          setConfirmOpen(true)
        }}
        onSubmit={() => setConfirmOpen(true)}
      />
      <Button
        variant="contained"
        onClick={handleClose}
        label={translate('const.cancel')}
        useSmallVersionBreakpoint={false}
        className={classes.actionsButton}
      />
    </Toolbar>
  )

  useEffect(() => {
    if (!open) {
      setNewSetDescription(undefined)
      setConfirmOpen(false)
    }
  }, [open, setNewSetDescription, setConfirmOpen])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => {
          setNewSetDescription(undefined)
          setConfirmOpen(false)
        }}
        confirm={handleCreateEntrancesSet}
        source="createEntrancesSet"
        action="set"
        resource={ResourceName.ENTRANCES_MATRIX}
      />
      <DialogActions
        className={classes.dialogTopBar}
        style={{ cursor: 'move' }}
      >
        <Typography className={classes.title} id="draggable-dialog-title">
          {translate(
            'resources.entrances-matrix.dialogs.createEntrancesSet.title',
          )}
        </Typography>
        <IconButton
          size="small"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <SimpleForm
          toolbar={<Actions />}
          validate={validateEntrancesSetCreate}
          resource={ResourceName.ENTRANCES_MATRIX}
        >
          <TextInput source="description" />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}
