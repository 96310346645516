import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { AccessBonusDto } from '../../../core/dto/access-bonus.dto'
import { ResourceName } from '../../../core/ResourceName'

interface AccessBonusTitleProps extends TitleProps {
  record?: AccessBonusDto
}

const ShowTitle = (data: AccessBonusTitleProps) => {
  const { record: bonus } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.access-bonuses.titles.show')}: {bonus?.id}.{' '}
      {bonus?.name} ({bonus?.description})
    </span>
  )
}

const GeneralActions = () => {
  const { record: bonus } = useShowContext<AccessBonusDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath={`/${ResourceName.ACCESS_BONUS}`}
          record={bonus}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_ACCESS_BONUSES)}
        />
      </Box>
    </>
  )
}

export const AccessBonusShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="description" />
      <GeneralActions />
    </SimpleShowLayout>
  </Show>
)
