import {
  cloneElement,
  createElement,
  FunctionComponent,
  isValidElement,
  ReactElement,
} from 'react'

const cloneOrCreateElement = <Props extends Record<string, unknown> = any>(
  element: ReactElement<Props> | FunctionComponent<Props>,
  props: Props,
  children?: ReactElement | ReactElement[],
) =>
  isValidElement(element)
    ? cloneElement(element as ReactElement<Props>, { ...props }, children)
    : createElement(element as FunctionComponent<Props>, { ...props }, children)

export default cloneOrCreateElement
