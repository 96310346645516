import { Box, Divider, Grid, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import {
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRefresh,
  useShowContext,
  useTranslate,
} from 'react-admin'
import ReactJson from 'react-json-view'
import { TicketImportDto } from '../../core/dto/ticket-import.dto'
import { ConfirmDialog } from '../common/ConfirmDialog'
import { ResourceName } from '../../core/ResourceName'
import Button from '../common/customized-mui-components/Button'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'
import { ExtendedTheme } from '../layout/app.theme'
import { TicketImportStates } from '../../core/enum/TicketsImportStates'

const ShowTitle = ({ ...props }) => {
  const { record: ticketImport } = props
  const translate = useTranslate()

  return (
    <span>
      {translate('resources.ticket-imports.titles.show')}: {ticketImport?.name}
    </span>
  )
}

const TicketImportRevertButton = ({ ...props }) => {
  const { record: ticketImport, loading } = useShowContext<TicketImportDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const refresh = useRefresh()

  const [confirmDialogOpen, setCofirmDialogOpen] = useState<boolean>(false)

  const handleRevertImport = async () => {
    await mutate(
      {
        type: sanitizeFetchType('revertTicketsAsyncJob'),
        resource: ResourceName.TICKET_IMPORTS,
        payload: {
          importId: ticketImport?.id,
        },
      },
      {
        returnPromise: true,
        onFailure: (msg) => notify(msg, 'error'),
      },
    )
    refresh()
  }

  const handleConfirmDialogOpen = () => {
    setCofirmDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        confirm={handleRevertImport}
        source="revertTicketsAsyncJob"
        cancel={() => setCofirmDialogOpen(false)}
        action="set"
        resource={ResourceName.TICKET_IMPORTS}
      />
      <Button
        onClick={handleConfirmDialogOpen}
        label="resources.ticket-imports.actions.revertTicketsAsyncJob"
        {...props}
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.REVERT_TICKET_IMPORT) ||
          loading ||
          mutationLoading ||
          ticketImport?.state !== TicketImportStates.IMPORTED
        }
        variant="contained"
        color="primary"
        size="small"
      />
    </>
  )
}

const TicketImportAcceptButton = ({ ...props }) => {
  const { record: ticketImport, loading } = useShowContext<TicketImportDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const refresh = useRefresh()

  const [confirmDialogOpen, setCofirmDialogOpen] = useState<boolean>(false)

  const handleAcceptImport = async () => {
    await mutate(
      {
        type: sanitizeFetchType('acceptTicketsImportAsyncJob'),
        resource: ResourceName.TICKET_IMPORTS,
        payload: {
          importId: ticketImport?.id,
        },
      },
      {
        returnPromise: true,
        onFailure: (msg) => notify(msg, 'error'),
      },
    )
    refresh()
  }

  const handleConfirmDialogOpen = () => {
    setCofirmDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        confirm={handleAcceptImport}
        source="acceptTicketsImportAsyncJob"
        cancel={() => setCofirmDialogOpen(false)}
        action="set"
        resource={ResourceName.TICKET_IMPORTS}
      />
      <Button
        onClick={handleConfirmDialogOpen}
        label="resources.ticket-imports.actions.acceptTicketsImportAsyncJob"
        {...props}
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.ACCEPT_TICKET_IMPORT) ||
          loading ||
          mutationLoading ||
          ticketImport?.state !== TicketImportStates.IMPORTED
        }
        variant="contained"
        color="primary"
        size="small"
      />
    </>
  )
}

const GeneralActions = () => {
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item style={{ margin: '2px' }}>
            <TicketImportRevertButton
              disabled={!hasAuthority(Authority.REVERT_TICKET_IMPORT)}
            />
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <TicketImportAcceptButton
              disabled={!hasAuthority(Authority.ACCEPT_TICKET_IMPORT)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const TicketImportShowLayout = ({ ...props }) => {
  const { loaded, loading, record: ticketImport } = useShowContext()
  const translate = useTranslate()
  const theme = useTheme<ExtendedTheme>()

  return (
    <SimpleShowLayout {...props}>
      <TextField source="id" label="Id" />
      <TextField source="name" />
      <TextField source="originalFileName" />
      <FunctionField<TicketImportDto>
        source="state"
        render={(r?: TicketImportDto) => (
          <div>
            {translate(`resources.enums.TicketImportStates.${r?.state}`)}
          </div>
        )}
      />
      <ReferenceField
        source="lastJobId"
        reference={ResourceName.JOBS}
        link="show"
      >
        <TextField source="jobId" />
      </ReferenceField>
      {loaded && !loading && ticketImport?.templateSnapshot && (
        <FunctionField<TicketImportDto>
          source="templateSnapshot"
          label=" "
          render={(r?: TicketImportDto) => {
            const templateJson = Object.fromEntries(
              r?.templateSnapshot
                ?.split(';')
                .map((str) => str?.split(' -> ')) as any,
            )
            return (
              <ReactJson
                src={templateJson}
                theme={theme.palette.rjvTheme}
                iconStyle="triangle"
                name={translate(
                  'resources.ticket-imports.fields.templateSnapshot',
                )}
                style={{
                  marginBottom: theme.spacing(1),
                  padding: theme.spacing(1),
                }}
                displayObjectSize={false}
                displayDataTypes={false}
                quotesOnKeys={false}
              />
            )
          }}
        />
      )}
      <GeneralActions />
    </SimpleShowLayout>
  )
}

export const TicketImportShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <TicketImportShowLayout />
  </Show>
)
