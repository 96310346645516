import { useMediaQuery, useTheme } from '@material-ui/core'
import * as React from 'react'
import {
  Create,
  CreateProps,
  GET_ONE,
  Identifier,
  NumberInput,
  sanitizeFetchType,
  SelectInput,
  SimpleForm,
  TextInput,
  useQuery,
  useTranslate,
} from 'react-admin'
import { useLocation } from 'react-router'
import { TribuneDto } from '../../../core/dto/tribune.dto'
import { TribuneType } from '../../../core/enum/TribuneType'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

const validateTribuneCreate = (values: TribuneDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.objectId) {
    errors.objectId = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = ({ objectId }: { objectId?: Identifier }) => {
  const translate = useTranslate()
  const { data: object } = useQuery(
    {
      type: sanitizeFetchType(GET_ONE),
      resource: ResourceName.OBJECTS,
      payload: {
        id: objectId,
      },
    },
    { enabled: objectId !== undefined },
  )

  return (
    <span>{`${translate('resources.tribunes.create')}${
      object ? `: ${object?.name} (${object?.id})` : ''
    }`}</span>
  )
}

const TribuneCreate = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { search } = useLocation()
  const objectIdRaw = new URLSearchParams(search).get('objectId')
  const objectId: number | undefined =
    objectIdRaw !== null ? Number(objectIdRaw) : undefined

  const defaultValue = () => ({
    objectId,
  })

  return (
    <Create {...props} title={<CreateTitle objectId={objectId} />}>
      <SimpleForm
        redirect={objectId ? `/objects/${objectId}/show/1` : 'show'}
        initialValues={defaultValue}
        validate={validateTribuneCreate}
      >
        {!objectId ? (
          <FilteredReferenceInput
            referenceInputProps={{
              source: 'objectId',
              defaultValue: null,
              reference: ResourceName.OBJECTS,
              sort: { field: 'name', order: 'ASC' },
              perPage: smallScreen ? 5 : 15,
              isRequired: true,
              filterSource: 'search',
            }}
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        ) : (
          <NumberInput source="objectId" disabled />
        )}
        <TextInput source="name" isRequired />
        <TextInput source="code" />
        <SelectInput
          source="type"
          choices={Object.entries(TribuneType).map(([value]) => ({
            id: value,
            name: `resources.enums.tribuneType.${value}`,
          }))}
        />
      </SimpleForm>
    </Create>
  )
}

export default TribuneCreate
