const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  id: (lambdaParameter, value) => `${lambdaParameter}.id==${value}`,
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  filterEventId: () => null,
  eventId: (lambdaParameter, value, otherValues) =>
    otherValues?.filterEventId === false
      ? null
      : `${lambdaParameter}.Events.Any(e=>e.Id.Equals(${value}))`,
  eventsIds: (lambdaParameter, value) =>
    `${lambdaParameter}.Events.Any(e=>e.Id.Equals(${value}))`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
