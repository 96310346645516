import { DataProvider, GetOneParams, GetOneResult } from 'react-admin'
import { STATISTICS_URL } from '../../api-urls'
import { get } from '../../common/fetch.utils'
import { EventStandsPeriodResponse } from './period-view'

const provider = {
  getOne: async (
    resource: string,
    { id: eventId }: GetOneParams,
  ): Promise<GetOneResult<EventStandsPeriodRAResponse>> => {
    const periodViewData = await get<EventStandsPeriodResponse>(
      STATISTICS_URL,
      `/GetEventStandsPeriods/${eventId}`,
    )

    return {
      data: {
        ...periodViewData,
        id: periodViewData?.eventId,
      },
    }
  },
} as DataProvider

export interface EventStandsPeriodRAResponse extends EventStandsPeriodResponse {
  readonly id: number
}

export default provider
