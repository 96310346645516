import * as React from 'react'
import { Route } from 'react-router-dom'
import { Configuration } from '../current-user/UserConfiguration'
import { DynamicStatisticsView } from '../stats/dynamic-statistics/DynamicStatisticsView'
import { PassageCountersView } from '../stats/passage-counters-view/PassageCountersView'

export default [
  <Route exact path="/configuration" render={() => <Configuration />} />,
  <Route
    exact
    path="/stats-dynamic-statistics/:id/preview"
    render={({ ...routeProps }) => <DynamicStatisticsView {...routeProps} />}
  />,
  <Route
    exact
    path="/stats-passage-counters-view"
    render={() => <PassageCountersView />}
  />,
]
