export enum Authority {
  // User management
  VIEW_USER_MANAGEMENT,

  // Logs
  VIEW_LOGS,

  // Log scheme data
  VIEW_LOG_SCHEME_DATA,
  EDIT_LOG_SCHEME_DATA,
  CREATE_LOG_SCHEME_DATA,

  // Stats
  VIEW_STATS_OVERALL_VIEW,
  VIEW_STATS_EVENT_STANDS_PERIOD,
  VIEW_STATS_EVENT_ENTRANCES,
  VIEW_STATS_PASSAGE_COUNTERS,
  VIEW_TECHNICAL_ASSISTANCE_PROTOCOL,

  // Dynamic statistics
  VIEW_DYNAMIC_STATISTICS,
  EDIT_DYNAMIC_STATISTICS,
  CREATE_DYNAMIC_STATISTICS,
  DELETE_DYNAMIC_STATISTICS,
  EXECUTE_DYNAMIC_STATISTICS,

  // Dynamic statistic parameters
  VIEW_DYNAMIC_STATISTIC_PARAMETERS,
  EDIT_DYNAMIC_STATISTIC_PARAMETERS,
  CREATE_DYNAMIC_STATISTIC_PARAMETERS,
  DELETE_DYNAMIC_STATISTIC_PARAMETERS,

  // Companies
  VIEW_COMPANIES,
  EDIT_COMPANIES,
  CREATE_COMPANIES,

  // Users
  VIEW_USERS,
  EDIT_USERS,
  CREATE_USERS,

  // Roles
  VIEW_ROLES,
  EDIT_ROLES,
  CREATE_ROLES,

  // Access dates
  VIEW_ACCESS_DATES,
  EDIT_ACCESS_DATES,
  CREATE_ACCESS_DATES,

  // Accesses
  VIEW_ACCESSES,
  EDIT_ACCESSES,
  CREATE_ACCESSES,

  // Access usings
  VIEW_ACCESS_USINGS,
  EDIT_ACCESS_USINGS,
  CREATE_ACCESS_USINGS,

  // Blocks
  VIEW_BLOCKS,
  EDIT_BLOCKS,
  CREATE_BLOCKS,

  // Bookings
  VIEW_BOOKINGS,
  EDIT_BOOKINGS,
  CREATE_BOOKINGS,
  BOOKINGS_BLOCKADE_BUTTON,
  BOOKINGS_TOGGLE_ACCESS_USINGS_BUTTON,
  BOOKINGS_RELEASE_ALL_USINGS,
  BOOKINGS_CREATE_USE,

  // Service bookings
  VIEW_SERVICE_BOOKINGS,
  EDIT_SERVICE_BOOKINGS,
  CREATE_SERVICE_BOOKINGS,

  // Points bookings
  VIEW_POINTS_BOOKINGS,
  EDIT_POINTS_BOOKINGS,
  CREATE_POINTS_BOOKINGS,

  // Customers
  VIEW_CUSTOMERS,
  EDIT_CUSTOMERS,
  CREATE_CUSTOMERS,

  // Events
  VIEW_EVENTS,
  EDIT_EVENTS,
  CREATE_EVENTS,
  EVENTS_CHANGE_ACTIVE_STATE,
  EVENTS_DEACTIVATE_ACCESS_USINGS,
  EVENTS_MANAGE_COMPANIES,

  // Recurring jobs
  VIEW_RECURRING_JOBS,
  DELETE_RECURRING_JOBS,
  TRIGGER_RECURRING_JOBS,

  // Jobs
  VIEW_JOBS,
  DELETE_JOBS,

  // Objects
  VIEW_OBJECTS,
  EDIT_OBJECTS,
  CREATE_OBJECTS,

  // Schemes
  VIEW_SCHEMES,
  EDIT_SCHEMES,
  CREATE_SCHEMES,

  // Seats
  VIEW_SEATS,
  EDIT_SEATS,
  CREATE_SEATS,

  // Tickets
  VIEW_TICKETS,
  EDIT_TICKETS,
  CREATE_TICKETS,

  // Tribunes
  VIEW_TRIBUNES,
  EDIT_TRIBUNES,
  CREATE_TRIBUNES,

  // Variants
  VIEW_VARIANTS,
  EDIT_VARIANTS,
  CREATE_VARIANTS,

  // Cameras
  VIEW_CAMERAS,
  EDIT_CAMERAS,
  CREATE_CAMERAS,
  CAMERAS_PREVIEW_BUTTON,

  // Camera servers
  VIEW_CAMERA_SERVERS,
  EDIT_CAMERA_SERVERS,
  CREATE_CAMERA_SERVERS,
  CAMERA_SERVERS_MANAGE_CREDENTIALS_BUTTON,

  // Engines
  VIEW_ENGINES,
  EDIT_ENGINES,
  CREATE_ENGINES,
  ENGINES_LOCK_BUTTON,
  ENGINES_SET_STANDBY_MODE_BUTTON,
  ENGINES_MANAGE_CREDENTIALS_BUTTON,
  ENGINES_RESTART_TSCAN_BUTTON,
  ENGINES_FORCE_REPORT_STATE_BUTTON,
  ENGINES_RESET_MODULES_BUTTON,

  // Offline servers
  VIEW_OFFLINE_SERVERS,
  EDIT_OFFLINE_SERVERS,
  CREATE_OFFLINE_SERVERS,
  OFFLINE_SERVERS_MANAGE_CREDENTIALS_BUTTON,
  OFFLINE_SERVERS_FORCE_REPORT_STATE_BUTTON,
  OFFLINE_SERVERS_RESTORE_REPLICATION_BUTTON,

  // Palms
  VIEW_PALMS,
  EDIT_PALMS,
  CREATE_PALMS,
  PALMS_LOCK_BUTTON,
  PALMS_SET_MOBILE_DEVICE_ID_BUTTON,

  // Palm scheme data
  VIEW_PALM_SCHEME_DATA,
  EDIT_PALM_SCHEME_DATA,
  CREATE_PALM_SCHEME_DATA,

  // Passages
  VIEW_PASSAGES,
  EDIT_PASSAGES,
  CREATE_PASSAGES,
  PASSAGES_COUNTERS_EDIT,
  PASSAGES_MANAGE_CREDENTIALS_BUTTON,
  PASSAGES_RESTART_TSCAN_BUTTON,
  PASSAGES_SET_FREE_PASS_BUTTON,
  PASSAGES_SET_POWER_BUTTON,
  PASSAGES_SET_PPOZ_BUTTON,
  PASSAGES_LOCK_BUTTON,
  PASSAGES_FORCE_REPORT_STATE_BUTTON,
  PASSAGES_RESET_MODULES_BUTTON,
  PASSAGES_SET_REVERSED_ROTATION_DIRECTION_BUTTON,
  PASSAGES_SET_OPEN,

  // Detectors
  VIEW_DETECTORS,
  EDIT_DETECTORS,
  CREATE_DETECTORS,
  DETECTORS_RESTART_TSCAN_BUTTON,
  DETECTORS_FORCE_REPORT_STATE_BUTTON,

  // Device categories
  VIEW_DEVICE_CATEGORIES,
  EDIT_DEVICE_CATEGORIES,
  CREATE_DEVICE_CATEGORIES,

  // Entrances
  VIEW_ENTRANCES,
  EDIT_ENTRANCES,
  CREATE_ENTRANCES,

  // Entrances sets
  VIEW_ENTRANCES_SET,
  CLEAR_ENTRANCES_SET,
  EDIT_ENTRANCES_SET,
  CREATE_ENTRANCES_SET,
  RESTORE_ENTRANCES_SET,

  // CSI
  VIEW_CSI,
  SYNC_STADIUM_BANS,

  // Service link
  VIEW_SERVICE_LINK,
  EDIT_SERVICE_LINK,
  CREATE_SERVICE_LINK,
  SYNC_SERVICE_LINK,

  // Depositors
  VIEW_DEPOSITORS,
  EDIT_DEPOSITORS,
  CREATE_DEPOSITORS,
  DEPOSITORS_MANAGE_CREDENTIALS_BUTTON,

  // Dynamic Property Definitions
  VIEW_DYNAMIC_PROPERTY_DEFINITIONS,
  EDIT_DYNAMIC_PROPERTY_DEFINITIONS,
  CREATE_DYNAMIC_PROPERTY_DEFINITIONS,

  // Dynamic Counters
  VIEW_DYNAMIC_COUNTERS,
  EDIT_DYNAMIC_COUNTERS,
  CREATE_DYNAMIC_COUNTERS,
  DELETE_DYNAMIC_COUNTERS,

  // Dynamic Counter Parameters
  VIEW_DYNAMIC_COUNTER_PARAMETERS,
  EDIT_DYNAMIC_COUNTER_PARAMETERS,
  CREATE_DYNAMIC_COUNTER_PARAMETERS,
  DELETE_DYNAMIC_COUNTER_PARAMETERS,

  // Access Bonus
  VIEW_ACCESS_BONUSES,
  EDIT_ACCESS_BONUSES,
  CREATE_ACCESS_BONUSES,

  // Devices
  VIEW_DEVICES_BASE,
  VIEW_VERIFIER_DEVICES,

  // Verifier Device Disabled
  VIEW_VERIFIER_DEVICE_DISABLED,
  EDIT_VERIFIER_DEVICE_DISABLED,
  CREATE_VERIFIER_DEVICE_DISABLED,

  // Verifier Scheme Data
  VIEW_VERIFIER_SCHEME_DATA,
  EDIT_VERIFIER_SCHEME_DATA,
  CREATE_VERIFIER_SCHEME_DATA,

  // Doors
  VIEW_DOORS,
  EDIT_DOORS,
  CREATE_DOORS,
  DOORS_SET_LOCK,
  DOORS_SET_STANDBY,
  DOORS_FORCE_REPORT_STATE,
  DOORS_RESET_MODULES,
  DOORS_SET_RELEASE,

  //Terminals
  VIEW_TERMINALS,
  EDIT_TERMINALS,
  CREATE_TERMINALS,
  TERMINALS_SET_LOCK,
  TERMINALS_SET_STANDBY,
  TERMINALS_FORCE_REPORT_STATE,
  TERMINALS_RESET_MODULES,
  TERMINALS_OPEN_FOR_SINGLE_VEHICLE,
  TERMINALS_SET_PRINTER_POWER,
  TERMINALS_SET_WORKING_MODE,

  // TSCans
  VIEW_TS_CANS,
  EDIT_TS_CANS,
  CREATE_TS_CANS,
  TS_CANS_MANAGE_CREDENTIALS,
  TS_CANS_RESTART,
  // Ticket Imports
  VIEW_TICKET_IMPORTS,
  IMPORT_TICKETS,
  REVERT_TICKET_IMPORT,
  ACCEPT_TICKET_IMPORT,

  // Ticket Import Mappings
  VIEW_TICKET_IMPORT_MAPPINGS,
  EDIT_TICKET_IMPORT_MAPPINGS,
  CREATE_TICKET_IMPORT_MAPPINGS,
  DELETE_TICKET_IMPORT_MAPPINGS,

  // Ticket Import Templates
  VIEW_TICKET_IMPORT_TEMPLATES,
  EDIT_TICKET_IMPORT_TEMPLATES,
  CREATE_TICKET_IMPORT_TEMPLATES,
  DELETE_TICKET_IMPORT_TEMPLATES,
}
