import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { DefinitionOwnerTypes } from '../../enum/DefinitionOwnerTypes'
import { DynamicPropertyDataTypes } from '../../enum/DynamicPropertyDataTypes'
import { DynamicPropertyDefinitionBaseDTO } from '../../dto/dynamic-property-definition.dto'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { get, getWithPagination, patch, post } from '../../common/fetch.utils'
import { DYNAMIC_PROPERTY_DEFINITION_URL } from '../../api-urls'
import filterMapper from './dynamic-property-definition-filter.mapper'
import { mapSortDynamicPropertyDefinitionParams } from './dynamic-property-definition-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<DynamicPropertyDefinitionBaseDTO>> => {
    const filterParams = `c=>${filterParamsComposer('c', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortDynamicPropertyDefinitionParams,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DynamicPropertyDefinitionBaseDTO[]>(
      `${DYNAMIC_PROPERTY_DEFINITION_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id: customerId }: GetOneParams,
  ): Promise<GetOneResult<DynamicPropertyDefinitionBaseDTO>> => {
    const customer = await get<DynamicPropertyDefinitionBaseDTO>(
      DYNAMIC_PROPERTY_DEFINITION_URL,
      `/${customerId}`,
    )
    return {
      data: customer as DynamicPropertyDefinitionBaseDTO,
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<DynamicPropertyDefinitionBaseDTO>> => {
    const data = await get<DynamicPropertyDefinitionBaseDTO[]>(
      `${DYNAMIC_PROPERTY_DEFINITION_URL}/GetByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { data, previousData }: UpdateParams<DynamicPropertyDefinitionBaseDTO>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      UpdateDynamicPropertyDefinitionRequest,
      DynamicPropertyDefinitionBaseDTO
    >(DYNAMIC_PROPERTY_DEFINITION_URL, {
      id: data.id,
      definitionOwnerType:
        data.definitionOwnerType !== previousData.definitionOwnerType
          ? data.definitionOwnerType
          : undefined,
      key: data.key !== previousData.key ? data.key : undefined,
      type: data.type !== previousData.type ? data.type : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<DynamicPropertyDefinitionBaseDTO>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateDynamicPropertyDefinitionRequest[],
      DynamicPropertyDefinitionBaseDTO[]
    >(DYNAMIC_PROPERTY_DEFINITION_URL, [data])
    return Promise.resolve({ data: created[0] })
  },
} as DataProvider

interface CreateDynamicPropertyDefinitionRequest {
  readonly definitionOwnerType: DefinitionOwnerTypes
  readonly key: string
  readonly type: DynamicPropertyDataTypes
}

interface UpdateDynamicPropertyDefinitionRequest {
  readonly id: number
  readonly definitionOwnerType?: DefinitionOwnerTypes
  readonly key?: string
  readonly type?: DynamicPropertyDataTypes
}

export default provider
