import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { ACCESS_BONUS_URL } from '../api-urls'
import { get, getWithPagination, patch, post } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import filterMapper from './access-bonus-filter.mapper'
import { mapSortAccessParam } from './access-bonus-sort.mapper'
import { AccessBonusDto } from '../dto/access-bonus.dto'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<AccessBonusDto>> => {
    const filterParams = `a=>${filterParamsComposer('a', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortAccessParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<AccessBonusDto[]>(
      `${ACCESS_BONUS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<AccessBonusDto>> => {
    const data = await get<AccessBonusDto>(ACCESS_BONUS_URL, `/${id}`)
    return {
      data,
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<AccessBonusDto>> => {
    const data = await get<AccessBonusDto[]>(
      `${ACCESS_BONUS_URL}/GetByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  update: async (
    resource: string,
    { data, previousData }: UpdateParams<AccessBonusDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<UpdateAccessBonusRequest, AccessBonusDto>(
      ACCESS_BONUS_URL,
      {
        id: data.id,
        name: data.name !== previousData.name ? data.name : undefined,
        description:
          data.description !== previousData.description
            ? data.description
            : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<AccessBonusDto>,
  ): Promise<CreateResult> => {
    const created = await post<CreateAccessBonusRequest[], AccessBonusDto[]>(
      ACCESS_BONUS_URL,
      [
        {
          name: data.name,
          description: data.description,
        },
      ],
    )
    return Promise.resolve({ data: created[0] })
  },
} as DataProvider

interface CreateAccessBonusRequest {
  readonly name: string
  readonly description: string
}

interface UpdateAccessBonusRequest {
  readonly id: number
  readonly name?: string
  readonly description?: string
}

export default provider
