import { makeStyles } from '@material-ui/core'
import { ArrowBack, Autorenew, Close } from '@material-ui/icons'
import React, { useState, useReducer, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { ToggleType } from '../../../../core/devices/passages/passage-set-boolean-status-button'
import { PassageDto } from '../../../../core/dto/device/passages/passage.dto'
import { PassageSelectStateDialog } from '../PassageDialogs'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import {
  passageSetFreePassBackwardButtonInitialState,
  passageSetFreePassBackwardButtonReducer,
} from '../../../../core/devices/passages/passage-set-free-pass-backward-button.reducer'

const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 34,
  },
})

export const PassageSetFreePassBackwardButton = ({ ...props }) => {
  const { asRecurringJob, disabled } = props
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const classes = useStyles()

  const [{ mutateQuery }, dispatch] = useReducer(
    passageSetFreePassBackwardButtonReducer,
    passageSetFreePassBackwardButtonInitialState,
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const [state, setState] = useState(false)
  const passage = useRecordContext<PassageDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  useEffect(() => {
    if (state) {
      dispatch({ type: ToggleType.OFF })
    } else {
      dispatch({ type: ToggleType.ON })
    }
  }, [state])

  const handleFreePass = async (
    recurringJobName?: string,
    cronExpression?: string,
    auditNote?: string,
  ) => {
    let query
    if (!asRecurringJob) {
      query = mutateQuery?.(passage, auditNote)
    } else {
      query = mutateQuery?.(
        passage,
        auditNote,
        recurringJobName as string,
        cronExpression as string,
      )
    }
    await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    refresh()
  }

  return (
    <>
      <PassageSelectStateDialog
        open={dialogOpen}
        confirm={handleFreePass}
        method="freePassBackward"
        close={() => setDialogOpen(false)}
        state={state}
        setState={setState}
        asRecurringJob={asRecurringJob}
        OnIcon={<ArrowBack className={classes.icon} />}
        OffIcon={
          <>
            <ArrowBack className={classes.icon} />
            <Close fontSize="large" className={classes.crossIcon} />
          </>
        }
        withAuditNote
      />
      <Button
        disabled={
          !hasAuthority(Authority.PASSAGES_SET_FREE_PASS_BUTTON) ||
          loading ||
          mutationLoading ||
          disabled === true
        }
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        onClick={() => setDialogOpen(true)}
        label={
          !asRecurringJob
            ? 'resources.passages.actions.setFreePassState.setBackward'
            : 'common.recurring-job.buttons.create-recurring-job'
        }
        useSmallVersionBreakpoint={false}
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
      />
    </>
  )
}
