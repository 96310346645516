import { DataProvider, GetListResult } from 'react-admin'
import { STATISTICS_URL } from '../../api-urls'
import { get } from '../../common/fetch.utils'
import { filterParamsComposer } from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { EventStandsPeriod, EventStandsPeriodResponse } from './period-view'
import filterMapper from './period-filter.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter }: TicketAPIGetListParams,
  ): Promise<GetListResult<EventStandsPeriodRAListResponse>> => {
    const { id, ...filterRest } = filter
    const filterParams = filterParamsComposer('', filterRest, filterMapper)
    const path = `/${id}?${filterParams !== 'true' ? filterParams : ''}`

    const { periods } = await get<EventStandsPeriodResponse>(
      `${STATISTICS_URL}/GetEventStandsPeriods`,
      path,
    )

    return Promise.resolve({
      data: periods.map((period) => ({
        ...period,
        id: period.eventTimeReference,
      })),
      total: periods.length,
    })
  },
} as DataProvider

export interface EventStandsPeriodRAListResponse extends EventStandsPeriod {
  readonly id: number
}

export default provider
