import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { FunctionField, Labeled, Record } from 'react-admin'
import { getContrastColor } from '../../core/common/visual.utils'

export const RAColorField = ({ ...props }) => {
  const { source } = props

  return (
    <Labeled {...props} source={source}>
      <FunctionField
        source={source as string}
        {...props}
        render={(record?: Record) => (
          <Box
            p={1}
            borderRadius={10}
            color={
              record?.[source as string]
                ? getContrastColor(`${record?.[source as string]}`)
                : '#ffffff'
            }
            bgcolor={
              record?.[source as string]
                ? `${record?.[source as string]}`
                : '#000000'
            }
          >
            <Typography>{record?.[source as string] ?? 'N/A'}</Typography>
          </Box>
        )}
      />
    </Labeled>
  )
}
