import { useEffect } from 'react';
import { getValuesFromRecords, inferTypeFromValues } from 'ra-core';
import { useSelector } from 'react-redux';
import { usePreferences } from '../../usePreferences';
/**
 * A hook which returns the model for the specified resource. Tries to infer it from the redux store data if not already defined.
 * @param {String} resource The resource
 * @param {String} preferencesKey The preference key which defaults to the resource name
 * @returns {ResourceModel} The resource model
 */
export var useResourceModel = function (resource, preferencesKey) {
    if (preferencesKey === void 0) { preferencesKey = resource; }
    var _a = usePreferences(preferencesKey), resourceModel = _a[0], setResourceModel = _a[1];
    var data = useSelector(function (state) {
        return state.admin.resources[resource]
            ? state.admin.resources[resource].data
            : {};
    });
    // data = [
    //     1: {
    //         id: 1,
    //         title: "Lorem Ipsum",
    //         views: 254,
    //         user_id: 123,
    //     },
    //     2: {
    //         id: 2,
    //         title: "Sic Dolor amet",
    //         views: 65,
    //         user_id: 456,
    //     },
    // };
    useEffect(function () {
        if (resourceModel != null && Object.keys(resourceModel).length > 0) {
            return;
        }
        // We might have no data after applying a filter and we would loose all types.
        // This does not resolve the issue when loading a page without data though. For that we'll have to store the model in preferences
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        var fields = inferResourceModel(data);
        setResourceModel({
            name: resource,
            label: resource,
            fields: fields,
        });
    }, [data, resource, resourceModel, setResourceModel]);
    return [resourceModel, setResourceModel];
};
var inferResourceModel = function (data) {
    var values = getValuesFromRecords(Object.values(data));
    // values = {
    //    id: [1, 2],
    //    title: ['Lorem Ipsum', 'Sic Dolor amet'],
    //    views: [254, 65],
    //    user_id: [123, 456],
    // }
    var types = {};
    Object.keys(values).forEach(function (key) {
        var fieldType = inferTypeFromValues(key, values[key]);
        types[key] = fieldType;
    });
    // types = {
    //    id: { type: 'id', props: { source: 'id' } },
    //    title: { type: 'string', props: { source: 'title } },
    //    views: { type: 'number', props: { source: 'views } },
    //    user_id: { type: 'reference', props: { source: 'user_id, reference: 'users' }, children: { type: 'referenceChild' },
    // }
    return types;
};
