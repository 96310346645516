import { DataProvider, sanitizeFetchType } from 'react-admin'
import { ResourceName } from '../ResourceName'
import fakeAccessDatesProvider from './providers/fake-access-dates.provider'
import fakeAccessUsingsProvider from './providers/fake-access-usings.provider'
import fakeAccessesProvider from './providers/fake-accesses.provider'
import fakeBlocksProvider from './providers/fake-blocks.provider'
import fakeBookingsProvider from './providers/fake-bookings.provider'
import fakeCompaniesProvider from './providers/fake-companies.provider'
import fakeCustomersProvider from './providers/fake-customers.provider'
import fakeDetectorsProvider from './providers/fake-detectors.provider'
import fakeDeviceCategoriesProvider from './providers/fake-device-categories.provider'
import fakeEnginesProvider from './providers/fake-engines.provider'
import fakeEntrancesProvider from './providers/fake-entrances.provider'
import fakeEventsProvider from './providers/fake-events.provider'
import fakeLogsProvider from './providers/fake-logs.provider'
import fakeObjectsProvider from './providers/fake-objects.provider'
import fakePalmsProvider from './providers/fake-palms.provider'
import fakePassagesProvider from './providers/fake-passages.provider'
import fakePermissionsProvider from './providers/fake-permissions.provider'
import fakeRolesProvider from './providers/fake-roles.provider'
import fakeSchemesProvider from './providers/fake-schemes.provider'
import fakeSeatsProvider from './providers/fake-seats.provider'
import fakeStatsOverallViewProvider from './providers/fake-stats-overall-view.provider'
import fakeStatsPeriodViewProvider from './providers/fake-stats-period-view.provider'
import fakeStatsPeriodListProvider from './providers/fake-stats-period-list.provider'
import fakeStatsPassageCountersViewProvider from './providers/fake-stats-passage-counters-view.provider'
import fakeStatsEventEntrancesViewProvider from './providers/fake-stats-event-entrances-view.provider'
import fakeTicketsProvider from './providers/fake-tickets.provider'
import fakeTribunesProvider from './providers/fake-tribunes.provider'
import fakeUsersProvider from './providers/fake-user.provider'
import fakeVariantsProvider from './providers/fake-variants.provider'
import fakeCamerasProvider from './providers/fake-cameras.provider'
import fakeCameraServersProvider from './providers/fake-camera-servers.provider'
import fakeJobsProvider from './providers/fake-jobs.provider'
import fakeOfflineServersProvider from './providers/fake-offline-servers.provider'
import fakeDevicesBaseProvider from './providers/fake-devices-base.provider'
import fakeVerifierDevicesProvider from './providers/fake-verifier-devices.provider'
import fakeLogSchemeDataProvider from './providers/fake-log-scheme-data.provider'
import fakePalmSchemeDataProvider from './providers/fake-palm-scheme-data.provider'
import fakeEntrancesSetProvider from './providers/fake-entrances-set.provider'
import fakeStatsDynamicStatisticsProvider from './providers/fake-stats-dynamic-statistics.provider'
import fakeStatsDynamicStatisticsExecutionProvider from './providers/fake-stats-dynamic-statistics-execution.provider'
import fakeStatsDynamicStatisticParametersProvider from './providers/fake-stats-dynamic-statistic-parameters.provider'
import fakeTechnicalAssistanceProtocolProvider from './providers/fake-technical-assistance-protocol.provider'
import fakeSyncLogsProvider from './providers/fake-sync-logs.provider'
import fakeServiceLinkProvider from './providers/fake-service-link.provider'
import fakeDepositorsProvider from './providers/fake-depositors.provider'
import fakeDynamicPropertyDefinitionsProvider from './providers/fake-dynamic-property-definiton.provider'
import fakeDynamicCountersProvider from './providers/fake-dynamic-counters.provider'
import fakeDynamicCounterParametersProvider from './providers/fake-dynamic-counter-parameters.provider'
import fakeAccessBonusProvider from './providers/fake-access-bonus.provider'
import fakeVerifierDeviceDisabledProvider from './providers/fake-verifier-device-disabled.provider'
import fakeVerifierSchemeDataProvider from './providers/fake-verifier-scheme-data.provider'
import fakeDoorsProvider from './providers/fake-doors.provider'
import fakeTerminalsProvider from './providers/fake-terminals.provider'
import fakeTSCansProvider from './providers/fake-ts-cans.provider'
import fakeTicketImportsProvider from './providers/fake-ticket-imports.provider'
import fakeTicketImportMappingsProvider from './providers/fake-ticket-import-mappings.provider'
import fakeTicketImportTemplatesProvider from './providers/fake-ticket-import-templates.provider'

const dataProviders: Record<ResourceName, DataProvider> = {
  [ResourceName.ACCESS_DATES]: fakeAccessDatesProvider,
  [ResourceName.ACCESSES]: fakeAccessesProvider,
  [ResourceName.ACCESS_USINGS]: fakeAccessUsingsProvider,
  [ResourceName.BOOKINGS]: fakeBookingsProvider,
  [ResourceName.CAMERAS]: fakeCamerasProvider,
  [ResourceName.CAMERA_SERVERS]: fakeCameraServersProvider,
  [ResourceName.COMPANIES]: fakeCompaniesProvider,
  [ResourceName.CURRENT_USER]: fakeUsersProvider,
  [ResourceName.CUSTOMERS]: fakeCustomersProvider,
  [ResourceName.DETECTORS]: fakeDetectorsProvider,
  [ResourceName.DEVICE_CATEGORIES]: fakeDeviceCategoriesProvider,
  [ResourceName.DEVICES_BASE]: fakeDevicesBaseProvider,
  [ResourceName.VERIFIER_DEVICES]: fakeVerifierDevicesProvider,
  [ResourceName.ENGINES]: fakeEnginesProvider,
  [ResourceName.ENTRANCES]: fakeEntrancesProvider,
  [ResourceName.ENTRANCES_SET]: fakeEntrancesSetProvider,
  [ResourceName.EVENTS]: fakeEventsProvider,
  [ResourceName.LOGS]: fakeLogsProvider,
  [ResourceName.OBJECTS]: fakeObjectsProvider,
  [ResourceName.PALMS]: fakePalmsProvider,
  [ResourceName.ROLES]: fakeRolesProvider,
  [ResourceName.PERMISSIONS]: fakePermissionsProvider,
  [ResourceName.PASSAGES]: fakePassagesProvider,
  [ResourceName.STATS_OVERALL_VIEW]: fakeStatsOverallViewProvider,
  [ResourceName.STATS_OVERALL_VIEW_LIST]: fakeEventsProvider,
  [ResourceName.STATS_PERIOD_VIEW]: fakeStatsPeriodViewProvider,
  [ResourceName.STATS_PERIOD_VIEW_LIST]: fakeEventsProvider,
  [ResourceName.STATS_PERIOD_LIST]: fakeStatsPeriodListProvider,
  [ResourceName.STATS_PASSAGE_COUNTERS_VIEW]:
    fakeStatsPassageCountersViewProvider,
  [ResourceName.STATS_EVENT_ENTRANCES_VIEW]:
    fakeStatsEventEntrancesViewProvider,
  [ResourceName.STATS_EVENT_ENTRANCES_VIEW_LIST]: fakeEventsProvider,
  [ResourceName.STATS_DYNAMIC_STATISTICS]: fakeStatsDynamicStatisticsProvider,
  [ResourceName.STATS_DYNAMIC_STATISTICS_EXECUTION]:
    fakeStatsDynamicStatisticsExecutionProvider,
  [ResourceName.STATS_DYNAMIC_STATISTIC_PARAMETERS]:
    fakeStatsDynamicStatisticParametersProvider,
  [ResourceName.TRIBUNES]: fakeTribunesProvider,
  [ResourceName.BLOCKS]: fakeBlocksProvider,
  [ResourceName.SEATS]: fakeSeatsProvider,
  [ResourceName.TICKETS]: fakeTicketsProvider,
  [ResourceName.USERS]: fakeUsersProvider,
  [ResourceName.VARIANTS]: fakeVariantsProvider,
  [ResourceName.JOBS]: fakeJobsProvider,
  [ResourceName.RECURRING_JOBS]: fakeJobsProvider,
  [ResourceName.OFFLINE_SERVERS]: fakeOfflineServersProvider,
  [ResourceName.SERVICE_BOOKINGS]: fakeBookingsProvider,
  [ResourceName.ENTRANCES_MATRIX]: fakeVerifierDevicesProvider,
  [ResourceName.SCHEMES]: fakeSchemesProvider,
  [ResourceName.LOG_SCHEME_DATA]: fakeLogSchemeDataProvider,
  [ResourceName.PALM_SCHEME_DATA]: fakePalmSchemeDataProvider,
  [ResourceName.TECHNICAL_ASSISTANCE_PROTOCOL]:
    fakeTechnicalAssistanceProtocolProvider,
  [ResourceName.SYNC_LOGS]: fakeSyncLogsProvider,
  [ResourceName.SERVICE_LINK]: fakeServiceLinkProvider,
  [ResourceName.DEPOSITORS]: fakeDepositorsProvider,
  [ResourceName.DYNAMIC_PROPERTY_DEFINITIONS]:
    fakeDynamicPropertyDefinitionsProvider,
  [ResourceName.DYNAMIC_COUNTERS]: fakeDynamicCountersProvider,
  [ResourceName.DYNAMIC_COUNTER_PARAMETERS]:
    fakeDynamicCounterParametersProvider,
  [ResourceName.ACCESS_BONUS]: fakeAccessBonusProvider,
  [ResourceName.VERIFIER_DEVICE_DISABLED]: fakeVerifierDeviceDisabledProvider,
  [ResourceName.VERIFIER_SCHEME_DATA]: fakeVerifierSchemeDataProvider,
  [ResourceName.DOORS]: fakeDoorsProvider,
  [ResourceName.TERMINALS]: fakeTerminalsProvider,
  [ResourceName.TS_CANS]: fakeTSCansProvider,
  [ResourceName.TICKET_IMPORTS]: fakeTicketImportsProvider,
  [ResourceName.TICKET_IMPORT_MAPPINGS]: fakeTicketImportMappingsProvider,
  [ResourceName.TICKET_IMPORT_TEMPLATES]: fakeTicketImportTemplatesProvider,
}

export default (
  fetchType: string,
  resource: string,
  params: unknown,
): Promise<unknown> => {
  const selectedProvider = dataProviders[resource as ResourceName]
  const type = sanitizeFetchType(fetchType)
  return selectedProvider[type](resource, params)
}
