import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRefresh,
  useShowContext,
  useTranslate,
} from 'react-admin'
import useStateRef from 'react-usestateref'
import { CameraServerDto } from '../../../../core/dto/device/camera/camera-servers/camera-server.dto'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button from '../../../common/customized-mui-components/Button'
import DraggableComponent from '../../../common/DraggableComponent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogProperty: {
      marginBottom: theme.spacing(1),
    },
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    bulkProgressTitle: {
      paddingLeft: '10px',
      width: '100%',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
  }),
)

export const SetCredentialsDialog = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const classes = useStyles()
  const [loginValue, setLoginValue] = useState('')
  const [loginError, setLoginError, loginErrorCurrentValue] = useStateRef(false)
  const [loginErrorText, setLoginErrorText] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [passwordError, setPasswordError, passwordErrorCurrentValue] =
    useStateRef(false)
  const [passwordErrorText, setPasswordErrorText] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { record: cameraServer } = useShowContext<CameraServerDto>()
  const [mutate] = useMutation()
  const refresh = useRefresh()
  const translate = useTranslate()
  const notify = useNotify()

  const handleClose = () => {
    setLoginValue('')
    setPasswordValue('')
    setLoginError(false)
    setPasswordError(false)
    setLoginErrorText('')
    setPasswordErrorText('')
    close()
  }

  const handleSetCredentials = async () => {
    setConfirmOpen(false)
    setLoginError(false)
    setPasswordError(false)
    setLoginErrorText('')
    setPasswordErrorText('')
    if (loginValue.replace(/\s/g, '').length === 0) {
      setLoginError(true)
      setLoginErrorText(
        translate(
          'resources.camera-servers.dialogs.setCredentials.login.error-bad-string',
        ),
      )
    } else if (loginValue.replace(/\s/g, '').length !== loginValue.length) {
      setLoginError(true)
      setLoginErrorText(
        translate(
          'resources.camera-servers.dialogs.setCredentials.login.error-no-spaces',
        ),
      )
    }
    if (passwordValue.replace(/\s/g, '').length === 0) {
      setPasswordError(true)
      setPasswordErrorText(
        translate(
          'resources.camera-servers.dialogs.setCredentials.password.error-bad-string',
        ),
      )
    } else if (
      passwordValue.replace(/\s/g, '').length !== passwordValue.length
    ) {
      setPasswordError(true)
      setPasswordErrorText(
        translate(
          'resources.camera-servers.dialogs.setCredentials.password.error-no-spaces',
        ),
      )
    }

    if (!loginErrorCurrentValue.current && !passwordErrorCurrentValue.current) {
      await mutate(
        {
          type: sanitizeFetchType('setCredentials'),
          resource: ResourceName.CAMERA_SERVERS,
          payload: {
            cameraServerId: cameraServer?.id,
            login: loginValue,
            password: passwordValue,
          },
        },
        {
          returnPromise: true,
          onFailure: (err) => notify(err?.message, 'error'),
        },
      )
      refresh()
      handleClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        confirm={handleSetCredentials}
        source="setCredentials"
        action="set"
        resource={ResourceName.CAMERA_SERVERS}
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate('resources.camera-servers.dialogs.setCredentials.title')}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <TextField
          className={classes.dialogProperty}
          label={translate(
            'resources.camera-servers.dialogs.setCredentials.login.label',
          )}
          variant="outlined"
          value={loginValue}
          onChange={(e) => setLoginValue(e.target.value)}
          fullWidth
          error={loginError}
          helperText={loginErrorText}
        />
        <TextField
          label={translate(
            'resources.camera-servers.dialogs.setCredentials.password.label',
          )}
          variant="outlined"
          value={passwordValue}
          onChange={(e) => setPasswordValue(e.target.value)}
          fullWidth
          type="password"
          autoComplete="new-password"
          error={passwordError}
          helperText={passwordErrorText}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          label={translate('ra.action.save')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
        <Button
          variant="contained"
          onClick={handleClose}
          label={translate('const.cancel')}
          useSmallVersionBreakpoint={false}
          className={classes.actionsButton}
        />
      </DialogActions>
    </Dialog>
  )
}
