import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { Sync } from '@material-ui/icons'
import React, { useContext } from 'react'
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterButton,
  FilterContext,
  ListProps,
  Pagination,
  ReferenceField,
  sanitizeListRestProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useListContext,
  useMutation,
  useTranslate,
} from 'react-admin'
import { useHistory } from 'react-router'
import { Authority } from '../../core/auth/Authority'
import { ResourceName } from '../../core/ResourceName'
import cloneOrCreateElement from '../common/cloneOrCreateElement'
import Button from '../common/customized-mui-components/Button'
import List from '../common/customized-ra-components/List'
import { useHasAuthority } from '../hooks/useHasAuthority'

const useStyles = makeStyles({
  expand: {
    display: 'flex',
    flexDirection: 'row',
  },
})

const SyncLogsExpand = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const classes = useStyles()

  return (
    <SimpleShowLayout {...props} className={classes.expand}>
      {hasAuthority(Authority.VIEW_USERS) && (
        <ReferenceField
          source="createdById"
          reference={ResourceName.USERS}
          link="show"
        >
          <TextField source="userName" />
        </ReferenceField>
      )}
      {hasAuthority(Authority.VIEW_USERS) && (
        <ReferenceField
          source="modifiedById"
          reference={ResourceName.USERS}
          link={hasAuthority(Authority.VIEW_USERS) ? 'show' : false}
        >
          <TextField source="userName" />
        </ReferenceField>
      )}
      <DateField source="modifiedAt" />
    </SimpleShowLayout>
  )
}

const SyncLogsFilters = ({ ...props }) => (
  <Filter {...props}>
    <BooleanInput source="isSuccessfull" defaultValue />
    <DateInput source="createdFrom" />
    <DateInput source="createdTo" />
  </Filter>
)

const SyncLogsActions = ({ ...props }) => {
  const { className, filters: filtersProp, ...rest } = props
  const { displayedFilters, filterValues, showFilter, refetch } =
    useListContext(props)
  const resource = ResourceName.SYNC_LOGS
  const filters = useContext(FilterContext) || filtersProp
  const hasAuthority = useHasAuthority()

  const [mutate] = useMutation()
  const translate = useTranslate()

  const history = useHistory()

  const handleSyncStadiumBans = async () => {
    await mutate(
      {
        resource: ResourceName.SYNC_LOGS,
        type: 'syncStadiumBans',
      },
      { returnPromise: true },
    )
    refetch()
  }

  const handleShowStadiumBans = () => {
    history.push(
      '/tickets?displayedFilters={"isBookingBanned":true}&filter={"isBookingBanned":true}',
    )
  }

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button
        onClick={handleSyncStadiumBans}
        label={translate('resources.sync-logs.actions.syncStadiumBans')}
        startIcon={<Sync />}
        disabled={!hasAuthority(Authority.SYNC_STADIUM_BANS)}
      />
      <Button
        onClick={handleShowStadiumBans}
        label={translate('resources.sync-logs.actions.showStadiumBans')}
        disabled={!hasAuthority(Authority.VIEW_TICKETS)}
      />
      {filtersProp
        ? cloneOrCreateElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
    </TopToolbar>
  )
}

export const SyncLogsList = (props: ListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <List
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<SyncLogsFilters />}
      actions={<SyncLogsActions />}
      {...props}
      bulkActionButtons={false}
      exporter={false}
      empty={false}
    >
      <Datagrid
        expand={<SyncLogsExpand />}
        padding={xSmallScreen ? 'checkbox' : 'default'}
      >
        <DateField source="createdAt" />
        <BooleanField source="isSuccessfull" />
        <TextField source="fetchedBansCount" />
        {!smallScreen && <TextField source="bannedBookingsCount" />}
        {!smallScreen && <TextField source="unbannedBookingsCount" />}
      </Datagrid>
    </List>
  )
}
