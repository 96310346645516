import { CreateResult, DataProvider, UpdateManyResult } from 'react-admin'

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateMany: async (resource, params): Promise<UpdateManyResult> =>
    Promise.resolve({
      data: [1],
    }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  create: async (resource, params): Promise<CreateResult<unknown>> =>
    Promise.resolve({
      data: { id: 1 },
    }),
} as DataProvider

export default provider
