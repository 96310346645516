import { Box, Divider, Grid } from '@material-ui/core'
import React from 'react'
import {
  Show,
  ShowProps,
  TextField,
  TitleProps,
  useTranslate,
  FunctionField,
  EditButton,
  useShowContext,
  TabbedShowLayout,
  Tab,
  ReferenceField,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { PalmSchemeDataDto } from '../../../../core/dto/palm-scheme-data.dto'
import { ResourceName } from '../../../../core/ResourceName'
import { RAColorField } from '../../../common/RAColorField'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { SchemeDto } from '../../../../core/dto/scheme/scheme.dto'

interface PalmSchemeDataTitleProps extends TitleProps {
  record?: PalmSchemeDataDto
}

const ShowTitle = (data: PalmSchemeDataTitleProps) => {
  const { record: scheme } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.palm-scheme-data.titles.show')}: {scheme?.id}.{' '}
      {scheme?.text}
    </span>
  )
}

const GeneralActions = () => {
  const { record: scheme } = useShowContext<PalmSchemeDataDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="flex" justifyContent="flex-end">
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <EditButton
              variant="contained"
              basePath="/palm-scheme-data"
              record={scheme}
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_PALM_SCHEME_DATA)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const PalmSchemeDataGeneralTab = ({ ...props }) => {
  const { record: scheme } = props
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <Tab
      {...props}
      label={translate('resources.palm-scheme-data.tabs.general')}
    >
      <TextField source="id" label="ID" />
      <FunctionField<PalmSchemeDataDto>
        source="language"
        render={(record?: PalmSchemeDataDto) =>
          translate(`settings.language.${record?.language}`)
        }
      />
      <TextField source="text" />
      <RAColorField source="backgroundColor" />
      {scheme?.backgroundImage && <TextField source="backgroundImage" />}
      {scheme?.sound && <TextField source="sound" />}
      <FunctionField<PalmSchemeDataDto>
        source="positiveValidation"
        render={(record?: PalmSchemeDataDto) =>
          record?.positiveValidation
            ? translate('const.yes')
            : translate('const.no')
        }
      />
      <TextField source="schemeId" />
      {hasAuthority(Authority.VIEW_SCHEMES) && (
        <ReferenceField
          source="schemeId"
          reference={ResourceName.SCHEMES}
          label="resources.palm-scheme-data.fields.scheme"
          link={false}
        >
          <FunctionField<SchemeDto>
            source="name"
            render={(record?: SchemeDto) => `${record?.id}. ${record?.name}`}
          />
        </ReferenceField>
      )}
      <GeneralActions />
    </Tab>
  )
}

export const PalmSchemeDataShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <TabbedShowLayout>
      <PalmSchemeDataGeneralTab />
    </TabbedShowLayout>
  </Show>
)
