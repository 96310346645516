import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Filter,
  FunctionField,
  ListProps,
  TextField,
  TextInput,
  SearchInput,
  Pagination,
  Datagrid,
} from 'react-admin'
import { CustomerDto } from '../../core/dto/customer.dto'
import { ResourceName } from '../../core/ResourceName'
import FilterProps from '../common/FilterProps'
import List from '../common/customized-ra-components/List'
import { MaskField } from '../common/MaskField'

const CustomerFilters = (props: FilterProps) => (
  <Filter {...props}>
    <SearchInput source="info" alwaysOn />
    <TextInput source="firstname" />
    <TextInput source="lastname" />
    <TextInput source="pesel" />
    <TextInput source="documentNumber" />
  </Filter>
)

export const CustomerList = (props: ListProps) => {
  const theme = useTheme()
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <List
      {...props}
      exporter={false}
      sort={{ field: 'lastname', order: 'ASC' }}
      filters={<CustomerFilters />}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      bulkActionButtons={false}
    >
      <Datagrid
        rowClick={(id) => `${ResourceName.CUSTOMERS}/${id}/show`}
        hasBulkActions={false}
      >
        <TextField source="firstname" />
        <TextField source="lastname" />
        {smallDevice && (
          <FunctionField<CustomerDto>
            source="documentNumber"
            render={(record?: CustomerDto) =>
              record?.pesel ? (
                <MaskField source="pesel" />
              ) : (
                <MaskField source="documentNumber" />
              )
            }
          />
        )}
        {!smallDevice && <MaskField source="pesel" />}
        {!smallDevice && <MaskField source="documentNumber" />}
        {!smallDevice && <TextField source="company" />}
      </Datagrid>
    </List>
  )
}
