import { DataProvider, GetListResult } from 'react-admin'
import { DYNAMIC_STATISTICS_URL } from '../../api-urls'
import { postWithPagination } from '../../common/fetch.utils'
import { queryParamsComposer } from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'

const provider = {
  getList: async (
    resource: string,
    { filter, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult> => {
    const pathParams = queryParamsComposer(undefined, pagination)
    const path = `/${pathParams ? `?${pathParams}` : ''}`

    const { id, dynamicallyDefinedParameters } = filter

    const {
      data,
      range: { total },
    } = await postWithPagination<
      ExecuteDynamicStatisticRequest,
      ExecuteDynamicStatisticResponse
    >(`${DYNAMIC_STATISTICS_URL}/Execute/${id}${path}`, {
      dynamicallyDefinedParameters,
    })

    return Promise.resolve({
      data,
      total,
    })
  },
} as DataProvider

interface ExecuteDynamicStatisticRequest {
  dynamicallyDefinedParameters: {
    [name: string]: string
  }
}

interface ExecuteDynamicStatisticResponse {
  data: any[]
}

export default provider
