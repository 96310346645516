import * as React from 'react';
import { Button } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { ResourcesModelsEditor } from './ResourcesModelsEditor';
import { usePreferencesEditor } from '../usePreferencesEditor';
export var EditResourcesModelsButton = function () {
    var setEditor = usePreferencesEditor().setEditor;
    var translate = useTranslate();
    var handleShowModelEditorClick = function () {
        setEditor(React.createElement(ResourcesModelsEditor, null));
    };
    return (React.createElement(Button, { onClick: handleShowModelEditorClick }, translate('ra-preferences.no-code.editor.resources_models')));
};
