import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React from 'react'
import {
  Title,
  useTranslate,
  GET_ONE,
  sanitizeFetchType,
  useQueryWithStore,
} from 'react-admin'
import { CurrentUserEdit } from './CurrentUserEdit'
import { ResourceName } from '../../core/ResourceName'
import { UserSettingsEdit } from './UserSettingsEdit'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'

const useStyles = makeStyles((theme: Theme) => ({
  accordionSumary: {
    backgroundColor: theme.palette.action.hover,
  },
  accordionDetails: {
    padding: 0,
  },
}))

export const Configuration = () => {
  const t = useTranslate()
  const classes = useStyles()
  const hasAuthority = useHasAuthority()

  const { data: user, loading } = useQueryWithStore({
    type: sanitizeFetchType(GET_ONE),
    resource: ResourceName.CURRENT_USER,
    payload: {},
  })

  return (
    <Card>
      <Title title={t('settings.name')} />
      {!loading && hasAuthority(Authority.VIEW_USER_MANAGEMENT) && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.accordionSumary}
          >
            {t('settings.titles.profile')}
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <CurrentUserEdit
              basePath="/configuration"
              resource={ResourceName.CURRENT_USER}
              id={user?.id}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {hasAuthority(Authority.VIEW_USER_MANAGEMENT) && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.accordionSumary}
          >
            {t('settings.titles.configuration')}
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <UserSettingsEdit />
          </AccordionDetails>
        </Accordion>
      )}
    </Card>
  )
}
