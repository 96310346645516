import { useMediaQuery, useTheme } from '@material-ui/core'
import * as React from 'react'
import {
  DateField,
  Filter,
  TextField,
  TextInput,
  useTranslate,
  Pagination,
  Datagrid,
} from 'react-admin'
import { ResourceName } from '../../../core/ResourceName'
import FilterProps from '../../common/FilterProps'
import List from '../../common/customized-ra-components/List'

const ListTitle = () => {
  const t = useTranslate()
  return (
    <span>
      {t(
        `resources.${ResourceName.STATS_EVENT_ENTRANCES_VIEW_LIST}.titles.list`,
      )}
    </span>
  )
}

const EntrancesViewFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
)

export const EventEntrancesViewList = ({ ...props }) => {
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <List
      {...props}
      actions={false}
      filter={{
        hasObjects: true,
      }}
      filters={<EntrancesViewFilters />}
      sort={{ field: 'id', order: 'DESC' }}
      title={<ListTitle />}
      pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
      bulkActionButtons={false}
    >
      <Datagrid
        rowClick={(id) =>
          `/${ResourceName.STATS_EVENT_ENTRANCES_VIEW}?${new URLSearchParams({
            filter: `{"eventId":${id}}`,
          }).toString()}`
        }
        hasBulkActions={false}
      >
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="dateStart" showTime />
        {!xSmallScreen && <DateField source="dateEnd" showTime />}
      </Datagrid>
    </List>
  )
}
