const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string
> = {
  id: (lambdaParameter, value) => `${lambdaParameter}.Id==${value}`,
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  objectId: (lambdaParameter, value) =>
    `${lambdaParameter}.Tribune.ObjectId.Equals(${value})`,
  tribuneId: (lambdaParameter, value) =>
    `${lambdaParameter}.TribuneId==${value}`,
  code: (lambdaParameter, value) =>
    `${lambdaParameter}.Code.Contains("${value}")`,
  type: (lambdaParameter, value) =>
    `${lambdaParameter}.Type.Equals(TicketAPI.Models.Enums.TicketManagement.BlockTypes.${value})`,
  belongsToEvent: (lambdaParameter, value) =>
    `${lambdaParameter}.Tribune.Object.Events.Any(ev=>ev.Id.Equals(${value}))`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
