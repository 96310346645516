import React, { useCallback, useState } from 'react'
import {
  useNotify,
  useMutation,
  useTranslate,
  sanitizeFetchType,
  GetListResult,
  GET_LIST,
} from 'react-admin'
import { useHistory } from 'react-router'
import { stringify } from 'querystring'
import { CardContent, CardHeader } from '@material-ui/core'
import { ResourceName } from '../../core/ResourceName'
import SearchInput from './SearchInput'
import { CustomerDto } from '../../core/dto/customer.dto'

export const FindCustomerByInfo = () => {
  const history = useHistory()
  const notify = useNotify()
  const [error, setError] = useState('')
  const [mutate, { loading }] = useMutation()
  const t = useTranslate()

  const fetchCustomers = useCallback(
    (info: string) =>
      mutate(
        {
          type: sanitizeFetchType(GET_LIST),
          resource: ResourceName.CUSTOMERS,
          payload: { filter: { info } },
        },
        {
          returnPromise: true,
          onFailure: (err) => notify(err?.message, 'error'),
        },
      ) as Promise<GetListResult<CustomerDto>>,
    [mutate, notify],
  )

  const onValue = useCallback(
    async (info: string) => {
      if (info.replace(/\s/g, '').length === 0) {
        setError('common.find-customer-by-info.invalidQuery')
      } else if (info.length < 3) {
        setError('common.find-customer-by-info.tooShort')
      } else {
        setError('')
        const {
          total,
          data: [onlyCustomer],
        } = await fetchCustomers(info)
        if (!total) {
          setError('common.find-customer-by-info.error')
        } else if (total === 1) {
          history.push({
            pathname: `/${ResourceName.CUSTOMERS}/${onlyCustomer.id}/show`,
          })
        } else {
          history.push({
            pathname: `/${ResourceName.CUSTOMERS}`,
            search: stringify({
              filter: JSON.stringify({
                info,
              }),
            }),
          })
        }
      }
    },
    [fetchCustomers, history],
  )

  return (
    <>
      <CardHeader title={t('common.find-customer-by-info.title')} />
      <CardContent>
        <SearchInput
          label="common.find-customer-by-info.input-label"
          onValue={onValue}
          disabled={loading}
          error={error}
        />
      </CardContent>
    </>
  )
}
