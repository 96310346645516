import React from 'react'
import {
  ListProps,
  TextField,
  Pagination,
  TextInput,
  Filter,
  Datagrid,
} from 'react-admin'
import List from '../../common/customized-ra-components/List'
import FilterProps from '../../common/FilterProps'

const AccessBonusFilters = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput source="info" alwaysOn />
  </Filter>
)

export const AccessBonusList = (props: ListProps) => (
  <List
    perPage={20}
    exporter={false}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100, 200]} />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    filters={<AccessBonusFilters />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
)
