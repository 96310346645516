import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { useTranslate, useDeleteWithUndoController, useRecordContext, useResourceContext, } from 'react-admin';
export var DeleteWithUndoIconButton = function (props) {
    var basePath = props.basePath, className = props.className, _a = props.label, label = _a === void 0 ? 'ra.action.delete' : _a, _b = props.redirect, redirectTo = _b === void 0 ? 'list' : _b;
    var record = useRecordContext(props);
    var resource = useResourceContext(props);
    var _c = useDeleteWithUndoController({
        resource: resource,
        record: record,
        redirect: redirectTo,
        basePath: basePath,
    }), loading = _c.loading, handleDelete = _c.handleDelete;
    var translate = useTranslate();
    var translatedLabel = translate(label, { _: label });
    return (React.createElement(Tooltip, { title: translatedLabel },
        React.createElement(IconButton, { "aria-label": translatedLabel, disabled: loading, onClick: handleDelete, className: classnames('ra-delete-button', className), key: "button", size: "small" },
            React.createElement(ActionDelete, { color: "error" }))));
};
DeleteWithUndoIconButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.any,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    icon: PropTypes.element,
};
