import {
  GetOneParams,
  GetOneResult,
  GetListResult,
  GetManyReferenceResult,
  DataProvider,
  GetManyParams,
  GetManyResult,
} from 'react-admin'
import { JOBS_URL } from '../api-urls'
import { DataProviderExtensionResult } from '../common/data-provider'
import { get, getWithPagination } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { TicketAPIGetManyReferenceParams } from '../common/ticket-api-get-may-reference.params'
import { JobDetails } from '../dto/recurring-job-details.dto'
import filterMapper from './job-filter.mapper'
import { mapSortJobParam } from './job-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<JobDetailsRaResponse>> => {
    const filterParams = `v=>${filterParamsComposer('v', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortJobParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<JobDetails[]>(
      `${JOBS_URL}/GetJobsByConditions`,
      path,
    )

    return Promise.resolve({
      data: data.map((job: JobDetails) => ({ ...job, id: job.jobId })),
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<JobDetailsRaResponse>> => {
    const data = await get<JobDetails>(`${JOBS_URL}/GetJob`, `/${id}`)
    return Promise.resolve({
      data: {
        ...data,
        id: data.jobId,
      },
    })
  },
  deleteJob: async (
    resource: string,
    { jobId }: DeleteJobRequest,
  ): Promise<DataProviderExtensionResult<DeleteJobResponse>> => {
    const openStream = await get<DeleteJobResponse>(
      `${JOBS_URL}/DeleteJob`,
      `/${jobId}`,
    )
    return {
      data: openStream,
    }
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<JobDetails>> => {
    const response = await get<JobDetails[]>(
      JOBS_URL,
      `/GetJobsByConditions/o=>new int[] {${ids.toString()}}.Contains(o.JobId)`,
    )
    const data = response.map((job) => ({ ...job, id: job?.jobId }))
    return Promise.resolve({ data })
  },
  getManyReference: async (
    resource: string,
    { id, pagination, sort, target }: TicketAPIGetManyReferenceParams,
  ): Promise<GetManyReferenceResult<JobDetailsRaResponse>> => {
    const filterPayload = {
      [target]: id,
    }

    const filterParams = `v=>${filterParamsComposer(
      'v',
      filterPayload,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortJobParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<JobDetails[]>(
      `${JOBS_URL}/GetJobsByConditions`,
      path,
    )

    return Promise.resolve({
      data: data.map((job: JobDetails) => ({ ...job, id: job.jobId })),
      total,
    })
  },
} as JobsDataProvider

interface JobsDataProvider extends DataProvider {
  deleteJob: (
    resource: string,
    params: DeleteJobRequest,
  ) => Promise<DataProviderExtensionResult<DeleteJobResponse>>
}

export interface DeleteJobRequest {
  readonly jobId: number
}

export interface DeleteJobResponse {
  readonly jobId: number
}

export interface JobDetailsRaResponse extends JobDetails {
  readonly id: string
}

export default provider
