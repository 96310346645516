import { Identifier } from 'ra-core'
import camelcaseKeys from 'camelcase-keys'
import { IOStatusDto } from '../../../dto/device/common/modules/io-status.dto'
import { LiteDto } from '../../../dto/device/common/modules/lite.dto'
import { ModuleBaseDto } from '../../../dto/device/common/modules/module-base.dto'
import { OfflineServerModuleDto } from '../../../dto/device/common/modules/offline-server-module.dto'
import { PresenterDto } from '../../../dto/device/common/modules/presenter.dto'
import { ReaderDto } from '../../../dto/device/common/modules/reader.dto'
import { SensorDto } from '../../../dto/device/common/modules/sensor.dto'
import { TurnstileDto } from '../../../dto/device/common/modules/turnstile.dto'
import { DeviceModuleTypes } from '../../../enum/device-modues/DeviceModuleTypes'
import { LCDPlusDto } from '../../../dto/device/common/modules/lcd-plus.dto'
import { LacanIODto } from '../../../dto/device/common/modules/lacan-io.dto'

const mapping: Record<DeviceModuleTypes, (moduleObject: any) => ModuleBaseDto> =
  {
    '0': (moduleObject) => moduleObject as OfflineServerModuleDto,
    '4': (moduleObject) => moduleObject as IOStatusDto,
    '8': (moduleObject) => moduleObject as TurnstileDto,
    '12': (moduleObject) => moduleObject as TurnstileDto,
    '16': (moduleObject) => moduleObject as TurnstileDto,
    '20': (moduleObject) => moduleObject as LiteDto,
    '24': (moduleObject) => moduleObject as ReaderDto,
    '28': (moduleObject) => moduleObject as ReaderDto,
    '36': (moduleObject) => moduleObject as LacanIODto,
    '44': (moduleObject) => moduleObject as PresenterDto,
    '48': (moduleObject) => moduleObject as PresenterDto,
    '52': (moduleObject) => moduleObject as IOStatusDto,
    '60': (moduleObject) => moduleObject as PresenterDto,
    '64': (moduleObject) => moduleObject as PresenterDto,
    '76': (moduleObject) => moduleObject as PresenterDto,
    '84': (moduleObject) => moduleObject as LiteDto,
    '88': (moduleObject) => moduleObject as LCDPlusDto,
    '96': (moduleObject) => moduleObject as SensorDto,
  }

const moduleMapper = (moduleObject: any, key: Identifier) => {
  const moduleType = Number(moduleObject?.module?.typeId)
  const moduleTypeEnum = DeviceModuleTypes[moduleType]
  if (!moduleTypeEnum) {
    return null
  }
  const moduleObjectWithId = { id: key, ...moduleObject }

  return mapping[moduleType as DeviceModuleTypes](moduleObjectWithId)
}

export const getModulesFromJsonStatus = (rawJsonStatus: string) => {
  let jsonStatus
  try {
    jsonStatus = JSON.parse(rawJsonStatus)
    if (jsonStatus?.Modules !== null && jsonStatus?.Modules !== undefined) {
      jsonStatus = jsonStatus.Modules as Array<any>
      if (jsonStatus === null || jsonStatus === undefined) jsonStatus = []
      else jsonStatus = jsonStatus.map((mod) => mod.Status)
    } else {
      jsonStatus = jsonStatus as Array<any>
      if (jsonStatus === null || jsonStatus === undefined) jsonStatus = []
    }
  } catch {
    jsonStatus = []
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.entries(jsonStatus).map(([key, value]) =>
    moduleMapper(
      camelcaseKeys(value as Record<string, any>, { deep: true }),
      key,
    ),
  )
}

export default moduleMapper
