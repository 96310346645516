const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  id: (lambdaParameter, value) => `${lambdaParameter}.Id==${value}`,
  templateId: (lambdaParameter, value) =>
    `${lambdaParameter}.ImportTemplate.Id.Equals(${value})`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
