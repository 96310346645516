const mapping: Record<string, string> = {
  id: 'l=>l.Id',
  variantId: 'l=>l.VariantId',
  eventId: 'l=>l.EventId',
  disabled: 'l=>l.Disabled',
  activeFrom: 'l=>l.ActiveFrom',
  activeTo: 'l=>l.ActiveTo',
  customerId: 'l=>l.CustomerId',
}

export const mapSortBookingParam = (key: string) => mapping[key]
