import { Box, Divider } from '@material-ui/core'
import React from 'react'
import {
  EditButton,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TitleProps,
  useShowContext,
  useTranslate,
  ResourceContextProvider,
  List,
  Pagination,
  TextInput,
  FunctionField,
} from 'react-admin'
import { CameraServerDto } from '../../../../core/dto/device/camera/camera-servers/camera-server.dto'
import { Authority } from '../../../../core/auth/Authority'
import { ResourceName } from '../../../../core/ResourceName'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import {
  EditableDatagrid,
  RowForm,
} from '../../../../lib/@react-admin/ra-editable-datagrid/esm/src'
import { validateCameraCreate } from '../cameras/CameraCreate'
import { validateCameraEdit } from '../cameras/CameraEdit'
import { CameraTypes } from '../../../../core/enum/CameraTypes'
import { CameraServerSetCredentialsButton } from './camera-server-show/CameraServerSetCredentialsButton'
import { CameraServerRemoveCredentialsButton } from './camera-server-show/CameraServerRemoveCredentialsButton'

interface CameraServerTitleProps extends TitleProps {
  record?: CameraServerDto
}

const ShowTitle = (data: CameraServerTitleProps) => {
  const { record: server } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.camera-servers.titles.show')}: {server?.id}.{' '}
      {server?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: server } = useShowContext<CameraServerDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <CameraServerSetCredentialsButton />
        <CameraServerRemoveCredentialsButton />
        <EditButton
          basePath={`/${ResourceName.CAMERA_SERVERS}`}
          record={server}
          variant="contained"
          disabled={!hasAuthority(Authority.EDIT_CAMERA_SERVERS)}
        />
      </Box>
    </>
  )
}

const ServerCameraCreate = ({ ...props }) => {
  const { serverId, ...rest } = props
  return (
    <RowForm
      initialValues={{
        serverId,
      }}
      {...rest}
      validate={validateCameraCreate}
    >
      <TextField source="id" label="ID" />
      <TextInput source="description" isRequired />
      <TextInput source="serverSideName" isRequired />
    </RowForm>
  )
}

const ServerCameraEdit = ({ ...props }) => (
  <RowForm {...props} validate={validateCameraEdit}>
    <TextField source="id" label="ID" />
    <TextInput source="description" isRequired />
    <TextInput source="serverSideName" isRequired />
  </RowForm>
)

const ServerCamerasGrid = ({ ...props }) => {
  const { record: server } = useShowContext()
  const hasAuthority = useHasAuthority()

  return (
    <EditableDatagrid
      {...props}
      actions={hasAuthority(Authority.EDIT_CAMERAS) ? undefined : false}
      editForm={<ServerCameraEdit />}
      createForm={
        hasAuthority(Authority.EDIT_CAMERAS) &&
        hasAuthority(Authority.CREATE_CAMERAS) ? (
          <ServerCameraCreate serverId={server?.id} />
        ) : undefined
      }
      hasBulkActions={false}
      defaultTitle={null}
      noDelete
    >
      <TextField source="id" label="ID" />
      <TextField source="description" />
      <TextField source="serverSideName" />
    </EditableDatagrid>
  )
}

const ServerCamerasTab = () => {
  const { record: server, basePath } = useShowContext()
  return (
    <ResourceContextProvider value={ResourceName.CAMERAS}>
      <List
        title=" "
        actions={false}
        exporter={false}
        basePath={basePath}
        filter={{ serverId: server?.id }}
        filters={undefined}
        perPage={20}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} />}
        bulkActionButtons={false}
        empty={false}
      >
        <ServerCamerasGrid />
      </List>
    </ResourceContextProvider>
  )
}

const CameraServerTabs = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const { record: server } = useShowContext()
  const translate = useTranslate()

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.camera-servers.tabs.general">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <FunctionField
          source="type"
          render={(record) =>
            translate(`resources.enums.CameraTypes.${record?.type}`)
          }
        />
        {server?.timeZone && (
          <FunctionField
            source="timeZone"
            render={(record) =>
              translate(`resources.enums.CameraTimeZones.${record?.timeZone}`)
            }
          />
        )}
        {server?.type !== CameraTypes.WEBSOCKET && (
          <FunctionField
            source="webProtocol"
            render={(record) =>
              translate(`resources.enums.WebProtocols.${record?.webProtocol}`)
            }
          />
        )}
        {server?.type !== CameraTypes.WEBSOCKET && (
          <TextField source="address" />
        )}
        {server?.type !== CameraTypes.HIKVISION_RTSP &&
          server?.type !== CameraTypes.AXXON_MJPEG &&
          server?.type !== CameraTypes.DAHUA_RTSP && (
            <TextField source="serverId" />
          )}
        <TextField source="delay" />
        {server?.downloadClipDuration && (
          <TextField source="downloadClipDuration" />
        )}
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_CAMERAS) && (
        <Tab label="resources.camera-servers.tabs.cameras" path="cameras">
          <ServerCamerasTab />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const CameraServerShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <CameraServerTabs />
  </Show>
)
