import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Create,
  CreateProps,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useLocation } from 'react-router'
import { SeatDto } from '../../../core/dto/seat/seat.dto'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

const validateSeatCreate = (values: SeatDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.blockId) {
    errors.blockId = 'ra.validation.required'
  }
  return errors
}

const SeatCreate = (props: CreateProps) => {
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { search } = useLocation()
  const objectIdRaw = new URLSearchParams(search).get('objectId')
  const objectId: number | undefined =
    objectIdRaw !== null ? Number(objectIdRaw) : undefined

  return (
    <Create {...props} title={translate('resources.seats.create')}>
      <SimpleForm
        redirect={`/objects/${objectId}/show/3`}
        validate={validateSeatCreate}
      >
        <FilteredReferenceInput
          source="blockId"
          defaultValue={null}
          reference={ResourceName.BLOCKS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          isRequired
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
        <TextInput source="numberAlias" />
        <TextInput source="rowAlias" />
      </SimpleForm>
    </Create>
  )
}

export default SeatCreate
