const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  firstname: (lambdaParameter, value) =>
    `${lambdaParameter}.Firstname.Contains("${value}")`,
  lastname: (lambdaParameter, value) =>
    `${lambdaParameter}.Lastname.Contains("${value}")`,
  pesel: (lambdaParameter, value) =>
    `${lambdaParameter}.Pesel.Contains("${value}")`,
  documentNumber: (lambdaParameter, value) =>
    `${lambdaParameter}.DocumentNumber.Contains("${value}")`,
  info: (lambdaParameter, value) =>
    `${value
      .toString()
      .split(' ')
      .map(
        (q: string) =>
          `(${lambdaParameter}.Id+${lambdaParameter}.Firstname+${lambdaParameter}.Lastname+${lambdaParameter}.Pesel+${lambdaParameter}.DocumentNumber).Contains("${q}")`,
      )
      .join('&&')}`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
