import {
  createStyles,
  Dialog,
  DialogActions,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import { useTranslate } from 'react-admin'
import { ResourceName } from '../../core/ResourceName'
import { CreateRecurringJobButton } from './CreateRecurringJobButton'
import Button from './customized-mui-components/Button'
import DraggableComponent from './DraggableComponent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogProperty: {
      marginBottom: theme.spacing(1),
    },
    confirmDialog: {
      minWidth: '300px',
    },
    actionsButton: {
      margin: '2%',
      width: '96%',
    },
    bulkStateActions: {
      height: '16px',
      background: 'rgba(0,0,0,0.2)',
      minWidth: '300px',
      userSelect: 'none',
    },
    bulkProgressTitle: {
      paddingLeft: '10px',
      width: '100%',
    },
    bulkProgressCloseButton: {
      margin: 0,
    },
  }),
)

export const ConfirmDialog = ({
  confirm,
  cancel,
  source,
  open,
  action,
  resource,
  asRecurringJob,
}: {
  confirm:
    | (() => void)
    | ((recurringJobName?: string, cronExpression?: string) => void)
  cancel: () => void
  source: string
  open: boolean
  action: 'set' | 'remove'
  resource: ResourceName
  asRecurringJob?: boolean
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <>
      <Dialog
        open={open}
        onClose={cancel}
        disableBackdropClick
        disableEscapeKeyDown
        PaperComponent={DraggableComponent}
        aria-labelledby="draggable-confirm-title"
        className={classes.confirmDialog}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogActions
          className={classes.bulkStateActions}
          style={{ cursor: 'move' }}
        >
          <Typography
            className={classes.bulkProgressTitle}
            id="draggable-confirm-title"
          >
            {translate(
              `resources.${resource}.dialogs.confirm.${source}.${action}`,
            )}
          </Typography>
          <IconButton
            size="small"
            className={classes.bulkProgressCloseButton}
            onClick={cancel}
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogActions>
        <DialogActions>
          {!asRecurringJob ? (
            <Button
              variant="contained"
              onClick={confirm as () => void}
              label={translate('const.yes')}
              useSmallVersionBreakpoint={false}
              className={classes.actionsButton}
            />
          ) : (
            <CreateRecurringJobButton
              size="small"
              saveRecurringJob={confirm}
              variant="contained"
              label="const.yes"
              className={classes.actionsButton}
            />
          )}
          <Button
            variant="contained"
            onClick={cancel}
            label={translate('const.no')}
            useSmallVersionBreakpoint={false}
            className={classes.actionsButton}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
