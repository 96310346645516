import * as React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const Empty = ({ text }: { text: string }) => (
  <Box textAlign="start" m={2}>
    <Typography variant="body2" paragraph>
      {text}
    </Typography>
  </Box>
)

export default Empty
