import { DataProvider } from 'react-admin'
import { DeviceBaseDto } from '../../dto/device/common/device-base/device-base.dto'

const fakeDevices: DeviceBaseDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeDevices, total: fakeDevices.length }),
} as DataProvider

export default provider
