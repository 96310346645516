const mapping: Record<string, string> = {
  id: 'l=>l.Id',
  serviceId: 'l=>l.ServiceId',
  serviceType: 'l=>l.ServiceType',
  apiUrl: 'l=>l.ApiUrl',
  eventId: 'l=>l.EventId',
  blockId: 'l=>l.BlockId',
  customerId: 'l=>l.CustomerId',
  variantId: 'l=>l.VariantId',
}

export const mapSortLogParam = (key: string) => mapping[key]
