import { FormControlLabel, Grid, Paper, Switch } from '@material-ui/core'
import { Event, List, Person, Search } from '@material-ui/icons'
import React, { ReactElement } from 'react'
import { useTranslate, Labeled } from 'react-admin'
import { UserSettingsDto } from '../../core/dto/user-settings.dto'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'

type DashboardItemSwitchProps = {
  checked: boolean
  label: string
  icon: ReactElement
  onSwitch: () => void
}

const DashboardItemSwitch = ({
  checked,
  label,
  icon,
  onSwitch,
}: DashboardItemSwitchProps) => {
  const translate = useTranslate()

  return (
    <Grid item xs={6} md={4} lg={3}>
      <Paper>
        {icon}
        <FormControlLabel
          control={
            <Switch checked={checked} onChange={onSwitch} color="primary" />
          }
          label={translate(label)}
          labelPlacement="top"
          style={{ margin: '5%', textAlign: 'center', width: '90%' }}
        />
      </Paper>
    </Grid>
  )
}

interface DashboardCustomizationProps {
  handleChangeValue: (key: string, value: string | boolean) => void
  record: UserSettingsDto
}

export const DashboardCustomization = ({
  handleChangeValue,
  record,
}: DashboardCustomizationProps) => {
  const hasAuthority = useHasAuthority()
  return (
    <Labeled label="dashboard.settings.title">
      <Grid container spacing={1}>
        {hasAuthority(Authority.VIEW_TICKETS) && (
          <DashboardItemSwitch
            checked={
              record?.frontDashboardVisibility_TicketFinder
                ? record.frontDashboardVisibility_TicketFinder
                : false
            }
            label="dashboard.settings.ticket-finder-label"
            icon={<Search />}
            onSwitch={() =>
              handleChangeValue(
                'frontDashboardVisibility_TicketFinder',
                !record.frontDashboardVisibility_TicketFinder,
              )
            }
          />
        )}
        {hasAuthority(Authority.VIEW_LOGS) && (
          <DashboardItemSwitch
            checked={
              record?.frontDashboardVisibility_LastLogs
                ? record.frontDashboardVisibility_LastLogs
                : false
            }
            label="dashboard.settings.last-logs-label"
            icon={<List />}
            onSwitch={() =>
              handleChangeValue(
                'frontDashboardVisibility_LastLogs',
                !record.frontDashboardVisibility_LastLogs,
              )
            }
          />
        )}
        {hasAuthority(Authority.VIEW_EVENTS) && (
          <DashboardItemSwitch
            checked={
              record?.frontDashboardVisibility_LastEvents
                ? record.frontDashboardVisibility_LastEvents
                : false
            }
            label="dashboard.settings.last-events-label"
            icon={<Event />}
            onSwitch={() =>
              handleChangeValue(
                'frontDashboardVisibility_LastEvents',
                !record.frontDashboardVisibility_LastEvents,
              )
            }
          />
        )}
        {hasAuthority(Authority.VIEW_CUSTOMERS) && (
          <DashboardItemSwitch
            checked={
              record?.frontDashboardVisibility_CustomerFinder
                ? record.frontDashboardVisibility_CustomerFinder
                : false
            }
            label="dashboard.settings.customer-finder-label"
            icon={<Person />}
            onSwitch={() =>
              handleChangeValue(
                'frontDashboardVisibility_CustomerFinder',
                !record.frontDashboardVisibility_CustomerFinder,
              )
            }
          />
        )}
      </Grid>
    </Labeled>
  )
}
