import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Create,
  CreateProps,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useLocation } from 'react-router'
import { BlockDto } from '../../../core/dto/block.dto'
import { BlockType } from '../../../core/enum/BlockType'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

const validateBlockCreate = (values: BlockDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.tribuneId) {
    errors.tribuneId = 'ra.validation.required'
  }
  return errors
}

const BlockCreate = (props: CreateProps) => {
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { search } = useLocation()
  const objectIdRaw = new URLSearchParams(search).get('objectId')
  const objectId: number | undefined =
    objectIdRaw !== null ? Number(objectIdRaw) : undefined

  return (
    <Create {...props} title={translate('resources.blocks.create')}>
      <SimpleForm
        redirect={`/objects/${objectId}/show/2`}
        validate={validateBlockCreate}
      >
        <FilteredReferenceInput
          source="tribuneId"
          defaultValue={null}
          reference={ResourceName.TRIBUNES}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          isRequired
          filterSource="search"
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
        <TextInput source="name" isRequired />
        <TextInput source="code" />
        <SelectInput
          source="type"
          choices={Object.entries(BlockType).map(([value]) => ({
            id: value,
            name: `resources.enums.blockType.${value}`,
          }))}
        />
      </SimpleForm>
    </Create>
  )
}

export default BlockCreate
