import { DataProvider } from 'react-admin'
import { DynamicStatisticDto } from '../../dto/dynamic-statistic.dto'

const fakeDynamicStatisticsViewList: DynamicStatisticDto[] = []

const provider = {
  getOne: (resource, { id }: { id: string }) => {
    const found = fakeDynamicStatisticsViewList.find((v) => `${v.id}` === id)
    if (found) {
      return Promise.resolve({
        data: found,
      })
    }
    return Promise.reject(new Error())
  },
} as DataProvider

export default provider
