import * as React from 'react';
import { getResources, useGetResourceLabel, } from 'react-admin';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { List, ListItemText } from '@material-ui/core';
import { ResourceModelEditor } from './ResourceModelEditor';
import { EditorTitle, PreferencesEditor } from '../Editor';
import { ListItemLink } from '../ListItemLink';
import { useRouteMatch } from 'react-router-dom';
export var ResourcesModelsEditor = function () {
    var data = useSelector(getResources);
    var getResourceLabel = useGetResourceLabel();
    var routeMatch = useRouteMatch();
    return (React.createElement(PreferencesEditor, null,
        React.createElement(Switch, null,
            data.map(function (_a) {
                var name = _a.name;
                return (React.createElement(Route, { key: name + "-route", path: routeMatch.path + "/configure-" + name, render: function () { return React.createElement(ResourceModelEditor, { resource: name }); } }));
            }),
            React.createElement(Route, { path: routeMatch.path, exact: true, render: function () { return (React.createElement(React.Fragment, null,
                    React.createElement(EditorTitle, { title: "ra-preferences.no-code.editor.resources_models" }),
                    React.createElement(List, { dense: true }, data.map(function (_a) {
                        var name = _a.name;
                        return (React.createElement(ListItemLink, { key: name + "-link", to: routeMatch.path + "/configure-" + name },
                            React.createElement(ListItemText, { primary: getResourceLabel(name, 2) })));
                    })))); } }))));
};
