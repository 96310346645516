import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import {
  FunctionField,
  Record,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin'
import { connect } from 'react-redux'
import { HIDE_API_ERROR_MODAL } from '../../core/constants/reducer.actions'
import DraggableComponent from './DraggableComponent'
import { ApiErrorModalPayload } from '../../core/common/api-error-modal.reducer'

const useStyles = makeStyles((theme: Theme) => ({
  dialogHeader: {
    height: '16px',
    background: theme.palette.error.main,
    minWidth: '300px',
    userSelect: 'none',
    color: theme.palette.error.contrastText,
  },
  dialogTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  dialogCloseButton: {
    margin: 0,
  },
  dialogContent: {
    padding: theme.spacing(0.5),
  },
  accordionSummary: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
}))

const ApiErrorModal = ({
  open,
  close,
  payload,
}: {
  open: boolean
  close: () => void
  payload?: ApiErrorModalPayload
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const handleClosePressed = () => {
    if (close) close()
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xl"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogActions
        style={{ cursor: 'move' }}
        className={classes.dialogHeader}
      >
        <Typography
          className={classes.dialogTitle}
          variant="h6"
          id="draggable-dialog-title"
        >
          HTTP {payload?.httpCode}
        </Typography>
        <IconButton
          size="small"
          onClick={handleClosePressed}
          className={classes.dialogCloseButton}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <SimpleShowLayout
          record={payload as any as Record}
          resource="api-error-handling"
        >
          {payload?.identifier && <TextField source="identifier" label="Id" />}
          {(payload?.details?.[0]?.errorCode || payload?.code) && (
            <FunctionField
              source="code"
              render={(record) =>
                record?.details?.[0]?.errorCode || record?.code
              }
            />
          )}
          {payload?.code && payload.code !== 'xxxx-xxxx' ? (
            <FunctionField
              source="message"
              render={(record?: Record) =>
                translate(
                  `common.api-error-handling.messages.${
                    record?.details?.[0]?.errorCode || record?.code.substring(5)
                  }`,
                )
              }
            />
          ) : (
            payload?.message && (
              <FunctionField
                source="message"
                render={(record?: Record) => record?.details?.[0]?.message}
              />
            )
          )}
          {payload?.details?.[0] && (
            <FunctionField
              source="details"
              label=" "
              fullWidth
              render={(record?: Record) => (
                <Accordion defaultExpanded>
                  <AccordionSummary>
                    <Typography className={classes.accordionSummary}>
                      {translate('resources.api-error-handling.fields.details')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{record?.details?.[0]?.message}</Typography>
                  </AccordionDetails>
                </Accordion>
              )}
            />
          )}
        </SimpleShowLayout>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state) => ({
  open: state.apiErrorModal?.show ? state.apiErrorModal.show : false,
  payload: state.apiErrorModal,
})

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch({ type: HIDE_API_ERROR_MODAL }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApiErrorModal)
