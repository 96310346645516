import { UPDATE_DASHBOARD } from '../constants/reducer.actions'

const identity = localStorage.getItem('username')
export const INACTIVE_COMPONENTS_KEY = `${identity}.inactiveComponents`

export enum DashboardItem {
  TICKET_FINDER = 'ticket_finder',
  LAST_LOGS = 'last_logs',
  LAST_EVENTS = 'last_events',
  CUSTOMER_FINDER = 'customer_finder',
}

export type DashboardState = {
  dashboard: {
    inactiveComponents: DashboardItem[]
  }
}

export type DashboardAction = {
  type: typeof UPDATE_DASHBOARD
  payload: DashboardItem
}
