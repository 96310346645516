import { makeStyles } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { FieldProps, FormDataConsumer, TextInput } from 'react-admin'
import { ChromePicker } from 'react-color'
import { useField } from 'react-final-form'
import { getContrastColor } from '../../core/common/visual.utils'
import { useOutsideClick } from '../hooks/useOutsideClick'

const useStyles = makeStyles({
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
})

interface ColorPickerProps extends FieldProps {
  source: string
}

export const RAColorPicker = (props: ColorPickerProps) => {
  const { record, source } = props
  const classes = useStyles()

  const [pickerOpen, setPickerOpen] = useState(false)
  const handleOpenPicker = () => {
    setPickerOpen(true)
  }
  const handleClosePicker = () => {
    setPickerOpen(false)
  }

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, pickerOpen && handleClosePicker)

  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source)

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          <TextInput
            {...props}
            error={!!(touched && error)}
            helperText={(touched && error) ?? ' '}
            onClick={handleOpenPicker}
            InputProps={{
              style: {
                color:
                  record?.[source] || formData?.[source]
                    ? getContrastColor(formData?.[source] ?? record?.[source])
                    : undefined,
                backgroundColor: formData?.[source] ?? record?.[source],
              },
            }}
            InputLabelProps={{
              style: {
                color:
                  record?.[source] || formData?.[source]
                    ? getContrastColor(formData?.[source] ?? record?.[source])
                    : undefined,
              },
            }}
          />
          <div className={classes.popover} ref={wrapperRef}>
            {pickerOpen ? (
              <ChromePicker
                color={formData?.[source] ?? record?.[source] ?? '#000000'}
                onChange={({ hex }) => onChange?.(hex)}
              />
            ) : undefined}
          </div>
        </>
      )}
    </FormDataConsumer>
  )
}
