import {
  FormDataConsumer,
  required,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import React, { ReactElement, useEffect, useReducer, useState } from 'react'
import { CardContent, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import {
  choicesReducer,
  CustomMapper,
  detectExactFields,
  FormMappings,
  SelectField,
  STATIC_VALUE_ID,
  TRANSITIONAL_STATIC_ENTRIES_KEY,
  TransitionalFormMappings,
  transitionalStaticFieldsResolver,
} from '../../../core/csv'

import { ImportBase } from './ImportBase'

const useStyles = makeStyles((theme) => ({
  input: {
    width: theme.spacing(42),
  },
  spacingLeft: {
    marginLeft: theme.spacing(2),
  },
}))

export interface ImportProps extends ResourceComponentProps {
  readonly selectFields: SelectField[]
  readonly validate?: (values: Record<string, string>) => {
    [n: string]: string
  }
  readonly children?: ReactElement | ReactElement[]
  readonly customMapper?: CustomMapper
  readonly onSuccess?: (response: { data: any }) => void
}

export const Import = (props: ImportProps) => {
  const {
    selectFields,
    validate,
    resource,
    customMapper,
    children: additionalFields = [],
    ...rest
  } = props
  const t = useTranslate()
  const classes = useStyles()

  const [choices, dispatchColumnNames] = useReducer(choicesReducer, [])
  const [initialFormValues, setInitialFormValues] = useState<
    Record<string, string>
  >({})

  const formValuesMapper = (
    formValues: TransitionalFormMappings,
  ): FormMappings => {
    const resolvedMappings = transitionalStaticFieldsResolver(formValues)
    return customMapper ? customMapper(resolvedMappings) : resolvedMappings
  }

  useEffect(() => {
    setInitialFormValues(
      detectExactFields(
        selectFields.map((v) => v[0]),
        choices.map((v) => v.id),
      ),
    )
  }, [selectFields, choices])

  return (
    <ImportBase
      title={`Import ${resource}`}
      columnNamesDispatcher={dispatchColumnNames}
      formValuesMapper={formValuesMapper}
      resource={resource}
      {...rest}
    >
      <SimpleForm
        validate={validate}
        component={CardContent}
        initialValues={initialFormValues}
      >
        {selectFields
          .map(([fieldName, customStaticValueField]) => (
            <>
              <FormDataConsumer>
                {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                {({ formData, ...restFormDataProps }) => (
                  <SelectInput
                    {...restFormDataProps}
                    className={classes.input}
                    key={fieldName}
                    choices={choices}
                    label={t('common.import.mapping-input-label', {
                      fieldName,
                    })}
                    source={fieldName}
                    // validate={(validate && validate(formData)[fieldName]) ? required(validate(formData)[fieldName]) : undefined}
                  />
                )}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...restFormDataProps }) =>
                  formData[fieldName] === STATIC_VALUE_ID &&
                  (customStaticValueField ? (
                    customStaticValueField(
                      fieldName,
                      classNames(classes.input, classes.spacingLeft),
                      { ...restFormDataProps },
                      formData,
                    )
                  ) : (
                    <TextInput
                      {...restFormDataProps}
                      key={`${STATIC_VALUE_ID}${fieldName}`}
                      className={classNames(classes.input, classes.spacingLeft)}
                      source={`${TRANSITIONAL_STATIC_ENTRIES_KEY}.${fieldName}`}
                      label={t('common.import.static-input-label', {
                        fieldName,
                      })}
                      validate={required(
                        validate && validate(formData)[fieldName],
                      )}
                      resettable
                    />
                  ))
                }
              </FormDataConsumer>
            </>
          ))
          .concat(
            Array.isArray(additionalFields)
              ? additionalFields
              : [additionalFields],
          )}
      </SimpleForm>
    </ImportBase>
  )
}
