import {
  Box,
  Divider,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { ReactElement, cloneElement, useContext } from 'react'
import {
  Datagrid,
  DateField,
  DateTimeInput,
  EditButton,
  FilterButton,
  FilterContext,
  FunctionField,
  Identifier,
  Labeled,
  List,
  ListProps,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ResourceContextProvider,
  SelectInput,
  Show,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutProps,
  TextField,
  TextInput,
  TitleProps,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { formatDate, formatTime } from '../../../core/common/date-time'
import { AccessDateDto } from '../../../core/dto/access-date.dto'
import { AccessUsingDto } from '../../../core/dto/access-using.dto'
import { AccessDto } from '../../../core/dto/access.dto'
import { EntranceDto } from '../../../core/dto/entrance.dto'
import { VariantDto } from '../../../core/dto/variant.dto'
import { AccessActivePeriodStartingOptions } from '../../../core/enum/AccessActivePeriodStartingOptions'
import { AccessDateTypes } from '../../../core/enum/AccessDateTypes'
import { ResourceName } from '../../../core/ResourceName'
import {
  EditableDatagrid,
  RowForm,
} from '../../../lib/@react-admin/ra-editable-datagrid/esm/src'
import ParameterizedCreateButton from '../../common/ParameterizedCreateButton'
import RelationTab from '../../common/RelationTab'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import relationTabFilter from '../../common/RelationTabFilter'
import { AccessUsingActivationButton } from '../access-usings/AccessUsingActivationButton'
import { AccessBonusDto } from '../../../core/dto/access-bonus.dto'
import { DayOfWeek } from '../../../core/enum/DayOfWeek'
import { BookingDto } from '../../../core/dto/booking.dto'
import { BookingCreateUseButton } from '../../bookings/booking-show/BookingCreateUseButton'
import { BookingReleaseAllUsingsButton } from '../../bookings/booking-show/BookingReleaseAllUsingsButton'

const useStyles = makeStyles({
  resettableSelectInput: {
    '& > div > div > button': {
      width: '32px',
      height: '32px',
    },
  },
})

const ShowTitle = (data: AccessTitleProps) => {
  const { record: variant } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.accesses.titles.show')}: {variant?.id}.{' '}
      {variant?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: access } = useShowContext<AccessDto>()
  const hasAuthority = useHasAuthority()
  return (
    <>
      <Divider />
      <Box p={1} display="block" textAlign="right">
        <EditButton
          basePath={`/${ResourceName.ACCESSES}`}
          record={access}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_ACCESSES)}
        />
      </Box>
    </>
  )
}

export const AccessDetailsTab = ({
  displayActions = true,
  displayReference,
  ...props
}: AccessDetailsTabProps) => {
  const { record: access } = useShowContext<AccessDto>()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <SimpleShowLayout {...props}>
      {displayReference ? (
        <ReferenceField
          link="show"
          source="id"
          reference={ResourceName.ACCESSES}
        >
          <TextField source="id" />
        </ReferenceField>
      ) : (
        <TextField source="id" />
      )}
      {displayReference ? (
        <ReferenceField
          link="show"
          source="id"
          label={translate('resources.accesses.fields.name')}
          reference={ResourceName.ACCESSES}
        >
          <TextField source="name" />
        </ReferenceField>
      ) : (
        <TextField source="name" />
      )}
      {hasAuthority(Authority.VIEW_ACCESS_BONUSES) && (
        <ReferenceField
          source="bonusId"
          reference={ResourceName.ACCESS_BONUS}
          link="show"
        >
          <FunctionField<AccessBonusDto>
            render={(record?: AccessBonusDto) =>
              `${record?.id}. ${record?.name} (${record?.description})`
            }
          />
        </ReferenceField>
      )}
      {hasAuthority(Authority.VIEW_ENTRANCES) && (
        <ReferenceField
          source="entranceId"
          reference={ResourceName.ENTRANCES}
          link="show"
        >
          <FunctionField<EntranceDto>
            render={(record?: EntranceDto) => (
              <div>
                {record?.id} ({record?.note})
              </div>
            )}
          />
        </ReferenceField>
      )}
      {hasAuthority(Authority.VIEW_USERS) && (
        <ReferenceField
          source="modifiedById"
          reference={ResourceName.USERS}
          link="show"
        >
          <TextField source="userName" />
        </ReferenceField>
      )}
      {hasAuthority(Authority.VIEW_USERS) && (
        <ReferenceField
          source="createdById"
          reference={ResourceName.USERS}
          link="show"
        >
          <TextField source="userName" />
        </ReferenceField>
      )}
      <FunctionField<AccessDto>
        source="allowReenter"
        render={(r?: AccessDto) => (
          <div>
            {r?.allowReenter ? translate('const.yes') : translate('const.no')}
          </div>
        )}
      />
      <FunctionField<AccessDto>
        source="multipleEntry"
        render={(r?: AccessDto) => (
          <div>
            {r?.multipleEntry ? translate('const.yes') : translate('const.no')}
          </div>
        )}
      />
      <TextField source="multiple" />
      <TextField source="usePriority" />
      <TextField source="delay" />
      {access?.releaseTime && (
        <FunctionField<AccessDto>
          source="releaseTime"
          render={(record?: AccessDto) => (
            <div>{formatTime(record?.releaseTime)}</div>
          )}
        />
      )}
      {access?.releaseDayOfMonth && <TextField source="releaseDayOfMonth" />}
      {access?.releaseDayOfWeek && (
        <FunctionField<AccessDto>
          source="releaseDayOfWeek"
          render={(r?: AccessDto) => (
            <div>
              {translate(`resources.enums.dayOfWeek.${r?.releaseDayOfWeek}`)}
            </div>
          )}
        />
      )}
      <DateField source="createdAt" showTime />
      <DateField source="modifiedAt" showTime />
      {access?.activePeriodStartingFrom !==
        AccessActivePeriodStartingOptions.NO_LIMIT && (
        <TextField source="activePeriod" />
      )}
      <FunctionField<AccessDto>
        source="activePeriodStartingFrom"
        render={(r?: AccessDto) => (
          <div>
            {translate(
              `resources.enums.activePeriodStartingFrom.${r?.activePeriodStartingFrom}`,
            )}
          </div>
        )}
      />
      {displayActions && <GeneralActions />}
    </SimpleShowLayout>
  )
}

const AccessDateExpand = ({ ...props }) => {
  const { record: accessDate } = useShowContext<AccessDateDto>()

  return (
    <Grid container justify="space-around">
      <Grid item>
        <Labeled {...props} source="timeFrom">
          <FunctionField<AccessDateDto>
            source="timeFrom"
            render={(record?: AccessDateDto) => (
              <div>{formatTime(record?.timeFrom)}</div>
            )}
          />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled {...props} source="timeTo">
          <FunctionField<AccessDateDto>
            source="timeTo"
            render={(record?: AccessDateDto) => (
              <div>{formatTime(record?.timeTo)}</div>
            )}
          />
        </Labeled>
      </Grid>
      <Grid item>
        <Labeled {...props} source="priority">
          <NumberField source="priority" />
        </Labeled>
      </Grid>
      {accessDate?.dayOfWeek && (
        <Grid item>
          <Labeled {...props} source="dayOfWeek">
            <TextField source="dayOfWeek" />
          </Labeled>
        </Grid>
      )}
    </Grid>
  )
}

const AccessDatesList = ({
  displayActions,
  predefinedAccessId: accessId,
  ...props
}: AccessDatesListProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const { basePath } = useListContext()
  const classes = useStyles()

  const ListActions = () => (
    <TopToolbar>
      {hasAuthority(Authority.CREATE_ACCESS_DATES) && (
        <ParameterizedCreateButton searchParams={{ accessId }} />
      )}
    </TopToolbar>
  )

  const EditForm = ({ ...editProps }) => (
    <RowForm {...editProps}>
      <DateTimeInput
        source="activeFrom"
        type="date"
        format={formatDate}
        parse={(value: string) => (value === '' ? null : value)}
      />
      <DateTimeInput
        source="activeTo"
        type="date"
        format={formatDate}
        parse={(value: string) => (value === '' ? null : value)}
      />
      <DateTimeInput
        source="timeFrom"
        type="time"
        format={formatTime}
        parse={(value: string) => (value === '' ? null : value)}
        inputProps={{ step: 1 }}
      />
      <DateTimeInput
        source="timeTo"
        type="time"
        format={formatTime}
        parse={(value: string) => (value === '' ? null : value)}
        inputProps={{ step: 1 }}
      />
      <SelectInput
        source="dayOfWeek"
        choices={[
          ...Object.entries(DayOfWeek).map(([value]) => ({
            id: value,
            name: `resources.enums.dayOfWeek.${value}`,
          })),
          {
            id: null,
            name: 'N/A',
          },
        ]}
        className={classes.resettableSelectInput}
      />
      <SelectInput
        source="type"
        choices={Object.entries(AccessDateTypes).map(([value]) => ({
          id: value,
          name: `resources.enums.accessDateTypes.${value}`,
        }))}
      />
      <NumberInput source="priority" />
    </RowForm>
  )

  return (
    <List
      {...props}
      basePath={basePath}
      exporter={false}
      perPage={20}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      actions={displayActions === undefined && <ListActions />}
      hasCreate={hasAuthority(Authority.CREATE_ACCESS_DATES)}
      empty={false}
    >
      <EditableDatagrid
        isRowExpandable={() => smallScreen}
        expand={<AccessDateExpand />}
        padding={xSmallScreen ? 'checkbox' : 'default'}
        editForm={<EditForm />}
        noDelete
        actions={
          displayActions === undefined &&
          hasAuthority(Authority.EDIT_ACCESS_DATES)
            ? undefined
            : false
        }
      >
        <DateField source="activeFrom" />
        <DateField source="activeTo" />
        {!smallScreen && (
          <FunctionField<AccessDateDto>
            source="timeFrom"
            render={(record?: AccessDateDto) => (
              <div>{formatTime(record?.timeFrom)}</div>
            )}
          />
        )}
        {!smallScreen && (
          <FunctionField<AccessDateDto>
            source="timeTo"
            render={(record?: AccessDateDto) => (
              <div>{formatTime(record?.timeTo)}</div>
            )}
          />
        )}
        <FunctionField<AccessDateDto>
          source="dayOfWeek"
          render={(r?: AccessDateDto) =>
            r?.dayOfWeek
              ? translate(`resources.enums.dayOfWeek.${r?.dayOfWeek}`)
              : undefined
          }
        />
        <FunctionField<AccessDateDto>
          source="type"
          render={(r?: AccessDateDto) =>
            translate(`resources.enums.accessDateTypes.${r?.type}`)
          }
        />
        {!smallScreen && <NumberField source="priority" />}
      </EditableDatagrid>
    </List>
  )
}

export const AccessDatesTab = ({
  displayActions,
  predefinedAccessId,
}: AccessDatesTabProps) => {
  const { basePath, record } = useShowContext<AccessDto>()
  return (
    <ResourceContextProvider value={ResourceName.ACCESS_DATES}>
      <AccessDatesList
        displayActions={displayActions}
        predefinedAccessId={predefinedAccessId}
        title=" "
        basePath={basePath}
        filter={{
          accessId: record?.id,
        }}
        perPage={20}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
      />
    </ResourceContextProvider>
  )
}

const AccessUsingsExpand = ({ ...props }) => {
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()

  return (
    <Grid container justify="space-around">
      <Grid item>
        {hasAuthority(Authority.VIEW_TICKETS) && (
          <Labeled {...props} source="ticketCode">
            <ReferenceField
              reference={ResourceName.TICKETS}
              source="ticketId"
              link={false}
            >
              <TextField source="ticketCode" />
            </ReferenceField>
          </Labeled>
        )}
      </Grid>
      <Grid item>
        <Labeled {...props} source="active">
          <FunctionField<AccessUsingDto>
            source="active"
            render={(record?: AccessUsingDto) =>
              record?.active ? translate('const.yes') : translate('const.no')
            }
          />
        </Labeled>
      </Grid>
    </Grid>
  )
}

const AccessUsingsGrid = ({ ...props }) => {
  const { booking, ...rest } = props
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <Datagrid
      {...rest}
      padding={xSmallScreen ? 'checkbox' : 'default'}
      isRowExpandable={() => xSmallScreen}
      expand={<AccessUsingsExpand />}
    >
      <DateField source="date" showTime />
      <FunctionField<AccessUsingDto>
        source="direction"
        render={(record?: AccessUsingDto) =>
          translate(`resources.enums.deviceDirection.${record?.direction}`)
        }
      />
      {!xSmallScreen && hasAuthority(Authority.VIEW_TICKETS) && (
        <ReferenceField
          label="resources.access-usings.fields.ticketCode"
          reference={ResourceName.TICKETS}
          source="ticketId"
          link={false}
        >
          <TextField source="ticketCode" />
        </ReferenceField>
      )}
      {!xSmallScreen && (
        <FunctionField<AccessUsingDto>
          source="active"
          render={(record?: AccessUsingDto) =>
            record?.active ? translate('const.yes') : translate('const.no')
          }
        />
      )}
      <FunctionField<AccessUsingDto>
        render={(record?: AccessUsingDto) => (
          <AccessUsingActivationButton record={record} booking={booking} />
        )}
      />
    </Datagrid>
  )
}

const AccessUsingsActions = ({ ...props }) => {
  const { booking, filters: filtersProp, ...restProps } = props
  const resource = ResourceName.TICKETS
  const { displayedFilters, filterValues, showFilter } = useListContext(props)
  const filters = useContext(FilterContext) || filtersProp

  return (
    <TopToolbar {...sanitizeListRestProps(restProps)}>
      {filtersProp
        ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })
        : filters && <FilterButton />}
      <BookingCreateUseButton booking={booking} />
      <BookingReleaseAllUsingsButton booking={booking} />
    </TopToolbar>
  )
}

const AccessUsingsList = ({ ...props }) => {
  const { booking, ...rest } = props

  return (
    <List
      {...rest}
      exporter={false}
      perPage={20}
      sort={{ field: 'date', order: 'DESC' }}
      bulkActionButtons={false}
      empty={false}
      actions={<AccessUsingsActions booking={booking} />}
    >
      <AccessUsingsGrid {...rest} booking={booking} />
    </List>
  )
}

export const AccessUsingsTab = ({
  accessUsingsTicketsIdsFilter = null,
  booking,
}: {
  accessUsingsTicketsIdsFilter?: number[] | null | undefined
  booking: BookingDto
}) => {
  const { basePath, record: selectedAccess } = useShowContext<AccessDto>()

  return (
    <ResourceContextProvider value={ResourceName.ACCESS_USINGS}>
      <AccessUsingsList
        title=" "
        basePath={basePath}
        filter={{
          accessId: selectedAccess?.id,
          ticketsIds: accessUsingsTicketsIdsFilter,
        }}
        actions={false}
        perPage={20}
        pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
        booking={booking}
      />
    </ResourceContextProvider>
  )
}

const AccessTabs = ({ ...props }: Omit<TabbedShowLayoutProps, 'children'>) => {
  const { record: access } = props
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  const relationFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.ACCESSES,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label="resources.accesses.tabs.details">
        <AccessDetailsTab displayActions />
      </Tab>
      {hasAuthority(Authority.VIEW_VARIANTS) && (
        <Tab path="variants" label="resources.accesses.tabs.variants">
          <>
            <RelationTab<AccessDto>
              resource={ResourceName.VARIANTS}
              source="variantsIds"
              mode={hasAuthority(Authority.EDIT_ACCESSES) ? 'edit' : 'show'}
              attachMethod="attachVariants"
              detachMethod="detachVariants"
              attachRequestPayload={(r, ids) => ({
                accessId: r.id,
                variantsIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                accessId: r.id,
                variantsIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterAccessId === true
              }
              filters={relationFilters() as ReactElement[]}
              filterDefaultValues={{
                accessId: access?.id,
                filterAccessId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField label="ID" source="id" />
              <TextField source="name" />
              <FunctionField<VariantDto>
                source="active"
                key="active"
                render={(r?: VariantDto) =>
                  r?.active ? translate('const.yes') : translate('const.no')
                }
              />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ACCESS_DATES) && (
        <Tab path="dates" label="resources.accesses.tabs.dates">
          <AccessDatesTab predefinedAccessId={access?.id} />
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const AccessShow = ({ ...props }: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <AccessTabs />
  </Show>
)

export interface AccessDetailsTabProps
  extends Omit<TabbedShowLayoutProps, 'children'> {
  displayActions?: boolean
  displayReference?: boolean
}

export interface AccessDatesTabProps
  extends Omit<TabbedShowLayoutProps, 'children'> {
  displayActions?: false
  predefinedAccessId?: Identifier
}

export interface AccessDatesListProps extends ListProps {
  displayActions?: false
  predefinedAccessId?: Identifier
}

interface AccessTitleProps extends TitleProps {
  record?: AccessDto
}
