import React, { useCallback } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  CreateProps,
  required,
  SelectInput,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin'
import {
  STATIC_VALUE_ID,
  SelectField,
  TRANSITIONAL_STATIC_ENTRIES_KEY,
} from '../../core/csv'
import { Import } from '../common/import/Import'
import { ResourceName } from '../../core/ResourceName'
import { BlockType } from '../../core/enum/BlockType'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { TicketType } from '../../core/enum/TicketType'
import { BookingType } from '../../core/enum/BookingType'
import { BookingDisabledOption } from '../../core/enum/BookingDisabledOption'

export const TicketImport = (props: CreateProps) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const t = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()

  const validateImport = (values: Record<string, string>) => {
    const errors: any = {}
    if (!values.customerFirstName) {
      errors.customerFirstName = 'ra.validation.required'
    }
    if (!values.customerLastname) {
      errors.customerLastname = 'ra.validation.required'
    }
    if (
      (values.$$transitionalStaticEntries$$ as any)?.ticketType !==
        TicketType.GENERATE &&
      !values.ticketCode
    ) {
      errors.ticketCode = 'ra.validation.required'
    }
    if (!values.ticketType) {
      errors.ticketType = 'ra.validation.required'
    }
    if (
      values.ticketType === STATIC_VALUE_ID &&
      !(values.$$transitionalStaticEntries$$ as any)?.ticketType
    ) {
      errors.$$transitionalStaticEntries$$ = {
        ticketType: 'ra.validation.required',
      }
    }
    if (
      values.ticketCode === STATIC_VALUE_ID &&
      !(values.$$transitionalStaticEntries$$ as any)?.ticketCode
    ) {
      errors.$$transitionalStaticEntries$$ = {
        ticketCode: 'ra.validation.required',
      }
    }
    if (
      values.bookingType === STATIC_VALUE_ID &&
      !(values.$$transitionalStaticEntries$$ as any)?.bookingType
    ) {
      errors.$$transitionalStaticEntries$$ = {
        bookingType: 'ra.validation.required',
      }
    }
    if (
      values.disabled === STATIC_VALUE_ID &&
      !(values.$$transitionalStaticEntries$$ as any)?.disabled
    ) {
      errors.$$transitionalStaticEntries$$ = {
        disabled: 'ra.validation.required',
      }
    }
    if (
      values.blockId === STATIC_VALUE_ID &&
      !(values.$$transitionalStaticEntries$$ as any)?.blockId
    ) {
      errors.$$transitionalStaticEntries$$ = {
        blockId: 'ra.validation.required',
      }
    }
    if (
      values.variantId === STATIC_VALUE_ID &&
      !(values.$$transitionalStaticEntries$$ as any)?.variantId
    ) {
      errors.$$transitionalStaticEntries$$ = {
        variantId: 'ra.validation.required',
      }
    }
    if (
      values.eventId === STATIC_VALUE_ID &&
      !(values.$$transitionalStaticEntries$$ as any)?.eventId
    ) {
      errors.$$transitionalStaticEntries$$ = {
        eventId: 'ra.validation.required',
      }
    }
    return errors
  }

  const selectFields: SelectField[] = [
    ['customerFirstName'],
    ['customerLastname'],
    ['customerPesel'],
    ['customerDocumentNumber'],
    ['ticketCode'],
    [
      'ticketType',
      (fieldName, className, formDataProps, formData) => (
        <SelectInput
          {...formDataProps}
          label={t('common.import.static-input-label', {
            fieldName,
          })}
          className={className}
          source={`${TRANSITIONAL_STATIC_ENTRIES_KEY}.${fieldName}`}
          choices={Object.keys(TicketType).map((key: string) => ({
            id: key,
            name: t(`resources.enums.ticketType.${key}`),
          }))}
          validate={required(
            validateImport && validateImport(formData)[fieldName],
          )}
        />
      ),
    ],
    [
      'bookingType',
      (fieldName, className, formDataProps, formData) => (
        <SelectInput
          {...formDataProps}
          label={t('common.import.static-input-label', {
            fieldName,
          })}
          className={className}
          source={`${TRANSITIONAL_STATIC_ENTRIES_KEY}.${fieldName}`}
          choices={[
            {
              id: null,
              name: 'const.emptySelection',
            },
            ...Object.keys(BookingType).map((key: string) => ({
              id: key,
              name: t(`resources.enums.bookingType.${key}`),
            })),
          ]}
          validate={required(
            validateImport && validateImport(formData)[fieldName],
          )}
        />
      ),
    ],
    [
      'disabled',
      (fieldName, className, formDataProps, formData) => (
        <SelectInput
          {...formDataProps}
          label={t('common.import.static-input-label', {
            fieldName,
          })}
          className={className}
          source={`${TRANSITIONAL_STATIC_ENTRIES_KEY}.${fieldName}`}
          choices={[
            {
              id: null,
              name: 'const.emptySelection',
            },
            ...Object.keys(BookingDisabledOption).map((key: string) => ({
              id: key,
              name: key,
            })),
          ]}
          validate={required(
            validateImport && validateImport(formData)[fieldName],
          )}
        />
      ),
    ],
    [
      'blockId',
      (fieldName, className, formDataProps, formData) => (
        <FilteredReferenceInput
          {...formDataProps}
          label={t('common.import.static-input-label', {
            fieldName,
          })}
          className={className}
          source={`${TRANSITIONAL_STATIC_ENTRIES_KEY}.${fieldName}`}
          defaultValue={null}
          reference={ResourceName.BLOCKS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          filter={{
            type: BlockType.DYNAMIC,
          }}
          validate={required(
            validateImport && validateImport(formData)[fieldName],
          )}
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      ),
    ],
    [
      'variantId',
      (fieldName, className, formDataProps, formData) => (
        <FilteredReferenceInput
          {...formDataProps}
          label={t('common.import.static-input-label', {
            fieldName,
          })}
          className={className}
          source={`${TRANSITIONAL_STATIC_ENTRIES_KEY}.${fieldName}`}
          defaultValue={null}
          reference={ResourceName.VARIANTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          validate={required(
            validateImport && validateImport(formData)[fieldName],
          )}
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      ),
    ],
    [
      'eventId',
      (fieldName, className, formDataProps, formData) => (
        <FilteredReferenceInput
          {...formDataProps}
          label={t('common.import.static-input-label', {
            fieldName,
          })}
          className={className}
          source={`${TRANSITIONAL_STATIC_ENTRIES_KEY}.${fieldName}`}
          defaultValue={null}
          reference={ResourceName.EVENTS}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="search"
          validate={required(
            validateImport && validateImport(formData)[fieldName],
          )}
          selectWithPaginationInputProps={{
            optionText: 'name',
            showFilter: true,
          }}
        />
      ),
    ],
  ]

  const onSuccess = useCallback(
    ({ ...response }) => {
      const {
        data: { jobId },
      } = response
      notify('common.import.import-success-message', 'success')
      redirect('show', `/jobs`, jobId)
    },
    [notify, redirect],
  )

  return (
    <Import
      selectFields={selectFields}
      validate={validateImport}
      onSuccess={onSuccess}
      {...props}
    />
  )
}
