import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import {
  GET_ONE,
  Identifier,
  sanitizeFetchType,
  useMutation,
  useNotify,
  useQuery,
  useRecordContext,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ReducerJobStatus } from '../../../../core/devices/offline-servers/restore-replication-button'
import {
  restoreReplicationButtonInitialState,
  restoreReplicationButtonReducer,
} from '../../../../core/devices/offline-servers/restore-replication-button.reducer'
import { OfflineServerDto } from '../../../../core/dto/device/offline-servers/offline-server.dto'
import { ResourceName } from '../../../../core/ResourceName'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      padding: 0,
      background: 'rgba(0,0,0,0.2)',
    },
    iconButton: {
      borderRadius: 0,
      background: theme.palette.primary.main,
    },
    title: {
      padding: '5px 24px',
    },
    button: {
      margin: '2px',
    },
  }),
)

const StatusTransitionDialog = ({
  handleSkipDialog,
  open,
}: {
  handleSkipDialog: () => void
  open: boolean
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Dialog open={open}>
      <DialogActions className={classes.actions}>
        <IconButton
          size="small"
          className={classes.iconButton}
          onClick={handleSkipDialog}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogTitle className={classes.title}>
        {translate(
          'resources.offline-servers.dialogs.restore-replication.title',
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate(
            'resources.offline-servers.dialogs.restore-replication.content',
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export const OfflineServerRestoreReplicationButton = ({ ...props }) => {
  const notify = useNotify()
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const classes = useStyles()
  const { loading } = useShowContext()
  const [{ type: buttonState, text, disabled: isButtonDisabled }, dispatch] =
    useReducer(
      restoreReplicationButtonReducer,
      restoreReplicationButtonInitialState,
    )
  const server = useRecordContext<OfflineServerDto>()
  const [transitionDialogOpen, setTransitionDialogOpen] = useState(false)
  const [mutate, { loading: mutationLoading }] = useMutation()

  const [jobId, setJobId] = useState<Identifier | undefined>(undefined)
  const {
    data: jobResult,
    refetch: refreshJobResult,
    loading: jobResultLoading,
  } = useQuery(
    {
      type: sanitizeFetchType(GET_ONE),
      resource: ResourceName.JOBS,
      payload: {
        id: jobId,
      },
    },
    {
      enabled: !!jobId,
    },
  )

  const restoreReplication = ({ id }: OfflineServerDto) => ({
    type: sanitizeFetchType('restoreReplication'),
    resource: ResourceName.OFFLINE_SERVERS,
    payload: {
      id,
    },
  })

  const startJobFetching = (jobIdentifier: Identifier) => {
    setJobId(jobIdentifier)
  }

  const stopJobFetching = () => {
    setJobId(undefined)
    setTransitionDialogOpen(false)
  }

  const getCurrentJobState = useCallback(() => {
    if (jobId && !jobResultLoading && jobResult?.jobStatus) {
      return jobResult.jobStatus
    }
    return 'InitialState'
  }, [jobId, jobResultLoading, jobResult])

  const handleSkipTransitionDialog = () => {
    stopJobFetching()
  }

  // Refresh reducer state
  useEffect(() => {
    if (!jobResultLoading) dispatch({ type: getCurrentJobState() })
  }, [jobId, getCurrentJobState, jobResultLoading])

  // Handle automatic job refetch and dialog open state
  useEffect(() => {
    const interval = setInterval(() => {
      if (refreshJobResult && jobId) refreshJobResult()
    }, 5000)
    if (buttonState === ReducerJobStatus.INITIAL_STATE) {
      clearInterval(interval)
      setTransitionDialogOpen(false)
    } else if (
      buttonState === ReducerJobStatus.SUCCEEDED ||
      buttonState === ReducerJobStatus.FAILED ||
      buttonState === ReducerJobStatus.DELETED
    ) {
      clearInterval(interval)
      if (jobId) {
        const isSuccess =
          buttonState === ReducerJobStatus.SUCCEEDED &&
          jobResult?.jobResult?.statusCode === 200
        notify(
          isSuccess
            ? 'resources.offline-servers.dialogs.restore-replication.success'
            : `${translate(
                'resources.offline-servers.dialogs.restore-replication.error',
              )} (${jobResult?.jobResult?.value?.message} - ${
                jobResult?.jobResult?.value?.identifier
              })`,
          isSuccess ? 'info' : 'error',
          undefined,
          false,
          1000 * 60,
        )
      }
      stopJobFetching()
    } else {
      setTransitionDialogOpen(true)
    }
    return () => clearInterval(interval)
  }, [
    refreshJobResult,
    buttonState,
    jobId,
    notify,
    translate,
    jobResult?.jobResult?.statusCode,
    jobResult?.jobResult?.value?.message,
    jobResult?.jobResult?.value?.identifier,
  ])

  const onClick = async () => {
    const query = restoreReplication?.(server)
    const { data: mutationResult } = await mutate(query, {
      returnPromise: true,
      onFailure: (msg) => notify(msg, 'error'),
    })
    startJobFetching(mutationResult?.jobId)
  }

  return (
    <>
      <StatusTransitionDialog
        handleSkipDialog={handleSkipTransitionDialog}
        open={transitionDialogOpen}
      />
      <Button
        {...props}
        className={classes.button}
        disabled={
          !hasAuthority(Authority.OFFLINE_SERVERS_RESTORE_REPLICATION_BUTTON) ||
          loading ||
          mutationLoading ||
          isButtonDisabled
        }
        variant="contained"
        onClick={onClick as () => void}
        label={text}
        useSmallVersionBreakpoint={false}
      />
    </>
  )
}
