import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  Identifier,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyResult,
} from 'react-admin'
import { OFFLINE_SERVERS_URL } from '../../api-urls'
import { DataProviderExtensionResult } from '../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { SlaveBrokerProperties } from '../../dto/device/offline-servers/slave-broker-properties.dto'
import { OfflineServerDto } from '../../dto/device/offline-servers/offline-server.dto'
import { RemoveOfflineServerCredentialsDto } from '../../dto/device/offline-servers/remove-offline-server-credentials.dto'
import { SetOfflineServerCredentialsDto } from '../../dto/device/offline-servers/set-offline-server-credentials.dto'
import { getDeviceCategoryChildren } from '../devices.utils'
import filterMapper from './offline-server-filter.mapper'
import mapSortOfflineServerParam from './offline-server-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<OfflineServerDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortOfflineServerParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<OfflineServerDto[]>(
      `${OFFLINE_SERVERS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<OfflineServerDto>> => {
    const data = await get<OfflineServerDto>(OFFLINE_SERVERS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<OfflineServerDto>> => {
    const data = await get<OfflineServerDto[]>(
      OFFLINE_SERVERS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<OfflineServerDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      OfflineServerDto
    >(OFFLINE_SERVERS_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
      isWatched:
        data.isWatched !== previousData.isWatched ? data.isWatched : undefined,
      address: data.address !== previousData.address ? data.address : undefined,
      categoryId:
        data.categoryId !== previousData.categoryId
          ? data.categoryId
          : undefined,
      jsonStatus:
        data.jsonStatus !== previousData.jsonStatus
          ? data.jsonStatus
          : undefined,
      cameraId:
        data.cameraId !== previousData.cameraId ? data.cameraId : undefined,
    })
    return Promise.resolve({ data: update })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateOfflineServerRequest>,
  ): Promise<CreateResult<OfflineServerDto>> => {
    const camera = await post<CreateOfflineServerRequest[], OfflineServerDto[]>(
      OFFLINE_SERVERS_URL,
      [data],
    )
    return {
      data: camera[0],
    }
  },
  setCredentials: async (
    resource: string,
    params: SetOfflineServerCredentialsDto,
  ): Promise<DataProviderExtensionResult<SetOfflineServerCredentialsDto>> => {
    const setCredentials = await put<
      SetOfflineServerCredentialsDto,
      SetOfflineServerCredentialsDto
    >(`${OFFLINE_SERVERS_URL}/SetCredentials`, params)
    return {
      data: setCredentials,
    }
  },
  removeCredentials: async (
    resource: string,
    params: RemoveOfflineServerCredentialsDto,
  ): Promise<
    DataProviderExtensionResult<RemoveOfflineServerCredentialsDto>
  > => {
    const removeCredentials = await put<
      RemoveOfflineServerCredentialsDto,
      RemoveOfflineServerCredentialsDto
    >(`${OFFLINE_SERVERS_URL}/RemoveCredentials`, params)
    return {
      data: removeCredentials,
    }
  },
  getProperties: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<SlaveBrokerProperties>> => {
    const getProperties = await get<SlaveBrokerProperties>(
      OFFLINE_SERVERS_URL,
      `/GetProperties/${params?.id}`,
    )
    return Promise.resolve({
      data: getProperties,
    })
  },
  forceReportState: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const forceReportState = await put<undefined, undefined>(
      `${OFFLINE_SERVERS_URL}/ForceReportState/${params?.id}`,
      undefined,
    )
    return {
      data: forceReportState,
    }
  },
  restoreReplication: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<ChangeStateResponse>> => {
    const restoreReplication = await put<undefined, ChangeStateResponse>(
      `${OFFLINE_SERVERS_URL}/RestoreReplicationAsyncJob/${params?.id}`,
      undefined,
    )
    return {
      data: restoreReplication,
    }
  },
} as CameraServerDataProvider

interface CameraServerDataProvider extends DataProvider {
  setCredentials: (
    resource: string,
    params: SetOfflineServerCredentialsDto,
  ) => Promise<DataProviderExtensionResult<SetOfflineServerCredentialsDto>>

  removeCredentials: (
    resource: string,
    params: RemoveOfflineServerCredentialsDto,
  ) => Promise<DataProviderExtensionResult<RemoveOfflineServerCredentialsDto>>

  getProperties: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<SlaveBrokerProperties>>

  forceReportState: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  restoreReplication: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<ChangeStateResponse>>
}

interface ChangeStateResponse {
  jobId: Identifier
}

export interface CreateOfflineServerRequest {
  readonly name: string
  readonly isWatched?: boolean
  readonly address: string
  readonly categoryId?: number
  readonly jsonStatus?: string
  readonly cameraId?: number
}

export default provider
