import { startCase, toLower } from 'lodash'

const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  key: (lambdaParameter, value) =>
    `${lambdaParameter}.Key.Contains("${value}")`,
  type: (lambdaParameter, value) =>
    `${lambdaParameter}.Type.Equals(TicketAPI.Models.Enums.DynamicProperties.DynamicPropertyDataTypes.${value})`,
  definitionOwnerType: (lambdaParameter, value) =>
    `${lambdaParameter}.Discriminator.Equals("${startCase(
      toLower(value),
    )}DynamicPropertyDefinition")`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
