import { Box, Divider, Grid } from '@material-ui/core'
import React, { ReactElement, useMemo } from 'react'
import {
  DateField,
  EditButton,
  FunctionField,
  Record,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TitleProps,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../core/auth/Authority'
import { EventDto } from '../../core/dto/event/event.dto'
import { ResourceName } from '../../core/ResourceName'
import RelationTab from '../common/RelationTab'
import relationTabFilter from '../common/RelationTabFilter'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { DeactivateAccessUsingsButton } from './event-show/DeactivateAccessUsingsButton'
import { ToggleActivationButton } from './event-show/ToggleActivationButton'
import DropdownButton from '../common/DropdownButton'
import { EventStatus } from '../../core/enum/EventStatus'
import { TechnicalAssistanceProtocolButton } from './event-show/TechnicalAssistanceProtocolButton'

interface EventTitleProps extends TitleProps {
  record?: EventDto
}

const ShowTitle = (data: EventTitleProps) => {
  const { record: event } = data
  const translate = useTranslate()
  const date = useMemo(
    () =>
      !((event?.dateStart as any) instanceof Date)
        ? event?.dateStart.replace('T', ' ')
        : '',
    [event?.dateStart],
  )
  return (
    <span>
      {translate('resources.events.titles.show')}: {event?.name} {date}{' '}
      {event?.id}
    </span>
  )
}

const GeneralActions = () => {
  const { record: event } = useShowContext<EventDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="flex" justifyContent="flex-end">
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item style={{ margin: '2px' }}>
            <TechnicalAssistanceProtocolButton />
          </Grid>
          <Grid item style={{ margin: '2px' }}>
            <DropdownButton mainAction={<DeactivateAccessUsingsButton />}>
              <DeactivateAccessUsingsButton asRecurringJob />
            </DropdownButton>
          </Grid>
          <Grid item>
            <EditButton
              variant="contained"
              basePath="/events"
              record={event}
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_EVENTS)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const StatusActions = () => (
  <>
    <Divider />
    <Box p={1} display="flex" justifyContent="flex-end">
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item style={{ margin: '2px' }}>
          <DropdownButton mainAction={<ToggleActivationButton />}>
            <ToggleActivationButton
              asRecurringJob
              recurringStatus={EventStatus.INACTIVE}
            />
            <ToggleActivationButton
              asRecurringJob
              recurringStatus={EventStatus.ACTIVE}
            />
          </DropdownButton>
        </Grid>
      </Grid>
    </Box>
  </>
)

const EventTabs = ({ ...props }) => {
  const translate = useTranslate()
  const { record: event } = props
  const hasAuthority = useHasAuthority()

  const relationFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.EVENTS,
    })

  const devicesFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [
        <TextInput source="name" alwaysOn />,
        <TextInput source="categoryId" alwaysOn />,
      ],
      resource: ResourceName.EVENTS,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label={translate('resources.events.tabs.general')}>
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <DateField source="dateStart" showTime />
        <DateField source="dateEnd" showTime />
        {event?.externalId && <TextField source="externalId" />}
        {event?.infix && <TextField source="infix" />}
        <GeneralActions />
      </Tab>
      <Tab label={translate('resources.events.tabs.status')}>
        <FunctionField
          source="status"
          render={(r: Record | undefined) => (
            <TextField
              emptyText={translate(
                `resources.enums.eventStatus.${r?.status.toLowerCase()}`,
              )}
            />
          )}
        />
        <StatusActions />
      </Tab>
      {hasAuthority(Authority.VIEW_COMPANIES) &&
        hasAuthority(Authority.EVENTS_MANAGE_COMPANIES) && (
          <Tab
            path="companies"
            label={translate('resources.events.tabs.companies')}
          >
            <>
              <RelationTab<EventDto>
                resource={ResourceName.COMPANIES}
                source="companiesIds"
                mode={
                  hasAuthority(Authority.EVENTS_MANAGE_COMPANIES)
                    ? 'edit'
                    : 'show'
                }
                attachMethod="attachCompanies"
                detachMethod="detachCompanies"
                attachRequestPayload={(r, ids) => ({
                  eventId: r.id,
                  companiesIds: ids,
                })}
                detachRequestPayload={(r, ids) => ({
                  eventId: r.id,
                  companiesIds: ids,
                })}
                refetchListAfterChange={(filters) =>
                  filters?.filterEventId === true
                }
                relationsRequestMethod="getCompanies"
                relationsRequestPayload={(r) => ({ eventId: r.id })}
                filters={relationFilters() as ReactElement[]}
                filterDefaultValues={{
                  eventId: event?.id,
                  filterEventId: false,
                }}
                sort={{
                  field: 'id',
                  order: 'DESC',
                }}
              >
                <TextField source="id" label="ID" />
                <TextField source="name" key="name" />
              </RelationTab>
            </>
          </Tab>
        )}
      {hasAuthority(Authority.VIEW_OBJECTS) && (
        <Tab path="objects" label={translate('resources.events.tabs.objects')}>
          <>
            <RelationTab<EventDto>
              resource={ResourceName.OBJECTS}
              source="objectsIds"
              mode={hasAuthority(Authority.EDIT_EVENTS) ? 'edit' : 'show'}
              attachMethod="attachObjects"
              detachMethod="detachObjects"
              attachRequestPayload={(r, ids) => ({
                eventId: r.id,
                objectsIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                eventId: r.id,
                objectsIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterEventId === true
              }
              filters={relationFilters() as ReactElement[]}
              filterDefaultValues={{
                eventId: event?.id,
                filterEventId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField source="id" label="ID" />
              <TextField source="name" key="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ENGINES) && (
        <Tab path="engines" label={translate('resources.events.tabs.engines')}>
          <>
            <RelationTab<EventDto>
              resource={ResourceName.ENGINES}
              source="verifierDevicesIds"
              mode={hasAuthority(Authority.EDIT_EVENTS) ? 'edit' : 'show'}
              attachMethod="attachDevices"
              detachMethod="detachDevices"
              attachRequestPayload={(r, ids) => ({
                eventId: r.id,
                verifierDevicesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                eventId: r.id,
                verifierDevicesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterEventId === true
              }
              relationsRequestMethod="getDevices"
              relationsRequestPayload={(r) => ({ eventId: r.id })}
              filters={devicesFilters() as ReactElement[]}
              filterDefaultValues={{
                eventId: event?.id,
                filterEventId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField source="id" label="ID" />
              <TextField source="name" key="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_PALMS) && (
        <Tab path="palms" label={translate('resources.events.tabs.palms')}>
          <>
            <RelationTab<EventDto>
              resource={ResourceName.PALMS}
              source="verifierDevicesIds"
              mode={hasAuthority(Authority.EDIT_EVENTS) ? 'edit' : 'show'}
              attachMethod="attachDevices"
              detachMethod="detachDevices"
              attachRequestPayload={(r, ids) => ({
                eventId: r.id,
                verifierDevicesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                eventId: r.id,
                verifierDevicesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterEventId === true
              }
              relationsRequestMethod="getDevices"
              relationsRequestPayload={(r) => ({ eventId: r.id })}
              filters={devicesFilters() as ReactElement[]}
              filterDefaultValues={{
                eventId: event?.id,
                filterEventId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField source="id" label="ID" />
              <TextField source="name" key="name" />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_DEPOSITORS) && (
        <Tab
          path="depositors"
          label={translate('resources.events.tabs.depositors')}
        >
          <>
            <RelationTab<EventDto>
              resource={ResourceName.DEPOSITORS}
              source="verifierDevicesIds"
              mode={hasAuthority(Authority.EDIT_EVENTS) ? 'edit' : 'show'}
              attachMethod="attachDevices"
              detachMethod="detachDevices"
              attachRequestPayload={(r, ids) => ({
                eventId: r.id,
                verifierDevicesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                eventId: r.id,
                verifierDevicesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterEventId === true
              }
              relationsRequestMethod="getDevices"
              relationsRequestPayload={(r) => ({ eventId: r.id })}
              filters={devicesFilters() as ReactElement[]}
              filterDefaultValues={{
                eventId: event?.id,
                filterEventId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField source="id" label="ID" />
              <TextField source="name" key="name" />
            </RelationTab>
          </>
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const EventShow = (props: ShowProps) => (
  <Show {...props} actions={false} title={<ShowTitle />}>
    <EventTabs />
  </Show>
)
