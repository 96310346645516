const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  ids: (lambdaParameter, value) =>
    `new int[] {${value}}.Contains(${lambdaParameter}.Id)`,
  ticketCode: (lambdaParameter, value) =>
    `${lambdaParameter}.TicketCode.Contains("${value}")`,
  bookingId: (lambdaParameter, value) =>
    `${lambdaParameter}.BookingId.Equals(${value})`,
  eventId: (lambdaParameter, value) =>
    `${lambdaParameter}.Booking.EventId.Equals(${value})`,
  bookingType: (lambdaParameter, value) =>
    `${lambdaParameter}.Booking.Type.Equals(TicketAPI.Models.Enums.TicketManagement.BookingTypes.${value})`,
  objectId: (lambdaParameter, value) =>
    `${lambdaParameter}.Booking.Event.Objects.Any(o=>o.Id.Equals(${value}))`,
  variantId: (lambdaParameter, value) =>
    `${lambdaParameter}.Booking.VariantId.Equals(${value})`,
  ticketType: (lambdaParameter, value) =>
    `${lambdaParameter}.TicketType.Equals(TicketAPI.Models.Enums.TicketManagement.TicketTypes.${value})`,
  isBookingBanned: (lambdaParameter) =>
    `${lambdaParameter}.Booking.Disabled.Equals(TicketAPI.Models.Enums.TicketManagement.BookingDisabledOptions.BAN)`,
  customerName: (lambdaParameter, value) =>
    `${value
      .toString()
      .split(' ')
      .map(
        (q: string) =>
          `(${lambdaParameter}.Booking.Customer.Firstname+${lambdaParameter}.Booking.Customer.Lastname).Contains("${q}")`,
      )
      .join('&&')}`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
