import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import {
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { VariantDto } from '../../../core/dto/variant.dto'
import { ResourceName } from '../../../core/ResourceName'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import Button from '../../common/customized-mui-components/Button'
import DraggableComponent from '../../common/DraggableComponent'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

const useStyles = makeStyles((theme: Theme) => ({
  dialogProperty: {
    marginBottom: theme.spacing(1),
  },
  bulkStateActions: {
    height: '16px',
    background: 'rgba(0,0,0,0.2)',
    minWidth: '300px',
    userSelect: 'none',
  },
  bulkProgressTitle: {
    paddingLeft: '10px',
    width: '100%',
  },
  bulkProgressCloseButton: {
    margin: 0,
  },
  actionsButton: {
    margin: '2%',
    width: '96%',
  },
  formToolbar: {
    backgroundColor: 'inherit',
    padding: 0,
    margin: 0,
  },
  dialogContent: {
    padding: '8px',
  },
}))

const validateAttachVariants = (values: VariantDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.id) {
    errors.id = 'ra.validation.required'
  }
  return errors
}

export const AccessVariantsRecurringDialog = ({
  open,
  close,
  setSelectedVariantId,
  handleAttachment,
  method,
}: {
  open: boolean
  close: () => void
  setSelectedVariantId: (value: number | undefined) => void
  handleAttachment: (recurringJobName?: string, cronExpression?: string) => void
  method: 'attach' | 'detach'
}) => {
  const classes = useStyles()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    setConfirmOpen(false)
    close()
  }

  const Actions = (props: ToolbarProps) => (
    <Toolbar {...props} className={classes.formToolbar}>
      <SaveButton
        variant="contained"
        size="small"
        className={classes.actionsButton}
        onSave={(values) => {
          setSelectedVariantId((values as VariantDto)?.id)
          setConfirmOpen(true)
        }}
        onSubmit={() => setConfirmOpen(true)}
      />
      <Button
        variant="contained"
        onClick={handleClose}
        label={translate('const.cancel')}
        useSmallVersionBreakpoint={false}
        className={classes.actionsButton}
      />
    </Toolbar>
  )

  useEffect(() => {
    if (!open) {
      setSelectedVariantId(undefined)
      setConfirmOpen(false)
    }
  }, [open, setSelectedVariantId, setConfirmOpen])

  const handleConfirm = (
    recurringJobName?: string,
    cronExpression?: string,
  ) => {
    handleAttachment(recurringJobName, cronExpression)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      onClick={(e) => e.stopPropagation()}
    >
      <ConfirmDialog
        open={confirmOpen}
        cancel={() => {
          setSelectedVariantId(undefined)
          setConfirmOpen(false)
        }}
        confirm={handleConfirm}
        source={`${method}Variants`}
        action="set"
        resource={ResourceName.ACCESSES}
        asRecurringJob
      />
      <DialogActions
        className={classes.bulkStateActions}
        style={{ cursor: 'move' }}
      >
        <Typography
          className={classes.bulkProgressTitle}
          id="draggable-dialog-title"
        >
          {translate(`resources.accesses.dialogs.${method}Variants.title`)}
        </Typography>
        <IconButton
          size="small"
          className={classes.bulkProgressCloseButton}
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        <SimpleForm toolbar={<Actions />} validate={validateAttachVariants}>
          <FilteredReferenceInput
            label={`resources.accesses.dialogs.${method}Variants.fields.variantId`}
            source="id"
            reference={ResourceName.VARIANTS}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={smallScreen ? 5 : 15}
            filterSource="search"
            required
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}
