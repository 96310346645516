import {
  CreateParams,
  CreateResult,
  DataProvider,
  UpdateManyParams,
  UpdateManyResult,
  GetListResult,
  GetManyParams,
  GetManyResult,
  Identifier,
  UpdateParams,
  UpdateResult,
  Record,
} from 'react-admin'
import { AccessUsingsCreateParams } from './access-usings-create.params'
import { AccessUsingDto } from '../dto/access-using.dto'
import { DeviceDirection } from '../enum/DeviceDirection'
import { DateTime, ignoreTimezoneOffset } from '../common/date-time'
import {
  get,
  getWithPagination,
  patchWithAuditComment,
  post,
} from '../common/fetch.utils'
import { ACCESS_USINGS } from '../api-urls'
import { mapSortAccessUsingsParam } from './access-usings-sort.mapper'
import filterMapper from './access-usings-filter.mapper'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'
import { RecurringJobCreationParamsDto } from '../dto/recurring-job-creation-params.dto'
import { AuditCommentParamsDto } from '../dto/audit-comment-params.dto'
import { CacheableDataProviderExtensionResult } from '../common/data-provider'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<AccessUsingDto>> => {
    const filterParams = `au=>${filterParamsComposer(
      'au',
      filter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortAccessUsingsParam,
    )
    const path = `/${filterParams}${pathParams ? `?${pathParams}` : ''}`

    const {
      data,
      range: { total },
    } = await getWithPagination<AccessUsingDto[]>(
      `${ACCESS_USINGS}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<UpdateAccessUsingRARequest>,
  ): Promise<UpdateResult<AccessUsingDto | RecurringJobDetailsDto>> => {
    const additionalParams = data as
      | RecurringJobCreationParamsDto
      | AuditCommentParamsDto
    const isRecurringJob = (additionalParams as RecurringJobCreationParamsDto)
      ?.recurringJobName
    const update = await patchWithAuditComment<
      UpdateAccessUsingRequest,
      AccessUsingDto | RecurringJobDetailsDto
    >(
      ACCESS_USINGS,
      {
        id,
        active:
          data.active !== previousData.active || isRecurringJob
            ? data.active
            : undefined,
      },
      additionalParams && additionalParams,
    )
    if ((update as RecurringJobDetailsDto)?.recurringJobId)
      return Promise.resolve({ data: previousData as AccessUsingDto })
    return Promise.resolve({
      data: update,
    })
  },
  updateMany: async (
    resource,
    { ids, data: { active = false, ...rest } }: UpdateManyParams,
  ): Promise<UpdateManyResult | RecurringJobDetailsDto> => {
    const additionalParams = rest as RecurringJobCreationParamsDto
    const pendingUpdates = ids
      .map((auId) => ({ id: auId, active } as UpdateAccessUsingRequest))
      .map((req) =>
        patchWithAuditComment<
          UpdateAccessUsingRequest,
          AccessUsingDto | RecurringJobDetailsDto
        >(ACCESS_USINGS, req, additionalParams && additionalParams),
      )
    const accessUsings = await Promise.all(pendingUpdates)
    const updatedIds = accessUsings
      .map((au) => (au as AccessUsingDto)?.id)
      .filter((id) => id)
    return Promise.resolve({
      data: updatedIds,
    })
  },
  create: async (
    resource,
    { data }: CreateParams<AccessUsingsCreateParams>,
  ): Promise<CreateResult> => {
    const { ticketIds, accessIds } = data
    const request: CreateAccessUsingRequest[] = accessIds.flatMap((accessId) =>
      ticketIds.map((ticketId) => ({
        date: ignoreTimezoneOffset(new Date()).toISOString() as DateTime,
        direction: DeviceDirection.IN,
        active: true,
        accessId,
        ticketId,
        verifierDeviceId: null,
      })),
    )
    await post<CreateAccessUsingRequest[], AccessUsingDto[]>(
      ACCESS_USINGS,
      request,
    )
    return Promise.resolve({
      data: { id: 0 }, // need to return something
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<AccessUsingDto>> => {
    const data = await get<AccessUsingDto[]>(
      `${ACCESS_USINGS}/GetByConditions`,
      `/u=>new int[] {${ids.toString()}}.Contains(u.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  activate: async (
    resource: string,
    { id }: AccessUsingActivationParams,
  ): Promise<CacheableDataProviderExtensionResult<Record>> => {
    await post<undefined, undefined>(
      `${ACCESS_USINGS}/Activate/${id}`,
      undefined,
    )
    return Promise.resolve({ data: { id } })
  },
  deactivate: async (
    resource: string,
    { id }: AccessUsingActivationParams,
  ): Promise<CacheableDataProviderExtensionResult<Record>> => {
    await post<undefined, undefined>(
      `${ACCESS_USINGS}/Deactivate/${id}`,
      undefined,
    )
    return Promise.resolve({ data: { id } })
  },
} as BookingsDataProvider

interface BookingsDataProvider extends DataProvider {
  activate: (
    resource: string,
    params: AccessUsingActivationParams,
  ) => Promise<CacheableDataProviderExtensionResult<Record>>

  deactivate: (
    resource: string,
    params: AccessUsingActivationParams,
  ) => Promise<CacheableDataProviderExtensionResult<Record>>
}

interface AccessUsingActivationParams {
  readonly id: number
}

interface CreateAccessUsingRequest {
  readonly date: DateTime
  readonly direction: DeviceDirection
  readonly active: boolean
  readonly ticketId: number
  readonly accessId: number
}

interface UpdateAccessUsingRequest {
  readonly id: Identifier
  readonly active: boolean | undefined
}

interface UpdateAccessUsingRARequest extends UpdateAccessUsingRequest {
  readonly auditComment: string
}

export default provider
