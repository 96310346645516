import {
  DataProvider,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  DeleteParams,
  DeleteResult,
  DeleteManyParams,
  DeleteManyResult,
  Identifier,
} from 'react-admin'
import { JOBS_URL } from '../api-urls'
import { CacheableDataProviderExtensionResult } from '../common/data-provider'
import { get, getWithPagination } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { RecurringJobDetailsDto } from '../dto/recurring-job-details.dto'
import filterMapper from './recurring-jobs-filter.mapper'
import { mapSortRecurringJobParam } from './recurring-jobs-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<RecurringJobRAResponse>> => {
    const filterParams = `v=>${filterParamsComposer('v', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortRecurringJobParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<RecurringJobDetailsDto[]>(
      `${JOBS_URL}/GetRecurringJobsByConditions`,
      path,
    )

    return Promise.resolve({
      data: data.map((value: RecurringJobDetailsDto) => ({
        ...value,
        id: value.recurringJobId,
      })),
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<RecurringJobRAResponse>> => {
    const data = await get<RecurringJobDetailsDto>(
      `${JOBS_URL}/GetRecurringJob`,
      `/${id}`,
    )
    return Promise.resolve({
      data: { ...data, id: data.recurringJobId },
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<RecurringJobRAResponse>> => {
    const data = await get<RecurringJobDetailsDto[]>(
      JOBS_URL,
      `/GetRecurringJobsByConditions/e=>new string[] {${ids
        .map((id) => `"${id}"`)
        .toString()}}.Contains(e.RecurringJobId)`,
    )
    return Promise.resolve({
      data: data.map((value: RecurringJobDetailsDto) => ({
        ...value,
        id: value.recurringJobId,
      })),
    })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    const data = await get<RecurringJobIdResponse>(
      `${JOBS_URL}/DeleteRecurringJob`,
      `/${id}`,
    )
    return {
      data: {
        id: data.jobId,
      },
    }
  },
  deleteMany: async (
    resource: string,
    { ids }: DeleteManyParams,
  ): Promise<DeleteManyResult> => {
    ids.forEach((id: Identifier) =>
      get<RecurringJobIdResponse>(`${JOBS_URL}/DeleteRecurringJob`, `/${id}`),
    )

    return {
      data: ids,
    }
  },
  triggerRecurringJob: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<RecurringJobIdResponse>> => {
    const data = await get<RecurringJobIdResponse>(
      `${JOBS_URL}/TriggerRecurringJob`,
      `/${id}`,
    )
    return Promise.resolve({ data })
  },
} as RecurringJobsDataProvider

interface RecurringJobsDataProvider extends DataProvider {
  triggerRecurringJob: (
    resource: string,
    params: GetOneParams,
  ) => Promise<CacheableDataProviderExtensionResult<RecurringJobIdResponse>>
}

export interface RecurringJobRAResponse extends RecurringJobDetailsDto {
  readonly id: string
}

interface RecurringJobIdResponse {
  readonly jobId: string
}

export default provider
