import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  useTranslate,
  TitleProps,
  SimpleForm,
  TextField,
  TextInput,
  SaveButton,
  ShowButton,
  Toolbar,
  ToolbarProps,
} from 'react-admin'
import { DetectorDto } from '../../../core/dto/device/detector/detector.dto'
import { VerifierDeviceDisabledDto } from '../../../core/dto/device/verifier-device-disabled/verifier-device-disabled.dto'

const validateVerifierDeviceDisabledEdit = (values: DetectorDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  return errors
}

interface VerifierDeviceDisabledTitleProps extends TitleProps {
  record?: VerifierDeviceDisabledDto
}

const EditTitle = (data: VerifierDeviceDisabledTitleProps) => {
  const { record } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.verifier-device-disabled.titles.edit')}:{' '}
      {record?.id}. {record?.name}{' '}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const VerifierDeviceDisabledEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <SimpleForm
      redirect="show"
      toolbar={<EditToolbar />}
      validate={validateVerifierDeviceDisabledEdit}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" isRequired />
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
)
