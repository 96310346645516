import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core'
import React from 'react'
import { ResourceContextProvider, useTranslate } from 'react-admin'
import { ResourceName } from '../core/ResourceName'
import { FindCustomerByInfo } from './common/FindCustomerByInfo'
import { FindUserByTicket } from './common/FindUserByTicket'
import { EventList } from './events/EventList'
import { LogList } from './logs/LogList'
import { useHasAuthority } from './hooks/useHasAuthority'
import { Authority } from '../core/auth/Authority'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      textAlign: 'center',
      letterSpacing: theme.spacing(0.2),
    },
    lastLogs: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '10%',
    },
  }),
)

const TicketFinder = () => (
  <Card style={{ width: '100%', marginBottom: '5px' }}>
    <FindUserByTicket />
  </Card>
)

const CustomerFinder = () => (
  <Card style={{ width: '100%', marginBottom: '5px' }}>
    <FindCustomerByInfo />
  </Card>
)

const LastLogs = () => {
  const t = useTranslate()
  return (
    <Card style={{ width: '100%', marginBottom: '5px' }}>
      <CardHeader title={t('dashboard.cards.last-logs.title')} />
      <CardContent>
        <ResourceContextProvider value={ResourceName.LOGS}>
          <LogList
            basePath="/"
            title=" "
            compactMode
            pagination={false}
            perPage={10}
            actions={false}
            filters={undefined}
          />
        </ResourceContextProvider>
      </CardContent>
    </Card>
  )
}

const LastEvents = () => {
  const t = useTranslate()
  return (
    <Card style={{ width: '100%', marginBottom: '5px' }}>
      <CardHeader title={t('dashboard.cards.last-events.title')} />
      <CardContent>
        <ResourceContextProvider value={ResourceName.EVENTS}>
          <EventList
            sort={{ field: 'id', order: 'DESC' }}
            compactMode
            basePath="/"
            title=" "
          />
        </ResourceContextProvider>
      </CardContent>
    </Card>
  )
}

export const Dashboard = () => {
  const classes = useStyles()
  const t = useTranslate()
  const hasAuthority = useHasAuthority()

  let preferences
  try {
    preferences = JSON.parse(localStorage?.preferences)
    if (preferences === null || preferences === undefined) preferences = {}
  } catch {
    preferences = {}
  }

  const {
    frontDashboardVisibility_CustomerFinder: customerFinderActive,
    frontDashboardVisibility_LastEvents: lastEventsActive,
    frontDashboardVisibility_LastLogs: lastLogsActive,
    frontDashboardVisibility_TicketFinder: ticketFinderActive,
  } = preferences || {
    frontDashboardVisibility_CustomerFinder: false,
    frontDashboardVisibility_LastEvents: false,
    frontDashboardVisibility_LastLogs: false,
    frontDashboardVisibility_TicketFinder: false,
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            className={classes.header}
            title={t('dashboard.welcome')}
            titleTypographyProps={{ variant: 'h4' }}
          />
        </Card>
      </Grid>
      <Grid item sm={6} xs={12}>
        {hasAuthority(Authority.VIEW_TICKETS) &&
          hasAuthority(Authority.VIEW_BOOKINGS) &&
          ticketFinderActive && <TicketFinder />}
        {hasAuthority(Authority.VIEW_EVENTS) && lastEventsActive && (
          <LastEvents />
        )}
      </Grid>
      <Grid className={classes.lastLogs} item sm={6} xs={12}>
        {hasAuthority(Authority.VIEW_CUSTOMERS) && customerFinderActive && (
          <CustomerFinder />
        )}
        {hasAuthority(Authority.VIEW_LOGS) && lastLogsActive && <LastLogs />}
      </Grid>
    </Grid>
  )
}
