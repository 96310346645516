import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useListContext,
  useMutation,
  useNotify,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { ConfirmDialog } from '../../common/ConfirmDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.white,
      margin: '2px',
    },
  }),
)

export const BookingCreateUseButton = ({ ...props }) => {
  const { booking } = props
  const classes = useStyles()
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading, record: access } = useShowContext()
  const { refetch } = useListContext()
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  const [mutate, { loading: mutationLoading }] = useMutation()

  const handleOpenConfirm = () => {
    setConfirmOpen(true)
  }

  const handleCloseConfirm = () => {
    setConfirmOpen(false)
  }

  const onClick = async () => {
    await mutate(
      {
        type: sanitizeFetchType('createUse'),
        resource: ResourceName.BOOKINGS,
        payload: {
          id: booking?.id,
          accessId: access?.id,
        },
      },
      {
        returnPromise: true,
        onFailure: (err) => notify(err?.message, 'error'),
      },
    )
    handleCloseConfirm()
    refetch?.()
  }

  return (
    <>
      <ConfirmDialog
        open={confirmOpen}
        confirm={onClick}
        source="createUse"
        cancel={handleCloseConfirm}
        action="set"
        resource={ResourceName.BOOKINGS}
      />
      <Button
        disabled={
          !hasAuthority(Authority.BOOKINGS_CREATE_USE) ||
          loading ||
          mutationLoading
        }
        className={classes.button}
        variant="contained"
        onClick={handleOpenConfirm}
        label="resources.bookings.actions.createUse"
        useSmallVersionBreakpoint={false}
      />
    </>
  )
}
