import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Edit,
  EditProps,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { AccessBonusDto } from '../../../core/dto/access-bonus.dto'

const validateAccessEdit = (values: AccessBonusDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.description) {
    errors.description = 'ra.validation.required'
  }
  return errors
}

interface AccessBonusTitleProps extends TitleProps {
  record?: AccessBonusDto
}

const EditTitle = (data: AccessBonusTitleProps) => {
  const { record } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.access-bonuses.titles.edit')}: {record?.id}.{' '}
      {record?.name} ({record?.description})
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const AccessBonusEdit = (props: EditProps) => (
  <Edit {...props} hasShow={false} title={<EditTitle />}>
    <SimpleForm
      redirect="list"
      toolbar={<EditToolbar />}
      validate={validateAccessEdit}
    >
      <TextField source="id" label="ID" />
      <TextInput source="name" isRequired />
      <TextInput source="description" isRequired />
    </SimpleForm>
  </Edit>
)
