import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Create,
  CreateProps,
  ListButton,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { CreateUserRequest } from '../../../core/administration/users/users.provider'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

export const validateUserCreate = (values: CreateUserRequest) => {
  const errors: { [n: string]: string } = {}
  if (!values.userName) {
    errors.userName = 'ra.validation.required'
  }
  if (!values.password) {
    errors.password = 'ra.validation.required'
  }
  if (!values.companyId) {
    errors.companyId = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.users.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const defaultValues = {
  requirePasswordUpdate: false,
}

export const UserCreate = (props: CreateProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Create {...props} title={<CreateTitle />}>
      <SimpleForm
        redirect="show"
        validate={validateUserCreate}
        toolbar={<CreateToolbar />}
        initialValues={defaultValues}
      >
        <TextInput source="userName" isRequired />
        <TextInput source="password" isRequired />
        {hasAuthority(Authority.VIEW_COMPANIES) && (
          <FilteredReferenceInput
            source="companyId"
            defaultValue={null}
            reference={ResourceName.COMPANIES}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
        <BooleanInput source="requirePasswordUpdate" />
      </SimpleForm>
    </Create>
  )
}
