type CsvDataAggregate = {
  columnNames: string[]
  previewRows: any[][]
  csvData: any[][]
}

export type CsvDataPayload = {
  rawCsvData: any[][]
}

export const csvDataInitialState: CsvDataAggregate = {
  columnNames: [],
  previewRows: [],
  csvData: [],
}

export const csvDataReducer = (
  aggregate: CsvDataAggregate,
  payload: CsvDataPayload | null,
): CsvDataAggregate => {
  if (payload) {
    const { rawCsvData } = payload
    return {
      columnNames: rawCsvData[0] as string[],
      previewRows: rawCsvData.slice(1, 6),
      csvData: rawCsvData.slice(1),
    }
  }
  return csvDataInitialState
}
