import { useMediaQuery, useTheme } from '@material-ui/core'
import { Autorenew } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  sanitizeFetchType,
  useMutation,
  useNotify,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { Authority } from '../../../core/auth/Authority'
import { ResourceName } from '../../../core/ResourceName'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import { EventDto } from '../../../core/dto/event/event.dto'
import { SyncCardsDialog } from './SyncCardsDialog'

export const SyncCardsButton = ({ ...props }) => {
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext<EventDto>()
  const [syncCardsDialogOpen, setSyncCardsDialogOpen] = useState(false)
  const [mutate, { loading: mutationLoading }] = useMutation()
  const [mutationResult, setMutationResult] = useState<
    { jobId: number } | undefined
  >(undefined)
  const refresh = useRefresh()

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSyncCards = async () => {
    setSyncCardsDialogOpen(false)
    const { data: result } = await mutate(
      {
        resource: ResourceName.SERVICE_LINK,
        type: sanitizeFetchType('syncCards'),
      },
      {
        returnPromise: true,
        onFailure: (msg) => notify(msg, 'error'),
      },
    )
    setMutationResult(result)
  }

  const handleCloseDialog = () => {
    refresh()
  }

  const handleSyncCardsDialog = () => {
    setSyncCardsDialogOpen(true)
  }

  return (
    <>
      <ConfirmDialog
        open={syncCardsDialogOpen}
        confirm={handleSyncCards}
        source="syncCards"
        cancel={() => setSyncCardsDialogOpen(false)}
        action="set"
        resource={ResourceName.SERVICE_LINK}
      />
      <SyncCardsDialog
        handleClose={handleCloseDialog}
        open={!!mutationResult || !!mutationLoading}
        result={mutationResult}
      />
      <Button
        onClick={handleSyncCardsDialog}
        label={
          !smallScreen ? 'resources.service-link.actions.syncCards' : undefined
        }
        {...props}
        useSmallVersionBreakpoint={false}
        disabled={
          !hasAuthority(Authority.EDIT_EVENTS) || loading || mutationLoading
        }
        variant="text"
        color="primary"
        startIcon={<Autorenew />}
        size={!smallScreen ? 'small' : 'large'}
      />
    </>
  )
}
