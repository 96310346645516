import { DataProvider } from 'react-admin'
import { PermissionDto } from '../../dto/permission.dto'

const fakePermissions: PermissionDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakePermissions, total: fakePermissions.length }),
} as DataProvider

export default provider
