import { DataProvider } from 'react-admin'
import { DateTime } from '../../common/date-time'
import { EventStandsPeriodResponse } from '../../stats/period-view/period-view'

const fakeStatsPeriodViewList: EventStandsPeriodResponse[] = [
  {
    eventId: 1,
    eventName: 'testEvent1',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    eventExternalId: '1',
    periods: [],
  },
  {
    eventId: 2,
    eventName: 'testEvent2',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    eventExternalId: '2',
    periods: [],
  },
  {
    eventId: 3,
    eventName: 'testEvent3',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    eventExternalId: '3',
    periods: [],
  },
  {
    eventId: 4,
    eventName: 'testEvent4',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    eventExternalId: '4',
    periods: [],
  },
  {
    eventId: 5,
    eventName: 'testEvent5',
    eventDateStart: '01-01-2021T00:00.00' as DateTime,
    eventDateEnd: '01-01-2021T00:00.00' as DateTime,
    eventExternalId: '5',
    periods: [],
  },
]

const provider = {
  getOne: (resource, { id }: { id: string }) => {
    const found = fakeStatsPeriodViewList.find((v) => `${v.eventId}` === id)
    if (found) {
      return Promise.resolve({
        data: found,
      })
    }
    return Promise.reject(new Error())
  },
} as DataProvider

export default provider
