var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Fragment } from 'react';
import { getFieldLabelTranslationArgs, useTranslate } from 'react-admin';
import { DialogContent, Popover, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
export var ComputedFieldTemplatePopover = function (_a) {
    var availableColumns = _a.availableColumns, resource = _a.resource, props = __rest(_a, ["availableColumns", "resource"]);
    var translate = useTranslate();
    var classes = useStyles(props);
    return (React.createElement(Popover, __assign({ anchorOrigin: {
            vertical: 'center',
            horizontal: 'left',
        }, transformOrigin: {
            vertical: 'center',
            horizontal: 'right',
        }, disableAutoFocus: true, disableEnforceFocus: true, disableEscapeKeyDown: true, disableRestoreFocus: true, disableScrollLock: true }, props),
        React.createElement(DialogContent, null,
            React.createElement(Typography, { component: "p" }, translate('ra-preferences.no-code.computed_fields.instructions')),
            React.createElement(Typography, { component: "code", className: classes.template }, translate('ra-preferences.no-code.computed_fields.template'))),
        React.createElement(DialogContent, null,
            React.createElement(Typography, null, translate('ra-preferences.no-code.computed_fields.available_fields')),
            React.createElement("dl", { className: classes.dl },
                React.createElement(Typography, { component: "dt", className: classes.dt, color: "textSecondary", variant: "subtitle2" }, translate('ra-preferences.no-code.computed_fields.source_title')),
                React.createElement(Typography, { component: "dd", className: classes.dd, color: "textSecondary", variant: "subtitle2" }, translate('ra-preferences.no-code.computed_fields.label_title')),
                availableColumns.map(function (column) { return (React.createElement(Fragment, { key: column[0] },
                    React.createElement(Typography, { component: "dt", className: classes.dt }, column[0]),
                    React.createElement(Typography, { component: "dd", className: classes.dd }, translate.apply(void 0, getFieldLabelTranslationArgs({
                        label: column[1],
                        resource: resource,
                        source: column[0],
                    }))))); })))));
};
var useStyles = makeStyles(function (theme) { return ({
    template: {
        fontFamily: theme.typography.fontFamily.fixed,
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.palette.grey[200],
        marginTop: theme.spacing(1),
    },
    dl: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    dt: {
        display: 'inline-block',
    },
    dd: {
        display: 'inline-block',
        marginBottom: theme.spacing(1),
    },
}); });
