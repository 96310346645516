import { makeStyles } from '@material-ui/core'
import React from 'react'
import {
  useTranslate,
  MenuItemLink,
  ReduxState,
  ResourceDefinition,
  getResources,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { ResourceName } from '../../core/ResourceName'
import { MenuItemProps } from './MenuItem'

const useStyles = makeStyles((theme) => ({
  sidebarIsOpen: {
    '& > div': {
      paddingLeft: theme.spacing(2),
    },
  },
}))

const MenuSubitem = (props: MenuItemProps) => {
  const { resource, icon, to, label } = props
  const translate = useTranslate()
  const classes = useStyles()

  const availableResources = new Set(
    useSelector<ReduxState, ResourceDefinition[]>(getResources).map(
      (r) => r.name as ResourceName,
    ),
  )

  return (
    <>
      {availableResources.has(resource) && (
        <MenuItemLink
          className={classes.sidebarIsOpen}
          to={to || `/${resource}`}
          primaryText={translate(label || `resources.${resource}.name`)}
          leftIcon={icon}
        />
      )}
    </>
  )
}

export default MenuSubitem
