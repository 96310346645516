import { createStyles, makeStyles } from '@material-ui/core'
import React, { useState, useReducer, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { ToggleType } from '../../../../core/devices/palms/palm-device-id-button'
import {
  palmDeviceIdButtonInitialState,
  palmDeviceIdButtonReducer,
} from '../../../../core/devices/palms/palm-device-id-button.reducer'
import { PalmDto } from '../../../../core/dto/device/palm/palm.dto'
import { ResourceName } from '../../../../core/ResourceName'
import { ConfirmDialog } from '../../../common/ConfirmDialog'
import Button from '../../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { PalmDeviceIdDialog } from '../PalmDialogs'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: '2px',
    },
  }),
)

export const PalmDeviceIdButton = () => {
  const classes = useStyles()
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const [{ type, text, mutateQuery }, dispatch] = useReducer(
    palmDeviceIdButtonReducer,
    palmDeviceIdButtonInitialState,
  )
  const [setDeviceIdDialogOpen, setSetDeviceIdDialogOpen] = useState(false)
  const [deviceId, setDeviceId] = useState('')
  const [resetDeviceIdDialogOpen, setResetDeviceIdDialogOpen] = useState(false)
  const palm = useRecordContext<PalmDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  useEffect(() => {
    if (palm.mobileDeviceId) {
      dispatch({ type: ToggleType.RESETID })
    } else {
      dispatch({ type: ToggleType.SETID })
    }
  }, [palm])

  const onClick = async () => {
    if (type === ToggleType.RESETID) setResetDeviceIdDialogOpen(true)
    else setSetDeviceIdDialogOpen(true)
  }

  const handleLock = async () => {
    const query = mutateQuery?.(palm, deviceId)
    await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    refresh()
  }

  return (
    <>
      <ConfirmDialog
        open={resetDeviceIdDialogOpen}
        confirm={handleLock}
        source="deviceId"
        cancel={() => setResetDeviceIdDialogOpen(false)}
        action="remove"
        resource={ResourceName.PALMS}
      />
      <PalmDeviceIdDialog
        open={setDeviceIdDialogOpen}
        close={() => setSetDeviceIdDialogOpen(false)}
        deviceId={deviceId}
        setDeviceId={setDeviceId}
        handleLock={handleLock}
      />
      <Button
        disabled={
          !hasAuthority(Authority.PALMS_SET_MOBILE_DEVICE_ID_BUTTON) ||
          loading ||
          mutationLoading
        }
        className={classes.button}
        variant="contained"
        onClick={onClick}
        label={text}
        useSmallVersionBreakpoint={false}
      />
    </>
  )
}
