import { DataProvider } from 'react-admin'
import { TerminalDto } from '../../dto/device/terminals/terminal.dto'

const fakeTerminals: TerminalDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeTerminals, total: fakeTerminals.length }),
} as DataProvider

export default provider
