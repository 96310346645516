import {
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { PALMS_URL } from '../api-urls'
import { get, getWithPagination, patch } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { getDeviceCategoryChildren } from '../devices/devices.utils'
import { PalmSchemeDataDto } from '../dto/palm-scheme-data.dto'
import { getSchemeChildren } from '../schemes/schemes.utils'
import filterMapper from './palm-scheme-data-filter.mapper'
import { mapSortPalmSchemeDataParam } from './palm-scheme-data-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<PalmSchemeDataDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    // Tree type filter for schemes
    if (extendedFilter?.schemeIdWithDescendants) {
      const schemesIds = await getSchemeChildren(
        extendedFilter.schemeIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, schemesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortPalmSchemeDataParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<PalmSchemeDataDto[]>(
      `${PALMS_URL}/GetPalmSchemeDataByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<PalmSchemeDataDto>> => {
    const data = await get<PalmSchemeDataDto>(
      `${PALMS_URL}/GetPalmSchemeData`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<PalmSchemeDataDto>> => {
    const data = await get<PalmSchemeDataDto[]>(
      PALMS_URL,
      `/GetPalmSchemeDataByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<PalmSchemeDataDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      PalmSchemeDataDto
    >(`${PALMS_URL}/PatchPalmSchemeData`, {
      id,
      text: data.text !== previousData.text ? data.text : undefined,
      backgroundColor:
        data.backgroundColor !== previousData.backgroundColor
          ? data.backgroundColor
          : undefined,
      backgroundImage:
        data.backgroundImage !== previousData.backgroundImage
          ? data.backgroundImage
          : undefined,
      sound: data.sound !== previousData.sound ? data.sound : undefined,
      positiveValidation:
        data.positiveValidation !== previousData.positiveValidation
          ? data.positiveValidation
          : undefined,
      // schemeId:
      //   data.schemeId !== previousData.schemeId ? data.schemeId : undefined,
    })
    return Promise.resolve({ data: update })
  },
} as DataProvider

export default provider
