import { Autorenew } from '@material-ui/icons'
import React, { useState, useReducer, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin'
import Button from '../../../common/customized-mui-components/Button'
import { ToggleType } from '../../../../core/devices/passages/passage-set-boolean-status-button'
import { PassageDto } from '../../../../core/dto/device/passages/passage.dto'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'
import {
  passageSetOpenButtonInitialState,
  passageSetOpenButtonReducer,
} from '../../../../core/devices/passages/passage-set-open-button.reducer'
import { PassageDirections } from '../../../../core/enum/PassageDirections'
import { PassageSetOpenDialog } from '../PassageDialogs'

export const PassageSetOpenButton = ({ ...props }) => {
  const { asRecurringJob, disabled, direction } = props
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()

  const [{ mutateQuery }, dispatch] = useReducer(
    passageSetOpenButtonReducer,
    passageSetOpenButtonInitialState,
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const passage = useRecordContext<PassageDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  useEffect(() => {
    if (direction === PassageDirections.OUT) {
      dispatch({ type: ToggleType.OFF })
    } else {
      dispatch({ type: ToggleType.ON })
    }
  }, [direction])

  const handleOpenPassage = async (
    timeInSeconds: number,
    recurringJobName?: string,
    cronExpression?: string,
    auditNote?: string,
  ) => {
    let query
    if (!asRecurringJob) {
      query = mutateQuery?.(
        {
          passageId: passage?.id,
          timeInSeconds,
          direction,
        },
        auditNote,
      )
    } else {
      query = mutateQuery?.(
        {
          passageId: passage?.id,
          timeInSeconds,
          direction,
        },
        auditNote,
        recurringJobName as string,
        cronExpression as string,
      )
    }
    await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    refresh()
  }

  return (
    <>
      <PassageSetOpenDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleOpenPassage={handleOpenPassage}
        direction={direction}
        asRecurringJob={asRecurringJob}
        withAuditNote
      />
      <Button
        disabled={
          !hasAuthority(Authority.PASSAGES_SET_OPEN) ||
          loading ||
          mutationLoading ||
          disabled === true
        }
        variant={!asRecurringJob ? 'contained' : 'text'}
        color={!asRecurringJob ? 'primary' : 'default'}
        onClick={() => setDialogOpen(true)}
        label={
          !asRecurringJob
            ? `resources.passages.actions.setOpen.${direction}`
            : 'common.recurring-job.buttons.create-recurring-job'
        }
        useSmallVersionBreakpoint={false}
        startIcon={asRecurringJob ? <Autorenew /> : undefined}
      />
    </>
  )
}
