const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  name: (lambdaParameter, value) =>
    `${lambdaParameter}.Name.Contains("${value}")`,
  parentId: (lambdaParameter, value) => `${lambdaParameter}.ParentId==${value}`,
  parentIds: (lambdaParameter, value) =>
    `new int%3F[] {${(
      value as number[]
    )?.toString()}}.Contains(${lambdaParameter}.ParentId)`,
  notId: (lambdaParameter, value) => `!${lambdaParameter}.Id.Equals(${value})`,
  search: (lambdaParameter, value) =>
    `(${lambdaParameter}.Id+${lambdaParameter}.Name).Contains("${value}")`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
