import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  CreateProps,
  ListButton,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { DynamicStatisticDto } from '../../../core/dto/dynamic-statistic.dto'

export const validateDynamicStatisticCreate = (values: DynamicStatisticDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }
  if (!values.sqlQuery) {
    errors.sqlQuery = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return (
    <span>{translate('resources.stats-dynamic-statistics.titles.create')}</span>
  )
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

export const DynamicStatisticCreate = (props: CreateProps) => (
  <Create {...props} title={<CreateTitle />}>
    <SimpleForm
      redirect="show"
      toolbar={<CreateToolbar />}
      validate={validateDynamicStatisticCreate}
    >
      <TextInput source="name" />
      <TextInput source="sqlQuery" fullWidth multiline />
    </SimpleForm>
  </Create>
)
