import { DataProvider } from 'react-admin'
import { DynamicCounterDto } from '../../dto/dynamic-counter.dto'

const fakeDynamicCounters: DynamicCounterDto[] = []

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({
      data: fakeDynamicCounters,
      total: fakeDynamicCounters.length,
    }),
} as DataProvider

export default provider
