const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  key: (lambdaParameter, value) =>
    `${lambdaParameter}.Key.Contains("${value}")`,
  dynamicCounterId: (lambdaParameter, value) =>
    `${lambdaParameter}.DynamicCounter.Id.Equals(${value})`,
  type: (lambdaParameter, value) =>
    `${lambdaParameter}.Type.Equals(TicketAPI.Models.Enums.DynamicCounters.DynamicCounterParameterTypes.${value})`,
}

const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
