import React from 'react'
import { Show, ShowProps, Tab, TabbedShowLayout } from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import {
  AccessDatesTab,
  AccessDetailsTab,
  AccessUsingsTab,
} from '../../configuration/accesses/AccessShow'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { BookingDto } from '../../../core/dto/booking.dto'

export const AccessShow = ({
  accessUsingsTicketsIdsFilter,
  booking,
  ...props
}: AccessShowProps) => {
  const hasAuthority = useHasAuthority()

  return (
    <Show {...props} actions={false}>
      <TabbedShowLayout syncWithLocation={false}>
        {hasAuthority(Authority.VIEW_ACCESSES) && (
          <Tab path="details" label="resources.bookings.accesses.tabs.details">
            <AccessDetailsTab displayActions={false} displayReference />
          </Tab>
        )}
        {hasAuthority(Authority.VIEW_ACCESS_DATES) && (
          <Tab path="dates" label="resources.bookings.accesses.tabs.dates">
            <AccessDatesTab displayActions={false} />
          </Tab>
        )}
        {hasAuthority(Authority.VIEW_ACCESS_USINGS) && (
          <Tab path="uses" label="resources.bookings.accesses.tabs.uses">
            <AccessUsingsTab
              accessUsingsTicketsIdsFilter={accessUsingsTicketsIdsFilter}
              booking={booking as BookingDto}
            />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  )
}

export interface AccessShowProps extends ShowProps {
  readonly accessUsingsTicketsIdsFilter: number[] | null | undefined
  readonly booking?: BookingDto
}
