import { Tooltip, makeStyles } from '@material-ui/core'
import { Close, FilterTiltShift, TrackChanges, Help } from '@material-ui/icons'
import React, { useState } from 'react'
import {
  useMutation,
  useRecordContext,
  useRefresh,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { SetStandbyModeDialog } from '../EngineDialogs'
import { EngineDto } from '../../../../core/dto/device/engine/engine.dto'
import { StanbyMode } from '../../../../core/enum/StanbyMode'
import { ResourceName } from '../../../../core/ResourceName'
import { Authority } from '../../../../core/auth/Authority'
import { useHasAuthority } from '../../../hooks/useHasAuthority'

const useStyles = makeStyles({
  iconContainer: {
    position: 'relative',
    width: 34,
  },
  icon: {
    position: 'absolute',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
})

export const EngineStandbyModeCompactButton = ({ ...props }) => {
  const { disabled } = props
  const { loading } = useShowContext()
  const translate = useTranslate()
  const classes = useStyles()
  const refresh = useRefresh()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const hasAuthority = useHasAuthority()

  const engine = useRecordContext<EngineDto>()

  const [standbyMode, setStandbyMode] = useState<StanbyMode | undefined>(
    engine?.stanbyMode || undefined,
  )
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleStandbyMode = async () => {
    await mutate({
      resource: ResourceName.ENGINES,
      type: 'setStandbyMode',
      payload: {
        engineId: engine?.id,
        stanbyMode: standbyMode,
      },
    })
    refresh()
  }

  const StandbyStatusIcon = () => {
    switch (engine?.stanbyMode) {
      case StanbyMode.ALWAYS_OFF:
        return (
          <Tooltip
            title={`${translate(
              'resources.engines.fields.stanbyMode',
            )}: ${translate(
              `resources.enums.standbyMode.${engine?.stanbyMode}`,
            )}`}
            onClick={
              !loading &&
              !mutationLoading &&
              !disabled &&
              hasAuthority(Authority.ENGINES_SET_STANDBY_MODE_BUTTON)
                ? () => setDialogOpen(true)
                : undefined
            }
          >
            <div
              className={classes.iconContainer}
              style={{
                cursor:
                  !loading && !mutationLoading && !disabled
                    ? 'pointer'
                    : 'default',
              }}
            >
              <TrackChanges className={classes.icon} />
              <Close className={classes.crossIcon} />
            </div>
          </Tooltip>
        )
      case StanbyMode.ALWAYS_ON:
        return (
          <Tooltip
            title={`${translate(
              'resources.engines.fields.stanbyMode',
            )}: ${translate(
              `resources.enums.standbyMode.${engine?.stanbyMode}`,
            )}`}
            onClick={
              !loading &&
              !mutationLoading &&
              !disabled &&
              hasAuthority(Authority.ENGINES_SET_STANDBY_MODE_BUTTON)
                ? () => setDialogOpen(true)
                : undefined
            }
          >
            <div
              className={classes.iconContainer}
              style={{
                cursor:
                  !loading && !mutationLoading && !disabled
                    ? 'pointer'
                    : 'default',
              }}
            >
              <TrackChanges className={classes.icon} />
            </div>
          </Tooltip>
        )
      case StanbyMode.SENSOR:
        return (
          <Tooltip
            title={`${translate(
              'resources.engines.fields.stanbyMode',
            )}: ${translate(
              `resources.enums.standbyMode.${engine?.stanbyMode}`,
            )}`}
            onClick={
              !loading &&
              !mutationLoading &&
              !disabled &&
              hasAuthority(Authority.ENGINES_SET_STANDBY_MODE_BUTTON)
                ? () => setDialogOpen(true)
                : undefined
            }
          >
            <div
              className={classes.iconContainer}
              style={{
                cursor:
                  !loading && !mutationLoading && !disabled
                    ? 'pointer'
                    : 'default',
              }}
            >
              <FilterTiltShift className={classes.icon} />
            </div>
          </Tooltip>
        )
      default:
        return (
          <Tooltip
            title={`${translate(
              'resources.engines.fields.stanbyMode',
            )}: ${translate(
              `resources.enums.standbyMode.${engine?.stanbyMode}`,
            )}`}
            onClick={
              !loading &&
              !mutationLoading &&
              !disabled &&
              hasAuthority(Authority.ENGINES_SET_STANDBY_MODE_BUTTON)
                ? () => setDialogOpen(true)
                : undefined
            }
          >
            <div
              className={classes.iconContainer}
              style={{
                cursor:
                  !loading && !mutationLoading && !disabled
                    ? 'pointer'
                    : 'default',
              }}
            >
              <Help className={classes.icon} />
            </div>
          </Tooltip>
        )
    }
  }

  return (
    <>
      <SetStandbyModeDialog
        open={dialogOpen}
        close={() => setDialogOpen(false)}
        standbyMode={standbyMode}
        setStandbyMode={setStandbyMode}
        handleStandbyMode={handleStandbyMode}
      />
      <StandbyStatusIcon />
    </>
  )
}
