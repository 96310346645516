var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { cloneElement, useCallback, useMemo, useState, } from 'react';
import { CRUD_UPDATE, DatagridRow, SaveContextProvider, useNotify, useSaveModifiers, useUpdate, useResourceContext, useRecordContext, } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { EditRowContext } from './EditRowContext';
var EditableRow = function (props) {
    var form = props.form, resourceOverride = props.resource, id = props.id, recordOverride = props.record, basePath = props.basePath, children = props.children, rowClick = props.rowClick, mutationMode = props.mutationMode, undoable = props.undoable, rest = __rest(props, ["form", "resource", "id", "record", "basePath", "children", "rowClick", "mutationMode", "undoable"]);
    var resource = useResourceContext(props);
    var record = useRecordContext(props);
    var _a = useState(false), isEdit = _a[0], setEdit = _a[1];
    var classes = useStyles(emptyObject);
    var openEditMode = useCallback(function () {
        setEdit(true);
    }, []);
    var closeEditMode = useCallback(function () {
        setEdit(false);
    }, []);
    var notify = useNotify();
    var _b = useUpdate(resource, id, {}, // set by the caller
    record), update = _b[0], saving = _b[1].loading;
    var defaultOnSuccess = useCallback(function () {
        notify('ra.notification.updated', 'info', {
            smart_count: 1,
        }, undoable);
        closeEditMode();
    }, [notify, undoable, closeEditMode]);
    var defaultOnFailure = useCallback(function (error) {
        notify(typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error', 'warning');
    }, [notify]);
    var _c = useSaveModifiers({
        onFailure: defaultOnFailure,
        onSuccess: defaultOnSuccess,
    }), onSuccessRef = _c.onSuccessRef, setOnSuccess = _c.setOnSuccess, onFailureRef = _c.onFailureRef, setOnFailure = _c.setOnFailure, transformRef = _c.transformRef, setTransform = _c.setTransform;
    var handleClick = useCallback(function (event) {
        var _a = getTableClickEventPosition(event), tbody = _a.tbody, row = _a.row, column = _a.column;
        openEditMode();
        // once the row is replaced by a form, focus the input inside the cell clicked
        setTimeout(function () {
            // No way to know the markup of the form in advance, as developers
            // can inject a form element of their own. The only valid assumption
            // is that the form should have the same number of columns as the row.
            // So we select the input based on the column it's in.
            var input = tbody.querySelector("tr:nth-child(" + row + ") td:nth-child(" + column + ") input");
            input && input.focus && input.focus();
        }, 100); // FIXME not super robust
    }, [openEditMode]);
    var save = useCallback(function (data, _, // unused redirectTo
    _a) {
        var // unused redirectTo
        _b = _a === void 0 ? {} : _a, onSuccessFromSave = _b.onSuccess, onFailureFromSave = _b.onFailure, transformFromSave = _b.transform;
        return Promise.resolve(transformFromSave
            ? transformFromSave(data)
            : !!transformRef.current
                ? transformRef.current(data)
                : data).then(function (data) {
            return update({ payload: { data: data } }, {
                action: CRUD_UPDATE,
                onSuccess: onSuccessFromSave
                    ? onSuccessFromSave
                    : !!onSuccessRef.current
                        ? onSuccessRef.current
                        : defaultOnSuccess,
                onFailure: onFailureFromSave
                    ? onFailureFromSave
                    : !!onFailureRef.current
                        ? onFailureRef.current
                        : defaultOnFailure,
                undoable: undoable,
            });
        });
    }, [
        defaultOnFailure,
        defaultOnSuccess,
        update,
        undoable,
        onFailureRef,
        onSuccessRef,
        transformRef,
    ]);
    var saveContext = {
        save: save,
        saving: saving,
        setOnSuccess: setOnSuccess,
        setOnFailure: setOnFailure,
        setTransform: setTransform,
        onSuccessRef: onSuccessRef,
        onFailureRef: onFailureRef,
        transformRef: transformRef,
    };
    var rowContext = useMemo(function () { return ({
        open: openEditMode,
        close: closeEditMode,
    }); }, [openEditMode, closeEditMode]);
    return (React.createElement(EditRowContext.Provider, { value: rowContext }, isEdit ? (React.createElement(SaveContextProvider, { value: saveContext }, cloneElement(form, __assign({ id: id, quitEditMode: closeEditMode, resource: resource, record: record, basePath: basePath, save: save, saving: saving, mutationMode: mutationMode, undoable: undoable }, rest)))) : (React.createElement(DatagridRow, __assign({ resource: resource, id: id, record: record, basePath: basePath }, rest, { className: classes.td, onClick: rowClick === 'edit'
            ? handleClick
            : function () { return undefined; } }), children))));
};
/**
 * Based on a MouseEvent triggered by a click on a table row,
 * get the tbody element, the row and column number of the cell clicked.
 *
 * @param {MouseEvent} event
 */
var getTableClickEventPosition = function (event) {
    var target = event.target;
    var td = target.closest('td');
    var tr = td.parentNode;
    var columns = tr.children;
    var column;
    for (var index = 0; index < columns.length; index++) {
        if (columns.item(index) === td) {
            column = index + 1;
        }
    }
    var tbody = tr.parentNode;
    var rows = tbody.children;
    var row;
    for (var index = 0; index < rows.length; index++) {
        if (rows.item(index) === tr) {
            row = index + 1;
        }
    }
    return { tbody: tbody, row: row, column: column };
};
var useStyles = makeStyles({
    td: {
        '& td:last-of-type > *': {
            visibility: 'hidden',
        },
        '&:hover td:last-of-type > *': {
            visibility: 'visible',
        },
    },
}, {
    name: 'RaEditableDatagridRow',
});
var emptyObject = {};
export default EditableRow;
