import { makeStyles, Tooltip } from '@material-ui/core'
import { Close, Fireplace } from '@material-ui/icons'
import React, { useState, useReducer, useEffect } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { ToggleType } from '../../../../core/devices/passages/passage-set-boolean-status-button'
import { PassageDto } from '../../../../core/dto/device/passages/passage.dto'
import { PassageSelectStateDialog } from '../PassageDialogs'
import {
  passageSetPPOZButtonInitialState,
  passageSetPPOZButtonReducer,
} from '../../../../core/devices/passages/passage-set-ppoz-button.reducer'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { Authority } from '../../../../core/auth/Authority'

const useStyles = makeStyles({
  iconContainer: {
    position: 'relative',
    width: 34,
  },
  icon: {
    position: 'absolute',
    left: 5,
    top: 5,
    fontSize: '24px !important',
  },
  crossIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 34,
    opacity: 0.8,
  },
})

export const PassageSetPPOZCompactButton = ({ ...props }) => {
  const { disabled, FireAlarm } = props
  const notify = useNotify()
  const translate = useTranslate()
  const classes = useStyles()
  const hasAuthority = useHasAuthority()

  const { loading } = useShowContext()
  const [{ mutateQuery }, dispatch] = useReducer(
    passageSetPPOZButtonReducer,
    passageSetPPOZButtonInitialState,
  )
  const [dialogOpen, setDialogOpen] = useState(false)
  const [state, setState] = useState(false)
  const passage = useRecordContext<PassageDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  useEffect(() => {
    if (state) {
      dispatch({ type: ToggleType.OFF })
    } else {
      dispatch({ type: ToggleType.ON })
    }
  }, [state])

  const handleFireAlarm = async (
    recurringJobName?: string,
    cronExpression?: string,
    auditNote?: string,
  ) => {
    const query = mutateQuery?.(passage, auditNote)
    await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    refresh()
  }

  return (
    <>
      <PassageSelectStateDialog
        open={dialogOpen}
        confirm={handleFireAlarm}
        method="ppozState"
        close={() => setDialogOpen(false)}
        state={state}
        setState={setState}
        OnIcon={<Fireplace className={classes.icon} />}
        OffIcon={
          <>
            <Fireplace className={classes.icon} />
            <Close fontSize="large" className={classes.crossIcon} />
          </>
        }
        withAuditNote
      />
      <Tooltip
        title={translate('resources.modules.fields.fireAlarm')}
        onClick={
          !loading &&
          !mutationLoading &&
          !disabled &&
          hasAuthority(Authority.PASSAGES_SET_PPOZ_BUTTON)
            ? () => setDialogOpen(true)
            : undefined
        }
      >
        <div
          className={classes.iconContainer}
          style={{
            cursor:
              !loading &&
              !mutationLoading &&
              !disabled &&
              hasAuthority(Authority.PASSAGES_SET_PPOZ_BUTTON)
                ? 'pointer'
                : 'default',
          }}
        >
          <Fireplace className={classes.icon} />
          {!FireAlarm ? (
            <Close fontSize="large" className={classes.crossIcon} />
          ) : undefined}
        </div>
      </Tooltip>
    </>
  )
}
