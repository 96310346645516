import {
  DashboardAction,
  DashboardItem,
  DashboardState,
  INACTIVE_COMPONENTS_KEY,
} from './dashboard'
import { UPDATE_DASHBOARD } from '../constants/reducer.actions'

const initialState: DashboardState['dashboard'] = {
  inactiveComponents:
    (localStorage
      .getItem(INACTIVE_COMPONENTS_KEY)
      ?.split(',') as DashboardItem[]) || [],
}

const save = (state: DashboardState['dashboard']) => {
  const { inactiveComponents } = state
  if (inactiveComponents.length) {
    localStorage.setItem(INACTIVE_COMPONENTS_KEY, inactiveComponents.join())
  } else {
    localStorage.removeItem(INACTIVE_COMPONENTS_KEY)
  }
  return state
}

export const dashboardReducer = (
  state = initialState,
  { type, payload: dashboardItem }: DashboardAction,
): DashboardState['dashboard'] => {
  if (type === UPDATE_DASHBOARD) {
    const { inactiveComponents } = state
    if (inactiveComponents.includes(dashboardItem)) {
      return save({
        ...state,
        inactiveComponents: inactiveComponents.filter(
          (v) => v !== dashboardItem,
        ),
      })
    }
    return save({
      ...state,
      inactiveComponents: [...inactiveComponents, dashboardItem],
    })
  }
  return state
}
