import { Box, Divider, Tooltip } from '@material-ui/core'
import { FormatListBulleted, Info } from '@material-ui/icons'
import React, { ReactElement, useState } from 'react'
import {
  EditButton,
  FunctionField,
  ResourceContextProvider,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useShowContext,
  useTranslate,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { DynamicStatisticDto } from '../../../core/dto/dynamic-statistic.dto'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { DynamicStatisticParametersList } from './dynamic-statistics-show/DynamicStatisticParameterList'
import { DynamicStatisticParametersDialog } from './DynamicStatisticsDialogs'
import RelationTab from '../../common/RelationTab'
import relationTabFilter from '../../common/RelationTabFilter'

const ViewTitle = ({ ...props }) => {
  const { record } = props
  const t = useTranslate()
  return (
    <span>
      {t(`resources.${ResourceName.STATS_DYNAMIC_STATISTICS}.titles.show`)}:{' '}
      {record?.name}
    </span>
  )
}

const GeneralActions = () => {
  const { record: dynamicStatistic, loading } =
    useShowContext<DynamicStatisticDto>()
  const hasAuthority = useHasAuthority()
  const [parametersOpen, setParametersOpen] = useState<boolean>(false)

  return (
    <>
      <DynamicStatisticParametersDialog
        open={parametersOpen}
        close={() => setParametersOpen(false)}
        id={dynamicStatistic?.id}
      />
      <Divider />
      <Box p={1} display="block" textAlign="right">
        {hasAuthority(Authority.EXECUTE_DYNAMIC_STATISTICS) && (
          <Button
            label="resources.stats-dynamic-statistics.actions.preview"
            startIcon={<FormatListBulleted />}
            variant="contained"
            style={{ margin: '2px' }}
            onClick={() => setParametersOpen(true)}
            disabled={loading}
          />
        )}
        <EditButton
          basePath={`/${ResourceName.STATS_DYNAMIC_STATISTICS}`}
          record={dynamicStatistic}
          variant="contained"
          style={{ margin: '2px' }}
          disabled={!hasAuthority(Authority.EDIT_DYNAMIC_STATISTICS)}
        />
      </Box>
    </>
  )
}

const DynamicStatisticsGrid = ({ ...props }) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()
  const { record: statistic } = useShowContext()

  const relationFilters = (...filterProps) =>
    relationTabFilter({
      ...filterProps,
      children: [<TextInput source="name" alwaysOn />],
      resource: ResourceName.STATS_DYNAMIC_STATISTICS,
    })

  return (
    <TabbedShowLayout {...props}>
      <Tab label={translate('resources.stats-dynamic-statistics.tabs.general')}>
        <TextField source="id" label="Id" />
        <TextField source="name" />
        <TextField source="sqlQuery" />
        <GeneralActions />
      </Tab>
      {hasAuthority(Authority.VIEW_DYNAMIC_STATISTIC_PARAMETERS) && (
        <Tab
          label={translate(
            'resources.stats-dynamic-statistics.tabs.parameters',
          )}
          path="parameters"
        >
          <ResourceContextProvider
            value={ResourceName.STATS_DYNAMIC_STATISTIC_PARAMETERS}
          >
            <DynamicStatisticParametersList
              title=" "
              filter={{ statisticId: statistic?.id }}
            />
          </ResourceContextProvider>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_ROLES) && (
        <Tab
          path="roles"
          label={translate('resources.stats-dynamic-statistics.tabs.roles')}
        >
          <>
            <RelationTab<DynamicStatisticDto>
              resource={ResourceName.ROLES}
              source="rolesIds"
              mode={
                hasAuthority(Authority.EDIT_DYNAMIC_STATISTICS)
                  ? 'edit'
                  : 'show'
              }
              attachMethod="attachRoles"
              detachMethod="detachRoles"
              attachRequestPayload={(r, ids) => ({
                dynamicStatisticId: r.id,
                rolesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                dynamicStatisticId: r.id,
                rolesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterDynamicStatisticId === true
              }
              filters={relationFilters() as ReactElement[]}
              filterDefaultValues={{
                dynamicStatisticId: statistic?.id,
                filterDynamicStatisticId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField source="id" label="ID" />
              <TextField source="name" key="name" />
              <FunctionField
                key="description"
                render={(record) =>
                  record?.description ? (
                    <Tooltip title={record?.description}>
                      <Info />
                    </Tooltip>
                  ) : (
                    <div />
                  )
                }
              />
            </RelationTab>
          </>
        </Tab>
      )}
      {hasAuthority(Authority.VIEW_COMPANIES) && (
        <Tab
          path="companies"
          label={translate('resources.stats-dynamic-statistics.tabs.companies')}
        >
          <>
            <RelationTab<DynamicStatisticDto>
              resource={ResourceName.COMPANIES}
              source="companiesIds"
              mode={
                hasAuthority(Authority.EDIT_DYNAMIC_STATISTICS)
                  ? 'edit'
                  : 'show'
              }
              attachMethod="attachCompanies"
              detachMethod="detachCompanies"
              attachRequestPayload={(r, ids) => ({
                dynamicStatisticId: r.id,
                companiesIds: ids,
              })}
              detachRequestPayload={(r, ids) => ({
                dynamicStatisticId: r.id,
                companiesIds: ids,
              })}
              refetchListAfterChange={(filters) =>
                filters?.filterDynamicStatisticId === true
              }
              filters={relationFilters() as ReactElement[]}
              filterDefaultValues={{
                dynamicStatisticId: statistic?.id,
                filterDynamicStatisticId: false,
              }}
              sort={{
                field: 'id',
                order: 'DESC',
              }}
            >
              <TextField source="id" label="ID" />
              <TextField source="name" key="name" />
            </RelationTab>
          </>
        </Tab>
      )}
    </TabbedShowLayout>
  )
}

export const DynamicStatisticsShow = ({ ...props }) => (
  <Show {...props} title={<ViewTitle />} actions={false}>
    <DynamicStatisticsGrid />
  </Show>
)
