import { Box, Tooltip, Typography } from '@material-ui/core'
import { Button, useNotify, useTranslate } from 'react-admin'
import GetAppIcon from '@material-ui/icons/GetApp'
import React, { Dispatch, useState } from 'react'
import { CsvDataPayload, processCsvFile } from '../../../core/csv'

type ImportCsvButtonProps = {
  csvDataDispatcher: Dispatch<CsvDataPayload | null>
}

export const ImportCsvButton = ({
  csvDataDispatcher,
}: ImportCsvButtonProps) => {
  const t = useTranslate()
  const notify = useNotify()
  const [fileName, setFileName] = useState<string>()

  let refInput: HTMLInputElement

  const clickImportButton = () => {
    refInput.value = ''
    refInput.click()
  }

  const onFileAdded = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName('')
    csvDataDispatcher(null)
    const importedFile = e.target.files?.[0]
    if (!importedFile) {
      notify('common.import.processing-error-message', 'error')
      throw new Error(`File not processed from input field`)
    }
    const processedCsvData = await processCsvFile(importedFile)
    setFileName(importedFile.name)
    csvDataDispatcher({ rawCsvData: processedCsvData })
  }

  return (
    <Box m={2} display="flex" alignItems="center">
      <Box>
        <Tooltip title={t('common.import.button-tooltip')}>
          <Box>
            <Button
              variant="outlined"
              color="primary"
              label={t('common.import.button-label')}
              onClick={clickImportButton}
            >
              <GetAppIcon
                style={{ transform: 'rotate(180deg)', fontSize: '20' }}
              />
            </Button>
          </Box>
        </Tooltip>
        <input
          ref={(ref) => {
            refInput = ref as HTMLInputElement
          }}
          type="file"
          style={{ display: 'none' }}
          onChange={onFileAdded}
          accept=".csv,.tsv"
        />
      </Box>
      <Box ml={2}>
        <Typography variant="body1">
          {fileName
            ? t('common.import.button-loaded-description', { fileName })
            : t('common.import.button-load-description')}
        </Typography>
      </Box>
    </Box>
  )
}
