const mapping: Record<
  string,
  (lambdaParameter: string, value: any, otherValues: any) => string | null
> = {
  id: (lambdaParameter, value) => `${lambdaParameter}.Id==${value}`,
  numberAlias: (lambdaParameter, value) =>
    `${lambdaParameter}.NumberAlias.Contains("${value}")`,
  rowAlias: (lambdaParameter, value) =>
    `${lambdaParameter}.RowAlias.Contains("${value}")`,
  blockId: (lambdaParameter, value) =>
    `${lambdaParameter}.BlockId.Equals(${value})`,
  objectId: (lambdaParameter, value) =>
    `${lambdaParameter}.Block.Tribune.ObjectId.Equals(${value})`,
  belongsToBlock: (lambdaParameter, value) =>
    `${lambdaParameter}.BlockId.Equals(${value})`,
  combinedFullAlias: (lambdaParameter, value) => {
    const values = (value as string)
      .split('/')
      .map((val) => val.trim())
      .filter((val) => val)
      .slice(0, 2)
    if (values?.length > 0) {
      const row = values[0]
      const number = values.length === 2 ? values[1] : values[0]
      return `(${lambdaParameter}.RowAlias.Equals("${row}") ${
        values.length === 2 ? '&&' : '||'
      } ${lambdaParameter}.NumberAlias.Equals("${number}"))`
    }
    return null
  },
}

export const filterMapper = (key: string) =>
  mapping[key] as (
    lambdaParameter: string,
    value: any,
    otherValues: any,
  ) => string

export default filterMapper
