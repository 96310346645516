import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState, useEffect, useReducer } from 'react'
import {
  useMutation,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { ToggleType } from '../../../core/bookings/toggle-booking-blockade-button'
import {
  blockTicketButtonInitialState,
  toggleTicketButtonReducer,
} from '../../../core/bookings/toggle-booking-blockade-button.reducer'
import { BookingDto } from '../../../core/dto/booking.dto'
import { BookingDisabledOption } from '../../../core/enum/BookingDisabledOption'
import { ResourceName } from '../../../core/ResourceName'
import Button from '../../common/customized-mui-components/Button'
import { useHasAuthority } from '../../hooks/useHasAuthority'
import { ConfirmDialog } from '../../common/ConfirmDialog'
import { ToggleBookingBlockadeDialog } from './ToggleBookingBlockadeDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.white,
      margin: '2px',
    },
  }),
)

export const ToggleBookingBlockadeButton = () => {
  const classes = useStyles()
  const notify = useNotify()
  const hasAuthority = useHasAuthority()
  const { loading } = useShowContext()
  const [disabledOption, setDisabledOption] = useState<
    BookingDisabledOption | undefined
  >(undefined)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  const [{ type, text, mutateQuery }, dispatch] = useReducer(
    toggleTicketButtonReducer,
    blockTicketButtonInitialState,
  )
  const { id: bookingId, disabled: bookingDisabled } =
    useRecordContext<BookingDto>()
  const [mutate, { loading: mutationLoading }] = useMutation()
  const refresh = useRefresh()

  useEffect(() => {
    if (bookingDisabled === null) {
      dispatch({ type: ToggleType.BLOCK })
    } else if (
      bookingDisabled === BookingDisabledOption.ADMIN ||
      bookingDisabled === BookingDisabledOption.BLACK_LISTED
    ) {
      dispatch({ type: ToggleType.UNBLOCK })
    } else {
      dispatch({ type: ToggleType.DISABLED })
    }
  }, [bookingDisabled])

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleOpenConfirm = () => {
    setConfirmOpen(true)
  }

  const handleCloseConfirm = () => {
    setConfirmOpen(false)
  }

  const onClick = async () => {
    const query = mutateQuery?.({
      id: bookingId,
      bookingDisabled: disabledOption as BookingDisabledOption,
    })
    await mutate(query, {
      returnPromise: true,
      onFailure: (err) => notify(err?.message, 'error'),
    })
    handleCloseConfirm()
    handleCloseDialog()
    refresh()
  }

  return (
    <>
      <ConfirmDialog
        open={confirmOpen}
        confirm={onClick}
        source="toggleBlockade"
        cancel={handleCloseConfirm}
        action={type === ToggleType.BLOCK ? 'set' : 'remove'}
        resource={ResourceName.BOOKINGS}
      />
      <ToggleBookingBlockadeDialog
        open={dialogOpen}
        close={handleCloseDialog}
        disabledOption={disabledOption}
        setDisabledOption={setDisabledOption}
        handleOpenConfirm={handleOpenConfirm}
      />
      <Button
        disabled={
          !hasAuthority(Authority.BOOKINGS_BLOCKADE_BUTTON) ||
          type === ToggleType.DISABLED ||
          loading ||
          mutationLoading
        }
        className={classes.button}
        variant="contained"
        onClick={
          type === ToggleType.BLOCK ? handleOpenDialog : handleOpenConfirm
        }
        label={text}
        useSmallVersionBreakpoint={false}
      />
    </>
  )
}
