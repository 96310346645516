import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  UpdateParams,
  UpdateResult,
  Identifier,
  GetManyParams,
  GetManyResult,
} from 'react-admin'
import { TRIBUNES_URL } from '../api-urls'
import { get, getWithPagination, post, patch } from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../common/ticket-api-get-list.params'
import { TribuneDto } from '../dto/tribune.dto'
import { TribuneCreateParams } from './tribunes-create.params'
import filterMapper from './tribunes-filter.mapper'
import { mapSortTribuneParam } from './tribunes-sort.mapper'

type CreateTribuneRequest = Array<TribuneCreateParams>

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<TribuneDto>> => {
    const filterParams = `v=>${filterParamsComposer('v', filter, filterMapper)}`
    const pathParams = queryParamsComposer(
      sort,
      pagination,
      mapSortTribuneParam,
    )
    const path = `/${filterParams}?${pathParams ?? pathParams}`
    const {
      data,
      range: { total },
    } = await getWithPagination<TribuneDto[]>(
      `${TRIBUNES_URL}/GetByConditions`,
      path,
    )
    return Promise.resolve({
      data,
      total,
    })
  },
  create: async (
    resource,
    { data }: CreateParams<TribuneCreateParams>,
  ): Promise<CreateResult> => {
    const request: CreateTribuneRequest = [data]
    await post<CreateTribuneRequest, TribuneDto[]>(TRIBUNES_URL, request)
    return Promise.resolve({
      data: { id: 0 },
    })
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      TribuneDto
    >(TRIBUNES_URL, {
      id,
      name: data.name !== previousData.name ? data.name : undefined,
    })
    return Promise.resolve({ data: update })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<TribuneDto>> => {
    const data = await get<TribuneDto[]>(
      TRIBUNES_URL,
      `/GetByConditions/t=>new int[] {${ids.toString()}}.Contains(t.Id)`,
    )
    return Promise.resolve({ data })
  },
} as DataProvider

export default provider
