import { Box, Divider, Grid } from '@material-ui/core'
import React from 'react'
import {
  Show,
  ShowProps,
  TextField,
  TitleProps,
  useTranslate,
  EditButton,
  TabbedShowLayout,
  Tab,
  useShowContext,
  FunctionField,
  ReferenceField,
  BooleanField,
} from 'react-admin'
import { Authority } from '../../../../core/auth/Authority'
import { LogSchemeDataDto } from '../../../../core/dto/log-scheme-data.dto'
import { ResourceName } from '../../../../core/ResourceName'
import { RAColorField } from '../../../common/RAColorField'
import { useHasAuthority } from '../../../hooks/useHasAuthority'
import { SchemeDto } from '../../../../core/dto/scheme/scheme.dto'

interface LogSchemeDataTitleProps extends TitleProps {
  record?: LogSchemeDataDto
}

const ShowTitle = (data: LogSchemeDataTitleProps) => {
  const { record: scheme } = data
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.log-scheme-data.titles.show')}: {scheme?.id}.{' '}
      {scheme?.text}
    </span>
  )
}

const GeneralActions = () => {
  const { record: scheme } = useShowContext<LogSchemeDataDto>()
  const hasAuthority = useHasAuthority()

  return (
    <>
      <Divider />
      <Box p={1} display="flex" justifyContent="flex-end">
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <EditButton
              variant="contained"
              basePath="/log-scheme-data"
              record={scheme}
              style={{ margin: '2px' }}
              disabled={!hasAuthority(Authority.EDIT_LOG_SCHEME_DATA)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export const LogSchemeDataShow = (props: ShowProps) => {
  const translate = useTranslate()
  const hasAuthority = useHasAuthority()

  return (
    <Show {...props} actions={false} title={<ShowTitle />}>
      <TabbedShowLayout>
        <Tab label={translate('resources.log-scheme-data.tabs.general')}>
          <TextField source="id" label="ID" />
          <FunctionField<LogSchemeDataDto>
            source="language"
            render={(record?: LogSchemeDataDto) =>
              translate(`settings.language.${record?.language}`)
            }
          />
          <TextField source="text" />
          <RAColorField source="color" />
          <BooleanField source="hiddenInSimpleView" />
          <TextField source="schemeId" />
          {hasAuthority(Authority.VIEW_SCHEMES) && (
            <ReferenceField
              source="schemeId"
              reference={ResourceName.SCHEMES}
              label="resources.log-scheme-data.fields.scheme"
              link={false}
            >
              <FunctionField<SchemeDto>
                source="name"
                render={(scheme?: SchemeDto) =>
                  `${scheme?.id}. ${scheme?.name}`
                }
              />
            </ReferenceField>
          )}
          <GeneralActions />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
