import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  Create,
  DateTimeInput,
  ListButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useTranslate,
  CreateProps,
} from 'react-admin'
import { Authority } from '../../../core/auth/Authority'
import { BookingDto } from '../../../core/dto/booking.dto'
import { CustomerDto } from '../../../core/dto/customer.dto'
import { BookingType } from '../../../core/enum/BookingType'
import { ResourceName } from '../../../core/ResourceName'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'
import { useHasAuthority } from '../../hooks/useHasAuthority'

const validateServiceBookingCreate = (values: BookingDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.customerId) {
    errors.customerId = 'ra.validation.required'
  }
  if (!values.variantId) {
    errors.variantId = 'ra.validation.required'
  }
  return errors
}

const CreateTitle = () => {
  const translate = useTranslate()
  return <span>{translate('resources.service-bookings.titles.create')}</span>
}

const CreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton
      basePath="/service-bookings"
      icon={<Cancel />}
      label="const.cancel"
    />
  </Toolbar>
)

export const ServiceBookingCreate = (props: CreateProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Create
      {...props}
      hasShow={false}
      title={<CreateTitle />}
      basePath="/bookings"
    >
      <SimpleForm
        redirect="show"
        toolbar={<CreateToolbar />}
        validate={validateServiceBookingCreate}
        initialValues={{
          type: BookingType.SERVICE,
        }}
      >
        <DateTimeInput
          source="activeFrom"
          parse={(value: string) => (value === '' ? null : value)}
        />
        <DateTimeInput
          source="activeTo"
          parse={(value: string) => (value === '' ? null : value)}
        />
        {hasAuthority(Authority.VIEW_CUSTOMERS) && (
          <FilteredReferenceInput
            source="customerId"
            defaultValue={null}
            reference={ResourceName.CUSTOMERS}
            sort={{ field: 'lastname', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="info"
            selectWithPaginationInputProps={{
              optionText: (choice: CustomerDto) =>
                `${choice.firstname} ${choice.lastname}`,
              showFilter: true,
            }}
          />
        )}
        {hasAuthority(Authority.VIEW_VARIANTS) && (
          <FilteredReferenceInput
            source="variantId"
            defaultValue={null}
            reference={ResourceName.VARIANTS}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={smallScreen ? 5 : 15}
            isRequired
            filterSource="search"
            selectWithPaginationInputProps={{
              optionText: 'name',
              showFilter: true,
            }}
          />
        )}
      </SimpleForm>
    </Create>
  )
}
