import { DataProvider } from 'react-admin'
import { CompanyDto } from '../../dto/company.dto'

const fakeCompanies: CompanyDto[] = [
  {
    id: 0,
    name: '0',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 1,
    name: '1',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 2,
    name: '2',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 3,
    name: '3',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 4,
    name: '4',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 5,
    name: '5',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 6,
    name: '6',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 7,
    name: '7',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 8,
    name: '8',
    usersIds: [],
    eventsIds: [],
  },
  {
    id: 9,
    name: '9',
    usersIds: [],
    eventsIds: [],
  },
]

const provider = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getList: (resource, params) =>
    Promise.resolve({ data: fakeCompanies, total: fakeCompanies.length }),
} as DataProvider

export default provider
