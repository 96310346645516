import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { DOORS_URL } from '../../api-urls'
import {
  CacheableDataProviderExtensionResult,
  DataProviderExtensionResult,
} from '../../common/data-provider'
import {
  get,
  getWithPagination,
  patch,
  post,
  put,
  recurringJobParamsComposer,
} from '../../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../../common/get-by-conditions.utils'
import { TicketAPIGetListParams } from '../../common/ticket-api-get-list.params'
import { DoorDto } from '../../dto/device/doors/door.dto'
import filterMapper from './door-filter.mapper'
import mapSortDoorParam from './door-sort.mapper'
import { getDeviceCategoryChildren } from '../devices.utils'
import { RecurringJobCreationParamsDto } from '../../dto/recurring-job-creation-params.dto'
import { DoorEventsDto } from '../../dto/device/doors/door-events.dto'
import { DoorEntrancesDto } from '../../dto/device/doors/door-entrances.dto'
import { RecurringJobDetailsDto } from '../../dto/recurring-job-details.dto'
import { SetStandbyModeDto } from '../../dto/device/doors/set-standby-mode.dto'
import { DeviceDirection } from '../../enum/DeviceDirection'
import { Multiplicities } from '../../enum/Multiplicities'

const provider = {
  getList: async (
    resource: string,
    { filter, sort, pagination }: TicketAPIGetListParams,
  ): Promise<GetListResult<DoorDto>> => {
    let extendedFilter = filter
    // Tree type filter for device categories
    if (extendedFilter?.categoryIdWithDescendants) {
      const categoriesIds = await getDeviceCategoryChildren(
        extendedFilter.categoryIdWithDescendants,
      )
      extendedFilter = { ...extendedFilter, categoriesIds }
    }
    const filterParams = `o=>${filterParamsComposer(
      'o',
      extendedFilter,
      filterMapper,
    )}`
    const pathParams = queryParamsComposer(sort, pagination, mapSortDoorParam)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<DoorDto[]>(`${DOORS_URL}/GetByConditions`, path)

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<GetOneResult<DoorDto>> => {
    const data = await get<DoorDto>(DOORS_URL, `/${id}`)
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<DoorDto>> => {
    const data = await get<DoorDto[]>(
      DOORS_URL,
      `/GetByConditions/e=>new int[] {${ids.toString()}}.Contains(e.Id)`,
    )
    return Promise.resolve({ data })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateDoorRequest>,
  ): Promise<CreateResult<DoorDto>> => {
    const door = await post<CreateDoorRequest[], DoorDto[]>(DOORS_URL, [data])
    return {
      data: door[0],
    }
  },
  update: async (
    resource: string,
    { id, data, previousData }: UpdateParams<DoorDto>,
  ): Promise<UpdateResult> => {
    const update = await patch<{ id: Identifier; [n: string]: any }, DoorDto>(
      DOORS_URL,
      {
        id,
        name: data.name !== previousData.name ? data.name : undefined,
        tsCanId:
          data.tsCanId !== previousData.tsCanId ? data.tsCanId : undefined,
        supervisorId:
          data.supervisorId !== previousData.supervisorId
            ? data.supervisorId
            : undefined,
      },
    )
    return Promise.resolve({ data: update })
  },
  attachEvents: async (
    resource: string,
    params: DoorEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<DoorEventsDto>> => {
    const doorEvents = await put<DoorEventsDto, DoorEventsDto>(
      `${DOORS_URL}/AttachEvents`,
      params,
    )
    return {
      data: doorEvents,
    }
  },
  detachEvents: async (
    resource: string,
    params: DoorEventsDto,
  ): Promise<CacheableDataProviderExtensionResult<DoorEventsDto>> => {
    const doorEvents = await put<DoorEventsDto, DoorEventsDto>(
      `${DOORS_URL}/DetachEvents`,
      params,
    )
    return {
      data: doorEvents,
    }
  },
  attachEntrances: async (
    resource: string,
    params: DoorEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<DoorEntrancesDto>> => {
    const doorEntrances = await put<DoorEntrancesDto, DoorEntrancesDto>(
      `${DOORS_URL}/AttachEntrances`,
      params,
    )
    return {
      data: doorEntrances,
    }
  },
  detachEntrances: async (
    resource: string,
    params: DoorEntrancesDto,
  ): Promise<CacheableDataProviderExtensionResult<DoorEntrancesDto>> => {
    const doorEntrances = await put<DoorEntrancesDto, DoorEntrancesDto>(
      `${DOORS_URL}/DetachEntrances`,
      params,
    )
    return {
      data: doorEntrances,
    }
  },
  lock: async (
    resource: string,
    { doorId, disabledId, ...rest }: LockParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const lockDoor = await put<
      LockParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${DOORS_URL}/Lock/${query ? `?${query}` : ''}`, {
      doorId,
      disabledId,
    })
    if ((lockDoor as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: lockDoor as RecurringJobDetailsDto,
      }
    return {
      data: lockDoor,
    }
  },
  unlock: async (
    resource: string,
    { doorId, ...rest }: UnlockParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const unlockDoor = await put<
      UnlockParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${DOORS_URL}/Unlock/${query ? `?${query}` : ''}`, {
      doorId,
    })
    if ((unlockDoor as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: unlockDoor as RecurringJobDetailsDto,
      }
    return {
      data: unlockDoor,
    }
  },
  setStandbyMode: async (
    resource: string,
    { doorId, stanbyMode, ...rest }: SetStandbyModeDto,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const setStandby = await put<
      SetStandbyModeDto,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${DOORS_URL}/SetStandbyMode/${query ? `?${query}` : ''}`, {
      doorId,
      stanbyMode,
    })
    if ((setStandby as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setStandby as RecurringJobDetailsDto,
      }
    return {
      data: setStandby,
    }
  },
  setDoorState: async (
    resource: string,
    { doorId, released, ...rest }: SetStateParams,
  ): Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  > => {
    const query =
      rest && recurringJobParamsComposer(rest as RecurringJobCreationParamsDto)
    const setState = await put<
      SetStateParams,
      ChangeStateResponse | RecurringJobDetailsDto
    >(`${DOORS_URL}/SetDoorReleased/${query ? `?${query}` : ''}`, {
      doorId,
      released,
    })
    if ((setState as RecurringJobDetailsDto)?.recurringJobId)
      return {
        data: setState as RecurringJobDetailsDto,
      }
    return {
      data: setState,
    }
  },
  forceReportState: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const forceReportState = await get<undefined>(
      DOORS_URL,
      `/ForceReportState/${params?.id}`,
    )
    return {
      data: forceReportState,
    }
  },
  resetModules: async (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ): Promise<DataProviderExtensionResult<undefined>> => {
    const resetModules = await get<undefined>(
      DOORS_URL,
      `/ResetModules/${params?.id}`,
    )
    return {
      data: resetModules,
    }
  },
} as DoorsDataProvider

interface DoorsDataProvider extends DataProvider {
  attachEvents: (
    resource: string,
    params: DoorEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<DoorEventsDto>>

  detachEvents: (
    resource: string,
    params: DoorEventsDto,
  ) => Promise<CacheableDataProviderExtensionResult<DoorEventsDto>>

  attachEntrances: (
    resource: string,
    params: DoorEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<DoorEntrancesDto>>

  detachEntrances: (
    resource: string,
    params: DoorEntrancesDto,
  ) => Promise<CacheableDataProviderExtensionResult<DoorEntrancesDto>>

  lock: (
    resource: string,
    params: LockParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  unlock: (
    resource: string,
    params: UnlockParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setStandbyMode: (
    resource: string,
    params: SetStandbyModeDto,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  setDoorState: (
    resource: string,
    params: SetStateParams,
  ) => Promise<
    DataProviderExtensionResult<ChangeStateResponse | RecurringJobDetailsDto>
  >

  forceReportState: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>

  resetModules: (
    resource: string,
    params: {
      readonly id: Identifier
    },
  ) => Promise<DataProviderExtensionResult<undefined>>
}

export interface CreateDoorRequest {
  readonly name: string
  readonly isWatched: boolean
  readonly direction: DeviceDirection
  readonly categoryId: number
  readonly disabledId: number
  readonly jsonStatus: string
  readonly onlineInfixCeck: boolean
  readonly multiplicity: Multiplicities
  readonly cameraId: number
  readonly tsCanId: number
  readonly supervisorId: number
}

export interface LockParams extends RecurringJobCreationParamsDto {
  doorId: number
  disabledId: number
}

export interface UnlockParams extends RecurringJobCreationParamsDto {
  doorId: number
}

interface SetStateParams extends RecurringJobCreationParamsDto {
  readonly doorId: Identifier
  readonly released: boolean
}

interface ChangeStateResponse {
  jobId: Identifier
}

export default provider
